import React, { useState, useEffect, useRef, useMemo, useContext } from "react"
import Select from "react-select"
import { startOfYear, format } from "date-fns"
import { fr } from "date-fns/esm/locale"
import { saveAs } from "file-saver"
import * as yup from "yup"

// hooks
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { SE_HOSTNAME, PERMISSIONS, schoolGrades } from "../../../../constants/constants"

// Components
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"
import Modal from "../../../../components/molecules/Modal"

// services
import {
  weekIdToDate,
  getCampusWeeksOptionsFromYear,
} from "../../../../utils/weeks"

// Utils
import { getFormStateName } from "../../../../utils/forms"
import { isNil } from "lodash"
import { KeycloakAuthContext } from "../../../../components/templates/KeycloakAuthProvider"

const permissions = [
  PERMISSIONS.PERM_STUDENTS_CREATE,
  PERMISSIONS.PERM_STUDENTS_UPDATE,
]

const StudentsForms = ({ selectedPlayer }) => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const { keycloak } = useContext(KeycloakAuthContext)

  const [, , formEventsError, { 
    postFormEvents, 
    postFormSign,
    getFormConfig,
    getFormHealthRecord,
    postFormConfig,
    putFormConfig,
    getFormTypes }] = useSportStudiesApi()

  const [subview, setSubview] = useState("list")
  const [data, setData] = useState({
    discountAmount: 0,
    discountMessage: "",
    hasDiscount: false,
    year: new Date().getFullYear(),
  })
  const [formSave, setFormSave] = useState(false)
  const [formError, setFormError] = useState(null)
  const [formSuccess, setFormSuccess] = useState(null)
  const [formsList, setFormsList] = useState([])
  const [formTypes, setFormTypes] = useState({})
  const [confirmSignFolderModal, setConfirmSignFolderModal] = useState(false)

  const canEditPeriodicity =
    !data?.stateId ||
    data.stateId === 1 ||
    data.stateId === 2 ||
    data.stateId === 3

  const formEvent = useRef(null)

  const weekOptions = useMemo(() => {
    if (data.year) {
      return getCampusWeeksOptionsFromYear({ year: data.year })
    }
    return []
  }, [data.year])

  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const NEED_SCHOOL_ID = (data.typeId === 1 || data.typeId === 4 || data.typeId === 6);

  useEffect(() => {
    setData({ ...data, studentId: selectedPlayer.id })

    getFormTypes().then(r => {
      if (r && r.data) setFormTypes(r.data.formsTypes)
    })

    getFormConfig(selectedPlayer.id)
      .then(response => {
        if (response && response.data)
          setFormsList(
            response.data.forms.map(form => ({
              ...form,
              startWeekId: {
                label: `Semaine du ${format(
                  weekIdToDate(form.startWeekId, "campus"),
                  "dd MMMM yyyy",
                  { locale: fr }
                )}`,
                value: form.startWeekId,
              },
              endWeekId: {
                label: `Semaine du ${format(
                  weekIdToDate(form.endWeekId - 1, "campus"),
                  "dd MMMM yyyy",
                  { locale: fr }
                )}`,
                value: form.endWeekId - 1,
              },
            }))
          )
      })
      .catch(errors => {
        //
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sanitizeForm = formData => {
    return {
      ...data,
      startWeekId: formData?.startWeekId?.value || undefined,
      endWeekId: formData?.endWeekId?.value + 1 || undefined,
    }
  }

  const handleSwitchToForm = () => setSubview("form")

  const handleSubmitForm = e => {
    e.preventDefault()
    setFormSave(true)
    setFormError(null)
    setFormSuccess(null)
    setGlobalMessage({ ...globalMessage, isActive: false })

    const sanitizedData = sanitizeForm(data)

    data.id
      ? putFormConfig(sanitizedData.id, sanitizedData)
          .then((res) => {
            setFormSave(false);
            if (res?.data) setFormSuccess("Enregistré avec succès");
          })
      : postFormConfig(sanitizedData)
          .then((res) => {
            setFormSave(false)

            if (res) {
              setFormSuccess("Enregistré avec succès")
            } else {
              setFormError("Une erreur s'est produite lors de la soumission.")
            }
          })
          .catch((errors) => {
            setFormSave(false)
            setFormError(errors.response.data.errors[0])
          })
  }

  const handleChange = e => {
    if (
      e.target.name === "schoolId" ||
      e.target.name === "discountAmount" ||
      e.target.name === "year" ||
      e.target.name === "startWeekId" ||
      e.target.name === "endWeekId" ||
      e.target.name === "typeId"
    ) {
      let updatedData = {
        ...data,
        [e.target.name]: parseFloat(e.target.value),
      }
      if (e.target.name === "discountAmount") {
        updatedData = {
          ...updatedData,
          discountAmount: updatedData.discountAmount * 100,
        }
      }
      if (
        e.target.name === "typeId" &&
        (e.target.value === "2" ||
          e.target.value === "3" ||
          e.target.value === "5" ||
          e.target.value === "7")
      ) {
        updatedData = { ...updatedData, schoolId: 1 }
      }
      if (e.target.name === "schoolId") {
        updatedData = { ...updatedData, grade: null }
      }
      setData(updatedData)
    } else if (e.target.name === "hasDiscount") {
      setData({ ...data, hasDiscount: e.target.value === "true" && true })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  const handleFormEditClick = formSelected => {
    setData(formSelected)
    setSubview("form")
  }

  const adminFormAccesLink = () => {
    if (keycloak.authenticated) {
      const thisStudentId = selectedPlayer.id
      const thisFormId = data ? data.id : ""
      return `https://${SE_HOSTNAME}/admin?studentId=${thisStudentId}&formId=${thisFormId}`
    }
    return null
  }

  const updateFormStatus = config => {
    postFormEvents(config.data)
      .then(response => {
        setData(response.data.form)
      })
      .catch(errors => {
        //
      })
  }

  const handleLockForm = () => {
    const conf = {
      data: { formId: data.id || null, transition: "lock" },
    }
    formEvent.current = "lock"
    updateFormStatus(conf)
  }

  const handleUnlockForm = () => {
    const conf = {
      data: { formId: data.id || null, transition: "unlock" },
    }
    formEvent.current = "unlock"
    updateFormStatus(conf)
  }

  const handleValidateForm = () => {
    const conf = {
      data: { formId: data.id || null, transition: "validate" },
    }
    formEvent.current = "validate"
    updateFormStatus(conf)
  }

  const handleConfirmSign = async () => {
    try{
      await postFormSign({ formId: data.id })
      formEvent.current = "sign"
      setData({...data, stateId: 5})
      setConfirmSignFolderModal(null)
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: <span>Vous avez signé avec succès ce dossier</span>,
        className: "alert alert-success",
      })
    } catch(err){
      setConfirmSignFolderModal(null)
      console.error('fail confirm : ', err)
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: <span>Une erreur est survenue</span>,
        className: "alert alert-danger",
      })
    }
  }

  const handleInValidateForm = () => {
    const conf = {
      data: { formId: data.id || null, transition: "invalidate" },
    }
    formEvent.current = "invalidate"
    updateFormStatus(conf)
  }

  const handleMedicalExport = async form => {
    const resp = await getFormHealthRecord(form.id)
    if (resp && resp.data) {
      const blob = resp.data
      saveAs(blob, "medical-export.zip")
    }
  }

  useEffect(() => {
    if (formEventsError) {
      if (formEvent.current === "validate") {
        switch (formEventsError.status) {
          case 400: {
            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <span>
                  Vous ne pouvez pas valider ce dossier, merci de choisir un
                  nouvel hébergement
                </span>
              ),
              className: "alert alert-danger",
            })
            break
          }
          default: {
            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <span>
                  Une erreur est survenue pendant la validation du dossier
                </span>
              ),
              className: "alert alert-danger",
            })
          }
        }
      } else {
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: <span>Une erreur est survenue</span>,
          className: "alert alert-danger",
        })
      }
    }
  }, [formEventsError])

  useEffect(() => {
    if (formError || formSuccess || globalMessage?.isActive) {
      window.scrollTo(0, 0);
    }
  }, [formError, formSuccess, globalMessage?.isActive]);

  const validationSchema = yup.object().shape({
    discountAmount: yup.number().required(),
    discountMessage: yup.string(),
    hasDiscount: yup.bool().required(),
    schoolId: !NEED_SCHOOL_ID ? yup.number() : yup.number().required(),
    ...(!canEditPeriodicity && { startWeekId: yup.number().required() }),
    ...(!canEditPeriodicity && { endWeekId: yup.number().required() }),
    studentId: yup.string().required(),
    typeId: yup.number().required(),
    year: yup.number().required(),
    grade: !NEED_SCHOOL_ID ? yup.string().nullable() : yup.string().required(),
  })

  const validateForm = () => validationSchema.isValidSync(sanitizeForm(data))

  const Form = () => (
    <>
      {formError ? <div className="alert alert-danger">{formError}</div> : null}
      {formSuccess ? (
        <div className="alert alert-success">{formSuccess}</div>
      ) : null}

      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() =>
          setGlobalMessage({ ...globalMessage, isActive: false })
        }
      />

      <div style={{ display: "flex", flexFlow: "row wrap" }}>
        <form
          onSubmit={handleSubmitForm}
          style={{ border: "1px solid black", padding: "16px" }}
        >
          <h2>Général</h2>
          <div className="form-group">
            <div>Dossier :&nbsp;</div>
            {Object.keys(formTypes).map(formTypeId => (
              <div key={`${formTypeId}_key`} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="typeId"
                  value={Number(formTypeId)}
                  checked={data.typeId === Number(formTypeId)}
                  onChange={handleChange}
                  id={`form_type_id_${formTypeId}`}
                />
                <label
                  className="form-check-label"
                  htmlFor={`form_type_id_${formTypeId}`}
                >
                  {formTypes[formTypeId].code}
                </label>
              </div>
            ))}
          </div>
          {NEED_SCHOOL_ID ? (
            <div className="form-group">
              <span>École :&nbsp;</span>
              <label htmlFor="schoolId-1">
                <input
                  id="schoolId-1"
                  type="radio"
                  name="schoolId"
                  value={1}
                  onChange={handleChange}
                  checked={data.schoolId === 1}
                  required
                />
                &nbsp;Francaise&nbsp;
              </label>
              <label htmlFor="schoolId-2">
                <input
                  id="schoolId-2"
                  type="radio"
                  name="schoolId"
                  value={2}
                  onChange={handleChange}
                  checked={data.schoolId === 2}
                  required
                />
                &nbsp;Internationale
              </label>
            </div>
          ) : null}

          {NEED_SCHOOL_ID && !isNil(data.schoolId) ? (
            <div className="form-group">
              <span>Classe :&nbsp;</span>

              {schoolGrades
                .filter((grade) => grade.schoolId === data.schoolId)
                .map(({ value, label }, key) => (
                    <label key={key}>
                      <input
                        type="radio"
                        id={value}
                        name="grade"
                        value={value}
                        onChange={handleChange}
                        checked={data.grade === value}
                      />
                      &nbsp;{label}&nbsp;&nbsp;
                    </label>
                ))
              }
            </div>
          ) : null}

          <div className="form-group">
            <label htmlFor="year">
              Année :&nbsp;
              <input
                id="year"
                type="number"
                name="year"
                value={data.year}
                min={new Date().getFullYear()}
                onChange={handleChange}
                step="1"
                required
              />
            </label>
            &nbsp;/&nbsp;
            {data.year + 1}
          </div>

          <div className="form-group">
            <div className="d-flex flex-column">
              <div className="pr-2 mb-2">Début de scolarité</div>
              <Select
                isDisabled={!canEditPeriodicity}
                value={data.startWeekId}
                onChange={option =>
                  setData({
                    ...data,
                    startWeekId: option,
                  })
                }
                options={weekOptions}
                placeholder="Semaine"
                noOptionsMessage={() => <span>Pas de résultats</span>}
                styles={{
                  container: provided => ({
                    ...provided,
                    flex: "1 1 0",
                  }),
                  option: provided => ({ ...provided, fontSize: ".92rem" }),
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex flex-column">
              <div className="pr-2 mb-2">Fin de scolarité</div>
              <Select
                isDisabled={!canEditPeriodicity}
                value={data.endWeekId}
                onChange={option =>
                  setData({
                    ...data,
                    endWeekId: option,
                  })
                }
                options={weekOptions}
                placeholder="Semaine"
                noOptionsMessage={() => <span>Pas de résultats</span>}
                styles={{
                  container: provided => ({
                    ...provided,
                    flex: "1 1 0",
                  }),
                  option: provided => ({ ...provided, fontSize: ".92rem" }),
                }}
              />
            </div>
          </div>

          <div className="form-group">
            <span>Remise :&nbsp;</span>
            <label htmlFor="hasDiscount-1">
              <input
                id="hasDiscount-1"
                type="radio"
                name="hasDiscount"
                value="true"
                onChange={handleChange}
                checked={data.hasDiscount}
                required
              />
              &nbsp;Oui&nbsp;
            </label>
            <label htmlFor="hasDiscount-2">
              <input
                id="hasDiscount-2"
                type="radio"
                name="hasDiscount"
                value="false"
                onChange={handleChange}
                checked={!data.hasDiscount}
                required
              />
              &nbsp;Non
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="discountAmount">
              Montant remise :&nbsp;
              <input
                id="discountAmount"
                type="number"
                name="discountAmount"
                step="0.01"
                min="0"
                onChange={handleChange}
                value={
                  data.discountAmount
                    ? data.discountAmount / 100
                    : data.discountAmount
                }
              />
              &nbsp;euros
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="discountMessage">
              Message remise :&nbsp;
              <input
                id="discountMessage"
                type="text"
                name="discountMessage"
                onChange={handleChange}
                value={data.discountMessage}
              />
            </label>
          </div>

          <button
            type="submit"
            disabled={formSave || !validateForm()}
            className="btn btn-success btn-sm"
          >
            Enregistrer
          </button>
        </form>

        {data.id ? (
          <div
            style={{
              border: "1px solid black",
              padding: "16px",
              marginLeft: "16px",
            }}
          >
            <h2>Gestion du statut : {getFormStateName(data.stateId)}</h2>
            <div className="row">
              <div className="col-sm-12 mb-4">
                <button
                    type="button"
                    disabled={data.stateId !== 4}
                    className="btn btn-primary btn-sm"
                    onClick={() => setConfirmSignFolderModal(true)}
                  >
                    Confirmer la signature du dossier
                </button>
              </div>
              <Modal
                isDisplayed={confirmSignFolderModal}
                size="m"
                onClose={() => setConfirmSignFolderModal(null)}
              >
                {confirmSignFolderModal ? (
                  <div>
                    <b className="mb-4">
                      Êtes vous sûr de signer le dossier ?
                    </b>
                    <div className="mt-3">
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={null}
                        onClick={handleConfirmSign}
                        className="btn btn-primary mr-2"
                      >
                        Valider
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={null}
                        onClick={() => setConfirmSignFolderModal(null)}
                        className="btn btn-secondary"
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                ) : null}
              </Modal>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="button"
                  disabled={data.stateId !== 2}
                  className="btn btn-primary btn-sm"
                  onClick={handleLockForm}
                >
                  Vérrouiller le dossier
                </button>
                <button
                  type="button"
                  disabled={data.stateId !== 1}
                  className="btn btn-primary btn-sm"
                  style={{ float: "right" }}
                  onClick={handleUnlockForm}
                >
                  Déverrouiller le dossier
                </button>
                <p>
                  <i>
                    * pensez à désactiver le dossier avant d'effectuer des
                    modifications
                  </i>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="button"
                  disabled={data.stateId !== 1 && data.stateId !== 3}
                  className="btn btn-success btn-sm"
                  onClick={handleValidateForm}
                  style={{ marginRight: "1rem" }}
                >
                  Valider le dossier
                </button>
                <button
                  type="button"
                  disabled={data.stateId === 1 || data.stateId === 2}
                  className="btn btn-danger btn-sm"
                  onClick={handleInValidateForm}
                >
                  Invalider le dossier
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <a
                  type="button"
                  className="btn btn-primary btn-sm"
                  href={adminFormAccesLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginTop: "1rem" }}
                >
                  Voir le dossier
                </a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <p>
                  Statut de l'acompte:{" "}
                  {data.depositPaymentStatus === "pending" ? (
                    <b>En attente</b>
                  ) : (
                    <b>Versé</b>
                  )}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )

  const Table = () => (
    <>
      <h2>Dossiers</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Année</th>
            <th>École</th>
            <th>Statut</th>
            <th>
              {rolePermissions[PERMISSIONS.PERM_STUDENTS_CREATE] ? (
                <button
                  className="btn btn-success btn-sm"
                  type="button"
                  onClick={handleSwitchToForm}
                >
                  Ouvrir un dossier
                </button>
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody>
          {formsList.length
            ? formsList.map((form, key) => (
                <tr key={key}>
                  <td>
                    {form.year}/{form.year + 1}
                  </td>
                  <td>
                    {form.schoolId === 1 ? "Francaise" : "Internationale"}
                  </td>
                  <td>{getFormStateName(form.stateId)}</td>
                  <td>
                    {rolePermissions[PERMISSIONS.PERM_STUDENTS_UPDATE] ? (
                      <button
                        className="btn btn-primary btn-sm mr-4"
                        type="button"
                        onClick={() => handleFormEditClick(form)}
                      >
                        Éditer
                      </button>
                    ) : null}
                    {form.stateId === 5 && (
                      <button
                        className="btn btn-secondary btn-sm"
                        type="button"
                        onClick={() => handleMedicalExport(form)}
                      >
                        Export du dossier médical
                      </button>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      
    </>
  )

  return subview === "form" ? Form() : Table()
}
StudentsForms.propTypes = {}

export default StudentsForms
