import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

// Components
import Routes from "./Routes"

// Context
import { AppProvider } from "./AppContext"
import { KeycloakAuthProvider } from './components/templates/KeycloakAuthProvider';

// utils
import "./i18n-legacy"

function App() {
  return (
    <KeycloakAuthProvider>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>
    </KeycloakAuthProvider>
  )
}

export default App
