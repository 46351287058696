import React, { useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

// Components
import Spinner from "../../../../../components/atoms/Spinner/Spinner"

import style from "./CardForm.module.css"

const CardForm = ({ paymentToken, clientData }) => {
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentError, setPaymentError] = useState(null)

  const handleSubmit = async event => {
    try {
      event.preventDefault()

      if (!stripe || !elements) return

      const cardElement = elements.getElement(CardElement)

      setPaymentLoading(true)

      const payment = await stripe.confirmCardPayment(paymentToken, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${clientData.firstname} ${clientData.lastname}`,
            email: clientData.email,
          },
        },
      })

      if (payment.error) {
        setPaymentError(payment.error.message)
        setPaymentLoading(false)
      } else {
        setPaymentError(null)
        setTimeout(() => {
          setPaymentLoading(false)
          history.push({
            pathname: `/client/${clientData.id}/orders/list`,
            state: {
              submitType:
                "Commande régularisée avec succès (paiement par stripe)",
              success: true,
            },
          })
        }, 3500)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {paymentError ? (
        <div className="alert alert-danger" role="alert">
          {paymentError}
        </div>
      ) : null}
      <form className={paymentLoading ? "d-none" : ""} onSubmit={handleSubmit}>
        <div className={style.paymentFormWrapper}>
          <div className="row mt-4">
            <div className="col-md-12">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                onReady={null}
                onChange={null}
                onBlur={null}
                onFocus={null}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                !stripe || !paymentToken /* || si input carte non terminé */
              }
            >
              Pay
            </button>
          </div>
        </div>
      </form>
      {paymentLoading ? <Spinner /> : null}
    </>
  )
}
CardForm.propTypes = {
  paymentToken: PropTypes.string,
  clientData: PropTypes.shape().isRequired,
}
CardForm.defaultProps = {
  paymentToken: null,
}

export default CardForm
