// Hooks
import useApi from "../useApi"

// Utils & misc
import { FID_HOSTNAME } from "../../../constants/constants"
import objectToURLParam from "../../../utils/objectToURLParam"

const useFidEmployees = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${FID_HOSTNAME}/api/v1`

  const getFidEmployees = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${BASE_URL}/employees${params}`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const createFidEmployee = est =>
    rawRequest("POST", `${BASE_URL}/employees`, est, {
      authKey: AUTHORIZATION,
    })

  const updateFidEmployee = (estId, est) =>
    rawRequest("PUT", `${BASE_URL}/employees/${estId}`, est, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getFidEmployees,
      updateFidEmployee,
      createFidEmployee,
    },
  ]
}

export default useFidEmployees
