import React, { useCallback, useState } from "react"
import { saveAs } from "file-saver"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Spinner from "../../../components/atoms/Spinner/Spinner"

// Hooks
import useExportsApi from "../../../hooks/Api/useExportsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils
import style from "./Export.module.css"
import cn from "../../../utils/cn"

const permissions = [
  PERMISSIONS.PERM_INVOICES_EXPORT_PDF,
  PERMISSIONS.PERM_CREDIT_NOTES_EXPORT_PDF,
  PERMISSIONS.PERM_INVOICES_EXPORT_CSV,
  PERMISSIONS.PERM_CREDITS_NOTES_EXPORT_CSV,
  PERMISSIONS.PERM_PAYMENTS_EXPORT,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_FULL,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_ANONYMOUS, // admin version
  PERMISSIONS.PERM_BOOKINGS_EXPORT_COMMERCIAL,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_GEOLOCALIZED,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_GEOLOCALIZED_ANONYMOUS,
  PERMISSIONS.PERM_CARTS_EXPORT,
  PERMISSIONS.PERM_CARTS_EXPORT_WITH_CLIENTS_DATA, // admin version
  PERMISSIONS.PERM_ORDERS_EXPORT,
  PERMISSIONS.PERM_ORDERS_PAYMENTS_MEANS_EXPORT,
  PERMISSIONS.PERM_SALES_SHEET_EXPORT,
  PERMISSIONS.PERM_YIELD_INCOME_EXPORT,
  PERMISSIONS.PERM_REPORTS_EVALUATIONS_DOWNLOAD,
]

const bookingTypes = {
  FULL: "full",
  ANONYMOUS: "anonymous",
  COMMERCIAL: "commercial",
  GEOLOCALIZED: "geolocalized",
  GEOLOCALIZED_ANONYMOUS: "geolocalized_anonymous",
}

const Export = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2019;

  /**
   * @description isNearEndYear - Display the next year if we are near the end of the current year
   * @see #98007
   */
  const isNearEndYear =  new Date() >= new Date(currentYear, 9, 24)
  const years = Array.from({length: currentYear - startYear + (isNearEndYear ? 2 : 1)},
     (_, i) => i + startYear)?.map((y) => y.toString());

  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [exportLoading, setExportLoading] = useState([])

  const [, , , { getPdfInvoicesExport }] = useExportsApi()
  const [, , , { getCsvInvoicesExport }] = useExportsApi()
  const [, , , { getCsvPaymentsExport }] = useExportsApi()
  const [, , , { getCsvBookingsExport }] = useExportsApi()
  const [, , , { getOrdersRecapExport }] = useExportsApi()
  const [, , , { getCsvCartsExport }] = useExportsApi()
  const [, , , { getPaymentMeansExport }] = useExportsApi()
  const [, , , { getSalesSheetExport }] = useExportsApi()
  const [, , , { getYieldIncome }] = useExportsApi()
  const [, , , { getReportsEvaluations }] = useExportsApi()

  const savedPdfInvoicesExport = useCallback(getPdfInvoicesExport, [])
  const savedCsvInvoicesExport = useCallback(getCsvInvoicesExport, [])
  const savedCsvPaymentsExport = useCallback(getCsvPaymentsExport, [])
  const savedCsvBookingsExport = useCallback(getCsvBookingsExport, [])
  const savedOrdersRecapExport = useCallback(getOrdersRecapExport, [])
  const savedCsvCartsExport = useCallback(getCsvCartsExport, [])
  const savedPaymentMeansExport = useCallback(getPaymentMeansExport, [])
  const savedSalesSheetExport = useCallback(getSalesSheetExport, [])
  const savedYieldIncomeExport = useCallback(getYieldIncome, [])
  const savedReportsEvaluations = useCallback(getReportsEvaluations, [])

  const handleExportFinished = (typeExport, typeId, year, exportType) => {
    setExportLoading(
      exportLoading.filter(
        l =>
          l.typeExport !== typeExport &&
          l.typeId !== typeId &&
          l.year !== year &&
          (typeof exportType === "string" ? l.exportType !== exportType : true)
      )
    )
  }

  const isExportLoading = (typeExport, typeId, year, exportType = null) => {
    const found = exportLoading.find(
      l =>
        l.typeExport === typeExport &&
        l.typeId === typeId &&
        l.year === year &&
        (typeof exportType === "string" ? l.exportType === exportType : true)
    )
    return !!found
  }

  /**
   *
   * @param {String} typeExport
   * @param {Object} params {typeId?: string, year?: string}
   */
  const handleExport = (
    typeExport,
    { typeId, year, exportType, anonymous }
  ) => {
    setExportLoading([
      ...exportLoading,
      { typeExport, typeId, year, exportType },
    ])

    switch (typeExport) {
      case "pdfinvoices":
        savedPdfInvoicesExport(typeId, year).then(res => {
          if (res && res.data) saveAs(res.data, "pdfinvoices.zip")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "csvinvoices":
        savedCsvInvoicesExport(typeId, year).then(res => {
          if (res && res.data) saveAs(res.data, "csvinvoices.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "csvpayments":
        savedCsvPaymentsExport(typeId).then(res => {
          if (res && res.data) saveAs(res.data, "csvpayments.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "csvbookings":
        savedCsvBookingsExport(year, { type: exportType }).then(res => {
          if (res && res.data) saveAs(res.data, "csvbookings.xlsx")
          handleExportFinished(typeExport, typeId, year, exportType)
        })
        break
      case "ordersrecap":
        savedOrdersRecapExport(year).then(res => {
          if (res && res.data) saveAs(res.data, "ordersrecap.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "csvcarts":
        savedCsvCartsExport(year, { anonymous }).then(res => {
          if (res && res.data) saveAs(res.data, "csvcarts.xlsx")
          handleExportFinished(typeExport, typeId, year, anonymous)
        })
        break
      case "paymentmeans":
        savedPaymentMeansExport(year).then(res => {
          if (res && res.data) saveAs(res.data, "paymentmeans.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "salessheet":
        savedSalesSheetExport(year).then(res => {
          if (res && res.data) saveAs(res.data, "salessheet.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "yieldincome":
        savedYieldIncomeExport(year).then(res => {
          if (res && res.data) saveAs(res.data, "yieldsincome.xlsx")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      case "reportsevaluations":
        savedReportsEvaluations(year).then(res => {
          if (res && res.data) saveAs(res.data, "reportsevaluations.csv")
          handleExportFinished(typeExport, typeId, year)
        })
        break
      default:
        throw Error("wrong export type")
    }
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Export</h1>
      </div>
      <div className="row">
        <table className={cn([style.exportTable, "mb-4"])}>
          <thead>
            <tr>
              <th />
              {years.map(year => (
                <th key={year}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rolePermissions[PERMISSIONS.PERM_INVOICES_EXPORT_PDF] ? (
              <tr>
                <td>PDF des factures</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={isExportLoading("pdfinvoices", 1, year)}
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("pdfinvoices", { typeId: 1, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("pdfinvoices", 1, year) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_CREDIT_NOTES_EXPORT_PDF] ? (
              <tr>
                <td>PDF des avoirs</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={isExportLoading("pdfinvoices", 2, year)}
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("pdfinvoices", { typeId: 2, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("pdfinvoices", 2, year) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_INVOICES_EXPORT_CSV] ? (
              <tr>
                <td>CSV des factures</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={isExportLoading("csvinvoices", 1, year)}
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvinvoices", { typeId: 1, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("csvinvoices", 1, year) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_CREDITS_NOTES_EXPORT_CSV] ? (
              <tr>
                <td>CSV des avoirs</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={isExportLoading("csvinvoices", 2, year)}
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvinvoices", { typeId: 2, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("csvinvoices", 2, year) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_PAYMENTS_EXPORT] ? (
              <tr>
                <td>CSV des paiements</td>
                <td colSpan={years.length - 1}>Rapport unique de 2019 à {years[years.length - 1]}</td>
                <td>
                  <button
                    disabled={isExportLoading("csvpayments", 1, undefined)}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleExport("csvpayments", { typeId: 1 })}
                  >
                    Télécharger{" "}
                    {isExportLoading("csvpayments", 1, undefined) && (
                      <Spinner />
                    )}
                  </button>
                </td>
              </tr>
            ) : null}
            {/* CSV des inscriptions */}
            {rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_ANONYMOUS] ? (
              <tr>
                <td>CSV des inscriptions</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvbookings",
                            undefined,
                            year,
                            bookingTypes.ANONYMOUS
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvbookings", {
                            year,
                            exportType: bookingTypes.ANONYMOUS,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvbookings",
                          undefined,
                          year,
                          bookingTypes.ANONYMOUS
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des inscriptions (admin) */}
            {rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_FULL] ? (
              <tr>
                <td>CSV des inscriptions Admin</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvbookings",
                            undefined,
                            year,
                            bookingTypes.FULL
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvbookings", {
                            year,
                            exportType: bookingTypes.FULL,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvbookings",
                          undefined,
                          year,
                          bookingTypes.FULL
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des inscriptions (commercial) */}
            {rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_COMMERCIAL] ? (
              <tr>
                <td>CSV des inscriptions Commercial</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvbookings",
                            undefined,
                            year,
                            bookingTypes.COMMERCIAL
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvbookings", {
                            year,
                            exportType: bookingTypes.COMMERCIAL,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvbookings",
                          undefined,
                          year,
                          bookingTypes.COMMERCIAL
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des inscriptions (geolocalized) */}
            {rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_GEOLOCALIZED] ? (
              <tr>
                <td>CSV des inscriptions Géolocalisé</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvbookings",
                            undefined,
                            year,
                            bookingTypes.GEOLOCALIZED
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvbookings", {
                            year,
                            exportType: bookingTypes.GEOLOCALIZED,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvbookings",
                          undefined,
                          year,
                          bookingTypes.GEOLOCALIZED
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des inscriptions (geolocalized) */}
            {rolePermissions[
              PERMISSIONS.PERM_BOOKINGS_EXPORT_GEOLOCALIZED_ANONYMOUS
            ] ? (
              <tr>
                <td>CSV des inscriptions Géolocalisé (Anonymisé)</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvbookings",
                            undefined,
                            year,
                            bookingTypes.GEOLOCALIZED_ANONYMOUS
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvbookings", {
                            year,
                            exportType: bookingTypes.GEOLOCALIZED_ANONYMOUS,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvbookings",
                          undefined,
                          year,
                          bookingTypes.GEOLOCALIZED_ANONYMOUS
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des paniers (admin) */}
            {rolePermissions[
              PERMISSIONS.PERM_CARTS_EXPORT_WITH_CLIENTS_DATA
            ] ? (
              <tr>
                <td>CSV des paniers Admin</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvcarts",
                            undefined,
                            year,
                            bookingTypes.FULL
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvcarts", {
                            year,
                            anonymous: false,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvcarts",
                          undefined,
                          year,
                          bookingTypes.FULL
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {/* CSV des paniers (standard) */}
            {rolePermissions[PERMISSIONS.PERM_CARTS_EXPORT] ? (
              <tr>
                <td>CSV des paniers</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading(
                            "csvcarts",
                            undefined,
                            year,
                            bookingTypes.ANONYMOUS
                          )
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("csvcarts", {
                            year,
                            anonymous: true,
                          })
                        }
                      >
                        Télécharger
                        {isExportLoading(
                          "csvcarts",
                          undefined,
                          year,
                          bookingTypes.ANONYMOUS
                        ) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_ORDERS_EXPORT] ? (
              <tr>
                <td>CSV des commandes</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={isExportLoading("ordersrecap", 2, year)}
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("ordersrecap", { typeId: 2, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("ordersrecap", 2, year) && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_ORDERS_PAYMENTS_MEANS_EXPORT] ? (
              <tr>
                <td>Moyens de paiement</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading("paymentmeans", 2, year)
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          handleExport("paymentmeans", { typeId: 2, year })
                        }
                      >
                        Télécharger
                        {isExportLoading("paymentmeans", 2, year) && (
                          <Spinner />
                        )}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
            {rolePermissions[PERMISSIONS.PERM_SALES_SHEET_EXPORT] ? (
              <tr>
                <td>Fiche des commerciaux</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading("salessheet", undefined, year)
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleExport("salessheet", { year })}
                      >
                        Télécharger
                        {isExportLoading("salessheet", undefined, year) && (
                          <Spinner />
                        )}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}

            {rolePermissions[PERMISSIONS.PERM_YIELD_INCOME_EXPORT] ? (
              <tr>
                <td>XLSX des revenus générés par le yield des hébergements et stages</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading("yieldincome", undefined, year)
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleExport("yieldincome", { year })}
                      >
                        Télécharger
                        {isExportLoading("yieldincome", undefined, year) && (
                          <Spinner />
                        )}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}

            {rolePermissions[PERMISSIONS.PERM_REPORTS_EVALUATIONS_DOWNLOAD] ? (
              <tr>
                <td>Rapports évaluations (Coach)</td>
                {years.map(year => {
                  return (
                    <td key={year}>
                      <button
                        disabled={
                          isExportLoading("reportsevaluations", undefined, year)
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleExport("reportsevaluations", { year })}
                      >
                        Télécharger
                        {isExportLoading("reportsevaluations", undefined, year) && (
                          <Spinner />
                        )}
                      </button>
                    </td>
                  )
                })}
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </WithMenu>
  )
}
export default Export
