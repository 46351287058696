import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

// Style
import style from "./Tabs.module.css"

const Tabs = ({tabs, updateActiveTab, className}) => {
  return (
    <ul className={`nav nav-tabs ${className} ${style.wrapper}`}>
      {tabs.map((tab, index) => (
        <Link to={tab.url} key={index} onClick={() => updateActiveTab(tab)} className={`nav-link ${tab.isActive ? style.active : ''} ${tab.disabled ? 'disabled' : ''} ${style.tab}`}>{tab.title}</Link>
      ))}
    </ul>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  updateActiveTab: PropTypes.func,
  className: PropTypes.string
}

export default Tabs