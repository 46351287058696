import React from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import { useTranslation } from "react-i18next"

// Utils & Misc
import cn from "../../../../utils/cn"

// Style
import style from "../../TextField/TextField.module.css"

export const Select = ({
  label,
  id,
  name,
  className,
  error,
  onChange,
  value,
  options,
  ...propsLeft
}) => {
  const { t } = useTranslation()
  const customSelectStyle = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      // border: "none",
      // borderRadius: "0",
      borderRadius: ".25rem",
      border: "1px solid rgba(0,0,0,.125)",
      // borderBottom: state.isFocused
      //   ? "0.125rem solid #3885dc"
      //   : "0.0625rem solid #2b2b2b",
      borderColor: error && "#f13929 !important",
      fontSize: "1.25rem",
      maxHeight: "2.2rem",
      minHeight: "0",
      padding: "0",
      // paddingBottom: '0.5rem',
      outline: "none",
      boxShadow: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "transform 0.3s ease-in-out",
      transform: state.isFocused && "rotate(180deg)",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    input: base => ({
      ...base,
      maxHeight: "2rem",
      paddingTop: "0",
      margin: "0",
    }),
    placeholder: base => ({
      ...base,
      whiteSpace: "nowrap",
    }),
    menu: base => ({
      ...base,
      zIndex: "3",
    }),
    menuPortal: base => ({
      ...base,
      zIndex: "3",
    }),
  }
  const handleOnChange = selected => {
    // Mimic the default input return
    if (onChange) {
      return onChange(
        {
          target: {
            type: "select",
            value: selected.value,
            id,
            name,
          },
        },
        selected
      )
    }
    return selected
  }
  return (
    <label
      htmlFor={id}
      className={cn([
        style.group,
        className,
        style.active,
        error ? style.hasError : "",
      ])}
    >
      <ReactSelect
        styles={customSelectStyle}
        inputId={id}
        onChange={handleOnChange}
        value={value ? options.filter(opt => opt.value === value) : undefined}
        options={options}
        placeholder={t("form.select.placeholder")}
        {...propsLeft}
      />
      {label ? (
        <span className={style.label}>
          {label}
          {error ? ` - ${error}` : null}
        </span>
      ) : null}
    </label>
  )
}
Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

Select.defaultProps = {
  id: null,
  label: null,
  error: null,
  className: null,
  onChange: undefined,
  value: null,
}

export default Select
