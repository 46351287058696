/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"
import { format } from "date-fns"

// Components
import Spinner from "../../../../atoms/Spinner/Spinner"
import { TextInput } from "../../../../atoms/Inputs/Inputs"
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"

// Hooks
import useFidLevels from "../../../../../hooks/Api/Fid/useFidLevels"

// Utils
import cn from "../../../../../utils/cn"

// Data
import { initialLevel } from "../form-initial-values"

import style from "./FidAdminLevels.module.css"

const EDITING = "editing"

// COMPONENT
const FidAdminLevels = () => {
  const [levels, setLevels] = useState([])
  const [levelsEdition, setLevelsEdition] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [{ getFidLevels, createFidLevel, updateFidLevel }] = useFidLevels()

  const getFidLevelsMem = useCallback(getFidLevels, [])

  const handleCRUDRequestError = error => {
    if (error.response) {
      switch (error.response.status) {
        case 500: {
          setGlobalMessage({
            ...globalMessage,
            isActive: true,
            message: (
              <span>Une erreur est survenue au moment de la soumission</span>
            ),
            className: "alert alert-danger",
          })
          break
        }
        case 400:
          {
            const errorsList =
              error.response.data && error.response.data.errors
                ? Object.keys(error.response.data.errors).map(errorName => ({
                    field: errorName,
                    message: error.response.data.errors[errorName],
                  }))
                : null

            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <ul className="m-0">
                  {errorsList
                    ? errorsList.map(err => (
                        <li>
                          <b>{err.field}: </b>
                          {err.message || "Erreur au niveau de ce champ"}
                        </li>
                      ))
                    : "Erreur au niveau du formulaire"}
                </ul>
              ),
              className: "alert alert-danger",
            })
          }
          break
        default:
          break
      }
    }
  }
  const handleMessageClose = e => {
    e.preventDefault()

    setGlobalMessage({ ...globalMessage, isActive: false })
  }

  const fetchInitialData = useCallback(async () => {
    try {
      const levResp = await getFidLevelsMem({
        withoutDeleted: 1,
      })

      const levList = Object.keys(levResp.data.levels).map(
        levId => levResp.data.levels[levId]
      )

      setLevels(levList)
      setLevelsEdition(
        levList.map(lev => ({
          ...lev,
          isEditing: false,
          loading: false,
          confirmDelete: false,
        }))
      )
      setDataReady(true)
    } catch (error) {
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>Une erreur est survenue au moment de charger les données</span>
        ),
        className: "alert alert-danger",
      })
    }
  }, [getFidLevelsMem, globalMessage])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const setField = (type, fieldId, payload) => {
    if (type === EDITING) {
      setLevelsEdition(
        levelsEdition.map(lev => {
          if (lev.id === fieldId) {
            return { ...lev, ...payload }
          }
          return lev
        })
      )
    } else {
      setLevels(
        levels.map(lev => {
          if (lev.id === fieldId) {
            return { ...lev, ...payload }
          }
          return lev
        })
      )
    }
  }

  const toggleEditMode = (e, id, state) => {
    if (e) e.preventDefault()

    setLevelsEdition(
      levelsEdition.map(lev =>
        lev.id === id ? { ...lev, isEditing: state, confirmDelete: false } : lev
      )
    )
  }

  const submitUpdate = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const lev = levelsEdition.find(levE => levE.id === fieldId)
    const { isEditing, deletedAt, loading, id: levId, ...payload } = lev

    try {
      // TODO: remove this line once fixed
      payload.threshold = Number(payload.threshold)

      const newLevResp = await updateFidLevel(levId, payload)
      setLevels(levels.map(e => (e.id === levId ? newLevResp.data : e)))
      setLevelsEdition(
        levelsEdition.map(e =>
          e.id === levId
            ? {
                ...newLevResp.data,
                loading: false,
                isEditing: false,
                confirmDelete: false,
              }
            : e
        )
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong> Le palier a été modifié.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const deleteLevel = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const lev = levelsEdition.find(levE => levE.id === fieldId)
    const { isEditing, loading, confirmDelete, id: levId, ...payload } = lev

    try {
      payload.deletedAt = format(new Date(), "yyyy-MM-dd HH:mm:ss")
      // TODO: remove this line once fixed
      payload.threshold = Number(payload.threshold)

      const delLevResp = await updateFidLevel(levId, payload)
      setLevels(levels.map(e => (e.id === levId ? delLevResp.data : e)))
      setLevelsEdition(levelsEdition.filter(level => level.id !== levId))
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès: </strong> Le palier a été supprimé.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const handleLevelFieldChange = (fieldId, fieldName, value) => {
    setField(EDITING, fieldId, {
      [fieldName]: value,
    })
  }

  const setConfirmDelete = (e, levId, value) => {
    e.preventDefault()
    setField(EDITING, levId, {
      confirmDelete: value,
    })
  }

  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialLevel,
    onSubmit: async submitValues => {
      try {
        const newLevResp = await createFidLevel(submitValues)
        setLevels([...levels, newLevResp.data])
        setLevelsEdition([
          ...levelsEdition,
          {
            ...newLevResp.data,
            loading: false,
            isEditing: false,
          },
        ])
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> Le palier a été ajouté.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  const columns = [
    {
      id: 1,
      header: "Palier",
      inputType: "text",
      cell: (lev, edition) => {
        if (!edition.isEditing) {
          return lev.name
        }
        return (
          <TextInput
            id="name"
            name="name"
            placeholder="Nom du palier"
            type="text"
            onChange={e =>
              handleLevelFieldChange(lev.id, "name", e.target.value)
            }
            value={edition.name}
          />
        )
      },
    },
    {
      id: 2,
      header: "Soldes",
      cell: (lev, edition) => {
        if (!edition.isEditing) {
          return lev.threshold
        }
        return (
          <TextInput
            id="threshold"
            name="threshold"
            placeholder="Solde"
            type="number"
            onChange={e =>
              handleLevelFieldChange(
                lev.id,
                "threshold",
                Number(e.target.value)
              )
            }
            value={edition.threshold}
          />
        )
      },
    },
  ]

  return (
    <div>
      {/* CREATE LEVEL */}
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={handleMessageClose}
      />
      <div className={cn(["card mb-4"])}>
        <div className="card-body">
          <h5 className="card-title">Création nouveau palier</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Nom du palier*"
                  id="levName"
                  placeholder="Nom du palier"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Solde d'acquisition*"
                  id="threshold"
                  placeholder="Solde d'acquisition"
                  type="number"
                  name="threshold"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.threshold}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12 col-md-12 pb-12">
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={false}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* LEVEL LIST */}
      {dataReady ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 pb-12">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      {columns.map(col => (
                        <th scope="col" key={`table_header_${col.id}`}>
                          {col.header}
                        </th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {levels
                      // TODO: Remove this once fixed
                      .map(lev => ({
                        ...lev,
                        threshold: Number(lev.threshold),
                      }))
                      .sort((a, b) => (a.threshold < b.threshold ? -1 : 1))
                      .map(lev => {
                        const edition = levelsEdition.length
                          ? levelsEdition.find(levE => levE.id === lev.id)
                          : null

                        if (edition)
                          return (
                            <tr key={`row_${lev.id}`}>
                              {columns.map(col => {
                                return (
                                  <td
                                    key={`column_${col.id}`}
                                    className="align-middle"
                                  >
                                    {col.cell(lev, edition)}
                                  </td>
                                )
                              })}
                              <td className="align-middle">
                                <div
                                  className="btn-group btn-group-sm float-right"
                                  role="group"
                                  aria-label="Edit admin"
                                >
                                  {!edition.isEditing ? (
                                    <span>
                                      {!edition.confirmDelete ? (
                                        <a
                                          onClick={e =>
                                            toggleEditMode(e, lev.id, true)
                                          }
                                          href="/"
                                        >
                                          Modifier
                                        </a>
                                      ) : null}
                                      <span
                                        className={cn([
                                          "ml-4",
                                          !edition.confirmDelete
                                            ? style.delete
                                            : "",
                                        ])}
                                      >
                                        {edition.confirmDelete ? (
                                          <b className={style.confirmLabel}>
                                            Êtes vous sûr ?
                                          </b>
                                        ) : null}
                                        <a
                                          className="text-danger"
                                          onClick={
                                            edition.confirmDelete
                                              ? e => deleteLevel(e, lev.id)
                                              : e =>
                                                  setConfirmDelete(
                                                    e,
                                                    lev.id,
                                                    true
                                                  )
                                          }
                                          href="/"
                                        >
                                          {edition.confirmDelete
                                            ? "Oui"
                                            : "Supprimer"}
                                        </a>
                                        {edition.confirmDelete ? (
                                          <a
                                            className="ml-4 mr-2 text-primary"
                                            onClick={e =>
                                              setConfirmDelete(e, lev.id, false)
                                            }
                                            href="/"
                                          >
                                            Annuler
                                          </a>
                                        ) : null}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <a
                                        className="mr-4"
                                        onClick={e => submitUpdate(e, lev.id)}
                                        href="/"
                                      >
                                        Valider
                                      </a>
                                      <a
                                        className={cn([
                                          "text-danger mr-2",
                                          style.delete,
                                        ])}
                                        onClick={e =>
                                          toggleEditMode(e, lev.id, false)
                                        }
                                        href="/"
                                      >
                                        Annuler
                                      </a>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

FidAdminLevels.propTypes = {}
FidAdminLevels.defaultProps = {}

export default FidAdminLevels
