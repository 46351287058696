import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import DatePicker from "react-datepicker"
import { pick } from "lodash"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

import "react-datepicker/dist/react-datepicker.css"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import { TextInput } from "../../../../components/atoms/Inputs/Inputs"

// Hooks
import useClientShuttlesApi from "../../../../hooks/Api/useClientShuttlesApi"
import useSexesApi from "../../../../hooks/Api/useSexesApi"
import useCountriesApi from "../../../../hooks/Api/useCountriesApi"
import useLanguagesApi from "../../../../hooks/Api/useLanguagesApi"
import useLanguagesLevelsApi from "../../../../hooks/Api/useLanguagesLevelsApi"
import useSportsApi from "../../../../hooks/Api/useSportsApi"
import useClientsApi from "../../../../hooks/Api/useClientsApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

// Utils & misc
import cn from "../../../../utils/cn"
import ShuttleEditModel from "./shuttle-edit-model"

import styles from "./ShuttlesEdit.module.css"

const permissions = [PERMISSIONS.PERM_SHUTTLES_UPDATE]

countries.registerLocale(countriesFR)

const ShuttlesEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const history = useHistory()

  // Shuttles API Hooks
  const [
    ,
    shuttleData,
    shuttleError,
    { getClientShuttle },
  ] = useClientShuttlesApi()
  const [, sexesData, , { getSexes }] = useSexesApi()
  const [, countriesData, , { getCountries }] = useCountriesApi()
  const [, languagesData, , { getLanguages }] = useLanguagesApi()
  const [, sportsData, , { getSports }] = useSportsApi()
  const [, clientData, , { getClientWithId }] = useClientsApi()

  const [
    ,
    languagesLevelsData,
    ,
    { getLanguagesLevels },
  ] = useLanguagesLevelsApi()

  const [
    updateShuttleState,
    ,
    updateShuttleError,
    { updateClientShuttle },
  ] = useClientShuttlesApi()

  const savedGetShuttleId = useCallback(getClientShuttle, [])
  const savedGetSexes = useCallback(getSexes, [])
  const savedGetCountries = useCallback(getCountries, [])
  const savedGetLanguages = useCallback(getLanguages, [])
  const savedGetLanguagesLevels = useCallback(getLanguagesLevels, [])
  const savedGetSports = useCallback(getSports, [])
  const savedGetClientWithId = useCallback(getClientWithId, [])

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: shuttleData || {},
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      updateClientShuttle(
        match.params.shuttleType + match.params.shuttleId,
        pick(valuesSubmit, Object.keys(ShuttleEditModel))
      ).then(() =>
        history.push({
          pathname: `/client/${match.params.clientId}/shuttles/list`,
          state: { submitType: "edit", success: true },
        })
      )
    },
  })

  useEffect(() => {
    savedGetShuttleId(match.params.shuttleType + match.params.shuttleId)
    savedGetSexes()
    savedGetCountries()
    savedGetLanguages()
    savedGetLanguagesLevels()
    savedGetSports()
    savedGetClientWithId(match.params.clientId)
  }, [
    savedGetShuttleId,
    savedGetSexes,
    savedGetCountries,
    savedGetLanguages,
    savedGetLanguagesLevels,
    savedGetSports,
    match.params.shuttleId,
    match.params.shuttleType,
    match.params.clientId,
    savedGetClientWithId,
  ])

  const backToList = () => {
    history.push({
      pathname: `/client/${match.params.clientId}/shuttles/list`,
      state: {},
    })
  }

  const isAllowedToEdit = rolePermissions[PERMISSIONS.PERM_SHUTTLES_UPDATE]

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {clientData
            ? `${clientData.firstname} ${clientData.lastname} / Navette / Éditer`
            : ""}
          {shuttleData && shuttleData.name ? ` / ${shuttleData.name}` : null}
        </h1>
      </div>

      <div className={cn(["mw-100", "w-75", "m-auto", styles.editSection])}>
        {values &&
        shuttleData &&
        sexesData &&
        countriesData &&
        languagesData &&
        sportsData &&
        languagesLevelsData ? (
          <form onSubmit={handleSubmit}>
            {shuttleError || updateShuttleError ? (
              <div className="alert alert-error" role="alert">
                {shuttleError || updateShuttleError}
              </div>
            ) : null}

            {/* GENERAL INFORMATION */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Informations</h5>

                {/* TRANSPORT TYPE */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <b>
                      <label>Type de transport</label>
                    </b>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        disabled={!isAllowedToEdit}
                        className="form-check-input"
                        type="radio"
                        name="location"
                        id="location-airport"
                        onChange={() => setFieldValue("location", "airport")}
                        checked={values.location === "airport"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="location-airport"
                      >
                        Aéroport de Nice
                      </label>
                    </div>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        disabled={!isAllowedToEdit}
                        className="form-check-input"
                        type="radio"
                        name="location"
                        id="location-station"
                        onChange={() => setFieldValue("location", "station")}
                        checked={values.location === "station"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="location-station"
                      >
                        Gare d'Antibes
                      </label>
                    </div>
                  </div>
                </div>

                {/* DATETIME */}
                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <label htmlFor="datetime">Start</label>
                    <div
                      className={cn(["input-group", styles.datePickerWrapper])}
                    >
                      <DatePicker
                        disabled={!isAllowedToEdit}
                        className={cn(["form-control"])}
                        // dateFormat="dd/MM/yyyy"
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        selected={Date.parse(values.date)}
                        onChange={date => {
                          setFieldValue(
                            "date",
                            format(date, "yyyy-MM-dd HH:mm:ss")
                          )
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="heure"
                      />
                      <div className="input-group-append">
                        <span
                          role="img"
                          aria-label="calendar"
                          className="input-group-text"
                        >
                          📅
                        </span>
                      </div>
                    </div>
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                </div>
                <div className="form-row">
                  {/* PROVENANCE */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      required
                      label="Provenance/Destination"
                      id="provenance"
                      name="provenance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.provenance}
                    />
                  </div>

                  {/* COMPANY */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      required
                      label="Compagnie"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                  </div>
                </div>

                <div className="form-row">
                  {/* No VOL */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      required
                      label="N° de vol / train"
                      id="number"
                      name="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.number}
                    />
                  </div>
                </div>

                {/* TERMINAL */}
                {values.location === "airport" && (
                  <div className="form-row">
                    <div className="col-sm-12 col-md-12 pb-2">
                      <b>
                        <label>Terminal</label>
                      </b>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          disabled={!isAllowedToEdit}
                          className="form-check-input"
                          type="radio"
                          name="terminal"
                          id="terminal-1"
                          onChange={() => setFieldValue("terminal", "1")}
                          checked={values.terminal === "1"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terminal-1"
                        >
                          Terminal 1
                        </label>
                      </div>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          disabled={!isAllowedToEdit}
                          className="form-check-input"
                          type="radio"
                          name="terminal"
                          id="terminal-2"
                          onChange={() => setFieldValue("terminal", "2")}
                          checked={values.terminal === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terminal-2"
                        >
                          Terminal 2
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {/* TERMINAL */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <b>
                      <label>
                        Votre enfant est accompagné par la compagnie ?
                      </label>
                    </b>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        disabled={!isAllowedToEdit}
                        className="form-check-input"
                        type="radio"
                        name="isAccompanied"
                        id="isAccompanied-1"
                        onChange={() => setFieldValue("isAccompanied", true)}
                        checked={values.isAccompanied === true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAccompanied-1"
                      >
                        Oui
                      </label>
                    </div>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        disabled={!isAllowedToEdit}
                        className="form-check-input"
                        type="radio"
                        name="isAccompanied"
                        id="isAccompanied-2"
                        onChange={() => setFieldValue("isAccompanied", false)}
                        checked={values.isAccompanied === false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isAccompanied-2"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="float-right mb-4">
              {!isAllowedToEdit ? (
                <button
                  onClick={backToList}
                  type="button"
                  className="btn btn-secondary mr-2"
                >
                  Revenir à la liste
                </button>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={updateShuttleState === "loading" || !isAllowedToEdit}
              >
                {updateShuttleState === "loading" ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Sauvegarder
              </button>
            </div>
          </form>
        ) : (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </WithMenu>
  )
}

ShuttlesEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ShuttlesEdit
