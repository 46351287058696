import React, { useEffect, useCallback, useState, useMemo } from "react"

// Utils
import { Link, useLocation } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"
import { sortBy } from "lodash"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useThemesApi from "../../../hooks/Api/useThemesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./ThemesList.module.css"

const permissions = [
  PERMISSIONS.PERM_THEMES_CREATE,
  PERMISSIONS.PERM_THEMES_UPDATE,
  PERMISSIONS.PERM_THEMES_DELETE,
]

const TagsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [requestState, payload, error, { getThemes }] = useThemesApi({ trigger: true })
  const [, , , { deleteTheme }] = useThemesApi()
  const [, , , { reorderThemes }] = useThemesApi()

  const savedGetThemes = useCallback(getThemes, [])
  const { state } = useLocation()

  const [confirmDelete, setConfirmDelete] = useState(null)

  useEffect(() => {
    savedGetThemes()
  }, [savedGetThemes])

  const handleThemeDeletion = async id => {
    await deleteTheme(id)
    savedGetThemes()
  }

  const themesList = useMemo(() => sortBy(payload?.themes || [], ["order"]).reverse(), [payload]);

  const onDragEnd = async result => {
    if (!result?.destination || !result?.source) return;

    let destination = null;
    if (result.source.index > result.destination?.index) {
      destination = result.destination.index - 1 < 0 ? null : themesList[result.destination.index - 1].id;
    } else {
      destination = themesList[result.destination.index].id;
    }

    await reorderThemes(themesList[result.source.index].id, destination)
    savedGetThemes()
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Thèmes</h1>
        {rolePermissions[PERMISSIONS.PERM_THEMES_CREATE] ? (
          <Link className="btn btn-success" to="/themes/new">
            Nouveau thème
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {requestState === "loading" && !payload ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Nom",
                  accessor: "name",
                  Cell: ({ cell: { value } }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit theme"
                    >
                      {value[1]}
                    </div>
                  ),
                },
                {
                  Header: "",
                  accessor: "#1",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      },
                    },
                  }) => (
                    <div
                      className="float-right"
                      role="group"
                      aria-label="theme-actions"
                    >
                      <Link
                        className="btn btn-sm btn-secondary"
                        to={`/themes/edit/${id}`}
                      >
                        {rolePermissions[PERMISSIONS.PERM_THEMES_UPDATE]
                          ? "Éditer"
                          : "Consulter"}
                      </Link>
                      {rolePermissions[PERMISSIONS.PERM_THEMES_DELETE] &&
                      confirmDelete !== id ? (
                        <div
                          onKeyDown={null}
                          tabIndex={0}
                          role="button"
                          onClick={() => setConfirmDelete(id)}
                          className="btn btn-sm btn-danger ml-4"
                        >
                          Supprimer
                        </div>
                      ) : null}
                      {rolePermissions[PERMISSIONS.PERM_THEMES_DELETE] &&
                      confirmDelete === id ? (
                        <div className="btn-group">
                          <div
                            onKeyDown={null}
                            tabIndex={0}
                            role="button"
                            onClick={() => handleThemeDeletion(id)}
                            className="btn btn-sm btn-danger ml-4"
                          >
                            Confirmer
                          </div>
                          <div
                            onKeyDown={null}
                            tabIndex={0}
                            role="button"
                            onClick={() => setConfirmDelete(null)}
                            className="btn btn-sm btn-secondary"
                          >
                            Annuler
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ),
                },
              ]}
              data={themesList || []}
              hasPagination
              isDraggable={rolePermissions[PERMISSIONS.PERM_THEMES_UPDATE]}
            />
          </DragDropContext>
        </>
      )}
    </WithMenu>
  )
}
export default TagsList
