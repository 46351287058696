import { RichUtils } from "draft-js"

export const EDITOR_TYPES = {
  INLINE: {
    id: "INLINE",
    toggleType: RichUtils.toggleInlineStyle,
  },
  BLOCK: { id: "BLOCK", toggleType: RichUtils.toggleBlockType },
}

export const EDITOR_OPTIONS = [
  { label: "Gras", style: "BOLD", type: EDITOR_TYPES.INLINE },
  { label: "Italique", style: "ITALIC", type: EDITOR_TYPES.INLINE },
  { label: "Souligné", style: "UNDERLINE", type: EDITOR_TYPES.INLINE },
  {
    label: "Liste à bulles",
    style: "unordered-list-item",
    type: EDITOR_TYPES.BLOCK,
  },
]

export const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
}
