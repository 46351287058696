import React, { useEffect, useCallback, useMemo } from "react"
import { useFormik } from "formik"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import ConfigSnippets from "./ConfigSnippets"
import ConfigHomePopin from "./ConfigHomePopin"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useConfigApi from "../../../hooks/Api/useConfigApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

import style from "./ConfigEdit.module.css"
import { exportCollectionToArrayImages } from "../../../utils/collection"

const permissions = [
  PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_DISPLAY,
  PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_UPDATE,
]

const ConfigEdit = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const [configRequestState, configPayload, , { getConfig }] = useConfigApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  const [
    configUpdateRequestState,
    configUpdatePayload,
    ,
    { updateConfig },
  ] = useConfigApi()
  const [
    ,
    paymentDelayMax,
    ,
    { getPaymentDelayMaxConfig },
  ] = useConfigApi()
  const [
    ,
    ,
    ,
    { updatePaymentDelayMaxConfig },
  ] = useConfigApi()

  const savedGetConfig = useCallback(getConfig, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetPaymentDelayMaxConfig = useCallback(
    getPaymentDelayMaxConfig,
    []
  )

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: configPayload || {},
    enableReinitialize: true,
  })

  const {
    values: paymentDelayValue,
    handleChange: handleDelayChange,
    handleBlur: handleDelayBlur,
  } = useFormik({
    initialValues: paymentDelayMax || {},
    enableReinitialize: true,
  })

  const handleConfigSubmit = e => {
    e.preventDefault()
    if (rolePermissions[PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_UPDATE]) {
      updatePaymentDelayMaxConfig(paymentDelayValue)
    }
    updateConfig(values)
  }

  useEffect(() => {
    savedGetConfig()
    savedGetMedias()
    if (rolePermissions[PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_DISPLAY])
      savedGetPaymentDelayMaxConfig()
  }, [
    savedGetConfig,
    savedGetMedias,
    savedGetPaymentDelayMaxConfig,
    rolePermissions,
  ])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Config</h1>
      </div>
      {configPayload &&
      configRequestState === "completed" &&
      mediasData &&
      mediasData.medias ? (
        <div className={cn(["mw-100", "w-75", "m-auto", style.editSection])}>
          <form onSubmit={handleConfigSubmit}>
            {rolePermissions[
              PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_DISPLAY
            ] ||
            rolePermissions[
              PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_UPDATE
            ] ? (
              <div className={cn(["card mb-4"])}>
                <div className="card-body">
                  <h3 className="card-title mb-4">
                    Date de limite de paiement (j)
                  </h3>
                  <div className="col-sm-4 col-md-4 pb-4 pl-0">
                    <label htmlFor="admin-firstname">Date limite</label>
                    <input
                      type="number"
                      className="form-control"
                      id="payment-delay"
                      name="paymentDelayMax"
                      value={paymentDelayValue.paymentDelayMax}
                      onChange={handleDelayChange}
                      onBlur={handleDelayBlur}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <ConfigSnippets
              values={values.frontAndMiddleJsSnippets}
              frontAndMiddleJsSnippets={values.frontAndMiddleJsSnippets}
              headJsSnippets={values.headJsSnippets}
              handleChange={handleChange}
            />
            <ConfigHomePopin
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              medias={mediasOptions}
            />
            <div className={style.submitBlock}>
              <button
                type="submit"
                className={cn(["btn btn-primary", style.submitButton])}
                disabled={configUpdateRequestState !== "completed"}
              >
                {false && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Sauvegarder
              </button>
              {configUpdatePayload && configUpdateRequestState === "completed" && (
                <div
                  className={cn(["alert alert-success", style.successMessage])}
                  role="alert"
                >
                  Sauvegardé avec succès
                </div>
              )}
            </div>
          </form>
        </div>
      ) : null}
    </WithMenu>
  )
}
export default ConfigEdit
