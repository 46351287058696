import React, { useState } from "react"

// Components
import LocalesList from "./LocalesList"
import LocalesEdit from "./LocalesEdit"

const Locales = () => {
  const [component, setComponent] = useState("list")
  const [selectedLocale, setSelectedLocale] = useState(null)

  const handleOnSelectLocale = locale => {
    setSelectedLocale(locale)
    setComponent("locale")
  }

  const handleBackToList = () => setComponent("list")

  return component === "list" ? (
    <LocalesList onSelectLocale={handleOnSelectLocale} />
  ) : (
    <LocalesEdit
      selectedLocale={selectedLocale}
      onBackToList={handleBackToList}
    />
  )
}

Locales.propTypes = {}

export default Locales
