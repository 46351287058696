import { eachWeekOfInterval, format, isDate, startOfYear } from 'date-fns'
import { fr } from "date-fns/esm/locale"
import fr_FR from 'date-fns/locale/fr'

const CAMPUS_ORIGIN = "2019-12-30 00:00:00"
const BOOKING_ORIGIN = "2018-12-31 00:00:00"

export const weekIdToDate = (weekId, service) => {
  const ORIGIN = service === "campus" ? CAMPUS_ORIGIN : BOOKING_ORIGIN
  const originDate = new Date(ORIGIN)
  const daysToAdd = (weekId - 1) * 7

  originDate.setDate(originDate.getDate() + daysToAdd)
  originDate.setHours(0, 0, 0, 0)
  return originDate
}

export const dateToWeekId = (date, service) => {
  const ORIGIN = service === "campus" ? CAMPUS_ORIGIN : BOOKING_ORIGIN
  const copiedDate = new Date(date.getTime())
  const originDate = new Date(ORIGIN)
  const day = 24 * 60 * 60 * 1000

  copiedDate.setHours(0, 0, 0, 0)

  const daysElapsed = Math.abs((copiedDate - originDate) / day)
  return Math.floor(daysElapsed / 7) + 1
}

export const getISOWeeks = y => {
  const d = new Date(y, 0, 1)
  const isLeap = new Date(y, 1, 29).getMonth() === 1
  return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52
}

export const weeksList = ({start, end}) => {
  return eachWeekOfInterval({
    start,
    end,
  }, {weekStartsOn: 1})
  .map((week, index) => ({
    id: index,
    title: `Semaine du lundi ${format(week, 'dd MMMM yyyy', { locale: fr_FR }).toLowerCase()}`,
    value: week,
  }))
}

export const formatDateForCoachUrl = (week) => {
  if (!week || !isDate(week)) return '';
  return format(week, 'dd_MM_yyyy');
}

export const getCampusWeeksOptionsFromYear = ({ year, shortLabel }) => {
  const options = []
      const currentYearWeeks = getISOWeeks(Number(year))
      const nextYearWeeks = getISOWeeks(Number(year) + 1)
      const firstWeek = new Date(Number(year), 1, 1, 0, 0)
      const firstWeekId = dateToWeekId(startOfYear(firstWeek), "campus")
      for (let i = 1; i < currentYearWeeks + nextYearWeeks; i += 1) {
        const dateLabel = format(
          weekIdToDate(firstWeekId + i, "campus"),
          "dd MMMM yyyy",
          { locale: fr }
        );
        options.push({
          label: shortLabel ? dateLabel : `Semaine du ${dateLabel}`,
          value: firstWeekId + i,
        })
      }
      return options
}
