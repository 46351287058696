import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import addDays from "date-fns/addDays"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import useAppContext from "../../../hooks/useAppContext"
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useAttachmentsApi from "../../../hooks/Api/useAttachmentsApi"
import usePeriodsApi from "../../../hooks/Api/usePeriodsApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

import styles from "./AttachmentsEdit.module.css"
import MediasInput from "../../../components/atoms/MediasInput/MediasInput"
import { exportCollectionToArrayImages } from "../../../utils/collection"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_ATTACHMENTS_UPDATE,
  PERMISSIONS.PERM_ATTACHMENTS_CREATE,
]

const AttachmentsEdit = ({ match }) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)
  const history = useHistory()

  // Attachments API Hooks
  const [
    ,
    attachmentData,
    attachmentError,
    { getAttachmentWithId },
  ] = useAttachmentsApi()
  const [
    updateAttachmentState,
    ,
    updateError,
    { updateAttachment },
  ] = useAttachmentsApi()
  const [
    newAttachmentState,
    ,
    newAttachmentError,
    { createAttachment },
  ] = useAttachmentsApi()

  // Media API Hook
  const [, mediasData, , { getMedias }] = useMediasApi()
  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  // Periods API Hook
  const [, periodsData, , { getPeriods }] = usePeriodsApi()

  const isEditingAttachment = !isEmpty(match.params)

  const savedGetAttachmentWithId = useCallback(getAttachmentWithId, [])
  const savedGetPeriods = useCallback(getPeriods, [])
  const savedGetMedias = useCallback(getMedias, [])

  const createInitialValues =
    mediasData && periodsData
      ? {
          name: "",
          attachmentPeriods: Object.keys(periodsData.periods).reduce(
            (acc, period) => {
              acc[period] = {
                periodId: Number(period),
                mediaId: Object.keys(locales).reduce((accMedia, curVal) => {
                  const media = { ...accMedia }
                  media[curVal] = 1
                  return media
                }, {}),
                name: Object.keys(locales).reduce((accName, curVal) => {
                  const name = { ...accName }
                  name[curVal] = ""
                  return name
                }, {}),
              }
              return acc
            },
            {}
          ),
        }
      : {}

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      attachments: isEditingAttachment
        ? attachmentData || {}
        : createInitialValues,
    },
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      const sanitizedValues = { ...valuesSubmit.attachments }
      delete sanitizedValues.id

      if (isEditingAttachment)
        updateAttachment(sanitizedValues, match.params.id).then(() =>
          history.push({
            pathname: "/attachments/list",
            state: { submitType: "edit", success: true },
          })
        )
      else
        createAttachment(sanitizedValues).then(
          setTimeout(() => {
            history.push({
              pathname: "/attachments/list",
              state: { submitType: "create", success: true },
            })
          }, 1500)
        )
    },
  })

  useEffect(() => {
    if (isEditingAttachment) savedGetAttachmentWithId(match.params.id)
    savedGetPeriods()
    savedGetMedias()
  }, [
    isEditingAttachment,
    savedGetAttachmentWithId,
    savedGetPeriods,
    savedGetMedias,
    match.params.id,
  ])

  const backToList = () => {
    history.push({
      pathname: "/attachments/list",
      state: {},
    })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_ATTACHMENTS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_ATTACHMENTS_CREATE] && !isEditing)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Brochure / ${isEditingAttachment ? "Éditer" : "Nouveau"}`}
          {attachmentData && attachmentData.name
            ? ` / ${attachmentData.name}`
            : null}
        </h1>
      </div>

      {values.attachments.attachmentPeriods &&
      periodsData &&
      mediasData &&
      (attachmentData || !isEditingAttachment) ? (
        <div className={cn(["mw-100", "w-100", "m-auto", styles.editSection])}>
          <form onSubmit={handleSubmit}>
            {attachmentError || updateError || newAttachmentError ? (
              <div className="alert alert-error" role="alert">
                {attachmentError || updateError || newAttachmentError}
              </div>
            ) : null}

            {/* NAME */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Informations</h5>

                <div className="form-row">
                  <div
                    className="col-sm-12 col-md-8 pb-6"
                    key="attachment-name"
                  >
                    <label htmlFor="attachment-name">Name</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="text"
                      className="form-control"
                      id="attachments.name"
                      name="attachments.name"
                      value={values.attachments.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                </div>
              </div>
            </div>

            {values.attachments.attachmentPeriods &&
            periodsData &&
            mediasData &&
            (attachmentData || !isEditingAttachment)
              ? Object.keys(values.attachments.attachmentPeriods).map(
                  periodId => (
                    <div
                      key={`attachments_period_${periodId}`}
                      className={cn(["card mb-4"])}
                    >
                      <div className="card-body">
                        <h5 className="card-title">{`Période à partir du ${format(
                          addDays(
                            new Date(2000, 0, 0),
                            periodsData.periods[periodId].startDayOfYear + 1
                          ),
                          "dd/MM"
                        )}`}</h5>

                        <div className="form-row">
                          {locales
                            ? Object.keys(locales).map(locale => (
                                <div
                                  className="col-12 col-lg-6 col-xl-3 pb-3"
                                  key={locale}
                                >
                                  <MediasInput
                                    label={`Media ID - ${locales[locale].code}`}
                                    options={mediasOptions}
                                    selection={values.attachments?.attachmentPeriods?.[periodId]?.mediaId?.[locale] ? [values.attachments.attachmentPeriods[periodId].mediaId[locale]] : []}
                                    setSelection={(value) => 
                                      setFieldValue(
                                        `attachments.attachmentPeriods.${periodId}.mediaId.${locale}`,
                                        value[0]?.id || null
                                      )
                                    }
                                    disabled={!isAllowedToEdit}
                                    required
                                  />
                                </div>
                              ))
                            : null}{" "}
                        </div>
                        <hr />
                        <div className="form-row">
                          {locales
                            ? Object.keys(locales).map(locale => (
                                <div
                                  className="col-sm-6 col-md-6 pb-3"
                                  key={locale}
                                >
                                  <label htmlFor="attachment-name">
                                    {`Name - ${locales[locale].code}`}
                                  </label>
                                  <input
                                    disabled={!isAllowedToEdit}
                                    type="text"
                                    className="form-control"
                                    id={`attachments.attachmentPeriods.${periodId}.name.${locale}`}
                                    name={`attachments.attachmentPeriods.${periodId}.name.${locale}`}
                                    value={
                                      values.attachments.attachmentPeriods[
                                        periodId
                                      ].name[locale] || ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                  />
                                  <div className="valid-tooltip">
                                    Looks good!
                                  </div>
                                </div>
                              ))
                            : null}{" "}
                        </div>
                      </div>
                    </div>
                  )
                )
              : null}
            <div className="valid-tooltip">Looks good!</div>
            <div className="mb-4">
              {!isAllowedToEdit ? (
                <button
                  onClick={backToList}
                  type="button"
                  className="btn btn-secondary mr-2"
                >
                  Revenir à la liste
                </button>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  updateAttachmentState === "loading" ||
                  newAttachmentState === "loading" ||
                  !isAllowedToEdit
                }
              >
                {updateAttachmentState === "loading" ||
                newAttachmentState === "loading" ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Sauvegarder
              </button>
            </div>
          </form>
        </div>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

AttachmentsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default AttachmentsEdit
