import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

// Utils
import cn from "../../../../../utils/cn"
import { Draggable } from "react-beautiful-dnd"
import { format, parse } from "date-fns"

// Services
import useBookingsForCoachApi from "../../../../../hooks/Api/useBookingsForCoachApi"

// Components
import GlobalTooltip from "../../../../../components/molecules/Tooltip/Tooltip"
import TooltipPlayer from "./TooltipPlayer"

// Style
import style from "./Player.module.css"

const Player = ({ player, handleSelectPlayer, playersSelected, sections, playerContextMenuOpened, setPlayerContextMenuOpened, handleResetSection, countries }) => {
  const contextMenuRef = useRef();
  const [contextMenuIsOpened, setContextMenuIsOpened] = useState(false);

  const [, , , { postBookingAssignment }] = useBookingsForCoachApi();

  const getShortBirthYear = (birthdate) => {
    const parsedDate = birthdate ? parse(birthdate, 'yyyy-MM-dd', new Date()) : null;
    const year = parsedDate ? format(parsedDate, 'yyyy') : null;
    const short = year?.length === 4 ? year.slice(-2) : null;
    return short;
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    setContextMenuIsOpened(true);
    return false;
  };

  useEffect(() => {
    setPlayerContextMenuOpened(contextMenuIsOpened ? player.id : null);
  }, [contextMenuIsOpened]);

  const handleChangeSection = (section) => {
    if (section?.code && player.bookingId) {
      postBookingAssignment({
        weekSection: section.code,
        bookingId: player.bookingId,
      }).then(() => {
        handleResetSection();
      });
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.offsetParent !== contextMenuRef?.current) {
      setContextMenuIsOpened(false);
    }
  };

  useEffect(() => {
    if (playerContextMenuOpened && playerContextMenuOpened !== player.id) {
      setContextMenuIsOpened(false);
    }
  }, [playerContextMenuOpened]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      setContextMenuIsOpened(false);
    }
  }, []);

  const getPlayerLabel = () => {
    // Name
    let label = player?.name || '';

    // Birth year
    if (getShortBirthYear(player?.birthdate)) {
      label += ` ${getShortBirthYear(player?.birthdate)}`;
    }

    // Player has 2 sessions (AM and PM)
    if (player?.sessions?.length === 2) {
      label += ` (${player.ordinal})`;
    }

    // Full day academy test
    if (player?.colorCode === 'full_day_test') {
      label += ' (Test Acad)'
    }

    return label;
  };

  if (!player.id) return <div>broken player</div>
  return (
    <Draggable
      draggableId={`${player.id}:${player.weekSectionRotationGroupCode ??
        player.weekSectionCode}`}
      index={player.index}
    >
      {(providedDrag, snapshot) => (
        <div
          className={`${style.player} ${
            style[`${player.colorCode}`]
          } ${player.isSelected && style["player-selected"]}`}
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
        >
          <div
            className={style.player_inner}
            onClick={(e) => {
              setContextMenuIsOpened(false);
              handleSelectPlayer(e, player);
            }}
            onContextMenu={handleRightClick}
          >
            {snapshot.isDragging && playersSelected.length > 0 && (
              <div className={style["number-players-selected"]}>
                {playersSelected.length}
              </div>
            )}
            <GlobalTooltip
              text="ℹ️"
              content={<TooltipPlayer player={player} countries={countries} />}
              copyContent={false}
            />

            <span
              className={cn([
                style.player_label,
                player.playerHasBookingPreviousWeek ? style.underlined : ''
              ])}
            >
              {getPlayerLabel()}
            </span>

            {player?.comment && (
              <GlobalTooltip
                text="⚠️"
                content={player.comment}
                copyContent={false}
              />
            )}
          </div>

          <div
            ref={contextMenuRef}
            className={cn([style.context_menu, contextMenuIsOpened ? style.opened : '', 'player_context_menu'])}
          >
            <p className={style.context_title}>
              Changer de section
            </p>
            {sections?.filter((section) => section.code !== player.weekSectionCode).map((section, key) => (
              <button key={key} onClick={() => handleChangeSection(section)}>
                {section.title}
              </button>
            ))}
          </div>
        </div>
      )}
    </Draggable>
  )
}

Player.propTypes = {
  player: PropTypes.object,
  handleSelectPlayer: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  playerContextMenuOpened: PropTypes.string,
  setPlayerContextMenuOpened: PropTypes.func,
  handleResetSection: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Player
