import React, { useRef } from "react"
import cn from '../../../utils/cn'
import { isEmpty } from "lodash";
import Select from 'react-select';

import style from './YieldsLegacy.module.css'

function Tab(props) {
  function handleClick() {
    props.changeSelectedYearId(props.year.id)
  }

  return (
    <a
      onClick={handleClick}
      className={`nav-link${props.active ? " active" : ""}`}
      href="#"
    >
      {props.year.number}
    </a>
  )
}

function Stock(props) {
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const spanRef = useRef(null);

  const displayPrice = (price) => price.toFixed(2).toString().replace('.', ',');

  const price = parseFloat(props.stock.price.toFixed(2));
  const yieldValue = parseFloat(props.stock.yield.toFixed(2));
  const offset = displayPrice(props.stock?.offset || 0);

  const updateInputWidth = (value) => {
    if (inputRef?.current && spanRef?.current) {
      const span = spanRef.current;
      span.textContent = value;
      const inputWidth = span.offsetWidth;
      inputRef.current.style.width = `${inputWidth}px`;
    }
  }

  const canEditYield = (quota) => {
    return !isEmpty(props.permsByTypology) && quota.familyId === 3 && quota.typologyIds?.length 
      ? quota.typologyIds.some((id) => !!props.permsByTypology[id])
      : props.authorizedToEdit;
  }

  const handleEditValue = (e) => {
    if (!canEditYield(props.quota)) return;

    const value = e?.target?.value ? +e.target.value : 0;
    props.changeStockYield(props.quotaId, props.weekId, parseFloat((value - price).toFixed(2)));

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updateInputWidth(value), 100);
  }

  return (
    <>
      <td>
        {props.stock.sold} /{props.stock.quantity}
      </td>
      {!props.macro && (
        <>
          <td className="text-right">{displayPrice(price)}</td>

          <td className="text-right">
            {props.authorizedToEdit ? (
              <div className={style.input_wrapper}>
                <input
                  type="number"
                  value={yieldValue}
                  onChange={handleEditValue}
                  className={cn([style.input, 'form-control'])}
                  min="0"
                  disabled={!canEditYield(props.quota)}
                  ref={inputRef}
                />
                <span ref={spanRef} className={style.input_value}>
                  {yieldValue}
                </span>
              </div>
            ) : (
              <>
                {yieldValue}
              </>
            )}
          </td>

          <td>
            <strong>
              {offset > 0 ? '+' : ''}
              {offset}
            </strong>
          </td>
        </>
      )}
    </>
  )
}

function Week(props) {
  const result = props.orderedQuotasIds.reduce(
    (accumulator, quotaId) => {
      if (!props.quotas?.[quotaId]?.macro && props.stocks?.[quotaId]?.[props.week.id]) {
        const stock = props.stocks[quotaId][props.week.id]
        accumulator.quantity += stock.quantity
        accumulator.sold += stock.sold
      }

      return accumulator
    },
    { quantity: 0, sold: 0 }
  )

  return (
    <tr>
      <td className={cn([style.weekHeaderCol, 'align-middle'])} style={{ left: "0" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-1">S{props.week.number}</div>
        </div>
      </td>
      <td>{props.week.start}</td>
      <td>{props.week.end}</td>
      <td>{props.week.season}</td>
      <td>{result.sold} /{result.quantity}</td>

      {props.orderedQuotasIds
        ?.filter((quotaId) => props.stocks?.[quotaId]?.[props.week.id])
        .map(quotaId => (
          <Stock
            key={quotaId}
            quotaId={quotaId}
            quota={props.quotas[quotaId]}
            weekId={props.week.id}
            stock={props.stocks[quotaId][props.week.id]}
            macro={props.quotas[quotaId].macro}
            changeStockYield={props.changeStockYield}
            authorizedToEdit={props.authorizedToEdit}
            productType={props.productType}
            permsByTypology={props.permsByTypology}
          />
        ))}
    </tr>
  )
}

class YieldsLegacy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedYearId: this.props.orderedYearsIds[0],
      stocks: this.props.stocks,
      quotaStocks: this.props.quotaStocks,
      events: [],
      syncing: false,
      filterQuotasIds: [],
    }
  }

  sendEvents = () => {
    this.setState(state => {
      this.props
        .updateStock(JSON.stringify({ events: state.events }))
        .then(() => {
          this.setState({
            events: [],
            syncing: false,
          })
        })

      return { syncing: true }
    })
  }

  changeSelectedYearId = yearId => {
    this.setState({
      selectedYearId: yearId?.toString(),
    })
  }

  changeStockYield = (quotaId, weekId, offset) => {
    this.setState(state => {
      const stocks = JSON.parse(JSON.stringify(state.stocks))
      const events = JSON.parse(JSON.stringify(state.events))
      const stock = stocks[quotaId][weekId]

      stock.yield = stock.price + offset
      stock.offset = offset

      events.push({
        type: "changeYield",
        data: JSON.stringify({
          stockId: stock.id,
          offset,
        }),
      })

      return {
        stocks,
        events,
      }
    })
  }

  changeFilterQuotasIds = (selected) => {
    this.setState({
      filterQuotasIds: selected?.map((option) => parseInt(option.value)) || [],
    })
  }

  filteredQuotaIds = () => {
    const { orderedQuotasIds } = this.props
    const { filterQuotasIds } = this.state

    const ids = filterQuotasIds.length > 0
      ? orderedQuotasIds.filter(quotaId => {
          for (const filterQuotaId of this.state.filterQuotasIds) {
            if (this.props.quotasGraph[filterQuotaId].includes(quotaId)) {
              return true
            }
          }
        })
      : orderedQuotasIds

    return ids.filter((id) => this.state.stocks?.[id]);
  }

  render = () => (
    <React.StrictMode>
      <div className={style.select_filter}>
        <Select
          options={this.props.filterQuotaOptions}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          onChange={this.changeFilterQuotasIds}
          placeholder="Filtrer par produit..."
        />
      </div>

      <div className="row">
        <div className="col">
          <ul className={cn([style.nav_tabs, 'nav nav-tabs'])}>
            {this.props.orderedYearsIds.map(yearId => (
              <Tab
                key={yearId}
                year={this.props.years[yearId]}
                active={this.state.selectedYearId === yearId}
                changeSelectedYearId={this.changeSelectedYearId}
              />
            ))}
          </ul>
        </div>
        <div className="col text-right">
          {this.props.authorizedToExport && (
            <button
              disabled={this.props.exportLoading}
              onClick={this.props.exportMatrix}
              className="btn btn-primary"
            >
              Exporter
              {this.props.exportLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          )}
        </div>
      </div>
      <div className={cn([style.table_wrapper, 'table-responsive'])}>
        <table className={cn(["table", "table-sm", "table-bordered", "table-hover", "table-striped", style.theadFixed])}>
          <thead>
            <tr>
              <th>Semaine</th>
              <th>Du</th>
              <th>Au</th>
              <th>Saison</th>
              <th>Stock total</th>
              {this.filteredQuotaIds().map((quotaId) => (
                <th
                  key={quotaId}
                  colSpan={this.props.quotas[quotaId].macro ? 1 : 4}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">
                      {this.props.quotas[quotaId].name}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
            <tr className={style.theadSub}>
              <th colSpan={5} />

              {this.filteredQuotaIds().map((quotaId) => (
                this.props.quotas[quotaId].macro ? (
                  <th key={`${quotaId}_subhead_macro`}>
                    Stock
                  </th>
                ) : (
                  ['Stock', 'Prix initial', 'Prix appliqué', 'Yield'].map((label, key) => (
                    <th key={`${quotaId}_subhead_${key}`}>
                      {label}
                    </th>
                  ))
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.orderedWeeksIds[this.state.selectedYearId].map(
              weekId => (
                <Week
                  key={weekId}
                  week={this.props.weeks[weekId]}
                  orderedQuotasIds={this.filteredQuotaIds()}
                  stocks={this.state.stocks}
                  quotas={this.props.quotas}
                  changeStockYield={this.changeStockYield}
                  authorizedToEdit={this.props.authorizedToEdit}
                  productType={this.props.productType}
                  permsByTypology={this.props.permsByTypology}
                />
              )
            )}
          </tbody>
        </table>
      </div>
      {this.props.authorizedToEdit && (
        <>
          <button
            type="button"
            disabled={
              this.state.events.length === 0 ||
              this.state.syncing ||
              !this.props.authorizedToEdit
            }
            onClick={this.sendEvents}
            className="btn btn-primary mt-3 mb-3"
          >
            {this.state.syncing ? "Synchonisation..." : "Valider"}
          </button>
          <a href="" className="btn btn-secondary mt-3 mb-3 ml-1">
            Annuler
          </a>
        </>
      )}
    </React.StrictMode>
  )
}

export default YieldsLegacy
