export default () => ({
  id: null,
  uid: null,
  clientId: null,
  firstname: "",
  lastname: "",
  sexId: null,
  nationalityId: 71,
  nationalityCode: "FR",
  birthdate: "",
  email: null,
  phone: null,
  rightToTheImage: false,
  thumbnail: null,
  playerSports: {
    1: {
      sportId: 1,
      isRanked: false,
      rankingCountryId: null,
      rankingCountryCode: null,
      ranking: null,
      rankingName: null,
      otherRanking: null,
      utrRanking: null,
      years: null,
      hoursPerWeek: null,
      participationsPerYear: null,
    },
    2: {
      sportId: 2,
      isRanked: false,
      rankingCountryId: null,
      rankingCountryCode: null,
      ranking: null,
      rankingName: null,
      otherRanking: null,
      utrRanking: null,
      years: null,
      hoursPerWeek: null,
      participationsPerYear: null,
    },
    3: {
      sportId: 3,
      isRanked: false,
      rankingCountryId: null,
      rankingCountryCode: null,
      ranking: null,
      rankingName: null,
      otherRanking: null,
      utrRanking: null,
      years: null,
      hoursPerWeek: null,
      participationsPerYear: null,
    },
  },
  playerLanguages: {
    1: {
      languageId: 1,
      levelId: null,
    },
    2: {
      languageId: 2,
      levelId: null,
    },
  },
  emergencyContactLastname: null,
  emergencyContactFirstname: null,
  emergencyContactOccupation: null,
  emergencyContactPhone: null,
  doctorLastname: null,
  doctorAddress: null,
  doctorZipCode: null,
  doctorCity: null,
  doctorCountryId: null,
  doctorCountryCode: null,
  socialSecurityAddress: null,
  socialSecurityZipCode: null,
  socialSecurityCity: null,
  socialSecurityCountryId: null,
  socialSecurityCountryCode: null,
  medicalObservations: null,
  medicalTermsAccepted: null,
  comment: null,
})
