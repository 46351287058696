import React, { useEffect, useCallback, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import {
  intervalToDuration,
  formatDuration,
  isAfter,
  differenceInSeconds,
} from "date-fns"
import { useHistory } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

// Components
import WithMenu from "../../../../../components/templates/WithMenu/WithMenu"
import GlobalMessage from "../../../../../components/atoms/GlobalMessage/GlobalMessage"
import Modal from "../../../../../components/molecules/Modal"
import CardForm from "../ResolvePayment/CardForm"

// Hooks
import useOrdersApi from "../../../../../hooks/Api/useOrdersApi"
import useClientsApi from "../../../../../hooks/Api/useClientsApi"
import usePreOrderApi from "../../../../../hooks/Api/usePreOrderApi"
import usePaymentsApi from "../../../../../hooks/Api/usePaymentsApi"

// Utils & misc
import cn from "../../../../../utils/cn"
import { ORDER_STATUS, STRIPE_PUBLIC } from "../../../../../constants/constants"

import style from "./ChoosePayment.module.css"

const otherPaymentMeans = [
  {
    name: "PAYPAL",
    paymentMeanId: 2,
  },
  {
    name: "TRANSFER",
    paymentMeanId: 3,
  },
  {
    name: "CASH",
    paymentMeanId: 4,
  },
  {
    name: "CHECK",
    paymentMeanId: 5,
  },
  {
    name: "CB - TPE",
    paymentMeanId: 6,
  },
  {
    name: "STRIPE",
    paymentMeanId: 1,
  },
]

const ChoosePayment = ({ match }) => {
  const history = useHistory()
  const [, order, , { getOrderById }] = useOrdersApi()
  const [, , , { getOrderById: getOrderByIdR }] = useOrdersApi()
  const [, , , { sendSMS }] = useOrdersApi()
  const [, , , { sendEmail }] = useOrdersApi()
  const [, smsStatusData, , { getSMSStatus }] = useOrdersApi()
  const [, client, , { getClientWithId }] = useClientsApi()
  const [, , , { validatePreOrder }] = usePreOrderApi()
  const [{ createPayment }] = usePaymentsApi()

  const [showPaymentChoice, setShowPaymentChoice] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [emailValue, setEmailValue] = useState("")
  const [secretBoxAmount, setSecretBoxAmount] = useState("")
  const [paymentSelected, setPaymentSelected] = useState(null)

  // Credit Card Payments
  const [, stripeToken, , { getStripePayment }] = useOrdersApi()
  const savedGetStripePayment = useCallback(getStripePayment, [])
  const stripe = useRef(null)
  const loadStripeObject = useCallback(async () => {
    stripe.current = await loadStripe(STRIPE_PUBLIC)
  }, [])
  //

  // Defferred Payments
  const [defferedPaymentsModalOpen, setDefferedPaymentsModalOpen] = useState(
    false
  )
  const [defferredPaymentValue, setDefferredPaymentValue] = useState(2)
  const [
    defferredPaymentsPreventSending,
    setDefferredPaymentsPreventSending,
  ] = useState(false)
  //

  const paymentChosen = useRef(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const smsStatus = useRef(null)
  const emailStatus = useRef(null)
  const [phoneValue, setPhoneValue] = useState({ value: "", valid: true })
  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetOrderById = useCallback(getOrderById, [])
  const savedGetSMSStatus = useCallback(getSMSStatus, [])

  const [smsTimerExpired, setSmsTimerExpired] = useState(false)
  const [emailTimerExpired, setEmailTimerExpired] = useState(false)

  const { orderId, clientId } = match.params

  function convertDateToUTC(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  const fetchSMSStatus = useCallback(async () => {
    const orderS = await getOrderByIdR(
      orderId,
      smsStatus?.current?.smsTokenUid
        ? { smsTokenUid: smsStatus.current.smsTokenUid }
        : null
    )

    if (
      paymentChosen.current &&
      paymentChosen.current.id === "SMS_PAYMENT" &&
      orderS &&
      orderS.data
    ) {
      if (orderS.data.smsPaymentErrorCode) {
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <>
              <div>
                <b>Erreur paiement client:</b>
              </div>
              <div>{orderS.data.smsPaymentErrorCode}</div>
              <div>{orderS.data.smsPaymentErrorDescription}</div>
              <div>{orderS.data.smsPaymentErrorNextSteps}</div>
            </>
          ),
          className: "alert alert-danger",
        })
      }
      if (orderS.data.status === ORDER_STATUS.VALIDATED) {
        history.push({
          pathname: `/client/${clientId}/orders/list`,
          state: {
            submitType: "Commande régularisée avec succès (paiement par SMS)",
            success: true,
          },
        })
      }
    }
  }, [clientId, getOrderByIdR, history, orderId])

  const updateSmsStatus = () => {
    if (smsStatus.current && smsStatus.current.expiration) {
      if (
        isAfter(
          convertDateToUTC(new Date()),
          new Date(smsStatus.current.expiration)
        )
      ) {
        smsStatus.current = null
        setSmsTimerExpired(true)
      } else {
        const { countDown, duration } = getCountdown(
          smsStatus.current.expiration
        )
        smsStatus.current = {
          ...smsStatus.current,
          countDown,
          duration,
        }
      }
    }
  }

  const fetchEmailStatus = useCallback(async () => {
    const orderS = await getOrderByIdR(
      orderId,
      emailStatus?.current?.smsTokenUid
        ? { smsTokenUid: emailStatus.current.smsTokenUid }
        : null
    )

    if (
      paymentChosen.current &&
      paymentChosen.current.id === "EMAIL_PAYMENT" &&
      orderS &&
      orderS.data
    ) {
      if (orderS.data.smsPaymentErrorCode) {
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <>
              <div>
                <b>Erreur paiement client:</b>
              </div>
              <div>{orderS.data.smsPaymentErrorCode}</div>
              <div>{orderS.data.smsPaymentErrorDescription}</div>
              <div>{orderS.data.smsPaymentErrorNextSteps}</div>
            </>
          ),
          className: "alert alert-danger",
        })
      }
      if (orderS.data.status === ORDER_STATUS.VALIDATED) {
        history.push({
          pathname: `/client/${clientId}/orders/list`,
          state: {
            submitType: "Commande régularisée avec succès (paiement par Email)",
            success: true,
          },
        })
      }
    }
  }, [clientId, getOrderByIdR, history, orderId])

  const updateEmailStatus = () => {
    if (emailStatus.current && emailStatus.current.expiration) {
      if (
        isAfter(
          convertDateToUTC(new Date()),
          new Date(emailStatus.current.expiration)
        )
      ) {
        emailStatus.current = null
        setEmailTimerExpired(true)
      } else {
        const { countDown, duration } = getCountdown(
          emailStatus.current.expiration
        )
        emailStatus.current = {
          ...emailStatus.current,
          countDown,
          duration,
        }
      }
    }
  }

  const handlePreOrderValidate = async () => {
    setButtonLoading(true)

    await validatePreOrder(clientId, {
      preventSending: defferredPaymentsPreventSending,
      paymentMeanId: defferredPaymentValue,
    })

    setButtonLoading(false)

    history.push({
      pathname: `/orders/list`,
      state: {
        submitType: `La commande ${orderId} a été ajoutée à la liste des commandes à régulariser avec succès.`,
        success: true,
      },
    })
  }

  const handleSecretBoxValidate = async () => {
    try {
      // Call the endpoint here
      setButtonLoading(true)
      await validatePreOrder(clientId, {
        preventSending: false,
        paymentMeanId: 7,
      })

      await createPayment({
        orderId,
        amount: secretBoxAmount?.length ? (parseFloat(secretBoxAmount.replace(',', '.'), 10).toFixed(2) * 100) : null,
        paymentMeanId: 7,
      })
      setButtonLoading(false)

      const message =
        order.toPayAmount > Number(secretBoxAmount)
          ? `Une partie de la commande a été payée avec succès (${secretBoxAmount}€)`
          : "Commande régularisée avec succès (SecretBox)"
      history.push({
        pathname: `/client/${clientId}/orders/list`,
        state: { submitType: message, success: true },
      })
    } catch (error) {
      setButtonLoading(false)
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: <span>Une erreur est survenue (Paiement par Secret Box)</span>,
        className: "alert alert-danger",
      })
    }
  }

  const handleEmailSend = async e => {
    e.preventDefault()
    if (emailValue) {
      try {
        const emailResp = await sendEmail(orderId, { email: emailValue })

        if (emailResp && emailResp.data) {
          setEmailTimerExpired(false)
          const { countDown, duration } = getCountdown(
            emailResp.data.expirationDate.date
          )
          emailStatus.current = {
            ...(emailStatus.current || {}),
            expiration: emailResp.data.expirationDate.date,
            countDown,
            duration,
            initialDuration: differenceInSeconds(
              new Date(emailResp.data.expirationDate.date),
              convertDateToUTC(new Date())
            ),
            smsTokenUid: emailResp.data.id,
          }
        }
      } catch (error) {
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: <span>Une erreur est survenue (Paiement par Email)</span>,
          className: "alert alert-danger",
        })
      }
    }
  }

  const getCountdown = useCallback(exp => {
    const curDate = convertDateToUTC(new Date())
    const expDate = new Date(exp)
    const duration = intervalToDuration({
      start: expDate,
      end: curDate,
    })

    const diff = differenceInSeconds(expDate, curDate)

    return {
      countDown: formatDuration(duration, {
        delimiter: " ",
      }),
      duration: diff,
    }
  }, [])

  const handleSmsSend = async () => {
    let phoneNumber = phoneValue.value
    if (phoneNumber && phoneNumber[0] !== "+") phoneNumber = `+${phoneNumber}`

    const sms = await sendSMS(orderId, { phone: phoneNumber })

    if (sms && sms.data) {
      setSmsTimerExpired(false)
      const { countDown, duration } = getCountdown(sms.data.expirationDate.date)
      smsStatus.current = {
        ...(smsStatus.current || {}),
        expiration: sms.data.expirationDate.date,
        countDown,
        duration,
        initialDuration: differenceInSeconds(
          new Date(sms.data.expirationDate.date),
          convertDateToUTC(new Date())
        ),
        smsTokenUid: sms.data.id,
      }
    }
  }

  // initial data load
  useEffect(() => {
    if (orderId && clientId) {
      savedGetSMSStatus(orderId)
      savedGetOrderById(orderId)
      savedGetClientWithId(clientId)
      savedGetStripePayment(orderId)
      loadStripeObject()
    }
  }, [
    clientId,
    orderId,
    savedGetClientWithId,
    savedGetOrderById,
    savedGetSMSStatus,
    savedGetStripePayment,
    loadStripeObject,
  ])

  // show the choice section when the order is loaded
  useEffect(() => {
    if (order) {
      if (order.status === ORDER_STATUS.VALIDATED) {
        history.push({
          pathname: `/client/${clientId}/order/${orderId}/resolve`,
          state: {},
        })
      } else if (order.status === ORDER_STATUS.CART) {
        setShowPaymentChoice(true)
      }
    }
  }, [clientId, history, order, orderId])

  // This effect runs an interval (sendsms polling)
  useEffect(() => {
    if (showPaymentChoice && paymentSelected?.id === "SMS_PAYMENT") {
      const intervalS = setInterval(() => {
        updateSmsStatus()
      }, 1000)

      const intervalR = setInterval(() => {
        fetchSMSStatus()
      }, 1000)
      return () => {
        clearInterval(intervalR)
        clearInterval(intervalS)
      }
    }

    if (showPaymentChoice && paymentSelected?.id === "EMAIL_PAYMENT") {
      const intervalS = setInterval(() => {
        updateEmailStatus()
      }, 1000)

      const intervalR = setInterval(() => {
        fetchEmailStatus()
      }, 1000)
      return () => {
        clearInterval(intervalR)
        clearInterval(intervalS)
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentChoice, paymentSelected])

  useEffect(() => {
    if (smsStatusData) {
      paymentChosen.current = { id: "SMS_PAYMENT", name: "Paiement par SMS" }
      const { countDown, duration } = getCountdown(
        smsStatusData.expirationDate.date
      )
      smsStatus.current = {
        ...smsStatusData,
        expiration: smsStatusData.expirationDate.date,
        countDown,
        duration,
        initialDuration: differenceInSeconds(
          new Date(smsStatusData.expirationDate.date),
          convertDateToUTC(new Date())
        ),
      }
    }
  }, [getCountdown, smsStatusData])

  useEffect(() => {
    paymentChosen.current = paymentSelected

    if (paymentSelected?.id === "EMAIL_PAYMENT" && client) {
      setEmailValue(client.email)
    }
  }, [paymentSelected, client])

  const getCountdownPercentage = () => {
    if (!paymentSelected?.id) return 0

    switch (paymentSelected.id) {
      case "SMS_PAYMENT": {
        if (
          smsStatus &&
          smsStatus.current &&
          smsStatus.current.duration &&
          smsStatus.current.initialDuration
        ) {
          return Math.round(
            (smsStatus.current.duration / smsStatus.current.initialDuration) *
              100
          )
        }
        break
      }
      case "EMAIL_PAYMENT": {
        if (
          emailStatus &&
          emailStatus.current &&
          emailStatus.current.duration &&
          emailStatus.current.initialDuration
        ) {
          return Math.round(
            (emailStatus.current.duration /
              emailStatus.current.initialDuration) *
              100
          )
        }
        break
      }
      default: {
        break
      }
    }
    return 0
  }

  const getProgressBarColor = () => {
    const cdPercent = getCountdownPercentage()

    if (cdPercent > 70) return "bg-success"
    if (cdPercent < 30) return "bg-danger"
    return "bg-warning"
  }

  return (
    <WithMenu>
      {showPaymentChoice ? (
        <div className={style.orderInfo}>
          <div className={style.pageHeader}>
            <div>
              {client ? (
                <>
                  <h1>
                    {`${client.firstname} ${client.lastname} / Commande / Choix du moyen de paiement`}
                  </h1>
                  <h3>
                    <span className="mt-2 badge badge-primary">
                      Status: <b>à valider</b>
                    </span>
                  </h3>
                </>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="list-group">
                <li className="list-group-item">
                  Balance de la commande{" "}
                  <span className="float-right">{order.orderBalance}</span>
                </li>
                <li className="list-group-item">
                  Crédits du client
                  <span className="float-right">{order.clientCredits}</span>
                </li>
                <li className="list-group-item">
                  Crédits utilisé pour la régularisation
                  <span className="float-right">{order.clientCreditsUsed}</span>
                </li>
                <li className="list-group-item">
                  Montant à payer pour la régularisation
                  <span className="float-right">{order.toPayAmount}</span>
                </li>
                <li className="list-group-item">
                  Montant remboursé pour la régularisation
                  <span className="float-right">{order.toRefundAmount}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Choix du moyen de paiement</h5>
                  <select
                    className="custom-select"
                    id="paymentChoice"
                    name="paymentChoice"
                    onChange={e =>
                      setPaymentSelected(JSON.parse(e.target.value))
                    }
                    // onChange={e => {
                    //   paymentChosen.current = JSON.parse(e.target.value)
                    // }}
                    value={
                      paymentSelected ? JSON.stringify(paymentSelected) : null
                    }
                  >
                    <option disabled value={null} selected>
                      Choisir un moyen de paiement...
                    </option>
                    {[
                      { id: "SMS_PAYMENT", name: "Paiement par SMS" },
                      { id: "EMAIL_PAYMENT", name: "Paiement par Email" },
                      { id: "CREDIT_CARD_PAYMENT", name: "Paiement par CB" },
                      { id: "SECRET_BOX", name: "Paiement Secret Box" },
                    ].map(paymentChoice => (
                      <option
                        key={paymentChoice.name}
                        value={JSON.stringify(paymentChoice)}
                      >
                        {paymentChoice.name}
                      </option>
                    ))}
                  </select>

                  {paymentSelected && paymentSelected.id === "SMS_PAYMENT" ? (
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <PhoneInput
                          country="fr"
                          value={phoneValue.value}
                          onChange={(value, country, e, formattedValue) =>
                            setPhoneValue({
                              ...phoneValue,
                              value,
                              all: { value, country, e, formattedValue },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <button
                          disabled={buttonLoading}
                          onClick={handleSmsSend}
                          type="button"
                          className="btn btn-primary"
                        >
                          {!smsStatus || !smsStatus.current
                            ? "Envoyer le sms de paiement"
                            : "Envoyer un nouvel sms"}
                          {buttonLoading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>

                      {smsStatus && smsStatus.current ? (
                        <div className="col-md-6 mt-4">
                          Expires in {smsStatus.current.countDown}
                          <div className="progress">
                            <div
                              className={`progress-bar ${getProgressBarColor()}`}
                              role="progressbar"
                              style={{ width: `${getCountdownPercentage()}%` }}
                              aria-label="progressbar"
                              aria-valuenow={getCountdownPercentage()}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                        </div>
                      ) : null}
                      {smsTimerExpired && (
                        <div className={style.smsTimerExpired}>
                          Le lien envoyé n’est plus valable
                        </div>
                      )}
                    </div>
                  ) : null}

                  {paymentSelected && paymentSelected.id === "EMAIL_PAYMENT" ? (
                    <form>
                      <div className="form-row mt-4">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <label htmlFor="email-payment">Email</label>
                          <div className="input-group">
                            <input
                              type="email"
                              required
                              className="form-control"
                              id="email-payment"
                              onChange={e => setEmailValue(e.target.value)}
                              value={emailValue}
                            />
                          </div>
                        </div>
                        <div
                          className={cn([
                            "col-sm-6 col-md-6 col-lg-6",
                            style.submitCol,
                          ])}
                        >
                          <button
                            disabled={buttonLoading}
                            onClick={handleEmailSend}
                            className="btn btn-primary"
                            type="submit"
                          >
                            Envoyer l'email de paiement
                            {buttonLoading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </div>

                      {emailStatus && emailStatus.current ? (
                        <div className="col-md-6 mt-4">
                          Expires in {emailStatus.current.countDown}
                          <div className="progress">
                            <div
                              className={`progress-bar ${getProgressBarColor()}`}
                              role="progressbar"
                              style={{
                                width: `${getCountdownPercentage()}%`,
                              }}
                              aria-label="progressbar"
                              aria-valuenow={getCountdownPercentage()}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                        </div>
                      ) : null}
                      {emailTimerExpired && (
                        <div className={style.emailTimerExpired}>
                          Le lien envoyé n’est plus valable
                        </div>
                      )}
                    </form>
                  ) : null}

                  {paymentSelected &&
                  paymentSelected.id === "CREDIT_CARD_PAYMENT" ? (
                    <div className="row mt-4">
                      <div className="col-md-12">
                        {stripeToken && stripe.current ? (
                          <Elements stripe={stripe.current}>
                            <CardForm
                              paymentToken={stripeToken}
                              clientData={client}
                            />
                          </Elements>
                        ) : (
                          <p>Chargement...</p>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {paymentSelected && paymentSelected.id === "SECRET_BOX" ? (
                    <div className="form-row mt-4">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <label htmlFor="secret-box-amount">Montant (€)</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="secret-box-amount"
                            onChange={e => {
                              const val = e.target?.value;

                              setSecretBoxAmount(val?.length ? val.replace(/^[A-Za-z]+$/, '') : '');
                            }}
                            value={secretBoxAmount}
                            placeholder="Montant (€)"
                          />
                        </div>
                      </div>
                      <div
                        className={cn([
                          "col-sm-6 col-md-6 col-lg-6",
                          style.submitCol,
                        ])}
                      >
                        <button
                          disabled={
                            Number(secretBoxAmount) <= 0 || buttonLoading
                          }
                          onClick={handleSecretBoxValidate}
                          type="button"
                          className="btn btn-primary"
                        >
                          Valider
                          {buttonLoading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  ) : null}
                  <div />
                  {globalMessage.isActive ? (
                    <div className="mt-4">
                      <GlobalMessage
                        isActive={globalMessage.isActive}
                        content={globalMessage.message}
                        className={globalMessage.className}
                        onClose={() =>
                          setGlobalMessage({
                            ...globalMessage,
                            isActive: false,
                          })
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-right mb-4">
              <button
                onClick={() => setDefferedPaymentsModalOpen(true)}
                type="button"
                className="btn btn-secondary"
              >
                Paiements différés
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        isDisplayed={defferedPaymentsModalOpen}
        onClose={() => setDefferedPaymentsModalOpen(false)}
      >
        <div className="row mt-4">
          <div className="col-md-12">
            <h5>
              Sélectionner le moyen de paiement différé qui sera utilisé pour la
              régularisation
            </h5>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <select
              className="custom-select"
              id="paymentOtherMeans"
              name="paymentOtherMeans"
              onChange={e => setDefferredPaymentValue(Number(e.target.value))}
              value={defferredPaymentValue}
            >
              {otherPaymentMeans.map(paymentMean => (
                <option
                  key={paymentMean.name}
                  value={paymentMean.paymentMeanId}
                >
                  {paymentMean.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <input
              type="checkbox"
              className="mr-2"
              id="preventSendEmail"
              name="preventSendEmail"
              checked={!!defferredPaymentsPreventSending}
              onChange={e =>
                setDefferredPaymentsPreventSending(
                  !defferredPaymentsPreventSending
                )
              }
            />
            <label className="form-check-label" htmlFor="preventSendEmail">
              Ne pas envoyer l'email de confirmation de commande
            </label>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <button
              disabled={buttonLoading}
              onClick={handlePreOrderValidate}
              type="button"
              className="btn btn-primary"
            >
              Sauvegarder{" "}
              {buttonLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </WithMenu>
  )
}

ChoosePayment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ChoosePayment
