// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useExtrasApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getExtras = options => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/extras${params}`)
  }

  const getExtraWithId = extraId =>
    request("GET", `${API_STAGES_URL}/v1/extras/${extraId}`)

  const updateExtra = data =>
    request("PUT", `${API_STAGES_URL}/v1/extras/${data.id}`, data)

  const updateExtras = data =>
    request("PUT", `${API_STAGES_URL}/v1/extras`, { extras: data })

  const createExtra = data =>
    request("POST", `${API_STAGES_URL}/v1/extras`, data)

  return [
    requestState,
    payload,
    error,
    {
      getExtras,
      getExtraWithId,
      updateExtra,
      updateExtras,
      createExtra,
    },
  ]
}

export default useExtrasApi
