import "es6-shim"
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

// TODO: UNCOMMENT TO ENABLE
// if (ENV !== "dev") {
//   Sentry.init({
//     dsn:
//       "https://ee38f026dd6e4fe68fed788ad6f61ff2@o125949.ingest.sentry.io/5429740",
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     environment: ENV,
//   })
// }

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
