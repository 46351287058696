import useApi from "./useApi"
import { API_STAGES_URL } from "../../constants/constants"

const useThemesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getThemes = () => request("GET", `${API_STAGES_URL}/v1/themes`)

  const getThemeById = themeId =>
    request("GET", `${API_STAGES_URL}/v1/themes/${themeId}`)

  const updateTheme = theme =>
    request("PUT", `${API_STAGES_URL}/v1/themes/${theme.id}`, theme)

  const createTheme = theme =>
    request("POST", `${API_STAGES_URL}/v1/themes`, theme)

  const deleteTheme = id =>
    request("DELETE", `${API_STAGES_URL}/v1/themes/${id}`)

  const reorderThemes = (reorderedThemeId, newFollowingThemeId) =>
    request("POST", `${API_STAGES_URL}/v1/themes:reorder`, {
      reorderedThemeId,
      newFollowingThemeId,
    })

  return [
    requestState,
    payload,
    error,
    {
      getThemes,
      getThemeById,
      updateTheme,
      createTheme,
      deleteTheme,
      reorderThemes,
    },
  ]
}

export default useThemesApi
