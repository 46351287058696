// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL, API_SE_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useProductsApi = () => {
  const [requestState, payload, error, request, rawRequest] = useApi()

  const getProducts = options => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/products${params}`)
  }

  const getProductById = id => {
    return request("GET", `${API_STAGES_URL}/v1/products/${id}`)
  }

  const getProductsStocks = () =>
    request("GET", `${API_STAGES_URL}/v1/productsstocks`)

  const getProductsStocksRange = (min, max) =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/productsstocks?minWeekId=${min}&maxWeekId=${max}`
    )

  const updateProductPrice = (productId, yearId, seasonId, value) =>
    rawRequest(
      "PUT",
      `${API_STAGES_URL}/v1/products/${productId}/prices/${yearId}/${seasonId}`,
      {
        value,
      }
    )

  const orderBookingProduct = (productId, targetProductId) =>
    request("POST", `${API_STAGES_URL}/v1/products/${productId}:order`, {
      targetProductId,
    })

  const getProductsSe = () =>
    request(
      "GET",
      `${API_SE_URL}/v1/products`,
      null,
      { axiosOptions: { headers: {} } },
      true
    )

  const getCampusProducts = params =>
    request(
      "GET",
      `${API_SE_URL}/v1/products`,
      null,
      {
        axiosOptions: {
          params,
          headers: {},
        },
      },
      true
    )

  const updateCampusProductStock = ({
    productId,
    sexId,
    weekId,
    quantity,
    active,
  }) =>
    request("POST", `${API_SE_URL}/v1/products/${productId}:set-stock`, {
      sexId,
      weekId,
      quantity,
      active,
    })

  const postProductConfig = product =>
    request(
      "POST",
      `${API_SE_URL}/v1/products`,
      product,
      { axiosOptions: { headers: {} } },
      true
    )

  const putProductsConfig = product =>
    request(
      "PUT",
      `${API_SE_URL}/v1/products`,
      product,
      { axiosOptions: { headers: {} } },
      true
    )

  const putProductConfig = (productID, product) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/products/${productID}`,
      product,
      { axiosOptions: { headers: {} } },
      true
    )

  const postProductSchool = (productId, schoolId, active = false) =>
    request(
      "POST",
      `${API_SE_URL}/v1/products/${productId}:set-school`,
      {
        schoolId,
        active,
      },
      { axiosOptions: { headers: {} } },
      true
    )

  const postProductGradeStatus = (productId, grade, active = false) =>
    request(
      "POST",
      `${API_SE_URL}/v1/products/${productId}:set-grade`,
      {
        grade,
        active,
      },
      { axiosOptions: { headers: {} } },
      true
    )

  const reorderProduct = (reorderedProductId, newPrecedingProductId) =>
    request(
      "POST",
      `${API_SE_URL}/v1/products:reorder`,
      {
        reorderedProductId,
        newPrecedingProductId,
      },
      { axiosOptions: { headers: {} } },
      true
    )

  return [
    requestState,
    payload,
    error,
    {
      getProducts,
      getProductById,
      getProductsStocks,
      updateProductPrice,
      orderBookingProduct,
      updateCampusProductStock,
      getProductsStocksRange,
      getProductsSe,
      getCampusProducts,
      postProductConfig,
      putProductsConfig,
      putProductConfig,
      postProductSchool,
      postProductGradeStatus,
      reorderProduct,
    },
  ]
}

export default useProductsApi
