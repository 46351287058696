const FAMILY = {
  CAMP: 1,
  EXTRA: 2,
  ACCOMMODATION: 3,
}

export const isProductUsedInPackage = (product, packages) => {
  for (const [, p] of Object.entries(packages)) {
    if (p.archived === false) {
      switch (product.product.familyId) {
        case FAMILY.CAMP: {
          if (product.id === p.campId) {
            return true
          }
          break
        }
        case FAMILY.EXTRA: {
          if (
            p.extras.find(
              extra => extra.id === product.id && extra.fixedQuantity > 0
            ) !== undefined
          ) {
            return true
          }
          break
        }
        case FAMILY.ACCOMMODATION: {
          if (
            p.accommodationIds.find(accId => accId === product.id) !== undefined
          ) {
            return true
          }
          break
        }
        default: {
          break
        }
      }
    }
  }
  return false
}

export const whichPackagesContainsProduct = (product, packages) => {
  const packagesFound = []
  for (const [, p] of Object.entries(packages)) {
    if (p.archived === false) {
      switch (product.product.familyId) {
        case FAMILY.CAMP: {
          if (product.id === p.campId) {
            packagesFound.push(p)
          }
          break
        }
        case FAMILY.EXTRA: {
          if (
            p.extras.find(
              extra => extra.extraId === product.id && extra.fixed > 0
            ) !== undefined
          ) {
            packagesFound.push(p)
          }
          break
        }
        case FAMILY.ACCOMMODATION: {
          if (
            p.accommodationIds.find(accId => accId === product.id) !== undefined
          ) {
            packagesFound.push(p)
          }
          break
        }
        default: {
          break
        }
      }
    }
  }
  return packagesFound
}
