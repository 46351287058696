/**
 * @param {object} values player form values
 * @param {object} clientPlayers players from a client obtained from the api
 */
export default (values, clientPlayers, editingPlayer) => {
  const formErrors = {
    global: null,
    firstname: null,
    lastname: null,
  }
  if (
    typeof values.firstname === "string" &&
    typeof values.lastname === "string"
  ) {
    const players = Object.keys(clientPlayers.players).map(player => ({
      firstname: clientPlayers.players[player].firstname || "",
      lastname: clientPlayers.players[player].lastname || "",
      uid: clientPlayers.players[player].uid,
    }))
    players.forEach(player => {
      if (
        player.firstname.toUpperCase() === values.firstname.toUpperCase() &&
        player.lastname.toUpperCase() === values.lastname.toUpperCase() &&
        player.uid !== editingPlayer
      ) {
        formErrors.global =
          "Un joueur avec le même prénom et nom existe déja pour ce client"
        formErrors.firstname = "Erreur de saisie"
        formErrors.lastname = "Erreur de saisie"
      }
    })
  }
  return formErrors
}
