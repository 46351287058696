// Hooks
import useApi from "./useApi"

// Utils & misc
import { COACH_HOSTNAME } from "../../constants/constants"


const useRotationsForCoachsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getRotationsForCoachs = (weekInput) => {
    const weekInDate = typeof weekInput === 'object' ? weekInput : new Date(weekInput)
    const options = {year: "numeric", month: "numeric", day: "numeric"};
    const week = new Intl.DateTimeFormat('fr-FR', options).format(weekInDate);
    return request("GET", `${COACH_HOSTNAME}/v1/weeks/${week.split('/').reverse().join('-')}`)
  }


  return [
    requestState,
    payload,
    error,
    {
      getRotationsForCoachs,
    },
  ]
}

export default useRotationsForCoachsApi
