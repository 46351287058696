import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils
import cn from "../../../utils/cn"

import styles from "./ClientNavigation.module.css"

const permissions = [
  PERMISSIONS.PERM_CLIENTS_DISPLAY,
  PERMISSIONS.PERM_CLIENTS_UPDATE,

  PERMISSIONS.PERM_PLAYERS_DISPLAY,
  PERMISSIONS.PERM_PLAYERS_CREATE,
  PERMISSIONS.PERM_PLAYERS_UPDATE,
  PERMISSIONS.PERM_PLAYERS_DISPLAY_BORROWERS,
  PERMISSIONS.PERM_PLAYERS_DELETE_BORROWER,
  PERMISSIONS.PERM_PLAYERS_SEND_LINK_EMAIL,

  PERMISSIONS.PERM_ORDERS_DISPLAY,
  PERMISSIONS.PERM_ORDERS_COMMENT,
  PERMISSIONS.PERM_ORDERS_RESOLVE,

  PERMISSIONS.PERM_ORDERS_ARTICLES_DISPLAY,
  PERMISSIONS.PERM_ORDERS_ARTICLES_CREATE,
  PERMISSIONS.PERM_ORDERS_ARTICLES_UPDATE,
  
  PERMISSIONS.PERM_SHUTTLES_DISPLAY,
  PERMISSIONS.PERM_SHUTTLES_UPDATE,

  PERMISSIONS.PERM_ORDERS_BALANCE_UPDATE,
]

const ClientNavigation = ({ client: { uid }, size }) => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  return (
    <div
      className={cn([
        "btn-group",
        size === "small" ? "btn-group-sm" : "",
        styles.wrapper,
      ])}
      role="group"
      aria-label="Basic example"
    >
      {rolePermissions[PERMISSIONS.PERM_CLIENTS_DISPLAY] ||
      rolePermissions[PERMISSIONS.PERM_CLIENTS_UPDATE] ? (
        <Link
          to={`/clients/edit/${uid}`}
          type="button"
          className="btn btn-secondary"
        >
          Profil
        </Link>
      ) : null}
      {rolePermissions[PERMISSIONS.PERM_PLAYERS_DISPLAY] ||
      rolePermissions[PERMISSIONS.PERM_PLAYERS_CREATE] ||
      rolePermissions[PERMISSIONS.PERM_PLAYERS_UPDATE] ||
      rolePermissions[PERMISSIONS.PERM_PLAYERS_DISPLAY_BORROWERS] ||
      rolePermissions[PERMISSIONS.PERM_PLAYERS_DELETE_BORROWER] ||
      rolePermissions[PERMISSIONS.PERM_PLAYERS_SEND_LINK_EMAIL] ? (
        <Link
          to={`/client/${uid}/players/list`}
          type="button"
          className="btn btn-secondary"
        >
          Joueurs
        </Link>
      ) : null}
      {rolePermissions[PERMISSIONS.PERM_ORDERS_DISPLAY] ||
      rolePermissions[PERMISSIONS.PERM_ORDERS_COMMENT] ||
      rolePermissions[PERMISSIONS.PERM_ORDERS_RESOLVE] ? (
        <Link
          to={`/client/${uid}/orders/list`}
          type="button"
          className="btn btn-secondary"
        >
          Commandes
        </Link>
      ) : null}
      {rolePermissions[PERMISSIONS.PERM_ORDERS_ARTICLES_DISPLAY] ||
      rolePermissions[PERMISSIONS.PERM_ORDERS_ARTICLES_CREATE] ||
      rolePermissions[PERMISSIONS.PERM_ORDERS_ARTICLES_UPDATE] ? (
        <Link
          to={`/client/${uid}/articles/list`}
          type="button"
          className="btn btn-secondary"
        >
          Articles
        </Link>
      ) : null}
      {rolePermissions[PERMISSIONS.PERM_SHUTTLES_DISPLAY] ||
      rolePermissions[PERMISSIONS.PERM_SHUTTLES_UPDATE] ? (
        <Link
          to={`/client/${uid}/shuttles/list`}
          type="button"
          className="btn btn-secondary"
        >
          Navettes
        </Link>
      ) : null}
      {rolePermissions[PERMISSIONS.PERM_ORDERS_BALANCE_UPDATE] ? (
        <Link
          to={`/client/${uid}/balance`}
          type="button"
          className="btn btn-secondary"
        >
          Balance
        </Link>
      ) : null}
    </div>
  )
}
ClientNavigation.propTypes = {
  size: PropTypes.string,
}
ClientNavigation.defaultProps = {
  size: "normal",
}

export default ClientNavigation
