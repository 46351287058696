import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap4-modal"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import ArticlesList from "./articles-list"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import useClientsApi from "../../../../hooks/Api/useClientsApi"
import useArticlesApi from "../../../../hooks/Api/useArticlesApi"
import usePermissionsState from "../../../../hooks/usePermissionsState"

import useYearsApi from "../../../../hooks/Api/useYearsApi"
import useSeasonsApi from "../../../../hooks/Api/useSeasonsApi"
import useWeeksApi from "../../../../hooks/Api/useWeeksApi"
import usePlayersApi from "../../../../hooks/Api/usePlayersApi"
import useFamiliesApi from "../../../../hooks/Api/useFamiliesApi"
import useProductsApi from "../../../../hooks/Api/useProductsApi"
import useDiscountsApi from "../../../../hooks/Api/useDiscountsApi"
import useLocalesApi from "../../../../hooks/Api/useLocalesApi"
import useMotivesApi from "../../../../hooks/Api/useMotivesApi"
import usePreOrderApi from "../../../../hooks/Api/usePreOrderApi"
import useOrdersApi from "../../../../hooks/Api/useOrdersApi"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

// Utils & misc
import convertArticleData from "./convert-article-data"
import {
  buildOrderedWeeks,
  buildOrderedFutureWeeks,
  buildPlayers,
  buildFamilies,
  buildProducts,
  buildStocksPrices,
  buildDiscounts,
} from "../../../../utils/articlesUtils"

import style from "./Articles.module.css"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_ORDERS_ARTICLES_CREATE,
  PERMISSIONS.PERM_ORDERS_ARTICLES_UPDATE,
]

const Articles = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const history = useHistory()
  const [covidWarningModalOpen, setCovidWarningModalOpen] = useState(false)
  const [orderSyncing, setOrderSyncing] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [articlesProps, setArticlesProps] = useState({})
  const [, clientData, , { getClientWithId }] = useClientsApi()
  const [putClientPlayerState,, putClientPlayerError, { putClientPlayerTennisData }] = useClientsApi()
  const [, articlesData, , { getArticlesFromClient }] = useArticlesApi()
  const [, seasonsData, , { getSeasons }] = useSeasonsApi()
  const [, yearsData, , { getYears }] = useYearsApi()
  const [, weeksData, , { getWeeks }] = useWeeksApi()
  const [, playersData, , { getClientsPlayers }] = usePlayersApi()
  const [, familiesData, , { getFamilies }] = useFamiliesApi()
  const [, productsData, , { getProducts }] = useProductsApi()
  const [, productsStocksData, , { getProductsStocksRange }] = useProductsApi()
  const [, discountData, , { getDiscounts }] = useDiscountsApi()
  const [, discountsTypesData, , { getDiscountsType }] = useDiscountsApi()
  const [, localesData, , { getLocales }] = useLocalesApi()
  const [, motivesData, , { getMotives }] = useMotivesApi()

  const [, , , { getOrderById }] = useOrdersApi()
  const [, , postPreOrderError, { postPreOrder }] = usePreOrderApi()

  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetArticles = useCallback(getArticlesFromClient, [])
  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetYears = useCallback(getYears, [])
  const savedGetWeeks = useCallback(getWeeks, [])
  const savedGetPlayers = useCallback(getClientsPlayers, [])
  const savedGetFamilies = useCallback(getFamilies, [])
  const savedGetProducts = useCallback(getProducts, [])
  const savedGetProductsStocks = useCallback(getProductsStocksRange, [])
  const savedGetDiscounts = useCallback(getDiscounts, [])
  const savedGetDiscountsTypes = useCallback(getDiscountsType, [])
  const savedGetLocales = useCallback(getLocales, [])
  const savedGetMotives = useCallback(getMotives, [])

  const exportCollectionToArray = (initialPayload, collection) => {
    if (initialPayload && initialPayload[collection])
      return Object.keys(initialPayload[collection]).map(
        i => initialPayload[collection][i]
      )
    return []
  }

  useEffect(() => {
    if (weeksData) {
      const weeks = exportCollectionToArray(weeksData, "weeks")
      const builtWeeks = buildOrderedWeeks(weeks, "ids")
      const min = builtWeeks[0]
      const max = builtWeeks[builtWeeks.length - 1]

      savedGetProductsStocks(min, max)
    }
    return () => {}
  }, [savedGetProductsStocks, weeksData])

  useEffect(() => {
    setArticlesProps({})
    savedGetClientWithId(match.params.clientId)
    savedGetArticles(match.params.clientId)
    savedGetSeasons()
    savedGetYears()
    savedGetWeeks()
    savedGetPlayers(match.params.clientId)
    savedGetFamilies()
    savedGetProducts()
    savedGetDiscounts()
    savedGetDiscountsTypes()
    savedGetLocales()
    savedGetMotives()
  }, [
    savedGetClientWithId,
    match.params.clientId,
    savedGetArticles,
    savedGetSeasons,
    savedGetYears,
    savedGetWeeks,
    savedGetPlayers,
    savedGetFamilies,
    savedGetProducts,
    savedGetProductsStocks,
    savedGetDiscounts,
    savedGetDiscountsTypes,
    savedGetLocales,
    savedGetMotives,
  ])

  useEffect(() => {
    if (
      weeksData &&
      seasonsData &&
      yearsData &&
      articlesData &&
      playersData &&
      familiesData &&
      productsData &&
      productsStocksData &&
      discountData &&
      discountsTypesData &&
      clientData &&
      localesData &&
      savedGetMotives
    ) {
      const weeksFormatted = exportCollectionToArray(weeksData, "weeks")
      const playersFormatted = exportCollectionToArray(playersData, "players")
      const familiesFormatted = exportCollectionToArray(
        familiesData,
        "families"
      )
      const productsStocksFormatted = exportCollectionToArray(
        productsStocksData,
        "productsStocks"
      )
      const discountsFormatted = exportCollectionToArray(
        discountData,
        "discounts"
      )
      const discountsTypeFormatted = exportCollectionToArray(
        discountsTypesData,
        "discountsTypes"
      )
      const { stocksBuilt, pricesBuilt } = buildStocksPrices(
        productsStocksFormatted
      )

      setArticlesProps({
        articles: articlesData
          ? exportCollectionToArray(articlesData, "articles").map(article => {
              return {
                ...article,
                productFamilyId:
                  productsData.products[`${article.productId}`].familyId,
              }
            })
          : null,
        // Array of week ids, starting from current date, to 5 months forward
        orderedWeeksIds: buildOrderedWeeks(weeksFormatted, "ids"),
        orderedFutureWeeksIds: buildOrderedFutureWeeks(weeksFormatted),
        weeks: weeksFormatted.reduce((acc, week) => {
          acc[week.id] = { start: week.start }
          return acc
        }, {}),
        orderedPlayersIds: playersFormatted
          .map(player => player.uid)
          .sort((a, b) => a - b),
        players: buildPlayers(playersFormatted),
        orderedFamilysIds: Object.keys(familiesData.families)
          .map(familyId => Number(familyId))
          .sort((a, b) => a - b),
        familys: buildFamilies(familiesFormatted),
        orderedProductsIds: Object.keys(productsData.products).map(product =>
          Number(product)
        ),
        products: buildProducts(productsData),
        stocks: stocksBuilt,
        prices: pricesBuilt,
        orderedDiscountsIds: discountsFormatted
          .filter(
            discount => discount.quantity > 0 && discount.start && discount.end
          )
          .map(discount => discount.id),
        discounts: buildDiscounts(
          discountsFormatted.filter(
            discount => discount.quantity > 0 && discount.start && discount.end
          )
        ),
        orderedDiscountTypesIds: Object.keys(
          discountsTypesData.discountsTypes
        ).map(discountId => Number(discountId)),

        discountTypes: discountsTypeFormatted.reduce((acc, discountType) => {
          acc[discountType.id] = { name: discountType.name }
          return acc
        }, {}),
        balance: clientData.balance,
        orderedLocalesIds: Object.keys(localesData.locales).map(locale =>
          Number(locale)
        ),
        locales: Object.keys(localesData.locales).reduce((acc, locale) => {
          acc[locale] = { code: localesData.locales[locale].code }
          return acc
        }, {}),
        billingAddress: clientData.defaultBillingAddress,
        submitUrl: "",
        motives: Object.keys(motivesData.motives).reduce((acc, motive) => {
          acc[motive] = {
            code: motivesData.motives[motive].code,
            forPurchase: motivesData.motives[motive].forPurchase,
            forCancellation: motivesData.motives[motive].forCancellation,
          }
          return acc
        }, {}),
        orderedMotivesIds: Object.keys(motivesData.motives).map(motive =>
          Number(motive)
        ),
      })
    }
    return () => {}
  }, [
    weeksData,
    yearsData,
    seasonsData,
    articlesData,
    playersData,
    familiesData,
    productsData,
    productsStocksData,
    discountData,
    discountsTypesData,
    clientData,
    localesData,
    motivesData,
    savedGetMotives,
  ])

  const cancelOrder = () => {
    setCovidWarningModalOpen(false)
    setOrderSyncing(false)
  }

  const confirmOrder = async (orderD = null) => {
    let articlesBody = orderD
    setCovidWarningModalOpen(false)

    if (!articlesBody) {
      articlesBody = orderData
    }

    if (articlesBody.discountId === -1) delete articlesBody.discountId

    postPreOrder(match.params.clientId, articlesBody).then((res) => {
      if (res?.data) {
        const { orderId } = res.data
  
        setArticlesProps({})
        savedGetClientWithId(match.params.clientId)
        savedGetArticles(match.params.clientId)
        savedGetSeasons()
        savedGetYears()
        savedGetWeeks()
        savedGetPlayers(match.params.clientId)
        savedGetFamilies()
        savedGetProducts()
        savedGetDiscounts()
        savedGetDiscountsTypes()
        savedGetLocales()
        savedGetMotives()
        setOrderSyncing(false)

        const bodyHasArticles = articlesBody.articles?.length > 0;
        const bodyHasUpdates = articlesBody.updates?.length > 0;
  
        if (
          (bodyHasArticles || bodyHasUpdates)
          && orderId
        ) {
          getOrderById(orderId).then(order => {
            if (Number(order?.data?.sToPayAmount) === 0) {
              history.push({
                pathname: `/client/${match.params.clientId}/orders/list`,
                state: {
                  status: "AUTO_PAID",
                },
              })
            } else {
              history.push({
                pathname: `/client/${match.params.clientId}/order/${orderId}/payment-choice`,
                state: {},
              })
            }
          })
        }
      }
    });
  }

  useEffect(() => {
    if (postPreOrderError) {
      setOrderSyncing(false)
      const errorCode = postPreOrderError?.data?.code;

      if (
        postPreOrderError.status === 400 
        && errorCode === 'product_out_of_stock'
        && postPreOrderError.data?.description
      ) {
        setGlobalMessage({
          isActive: true,
          message: (
            <span>{postPreOrderError.data.description}</span>
          ),
          className: "alert alert-danger",
        })
      } else {
        setGlobalMessage({
          isActive: true,
          message: (
            <span>Impossible d'ajouter ces commandes </span>
          ),
          className: "alert alert-danger",
        })
      }
    }
  }, [postPreOrderError]);

  const handleSubmitArticles = data => {
    setOrderSyncing(true)
    const articlesBody = convertArticleData(data)

    let covidPeriodChosen = false
    if (articlesBody.articles.length) {
      for (let i = 0; i < articlesBody.articles.length; i += 1) {
        const { weekId } = articlesBody.articles[i]

        if (weekId >= 79 && weekId <= 87) {
          covidPeriodChosen = true
          break
        }
      }
    }
    if (
      covidPeriodChosen &&
      !articlesBody.freeInsurance &&
      articlesBody.insurance
    ) {
      setOrderData(articlesBody)
      setCovidWarningModalOpen(true)
    } else confirmOrder(articlesBody)
  }

  const handleMessageClose = e => {
    setGlobalMessage({ ...globalMessage, isActive: false })
  }

  return (
    <WithMenu>
      <Modal
        visible={covidWarningModalOpen}
        dialogClassName="modal-confirmation modal-confirm-insurance"
      >
        <div className="modal-header">
          <div className="text-xl modal-header-title">
            <b>Confirmer l'assurance annulation</b>
          </div>
          <div
            onKeyDown={null}
            role="button"
            tabIndex={[0]}
            className="close"
            data-dismiss="modal"
            onClick={cancelOrder}
          >
            <span className="icon-cross" aria-hidden="true" />
            <span className="sr-only">Close</span>
          </div>
        </div>

        <div className="modal-body confirm-finalize-body">
          <p>
            Un ou plusieurs articles ont été sélectionnés pendant la période de
            réouverture (avec assurance annulation offerte), êtes vous sûr de la
            facturer ?
          </p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default btn-md"
            onClick={cancelOrder}
          >
            Annuler
          </button>
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={() => confirmOrder()}
          >
            Confirmer
          </button>
        </div>
      </Modal>
      <div className={style.pageHeader}>
        <h1>
          {`Clients 
          ${
            clientData
              ? ` / ${clientData.firstname} ${clientData.lastname}`
              : null
          } / Articles`}
        </h1>
      </div>
      <div className={style.topMenu}>
        <ClientNavigation
          client={{ ...clientData, uid: match.params.clientId }}
        />
      </div>
      <div>
        {articlesProps.articles ? (
          <ArticlesList
            {...articlesProps}
            orderSyncing={orderSyncing}
            handleSubmitArticles={handleSubmitArticles}
            putClientPlayerTennisData={putClientPlayerTennisData}
            putClientPlayerState={putClientPlayerState}
            putClientPlayerError={putClientPlayerError}
            rolePermissions={rolePermissions}
            globalMessage={globalMessage}
            handleMessageClose={handleMessageClose}
            clientId={clientData?.id}
          />
        ) : (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </WithMenu>
  )
}

Articles.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default Articles
