/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useCallback, useState, useContext } from "react"
import PropTypes from "prop-types"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import bootstrap from "bootstrap" // eslint-disable-line no-unused-vars
import { SPA_HOSTNAME, MENU_ENTRIES, ENV } from "../../../constants/constants"

// Components
import HeaderBanner from "../../atoms/HeaderBanner/HeaderBanner"
import DropdownList from "./DropdownList";
import MenuItem from "./MenuItem";

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useUtilsApi from "../../../hooks/Api/useUtilsApi"


// Utils & misc
import cn from "../../../utils/cn"
import { filterMenuEntries } from "../../../utils/rolesHandler"
import { KeycloakAuthContext } from "../KeycloakAuthProvider"

// Style
import style from "./WithMenu.module.css"


const WithMenu = ({ children }) => {
  const { t: translate } = useTranslation();
  const history = useHistory()
  const location = useLocation()
  const {
    setLocales,
    clearAuth,
    context: { userInfo },
  } = useAppContext()

  const [verticalMenuOpen, setVerticalMenuOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState(null)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(location.pathname)
  const [keycloakUser, setKeycloakUser] = useState(null)
  const [, , , { getLocales }] = useUtilsApi()

  const { keycloak } = useContext(KeycloakAuthContext)

  const savedGetLocales = useCallback(getLocales, [])
  const savedSetLocales = useCallback(setLocales, [])

  useEffect(() => {
    if (userInfo) setCurrentMenu(filterMenuEntries(userInfo))
  }, [userInfo])

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.loadUserInfo().then(r => setKeycloakUser(r))
    }

    return () => {}
  }, [keycloak, keycloak.authenticated])

  useEffect(() => {
    savedGetLocales().then(res => {
      if (res && res.data) {
        savedSetLocales(res.data.locales)
      }
    })
    return () => {}
  }, [savedGetLocales, savedSetLocales])

  useEffect(() => {
    window.addEventListener("click", e => {
      const adminContainer = document.getElementById("adminContainer")
      if (adminContainer && adminContainer.contains(e.target)) {
        setVerticalMenuOpen(false)
      }
    })
  }, [])

  const initialDropdownOpen = () => {
    let dropOpen = ""
    const routes = MENU_ENTRIES.filter(route => route.dropdown)
    for (let i = 0; i < routes.length; i += 1) {
      const route = routes[i]
      for (const [key, value] of Object.entries(route.dropdown)) { // eslint-disable-line no-unused-vars
        if (value.path === location.pathname) dropOpen = route
      }
    }
    setDropdownMenuOpen(dropOpen)
  }
  const savedInitialDropdownOpen = useCallback(initialDropdownOpen, [])

  useEffect(() => {
    savedInitialDropdownOpen()
  }, [savedInitialDropdownOpen])

  const logout = () => {
    clearAuth()
    keycloak.logout({
      redirectUri: `https://${SPA_HOSTNAME}`,
    })
  }

  const handleDropdownOpenChange = (dropDown, origin) => {
    setDropdownMenuOpen(
      dropdownMenuOpen.path === dropDown.path && origin !== "minified"
        ? false
        : dropDown
    )
  }

  const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
  return (
    <>
      {userInfo ? (
        <>
          {/* TOP SIDEBAR */}
          <HeaderBanner />
          <input
            type="checkbox"
            className={style.openSidebarMenu}
            id="openSidebarMenu"
            onChange={e => setVerticalMenuOpen(!verticalMenuOpen)}
            checked={verticalMenuOpen}
          />
          <label
            htmlFor="openSidebarMenu"
            className={style.sidebarIconToggle}
            style={{
              left: mac ? 39 : 30,
            }}
          >
            <div className={cn([style.spinner, style.diagonal, style.part1])} />
            <div className={cn([style.spinner, style.horizontal])} />
            <div className={cn([style.spinner, style.diagonal, style.part2])} />
          </label>

          {/* VERTICAL SIDEBAR */}
          <div className={style.sidebarMenu} id="sidebarMenu">
            <ul
              className={cn([
                style.sidebarMenuInner,
                !verticalMenuOpen ? style.minimized : "",
              ])}
            >
              {verticalMenuOpen ? (
                <li className={style.sidebarMenuHeader}>
                  {keycloakUser ? (
                    <>
                      {keycloakUser.email}
                      <span
                        onKeyDown={null}
                        role="button"
                        onClick={logout}
                        tabIndex={0}
                      >
                        Déconnexion
                      </span>
                    </>
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </li>
              ) : null}
              {currentMenu &&
                currentMenu.map((route, index) => {
                  return route && !route.dropdown ? (
                    <MenuItem
                      key={index}
                      setVerticalMenuOpen={setVerticalMenuOpen}
                      isDropdown={false}
                      dropdownOpen={false}
                      history={history}
                      location={location}
                      verticalMenuOpen={verticalMenuOpen}
                      route={route}
                    />
                  ) : (
                    <div key={index}>
                      {verticalMenuOpen ? (
                        <DropdownList
                          setVerticalMenuOpen={setVerticalMenuOpen}
                          key={index}
                          route={route}
                          translate={translate}
                          handleDropdownOpenChange={handleDropdownOpenChange}
                          dropdownOpen={route.path === dropdownMenuOpen.path}
                        />
                      ) : (
                        <MenuItem
                          key={index}
                          setVerticalMenuOpen={setVerticalMenuOpen}
                          handleDropdownOpenChange={handleDropdownOpenChange}
                          isDropdown
                          dropdownOpen={route.path === dropdownMenuOpen.path}
                          history={history}
                          location={location}
                          verticalMenuOpen={verticalMenuOpen}
                          route={route}
                        />
                      )}
                    </div>
                  )
                })}
            </ul>
          </div>
          <div id="center" className={cn([style.main, style.center])}>
            <div className="admin-container" id="adminContainer">
              {ENV !== "production" ? <rn-banner /> : null}
              {children}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

WithMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WithMenu
