import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty, omit } from "lodash"
import { useHistory } from "react-router-dom"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import {
  TextInput,
  SelectInput,
} from "../../../../components/atoms/Inputs/Inputs"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"
import PhoneField from "../../../../components/atoms/PhoneField"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePlayersApi from "../../../../hooks/Api/usePlayersApi"
import useSexesApi from "../../../../hooks/Api/useSexesApi"
import useCountriesApi from "../../../../hooks/Api/useCountriesApi"
import useLanguagesApi from "../../../../hooks/Api/useLanguagesApi"
import useLanguagesLevelsApi from "../../../../hooks/Api/useLanguagesLevelsApi"
import useSportsApi from "../../../../hooks/Api/useSportsApi"
import usePermissionsState from "../../../../hooks/usePermissionsState"
// import useClientsApi from "../../../../hooks/Api/useClientsApi"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

// Utils & misc
import cn from "../../../../utils/cn"
import PLAYER_EDIT_INITIAL_VALUES from "./player-edit-initial"
import playerValidation from "./player-edit-validation"
import { hasPlayerLegalAge } from "../../../../utils/hasPlayerLegalAge"

import styles from "./PlayersEdit.module.css"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_PLAYERS_CREATE,
  PERMISSIONS.PERM_PLAYERS_UPDATE,
]

const PlayersEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const history = useHistory()

  // Form state
  const [globalError, setGlobalError] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)

  // Players API Hooks
  // const [, clientData, , { getClientWithId }] = useClientsApi()
  const [, playersData, , { getClientsPlayers }] = usePlayersApi()
  const [, playerData, , { getPlayerById }] = usePlayersApi()
  const [, sexesData, , { getSexes }] = useSexesApi()
  const [, countriesData, , { getCountries }] = useCountriesApi()
  const [, languagesData, , { getLanguages }] = useLanguagesApi()
  const [, sportsData, , { getSports }] = useSportsApi()
  const [
    ,
    languagesLevelsData,
    ,
    { getLanguagesLevels },
  ] = useLanguagesLevelsApi()

  const [
    ,
    updatePlayerData,
    updatePlayerError,
    { updatePlayer },
  ] = usePlayersApi()
  const [
    ,
    ,
    ,
    { activatePlayerImageRight },
  ] = usePlayersApi()
  const [
    ,
    newPlayerData,
    newPlayerError,
    { createPlayer },
  ] = usePlayersApi()

  const isEditingPlayer = !isEmpty(match.params.playerId)

  // const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetClientsPlayers = useCallback(getClientsPlayers, [])
  const savedGetPlayerId = useCallback(getPlayerById, [])
  const savedGetSexes = useCallback(getSexes, [])
  const savedGetCountries = useCallback(getCountries, [])
  const savedGetLanguages = useCallback(getLanguages, [])
  const savedGetLanguagesLevels = useCallback(getLanguagesLevels, [])
  const savedGetSports = useCallback(getSports, [])

  const [formSubmitError, setFormSubmitError] = useState(null)
  const [formFieldsError, setFormFieldsError] = useState({
    global: null,
    firstname: null,
    lastname: null,
  })

  const handleActOrDeactImageRight = (player, isActive) => {
    // right to the image (activate or deactivate)
    if (player?.clientId && player?.uid) {
      activatePlayerImageRight({
        clientId: player.clientId,
        playerId: player.uid,
        isActive
      })
        .then(() => setSubmitLoading(false))
        .catch(() => setSubmitLoading(false))
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: isEditingPlayer
      ? { ...PLAYER_EDIT_INITIAL_VALUES(), ...playerData } || {}
      : { ...PLAYER_EDIT_INITIAL_VALUES(), clientId: match.params.clientId },
    enableReinitialize: isEditingPlayer,
    onSubmit: valuesSubmit => {
      // Phone Check
      if (valuesSubmit.phone && valuesSubmit.phone.length) {
        const parsedNumber = parsePhoneNumberFromString(valuesSubmit.phone)
        if (!parsedNumber) {
          setFormSubmitError("Le numéro de téléphone est invalide")
          return
        }
      }

      setSubmitLoading(true)
      const sanitizedValues = { ...omit(valuesSubmit, ['rightToTheImage']) }

      // if player has legal age, right to the image is true by default
      const isActiveImageRight = hasPlayerLegalAge(sanitizedValues?.birthdate) || valuesSubmit.rightToTheImage || false;

      if (isEditingPlayer) {
        updatePlayer(sanitizedValues)
          .then((res) => {
            if (res?.data?.rightToTheImage !== isActiveImageRight) {
              handleActOrDeactImageRight(res?.data, isActiveImageRight)
            } else {
              setSubmitLoading(false)
            }
          })
          .catch(() => setSubmitLoading(false))
      } else {
        createPlayer(sanitizedValues)
          .then((res) => {
            if (res?.data?.rightToTheImage !== isActiveImageRight) {
              handleActOrDeactImageRight(res?.data, isActiveImageRight)
            } else {
              setSubmitLoading(false)
            }
          })
          .catch(() => setSubmitLoading(false))
      }
    },
  })

  useEffect(() => {
    if ((updatePlayerData || newPlayerData) && !submitLoading) {
      history.push({
        pathname: `/client/${match.params.clientId}/players/list`,
        state: {
          submitType: isEditingPlayer ? "edit" : "create",
          success: true,
        },
      })
    }
  }, [
    history,
    isEditingPlayer,
    match.params.clientId,
    updatePlayerData,
    newPlayerData,
    submitLoading,
  ])

  useEffect(() => {
    const message =
      updatePlayerError &&
      updatePlayerError.data &&
      updatePlayerError.data.errors

    if (message) {
      setGlobalError(message[Object.keys(message)[0]])
    }
  }, [updatePlayerError])

  useEffect(() => {
    const message =
      newPlayerError && newPlayerError.data && newPlayerError.data.errors

    if (message) {
      setGlobalError(message[Object.keys(message)[0]])
    }
  }, [newPlayerError])

  useEffect(() => {
    if (isEditingPlayer) savedGetPlayerId(match.params.playerId)
    // savedGetClientWithId(match.params.clientId)
    savedGetClientsPlayers(match.params.clientId)
    savedGetSexes()
    savedGetCountries()
    savedGetLanguages()
    savedGetLanguagesLevels()
    savedGetSports()
  }, [
    isEditingPlayer,
    // savedGetClientWithId,
    savedGetClientsPlayers,
    savedGetPlayerId,
    savedGetSexes,
    savedGetCountries,
    savedGetLanguages,
    savedGetLanguagesLevels,
    savedGetSports,
    match.params.playerId,
    match.params.clientId,
  ])

  useEffect(() => {
    if (playersData && values) {
      const formErrors = playerValidation(
        values,
        playersData,
        isEditingPlayer ? match.params.playerId : null
      )
      setFormFieldsError(formErrors)
    }
  }, [isEditingPlayer, match.params.playerId, playersData, values])

  const handlePlayerLanguageChange = (languageId, val) => {
    const newPlayerLanguages = { ...values.playerLanguages }

    newPlayerLanguages[languageId].levelId = Number(val)
    setFieldValue("playerLanguages", newPlayerLanguages)
  }

  const handlePlayerSportChange = (sportId, val) => {
    const newPlayerSport = { ...values.playerSports }

    newPlayerSport[sportId] = { ...newPlayerSport[sportId], ...val }

    setFieldValue("playerSports", newPlayerSport)
  }

  const handlePlayerPhoneInit = status => {
    switch (status) {
      case "EMPTY": break;
      case "INVALID": {
        if (values && values.phone) {
          setGlobalError(
            `Le numéro de téléphone a été traité comme invalide, veuillez l'entrer à nouveau ${
              values && values.phone ? `(${values.phone})` : null
            }`
          )
        }
        break
      }
      default: {
        break
      }
    }
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_PLAYERS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_PLAYERS_CREATE] && !isEditingPlayer)

  const playerDataState = isEditingPlayer ? !!playerData : true

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Joueur / ${isEditingPlayer ? "Éditer" : "Nouveau"}`}
          {playerData && playerData.name ? ` / ${playerData.name}` : null}
        </h1>
        <ClientNavigation client={{ uid: match.params.clientId }} />
      </div>

      <div className={cn(["mw-100", "w-75", "m-auto", styles.editSection])}>
        {values &&
        playersData &&
        playerDataState &&
        sexesData &&
        countriesData &&
        languagesData &&
        sportsData &&
        languagesLevelsData ? (
          <form onSubmit={handleSubmit}>
            {globalError || formSubmitError ? (
              <div className="alert alert-danger" role="alert">
                {globalError || formSubmitError}
              </div>
            ) : null}

            {formFieldsError && formFieldsError.global ? (
              <div className="alert alert-danger" role="alert">
                {formFieldsError.global}
              </div>
            ) : null}

            {/* GENERAL INFORMATION */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Informations Personnelles</h5>

                <div className="form-row">
                  {/* NOM */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      required
                      label="Nom*"
                      id="lastname"
                      name="lastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                    />
                  </div>

                  {/* PRENOM */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      required
                      label="Prénom*"
                      id="firstname"
                      name="firstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                    />
                  </div>
                </div>

                <div className="form-row">
                  {/* GENDER */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <SelectInput
                      disabled={!isAllowedToEdit}
                      label="Genre*"
                      id="sexId"
                      name="sexId"
                      onChange={e =>
                        setFieldValue("sexId", Number(e.target.value))
                      }
                      onBlur={handleBlur}
                      value={values.sexId}
                      options={[
                        <option key="optionFirst" value="" selected disabled>
                          Genre
                        </option>,
                        ...Object.keys(sexesData.sexes).map(sexId => (
                          <option key={`option${sexId}`} value={sexId}>
                            {sexesData.sexes[sexId].name["1"]}
                          </option>
                        )),
                      ]}
                    />
                  </div>

                  {/* NATIONALITY */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <SelectInput
                      disabled={!isAllowedToEdit}
                      label="Nationalité*"
                      id="nationalityId"
                      name="nationalityId"
                      onChange={e =>
                        setFieldValue("nationalityId", Number(e.target.value))
                      }
                      onBlur={handleBlur}
                      value={values.nationalityId}
                      options={Object.keys(countriesData.countries).map(
                        countryId => (
                          <option key={`option${countryId}`} value={countryId}>
                            {countriesData.countries[countryId].name["1"]}
                          </option>
                        )
                      )}
                    />
                  </div>
                </div>

                <div className="form-row">
                  {/* BIRTHDATE */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <label htmlFor="clients-birthdate ">
                      <b>Date de naissance*</b>
                    </label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="date"
                      className="form-control"
                      id="clients-birthdate"
                      name="birthdate"
                      value={values.birthdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  {/* EMAIL */}
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>

                  {/* PHONE */}
                  {!isEditingPlayer || values ? (
                    <div className="col-sm-6 col-md-6 pb-2">
                      <label
                        className="font-weight-bold"
                        htmlFor="clients-phone"
                      >
                        Téléphone
                      </label>
                      <PhoneField
                        disabled={!isAllowedToEdit}
                        id="phone"
                        name="phone"
                        label=""
                        selectPlaceholder="..."
                        value={values.phone}
                        error={false}
                        onInit={handlePlayerPhoneInit}
                        onBlur={number => setFieldValue("phone", number)}
                      />
                    </div>
                  ) : null}
                </div>

                {/* LANGUAGE LEVEL */}
                <div className="form-row">
                  {Object.keys(languagesData.languages).map(languageId => (
                    <div
                      key={`langLevel${languageId}`}
                      className="col-sm-6 col-md-6 pb-2"
                    >
                      <SelectInput
                        disabled={!isAllowedToEdit}
                        label={`Niveau de langue ${languagesData.languages[languageId].name["1"]}*`}
                        id={`playerLanguages[${languageId}]`}
                        name={`playerLanguages[${languageId}]`}
                        onChange={e =>
                          handlePlayerLanguageChange(languageId, e.target.value)
                        }
                        value={values.playerLanguages[languageId].levelId}
                        onBlur={handleBlur}
                        options={[
                          <option key="optionFirst" value="" selected disabled>
                            {`Niveau de langue ${languagesData.languages[languageId].name["1"]}*`}
                          </option>,
                          ...Object.keys(languagesLevelsData.languagesLevels).map(langLevelId => (
                            <option
                              key={`option${langLevelId}`}
                              value={langLevelId}
                            >
                              {[
                                '', 
                                'A1 (Débutant / Elementary)', 
                                'A2-B1 (Intermediaire / Intermediate)', 
                                'B2 (Avancé / Advanced)', 
                                'C1-C2 (Bilingue / Bilingual)'
                              ][langLevelId]}
                            </option>
                          )),
                        ]}
                      />
                    </div>
                  ))}
                </div>

                {/* RIGHT TO THE IMAGE (players under legal age only) */}
                {!hasPlayerLegalAge(values.birthdate) ? (
                  <div className="form-row mt-3">
                    <div className="col-sm-6 col-md-6 pb-2">
                      <label className="font-weight-bold c-pointer">
                        <input
                          id="rightToTheImage"
                          type="checkbox"
                          name="rightToTheImage"
                          onChange={handleChange}
                          checked={hasPlayerLegalAge(values.birthdate) || values.rightToTheImage || false}
                          className="mr-2"
                          disabled={hasPlayerLegalAge(values.birthdate)}
                        />
                        Droit à l'image
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Commentaire</h5>
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <textarea
                      disabled={!isAllowedToEdit}
                      className="form-control"
                      id="comment"
                      name="comment"
                      rows="3"
                      value={values.comment || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* PLAYER SPORTS */}
            {/* Temporary hide padel from sports (id => 3) */}
            {Object.keys(sportsData.sports).filter((id) => id !== "3").map(sportId => (
              <div key={`sports${sportId}`} className={cn(["card mb-4"])}>
                <div className="card-body">
                  <h5 className="card-title">{`${sportsData.sports[sportId].name["1"]}: Informations`}</h5>

                  <div className="form-row pt-2">
                    {/* YEARS */}
                    <div className="col-sm-12 col-md-12 pb-2">
                      <TextInput
                        disabled={!isAllowedToEdit}
                        type="number"
                        label={`En quelle année avez-vous commencé la pratique ?${sportId === '1' ? '*' : ''}`}
                        id={`years${sportId}`}
                        name={`years${sportId}`}
                        value={values.playerSports[sportId].years}
                        onChange={e =>
                          handlePlayerSportChange(sportId, {
                            years: e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : null,
                          })
                        }
                        onBlur={handleBlur}
                        required={sportId === '1'}
                      />
                    </div>

                    {/* HOURS PER WEEK */}
                    <div className="col-sm-12 col-md-12 pb-2">
                      <TextInput
                        disabled={!isAllowedToEdit}
                        type="number"
                        label={`Combien d'heures jouez-vous par semaine ?${sportId === '1' ? '*' : ''}`}
                        id={`hoursPerWeek${sportId}`}
                        name={`hoursPerWeek${sportId}`}
                        value={values.playerSports[sportId].hoursPerWeek}
                        onChange={e =>
                          handlePlayerSportChange(sportId, {
                            hoursPerWeek: e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : null,
                          })
                        }
                        onBlur={handleBlur}
                        required={sportId === '1'}
                      />
                    </div>

                    {/* PARTICIPATIONS PER YEAR */}
                    <div className="col-sm-12 col-md-12 pb-2">
                      <TextInput
                        disabled={!isAllowedToEdit}
                        type="number"
                        label={`À combien de matchs participez-vous par an ?${sportId === '1' ? '*' : ''}`}
                        id={`participationsPerYear${sportId}`}
                        name={`participationsPerYear${sportId}`}
                        value={
                          values.playerSports[sportId].participationsPerYear
                        }
                        onChange={e =>
                          handlePlayerSportChange(sportId, {
                            participationsPerYear: e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : null,
                          })
                        }
                        onBlur={handleBlur}
                        required={sportId === '1'}
                      />
                    </div>

                    {/* IS RANKED */}
                    <div className="col-sm-12 col-md-12 pb-3 pt-4">
                      <b>
                        <label>Êtes-vous classé ?</label>
                      </b>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          disabled={!isAllowedToEdit}
                          className="form-check-input"
                          type="radio"
                          name={`isRankedSport${sportId}`}
                          id={`isRankedSport${sportId}Yes`}
                          onChange={() =>
                            handlePlayerSportChange(sportId, { isRanked: true })
                          }
                          checked={!!values.playerSports[sportId].isRanked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`isRankedSport${sportId}Yes`}
                        >
                          Oui
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          disabled={!isAllowedToEdit}
                          className="form-check-input"
                          type="radio"
                          name={`isRankedSport${sportId}`}
                          id={`isRankedSport${sportId}No`}
                          onChange={() =>
                            handlePlayerSportChange(sportId, {
                              isRanked: false,
                            })
                          }
                          checked={!values.playerSports[sportId].isRanked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`isRankedSport${sportId}No`}
                        >
                          Non
                        </label>
                      </div>
                    </div>

                    {/* RANKED COUNTRY */}
                    {values.playerSports[sportId].isRanked ? (
                      <div className="col-sm-12 col-md-12 pb-3">
                        <SelectInput
                          disabled={!isAllowedToEdit}
                          label="Pays"
                          id={`rankingCountry${sportId}`}
                          name={`rankingCountry${sportId}`}
                          onChange={e => {
                            handlePlayerSportChange(
                              sportId,
                              JSON.parse(e.target.value)
                            )
                          }}
                          value={JSON.stringify({
                            rankingCountryId:
                              values.playerSports[sportId].rankingCountryId,
                            rankingCountryCode:
                              values.playerSports[sportId].rankingCountryCode,
                          })}
                          onBlur={handleBlur}
                          options={[
                            <option
                              value={JSON.stringify({
                                rankingCountryId: null,
                                rankingCountryCode: null,
                              })}
                              selected
                              disabled
                            >
                              Pays
                            </option>,
                            ...Object.keys(countriesData.countries).map(
                              countryId => (
                                <option
                                  value={JSON.stringify({
                                    rankingCountryId: Number(countryId),
                                    rankingCountryCode:
                                      countriesData.countries[countryId].code,
                                  })}
                                >
                                  {countriesData.countries[countryId].name["1"]}
                                </option>
                              )
                            ),
                          ]}
                        />
                      </div>
                    ) : null}

                    {/* AUTRE CLASSEMENT (replaced by "CLASSEMENT ACTUEL") */}
                    {values.playerSports[sportId].isRanked &&
                    values.playerSports[sportId].rankingCountryId ? (
                      <div className="col-sm-12 col-md-12 pb-3">
                        <TextInput
                          disabled={!isAllowedToEdit}
                          label="Classement actuel"
                          id={`isOtherRanking${sportId}`}
                          name={`isOtherRanking${sportId}`}
                          value={values.playerSports[sportId].otherRanking}
                          onChange={e =>
                            handlePlayerSportChange(sportId, {
                              otherRanking: e.target.value,
                            })
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    ) : null}

                    {/* CLASSEMENT UTR */}
                    {sportId === "1" &&
                    values.playerSports[sportId].isRanked &&
                    values.playerSports[sportId].rankingCountryId ? (
                      <div className="col-sm-12 col-md-12 pb-3">
                        <TextInput
                          disabled={!isAllowedToEdit}
                          label="Classement UTR"
                          id={`isUtrRanking${sportId}`}
                          name={`isUtrRanking${sportId}`}
                          value={values.playerSports[sportId].utrRanking}
                          onChange={e =>
                            handlePlayerSportChange(sportId, {
                              utrRanking: e.target.value,
                            })
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

            {/* CONTACT URGENCE */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Contact d'urgence</h5>

                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Nom"
                      id="emergencyContactLastname"
                      name="emergencyContactLastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emergencyContactLastname}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Prénom"
                      id="emergencyContactFirstname"
                      name="emergencyContactFirstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emergencyContactFirstname}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Qualité"
                      id="emergencyContactOccupation"
                      name="emergencyContactOccupation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emergencyContactOccupation}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Téléphone"
                      id="emergencyContactPhone"
                      name="emergencyContactPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emergencyContactPhone}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* MEDECIN TRAITANT */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Médecin Traitant</h5>

                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Nom"
                      id="doctorLastname"
                      name="doctorLastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.doctorLastname}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Adresse"
                      id="doctorAddress"
                      name="doctorAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.doctorAddress}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Code postal"
                      id="doctorZipCode"
                      name="doctorZipCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.doctorZipCode}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Ville"
                      id="doctorCity"
                      name="doctorCity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.doctorCity}
                    />
                  </div>
                </div>

                {/* DOCTOR COUNTRY */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <SelectInput
                      disabled={!isAllowedToEdit}
                      label="Pays"
                      id="doctorCountry"
                      name="doctorCountry"
                      value={JSON.stringify({
                        id: values.doctorCountryId,
                        code: values.doctorCountryCode,
                      })}
                      onChange={e => {
                        const countryInfo = JSON.parse(e.target.value)

                        setFieldValue("doctorCountryId", countryInfo.id)
                        setFieldValue("doctorCountryCode", countryInfo.code)
                      }}
                      required={false}
                      onBlur={handleBlur}
                      options={[
                        <option
                          key="optionFirst"
                          value={JSON.stringify({ id: null, code: null })}
                          selected
                          disabled
                        >
                          Pays
                        </option>,
                        ...Object.keys(countriesData.countries).map(
                          countryId => (
                            <option
                              key={`option${countryId}`}
                              value={JSON.stringify({
                                id: Number(countryId),
                                code: countriesData.countries[countryId].code,
                              })}
                            >
                              {countriesData.countries[countryId].name["1"]}
                            </option>
                          )
                        ),
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SECURITE SOCIALE */}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Sécurité Sociale</h5>

                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Adresse"
                      id="socialSecurityAddress"
                      name="socialSecurityAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.socialSecurityAddress}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Code postal"
                      id="socialSecurityZipCode"
                      name="socialSecurityZipCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.socialSecurityZipCode}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6 col-md-6 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Ville"
                      id="socialSecurityCity"
                      name="socialSecurityCity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.socialSecurityCity}
                    />
                  </div>
                </div>

                {/* SOCIAL SECURITY COUNTRY */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <SelectInput
                      disabled={!isAllowedToEdit}
                      label="Pays"
                      id="socialSecurityCountry"
                      name="socialSecurityCountry"
                      value={JSON.stringify({
                        id: values.socialSecurityCountryId,
                        code: values.socialSecurityCountryCode,
                      })}
                      onChange={e => {
                        const countryInfo = JSON.parse(e.target.value)

                        setFieldValue("socialSecurityCountryId", countryInfo.id)
                        setFieldValue(
                          "socialSecurityCountryCode",
                          countryInfo.code
                        )
                      }}
                      required={false}
                      onBlur={handleBlur}
                      options={[
                        <option
                          key="optionFirst"
                          value={JSON.stringify({ id: null, code: null })}
                          selected
                          disabled
                        >
                          Pays
                        </option>,
                        ...Object.keys(countriesData.countries).map(
                          countryId => (
                            <option
                              key={`option${countryId}`}
                              value={JSON.stringify({
                                id: Number(countryId),
                                code: countriesData.countries[countryId].code,
                              })}
                            >
                              {countriesData.countries[countryId].name["1"]}
                            </option>
                          )
                        ),
                      ]}
                    />
                  </div>
                </div>

                {/* ADDITIONAL OBSERVATIONS */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Observations complémentaires (Allergies, traitement en cours, ...)"
                      id="medicalObservations"
                      name="medicalObservations"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.medicalObservations}
                    />
                  </div>
                </div>

                {/* DIET */}
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <TextInput
                      disabled={!isAllowedToEdit}
                      label="Régime alimentaire"
                      id="diet"
                      name="diet"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.diet}
                    />
                  </div>
                </div>
              </div>
            </div>
            {globalError || formSubmitError ? (
              <div className="alert alert-danger" role="alert">
                {globalError || formSubmitError}
              </div>
            ) : null}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                submitLoading ||
                (formFieldsError && formFieldsError.global) ||
                !isAllowedToEdit
              }
            >
              {submitLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isEditingPlayer ? "Sauvegarder" : "Créer"}
            </button>
          </form>
        ) : (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </WithMenu>
  )
}

PlayersEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default PlayersEdit
