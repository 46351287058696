// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useOrdersApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getOrders = options => {
    const params = options ? objectToURLParam(options) : ""
    // clientId
    return request("GET", `${API_STAGES_URL}/v1/orders${params}`)
  }

  const getOrderById = (orderId, options) => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/orders/${orderId}${params}`)
  }

  const editOrder = (orderId, data) =>
    request("PUT", `${API_STAGES_URL}/v1/orders/${orderId}`, data)

  const getStripePayment = orderId =>
    request("GET", `${API_STAGES_URL}/v1/orders/${orderId}/stripe-payment`)

  const payWithOtherMeans = (orderId, mean) =>
    request(
      "POST",
      `${API_STAGES_URL}/v1/orders/${orderId}/pay-other-mean`,
      mean
    )

  const sendEmail = (orderId, email) =>
    request("POST", `${API_STAGES_URL}/v1/order/${orderId}/sendemail`, email)

  const sendSMS = (orderId, phone) =>
    request("POST", `${API_STAGES_URL}/v1/order/${orderId}/sendsms`, phone)

  const getSMSStatus = orderId =>
    request("GET", `${API_STAGES_URL}/v1/order/${orderId}/sendsms`)

  return [
    requestState,
    payload,
    error,
    {
      getOrders,
      getOrderById,
      editOrder,
      getStripePayment,
      payWithOtherMeans,
      sendSMS,
      sendEmail,
      getSMSStatus,
    },
  ]
}

export default useOrdersApi
