import React, { useEffect, useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import GlobalMessage from "../../../components/atoms/GlobalMessage/GlobalMessage"
import Spinner from "../../../components/atoms/Spinner/Spinner"

// Hooks
import useRolesApi from "../../../hooks/Api/Authorization/useRolesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./RolesList.module.css"

const permissions = [
  PERMISSIONS.PERM_ROLES_DISPLAY,
  PERMISSIONS.PERM_ROLES_UPDATE,
  PERMISSIONS.PERM_ROLES_CREATE,
  PERMISSIONS.PERM_ROLES_ARCHIVE,
]

const RolesList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const { state } = useLocation()
  const [roles, setRoles] = useState([])
  const [rolesLoading, setRolesLoading] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [{ getRoles }] = useRolesApi()
  const savedGetRoles = useCallback(getRoles, [])

  const exportCollectionToArray = (initialPayload, collection) => {
    if (initialPayload && initialPayload[collection])
      return Object.keys(initialPayload[collection]).map(
        i => initialPayload[collection][i]
      )
    return []
  }

  const fetchRoles = useCallback(async () => {
    try {
      setRolesLoading(true)
      const rolesResp = await savedGetRoles()
      setRoles(exportCollectionToArray(rolesResp.data, "roles"))
      setRolesLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [savedGetRoles])

  useEffect(() => {
    fetchRoles()
  }, [fetchRoles])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Roles</h1>
        {rolePermissions[PERMISSIONS.PERM_ROLES_CREATE] ? (
          <Link className="btn btn-success" to="/roles/new">
            Nouveau rôle
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          {`Rôle ${
            state.submitType === "create" ? "créé" : "édité"
          } avec succès`}
        </div>
      )}
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />

      {rolesLoading || !roles || !roles.length ? (
        <Spinner />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_ROLES_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_ROLES_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                },
                {
                  Header: "Role",
                  accessor: "name",
                },
                {
                  Header: "Nombre d'utilisateurs",
                  accessor: "usersCount",
                },
                {
                  Header: "",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        original: { id },
                      },
                    },
                  }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit role"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/roles/edit/${id}`}
                      >
                        Éditer
                      </Link>
                    </div>
                  ),
                },
              ]}
              data={roles}
              hasPagination
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default RolesList
