import React, { useState } from "react"
import PropTypes from "prop-types"
import { format } from "date-fns"

// Hooks
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

const StudentsList = ({ onPlayerSelect }) => {
  const [data, setData] = useState({})
  const [players, setPlayers] = useState([])

  const [, , , { searchStudents }] = useSportStudiesApi()

  const handleSubmit = e => {
    e.preventDefault()
    searchStudents(data).then(response => {
      response.status === 200
        ? setPlayers(response.data)
        : // eslint-disable-next-line no-console
          console.warn(response.error)
    })
  }

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const getBirthdate = date => {
    try {
      const formatted = format(new Date(date), "dd/MM/yyyy")
      return formatted
    } catch (error) {
      return ""
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <table className="table">
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th
              className="align-middle"
              colSpan="3"
              style={{ borderRight: "1px solid #dee2e6" }}
            >
              Etudiant
            </th>
            <th className="align-middle" colSpan="3">
              Client
            </th>
            <th />
          </tr>
          <tr>
            <td className="align-middle">
              <span style={{ display: "block", fontWeight: "700" }}>
                Prénom
              </span>
              <input
                type="text"
                onChange={handleChange}
                name="firstname"
                placeholder="Rechercher"
              />
            </td>
            <td className="align-middle">
              <span style={{ display: "block", fontWeight: "700" }}>Nom</span>
              <input
                type="text"
                onChange={handleChange}
                name="lastname"
                placeholder="Rechercher"
              />
            </td>
            <td
              className="align-middle"
              style={{ borderRight: "1px solid #dee2e6" }}
            >
              <span style={{ display: "block", fontWeight: "700" }}>Date</span>
            </td>
            <td className="align-middle">
              <span style={{ display: "block", fontWeight: "700" }}>
                Prénom
              </span>
              <input
                type="text"
                onChange={handleChange}
                name="clientFirstname"
                placeholder="Rechercher"
              />
            </td>
            <td className="align-middle">
              <span style={{ display: "block", fontWeight: "700" }}>Nom</span>
              <input
                type="text"
                onChange={handleChange}
                name="clientLastname"
                placeholder="Rechercher"
              />
            </td>
            <td className="align-middle">
              <span style={{ display: "block", fontWeight: "700" }}>Email</span>
              <input
                type="text"
                onChange={handleChange}
                name="clientEmail"
                placeholder="Rechercher"
              />
            </td>
            <td>
              <button className="btn btn-success mt-3 mb-3" type="submit">
                Rechercher
              </button>
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {players.length > 0 ? (
            players.map(player => (
              <tr key={player.id}>
                <td className="align-middle text-nowrap">{player.firstname}</td>
                <td className="align-middle text-nowrap">{player.lastname}</td>
                <td
                  className="align-middle text-nowrap"
                  style={{ borderRight: "1px solid #dee2e6" }}
                >
                  {player.birthdate ? getBirthdate(player.birthdate.date) : ""}
                </td>
                <td className="align-middle text-nowrap">
                  {player.clientFirstname}
                </td>
                <td className="align-middle text-nowrap">
                  {player.clientLastname}
                </td>
                <td className="align-middle text-nowrap">
                  {player.clientEmail}
                </td>
                <td className="align-middle text-nowrap">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onPlayerSelect(player)}
                  >
                    Selectionner
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr
              style={{
                fontWeight: "700",
                fontSize: "1.2rem",
                textAlign: "center",
              }}
            >
              <td colSpan="7">
                Pas de joueurs à afficher. Faites une nouvelle recherche.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </form>
  )
}

StudentsList.propTypes = {
  onPlayerSelect: PropTypes.func.isRequired,
}

export default StudentsList
