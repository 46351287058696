export default {
  typeId: null,
  location: null,
  date: null,
  provenance: null,
  company: null,
  number: null,
  terminal: null,
  isAccompanied: null,
}
