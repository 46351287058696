import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { saveAs } from "file-saver"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Table from "../../../../components/organisms/Table"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"
import InvoiceExportLanguage from "../../../../components/molecules/InvoiceExportLanguage/InvoiceExportLanguage"
import Tooltip from "../../../../components/molecules/Tooltip/Tooltip"
import Modal from "../../../../components/molecules/Modal"

// Hooks
import useClientsApi from "../../../../hooks/Api/useClientsApi"
import useOrdersApi from "../../../../hooks/Api/useOrdersApi"
import useInvoiceApi from "../../../../hooks/Api/useInvoiceApi"
import usePreOrderApi from "../../../../hooks/Api/usePreOrderApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

import style from "./OrdersList.module.css"

// Utils & misc
import cn from "../../../../utils/cn"

const permissions = [
  PERMISSIONS.PERM_ORDERS_COMMENT,
  PERMISSIONS.PERM_ORDERS_RESOLVE,
]

const BackRowComment = ({ order, readonly = false }) => {
  const [, , , { editOrder }] = useOrdersApi()
  const [editMode, setEditMode] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [commentValue, setCommentValue] = useState(order.comment)

  const handleOrderCommentSubmit = async () => {
    if (commentValue) {
      try {
        setSubmitLoading(true)
        await editOrder(order.id, { comment: commentValue })
        setEditMode(false)
        setSubmitLoading(false)
      } catch (error) {
        console.error("error in handleOrderCommentSubmit: ", error)
        setSubmitLoading(false)
      }
    }
  }

  const editName = commentValue ? "Modifier" : "Ajouter"

  return (
    <>
      {readonly ? (
        <div>{commentValue}</div>
      ) : (
        <div>
          {editMode ? (
            <div className={cn(["input-group", style.inputComment])}>
              <input
                value={commentValue}
                onChange={e => setCommentValue(e.target.value)}
                className="form-control"
              />
              <div className="input-group-append">
                <button
                  disabled={submitLoading}
                  onClick={handleOrderCommentSubmit}
                  className="btn btn-primary"
                  type="button"
                >
                  Valider
                  {submitLoading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div>{commentValue || ""}</div>
          )}
          <span
            onClick={() => setEditMode(!editMode)}
            onKeyDown={null}
            role="button"
            tabIndex={[]}
            className={cn(["btn btn-link p-0", style.editCommentButton])}
          >
            {editMode ? "Annuler" : editName}
          </span>
        </div>
      )}
    </>
  )
}

const OrdersList = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [
    clientRequestState,
    clientData,
    clientError,
    { getClientWithId },
  ] = useClientsApi()
  const {
    context: { locales },
  } = useAppContext()
  const [orderAutoPaidModalOpen, setOrderAutoPaidModalOpen] = useState(false)
  const [preventEmailOrderAutoPaid, setPreventEmailOrderAutoPaid] = useState(
    false
  )
  const [orderAutoPaidTimeout, setOrderAutoPaidTimeout] = useState(null)
  const [orderAutoPaidLoading, setOrderAutoPaidLoading] = useState(false)
  const [orderAutoPaidStatus, setOrderAutoPaidStatus] = useState(null)
  const [orderAutoPaidCount, setOrderAutoPaidCount] = useState(60)
  const [ordersRequestState, ordersData, , { getOrders }] = useOrdersApi()
  const [, , , { getInvoiceById }] = useInvoiceApi()
  const [, , , { payWithCredit }] = usePreOrderApi()

  const { state } = useLocation()

  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetOrders = useCallback(getOrders, [])
  const savedGetInvoiceById = useCallback(getInvoiceById, [])

  const fetchClientData = () => {
    savedGetClientWithId(match.params.clientId)
    savedGetOrders({ clientId: match.params.clientId })
  }

  useEffect(() => {
    fetchClientData()
  }, [])

  const startTimer = () => {
    setInterval(() => {
      setOrderAutoPaidCount(count => count - 1)
    }, 1000)
  }

  useEffect(() => {
    if (state?.status === "AUTO_PAID") {
      setOrderAutoPaidTimeout(
        setTimeout(() => {
          handleOrderAutoPaidSubmit(false)
        }, 60000)
      )
      startTimer()
      setOrderAutoPaidModalOpen(true)
    }
  }, [state])

  const handleOrderAutoPaidClose = () => {
    clearTimeout(orderAutoPaidTimeout)
    setOrderAutoPaidModalOpen(false)
  }

  const handleOrderAutoPaidSubmit = preventSending => {
    clearTimeout(orderAutoPaidTimeout)
    setOrderAutoPaidLoading(true)
    payWithCredit(match.params.clientId, { preventSending })
      .then(response => {
        if (response) {
          const { status } = response
          if (status === 204) {
            setOrderAutoPaidLoading(false)
            setOrderAutoPaidModalOpen(false)
            setOrderAutoPaidStatus({
              status: "SUCCESS",
              message:
                "Commande régularisée avec succès (paiement par avoir / balance)",
            })
            fetchClientData()
          }
        }
      })
      .catch(e => {
        if (e.response) {
          const { status } = e.response

          setOrderAutoPaidStatus({
            status: "ERROR",
            message: "Une erreur est survenue",
          })

          if (status === 402) {
            setOrderAutoPaidLoading(false)
            setOrderAutoPaidModalOpen(false)
          }
        }
      })
  }

  const handleInvoiceDowload = async invoice => {
    const invoiceFile = await savedGetInvoiceById(invoice.id)
    saveAs(invoiceFile.data, `${invoice.number}.pdf`)
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          {clientData
            ? `Client / ${clientData.firstname} ${clientData.lastname} `
            : null}
          / Commandes
        </h1>
      </div>
      <ClientNavigation
        client={{ ...clientData, uid: match.params.clientId }}
      />
      {orderAutoPaidStatus?.status === "SUCCESS" && (
        <div className="alert alert-success" role="alert">
          {orderAutoPaidStatus?.message || ""}
        </div>
      )}
      {state?.success && (
        <div className="alert alert-success" role="alert">
          {state?.submitType || ""}
        </div>
      )}
      {clientError || orderAutoPaidStatus?.status === "ERROR" ? (
        <div className="alert alert-error" role="alert">
          {clientError || orderAutoPaidStatus?.message}
        </div>
      ) : null}
      {clientRequestState === "loading" ||
      !clientData ||
      ordersRequestState === "loading" ||
      !ordersData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Table
          columns={[
            {
              Header: "ID",
              accessor: "id",
            },
            {
              Header: "Date de création",
              accessor: "creation",
            },
            {
              Header: "Assurance annulation",
              accessor: "insurance",
              Cell: ({
                cell: {
                  row: {
                    values: { insurance },
                  },
                },
              }) => <div>{insurance ? "Oui" : "Non"}</div>,
            },
            {
              Header: "Montant",
              accessor: row =>
                (parseInt(row.amountInclTax, 10) / 100).toFixed(2),
            },
            {
              Header: "Balance",
              accessor: "balance",
            },
            {
              Header: "Facture",
              accessor: "invoice",
              Cell: ({
                cell: {
                  row: {
                    original: { invoice, amountExclTax, amountInclTax },
                  },
                },
              }) => (
                <>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleInvoiceDowload(invoice)}
                  >
                    <Tooltip
                      copyContent={false}
                      text={`${invoice.number}.pdf`}
                      content={
                        <div>
                          <div>
                            {`Total HT(€): ${(
                              parseInt(amountExclTax, 10) / 100
                            ).toFixed(2)}`}
                          </div>
                          <div>{`Total TTC(€): ${(
                            parseInt(amountInclTax, 10) / 100
                          ).toFixed(2)}`}</div>
                        </div>
                      }
                    />
                  </button>
                </>
              ),
            },
            {
              Header: "Avoirs",
              accessor: "creditNotes",
              Cell: ({
                cell: {
                  row: {
                    values: { creditNotes },
                  },
                },
              }) => (
                <div>
                  {creditNotes
                    ? creditNotes.map(credit => (
                        <button
                          type="submit"
                          className={cn(["btn btn-link", style.creditSpacing])}
                          onClick={() => handleInvoiceDowload(credit)}
                        >
                          {credit.number}.pdf
                        </button>
                      ))
                    : null}
                </div>
              ),
            },
            {
              Header: "Langue Facture",
              accessor: "tag0",
              Cell: ({
                cell: {
                  row: {
                    original: { ...order },
                  },
                },
              }) =>
                locales ? (
                  <InvoiceExportLanguage
                    invoiceId={order.invoice.id.toString()}
                    invoiceName={order.invoice.number}
                    locales={locales}
                  />
                ) : null,
            },
            {
              Header: "Commentaire",
              accessor: "comment",
              width: 400,
              Cell: ({
                cell: {
                  row: {
                    original: { ...order },
                  },
                },
              }) => (
                <BackRowComment
                  readonly={!rolePermissions[PERMISSIONS.PERM_ORDERS_COMMENT]}
                  order={order}
                />
              ),
            },
            {
              Header: "Status",
              accessor: "tag1",
              Cell: ({
                cell: {
                  row: {
                    original: { balance, id },
                  },
                },
              }) => (
                <div>
                  {Number(balance) !== 0 ? (
                    <>
                      {rolePermissions[PERMISSIONS.PERM_ORDERS_RESOLVE] ? (
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Edit discount"
                        >
                          <Link
                            className="btn btn-secondary"
                            to={`/client/${clientData.id}/order/${id}/resolve`}
                          >
                            Régulariser
                          </Link>
                        </div>
                      ) : (
                        <div>à régulariser</div>
                      )}
                    </>
                  ) : (
                    <div>Payé</div>
                  )}
                </div>
              ),
            },
          ]}
          // backrow={ordersData.orders.map((order, i) =>
          //   order.comment ? <BackRowComment order={order} /> : null
          // )}
          data={ordersData.orders}
          hasSort
          hasPagination
        />
      )}
      <Modal
        isDisplayed={orderAutoPaidModalOpen}
        onClose={() => handleOrderAutoPaidClose()}
      >
        <div className="row mt-4">
          <div className="col-md-12">
            <h5>
              {`La commande va être régularisée automatiquement dans ${orderAutoPaidCount} secondes (avoir disponible
              suffisant).`}
            </h5>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <input
              type="checkbox"
              className="mr-2"
              id="preventSendEmail"
              name="preventSendEmail"
              checked={!!preventEmailOrderAutoPaid}
              onChange={e =>
                setPreventEmailOrderAutoPaid(!preventEmailOrderAutoPaid)
              }
            />
            <label className="form-check-label" htmlFor="preventSendEmail">
              Ne pas envoyer l'email de confirmation de commande
            </label>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <button
              disabled={orderAutoPaidLoading}
              onClick={() =>
                handleOrderAutoPaidSubmit(preventEmailOrderAutoPaid)
              }
              type="button"
              className="btn btn-primary"
            >
              Valider{" "}
              {orderAutoPaidLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </WithMenu>
  )
}

OrdersList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default OrdersList
