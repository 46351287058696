import React, { useEffect, useCallback, useState, useContext, useMemo } from "react"
import { getYear, format } from "date-fns"
import { fr } from "date-fns/esm/locale"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import Modal from "../../../components/molecules/Modal"
import StudentFormsFilters from "./StudentFormsFilters/StudentFormsFilters"

// Hooks
import useSportStudiesApi from "../../../hooks/Api/useSportStudiesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS, SE_HOSTNAME } from "../../../constants/constants"

import style from "./StudentForms.module.css"

// Utils & misc
import { formStateOptions, getFormStateName } from "../../../utils/forms"
import { exportCollectionToArray } from "../../../utils/collection"
import { getCampusWeeksOptionsFromYear, weekIdToDate } from "../../../utils/weeks"
import { KeycloakAuthContext } from "../../../components/templates/KeycloakAuthProvider"
import { isNil, isNumber } from "lodash"

const permissions = [
  PERMISSIONS.PERM_CAMPUS_FORMS_DISPLAY,
  PERMISSIONS.PERM_CAMPUS_FORMS_UPDATE,
]

const currentYear = getYear(new Date())

const StudentForms = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const { keycloak } = useContext(KeycloakAuthContext)
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [formsState, formsData, , { getForms }] = useSportStudiesApi()
  const [formTypesState,formTypesData,,{ getFormTypes }] = useSportStudiesApi()
  const [, , , { payDeposit }] = useSportStudiesApi()

  const savedGetForms = useCallback(getForms, [])
  const savedGetFormTypes = useCallback(getFormTypes, [])

  const [confirmDepositModal, setConfirmDepositModal] = useState(null)

  // filters
  const [filters, setFilters] = useState({
    formState: { value: null, options: formStateOptions },
    formType: { value: null, options: null },
    studentName: { value: '' },
    startWeek: { value: null, options: null },
    amount: { value: '' },
    depositStatus: { value: null, options: [{ value: 'pending', label: 'En attente' }, { value: 'done', label: 'Acompte payé' }] },
  })

  const [yearSelected, setYearSelected] = useState({
    label: `${currentYear}/${currentYear + 1}`,
    value: currentYear,
  })

  useEffect(() => {
    if (formTypesData && !filters.formType?.options) {
      const formTypesOptions = exportCollectionToArray(formTypesData, "formsTypes")?.map((type) => ({ value: type.id, label: type.code })) || [];
      setFilters((prev) => ({ ...prev, formType: { ...prev.formType, options: formTypesOptions } }));
    }
  }, [formTypesData])

  useEffect(() => {
    if (yearSelected?.value) {
      const year = yearSelected.value;
      const startWeekOptions = getCampusWeeksOptionsFromYear({year, shortLabel: true });

      setFilters((prev) => ({ ...prev, startWeek: { ...prev.startWeek, options: startWeekOptions } }));
    }
  }, [yearSelected])

  const getNormalizedValue = (value) => (
    value.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  );

  const isSearchMatching = ({ searchValue, firstKeyword, secondKeyword }) => {
    const searchWords = searchValue.toString().split(' ').map((word) => getNormalizedValue(word));
    const first = getNormalizedValue(firstKeyword || '');
    const second = getNormalizedValue(secondKeyword || '');
    return searchWords.every((word) => first.includes(word) || second.includes(word));
  };

  const isAmountMatching = ({ searchAmount, comparedAmount }) => {
    const searchAmountToNumber = parseFloat(searchAmount.replace(',', '.'));

    if (isFinite(searchAmountToNumber) && isFinite(comparedAmount)) {
      return searchAmountToNumber * 100 === comparedAmount;
    }
    return false;
  };

  const applyStudentFormsFilters = (form) => {
    // form state
    if (filters.formState.value?.length && !filters.formState.value.some((val) => val.value === form.stateId)) return false;
    // form type
    if (filters.formType.value?.length && !filters.formType.value.some((val) => val.value === form.typeId)) return false;
    // student name
    if (filters.studentName.value !== '' && !isSearchMatching({ searchValue: filters.studentName.value, firstKeyword: form.firstname, secondKeyword: form.lastname })) return false;
    // start week
    if (filters.startWeek.value?.length && !filters.startWeek.value.some((val) => val.value === form.startWeekId)) return false;
    // amount
    if (filters.amount.value !== '' && !isAmountMatching({ searchAmount: filters.amount.value, comparedAmount: form.amount })) return false;
    // deposit status
    if (!isNil(filters.depositStatus.value?.value) && form.depositPaymentStatus !== filters.depositStatus.value.value) return false;
    
    return true;
  };

  const filteredStudentForms = useMemo(() => (
    formsData?.forms || [])
      .map((form) => ({
        ...form,
        firstname: form.student.firstname,
        lastname: form.student.lastname,
      }))
      .filter((form) => applyStudentFormsFilters(form)
  ), [formsData, filters]);

  const adminFormAccessLink = (studentId, formId) => {
    if (keycloak.authenticated) {
      return `https://${SE_HOSTNAME}/admin?studentId=${studentId}&formId=${formId}`
    }
    return null
  }

  const getDepositAmountContent = form => {
    switch (form.depositPaymentStatus) {
      case "pending": {
        return (
          <span className={style.depositActions}>
            <p className="m-0">En attente</p>
            {canEdit && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={null}
                onClick={() => setConfirmDepositModal(form)}
                className="btn btn-primary btn-sm ml-3"
              >
                Valider l'acompte
              </div>
            )}
          </span>
        )
      }
      default:
        return <p className="m-0">Acompte payé</p>
    }
  }

  const handleDeposit = async form => {
    await payDeposit(form.id)
    setConfirmDepositModal(null)
    savedGetForms({
      year: yearSelected.value,
      isActive: "true",
    })
  }

  useEffect(() => {
    savedGetFormTypes()
  }, [savedGetFormTypes])

  useEffect(() => {
    savedGetForms({
      year: yearSelected.value,
      isActive: "true",
    })
  }, [savedGetForms, yearSelected])

  const canEdit = rolePermissions[PERMISSIONS.PERM_CAMPUS_FORMS_UPDATE]

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Suivi des dossiers</h1>
      </div>

      <StudentFormsFilters
        filters={filters} 
        setFilters={setFilters}
        yearSelected={yearSelected}
        setYearSelected={setYearSelected}
      />
      
      {formsState === "loading"
      || !formsData
      || formTypesState === "loading"
      || !formTypesData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Table
          columns={[
            {
              Header: "Statut",
              accessor: "stateId",
              Cell: ({
                cell: {
                  row: {
                    values: { stateId },
                  },
                },
              }) => <div>{getFormStateName(stateId)}</div>,
            },
            {
              Header: "Type de dossier",
              accessor: "typeId",
              Cell: ({
                cell: {
                  row: {
                    values: { typeId },
                  },
                },
              }) => (
                <div>
                  {
                    exportCollectionToArray(formTypesData, "formsTypes").find(
                      formType => {
                        return formType.id === typeId
                      }
                    ).code
                  }
                </div>
              ),
            },
            {
              Header: "Nom",
              accessor: "lastname",
            },
            {
              Header: "Prénom",
              accessor: "firstname",
            },
            {
              Header: "Date de rentrée",
              accessor: "startWeekId",
              Cell: ({
                cell: {
                  row: {
                    values: { startWeekId },
                  },
                },
              }) => (
                <div>
                  {format(weekIdToDate(startWeekId, 'campus'), "dd MMMM yyyy", {
                    locale: fr,
                  })}
                </div>
              ),
            },
            {
              Header: "Montant total",
              accessor: "amount",
              Cell: ({
                cell: {
                  row: {
                    values: { amount },
                  },
                },
              }) => <div>{amount / 100}</div>,
            },
            {
              Header: "Paiement de l'acompte",
              accessor: "depositPaymentStatus",
              Cell: ({
                cell: {
                  row: {
                    original: { ...form },
                  },
                },
              }) => <div>{getDepositAmountContent(form)}</div>,
            },
            {
              Header: "",
              accessor: "tag3",
              Cell: ({
                cell: {
                  row: {
                    original: { ...form },
                  },
                },
              }) => (
                <a
                  type="button"
                  href={adminFormAccessLink(form.student.id, form.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn btn-secondary btn-sm">
                    Voir le dossier
                  </div>
                </a>
              ),
            },
          ]}
          data={filteredStudentForms}
          hasSort
        />
      )}

      <Modal
        isDisplayed={!!confirmDepositModal}
        onClose={() => setConfirmDepositModal(null)}
      >
        {confirmDepositModal ? (
          <div>
            <b className="mb-4">
              Êtes vous sûr de valider le versement de l'acompte ?
            </b>
            <div className="mt-3">
              <div
                role="button"
                tabIndex={0}
                onKeyDown={null}
                onClick={() => handleDeposit(confirmDepositModal)}
                className="btn btn-primary mr-2"
              >
                Valider
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={null}
                onClick={() => setConfirmDepositModal(null)}
                className="btn btn-secondary"
              >
                Annuler
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </WithMenu>
  )
}

StudentForms.propTypes = {}

export default StudentForms
