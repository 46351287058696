import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

// Components
import Table from "../../../../../components/organisms/Table"
import Button from "../../../../../components/atoms/Button"
import { SelectInput } from "../../../../../components/atoms/Inputs/Inputs"
import IDTooltip from "../../../../../components/molecules/IDTooltip/IDTooltip"

// hooks
import useBookingsForCoachApi from "../../../../../hooks/Api/useBookingsForCoachApi"
import useCountriesApi from "../../../../../hooks/Api/useCountriesApi"

// Style
import style from "./SectionShowed.module.css"

// utils & misc
import { findGender, staticRanking } from "../../../../../utils/coach/staticValues"
import { findCountry } from "../../../../../utils/coach/findCountry"
import { isNil } from "lodash"

const SectionShowed = ({ sections, updateSections }) => {
  // hooks
  const [, , , { postBookingAssignment }] = useBookingsForCoachApi();
  const [, , , { putForcedPlayerRanking }] = useBookingsForCoachApi();

  // finders
  const findSectionActive = sectionsIte =>
    sectionsIte.find(section => section.isActive)
  const findPlayerIndex = playerIndex =>
    sectionActive.players.findIndex(p => p.id === playerIndex)

    // states
  const [sectionActive, setSectionActive] = useState(findSectionActive(sections))

  const [, countries, , { getCountries }] = useCountriesApi()
  const savedGetCountries = useCallback(getCountries, [])
  useEffect(() => {
    if (!countries) savedGetCountries()
  },[countries, savedGetCountries])

  //handlers
  const handleSectionOfPlayer = (idPlayer, evt) => {
    const playersToUpdate = sectionActive.players
      .map(player => {
        if (player.id === idPlayer)
          return {
            ...player,
            isModified: true,
            weekSectionCode: evt.target.value,
          }
        return player
      })
      .slice()
    setSectionActive({ ...sectionActive, players: playersToUpdate })
  }

  const handleSubmitSectionOfPlayer = async idPlayer => {
    const playerToUpdate = sectionActive.players[findPlayerIndex(idPlayer)]
    const targetSectionPlayer = playerToUpdate.weekSectionCode
    postBookingAssignment({
      weekSection: targetSectionPlayer,
      bookingId: playerToUpdate.id,
    })

    const sectionsUpdated = sections.map(section => {
      if (targetSectionPlayer.includes(section.code))
        return { ...section, players: section.players.concat({...playerToUpdate, isModified: false}) }
      if (section.players.some(player => player.id === playerToUpdate.id))
        return {
          ...section,
          players: section.players.filter(
            player => player.id !== playerToUpdate.id
          ),
        }
      return section
    })

    updateSections(sectionsUpdated)
    setSectionActive(findSectionActive(sectionsUpdated))
  }

  // useEffect
  // if i don't do that the table dont' refresh.
  useEffect(() => {
    if (findSectionActive(sections).code !== sectionActive.code)
      setSectionActive(findSectionActive(sections))
  }, [sections, sectionActive, setSectionActive])

  const changeForcedRanking = (idPlayer, evt) => {
    const value = staticRanking.find((ranking) => (ranking.value === evt.target.value))?.label;

    if (value) {
      putForcedPlayerRanking({ playerId: idPlayer, forcedPlayerRanking: value }).then((res) => {
        if (res?.status === 204) {
          const updated = sections.map((section) => ({
            ...section,
            players: section.players?.map((player) => {
              if (player.id === idPlayer) return {...player, forcedPlayerRanking: value };
              return player;
            }) || [],
          }));
  
          updateSections(updated);
          setSectionActive(findSectionActive(updated));
        }
      });
    }
  };

  const columns = [
    {
      Header: "Code couleur",
      accessor: "colorCode",
      Cell: ({
        cell: {
          row: {
            values: { colorCode },
          },
        },
      }) => (
        colorCode === 'full_day_test' ? (
          <div><strong className={style.test_acad}>TEST ACAD</strong></div>
        ) : (
          <div className={`${style.tag} ${style[colorCode]}`} title={colorCode.split('_').join(' ')} />
        )
      ),
    },
    {
      Header: "Id",
      accessor: "id",
      Cell: ({
        cell: {
          row: {
            values: { id },
          },
        },
      }) => <IDTooltip uid={id} />,
    },
    {
      Header: "Nom / Prénom",
      accessor: "name",
    },
    {
      Header: "Né(e) le",
      accessor: "birthdate",
    },
    {
      Header: "Genre",
      accessor: "sexCode",
      Cell: ({
        cell: {
          row: {
            values: { sexCode },
          },
        },
      }) => <div>{findGender(sexCode).title}</div>,
    },
    {
      Header: "Nationalité",
      accessor: "nationalityCountryCode",
      Cell: ({
        cell: {
          row: {
            values: { nationalityCountryCode },
          },
        },
      }) => {
        return (
          <div>
            {findCountry(countries?.countries, nationalityCountryCode)}
          </div>
        )}
    },
    {
      Header: 'Infos sportives',
      accessor: "firstYearOfPractice",
      Cell: ({
        row: {
          original: { firstYearOfPractice, hoursPlayedPerWeek, matchesPlayedPerYear } 
        }
      }) => (
        <div className={style.tennis_data}>
          {firstYearOfPractice || hoursPlayedPerWeek || matchesPlayedPerYear ? (
            <>
              {!isNil(firstYearOfPractice) ? <p>Début tennis : {firstYearOfPractice}</p> : null}
              {!isNil(hoursPlayedPerWeek) ? <p>Heures/semaine : {hoursPlayedPerWeek}</p> : null}
              {!isNil(matchesPlayedPerYear) ? <p>Matchs/an : {matchesPlayedPerYear}</p> : null}
            </>
          ) : (
            <p>Non renseignées</p>
          )}
        </div>
      )
    },
    {
      Header: "Ranking",
      accessor: "ranking",
      Cell: ({
        row: {
          original: { id, ranking, forcedPlayerRanking },
        },
      }) => (
        <>
          <SelectInput
            id="player-ranking-selector"
            onChange={evt => changeForcedRanking(id, evt)}
            value={
              staticRanking.find((r) => (r.label === forcedPlayerRanking))?.value
              || staticRanking.find((r) => (r.label === ranking))?.value
            }
            name="playerRanking"
            options={[
              <option key="label" value="" disabled>
                Ranking
              </option>,
              staticRanking.map(({ label, value }, key) => (
                <option key={key} value={value}>
                  {label}
                </option>
              )),
            ]}
          />
        </>
      ),
    },
    {
      Header: "Section",
      accessor: "weekSectionCode",
      Cell: ({
        cell: {
          row: {
            values: { id, weekSectionCode },
          },
        },
      }) => (
        <SelectInput
          id="section-selector"
          onChange={evt => handleSectionOfPlayer(id, evt)}
          value={sectionActive.players[findPlayerIndex(id)]?.weekSectionCode}
          name="playerSectionSelector"
          options={[
            sections.map(sectionSelect => {
              return (
                <option 
                key={sectionSelect.code} 
                value={sectionSelect.code} 
                disabled={sectionActive.sectionCode === sectionSelect.sectionCode}>
                  {sectionSelect.title}
                </option>
              )
            }),
          ]}
        />
      ),
    },
    {
      Header: "",
      accessor: "isModified",
      Cell: ({
        cell: {
          row: {
            values: { id, isModified },
          },
        },
      }) => (
        <Button
          type="button"
          size="small"
          label={`Valider le changement de section`}
          disabled={!isModified}
          onClick={() => handleSubmitSectionOfPlayer(id)}
        />
      ),
    },
  ]

  return (
    <div className={style["players-table"]}>
      {sectionActive && sectionActive.players.length ? (
        <Table
          columns={columns}
          data={sectionActive.players}
          hasPagination
          hasSort
          hiddenColumns={['id']}
        />
      ) : (
        <div>Aucun joueur dans cette section</div>
      )}
    </div>
  )
}

SectionShowed.propTypes = {
  section: PropTypes.array,
  updateSections: PropTypes.func,
}

export default SectionShowed
