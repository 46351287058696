// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useDiscountsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getDiscounts = () => request("GET", `${API_STAGES_URL}/v1/discounts`)

  const getDiscountWithId = discountId =>
    request("GET", `${API_STAGES_URL}/v1/discounts/${discountId}`)

  const updateDiscount = (discount, discountId) =>
    request("PUT", `${API_STAGES_URL}/v1/discounts/${discountId}`, discount)

  const createDiscount = discount =>
    request("POST", `${API_STAGES_URL}/v1/discounts`, discount)

  const getDiscountsType = () =>
    request("GET", `${API_STAGES_URL}/v1/discountstypes`)

  return [
    requestState,
    payload,
    error,
    {
      getDiscounts,
      getDiscountWithId,
      updateDiscount,
      createDiscount,
      getDiscountsType,
    },
  ]
}

export default useDiscountsApi
