import React, { useEffect, useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import Spinner from "../../../components/atoms/Spinner/Spinner"

// Hooks
import useAdminsApi from "../../../hooks/Api/Authorization/useAdminsApi"
import useRolesApi from "../../../hooks/Api/Authorization/useRolesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./AdminsList.module.css"

const permissions = [
  PERMISSIONS.PERM_ADMINS_DISPLAY,
  PERMISSIONS.PERM_ADMINS_ARCHIVE,
  PERMISSIONS.PERM_ADMINS_UPDATE,
  PERMISSIONS.PERM_ADMINS_CREATE,
]

const AdminsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [{ getAdmins }] = useAdminsApi()
  const [{ getRoles }] = useRolesApi()
  const [admins, setAdmins] = useState(null)
  const [roles, setRoles] = useState(null)
  const [displayArchived, setDisplayArchived] = useState(false)
  const [error, setError] = useState(null)
  const savedGetAdmins = useCallback(getAdmins, [])
  const savedGetRoles = useCallback(getRoles, [])
  const { state } = useLocation()

  const fetchAdmins = useCallback(async () => {
    try {
      const adminsResp = await savedGetAdmins()
      const rolesResp = await savedGetRoles()

      setAdmins(
        Object.keys(adminsResp.data.admins).map(
          adminId => adminsResp.data.admins[adminId]
        )
      )
      setRoles(
        Object.keys(rolesResp.data.roles).map(
          roleId => rolesResp.data.roles[roleId]
        )
      )
    } catch (e) {
      if (e.status) {
        setError(e.status.message)
      }
    }
  }, [savedGetAdmins, savedGetRoles])

  useEffect(() => {
    fetchAdmins()
  }, [fetchAdmins])

  const getRoleName = userRoleId => {
    const foundRole = roles.find(role => role.id === userRoleId)

    if (foundRole !== undefined) return foundRole.name
    return "Rôle inconnu"
  }

  const getStatus = (archived, active) => {
    if (archived) return "Archivé"
    if (active) return "Actif"
    return "Inactif"
  }
  
  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <span className="d-inline-flex">
          <h1 className="mb-0">Admins</h1>
          {displayArchived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Utilisateurs archivés
              </span>
            </h3>
          ) : null}
        </span>
        {rolePermissions[PERMISSIONS.PERM_ADMINS_CREATE] ? (
          <Link className="btn btn-success" to="/admins/new">
            Nouvel admin
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      
      {admins && admins.length && roles && roles.length ? (
        <>
          {rolePermissions[PERMISSIONS.PERM_ADMINS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_ADMINS_UPDATE] ? (
            <>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="display-archived"
                  value={displayArchived}
                  onClick={() => setDisplayArchived(!displayArchived)}
                />
                <label className="form-check-label" htmlFor="display-archived">
                  Afficher les utilisateurs archivés
                </label>
              </div>
              <Table
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                  },
                  {
                    Header: "Nom",
                    accessor: "lastname",
                  },
                  {
                    Header: "Prénom",
                    accessor: "firstname",
                  },
                  {
                    Header: "E-Mail",
                    accessor: "email",
                  },
                  {
                    Header: "Profil",
                    accessor: "roleId",
                    Cell: ({
                      cell: {
                        row: {
                          values: { roleId },
                        },
                      },
                    }) => <div>{getRoleName(roleId)}</div>,
                  },
                  {
                    Header: "Status",
                    accessor: "active",
                    Cell: ({
                      cell: {
                        row: {
                          original: { active, archived },
                        },
                      },
                    }) => <div>{getStatus(archived, active)}</div>,
                  },
                  {
                    Header: "",
                    accessor: "admin",
                    Cell: ({
                      cell: {
                        row: {
                          values: { id },
                        },
                      },
                    }) => (
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Edit admin"
                      >
                        <Link
                          className="btn btn-secondary"
                          to={`/admins/edit/${id}`}
                        >
                          Éditer
                        </Link>
                      </div>
                    ),
                  },
                ]}
                data={admins.filter(admin => {
                  if (displayArchived) {
                    if (admin.archived) return true
                    return false
                  }
                  if (admin.archived) return false
                  return true
                })}
                hasPagination
                hasSort
                hasSearch
              />
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </WithMenu>
  )
}
export default AdminsList
