import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory, useParams } from "react-router-dom"

// Components
import WithMenu from '../../../../components/templates/WithMenu/WithMenu'
import WeekSelector from '../../../../components/atoms/WeekSelector/WeekSelector'
import Tabs from '../../../../components/molecules/Tabs/Tabs'
import SlotsAndGroupsOfDay from './SlotsAndGroupsOfDay/SlotsAndGroupsOfDay'
import Spinner from '../../../../components/atoms/Spinner/Spinner'
import Button from '../../../../components/atoms/Button'

//Hooks
import useBookingsForCoachApi from '../../../../hooks/Api/useBookingsForCoachApi'
import useRotationsForCoachsApi from '../../../../hooks/Api/useRotationsForCoachsApi'
import useCountriesApi from '../../../../hooks/Api/useCountriesApi'

// Style
import style from './SectionsGroupsList.module.css'

// utils & misc
import { getTitleSection, mapPlayersInSectionsAndGroups } from '../../../../utils/coach/mappers'
import { saveAs } from 'file-saver'
import { formatDateForCoachUrl, weeksList } from '../../../../utils/weeks'
import { orderRotationSections } from '../../../../utils/coach/orderSections'
import { startOfWeek } from 'date-fns'
import useAppContext from '../../../../hooks/useAppContext'

const SectionsGroupsList = props => {
  const date = new Date();
  const weeksListForGroups = useMemo(() => weeksList({
    start: new Date(date.getFullYear(), date.getMonth() - 1, date.getDay()+1),
    end: new Date(date.getFullYear() + 7, date.getMonth(), date.getDay()+1)
  }) , [])

  const { setCurrentCoachWeek, context: { currentCoachWeek }} = useAppContext();
  const mondayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  const params = useParams()
  const paramWeekSelected = params?.weekSelected && new Date(params.weekSelected.split('_').reverse().join('/'));
  
  // sections
  const [sections, setSections] = useState(null)

  const [weekSelected, setWeekSelection] = useState(paramWeekSelected || currentCoachWeek || mondayOfCurrentWeek)

  const isTabActive = (section, indexToMatch) => props.match.params.section ? props.match.params.section === section.sectionCode : indexToMatch === 0;
  const tabActive = sections && sections.find(section => section.isActive)

  // static data
  const [, countries, , { getCountries }] = useCountriesApi();
  useEffect(() => { getCountries(); }, []);

  //hooks
  const [, rotations, , { getRotationsForCoachs }] = useRotationsForCoachsApi()
  const [, bookingsPlayers, , { getBookingsForCoach, getBookingsCoachExportXls }] = useBookingsForCoachApi()

  const savedGetRotations = useCallback(getRotationsForCoachs,[])
  const savedGetBookings = useCallback(getBookingsForCoach,[])
  const history = useHistory() 

  const initSectionsMapperAndSetter = () => {
    const orderRotations = orderRotationSections(rotations)
    const week = formatDateForCoachUrl(weekSelected)
    const mapppedSections = orderRotations.map((section, index) => ({
      ...section, 
      title: getTitleSection(section.sectionCode), 
      isActive: isTabActive(section, index), 
      players: [], 
      url: '/sections/groups/' + section.sectionCode + '/' + week
    }))

    const mapPlayersToSections = mapppedSections && mapPlayersInSectionsAndGroups(mapppedSections || [], bookingsPlayers?.bookings || [])
    setSections(mapPlayersToSections)
  }

  useEffect(() => {
    if (paramWeekSelected) {
      setCurrentCoachWeek(paramWeekSelected)
    }
  }, [])

  useEffect(() => {
    if (!rotations && !bookingsPlayers && weekSelected) {
      savedGetRotations(weekSelected)
      savedGetBookings(weekSelected)
    }
    if (rotations && bookingsPlayers) {
      initSectionsMapperAndSetter()
    }
  }, [rotations, bookingsPlayers])

  const handleUpdateSections = (sectionsUpdated) =>{
    setSections(sectionsUpdated)
  }

  const handleUpdateActiveTab = (activeTab) => {
    const weekSelectedFormated = formatDateForCoachUrl(weekSelected)
    const sectionsUpdated = sections.map(section => ({...section, isActive: section.sectionCode === activeTab.sectionCode}))
    setSections(sectionsUpdated)
    history.push('/sections/groups/'+ tabActive.sectionCode + '/' +  weekSelectedFormated)
  }

  const handleExportSection = async (all) => {
    const options = {year: "numeric", month: "numeric", day: "numeric"};
    const weekSelectedFormated = new Intl.DateTimeFormat('fr-FR', options).format(weekSelected)
    const weekSelectedFormatedForApi = weekSelectedFormated.split('/').reverse().join('-')
    const file = await getBookingsCoachExportXls(weekSelectedFormatedForApi, all ? null : tabActive?.sectionCode || null);
    if (file) saveAs(file.data, !all && tabActive?.sectionCode ? `${weekSelectedFormated}_${tabActive.sectionCode}.xls` : `${weekSelectedFormated}.xls`)
    savedGetRotations(weekSelected)
    savedGetBookings(weekSelected)
  }

  const handleChangeWeekSelector = (week) => {
    const weekSelectedFormated = formatDateForCoachUrl(week)
    savedGetRotations(week)
    savedGetBookings(week)
    setWeekSelection(week)
    setCurrentCoachWeek(week)
    history.push('/sections/groups/'+ tabActive.sectionCode + '/' +  weekSelectedFormated)
  }
  return (
    <WithMenu>
      <div className="row">
        <div className={[style['page-header'], "col-sm-9"]}>
          <h1 className={style['page-header__title']}>Repartition groupe</h1>
        </div>
      </div>
      <div className={style.actions}>
          <div className={style['actions__left']}>
            <WeekSelector weeksList={weeksListForGroups} value={weekSelected} onChange={handleChangeWeekSelector} />
          </div>
          <div className={style['actions__right']}>
            <Button size="small" onClick={() => handleExportSection(true)} disabled={!sections|| !rotations} label="Exporter toutes les sections" />
            <Button size="small" onClick={() => handleExportSection(false)} label="Exporter la section" />
          </div>
      </div>

      {sections && rotations ? (
        <Tabs
        tabs={sections}
        updateActiveTab={handleUpdateActiveTab}
        className={style.tabs}
        /> 
      ) : (
        <Spinner />
      )}

      {sections && rotations ? (
        <SlotsAndGroupsOfDay
          sections={sections}
          rotations={rotations}
          bookings={bookingsPlayers?.bookings}
          updateSections={handleUpdateSections}
          handleResetSection={() => handleChangeWeekSelector(weekSelected)}
          countries={countries?.countries ? Object.values(countries?.countries)?.map((country) => ({ code: country.code, name: country.name })) || [] : []}
        />
      ) : (
        <Spinner />
      )}

    </WithMenu>
  )
}

export default SectionsGroupsList
