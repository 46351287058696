import { useCallback, useContext, useState } from "react"
import axios from "axios"
import { KeycloakAuthContext } from "../../components/templates/KeycloakAuthProvider"

const useApi = (options = { trigger: false }) => {
  const { trigger } = options
  const { keycloak } = useContext(KeycloakAuthContext)
  const [requestState, setRequestState] = useState(
    trigger ? "loading" : "completed"
  )
  const [payload, setPayload] = useState(null)
  const [error, setError] = useState(null)

  const responseHandler = res => {
    if (res.data) setPayload(res.data)
    setRequestState("completed")

    return res
  }

  const rawRequest = async (
    method,
    url,
    data,
    reqOptions = {
      authKey: "x-authorization",
    }
  ) => {
    await keycloak.updateToken(30)

    const axiosConfig = keycloak.authenticated
      ? {
          headers: {
            [reqOptions.authKey]: `Bearer ${keycloak.token}`,
          },
        }
      : { headers: {} }

    const config = {
      ...axiosConfig,
      ...reqOptions.axiosOptions,
      headers: axiosConfig.headers,
      url,
      method,
      data,
    }

    const res = await axios(config)
    return res
  }

  const request = async (
    method,
    url,
    data,
    reqOptions = { silentLoad: false, axiosOptions: { headers: {} } }
  ) => {
    setRequestState("loading")
    setPayload(null)
    setError(null)
    if (!reqOptions.silentLoad) setRequestState("loading")

    await keycloak.updateToken(30)

    const axiosConfig = keycloak.authenticated
      ? {
          headers: {
            "x-authorization": `Bearer ${keycloak.token}`,
          },
        }
      : { headers: {} }

    try {
      const config = {
        ...axiosConfig,
        ...reqOptions.axiosOptions,
        headers: { ...axiosConfig.headers, ...reqOptions.axiosOptions.headers },
        url,
        method,
        data,
      }
      const res = await axios(config)

      responseHandler(res)
      return res
    } catch (e) {
      console.error("useApi request error: ", e)
      setError(e.response)
      setRequestState("completed")
    }
  }

  return [requestState, payload, error, useCallback(request,[request]), rawRequest]
}

export default useApi
