import React, { useEffect, useCallback, useState, useMemo } from "react"
import PropTypes from 'prop-types';

// Utils
import { format, isAfter, isBefore, addDays, parse } from "date-fns"
import { saveAs } from "file-saver"
import { isEmpty, isString } from "lodash";
import { getTitleSection } from "../../../utils/coach/mappers";

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useBookingsApi from "../../../hooks/Api/useBookingsApi"
import useWeeksApi from "../../../hooks/Api/useWeeksApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

import columnsData from "./columnsData"
import columnsGuestData from "./columnsGuestData";

import style from "./BookingsList.module.css"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

const permissions = [
  PERMISSIONS.PERM_BOOKINGS_EXPORT_FULL,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DISPLAY,
  PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD,
]

const BookingsList = ({ isExportGuest }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [requestStateBookings, bookingsData, bookingsError, { getBookings }] = useBookingsApi()
  const [requestStateBookingsGuest, bookingsGuestData, bookingsGuestErrorData, { getBookingsGuest }] = useBookingsApi()
  const bookingsGuestError = useMemo(() => bookingsGuestErrorData ? (isString(bookingsGuestErrorData) ? bookingsGuestErrorData : "Une erreur s'est produite. Merci de réessayer plus tard.") : null)
  const [, bookingsExportData, , { getBookingsExport }] = useBookingsApi()
  const [, bookingsGuestExportData, , { getBookingsGuestExport }] = useBookingsApi()

  const [weeksRequestState, weeksData, , { getWeeks }] = useWeeksApi()
  const [weekSelected, setWeekSelected] = useState(null)

  const tableBookingsGuestData = useMemo(() => (
    bookingsGuestData?.bookings?.map((booking) => ({
      ...booking,
      section: !isEmpty(booking.section) ? getTitleSection(booking.section) : ''
    })) || []
  ), [bookingsGuestData])

  const savedGetBookings = useCallback(isExportGuest ? getBookingsGuest : getBookings, [])
  const savedGetBookingsExport = useCallback(isExportGuest ? getBookingsGuestExport : getBookingsExport, [])
  const savedGetWeeks = useCallback(getWeeks, [])

  useEffect(() => {
    savedGetWeeks()
  }, [savedGetBookings, savedGetWeeks])

  useEffect(() => {
    if (weeksData) {
      const keys = Object.keys(weeksData.weeks)
      const now = new Date()

      for (let i = 0; i < keys.length; i += 1) {
        const week = weeksData.weeks[keys[i]]
        const weekDate = parse(week.start, "yyyy-MM-dd", new Date())
        if (isAfter(now, weekDate) && isBefore(now, addDays(weekDate, 7))) {
          setWeekSelected(week.id)
        }
      }
    }
  }, [weeksData])

  const exportCollectionToArray = (initialPayload, collection) => {
    if (initialPayload && initialPayload[collection])
      return Object.keys(initialPayload[collection]).map(
        i => initialPayload[collection][i]
      )
    return []
  }

  const getWeeksOptions = () =>
    weeksData
      ? Object.keys(weeksData.weeks).map(weekId => {
          const week = weeksData.weeks[weekId]

          return (
            <option key={`option_${weekId}`} value={weekId}>{`${format(
              Date.parse(week.start),
              `yyyy (MMM dd)`
            )} Semaine ${week.number}`}</option>
          )
        })
      : null

  const handleSearchWeekClick = () => {
    if (weekSelected) savedGetBookings(weekSelected)
  }

  const handleExportWeekClick = () => {
    if (weekSelected) savedGetBookingsExport(weekSelected)
  }

  useEffect(() => {
    if (bookingsExportData || bookingsGuestExportData) {
      if (bookingsExportData) {
        saveAs(bookingsExportData, "inscriptions.xlsx")
      } else {
        saveAs(bookingsGuestExportData, "exportGuest.xlsx")
      }
    }
  }, [bookingsExportData, bookingsGuestExportData])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>{isExportGuest ? 'Export guest' : 'Inscriptions'}</h1>
      </div>

      {bookingsError || bookingsGuestError ? (
        <div className="alert alert-danger" role="alert">
          {bookingsError || bookingsGuestError}
        </div>
      ) : null}

      {!rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DISPLAY] &&
      !rolePermissions[PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD] ? (
        <span>Vous n'avez pas les permissions d'afficher ces données</span>
      ) : (
        <>
          {weeksRequestState === "completed" && weeksData ? (
            <div className="row">
              <div className="col-sm-8 col-md-8 pb-3">
                <select
                  className="custom-select"
                  id="week-select"
                  name="week-select"
                  required
                  onChange={e => setWeekSelected(e.target.value)}
                  onBlur={null}
                  value={weekSelected}
                >
                  <option value="" disabled>
                    Choisir une semaine
                  </option>
                  {getWeeksOptions()}
                </select>
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <div
                  className="btn-toolbar float-right"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group mr-2"
                    role="group"
                    aria-label="First group"
                  >
                    {rolePermissions[
                      PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DISPLAY
                    ] ? (
                      <button
                        onClick={handleSearchWeekClick}
                        type="button"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    ) : null}
                  </div>
                  {weekSelected &&
                  rolePermissions[
                    PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD
                  ] ? (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Second group"
                    >
                      <button
                        onClick={handleExportWeekClick}
                        type="button"
                        className="btn btn-dark"
                      >
                        Export
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </>
      )}

      {requestStateBookings === 'loading' || requestStateBookingsGuest === 'loading' ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        bookingsData || bookingsGuestData ? (
          <div>
            <Table
              columns={isExportGuest ? columnsGuestData : columnsData}
              data={isExportGuest ? tableBookingsGuestData : bookingsData?.bookings}
              hasPagination
              hasSort
            />
          </div>
        ) : null
      )}
    </WithMenu>
  )
}

BookingsList.propTypes = {
  isExportGuest: PropTypes.bool,
};

BookingsList.defaultProps = {
  isExportGuest: false,
};

export default BookingsList
