/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

// Utils & Misc
import cn from "../../../utils/cn"

// Components
import Picto from "../../atoms/Picto"

// Style
import style from "./Modal.module.css"

export const useModal = () => {
  const [isDisplayed, setIsShowing] = useState(false)
  const toggle = () => setIsShowing(!isDisplayed)
  return [isDisplayed, toggle]
}

export const Modal = ({ children, isDisplayed, onClose, title, size }) => {
  const handleKeyPress = e => e.code === "Enter" && onClose()

  useEffect(() => {
    if (isDisplayed) {
      document.body.style.position = "fixed"
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.right = 0
      document.body.style.left = 0
      document.body.style.maxHeight = "100vh"
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1)
    }
  }, [isDisplayed])

  return isDisplayed
    ? ReactDOM.createPortal(
        <>
          <div
            className={style.overlay}
            onClick={onClose}
            onKeyPress={handleKeyPress}
            role="button"
            tabIndex="-1"
          />
          <div className={cn([style.modal, style[size]])}>
            <div className={style.header}>
              <p>{title}</p>
              <button type="button" onClick={onClose} className={style.close}>
                <Picto icon="cross" />
              </button>
            </div>
            <div className={style.content}>{children}</div>
          </div>
        </>,
        document.body
      )
    : null
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
}
Modal.defaultProps = {
  title: null,
  size: "m",
}

export default Modal
