import React from 'react'
import PropTypes from 'prop-types'

// Components
import { SelectInput } from '../Inputs/Inputs'
import Spinner from '../Spinner/Spinner'

const WeekSelector = ({id, className, weeksList, value, onChange}) => {
  
  const handleOnChange = (evt) => {
    onChange && onChange(new Date(evt.target.value))
  }
  if(weeksList && value) {
    return (
      <SelectInput 
        id={id} 
        value={value} 
        onChange={handleOnChange} 
        className={className} 
        name="weekSelector" 
        options={[
          <option key="default" value="" disabled>
            Choix de la semaine
          </option>,
          weeksList.map(week => (
            <option key={week.id} value={week.value}>
              {week.title}
            </option>
          ))
        ]}
      />
    )
  } else return <Spinner />

}

WeekSelector.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  weeksList: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
}

export default WeekSelector