// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useWeeksApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getWeeks = () => request("GET", `${API_STAGES_URL}/v1/weeks`)

  const updateWeeks = (weekId, weekData) =>
    request("PUT", `${API_STAGES_URL}/v1/weeks/${weekId}`, weekData)

  return [
    requestState,
    payload,
    error,
    {
      getWeeks,
      updateWeeks,
    },
  ]
}

export default useWeeksApi
