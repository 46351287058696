import React, { useState, useCallback, useEffect } from "react"
import { useFormik } from "formik"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"
import cn from "../../../../utils/cn"

const permissions = [
  PERMISSIONS.PERM_CAMPUS_CONFIG_DISPLAY,
  PERMISSIONS.PERM_CAMPUS_CONFIG_UPDATE,
]

const Config = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [, configData, , { getConfig }] = useSportStudiesApi()
  const [updateState, , , { putConfig }] = useSportStudiesApi()

  const [updateSuccess, setUpdateSuccess] = useState(false)

  const savedGetConfig = useCallback(getConfig, [])

  useEffect(() => {
    savedGetConfig()
  }, [savedGetConfig])

  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues:
      {
        ...configData,
        cancellationInsuranceRate: configData?.cancellationInsuranceRate / 100,
      } || {},
    enableReinitialize: true,
    onSubmit: async valuesSubmit => {
      await putConfig({
        ...valuesSubmit,
        cancellationInsuranceRate: valuesSubmit.cancellationInsuranceRate * 100,
      })
      setUpdateSuccess(true)
    },
  })

  const canUserEdit = rolePermissions[PERMISSIONS.PERM_CAMPUS_CONFIG_UPDATE]

  return (
    <>
      <h1>Config</h1>
      <div className="row mt-4">
        <div className="col">
          <form onSubmit={handleSubmit}>
            <div className="col-sm-4 col-md-4 pb-4 pl-0">
              <label htmlFor="admin-firstname">
                Montant assurance annulation, (% des frais d'inscription)
              </label>
              <input
                type="number"
                className="form-control"
                id="cancellationInsuranceRate"
                name="cancellationInsuranceRate"
                value={values.cancellationInsuranceRate}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!canUserEdit}
              />
            </div>
            {updateSuccess && (
              <p className="text-success mb-2">Configuration sauvegardée</p>
            )}
            {canUserEdit && (
              <button
                type="submit"
                className={cn(["btn btn-primary"])}
                disabled={updateState === "loading"}
              >
                {updateState === "loading" && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Sauvegarder
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

Config.propTypes = {}

export default Config
