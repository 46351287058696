// Hooks
import useApi from "../useApi"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../../constants/constants"

const useCategories = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getCategories = () =>
    rawRequest("GET", `${BASE_URL}/categories`, null, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getCategories,
    },
  ]
}

export default useCategories
