// Hooks
import useApi from "../useApi"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../../constants/constants"

const useAdminsApi = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getAdmins = () =>
    rawRequest("GET", `${BASE_URL}/admins`, null, { authKey: AUTHORIZATION })

  const getAdminWithId = adminId =>
    rawRequest("GET", `${BASE_URL}/admins/${adminId}`, null, {
      authKey: AUTHORIZATION,
    })

  const createAdmin = admin =>
    rawRequest("POST", `${BASE_URL}/admins`, admin, {
      authKey: AUTHORIZATION,
    })

  const updateAdmin = (adminId, admin) =>
    rawRequest("PUT", `${BASE_URL}/admins/${adminId}`, admin, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getAdmins,
      getAdminWithId,
      createAdmin,
      updateAdmin,
    },
  ]
}

export default useAdminsApi
