/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react"
import { Link } from "react-router-dom"

// Utils
import cn from "../../../utils/cn"
import { isNil } from "lodash"

// Component
import Editor from "../../../components/organisms/Editor/Editor"
import Archive from "../../../components/molecules/Archive/Archive"
import { Switch as Toggle } from "../../../components/atoms/Inputs/Inputs"
import MediasInput from "../../../components/atoms/MediasInput/MediasInput"

import style from "./PackageCreationLegacy.module.css"

const VIEWS = {
  FORM: "FORM",
  ARCHIVE: "ARCHIVE",
}

const buildTranslateValues = locales => {
  return locales
    ? locales.reduce((acc, locale) => ({ ...acc, [locale.localeId]: "" }), {})
    : {}
}

function Switch(props) {
  function handleClick() {
    props.toggleBoolean(props.path)
  }

  return (
    <div onClick={handleClick} className="custom-control custom-switch">
      <input
        type="checkbox"
        checked={props.getValue(props.path)}
        className="custom-control-input"
        readOnly
      />
      <label className="custom-control-label">{props.label}</label>
    </div>
  )
}

function Select(props) {
  function getValue() {
    return props.getValue(props.path)
  }

  function setValue(event) {
    props.setValue(props.path, Number(event.target.value))
  }

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <select value={getValue()} onChange={setValue} className="form-control">
        {props.options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )
}

function SliderMedias(props) {
  function setValue(event) {
    const value = [...event.target.options]
      .filter(o => o.selected)
      .map(o => ({
        id: Number(o.value),
        path: null,
      }))
    props.setValue("slides", value)
  }

  function getError() {
    return props.getError("slides")
  }

  return (
    <div className="form-group">
      <label>Médias du slider, Sélectionner 3 images obligatoirement</label>
      <select
        multiple
        onChange={setValue}
        value={props.getValue("slides").map(slide => slide.id)}
        className={`form-control${getError() ? " is-invalid" : ""}`}
      >
        {props.options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{getError()}</div>
    </div>
  )
}

function Input(props) {
  const type = props.type !== undefined ? props.type : "text"

  function getValue() {
    return props.getValue(props.path) ? props.getValue(props.path) : undefined
  }

  function setValue(event) {
    let { value } = event.target

    if (type === "number") {
      value = Number(value)
    }

    props.setValue(props.path, value)
  }

  function getError() {
    return props.getError(props.path)
  }

  return (
    <div className="form-group">
      {props.label && <label>{props.label}</label>}
      <input
        type={type}
        value={getValue()}
        onChange={setValue}
        className={`form-control${getError() ? " is-invalid" : ""}`}
      />
      <div className="invalid-feedback">{getError()}</div>
    </div>
  )
}

function Name(props) {
  return (
    <div className="form-row">
      {props.locales.map(locale => (
        <div key={locale.localeId} className="col">
          <Input
            label={`${props.label} ${locale.code.toUpperCase()}`}
            getValue={props.getValue}
            setValue={props.setValue}
            getError={props.getError}
            path={`name.${locale.localeId}`}
          />
        </div>
      ))}
    </div>
  )
}

function Subtitle(props) {
  return (
    <div className="form-row">
      {props.locales.map(locale => (
        <div key={locale.localeId} className="col">
          <Input
            label={`${props.label} ${locale.code.toUpperCase()}`}
            getValue={props.getValue}
            setValue={props.setValue}
            getError={props.getError}
            path={`subtitle.${locale.localeId}`}
          />
        </div>
      ))}
    </div>
  )
}

function ShortDescription(props) {
  return (
    <div className="form-row">
      {props.locales.map(locale => (
        <div key={locale.localeId} className="col">
          <Editor
            disabled={!props.isAllowedToEdit}
            initialContent={props.getValue(
              `shortDescription.${locale.localeId}`
            )}
            handleContentChange={htmlContent =>
              props.setValue(`shortDescription.${locale.localeId}`, htmlContent)
            }
            label={`${props.label} ${locale.code.toUpperCase()}`}
            error={props.getError()}
          />
        </div>
      ))}
    </div>
  )
}

function FullDescription(props) {
  return (
    <div className="form-row">
      {props.locales.map(locale => (
        <div key={locale.localeId} className="col">
          <Editor
            disabled={!props.isAllowedToEdit}
            initialContent={props.getValue(
              `fullDescription.${locale.localeId}`
            )}
            handleContentChange={htmlContent =>
              props.setValue(`fullDescription.${locale.localeId}`, htmlContent)
            }
            label={`${props.label} ${locale.code.toUpperCase()}`}
            error={props.getError()}
          />
        </div>
      ))}
    </div>
  )
}

function Checkboxes(props) {
  function toggleArrayValue(event) {
    props.toggleArrayValue(props.path, Number(event.target.value))
  }

  function isChecked(value) {
    return props.getValue(props.path).includes(value)
  }

  return (
    <fieldset className="form-group">
      <legend>{props.label}</legend>
      {props.options && props.options.length
        ? props.options.map(option => (
            <div key={option.id} className="form-check form-check-inline">
              <input
                id={`${option.id}-${option.name}`}
                type="checkbox"
                value={option.id}
                checked={isChecked(option.id)}
                onChange={toggleArrayValue}
                className="form-check-input"
              />
              <label
                htmlFor={`${option.id}-${option.name}`}
                className="form-check-label"
              >
                {option.name}
              </label>
            </div>
          ))
        : null}
      <div className="text-danger">
        <small>{props.getError(props.path)}</small>
      </div>
    </fieldset>
  )
}

function Radios(props) {
  function setValue(event) {
    props.setValue(props.path, Number(event.target.value))
  }

  return (
    <fieldset className="form-group">
      <legend>{props.label}</legend>
      {props.options && props.options.length
        ? props.options.map(option => (
            <div key={option.id} className="form-check form-check-inline">
              <input
                id={`${option.id}-${option.name}`}
                type="radio"
                value={option.id}
                checked={props.getValue(props.path) === option.id}
                onChange={setValue}
                className="form-check-input"
              />
              <label
                htmlFor={`${option.id}-${option.name}`}
                className="form-check-label"
              >
                {option.name}
              </label>
            </div>
          ))
        : null}
      <div className="text-danger">
        <small>{props.getError(props.path)}</small>
      </div>
    </fieldset>
  )
}

function Feature(props) {
  function setValue(event) {
    props.setValue(`${props.path}.included`, event.target.checked)
  }

  function getValue() {
    return props.getValue(`${props.path}.included`)
  }

  return (
    <tr>
      {props.locales.map(locale => (
        <td key={locale.localeId}>
          <Input
            getValue={props.getValue}
            setValue={props.setValue}
            getError={props.getError}
            path={`${props.path}.name.${locale.localeId}`}
          />
        </td>
      ))}
      <td>
        <input
          type="checkbox"
          checked={getValue()}
          onChange={setValue}
          className="form-check-input"
        />
      </td>
    </tr>
  )
}

function Features(props) {
  function getValue() {
    return props.getValue("features")
  }

  function pushValue() {
    props.pushValue("features", {
      ordinal: getValue().length,
      name: buildTranslateValues(props.locales),
      included: false,
    })
  }

  function popValue() {
    props.popValue("features")
  }

  return (
    <fieldset className="form-group">
      <legend>Caractéristiques</legend>
      <table className="table">
        <thead>
          <tr>
            {props.locales.map(locale => (
              <th key={locale.localeId}>{`${"Nom" +
                " "}${locale.code.toUpperCase()}`}</th>
            ))}
            <th>Incluse</th>
          </tr>
        </thead>
        <tbody>
          {getValue().map((feature, key) => (
            <Feature
              key={key}
              locales={props.locales}
              getValue={props.getValue}
              setValue={props.setValue}
              getError={props.getError}
              path={`features.${key}`}
            />
          ))}
        </tbody>
      </table>
      <button
        type="button"
        onClick={pushValue}
        className="btn btn-secondary mr-3"
      >
        Ajouter une caractéristique
      </button>
      {getValue().length > 0 && (
        <button type="button" onClick={popValue} className="btn btn-danger">
          Supprimer une caractéristique
        </button>
      )}
    </fieldset>
  )
}

function Accommodations(props) {
  function isChecked(value) {
    return props.getValue("accommodationIds").includes(value)
  }

  return (
    <fieldset className="form-group">
      <legend>Hébergement</legend>
      <div className="form-group">
        <div className="form-check form-check-inline">
          <input
            id="accomodation-mandatory"
            type="checkbox"
            checked={props.getValue("accommodationMandatory") || false}
            onChange={props.toggleMandatoryAccommodation}
            className="form-check-input"
          />
          <label htmlFor="accomodation-mandatory" className="form-check-label">
            Obligatoire
          </label>
        </div>
      </div>
      <div className="form-group">
        {props.accommodations.map(accommodation => (
          <div key={accommodation.id} className="form-check form-check-inline">
            <input
              id={`${accommodation.id}-${accommodation.name}`}
              type={props.getValue("accommodationMandatory") ? "radio" : "checkbox"}
              value={accommodation.id}
              checked={isChecked(accommodation.id)}
              onChange={(e) => props.toggleAccommodation(Number(e.target.value))}
              className="form-check-input"
            />
            <label
              htmlFor={`${accommodation.id}-${accommodation.name}`}
              className="form-check-label"
            >
              {accommodation.name}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

function Extra(props) {
  function getExtra() {
    const extras = props.getValue("extras")
    const extra = extras.find(bE => bE.id === props.extra.id)
    return extra === undefined
      ? { id: props.extra.id, fixedQuantity: 0, optionalQuantity: 0 }
      : extra
  }

  function handleExtraChange(event, type) {
    const amountSet = Number(event.target.value)

    if (amountSet < 0) return

    props.handleExtraChange(props.extra.id, {
      type,
      amount: amountSet,
    })
  }

  return (
    <tr>
      <td>{props.extra.name}</td>
      <td className={style.extraColumn}>
        <input
          style={{ marginLeft: "-0.5rem" }}
          type="number"
          value={getExtra().optionalQuantity}
          onChange={e => handleExtraChange(e, "optionalQuantity")}
          className={cn([style.extraInput, "form-control"])}
        />
      </td>
      <td className={style.extraColumn}>
        <input
          style={{ marginLeft: "-0.5rem" }}
          type="number"
          value={getExtra().fixedQuantity}
          onChange={e => handleExtraChange(e, "fixedQuantity")}
          className={cn([style.extraInput, "form-control"])}
        />
      </td>
    </tr>
  )
}

function Extras(props) {
  return (
    <fieldset className="form-group">
      <legend>Extras</legend>
      <table className="table">
        <thead>
          <tr>
            <th>Extra</th>
            <th style={{ textAlign: "right" }}>Proposé</th>
            <th style={{ textAlign: "right" }}>Imposé</th>
          </tr>
        </thead>
        <tbody>
          {props.extras.map(extra => (
            <Extra
              key={extra.id}
              extra={extra}
              getValue={props.getValue}
              handleExtraChange={props.handleExtraChange}
            />
          ))}
        </tbody>
      </table>
    </fieldset>
  )
}

function Products(props) {
  return (
    <fieldset className="form-group mt-4">
      <div className="mt-4 mb-4">
        <legend className="m-0">Stage</legend>
        <Select
          label=""
          options={props.camps}
          getValue={props.getValue}
          setValue={props.setValue}
          path="campId"
        />
      </div>

      <div className="mt-4 mb-4">
        <Accommodations
          accommodations={props.accommodations}
          getValue={props.getValue}
          toggleAccommodation={props.toggleAccommodation}
          toggleMandatoryAccommodation={props.toggleMandatoryAccommodation}
        />
      </div>
      <Extras
        extras={props.extras}
        getValue={props.getValue}
        handleExtraChange={props.handleExtraChange}
      />
    </fieldset>
  )
}

const Errors = ({ errorList }) => (
  <ul>
    {errorList.map(error => (
      <li>{`${error.field} - ${error.message}`}</li>
    ))}
  </ul>
)

class PackageCreation extends React.Component {
  state = {
    data: {
      typeId: [],
      durationId: [],
      formatId: [],
      sportIds: [],
      tagIds: [],
      thumbnailId: this.props.images[0].id,
      bannerId: this.props.images[0].id,
      thumbnailPath: null,
      bannerPath: null,
      slides: [],
      name: buildTranslateValues(this.props.locales),
      subtitle: buildTranslateValues(this.props.locales),
      shortDescription: buildTranslateValues(this.props.locales),
      fullDescription: buildTranslateValues(this.props.locales),
      days: 0,
      nights: 0,
      new: true,
      active: true,
      features: [],
      campId: this.props.camps[0].id,
      accommodationIds: [],
      accommodationMandatory: false,
      numberOfWeeks: 0,
      extras: [],
      videoSource: "",
      videoTitle: buildTranslateValues(this.props.locales),
    },
    errors: {},
    currentView: VIEWS.FORM,
  }

  constructor(props) {
    super(props)

    if (props.data) {
      this.state.data = props.data
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data })
    }
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors })
    }
  }

  getError = path => {
    if (path in this.state.errors && this.state.errors.hasOwnProperty(path)) {
      return this.state.errors[path]
    }
  }

  setValue = (path, value) => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))
      const segments = path.split(".")

      let i
      let target = data

      for (i = 0; i < segments.length - 1; ++i) {
        target = target[segments[i]]
      }

      target[segments[i]] = value

      return {
        data,
      }
    })
  }

  resolveStatePath = (data, path) => {
    const segments = path.split(".")

    for (let i = 0; i < segments.length; ++i) {
      data = data[segments[i]]
    }

    return data
  }

  pushValue = (path, value) => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))
      const target = this.resolveStatePath(data, path)

      target.push(value)

      return {
        data,
      }
    })
  }

  popValue = path => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))
      const target = this.resolveStatePath(data, path)

      target.pop()

      return {
        data,
      }
    })
  }

  toggleArrayValue = (path, value) => {
    this.setState((state, props) => {
      const data = JSON.parse(JSON.stringify(state.data))
      const target = this.resolveStatePath(data, path)

      const index = target.indexOf(value)

      if (index === -1) {
        target.push(value)
      } else {
        target.splice(index, 1)
      }

      return {
        data,
      }
    })
  }

  toggleAccommodation = (value) => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))

      const index = data.accommodationIds.indexOf(value)

      if (index === -1) {
        if (data.accommodationMandatory) {
          data.accommodationIds = [value]
        } else {
          data.accommodationIds.push(value)
        }
      } else {
        data.accommodationIds.splice(index, 1)
      }

      return {
        data,
      }
    })
  }

  toggleMandatoryAccommodation = () => {
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        accommodationMandatory: !state.data.accommodationMandatory,
        accommodationIds: !isNil(state.data.accommodationIds?.[0]) ? [state.data.accommodationIds?.[0]] : []
      }
    }))
  }

  handleExtraChange = (value, payload) => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))

      const index = data.extras.findIndex(extra => extra.id === value)

      if (index === -1) {
        data.extras.push({
          id: value,
          optionalQuantity:
            payload.type === "optionalQuantity" ? payload.amount : 0,
          fixedQuantity: payload.type === "fixedQuantity" ? payload.amount : 0,
        })
      } else {
        data.extras[index] = {
          ...data.extras[index],
          [payload.type]: payload.amount,
        }
      }

      return {
        data,
      }
    })
  }

  toggleBoolean = path => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))
      const segments = path.split(".")

      let i
      let target = data

      for (i = 0; i < segments.length - 1; ++i) {
        target = target[segments[i]]
      }

      target[segments[i]] = !target[segments[i]]

      return {
        data,
      }
    })
  }

  getValue = path => {
    const segments = path.split(".")

    let target = this.state.data

    for (let i = 0; i < segments.length; ++i) {
      try {
        target = target[segments[i]]
      } catch (error) {
        console.error(segments[i], segments, target)
      }
    }
    return target
  }

  getReadOnlyValue = (key, type) => {
    const selectedCamp = this.props.camps?.find((camp) => camp.id === this.state.data?.campId);

    return selectedCamp ? (
      this.props[type]?.length && !isNil(selectedCamp?.[key]) ? (
        <div className={style.tag}>
          {this.props[type].find((type) => type.id === selectedCamp[key])?.name}
        </div>
      ) : null
    ) : null
  };

  render = () => {
    return (
      <React.StrictMode>
        {this.state.currentView === VIEWS.FORM ? (
          <form
            onSubmit={e =>
              this.props.handleSubmit(e, {
                ...this.state.data,
                archived: false,
              })
            }
          >
            {!isNil(this.state.data?.id) && !isNil(this.state.data?.campId) ? (
              <div className={style.tags}>
                {this.getReadOnlyValue('typeId', 'types')}
                {this.getReadOnlyValue('formatId', 'formats')}
                {this.getReadOnlyValue('durationId', 'durations')}
              </div>
            ) : null}

            <fieldset disabled={!this.props.isAllowedToEdit}>
              <div className="form-group">
                <Switch
                  label="Nouveauté"
                  getValue={this.getValue}
                  toggleBoolean={this.toggleBoolean}
                  path="new"
                />
              </div>
              <div className="form-group">
                <legend>Status du package</legend>
                <Toggle
                  label={this.state.data.active ? "Actif" : "Inactif"}
                  value={this.state.data.active}
                  onClick={() => this.toggleBoolean("active")}
                />
              </div>
              <Checkboxes
                label="Sports"
                path="sportIds"
                options={this.props.sports}
                getValue={this.getValue}
                toggleArrayValue={this.toggleArrayValue}
                getError={this.getError}
              />
              <Checkboxes
                label="Tags"
                path="tagIds"
                options={this.props.tags}
                getValue={this.getValue}
                toggleArrayValue={this.toggleArrayValue}
                getError={this.getError}
              />
              <MediasInput
                label="Vignette"
                options={this.props.images}
                selection={this.getValue("thumbnailId") ? [this.getValue("thumbnailId")] : []}
                setSelection={(value) => this.setValue('thumbnailId', value[0]?.id || null)}
                required
              />
              <MediasInput
                label="Bannière"
                options={this.props.images}
                selection={this.getValue("bannerId") ? [this.getValue("bannerId")] : []}
                setSelection={(value) => this.setValue('bannerId', value[0]?.id || null)}
                required
              />
              <MediasInput
                label="Slider"
                options={this.props.images}
                selection={this.getValue("slides")?.map((slide) => slide.id) || []}
                setSelection={(value) => this.setValue('slides', value)}
                isMultiple
                minRequired={3}
                required
              />
              <Name
                label="Titre"
                locales={this.props.locales}
                setValue={this.setValue}
                getValue={this.getValue}
                getError={this.getError}
              />
              <Subtitle
                label="Sous-titre"
                locales={this.props.locales}
                setValue={this.setValue}
                getValue={this.getValue}
                getError={this.getError}
              />
              <ShortDescription
                label="Description courte"
                locales={this.props.locales}
                getValue={this.getValue}
                setValue={this.setValue}
                getError={this.getError}
                isAllowedToEdit={this.props.isAllowedToEdit}
              />
              <FullDescription
                label="Description complète"
                locales={this.props.locales}
                getValue={this.getValue}
                setValue={this.setValue}
                getError={this.getError}
                isAllowedToEdit={this.props.isAllowedToEdit}
              />
              <div className="form-row">
                <div className="col">
                  <Input
                    label="Nombre de jours"
                    type="number"
                    getValue={this.getValue}
                    setValue={this.setValue}
                    getError={this.getError}
                    path="days"
                  />
                </div>
                <div className="col">
                  <Input
                    label="Nombre de nuits"
                    type="number"
                    getValue={this.getValue}
                    setValue={this.setValue}
                    getError={this.getError}
                    path="nights"
                  />
                </div>
                <div className="col">
                  <Input
                    label="Nombre de semaines"
                    type="number"
                    getValue={this.getValue}
                    setValue={this.setValue}
                    getError={this.getError}
                    path="numberOfWeeks"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <Input
                    label="Source vidéo"
                    type="text"
                    getValue={this.getValue}
                    setValue={this.setValue}
                    getError={this.getError}
                    path="videoSource"
                  />
                </div>
              </div>
              <div className="form-row">
                {this.props.locales.map(locale => (
                  <div key={locale.localeId} className="col">
                    <Input
                      label={`Titre vidéo ${locale.code.toUpperCase()}`}
                      getValue={this.getValue}
                      setValue={this.setValue}
                      getError={this.getError}
                      path={`videoTitle.${locale.localeId}`}
                    />
                  </div>
                ))}
              </div>
              <Features
                locales={this.props.locales}
                getValue={this.getValue}
                setValue={this.setValue}
                pushValue={this.pushValue}
                popValue={this.popValue}
                getError={this.getError}
              />

              <Products
                camps={this.props.camps}
                accommodations={this.props.accommodations}
                extras={this.props.extras}
                getValue={this.getValue}
                setValue={this.setValue}
                pushValue={this.pushValue}
                popValue={this.popValue}
                toggleAccommodation={this.toggleAccommodation}
                toggleMandatoryAccommodation={this.toggleMandatoryAccommodation}
                handleExtraChange={this.handleExtraChange}
              />
              {this.props.submitError && (
                <div className="alert alert-warning" role="alert">
                  <Errors errorList={this.props.submitError} />
                </div>
              )}
            </fieldset>
            <div className="mb-4">
              {!this.props.isAllowedToEdit ? (
                <Link to="/packages">
                  <button type="button" className="btn btn-secondary mr-2">
                    Revenir à la liste
                  </button>
                </Link>
              ) : null}

              {this.props.isAllowedToArchive &&
              this.props.isAllowedToEdit &&
              this.props.isEditing ? (
                <div className="float-right mb-4">
                  <button
                    type="button"
                    onClick={() =>
                      this.setState(state => ({
                        ...state,
                        currentView: VIEWS.ARCHIVE,
                      }))
                    }
                    className="btn btn-danger"
                  >
                    ARCHIVER
                  </button>
                </div>
              ) : null}
              <button
                disabled={!this.props.isAllowedToEdit}
                type="submit"
                className="btn btn-success"
              >
                VALIDER
              </button>
            </div>
          </form>
        ) : null}
        {this.state.currentView === VIEWS.ARCHIVE ? (
          <div className={style.formWidth}>
            <Archive
              onArchivedClick={() => {
                this.props.archivePackage(this.props.data.id).then((res) => {
                  if (res.status === 204) {
                    this.props.history.push({
                      pathname: "/packages",
                      state: { submitType: "edit", success: true },
                    })
                  }
                })
              }}
              onCancelClick={() => this.setState({ currentView: VIEWS.FORM })}
            >
              <div className={style.archiveText}>
                {`Etes-vous sûr de vouloir archiver le package ${this.props.data.name[1]} ?`}
              </div>
            </Archive>
          </div>
        ) : null}
      </React.StrictMode>
    )
  }
}

export default PackageCreation
