import React, { useEffect, useCallback, useState, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"
import { sortBy } from "lodash"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import usePackagesApi from "../../../hooks/Api/usePackagesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./PackagesList.module.css"

const permissions = [
  PERMISSIONS.PERM_PACKAGES_DISPLAY,
  PERMISSIONS.PERM_PACKAGES_CREATE,
  PERMISSIONS.PERM_PACKAGES_ARCHIVE,
  PERMISSIONS.PERM_PACKAGES_UPDATE,
]

const PackagesList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [requestState, payload, error, { getPackages }] = usePackagesApi({
    trigger: true,
  })
  const [, , , { reorderPackage }] = usePackagesApi()
  const [, , , { updatePackage }] = usePackagesApi()
  const [packages, setPackages] = useState({})
  const [displayArchived, setDisplayArchived] = useState(false)
  const [packageHiglightLoading] = useState(false)
  const savedGetPackages = useCallback(getPackages, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetPackages()
  }, [savedGetPackages])

  useEffect(() => {
    if (requestState === "completed" && payload && payload.packages) {
      setPackages(payload.packages)
    }
  }, [requestState, payload])

  const packagesCollectionToArray = data =>
    data ? Object.keys(data).map(i => data[i]) : []

  const packageList = useMemo(
    () =>
      sortBy(packagesCollectionToArray(packages), ["ordinal"])
        .filter(p => {
          if (displayArchived) {
            if (p.archived) return true
            return false
          }
          if (p.archived) return false
          return true
        })
        .reverse(),
    [packages, displayArchived]
  )

  // /**
  //  * Optimistic UI for packages
  //  * @param {*} array
  //  */
  // const editPackages = array => {
  //   setPackages(array)

  //   setPackageHiglightLoading(true)
  //   updatePackages(array)
  //     .then(res => {
  //       if (res.data && res.data.packages) {
  //         setPackages(res.data.packages)
  //       } else {
  //         setPackages(packages)
  //       }
  //       setPackageHiglightLoading(false)
  //     })
  //     .catch(() => {
  //       setPackages(packages)
  //     })
  // }

  const onDragEnd = async result => {
    if (!result.destination) {
      return
    }

    let destination
    if (result.source.index > result.destination.index) {
      if (result.destination.index - 1 < 0) {
        destination = 0
      } else {
        destination = packageList[result.destination.index - 1].id
      }
    } else {
      destination = packageList[result.destination.index].id
    }

    await reorderPackage(packageList[result.source.index].id, destination)
    savedGetPackages()
  }

  const getStatus = (archived, active) => {
    if (archived) return "Archivé"
    if (active) return "Actif"
    return "Inactif"
  }

  const handlePackageHighlight = async (p, highlighted) => {
    await updatePackage({ ...p, highlighted })
    savedGetPackages()
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <span className="d-inline-flex">
          <h1>Packages</h1>
          {displayArchived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Packages archivés
              </span>
            </h3>
          ) : null}
        </span>
        {rolePermissions[PERMISSIONS.PERM_PACKAGES_CREATE] ? (
          <Link className="btn btn-success" to="/packages/new">
            Nouveau package
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {!packageList ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_PACKAGES_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_PACKAGES_UPDATE] ? (
            <>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="display-archived"
                  checked={displayArchived}
                  onChange={() => setDisplayArchived(!displayArchived)}
                />
                <label className="form-check-label" htmlFor="display-archived">
                  Afficher les packages archivés
                </label>
              </div>
              <div className={style.warnMessage}>
                Au delà de 4 packages mis en avant, seuls les 4 premiers de la
                liste seront effectivement mis en avant sur la page d’accueil.*
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Table
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                    },
                    {
                      Header: "Label",
                      accessor: "name",
                      Cell: ({ cell: { value } }) => (
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Edit package"
                        >
                          {value[1]}
                        </div>
                      ),
                    },
                    {
                      Header: "Status",
                      accessor: "active",
                      Cell: ({
                        cell: {
                          row: {
                            original: { active, archived },
                          },
                        },
                      }) => <div>{getStatus(archived, active)}</div>,
                    },
                    {
                      Header: "Mise en avant*",
                      accessor: "highlighted",
                      Cell: ({
                        cell: {
                          row: { original },
                        },
                      }) => (
                        <input
                          type="checkbox"
                          id="highlighted"
                          disabled={packageHiglightLoading || original.archived}
                          name="highlighted"
                          checked={original.highlighted}
                          onChange={() => {
                            handlePackageHighlight(
                              original,
                              !original.highlighted
                            )
                            // editPackages({
                            //   ...packages,
                            //   [original.id]: {
                            //     ...original,
                            //     highlighted: !original.highlighted,
                            //   },
                            // })
                          }}
                        />
                      ),
                    },

                    {
                      Header: "",
                      accessor: "package",
                      Cell: ({
                        cell: {
                          row: {
                            values: { id },
                          },
                        },
                      }) => (
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Edit package"
                        >
                          <Link
                            className="btn btn-secondary"
                            to={`/packages/edit/${id}`}
                          >
                            Éditer
                          </Link>
                        </div>
                      ),
                    },
                  ]}
                  data={packageList}
                  hasPagination
                  isDraggable={
                    rolePermissions[PERMISSIONS.PERM_PACKAGES_UPDATE]
                  }
                />
              </DragDropContext>
            </>
          ) : null}
        </>
      )}
    </WithMenu>
  )
}
export default PackagesList
