import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from "react-router-dom"

// style
import style from './WithMenu.module.css'

// utils & misc
import cn from '../../../utils/cn'

const DropdownList = ({
  setVerticalMenuOpen,
  route,
  translate,
  dropdownOpen,
  handleDropdownOpenChange,
}) => {
  const history = useHistory()
  const location = useLocation()

  const dropdownPaths = Object.keys(route.dropdown).map(drop => {
    const dropdown = route.dropdown[drop]
    return {
      dropId: drop,
      key: dropdown.i18nKey,
    }
  })

  return (
    <>
      <li
        className={cn([
          style.upperCase,
          style.sectionHeader,
          style.sectionDropdown,
        ])}
        style={{ flex: "none" }}
        onKeyDown={null}
        onClick={() => handleDropdownOpenChange(route, "standard")}
        key={route.path}
      >
        <div>{route.i18nKey}</div>
        <i className={`fas fa-caret-${dropdownOpen ? "down" : "right"}`} />
      </li>
      {dropdownPaths ? (
        <>
          {dropdownPaths.map((drop) => (
            <li
              onClick={() => {
                setTimeout(() => {
                  setVerticalMenuOpen(false)
                }, 75)
                history.push(route.dropdown[drop.dropId].path)
              }}
              className={cn([
                style.sectionSubheader,
                dropdownOpen ? style.open : "",
                style.listItem,
                location.pathname === route.dropdown[drop.dropId].path
                  ? style.active
                  : "",
              ])}
              style={{ flex: "none" }}
              key={`${route.path}_${drop.key}`}
            >
              {translate(`dropdown.${drop.key}`)}
            </li>
          ))}
        </>
      ) : null}
    </>
  )
}

DropdownList.propTypes = {
  setVerticalMenuOpen: PropTypes.func,
  route: PropTypes.object,
  translate: PropTypes.func,
  dropdownOpen: PropTypes.bool,
  handleDropdownOpenChange: PropTypes.func,
}

export default DropdownList