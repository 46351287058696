// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL, API_SE_URL } from "../../constants/constants"

const useSportStudiesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getDocument = (params, mime = "") => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: mime,
      },
    }
    const queryString = `?${Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join("&")}`

    return request(
      "GET",
      `${API_SE_URL}/v1/documents${queryString}`,
      null,
      { silentLoad: false, axiosOptions: config },
      true
    )
  }

  const uploadImage = file =>
    request(
      "POST",
      `${API_SE_URL}/v1/images/${file.name}`,
      file,
      {
        silentLoad: false,
        axiosOptions: { headers: {} },
      },
      true
    )

  const getFormTypes = () =>
    request(
      "GET",
      `${API_SE_URL}/v1/forms-types`,
      null,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const putFormType = (formTypeId, formType) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/forms-types/${formTypeId}`,
      formType,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const getFormHealthRecord = formId => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "application/zip",
      },
    }
    return request(
      "GET",
      `${API_SE_URL}/v1/forms/${formId}/health_record`,
      null,
      { silentLoad: false, axiosOptions: config },
      true
    )
  }
  const getFormConfig = studentId =>
    request(
      "GET",
      `${API_SE_URL}/v1/forms?studentId=${studentId}`,
      null,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const getForms = (params = {}) =>
    request(
      "GET",
      `${API_SE_URL}/v1/forms`,
      null,
      { silentLoad: false, axiosOptions: { headers: {}, params } },
      true
    )

  const postFormConfig = data =>
    request(
      "POST",
      `${API_SE_URL}/v1/forms`,
      data,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const putFormConfig = (id, data) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/forms/${id}`,
      data,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const postFormEvents = data =>
    request(
      "POST",
      `${API_SE_URL}/v1/form_events`,
      data,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )
  const postFormSign = ({formId}) =>
    request(
      "POST",
      `${API_SE_URL}/v1/forms/${formId}:sign`,{},
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const getStudentsWithId = studentId =>
    request(
      "GET",
      `${API_SE_URL}/v1/students/${studentId}`,
      null,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const createStudent = (studentId, data) =>
    request(
      "POST",
      `${API_SE_URL}/v1/students/${studentId}`,
      data,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const searchStudents = data =>
    request("POST", `${API_STAGES_URL}/v1/students/search`, data)

  const getOptionsConfig = () =>
    request(
      "GET",
      `${API_SE_URL}/v1/options`,
      null,
      { axiosOptions: { headers: {} } },
      true
    )

  const postOptionsConfig = data =>
    request(
      "POST",
      `${API_SE_URL}/v1/options`,
      data,
      { axiosOptions: { headers: {} } },
      true
    )

  const putOptionsConfig = (id, data) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/options/${id}`,
      data,
      { axiosOptions: { headers: {} } },
      true
    )

  const getConfig = (id, data) =>
    request(
      "GET",
      `${API_SE_URL}/v1/config`,
      null,
      { axiosOptions: { headers: {} } },
      true
    )

  const putConfig = data =>
    request(
      "PUT",
      `${API_SE_URL}/v1/config`,
      data,
      { axiosOptions: { headers: {} } },
      true
    )

  const payDeposit = formId =>
    request(
      "POST",
      `${API_SE_URL}/v1/forms/${formId}:pay-deposit`,
      null,
      { axiosOptions: { headers: {} } },
      true
    )

  return [
    requestState,
    payload,
    error,
    {
      getDocument,
      uploadImage,
      getFormHealthRecord,
      getFormTypes,
      putFormType,
      getForms,
      getFormConfig,
      postFormConfig,
      putFormConfig,
      postFormEvents,
      postFormSign,
      getStudentsWithId,
      createStudent,
      searchStudents,
      getOptionsConfig,
      postOptionsConfig,
      putOptionsConfig,
      getConfig,
      putConfig,
      payDeposit,
    },
  ]
}

export default useSportStudiesApi
