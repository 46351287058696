/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import _ from "lodash"
import React, { useCallback, useEffect, useState } from "react"

// Components
import Spinner from "../../atoms/Spinner/Spinner"

// Constants
import { PRODUCTS } from "../../../constants/constants"

// Hooks
import useAccommodationsApi from "../../../hooks/Api/useAccommodationsApi"
import useCampsApi from "../../../hooks/Api/useCampsApi"
import useExtrasApi from "../../../hooks/Api/useExtrasApi"
import usePackagesApi from "../../../hooks/Api/usePackagesApi"

// Utils
import cn from "../../../utils/cn"
import { exportCollectionToArray } from "../../../utils/collection"
import styles from "./ProductArchiveWizard.module.css"

/* TODO:
Update based on path
OnChange sur acc et extras

TODO: tester la validation extras et accommodations
Gérer les erreurs (le produit est toujours inclus dans le package)

logique:
si l'hébergement est le produit à archiver, 1 cas de figure:
1: vérifier que l'hébergement à archiver n'est pas dans la liste des accommodationsIds
*/

const ProductArchiveWizard = ({
  productType,
  product,
  packagesContaining,
  handleProductArchive,
}) => {
  const [campsOptions, setCampsOptions] = useState([])
  const [, , , { getCamps }] = useCampsApi()
  const savedGetCamps = useCallback(getCamps, [])

  const [, , , { updatePackage }] = usePackagesApi()

  const [accommodations, setAccommodations] = useState([])
  const [, , , { getAccommodations }] = useAccommodationsApi()
  const savedGetAccommodations = useCallback(getAccommodations, [])

  const [extras, setExtras] = useState([])
  const [, , , { getExtras }] = useExtrasApi()
  const savedGetExtras = useCallback(getExtras, [])

  const [editedPackages, setEditedPackages] = useState(
    packagesContaining.map(p => ({
      data: p,
    }))
  )
  const [currentPackageIndex, setCurrentPackageIndex] = useState(0)

  const fetchFormData = useCallback(async () => {
    try {
      const campsResp = await savedGetCamps({ archived: false })
      const accommodationResp = await savedGetAccommodations({
        archived: false,
      })
      const extrasDataResp = await savedGetExtras({ archived: false })

      setCampsOptions(exportCollectionToArray(campsResp.data, "camps"))
      setAccommodations(
        exportCollectionToArray(accommodationResp.data, "accommodations")
      )
      setExtras(exportCollectionToArray(extrasDataResp.data, "extras"))
    } catch (error) {
      console.error("fetchFormData", error)
    }
  }, [savedGetCamps, savedGetAccommodations])

  useEffect(() => {
    fetchFormData()
  }, [fetchFormData])

  // Returns the product id if the package includes the product to be archived (error), returns -1 otherwise
  const validatePackage = () => {
    const currentPackage = editedPackages[currentPackageIndex].data
    if(!currentPackage?.slots) return -1
    for (let i = 0; i < currentPackage.slots.length; ++i) {
      const slot = currentPackage.slots[i]
      for (const [, bundle] of Object.entries(slot.bundles)) {
        switch (productType) {
          case PRODUCTS.CAMPS: {
            if (bundle.campId === product.id) return bundle.campId
            break
          }
          case PRODUCTS.ACCOMMODATIONS: {
            const foundAccommodation = bundle.accommodationsIds.find(
              accId => accId === product.id
            )
            if (foundAccommodation !== undefined) return foundAccommodation
            break
          }
          case PRODUCTS.EXTRAS: {
            const foundExtra = bundle.bundleExtras.find(
              extra => extra.extraId === product.id
            )
            if (
              foundExtra !== undefined &&
              (foundExtra.fixed > 0 || foundExtra.optional > 0)
            )
              return foundExtra
            break
          }
          default:
            break
        }
      }
    }
    return -1
  }
  const goToNextPackage = () => {
    setCurrentPackageIndex(currentPackageIndex + 1)
    window.scrollTo(0, 0)
  }

  const goToPreviousPackage = () => {
    setCurrentPackageIndex(currentPackageIndex - 1)
    window.scrollTo(0, 0)
  }

  const setPackageValue = (pType, path, value, info = {}) => {
    const newPackage = { ...editedPackages[currentPackageIndex].data }
    const editedPackagesUpdated = [...editedPackages]

    switch (pType) {
      case PRODUCTS.CAMPS: {
        _.set(newPackage, path, Number(value))
        break
      }
      case PRODUCTS.ACCOMMODATIONS: {
        const fixedAcc = _.get(
          newPackage,
          getPath("fixedAccommodation", info.slotIndex, info.bundleId)
        )
        let prevIds = _.get(newPackage, path)
        if (fixedAcc) {
          prevIds = [value]
        } else {
          const index = prevIds.findIndex(id => id === value)
          if (index !== -1) {
            prevIds.splice(index, 1)
          } else {
            prevIds = [...prevIds, value]
          }
        }
        _.set(
          newPackage,
          path,
          prevIds.sort((a, b) => (a <= b ? -1 : 1))
        )
        break
      }
      case PRODUCTS.EXTRAS: {
        if (value < 0) return

        const bundleExtras = _.get(newPackage, path)
        const extraIndex = bundleExtras.findIndex(
          extra => extra.extraId === info.extraId
        )
        if (extraIndex === -1) {
          bundleExtras.push({
            extraId: info.extraId,
            optional: info.extraType === "optional" ? value : 0,
            fixed: info.extraType === "fixed" ? value : 0,
          })
        } else {
          bundleExtras[extraIndex] = {
            ...bundleExtras[extraIndex],
            [info.extraType]: value,
          }
          if (
            bundleExtras[extraIndex].optional === 0 &&
            bundleExtras[extraIndex].fixed === 0
          ) {
            bundleExtras.splice(extraIndex, 1)
          }
        }
        _.set(newPackage, path, bundleExtras)
        break
      }
      case "fixedAccommodation": {
        const accIdsPath = getPath(
          PRODUCTS.ACCOMMODATIONS,
          info.slotIndex,
          info.bundleId
        )
        const accIds = _.get(newPackage, accIdsPath)
        if (accIds.length > 0) _.set(newPackage, accIdsPath, [accIds[0]])
        _.set(newPackage, path, value)
        break
      }
      default:
        break
    }
    editedPackagesUpdated[currentPackageIndex].data = newPackage
    setEditedPackages(editedPackagesUpdated)
  }

  // GLOBAL
  const getBasePath = (slotIndex, bundleId) =>
    `slots.${slotIndex}.bundles.${bundleId}`

  const getPath = (pType, slotIndex, bundleId) => {
    const base = getBasePath(slotIndex, bundleId)
    switch (pType) {
      case PRODUCTS.CAMPS: {
        return `${base}.campId`
      }
      case PRODUCTS.ACCOMMODATIONS: {
        return `${base}.accommodationsIds`
      }
      case PRODUCTS.EXTRAS: {
        return `${base}.bundleExtras`
      }
      case "fixedAccommodation": {
        return `${base}.fixedAccommodation`
      }
      default:
        break
    }
  }

  // EXTRAS
  const getBundleExtra = (bundleExtras, extraId) => {
    // const bundleExtras = props.getValue(props.path)
    const bundleExtra = bundleExtras.find(bE => bE.extraId === extraId)
    return bundleExtra === undefined
      ? { extraId, fixed: 0, optional: 0 }
      : bundleExtra
  }

  const handleValidateArchive = async () => {
    try {
      const packageBodies = editedPackages.map(p => p.data)
      for (let i = 0; i < packageBodies.length; ++i) {
        await updatePackage(packageBodies[i])
      }
      handleProductArchive()
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 500:
            console.error(
              "[handleValidateArchive] / 500 - Une erreur est survenue"
            )
            break
          case 400:
            console.error("[handleValidateArchive] / 400")
            break
          default:
            console.error("[handleValidateArchive] / Unknown error")
            break
        }
      }
    }
  }

  const currentPackage = editedPackages[currentPackageIndex].data
  const packageInvalid = validatePackage()
  const isPackageValid = packageInvalid === -1

  return (
    <div>
      <h5 className="text-center mb-4 mt-2">
        Merci d'éditer les packages suivants avant l'archivage du produit{" "}
        <b>{product.product.name["1"]}</b>
      </h5>
      <h4 className="text-center">
        <span className="badge badge-pill badge-primary">{`Package ${currentPackageIndex +
          1}/${editedPackages.length}`}</span>
      </h4>
      <h2 className="text-center mb-4 mt-4">
        {editedPackages[currentPackageIndex].data.name[1]}
      </h2>
      {extras && accommodations && campsOptions ? (
        <div className={cn([styles.editView])}>
          {/* SLOTS */}
          <fieldset className="form-group">
            <legend>Créneaux</legend>
            {currentPackage?.slots?.map((slot, slotIndex) => (
              // SLOT
              <div className="card form-group">
                <div className="card-body">
                  <legend>Bundles</legend>
                  {Object.keys(slot.bundles)
                    .map(bundleId => ({
                      ...slot.bundles[bundleId],
                      id: bundleId,
                    }))
                    .map((bundle, bundleIndex) => (
                      <div className="card form-group">
                        <div className="card-body">
                          {/* CAMP */}
                          <div className="form-group">
                            <label>Stage</label>
                            <select
                              value={bundle.campId}
                              onChange={e =>
                                setPackageValue(
                                  PRODUCTS.CAMPS,
                                  getPath(PRODUCTS.CAMPS, slotIndex, bundle.id),
                                  e.target.value,
                                  {
                                    slotIndex,
                                    bundleId: bundle.id,
                                  }
                                )
                              }
                              className="form-control"
                            >
                              {campsOptions.map(option => (
                                <option key={option.id} value={option.id}>
                                  {option.product.name[1]}
                                </option>
                              ))}
                            </select>
                            {packageInvalid === bundle.campId &&
                            productType === PRODUCTS.CAMPS ? (
                              <div className={styles.invalid}>
                                Le stage séléctionné va être archivé, veuillez
                                le retirer avant de continuer
                              </div>
                            ) : null}
                          </div>

                          {/* ACCOMMODATIONS */}
                          <div>
                            <fieldset className="form-group">
                              <legend>Hébergement</legend>
                              <div className="form-group">
                                {bundle.accommodationsIds.find(
                                  accIds => accIds === packageInvalid
                                ) && productType === PRODUCTS.ACCOMMODATIONS ? (
                                  <div className={cn([styles.invalid, "mb-2"])}>
                                    Un hébergement séléctionné va être archivé,
                                    veuillez le retirer avant de continuer
                                  </div>
                                ) : null}
                                <div className="form-check form-check-inline">
                                  <input
                                    id="accomodation-mandatory"
                                    type="checkbox"
                                    checked={bundle.fixedAccommodation}
                                    onChange={e =>
                                      setPackageValue(
                                        "fixedAccommodation",
                                        getPath(
                                          "fixedAccommodation",
                                          slotIndex,
                                          bundle.id
                                        ),
                                        !bundle.fixedAccommodation,
                                        {
                                          slotIndex,
                                          bundleId: bundle.id,
                                        }
                                      )
                                    }
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor="accomodation-mandatory"
                                    className="form-check-label"
                                  >
                                    Obligatoire
                                  </label>
                                </div>
                              </div>

                              <div className="form-group">
                                {accommodations.map(accommodation => (
                                  <div
                                    key={accommodation.id}
                                    className="form-check form-check-inline"
                                  >
                                    <input
                                      id={`${accommodation.id}-${accommodation.product.name[1]}`}
                                      type={
                                        bundle.fixedAccommodation
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      value={accommodation.id}
                                      checked={
                                        bundle.accommodationsIds
                                          ? bundle.accommodationsIds.includes(
                                              accommodation.id
                                            )
                                          : false
                                      }
                                      onChange={e =>
                                        setPackageValue(
                                          PRODUCTS.ACCOMMODATIONS,
                                          getPath(
                                            PRODUCTS.ACCOMMODATIONS,
                                            slotIndex,
                                            bundle.id
                                          ),
                                          accommodation.id,
                                          {
                                            slotIndex,
                                            bundleId: bundle.id,
                                          }
                                        )
                                      }
                                      className="form-check-input"
                                    />
                                    <label
                                      htmlFor={`${accommodation.id}-${accommodation.product.name[1]}`}
                                      className="form-check-label"
                                    >
                                      {accommodation.product.name[1]}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          </div>

                          {/* EXTRA */}
                          <div>
                            <fieldset className="form-group">
                              <legend>Extras</legend>
                              {bundle.bundleExtras.find(
                                extra =>
                                  extra.extraId === packageInvalid.extraId
                              ) && productType === PRODUCTS.EXTRAS ? (
                                <div className={cn([styles.invalid, "mb-2"])}>
                                  Un extra va être archivé, veuillez mettre à
                                  zéro le montant proposé et imposé pour celui
                                  ci
                                </div>
                              ) : null}
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Extra</th>
                                    <th style={{ textAlign: "right" }}>
                                      Proposé
                                    </th>
                                    <th style={{ textAlign: "right" }}>
                                      Imposé
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {extras.map(extra => (
                                    <tr key={`extra_${extra.id}`}>
                                      <td>{extra.product.name[1]}</td>
                                      <td className={styles.extraColumn}>
                                        <input
                                          style={{ marginLeft: "-0.5rem" }}
                                          type="number"
                                          value={
                                            getBundleExtra(
                                              bundle.bundleExtras,
                                              extra.id
                                            ).optional
                                          }
                                          onChange={e =>
                                            setPackageValue(
                                              PRODUCTS.EXTRAS,
                                              getPath(
                                                PRODUCTS.EXTRAS,
                                                slotIndex,
                                                bundle.id
                                              ),
                                              Number(e.target.value),
                                              {
                                                slotIndex,
                                                bundleId: bundle.id,
                                                extraType: "optional",
                                                extraId: extra.id,
                                              }
                                            )
                                          }
                                          className={cn([
                                            styles.extraInput,
                                            "form-control",
                                          ])}
                                        />
                                      </td>
                                      <td className={styles.extraColumn}>
                                        <input
                                          style={{ marginLeft: "-0.5rem" }}
                                          type="number"
                                          value={
                                            getBundleExtra(
                                              bundle.bundleExtras,
                                              extra.id
                                            ).fixed
                                          }
                                          onChange={e =>
                                            setPackageValue(
                                              PRODUCTS.EXTRAS,
                                              getPath(
                                                PRODUCTS.EXTRAS,
                                                slotIndex,
                                                bundle.id
                                              ),
                                              Number(e.target.value),
                                              {
                                                slotIndex,
                                                bundleId: bundle.id,
                                                extraType: "fixed",
                                                extraId: extra.id,
                                              }
                                            )
                                          }
                                          className={cn([
                                            styles.extraInput,
                                            "form-control",
                                          ])}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </fieldset>
        </div>
      ) : (
        <Spinner />
      )}
      {/* STEP BUTTONS */}
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12 float-right">
          <div className="float-right">
            {currentPackageIndex > 0 ? (
              <button
                type="button"
                onClick={goToPreviousPackage}
                className="btn btn-secondary mr-2"
              >
                <i className="fas fa-arrow-left mr-2" />
                Package précédent
              </button>
            ) : null}
            <span>
              {currentPackageIndex + 1 === editedPackages.length ? (
                <button
                  type="button"
                  onClick={handleValidateArchive}
                  className="btn btn-warning"
                  disabled={!isPackageValid}
                >
                  VALIDER l'ARCHIVAGE
                </button>
              ) : (
                <button
                  type="button"
                  onClick={goToNextPackage}
                  className="btn btn-primary"
                  disabled={!isPackageValid}
                >
                  Package suivant
                  <i className="fas fa-arrow-right ml-2" />
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12 float-right">
          <div className="float-right">
            {!isPackageValid ? (
              <div className={cn([styles.invalid, "mr-2"])}>
                Une / des erreurs au niveau du formulaire empêchent de continuer
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductArchiveWizard.propTypes = {}

export default ProductArchiveWizard
