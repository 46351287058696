/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"
import { format } from "date-fns"

// Components
import Spinner from "../../../../atoms/Spinner/Spinner"
import { TextInput, SelectInput } from "../../../../atoms/Inputs/Inputs"
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"

// Hooks
import useFidPrivileges from "../../../../../hooks/Api/Fid/useFidPrivileges"
import useFidEstablishments from "../../../../../hooks/Api/Fid/useFidEstablishments"
import useFidLevels from "../../../../../hooks/Api/Fid/useFidLevels"
import useFidTargets from "../../../../../hooks/Api/Fid/useFidTargets"

// Utils
import cn from "../../../../../utils/cn"

// Data
import { initialPrivilege } from "../form-initial-values"

import style from "./FidAdminPrivileges.module.css"

const EDITING = "editing"

const privilegesTypes = {
  ONESHOT: "One shot",
  RECURRENT: "Récurrents",
  PERMANENTS: "Permanents",
}

// COMPONENT
const FidAdminPrivileges = () => {
  const [privileges, setPrivileges] = useState([])
  const [establishments, setEstablishments] = useState([])
  const [levels, setLevels] = useState([])
  const [targets, setTargets] = useState({})
  const [filterEstablishment, setFilterEstablishment] = useState(null)

  const [privilegesEdition, setPrivilegesEdition] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [
    { getFidPrivileges, createFidPrivilege, updateFidPrivilege },
  ] = useFidPrivileges()
  const [{ getFidTargets }] = useFidTargets()
  const [{ getFidEstablishments }] = useFidEstablishments()
  const [{ getFidLevels }] = useFidLevels()

  const getFidPrivilegesMem = useCallback(getFidPrivileges, [])
  const getFidTargetsMem = useCallback(getFidTargets, [])
  const getFidEstablishmentsMem = useCallback(getFidEstablishments, [])
  const getFidLevelsMem = useCallback(getFidLevels, [])

  const handleCRUDRequestError = error => {
    if (error.response) {
      switch (error.response.status) {
        case 500: {
          setGlobalMessage({
            ...globalMessage,
            isActive: true,
            message: (
              <span>Une erreur est survenue au moment de la soumission</span>
            ),
            className: "alert alert-danger",
          })
          break
        }
        case 400:
          {
            const errorsList =
              error.response.data && error.response.data.errors
                ? Object.keys(error.response.data.errors).map(errorName => ({
                    field: errorName,
                    message: error.response.data.errors[errorName],
                  }))
                : null

            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <ul className="m-0">
                  {errorsList
                    ? errorsList.map(err => (
                        <li>
                          <b>{err.field}: </b>
                          {err.message || "Erreur au niveau de ce champ"}
                        </li>
                      ))
                    : "Erreur au niveau du formulaire"}
                </ul>
              ),
              className: "alert alert-danger",
            })
          }
          break
        default:
          break
      }
    }
  }
  const handleMessageClose = e => {
    e.preventDefault()

    setGlobalMessage({ ...globalMessage, isActive: false })
  }
  const fetchInitialData = useCallback(async () => {
    const getListFromResponse = r => Object.keys(r).map(id => r[id])

    try {
      const privResp = await getFidPrivilegesMem({
        withoutDeleted: 1,
      })
      const estResp = await getFidEstablishmentsMem({
        withoutAdmin: 1,
      })
      const levResp = await getFidLevelsMem({
        withoutDeleted: 1,
      })
      const targResp = await getFidTargetsMem()

      const privList = getListFromResponse(privResp.data.privileges)
      const estList = getListFromResponse(estResp.data.establishments)
      const levList = getListFromResponse(levResp.data.levels)

      setPrivileges(privList)
      setPrivilegesEdition(
        privList.map(priv => ({
          ...priv,
          isEditing: false,
          loading: false,
          confirmDelete: false,
        }))
      )
      setEstablishments(estList)
      setLevels(levList.sort((a, b) => (a.threshold < b.threshold ? -1 : 1)))
      setTargets(targResp.data.targets)
      setDataReady(true)
    } catch (error) {
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>Une erreur est survenue au moment de charger les données</span>
        ),
        className: "alert alert-danger",
      })
    }
  }, [
    getFidEstablishmentsMem,
    getFidLevelsMem,
    getFidPrivilegesMem,
    getFidTargetsMem,
    globalMessage,
  ])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const setField = (type, fieldId, payload) => {
    if (type === EDITING) {
      setPrivilegesEdition(
        privilegesEdition.map(priv => {
          if (priv.id === fieldId) {
            return { ...priv, ...payload }
          }
          return priv
        })
      )
    } else {
      setPrivileges(
        privileges.map(priv => {
          if (priv.id === fieldId) {
            return { ...priv, ...payload }
          }
          return priv
        })
      )
    }
  }

  const toggleEditMode = (e, id, state) => {
    if (e) e.preventDefault()

    setPrivilegesEdition(
      privilegesEdition.map(priv =>
        priv.id === id
          ? { ...priv, isEditing: state, confirmDelete: false }
          : priv
      )
    )
  }

  const submitUpdate = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const priv = privilegesEdition.find(privE => privE.id === fieldId)
    const { isEditing, expirationDate, loading, id: privId, ...payload } = priv

    try {
      const newLevResp = await updateFidPrivilege(privId, payload)
      setPrivileges(
        privileges.map(e => (e.id === privId ? newLevResp.data : e))
      )
      setPrivilegesEdition(
        privilegesEdition.map(e =>
          e.id === privId
            ? {
                ...newLevResp.data,
                loading: false,
                isEditing: false,
                confirmDelete: false,
              }
            : e
        )
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong> Le privilège a été modifié.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const deletePrivilege = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const priv = privilegesEdition.find(privE => privE.id === fieldId)
    const { isEditing, loading, confirmDelete, id: privId, ...payload } = priv

    try {
      payload.expirationDate = format(new Date(), "yyyy-MM-dd HH:mm:ss")
      const delLevResp = await updateFidPrivilege(privId, payload)
      setPrivileges(
        privileges.map(e => (e.id === privId ? delLevResp.data : e))
      )
      setPrivilegesEdition(
        privilegesEdition.filter(privilege => privilege.id !== privId)
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès: </strong> Le privilège a été supprimé.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const handlePrivilegeFieldChange = (fieldId, fieldName, value) => {
    setField(EDITING, fieldId, {
      [fieldName]: value,
    })
  }

  // ADD Privilege
  const handlePrivilegeTypeCheck = (crud, type) => {
    switch (type) {
      case privilegesTypes.ONESHOT: {
        if (crud === "create") {
          setFieldValue("isPermanent", false)
          setFieldValue("recurrence", null)
        } else {
          // edit
        }
        break
      }
      case privilegesTypes.PERMANENTS: {
        if (crud === "create") {
          setFieldValue("isPermanent", true)
          setFieldValue("recurrence", null)
        } else {
          // edit
        }
        break
      }
      case privilegesTypes.RECURRENT: {
        if (crud === "create") {
          setFieldValue("isPermanent", false)
          setFieldValue("recurrence", 0)
        } else {
          // edit
        }
        break
      }
      default:
        break
    }
  }

  const handleTargetChange = (targetId, state) => {
    if (state) {
      // Add
      setFieldValue("targets", [...values.targets, targetId])
    } else {
      // Remove
      setFieldValue(
        "targets",
        values.targets.filter(id => id !== targetId)
      )
    }
  }

  const setConfirmDelete = (e, privId, value) => {
    e.preventDefault()
    setField(EDITING, privId, {
      confirmDelete: value,
    })
  }

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: initialPrivilege,
    onSubmit: async submitValues => {
      try {
        const newLevResp = await createFidPrivilege(submitValues)
        setPrivileges([...privileges, newLevResp.data])
        setPrivilegesEdition([
          ...privilegesEdition,
          {
            ...newLevResp.data,
            loading: false,
            isEditing: false,
          },
        ])
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> Le privilège a été ajouté.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  const columns = dataReady
    ? [
        {
          id: 1,
          header: "Nom",
          editable: true,
          cell: (priv, edition) => {
            if (!edition.isEditing) {
              return priv.name
            }
            return (
              <TextInput
                label="Nom du privilège"
                id="name"
                name="name"
                placeholder="Nom"
                type="text"
                onChange={e =>
                  handlePrivilegeFieldChange(priv.id, "name", e.target.value)
                }
                value={edition.name}
              />
            )
          },
        },
        {
          id: 2,
          header: "Établissement",
          editable: true,
          cell: (priv, edition) => {
            if (!edition.isEditing) {
              return priv.establishmentId
                ? establishments.find(est => est.id === priv.establishmentId)
                    .name
                : ""
            }
            return (
              <SelectInput
                label="Établissement"
                id="establishmentId"
                name="establishmentId"
                required={false}
                onChange={e =>
                  handlePrivilegeFieldChange(
                    priv.id,
                    "establishmentId",
                    Number(e.target.value)
                  )
                }
                value={Number(edition.establishmentId)}
                options={[
                  <option key="default" value="" selected disabled>
                    Établissement
                  </option>,
                  establishments.map(adm => (
                    <option key={`option${adm.id}`} value={Number(adm.id)}>
                      {adm.name}
                    </option>
                  )),
                ]}
              />
            )
          },
        },
        {
          id: 3,
          header: "Type",
          editable: false,
          cell: (priv, edition) => {
            if (!edition.isEditing) {
              if (priv.isPermanent) return privilegesTypes.PERMANENTS
              if (priv.recurrence !== null) return privilegesTypes.RECURRENT
              return privilegesTypes.ONESHOT
            }
            return null
          },
        },
        {
          id: 4,
          header: "Niveau",
          editable: false,
          cell: (priv, edition) => {
            if (!edition.isEditing) {
              return priv.levelId
                ? levels.find(lev => lev.id === priv.levelId).name
                : ""
            }
            return null
          },
        },
        {
          id: 5,
          header: "Cible",
          editable: true,
          editionSpan: 3,
          cell: (priv, edition) => {
            const targetChange = (targetId, state) => {
              if (state) {
                // Add
                handlePrivilegeFieldChange(priv.id, "targets", [
                  ...edition.targets,
                  targetId,
                ])
              } else {
                // Remove
                handlePrivilegeFieldChange(
                  priv.id,
                  "targets",
                  edition.targets.filter(id => id !== targetId)
                )
              }
            }
            if (!edition.isEditing) {
              return priv.targets.reduce((acc, val, index) => {
                return `${acc} ${targets[val].name}${
                  index >= priv.targets.length - 1 ? "" : ","
                }`
              }, "")
            }
            return (
              <span>
                <b>
                  <label>Cible</label>
                </b>
                <div className="input-group">
                  {Object.keys(targets).map(targetId => (
                    <div
                      key={`targetType_${targetId}`}
                      className="form-check form-check-inline mr-4"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`targetType_${targetId}`}
                        id={`targetType_${targetId}`}
                        onChange={() => {
                          targetChange(
                            Number(targetId),
                            !(
                              edition.targets.find(
                                id => Number(targetId) === id
                              ) !== undefined
                            )
                          )
                        }}
                        checked={
                          edition.targets.find(
                            id => Number(targetId) === id
                          ) !== undefined
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`targetType_${targetId}`}
                      >
                        {targets[targetId].name}
                        <span className="ml-2">
                          {targets[targetId].subLabel || ""}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </span>
            )
          },
        },
      ]
    : []

  return (
    <div>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={handleMessageClose}
      />
      {/* CREATE PRIVILEGE */}
      <div className={cn(["card mb-4"])}>
        <div className="card-body">
          <h5 className="card-title">Création nouveau privilège</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Nom du privilège"
                  id="privName"
                  placeholder="Nom du privilège"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <SelectInput
                  label="Palier"
                  id=""
                  required={false}
                  name="levelId"
                  onChange={e =>
                    setFieldValue("levelId", Number(e.target.value))
                  }
                  onBlur={handleBlur}
                  value={values.levelId}
                  options={[
                    <option key="default" value="" selected disabled>
                      Palier
                    </option>,
                    levels.map(lev => (
                      <option key={`option${lev.id}`} value={lev.id}>
                        {lev.name}
                      </option>
                    )),
                  ]}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <SelectInput
                  label="Établissement"
                  id=""
                  required={false}
                  name="establishmentId"
                  onChange={e =>
                    setFieldValue("establishmentId", Number(e.target.value))
                  }
                  onBlur={handleBlur}
                  value={values.establishmentId}
                  options={[
                    <option key="default" value="" selected disabled>
                      Établissement
                    </option>,
                    establishments.map(est => (
                      <option key={`option${est.id}`} value={est.id}>
                        {est.name}
                      </option>
                    )),
                  ]}
                />
              </div>
            </div>
            <div className="form-row mb-3">
              <div className={cn(["col-sm-12 col-md-12 pb-12", style.column])}>
                <div className="form-check form-check-inline mr-4">
                  <span className="mr-4">
                    <b>Type de privilège</b>
                  </span>
                </div>
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="oneshot"
                    id="oneshot"
                    onChange={() =>
                      handlePrivilegeTypeCheck(
                        "create",
                        privilegesTypes.ONESHOT
                      )
                    }
                    checked={
                      values.recurrence === null && values.isPermanent === false
                    }
                  />
                  <label className="form-check-label" htmlFor="oneshot">
                    {privilegesTypes.ONESHOT}
                  </label>
                </div>
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isPermanent"
                    id="isPermanent"
                    onChange={() =>
                      handlePrivilegeTypeCheck(
                        "create",
                        privilegesTypes.PERMANENTS
                      )
                    }
                    checked={values.isPermanent}
                  />
                  <label className="form-check-label" htmlFor="isPermanent">
                    {privilegesTypes.PERMANENTS}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="recurrence"
                    id="recurrence"
                    onChange={() =>
                      handlePrivilegeTypeCheck(
                        "create",
                        privilegesTypes.RECURRENT
                      )
                    }
                    checked={values.recurrence !== null}
                  />
                  <label className="form-check-label" htmlFor="recurrence">
                    {privilegesTypes.RECURRENT}
                  </label>

                  {values.recurrence !== null ? (
                    <span className="ml-4">
                      <TextInput
                        id="recurrence"
                        type="number"
                        placeholder="Durée de récurrence"
                        name="recurrence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recurrence}
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {dataReady ? (
              <div className="form-row mb-3">
                <div
                  className={cn(["col-sm-12 col-md-12 pb-12", style.column])}
                >
                  <div className="form-check form-check-inline mr-4">
                    <span className="mr-4">
                      <b>Cible</b>
                    </span>
                  </div>
                  {Object.keys(targets).map(targetId => (
                    <div
                      key={`targetType_${targetId}`}
                      className="form-check form-check-inline mr-4"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`targetType_${targetId}`}
                        id={`targetType_${targetId}`}
                        onChange={() => {
                          handleTargetChange(
                            Number(targetId),
                            !(
                              values.targets.find(
                                id => Number(targetId) === id
                              ) !== undefined
                            )
                          )
                        }}
                        checked={
                          values.targets.find(id => Number(targetId) === id) !==
                          undefined
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`targetType_${targetId}`}
                      >
                        {targets[targetId].name}
                        <span className="ml-2">
                          {targets[targetId].subLabel || ""}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="form-row">
              <div className="col-sm-12 col-md-12 pb-12">
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={false}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* PRIVILEGE LIST */}
      {dataReady ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-8 col-md-6 pb-6">
                <SelectInput
                  label="Filtrer par établissement"
                  id=""
                  required={false}
                  onChange={e =>
                    setFilterEstablishment(
                      e.target.value === "null" ? null : Number(e.target.value)
                    )
                  }
                  value={filterEstablishment}
                  options={[
                    <option key="defaultFilterEst" value="null">
                      Établissement de rattachement
                    </option>,
                    establishments.map(est => (
                      <option key={`option${est.id}`} value={Number(est.id)}>
                        {est.name}
                      </option>
                    )),
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 pb-12">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      {columns.map(col => (
                        <th scope="col" key={`table_header_${col.id}`}>
                          {col.header}
                        </th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {privileges
                      .filter(priv => {
                        if (filterEstablishment !== null) {
                          return priv.establishmentId === filterEstablishment
                        }
                        return true
                      })
                      .sort((a, b) => (a.threshold < b.threshold ? -1 : 1))
                      .map(priv => {
                        const edition = privilegesEdition.length
                          ? privilegesEdition.find(
                              privE => privE.id === priv.id
                            )
                          : null

                        if (edition)
                          return (
                            <tr key={`row_${priv.id}`}>
                              {columns.map(col => {
                                if (
                                  (col.editable && edition.isEditing) ||
                                  !edition.isEditing
                                )
                                  return (
                                    <td
                                      colSpan={
                                        (edition.isEditing
                                          ? col.editionSpan
                                          : null) || null
                                      }
                                      key={`column_${col.id}`}
                                      className="align-middle"
                                    >
                                      {col.cell(priv, edition)}
                                    </td>
                                  )
                                return null
                              })}
                              <td className="align-middle">
                                <div
                                  className="btn-group btn-group-sm float-right"
                                  role="group"
                                  aria-label="Edit admin"
                                >
                                  {!edition.isEditing ? (
                                    <span>
                                      {!edition.confirmDelete ? (
                                        <a
                                          onClick={e =>
                                            toggleEditMode(e, priv.id, true)
                                          }
                                          href="/"
                                        >
                                          Modifier
                                        </a>
                                      ) : null}
                                      <span
                                        className={cn([
                                          "ml-4",
                                          !edition.confirmDelete
                                            ? style.delete
                                            : "",
                                        ])}
                                      >
                                        {edition.confirmDelete ? (
                                          <b className={style.confirmLabel}>
                                            Êtes vous sûr ?
                                          </b>
                                        ) : null}
                                        <a
                                          className="text-danger"
                                          onClick={
                                            edition.confirmDelete
                                              ? e => deletePrivilege(e, priv.id)
                                              : e =>
                                                  setConfirmDelete(
                                                    e,
                                                    priv.id,
                                                    true
                                                  )
                                          }
                                          href="/"
                                        >
                                          {edition.confirmDelete
                                            ? "Oui"
                                            : "Supprimer"}
                                        </a>
                                        {edition.confirmDelete ? (
                                          <a
                                            className="ml-4 text-primary"
                                            onClick={e =>
                                              setConfirmDelete(
                                                e,
                                                priv.id,
                                                false
                                              )
                                            }
                                            href="/"
                                          >
                                            Annuler
                                          </a>
                                        ) : null}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <a
                                        className="mr-4"
                                        onClick={e => submitUpdate(e, priv.id)}
                                        href="/"
                                      >
                                        Valider
                                      </a>
                                      <a
                                        className={cn([
                                          "text-danger mr-2",
                                          style.delete,
                                        ])}
                                        onClick={e =>
                                          toggleEditMode(e, priv.id, false)
                                        }
                                        href="/"
                                      >
                                        Annuler
                                      </a>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

FidAdminPrivileges.propTypes = {}
FidAdminPrivileges.defaultProps = {}

export default FidAdminPrivileges
