import React, { useEffect, useCallback, useState, useMemo } from "react"
import { getYear } from "date-fns"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

import "react-datepicker/dist/react-datepicker.css"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Editor from "../../../../components/organisms/Editor/Editor"
import Select from "../../../../components/atoms/Select"
import Archive from "../../../../components/molecules/Archive/Archive"
import ProductArchiveWizard from "../../../../components/molecules/ProductArchiveWizard/ProductArchiveWizard"
import Spinner from "../../../../components/atoms/Spinner/Spinner"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useAccommodationsApi from "../../../../hooks/Api/useAccommodationsApi"
import useSeasonsApi from "../../../../hooks/Api/useSeasonsApi"
import useQuotasApi from "../../../../hooks/Api/useQuotasApi"
import useTypesApi from "../../../../hooks/Api/useTypesApi"
import useDurationsApi from "../../../../hooks/Api/useDurationsApi"
import useFormatsApi from "../../../../hooks/Api/useFormatsApi"
import useSportsApi from "../../../../hooks/Api/useSportsApi"
import useMediasApi from "../../../../hooks/Api/useMediasApi"
import useAttachmentsApi from "../../../../hooks/Api/useAttachmentsApi"
import useTypologiesApi from "../../../../hooks/Api/useTypologiesApi"
import useTaxRatesApi from "../../../../hooks/Api/useTaxRatesApi"
import usePackagesApi from "../../../../hooks/Api/usePackagesApi"
import useYearsApi from "../../../../hooks/Api/useYearsApi"

// Constants
import { PERMISSIONS, PRODUCTS } from "../../../../constants/constants"

// Utils & misc
import cn from "../../../../utils/cn"
import {
  isProductUsedInPackage,
  whichPackagesContainsProduct,
} from "../../../../utils/productsUtils"
import { exportCollectionToArray, exportCollectionToArrayImages } from "../../../../utils/collection"
import { getInitialLocaleValues } from "../../../../utils/locales"

import styles from "./AccommodationsEdit.module.css"
import MediasInput from "../../../../components/atoms/MediasInput/MediasInput"

const VIEWS = {
  FORM: "FORM",
  ARCHIVE: "ARCHIVE",
}

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE,
  PERMISSIONS.PERM_ACCOMMODATIONS_CREATE,
  PERMISSIONS.PERM_ACCOMMODATIONS_ARCHIVE,
]

const AccommodationsEdit = ({ match }) => {
  const familyId = 3
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const history = useHistory()

  const [, seasonsData, , { getSeasons }] = useSeasonsApi()
  const [, quotasData, , { getQuotas }] = useQuotasApi()
  const [, typesData, , { getTypes }] = useTypesApi()
  const [, durationsData, , { getDurations }] = useDurationsApi()
  const [, formatsData, , { getFormats }] = useFormatsApi()
  const [, sportsData, , { getSports }] = useSportsApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, attachmentsData, , { getAttachments }] = useAttachmentsApi()
  const [, typologiesData, , { getTypologies }] = useTypologiesApi()
  const [, taxRatesData, , { getTaxRates }] = useTaxRatesApi()
  const [, packagesData, , { getPackages }] = usePackagesApi()
  const [, yearsData, , { getYears }] = useYearsApi()

  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  // Accommodations API Hooks
  const [
    ,
    accommodationData,
    accommodationError,
    { getAccommodationWithId },
  ] = useAccommodationsApi()
  const [
    updateAccommodationState,
    ,
    updateAccommodationError,
    { updateAccommodation },
  ] = useAccommodationsApi()
  const [
    newAccommodationState,
    ,
    newAccommodationError,
    { createAccommodation },
  ] = useAccommodationsApi()
  const [
    ,
    constraintData,
    ,
    { getAccommodationConstraints },
  ] = useAccommodationsApi()

  const isEditingAccommodation = !isEmpty(match.params)

  const [isUsedInPackage, setIsUsedInPackage] = useState(undefined)
  const [packagesContaining, setPackagesContaining] = useState([])
  const [currentView, setCurrentView] = useState(VIEWS.FORM)

  const savedGetAccommodationWithId = useCallback(getAccommodationWithId, [])
  const savedGetAccommodationConstraints = useCallback(
    getAccommodationConstraints,
    []
  )
  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetQuotas = useCallback(getQuotas, [])
  const savedGetTypes = useCallback(getTypes, [])
  const savedGetDurations = useCallback(getDurations, [])
  const savedGetFormats = useCallback(getFormats, [])
  const savedGetSports = useCallback(getSports, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetAttachments = useCallback(getAttachments, [])
  const savedGetTypologies = useCallback(getTypologies, [])
  const savedTaxRates = useCallback(getTaxRates, [])
  const savedGetPackages = useCallback(getPackages, [])
  const savedGetYears = useCallback(getYears, [])

  const getInitialConstraintValue = () =>
    constraintData?.accommodationConstraints[0].code

  const getTaxRateByFamilyId = (first = false) => {
    if (taxRatesData && taxRatesData.taxRates) {
      const taxRatesArray = Object.keys(taxRatesData.taxRates).map(
        taxRateId => taxRatesData.taxRates[taxRateId]
      )

      if (first)
        return taxRatesArray.find(taxRate =>
          taxRate.taxRateFamiliesIds.find(id => id === familyId)
        )
      return taxRatesArray.filter(
        taxRate => !!taxRate.taxRateFamiliesIds.find(id => id === familyId)
      )
    }
    return null
  }

  const getCurrentYearId = () => {
    if (yearsData) {
      const year = getYear(new Date())
      const years = exportCollectionToArray(yearsData, "years")
      return years?.find(y => y.number === year)?.id || years[0].id
    }
    return null
  }
  const currentYearId = getCurrentYearId()

  const createPriceObject = () => {
    return {
      [currentYearId]: Object.keys(seasonsData.seasons).reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue] = 0
          return accumulator
        },
        {}
      ),
    }
  }

  const createInitialValues =
    locales && seasonsData && taxRatesData && constraintData
      ? {
          product: {
            code: "",
            account: "",
            name: getInitialLocaleValues(locales),
            prices: createPriceObject(),
            taxRateId: getTaxRateByFamilyId(true).id,
            quotasIds: [],
            defaultStock: 0,
            active: true,
          },
          subtitle: getInitialLocaleValues(locales),
          description: getInitialLocaleValues(locales),
          thumbnailMediaId: 1,
          sliderMediaIds: [],
          typologyId: 1,
          capacity: 1,
          constraintCode: getInitialConstraintValue(),
        }
      : {}

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      accommodations: isEditingAccommodation
        ? accommodationData || {}
        : createInitialValues,
    },
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      const sanitizedValues = {
        ...valuesSubmit.accommodations,
        product: { ...valuesSubmit.accommodations.product, archived: false },
      }
      if (isEditingAccommodation)
        updateAccommodation(sanitizedValues).then(() =>
          history.push({
            pathname: "/accommodations/list",
            state: { submitType: "edit", success: true },
          })
        )
      else
        createAccommodation(sanitizedValues).then(() =>
          history.push({
            pathname: "/accommodations/list",
            state: { submitType: "create", success: true },
          })
        )
    },
  })

  const backToList = () => {
    history.push({
      pathname: "/accommodations/list",
      state: {},
    })
  }

  useEffect(() => {
    if (isEditingAccommodation) savedGetAccommodationWithId(match.params.id)
    savedGetAccommodationConstraints()
    savedGetSeasons()
    savedGetQuotas({ archived: false })
    savedGetTypes()
    savedGetDurations()
    savedGetFormats()
    savedGetSports()
    savedGetMedias()
    savedGetAttachments()
    savedGetTypologies()
    savedTaxRates()
    savedGetPackages()
    savedGetYears()
  }, [
    isEditingAccommodation,
    savedGetAccommodationWithId,
    savedGetSeasons,
    savedGetQuotas,
    savedGetTypes,
    match.params.id,
    savedGetDurations,
    savedGetFormats,
    savedGetSports,
    savedGetMedias,
    savedGetAttachments,
    savedGetTypologies,
    savedTaxRates,
    savedGetPackages,
    savedGetYears,
  ])

  const handleSelectionChange = selectedOptions => {
    setFieldValue(
      "accommodations.sliderMediaIds",
      selectedOptions.map(option => Number(option.value))
    )
  }

  const handleAccommodationArchive = async () => {
    const sanitizedValues = {
      ...values.accommodations,
      product: { ...values.accommodations.product, archived: true },
    }
    if (isEditingAccommodation)
      updateAccommodation(sanitizedValues).then(() =>
        history.push({
          pathname: "/accommodations/list",
          state: { submitType: "edit", success: true },
        })
      )
    else
      createAccommodation(sanitizedValues).then(() =>
        history.push({
          pathname: "/accommodations/list",
          state: { submitType: "create", success: true },
        })
      )
  }

  let isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_CREATE] &&
      !isEditingAccommodation)

  if (isEditingAccommodation) {
    if (accommodationData && accommodationData.product.archived)
      isAllowedToEdit = false
  }

  // When packageData changes, check if product is used in any of them, inactive or not
  useEffect(() => {
    if (packagesData && accommodationData) {
      const isProductUsed = isProductUsedInPackage(
        accommodationData,
        packagesData.packages
      )
      setIsUsedInPackage(isProductUsed)

      setPackagesContaining(
        whichPackagesContainsProduct(accommodationData, packagesData.packages)
      )
    }
  }, [packagesData, accommodationData])

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Hébergement / ${isEditingAccommodation ? "Éditer" : "Nouveau"}`}
          {accommodationData
            ? ` / ${accommodationData.product.name["1"]}`
            : null}
        </h1>
      </div>

      <div className={cn(["mw-100", "w-100", "m-auto", styles.editSection])}>
        {currentView === VIEWS.FORM ? (
          <>
            {values.accommodations.product &&
            seasonsData &&
            locales &&
            quotasData &&
            durationsData &&
            formatsData &&
            sportsData &&
            mediasData &&
            attachmentsData &&
            typologiesData &&
            taxRatesData &&
            yearsData &&
            typesData ? (
              <form onSubmit={handleSubmit}>
                {accommodationError ||
                updateAccommodationError ||
                newAccommodationError ? (
                  <div className="alert alert-error" role="alert">
                    error
                  </div>
                ) : null}

                <div className={cn(["card mb-4"])}>
                  <div className="card-body">
                    <h5 className="card-title">Informations</h5>

                    <div className="form-row">
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="accommodation-typeId">Code</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id="accommodations.product.code"
                          name="accommodations.product.code"
                          value={values.accommodations.product.code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="accommodation-typeId">Account</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id="accommodations.product.account"
                          name="accommodations.product.account"
                          value={values.accommodations.product.account}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {Object.keys(locales).map(localeId => (
                        <div
                          key={`name_input_${localeId}`}
                          className="col-sm-4 col-md-4 pb-3"
                        >
                          <b>
                            <label htmlFor="accommodation-typeId">{`Name ${locales[
                              localeId
                            ].code.toUpperCase()}`}</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            required
                            type="text"
                            className="form-control"
                            id={`accommodations.product.name.${localeId}`}
                            name={`accommodations.product.name.${localeId}`}
                            value={values.accommodations.product.name[localeId]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      ))}

                      {!isEditingAccommodation &&
                        Object.keys(seasonsData.seasons).map(seasonId => (
                          <div
                            key={`price_input_${seasonId}`}
                            className="col-sm-4 col-md-4 pb-3"
                          >
                            <b>
                              <label htmlFor="accommodation-typeId">{`Saison ${seasonsData.seasons[seasonId].name}`}</label>
                            </b>
                            <div className="input-group">
                              <input
                                disabled={!isAllowedToEdit}
                                required
                                type="number"
                                className="form-control"
                                id={`accommodations.product.prices.${currentYearId}.${seasonId}`}
                                name={`accommodations.product.prices.${currentYearId}.${seasonId}`}
                                value={
                                  values.accommodations.product.prices[
                                    currentYearId
                                  ][seasonId] / 100
                                }
                                onChange={(e) => setFieldValue(`accommodations.product.prices.${currentYearId}.${seasonId}`, Number(e.target.value) * 100)}
                                onBlur={handleBlur}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">€</span>
                              </div>
                            </div>
                            <div className="valid-tooltip">Looks good!</div>
                          </div>
                        ))}

                      <div className="col-sm-4 col-md-4 pb-3">
                        <b>
                          <label htmlFor="accommodation-typeId">
                            Default stock
                          </label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="number"
                          className="form-control"
                          id="accommodations.product.defaultStock"
                          name="accommodations.product.defaultStock"
                          value={values.accommodations.product.defaultStock}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      <div className="col-sm-4 col-md-4 pb-3">
                        <b>
                          <label htmlFor="accommodation-typeId">Capacité</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="number"
                          className="form-control"
                          id="accommodations.capacity"
                          name="accommodations.capacity"
                          value={values.accommodations.capacity > 0 ? values.accommodations.capacity : 1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min={1}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      <div className="col-sm-4 col-md-4 pb-3">
                        <b>
                          <label htmlFor="accommodation-taxRate">
                            Tax rate
                          </label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="accommodations.product.taxRateId"
                          name="accommodations.product.taxRateId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "accommodations.product.taxRateId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.accommodations.product.taxRateId}
                        >
                          {getTaxRateByFamilyId().map(taxRate => (
                            <option
                              key={`duration_id_select_${taxRate.id}`}
                              value={taxRate.id}
                            >
                              {taxRate.value}
                            </option>
                          ))}
                        </select>

                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {Object.keys(locales).map(localeId => (
                        <div
                          key={`subtitle_input_${localeId}`}
                          className="col-sm-4 col-md-4 pb-3"
                        >
                          <b>
                            <label htmlFor="accommodation-typeId">{`Subtitle ${locales[
                              localeId
                            ].code.toUpperCase()}`}</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            required
                            type="text"
                            className="form-control"
                            id={`accommodations.subtitle.${localeId}`}
                            name={`accommodations.subtitle.${localeId}`}
                            value={values.accommodations.subtitle[localeId]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      ))}

                      {Object.keys(locales).map(localeId => (
                        <div
                          key={`description_input_${localeId}`}
                          className="col-sm-4 col-md-4 pb-3"
                        >
                          <b>
                            <label htmlFor="accommodation-typeId">{`Description ${locales[
                              localeId
                            ].code.toUpperCase()}`}</label>
                          </b>
                          <Editor
                            disabled={!isAllowedToEdit}
                            initialContent={
                              values.accommodations.description[localeId]
                            }
                            handleContentChange={htmlContent =>
                              setFieldValue(
                                `accommodations.description.${localeId}`,
                                htmlContent
                              )
                            }
                            label={null}
                            error={null}
                          />

                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      ))}

                      <div className="col-sm-12 col-md-12 pb-3">
                        <MediasInput
                          label="Thumbnail"
                          options={mediasOptions}
                          selection={values.accommodations?.thumbnailMediaId ? [values.accommodations.thumbnailMediaId] : []}
                          setSelection={(value) => setFieldValue('accommodations.thumbnailMediaId', value[0]?.id || null)}
                          disabled={!isAllowedToEdit}
                          required
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-thumbnailMediaId">
                            Slider media ids
                          </label>
                        </b>
                        <Select
                          isDisabled={!isAllowedToEdit}
                          value={values.accommodations.sliderMediaIds.map(
                            id => {
                              const foundMedia = Object.keys(
                                mediasData.medias
                              ).find(mediaId => Number(mediaId) === id)
                              return {
                                label: mediasData.medias[foundMedia].fileKey,
                                value: mediasData.medias[foundMedia].id,
                              }
                            }
                          )}
                          onChange={handleSelectionChange}
                          isMulti
                          options={Object.keys(mediasData.medias).map(
                            mediaId => ({
                              value: mediaId,
                              label: mediasData.medias[mediaId].fileKey,
                            })
                          )}
                        />
                      </div>
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-thumbnailMediaId">
                            Contraintes
                          </label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="accommodations.constraintCode"
                          name="accommodations.constraintCode"
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.accommodations.constraintCode}
                        >
                          {constraintData.accommodationConstraints.map(
                            constraint => (
                              <option
                                key={`constraint_select_${constraint.code}`}
                                value={constraint.code}
                              >
                                {constraint.code}
                              </option>
                            )
                          )}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-thumbnailMediaId">
                            Typologie
                          </label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="accommodations.typologyId"
                          name="accommodations.typologyId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "accommodations.typologyId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.accommodations.typologyId}
                        >
                          {Object.keys(typologiesData.typologies)
                            .filter(
                              t => typologiesData.typologies[t].familyId === 3
                            )
                            .map(typology => (
                              <option
                                key={`typology_id_select_${typology}`}
                                value={typology}
                              >
                                {typologiesData.typologies[typology].code}
                              </option>
                            ))}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    </div>
                  </div>
                </div>
                {rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_ARCHIVE] &&
                isEditingAccommodation &&
                !accommodationData?.product?.archived ? (
                  <div className="float-right mb-4">
                    {/* {isUsedInPackage ? (
                      <span className="mr-3 text-secondary">
                        Ce produit est utilisé dans un package
                      </span>
                    ) : null} */}
                    <button
                      type="button"
                      onClick={() => setCurrentView(VIEWS.ARCHIVE)}
                      className="btn btn-danger"
                      // disabled={isUsedInPackage}
                    >
                      ARCHIVER
                    </button>
                  </div>
                ) : null}
                <div className="mb-4">
                  {isAllowedToEdit ? (
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={
                        updateAccommodationState === "loading" ||
                        newAccommodationState === "loading"
                      }
                    >
                      {updateAccommodationState === "loading" ||
                      newAccommodationState === "loading" ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isEditingAccommodation ? "SAUVEGARDER" : "CRÉER"}
                    </button>
                  ) : (
                    <button
                      onClick={backToList}
                      type="button"
                      className="btn btn-primary"
                    >
                      Revenir à la liste
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </>
        ) : null}
        {currentView === VIEWS.ARCHIVE ? (
          <div className={styles.formWidth}>
            <Archive
              onArchivedClick={() => handleAccommodationArchive()}
              onCancelClick={() => setCurrentView(VIEWS.FORM)}
              customView={isUsedInPackage}
              centered={!isUsedInPackage}
            >
              {isUsedInPackage ? (
                <div>
                  {packagesContaining ? (
                    <ProductArchiveWizard
                      productType={PRODUCTS.ACCOMMODATIONS}
                      product={accommodationData}
                      packagesContaining={packagesContaining}
                      handleProductArchive={handleAccommodationArchive}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              ) : (
                <div className={styles.archiveText}>
                  {`Etes-vous sûr de vouloir archiver l'hébergement ${accommodationData.product.name["1"]} ?`}
                </div>
              )}
            </Archive>
          </div>
        ) : null}
      </div>
    </WithMenu>
  )
}

AccommodationsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
}

export default AccommodationsEdit
