import React, { useEffect, useCallback, useState, useMemo } from "react"

// Utils
import { Link, useLocation } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"
import { sortBy } from "lodash"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Table from "../../../../components/organisms/Table"
import IDTooltip from "../../../../components/molecules/IDTooltip/IDTooltip"

// Hooks
import useCampsApi from "../../../../hooks/Api/useCampsApi"
import useProductsApi from "../../../../hooks/Api/useProductsApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

import style from "./CampsList.module.css"

const permissions = [
  PERMISSIONS.PERM_CAMPS_DISPLAY,
  PERMISSIONS.PERM_CAMPS_UPDATE,
  PERMISSIONS.PERM_CAMPS_CREATE,
  PERMISSIONS.PERM_CAMPS_ARCHIVE,
  PERMISSIONS.PERM_CAMP_PRICES_UPDATE,
]

const CampsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [campsRequestState, campsData, campsError, { getCamps }] = useCampsApi({
    trigger: true,
  })
  const [orderBookingProductState, , , { orderBookingProduct }] = useProductsApi()

  const savedGetCamps = useCallback(getCamps, [])
  const { state } = useLocation()
  const [displayArchived, setDisplayArchived] = useState(false)

  useEffect(() => {
    savedGetCamps()
  }, [savedGetCamps])

  const campsCollectionToArray = useMemo(() => (
    campsData && campsData.camps
      ? Object.keys(campsData.camps).map(i => campsData.camps[i])
      : []
  ), [campsData])

  const filteredCampsList = useMemo(() => (
    sortBy(
      campsCollectionToArray.filter((camps) => {
        if (!camps.product) return false
        if (displayArchived) {
          if (camps.product.archived) return true
          return false
        }
        if (camps.product.archived) return false
        return true
      }),
      (camp) => camp.product?.order,
    )
    .reverse()
  ), [campsData, displayArchived])

  const onDragEnd = async (result) => {
    if (!result?.destination || !result?.source) return;

    let destination = null;
    if (result.source.index > result.destination?.index) {
      destination = result.destination.index - 1 < 0 ? null : filteredCampsList[result.destination.index - 1];
    } else {
      destination = filteredCampsList[result.destination.index];
    }

    const productToOrder = filteredCampsList[result.source.index]?.product;
    const destinationProduct = destination?.product;

    await orderBookingProduct(productToOrder?.id, destinationProduct?.id)
    savedGetCamps()
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <div className="d-inline-flex">
          <h1>Produits / Stages</h1>
          {displayArchived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Stages archivés
              </span>
            </h3>
          ) : null}
        </div>
        {rolePermissions[PERMISSIONS.PERM_CAMPS_CREATE] ? (
          <Link className="btn btn-success" to="/camps/edit">
            Nouveau Stage
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {campsError ? (
        <div className="alert alert-campsError" role="alert">
          {campsError}
        </div>
      ) : null}
      {(campsRequestState === "loading" && !campsData) || orderBookingProductState === 'loading' ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_CAMPS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_CAMPS_UPDATE] ? (
            <>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="display-archived"
                  checked={displayArchived}
                  onChange={() => setDisplayArchived(!displayArchived)}
                />
                <label className="form-check-label" htmlFor="display-archived">
                  Afficher les stages archivés
                </label>
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                <Table
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                    },
                    {
                      Header: "Id Produit",
                      accessor: "product",
                      Cell: ({
                        cell: {
                          row: {
                            values: { product },
                          },
                        },
                      }) =>
                        product ? <IDTooltip uid={product.id} /> : <span>?</span>,
                    },
                    {
                      Header: "Nom",
                      accessor: "product.name.1",
                    },
                    {
                      Header: "Code",
                      accessor: "product.code",
                    },
                    {
                      Header: "",
                      accessor: "tag",
                      Cell: ({
                        cell: {
                          row: {
                            values: { id, product },
                          },
                        },
                      }) => (
                        <>
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Edit camp"
                          >
                            <Link
                              className="btn btn-secondary"
                              to={`/camps/edit/${id}`}
                            >
                              Éditer
                            </Link>
                          </div>
                          {rolePermissions[
                            PERMISSIONS.PERM_CAMP_PRICES_UPDATE
                          ] ? (
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Edit camp"
                            >
                              <Link
                                className="btn btn-secondary ml-3"
                                to={`/prices/edit/${product.id}`}
                              >
                                Prix
                              </Link>
                            </div>
                          ) : null}
                        </>
                      ),
                    },
                  ]}
                  data={filteredCampsList}
                  hasPagination
                  isDraggable={rolePermissions[PERMISSIONS.PERM_CAMPS_UPDATE] && !displayArchived}
                />
              </DragDropContext>
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default CampsList
