import React, { useEffect, useCallback, useState } from "react"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import IntlLegacy from "./IntlLegacy"

// Hooks
import useUtilsApi from "../../../hooks/Api/useUtilsApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import useIntlApi from "../../../hooks/Api/useIntlApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./Intl.module.css"

const permissions = [PERMISSIONS.PERM_TRANSLATIONS_UPDATE]

const Intl = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [loading, setLoading] = useState(false)
  const [, localesData, , { getLocales }] = useUtilsApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, intlData, , { getIntl }] = useIntlApi()

  const [, , , { updateIntl }] = useIntlApi()

  const savedGetLocales = useCallback(getLocales, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetIntl = useCallback(getIntl, [])

  useEffect(() => {
    savedGetLocales()
    savedGetMedias()
    savedGetIntl()
  }, [savedGetLocales, savedGetMedias, savedGetIntl])

  const localesCollectionToArray = () =>
    localesData && localesData.locales
      ? Object.keys(localesData.locales).map(i => ({
          ...localesData.locales[i],
          id: i,
        }))
      : []

  const handleUpdateIntl = stocksEvents =>
    updateIntl(stocksEvents).then(res => {
      if (res && res.data) {
        return res.data
      }
    })

  useEffect(() => {
    if (localesData && mediasData && intlData) {
      setLoading(true)
    }
  }, [localesData, mediasData, intlData])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Intl</h1>
      </div>

      {loading ? (
        <IntlLegacy
          canUserEdit={rolePermissions[PERMISSIONS.PERM_TRANSLATIONS_UPDATE]}
          locales={localesCollectionToArray()}
          medias={Object.keys(mediasData.medias).reduce((acc, media) => {
            acc[media] = {
              ...mediasData.medias[media],
              name: mediasData.medias[media].fileKey,
            }
            return acc
          }, {})}
          entities={Object.keys(intlData.entities).map(
            item => intlData.entities[item]
          )}
          types={intlData.types}
          data={intlData.data}
          updateIntl={handleUpdateIntl}
        />
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

export default Intl
