// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useSportsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getSports = () => request("GET", `${API_STAGES_URL}/v1/sports`)

  const getSportWithId = sportId =>
    request("GET", `${API_STAGES_URL}/v1/sports/${sportId}`)

  const updateSport = sport =>
    request("PUT", `${API_STAGES_URL}/v1/sports/${sport.id}`, sport)

  const createSport = sport =>
    request("POST", `${API_STAGES_URL}/v1/sports`, sport)

  return [
    requestState,
    payload,
    error,
    {
      getSports,
      getSportWithId,
      updateSport,
      createSport,
    },
  ]
}

export default useSportsApi
