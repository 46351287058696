import React from "react"
import PropTypes from "prop-types"

// Components
import { TextInput } from "../../../components/atoms/Inputs/Inputs"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

import "./ConfigSnippets.module.css"
import MediasInput from "../../../components/atoms/MediasInput/MediasInput"

const permissions = [
  PERMISSIONS.PERM_CONFIG_POPIN_DISPLAY,
  PERMISSIONS.PERM_CONFIG_POPIN_UPDATE,
]

const ConfigHomePopin = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  medias,
}) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const handleCheckboxClick = (field, val) => {
    setFieldValue(field, val)
  }

  const localesArray = Object.keys(locales).map(localeId => ({
    id: localeId,
    code: locales[localeId].code,
  }))

  return (
    <>
      {rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_DISPLAY] ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <h3 className="card-title mb-4">Home Pop-in</h3>

            {/* checkbox */}
            <div className="col-sm-12 col-md-12 pb-4 pl-0">
              <div className="form-check">
                <input
                  disabled={
                    !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                  }
                  checked={values.homeModalShow}
                  className="form-check-input"
                  type="checkbox"
                  onChange={() =>
                    handleCheckboxClick("homeModalShow", !values.homeModalShow)
                  }
                  id="quotas_input_showIfWithAccommodations"
                />
                <label
                  className="form-check-label"
                  htmlFor="quotas_input_showIfWithAccommodations"
                >
                  Afficher la pop-in ?
                </label>
              </div>
            </div>

            {/* homeModalTitles */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Titre</h4>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <TextInput
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          label={`${locale.code}`}
                          id={`homeModalTitles.${locale.id}`}
                          name={`homeModalTitles.${locale.id}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.homeModalTitles[locale.id]}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>
            {/* homeModalSubtitles && homeModalSubtitleColor */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Sous titre</h4>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <TextInput
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          label={`${locale.code}`}
                          id={`homeModalSubtitles.${locale.id}`}
                          name={`homeModalSubtitles.${locale.id}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.homeModalSubtitles[locale.id]}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className="form-row">
                <div className="col-sm-12 col-md-6 pb-2">
                  <TextInput
                    disabled={
                      !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                    }
                    label="couleur (texte)"
                    id="homeModalSubtitleColor"
                    name="homeModalSubtitleColor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.homeModalSubtitleColor}
                    placeholder="#FF00FF"
                  />
                </div>
              </div>
            </div>
            {/* homeModalBodies && homeModalBodyColor */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Body</h4>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <label
                          htmlFor={`config-frontAndMiddleJsSnippets-${locale.id}`}
                        >
                          {`${locale.code} (limite de 300 caractères)`}
                        </label>
                        <textarea
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          className="form-control"
                          id={`homeModalBodies.${locale.id}`}
                          name={`homeModalBodies.${locale.id}`}
                          rows="3"
                          value={values.homeModalBodies[locale.id]}
                          onChange={e =>
                            setFieldValue(
                              `homeModalBodies.${locale.id}`,
                              e.target.value.length > 300
                                ? values.homeModalBodies[locale.id]
                                : e.target.value
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className="form-row">
                <div className="col-sm-12 col-md-6 pb-2">
                  <TextInput
                    disabled={
                      !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                    }
                    label="couleur (texte)"
                    id="homeModalBodyColor"
                    name="homeModalBodyColor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.homeModalBodyColor}
                    placeholder="#FF00FF"
                  />
                </div>
              </div>
            </div>
            {/* homeModalEmailLabels && homeModalEmail && homeModalEmailColor */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Email</h4>
              <h5 className="mt-4">Label</h5>
              <div className="form-row mb-4">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <TextInput
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          label={`${locale.code}`}
                          id={`homeModalEmailLabels.${locale.id}`}
                          name={`homeModalEmailLabels.${locale.id}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.homeModalEmailLabels[locale.id]}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className="form-row">
                <div className="col-sm-12 col-md-6 pb-2">
                  <TextInput
                    disabled={
                      !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                    }
                    type="text"
                    label="email"
                    id="homeModalEmail"
                    name="homeModalEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.homeModalEmail}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12 col-md-6 pb-2">
                  <TextInput
                    disabled={
                      !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                    }
                    label="couleur (texte)"
                    id="homeModalEmailColor"
                    name="homeModalEmailColor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.homeModalEmailColor}
                    placeholder="#FF00FF"
                  />
                </div>
              </div>
            </div>
            {/* homeModalSubmits && homeModalBodyColor */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Submits</h4>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <TextInput
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          label={`${locale.code}`}
                          id={`homeModalSubmits.${locale.id}`}
                          name={`homeModalSubmits.${locale.id}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.homeModalSubmits[locale.id]}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>

            {/* media */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Image</h4>
              <div className="form-row">
                <div className="col-sm-12 col-md-12 pb-1 mt-3">
                  <MediasInput
                    options={medias}
                    selection={values.homeModalMediaId ? [values.homeModalMediaId] : []}
                    setSelection={(value) => setFieldValue("homeModalMediaId", value[0]?.id || null)}
                    disabled={!rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]}
                    noBorder
                    required
                  />
                </div>
              </div>
            </div>

            {/* headerShow && headerTitles */}
            <div className="card card-body bg-light mb-4">
              <h4 className="mb-3">Header</h4>

              {/* checkbox */}
              <div className="col-sm-12 col-md-12 pb-4 pl-0">
                <div className="form-check">
                  <input
                    disabled={
                      !rolePermissions[PERMISSIONS.PERM_CONFIG_POPIN_UPDATE]
                    }
                    checked={values.headerShow}
                    className="form-check-input"
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxClick("headerShow", !values.headerShow)
                    }
                    id="quotas_input_showIfWithAccommodations"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="quotas_input_showIfWithAccommodations"
                  >
                    Afficher le header ?
                  </label>
                </div>
              </div>

              <h5>Titre</h5>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <TextInput
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          label={`${locale.code}`}
                          id={`headerTitles.${locale.id}`}
                          name={`headerTitles.${locale.id}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.headerTitles[locale.id]}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <h5 className="mt-4">Sous Titre</h5>
              <div className="form-row">
                {locales
                  ? localesArray.map(locale => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={`locale-modal-title-${locale.id}`}
                      >
                        <label
                          htmlFor={`config-frontAndMiddleJsSnippets-${locale.id}`}
                        >
                          {`${locale.code} (limite de 150 caractères)`}
                        </label>
                        <textarea
                          disabled={
                            !rolePermissions[
                              PERMISSIONS.PERM_CONFIG_POPIN_UPDATE
                            ]
                          }
                          className="form-control"
                          id={`headerSubtitles.${locale.id}`}
                          name={`headerSubtitles.${locale.id}`}
                          rows="3"
                          value={values.headerSubtitles[locale.id]}
                          onChange={e =>
                            setFieldValue(
                              `headerSubtitles.${locale.id}`,
                              e.target.value.length > 150
                                ? values.headerSubtitles[locale.id]
                                : e.target.value
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

ConfigHomePopin.propTypes = {
  values: PropTypes.shape({ frontAndMiddleJsSnippets: PropTypes.shape() })
    .isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  medias: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
export default ConfigHomePopin
