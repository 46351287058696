import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useTagsApi from "../../../hooks/Api/useTagsApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

countries.registerLocale(countriesFR)

const permissions = [PERMISSIONS.PERM_TAGS_UPDATE, PERMISSIONS.PERM_TAGS_CREATE]

const TagsEdit = ({ match }) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)

  const history = useHistory()
  const [, tagData, tagError, { getTagWithId }] = useTagsApi()
  const [
    updateTagState,
    updateTagData,
    updateError,
    { updateTag },
  ] = useTagsApi()
  const [newTagState, newTagData, newTagError, { createTag }] = useTagsApi()

  const savedGetTagWithId = useCallback(getTagWithId, [])

  const isEditingTag = !isEmpty(match.params)

  const initValues = () => {
    const init = { name: {} }

    if (locales && Object.keys(locales)?.length) {
      Object.keys(locales).forEach(locale => {
        init.name[locale] = '';
      })
    }
    return init
  }

  useEffect(() => {
    if (isEditingTag) savedGetTagWithId(match.params.id)
  }, [isEditingTag, savedGetTagWithId, match.params.id])

  const handleSubmit = values => {
    if (isEditingTag) {
      updateTag(values).then(() =>
        history.push({
          pathname: "/tags",
          state: { submitType: "edit", success: true },
        })
      )
    } else {
      createTag(values).then(() =>
        history.push({
          pathname: "/tags",
          state: { submitType: "create", success: true },
        })
      )
    }
  }

  const backToList = () => {
    history.push({
      pathname: "/tags",
      state: {},
    })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_TAGS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_TAGS_CREATE] && !isEditing)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Tags / ${isEditingTag ? "Éditer" : "Nouveau"}`}
          {tagData && tagData.name ? ` / ${tagData.name[1]}` : null}
        </h1>
      </div>

      <Formik
        enableReinitialize
        initialValues={isEditingTag && tagData ? tagData : initValues()}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form className="mw-100 w-50 m-auto">
            {(!isEmpty(updateTagData) && updateTagState === "completed") ||
            (!isEmpty(newTagData) && newTagState === "completed") ? (
              <div className="alert alert-success" role="alert">
                Sauvegardé avec succès
              </div>
            ) : null}
            {tagError || updateError || newTagError ? (
              <div className="alert alert-error" role="alert">
                {tagError || updateError || newTagError}
              </div>
            ) : null}
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Identité</h5>

                <div className="form-row">
                  {locales
                    ? Object.keys(locales).map(locale => (
                        <div className="col-sm-12 col-md-6 pb-2" key={locale}>
                          <label htmlFor="tag-firstname">
                            {`Name ${locales[locale].code}`}
                          </label>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            className="form-control"
                            id={`name.${locale}`}
                            name={`name.${locale}`}
                            value={values?.name?.[locale] || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>

            <div className="float-right mb-4">
              {!isAllowedToEdit ? (
                <button
                  onClick={backToList}
                  type="button"
                  className="btn btn-secondary mr-2"
                >
                  Revenir à la liste
                </button>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  updateTagState === "loading" ||
                  newTagState === "loading" ||
                  !isAllowedToEdit
                }
              >
                {updateTagState === "loading" || newTagState === "loading" ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Valider
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </WithMenu>
  )
}

TagsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default TagsEdit
