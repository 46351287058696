// Hooks
import useApi from "./useApi"

// Utils & misc
import { COACH_HOSTNAME } from "../../constants/constants"

const useCoachesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getCoaches = () => request("GET", `${COACH_HOSTNAME}/v1/coaches`);


  return [
    requestState,
    payload,
    error,
    {
      getCoaches,
    },
  ]
}

export default useCoachesApi
