import React, { useState } from "react"
import Tooltip from "react-tooltip-lite"
import PropTypes from "prop-types"

// Utils
import copyTextToClipboard from "../../../utils/copyTextToClipboard"
import cn from "../../../utils/cn"

// Style
import style from "./Tooltip.module.css"

export const useDropdown = () => {
  const [isToggled, setIsToggled] = useState(false)
  const toggle = () => setIsToggled(!isToggled)
  return [isToggled, toggle]
}

const GlobalTooltip = ({ content, text, copyContent, children }) => {
  const [idCopied, setIdCopied] = useState(false)

  const handleIdClick = id => {
    copyTextToClipboard(id)
    if (id) {
      setIdCopied(true)
      setTimeout(() => {
        setIdCopied(false)
      }, 3000)
    }
  }

  return (
    <span
      onKeyDown={null}
      role="button"
      tabIndex={[0]}
      onClick={copyContent ? () => handleIdClick(content || "") : null}
      className={cn([style.col, copyContent ? style.clickable : ""])}
    >
      <Tooltip
        direction="up-start with arrow"
        tipContentClassName={style.tooltipContent}
        content={idCopied ? "copié" : content || ""}
      >
        {children || text}
      </Tooltip>
    </span>
  )
}

GlobalTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  text: PropTypes.string,
  copyContent: PropTypes.bool,
}
GlobalTooltip.defaultProps = {
  copyContent: true,
}

export default GlobalTooltip
