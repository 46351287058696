import React, { useEffect, useCallback, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"
import { sortBy } from "lodash"

// Components
import Table from "../../../components/organisms/Table"

// Hooks
import useEventsApi from "../../../hooks/Api/useEventsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./EventsList.module.css"
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

const permissions = [
  PERMISSIONS.PERM_EVENTS_CREATE,
  PERMISSIONS.PERM_EVENTS_UPDATE,
]

const EventsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [requestState, payload, error, { getEvents }] = useEventsApi({
    trigger: true,
  })
  const savedGetEvents = useCallback(getEvents, [])
  const { state } = useLocation()
  const [, , , { reorderEvent }] = useEventsApi()

  useEffect(() => {
    savedGetEvents()
  }, [savedGetEvents])

  const eventsList = useMemo(
    () => sortBy(payload?.events || [], ["ordinal"]).reverse(),
    [payload]
  )

  const onDragEnd = async result => {
    if (!result.destination) {
      return
    }

    let destination
    if (result.source.index > result.destination.index) {
      if (result.destination.index - 1 < 0) {
        destination = 0
      } else {
        destination = eventsList[result.destination.index - 1].id
      }
    } else {
      destination = eventsList[result.destination.index].id
    }

    await reorderEvent(eventsList[result.source.index].id, destination)
    savedGetEvents()
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Events</h1>
        {rolePermissions[PERMISSIONS.PERM_EVENTS_CREATE] ? (
          <Link className="btn btn-success" to="/events/new">
            Nouvel event
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {requestState === "loading" && !payload ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Table
            columns={[
              {
                Header: "Id",
                accessor: "id",
              },
              {
                Header: "Label",
                accessor: "title",
                Cell: ({ cell: { value } }) => (
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    aria-label="Edit event"
                  >
                    {value[1]}
                  </div>
                ),
              },
              {
                Header: "",
                accessor: "#1",
                Cell: ({
                  cell: {
                    row: {
                      values: { id },
                    },
                  },
                }) => (
                  <div
                    className="btn-group btn-group-sm float-right"
                    role="group"
                    aria-label="Edit event"
                  >
                    <Link
                      className="btn btn-secondary"
                      to={`/events/edit/${id}`}
                    >
                      {rolePermissions[PERMISSIONS.PERM_EVENTS_UPDATE]
                        ? "Éditer"
                        : "Consulter"}
                    </Link>
                  </div>
                ),
              },
            ]}
            data={eventsList || []}
            hasPagination
            isDraggable={rolePermissions[PERMISSIONS.PERM_EVENTS_UPDATE]}
          />
        </DragDropContext>
      )}
    </WithMenu>
  )
}
export default EventsList
