// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import { COACH_HOSTNAME } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useExportsApi = () => {
  // const configPdf = {
  //   responseType: "blob",
  //   headers: {
  //     Accept: "application/pdf",
  //   },
  // }
  const configZip = {
    responseType: "blob",
    headers: {
      Accept: "application/zip",
    },
  }
  const config = {
    responseType: "blob",
    headers: {
      Accept: "xlsx",
    },
  }

  const [requestState, payload, error, request] = useApi()

  const getPdfInvoicesExport = (typeId, year) => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/pdfinvoices/${typeId}/${year}`,
      null,
      {
        axiosOptions: configZip,
      }
    )
  }

  const getCsvInvoicesExport = (typeId, year) => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/csvinvoices/${typeId}/${year}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getCsvPaymentsExport = typeId => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/csvpayments/${typeId}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getCsvBookingsExport = (year, options) => {
    const params = options ? objectToURLParam(options) : ""

    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/csvbookings/${year}${params}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getCsvCartsExport = (year, options) => {
    const params = options ? objectToURLParam(options) : ""

    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/csvcarts/${year}${params}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getOrdersRecapExport = year => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/ordersrecap/${year}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getPaymentMeansExport = year => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/payments-means/${year}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getSalesSheetExport = year => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/sales-sheet/${year}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getYieldIncome = (year) => {
    return request(
      "GET",
      `${API_STAGES_URL}/v1/export/yield-income/${year}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getReportsEvaluations = (year) => {
    return request(
      "GET",
      `${COACH_HOSTNAME}/v1/reports/${year}/evaluations`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  return [
    requestState,
    payload,
    error,
    {
      getPdfInvoicesExport,
      getCsvInvoicesExport,
      getCsvPaymentsExport,
      getCsvBookingsExport,
      getOrdersRecapExport,
      getCsvCartsExport,
      getPaymentMeansExport,
      getSalesSheetExport,
      getYieldIncome,
      getReportsEvaluations,
    },
  ]
}

export default useExportsApi
