// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useInvoiceApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getInvoiceById = invoiceId => {
    const config = {
      responseType: "blob",
    }

    return request("GET", `${API_STAGES_URL}/v1/invoice/${invoiceId}`, null, {
      axiosOptions: config,
    })
  }

  const getInvoiceLanguage = (invoiceId, localeId) => {
    const config = {
      responseType: "blob",
    }

    return request(
      "GET",
      `${API_STAGES_URL}/v1/invoices/${invoiceId}/${localeId}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  return [
    requestState,
    payload,
    error,
    {
      getInvoiceById,
      getInvoiceLanguage,
    },
  ]
}

export default useInvoiceApi
