import { useContext } from "react"

// Utils & misc
import { AppContext } from "../AppContext"

const useAppContext = () => {
  const [state, setState] = useContext(AppContext)

  const setAuth = authTokens => {
    setState({ ...state, auth: authTokens })
  }

  const clearAuth = () => setState({})

  const setMessage = message => {
    const newMessages = [].concat(message, state.messages || [])
    setState({ ...state, messages: newMessages })
  }

  const clearMessage = messageIndex => {
    const newMessages = state.messages.filter(
      (messages, index) => index !== messageIndex
    )
    setState({ ...state, messages: newMessages })
  }

  const setLocales = locales => {
    setState(prevState => ({
      ...prevState,
      locales,
    }))
  }

  const setMyInfo = myInfo => {
    setState({ ...state, myInfo })
  }

  const setUserInfo = userInfo => {
    setState(prevState => ({
      ...prevState,
      userInfo,
    }))
  }

  const setFidEmployee = employee => {
    setState({ ...state, fidEmployee: employee })
  }

  const setFidActiveMember = member => {
    setState({ ...state, fidActiveMember: member })
  }

  const setFidGlobalMessage = message => {
    setState({ ...state, fidGlobalMessage: message })
  }

  const setBatchState = s => {
    setState({ ...state, ...s })
  }

  const setSearchedClients = clients => {
    setState({ ...state, searchedClients: clients })
  }

  const setCurrentCoachWeek = (week) => {
    setState({ ...state, currentCoachWeek: week })
  }

  return {
    context: state,
    setAuth,
    clearAuth,
    setMessage,
    clearMessage,
    setSearchedClients,
    setLocales,
    setMyInfo,
    setUserInfo,
    setFidEmployee,
    setFidActiveMember,
    setFidGlobalMessage,
    setBatchState,
    setCurrentCoachWeek,
  }
}

export default useAppContext
