import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { format, parse } from "date-fns"
import { isEmpty } from "lodash"

// Components
import StudentsForms from "./StudentsForms"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

const permissions = [
  PERMISSIONS.PERM_STUDENTS_UPDATE,
  PERMISSIONS.PERM_CLIENTS_CREATE,
]

const StudentsEdit = ({ selectedPlayer, onBackToList }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, , , { getStudentsWithId }] = useSportStudiesApi()
  const [, , , { createStudent }] = useSportStudiesApi()
  const [data, setData] = useState({
    guardians: {
      1: { localeId: 1, ordinal: 2 },
      2: { localeId: 1, ordinal: 2 },
    },
  })
  const [formSave, setFormSave] = useState(false)
  const [formError, setFormError] = useState(null)
  const [subview, setSubview] = useState("general")

  const savedGetStudentsWithId = useCallback(getStudentsWithId, [])

  useEffect(() => {
    if (!isEmpty(selectedPlayer)) {
      savedGetStudentsWithId(selectedPlayer.id)
        .then(response => {
          const newData = response.data
          if (newData.guardians[2] === undefined) {
            newData.guardians[2] = {
              localeId: data.guardians[2].localeId,
              ordinal: 2,
            }
          }
          setData(newData)
        })
        .catch(errors => {
          setFormSave(false)
          if (errors && errors.response)
            setFormError(errors.response.data.errors[0])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedGetStudentsWithId, selectedPlayer])

  const handleSubmit = e => {
    e.preventDefault()
    setFormSave(true)
    setFormError(null)

    const body = JSON.parse(JSON.stringify(data))
    body.birthdate = body.birthdate ? body.birthdate.date : ""
    if (!body.guardians[2].email) {
      delete body.guardians[2]
    }

    createStudent(selectedPlayer.id, body)
      .then(() => {
        onBackToList()
      })
      .catch(errors => {
        setFormSave(false)
        setFormError(errors.response.data.errors[0])
      })
  }

  const handleChangeEmail2 = e => {
    setData({
      ...data,
      guardians: {
        ...data.guardians,
        2: {
          ...data.guardians[2],
          email: e.target.value,
        },
      },
    })
  }

  const handleChangeLocale = e => {
    const ordinal = e.target.name === "locale1Id" ? 1 : 2
    setData({
      ...data,
      guardians: {
        ...data.guardians,
        [ordinal]: {
          ...data.guardians[ordinal],
          localeId: Number(e.target.value),
        },
      },
    })
  }

  const handleSwitchToForms = () => setSubview("forms")

  const handleSwitchToPlayer = () => setSubview("general")

  const canUserEdit = rolePermissions[PERMISSIONS.PERM_STUDENTS_UPDATE]

  return (
    <div className="container-fluid mt-3 mb-4">
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={onBackToList}
        style={{ marginRight: "16px" }}
      >
        Retour à la liste
      </button>
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={handleSwitchToPlayer}
        style={{ marginRight: "16px" }}
      >
        Joueur
      </button>
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={handleSwitchToForms}
        disabled={!data.registered}
      >
        Dossiers
      </button>
      <h1>
        {selectedPlayer.firstname}
        &nbsp;
        {selectedPlayer.lastname} -{" "}
        {format(
          parse(
            selectedPlayer.birthdate.date,
            "yyyy-MM-dd HH:mm:ss.SSSSSS",
            new Date()
          ),
          "dd/MM/yyyy"
        )}
        &nbsp;
        {rolePermissions[PERMISSIONS.PERM_CLIENTS_CREATE] ? (
          <a
            className="btn btn-primary btn-sm"
            href="/admin/client/create"
            target="_blank"
          >
            Nouveau client
          </a>
        ) : null}
      </h1>
      {subview === "forms" ? (
        <StudentsForms selectedPlayer={selectedPlayer} />
      ) : (
        <form onSubmit={handleSubmit}>
          {formError ? (
            <div className="alert alert-danger">{formError}</div>
          ) : null}

          <fieldset id="guardian1" className="form-group">
            <h2>Parent/Tuteur 1</h2>
            <div className="form-group">
              <label htmlFor="clientFirstname">
                <div className="form-group">Prénom</div>
                <input
                  id="clientFirstname"
                  type="text"
                  name="clientFirstname"
                  defaultValue={data.guardians[1].firstname}
                  disabled
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="clientLastname">
                <div className="form-group">Nom</div>
                <input
                  id="clientLastname"
                  type="text"
                  name="clientLastname"
                  defaultValue={data.guardians[1].lastname}
                  disabled
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="clientEmail">
                <div className="form-group">Email</div>
                <input
                  id="clientEmail"
                  type="email"
                  name="clientEmail"
                  defaultValue={data.guardians[1].email}
                  disabled
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="locale1Id">
                <div className="form-group">Langue des emails</div>
                <select
                  disabled={!canUserEdit}
                  id="locale1Id"
                  name="locale1Id"
                  value={data.guardians[1].localeId}
                  onChange={handleChangeLocale}
                  required
                >
                  <option value={1}>Français</option>
                  <option value={2}>Anglais</option>
                </select>
              </label>
            </div>
          </fieldset>

          <fieldset id="guardian2">
            <h2>Parent/Tuteur 2</h2>
            <div className="form-group">
              <label htmlFor="guardian2Email">
                <div className="form-group">Email</div>
                <input
                  disabled={!canUserEdit}
                  id="guardian2Email"
                  type="email"
                  name="guardian2Email"
                  value={data.guardians[2].email}
                  onChange={handleChangeEmail2}
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="locale2Id">
                <div className="form-group">Langue des emails</div>
                <select
                  disabled={!canUserEdit}
                  id="locale2Id"
                  name="locale2Id"
                  value={data.guardians[2].localeId}
                  onChange={handleChangeLocale}
                  required
                >
                  <option value={1}>Français</option>
                  <option value={2}>Anglais</option>
                </select>
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-success btn-sm"
              disabled={formSave || !canUserEdit}
            >
              Sauvegarder
            </button>
          </fieldset>
        </form>
      )}
    </div>
  )
}

StudentsEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedPlayer: PropTypes.object.isRequired,
  onBackToList: PropTypes.func.isRequired,
}

export default StudentsEdit
