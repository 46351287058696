import $ from "jquery"
import React from "react"

function Tab(props) {
  function handleClick() {
    props.changeSelectedYearId(props.year.id)
  }

  return (
    <a
      onClick={handleClick}
      className={`nav-link${props.active ? " active" : ""}`}
      href="#"
    >
      {props.year.number}
    </a>
  )
}

function Week(props) {
  return (
    <tr>
      <td className="align-middle td-fixed-1">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-1">S{props.week.number}</div>
        </div>
      </td>
      <td className="align-middle td-fixed-2">{props.week.start}</td>
      <td className="align-middle td-fixed-3">{props.week.end}</td>
      <td className="align-middle td-fixed-4">{props.week.season}</td>
      {props.orderedPackagesIds.map(packageId => (
        <td key={packageId} className="text-nowrap text-right">
          {props.prices[packageId][props.week.id]}
        </td>
      ))}
    </tr>
  )
}

class Packages extends React.Component {
  state = {
    selectedYearId: this.props.orderedYearsIds[0],
  }

  componentDidMount() {
    const $table = $(".table-responsive")
    $table.height($(window).height() - ($("html").height() - $table.height()))

    // to fix X colums in the Table, to avoid them to collapse on top one another we have to
    // set the left position by adding the width of the previous columns
    // TODO: re-compute left positioning at window resize
    const fixedColumsNumber = 4
    let leftOffset = 0
    Array.from(
      { length: fixedColumsNumber },
      (v, index) =>
        document.getElementsByClassName(`td-fixed-${index + 1}`)[0].clientWidth
    ).forEach((width, index) => {
      ;[...document.getElementsByClassName(`td-fixed-${index + 1}`)].forEach(
        td => {
          td.style.left = `${leftOffset}px`
        }
      )
      leftOffset += width
    })
  }

  changeSelectedYearId = yearId => {
    this.setState({
      selectedYearId: yearId?.toString(),
    })
  }

  render = () => (
    <React.StrictMode>
      <ul className="nav nav-tabs">
        {this.props.orderedYearsIds.map(yearId => (
          <Tab
            key={yearId}
            year={this.props.years[yearId]}
            active={this.state.selectedYearId === yearId}
            changeSelectedYearId={this.changeSelectedYearId}
          />
        ))}
      </ul>
      <div className="table-responsive">
        <table className="table table-sm table-bordered table-hover thead-fixed">
          <thead>
            <tr>
              <th className="align-middle">Semaine</th>
              <th className="align-middle">Du</th>
              <th className="align-middle">Au</th>
              <th className="align-middle">Saison</th>
              {this.props.orderedPackagesIds.map(packageId => (
                <th key={packageId} className="align-middle">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">
                      {this.props.packages[packageId].name[1]}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.orderedWeeksIds[this.state.selectedYearId].map(
              weekId => (
                <Week
                  key={weekId}
                  week={this.props.weeks[weekId]}
                  orderedPackagesIds={this.props.orderedPackagesIds}
                  prices={this.props.prices}
                />
              )
            )}
          </tbody>
        </table>
      </div>
    </React.StrictMode>
  )
}

export default Packages
