import React, { useState, useEffect, useCallback } from "react"

// Components
import { Link, useLocation } from "react-router-dom"
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import Spinner from "../../../components/atoms/Spinner/Spinner"
import GlobalMessage from "../../../components/atoms/GlobalMessage/GlobalMessage"
import Modal from "../../../components/molecules/Modal"

// Hooks
import useOptinsApi from "../../../hooks/Api/Authorization/useOptinsApi"

// Utils
import { exportCollectionToArray } from "../../../utils/collection"

import styles from "./Optins.module.css"

const Optins = () => {
  const { state } = useLocation()

  const [, , , { getOptins }] = useOptinsApi()
  const [, , , { updateOptins }] = useOptinsApi()
  const savedGetOptins = useCallback(getOptins, [])

  const [optins, setOptins] = useState([])

  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const fetchOptins = useCallback(async () => {
    try {
      const resp = await savedGetOptins()
      setOptins(exportCollectionToArray(resp.data, "optinsSections"))
    } catch (error) {
      console.error(error)
    }
  }, [savedGetOptins])

  useEffect(() => {
    fetchOptins()
  }, [fetchOptins])

  useEffect(() => {
    if (state !== undefined && state.success) {
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: <span>{state.message}</span>,
        className: "alert alert-success",
      })
    }
  }, [state])

  // DELETION
  const [selectedOptinDeletion, setSelectedOptinDeletion] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const handleOptinDelete = smsKey => {
    setSelectedOptinDeletion(smsKey)
    setDeleteModalOpen(true)
  }
  const deleteOptin = async () => {
    try {
      const payload = {
        optinsSections: optins.filter(
          optin => optin.sugarCrmSmsKey !== selectedOptinDeletion
        ),
      }
      await updateOptins(payload)
      await fetchOptins()
      setDeleteModalOpen(false)
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>{`L'optin ${selectedOptinDeletion} a été supprimé`}</span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <WithMenu>
      <div className={styles.pageHeader}>
        <span className="d-inline-flex">
          <h1 className="mb-0">Optins</h1>
        </span>
        <Link className="btn btn-success" to="/optins/new">
          Nouvel optin
        </Link>
      </div>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />
      {optins && optins.length ? (
        <>
          <Table
            columns={[
              {
                Header: "SMS Key",
                accessor: "sugarCrmSmsKey",
              },
              {
                Header: "Email Key",
                accessor: "sugarCrmEmailKey",
              },
              {
                Header: "Nom",
                accessor: "tag0",
                Cell: ({
                  cell: {
                    row: {
                      original: { intl },
                    },
                  },
                }) => <div>{intl?.fr.name}</div>,
              },
              {
                Header: "Description",
                accessor: "tag1",
                Cell: ({
                  cell: {
                    row: {
                      original: { intl },
                    },
                  },
                }) => <div>{intl?.fr.description}</div>,
              },
              {
                Header: "",
                accessor: "tag2",
                Cell: ({
                  cell: {
                    row: {
                      original: { sugarCrmSmsKey },
                    },
                  },
                }) => (
                  <>
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit admin"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/optins/edit/${sugarCrmSmsKey}`}
                      >
                        Éditer
                      </Link>
                    </div>
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      onClick={() => handleOptinDelete(sugarCrmSmsKey)}
                    >
                      Supprimer
                    </button>
                  </>
                ),
              },
            ]}
            data={optins}
            hasPagination
            hasSort
          />
        </>
      ) : (
        <Spinner />
      )}
      <Modal
        isDisplayed={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <div className="row mt-4">
          <div className="col-md-12 justify-content-md-center">
            <h5>{`Supprimer l'optin ${selectedOptinDeletion}`}</h5>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12" />
        </div>
        <div className="row mt-4">
          <div className="col-md-12 justify-content-md-center">
            <button
              type="button"
              tabIndex={[0]}
              onKeyDown={null}
              onClick={deleteOptin}
              className="btn btn-primary"
            >
              Confirmer la suppression
            </button>
          </div>
        </div>
      </Modal>
    </WithMenu>
  )
}

export default Optins
