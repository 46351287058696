import React, { useEffect, useCallback, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useFileUploadApi from "../../../hooks/Api/useFileUploadApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./MediasList.module.css"
import useImage from "../../../hooks/useImage"
import { isNil, min, orderBy, sortBy } from "lodash"
import { SelectInput, TextInput } from "../../../components/atoms/Inputs/Inputs"
import cn from "../../../utils/cn"
import MediasEditModal from "./MediasEditModal/MediasEditModal"
import Picto from "../../../components/atoms/Picto/Picto"

const permissions = [
  PERMISSIONS.PERM_MEDIAS_DISPLAY,
  PERMISSIONS.PERM_MEDIAS_UPDATE,
  PERMISSIONS.PERM_MEDIAS_CREATE,
]

const MediasList = () => {
  const { context: { userInfo } } = useAppContext()

  const orderByOptions = [
    { value: 'desc', label: 'De la plus récente à la plus ancienne' },
    { value: 'asc', label: 'De la plus ancienne à la plus récente' }
  ]

  const countStep = 48
  const [countDisplay, setCountDisplay] = useState(countStep)
  const [filterByText, setFilterByText] = useState('')
  const [orderByDate, setOrderByDate] = useState(orderByOptions.find((opt) => opt.value === 'asc'))
  const [selectedMedia, setSelectedMedia] = useState(null)

  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [newUploadSuccess, setNewUploadSuccess] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [, fileUploadRequest] = useFileUploadApi()
  const [mediasRequestState, mediasData, mediasError, { getMedias }] = useMediasApi()

  const { getImageSrc } = useImage()

  const savedGetMedias = useCallback(getMedias, [])
  const { state } = useLocation()

  const exportCollectionToArray = (initialPayload, collection) => {
    if (initialPayload && initialPayload[collection])
      return Object.keys(initialPayload[collection]).map(
        i => initialPayload[collection][i]
      )
    return []
  }

  const mediasToArray = useMemo(() => exportCollectionToArray(mediasData, "medias") || [], [mediasData])

  const medias = useMemo(() => {
    let items = orderBy(mediasToArray, 'id', orderByDate?.value || 'asc');

    if (filterByText && filterByText !== '') {
      const terms = filterByText.toLowerCase().replace('é', 'e').replace('è', 'e').split(' ')
      items = items.filter((item) => terms.every((term) => item.fileKey?.toLowerCase()?.includes(term)))
    }

    return items.slice(0, min([items?.length, countDisplay]))
  }, [mediasToArray, countDisplay, filterByText, orderByDate])

  useEffect(() => {
    savedGetMedias()
  }, [savedGetMedias])

  const handleAddNewMedia = (e) => {
    const file = e?.target?.files?.[0]

    if (file && file?.name) {
      setUploadError(false)
      setNewUploadSuccess(false)
      setUploadLoading(true)

      const sanitizedFileName = file.name.replace(/[^a-z0-9.]/gi, "_").toLowerCase()

      fileUploadRequest(sanitizedFileName, file)
        .then((res) => {
          if (res) {
            setTimeout(() => {
              setNewUploadSuccess(true)
              savedGetMedias()
              setUploadLoading(false)
            }, 1000);
          } else {
            setUploadError(true)
            setUploadLoading(false)
          }
        })
        .catch(e => {
          console.error(e)
          setUploadError(true)
          setUploadLoading(false)
        })
    }
  }

  const handleScroll = () => {
    const scrollAmount = window.innerHeight + document.documentElement.scrollTop
    const bottom = document.documentElement.offsetHeight - 10
    if (scrollAmount >= bottom) setCountDisplay((prev) => prev + countStep)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOpenMedia = (media) => {
    if (rolePermissions[PERMISSIONS.PERM_MEDIAS_UPDATE]) {
      setSelectedMedia(media)
    }
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Médias</h1>
        {rolePermissions[PERMISSIONS.PERM_MEDIAS_CREATE] ? (
            <button type="button" className="btn btn-success" disabled={uploadLoading}>
              <label className={style.input_file}>
                {uploadLoading ? (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Ajouter un média
                <input type="file" accept="image/*" onChange={handleAddNewMedia} disabled={uploadLoading} />
              </label>
            </button>
        ) : null}
      </div>

      {(state && state.success) || uploadSuccess ? (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      ) : null}
      {newUploadSuccess ? (
        <div className="alert alert-success" role="alert">
          Le nouveau média a bien été ajouté
        </div>
      ) : null}
      {mediasError ? (
        <div className="alert alert-danger" role="alert">
          {mediasError}
        </div>
      ) : null}
      {uploadError ? (
        <div className="alert alert-danger" role="alert">
          Une erreur s'est produite lors de l'ajout du média. Merci de réessayer ultérieurement.
        </div>
      ) : null}

      {mediasRequestState !== "completed" || !mediasData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_MEDIAS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_MEDIAS_UPDATE] ? (
            <>
              <div className={style.filters}>
                <div className={style.filter}>
                  <TextInput
                    placeholder="Rechercher un média..."
                    id="search"
                    value={filterByText}
                    onChange={(e) => setFilterByText(e.target.value)}
                  />
                </div>

                <div className={style.filter}>
                  <SelectInput
                    value={orderByDate.value}
                    onChange={(evt) => {
                      setOrderByDate(orderByOptions.find((opt) => opt.value === evt.target.value))
                    }}
                    options={orderByOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  />
                </div>
              </div>
              <div className={style.medias_grid}>
                {medias?.length ? (
                  medias?.map((media, index) => (
                    <div key={index} className={style.media_item}>
                      <div
                        role="presentation"
                        onClick={() => handleOpenMedia(media)}
                        className={cn([
                          style.media_item_img,
                          rolePermissions[PERMISSIONS.PERM_MEDIAS_UPDATE] ? style.with_hover : ''
                        ])}
                      >
                        <img
                          alt={media.fileKey}
                          src={getImageSrc({ fileKey: media.fileKey, width: 330 })}
                        />

                        {rolePermissions[PERMISSIONS.PERM_MEDIAS_UPDATE] ? (
                          <p className={style.media_edit}>
                            <Picto icon="pen" />
                            Éditer le média
                          </p>
                        ) : null}
                      </div>
                      <p className={style.media_label}>
                        {media.fileKey.replace('medias/', '')}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="mt-2">Aucun résultat</p>
                )}
              </div>

              <div className={style.floating_cta}>
                <button
                  type="button"
                  className={style.back_to_top}
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                  <Picto icon="arrowTop" className="mr-2" />
                  Retour en haut de page
                </button>
              </div>

              <MediasEditModal
                listStyle={style}
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                setUploadSuccess={setUploadSuccess}
                handleRefreshList={savedGetMedias}
              />
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default MediasList
