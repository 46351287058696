import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

// Utils
import { cloneDeep, min, orderBy, sortBy } from 'lodash'
import cn from '../../../utils/cn'
import Modal from 'react-bootstrap4-modal'
import useImage from '../../../hooks/useImage'

// Components
import { TextInput } from '../Inputs/Inputs'

import styles from './MediasInput.module.css'
import Picto from '../Picto/Picto'

const MediasInput = ({ label, options, selection, isMultiple, minRequired, setSelection, disabled, required, noBorder }) => {
  const { getImageSrc } = useImage()
  const scrollContainerRef = useRef()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const countStep = 48
  const [countDisplay, setCountDisplay] = useState(countStep)
  const [filterByText, setFilterByText] = useState('')

  const [initialValue, setInitialValue] = useState(cloneDeep(selection)?.map((ids) => ({ id: ids })))

  const getActionLabel = () => (
    isMultiple ? 'Ajouter des médias' : (
      selection?.length ? 'Remplacer le média' : 'Ajouter un média' 
    )
  )

  const handleCloseAndResetModal = () => {
    setIsModalOpened(false)
    setCountDisplay(countStep)
    setFilterByText('')

    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }

  const handleCancel = () => {
    setSelection(initialValue)
    handleCloseAndResetModal()
  }

  const handleValidate = () => {
    setInitialValue(cloneDeep(selection)?.map((ids) => ({ id: ids })))
    handleCloseAndResetModal()
  }

  const handleResetSelection = () => {
    setSelection([])
  }

  const isInitialSelectedMedia = (mediaId) => initialValue.some((val) => val.id === mediaId)

  const mediasItems = useMemo(() => {
    // order from oldest to most recent
    let items = orderBy(options, 'id', 'asc')

    // filter by text search
    if (filterByText && filterByText !== '') {
      const terms = filterByText.toLowerCase().replace('é', 'e').replace('è', 'e').split(' ')
      items = items.filter((item) => terms.every((term) => item.fileKey?.toLowerCase()?.includes(term)))
    }

    // put initial selection on top of the grid list
    if (initialValue?.length) {
      items = items.sort((a, b) => {
        if (!isInitialSelectedMedia(a.id) && isInitialSelectedMedia(b.id)) return 1;
        if (isInitialSelectedMedia(a.id) && !isInitialSelectedMedia(b.id)) return -1;
        return 0;
      })
    } 

    // apply infinite scroll limit
    return items.slice(0, min([items?.length, countDisplay]));
  }, [options, filterByText, countDisplay, initialValue])

  const handleGridScroll = (e) => {
    const div = e.target;

    // infinite scroll
    const scrollAmount = div.scrollTop
    const bottom = div.scrollHeight - div.offsetHeight - 2
    if (scrollAmount >= bottom) setCountDisplay((prev) => prev + countStep)
  }

  const handleSelectMedia = (media) => {
    if (isMultiple) {
      const currentSelection = cloneDeep(selection || [])
  
      const newSelection = selection.some((id) => id === media.id)
        ? currentSelection.filter((id) => id !== media.id)
        : [...currentSelection, media.id]
      
      setSelection(newSelection.map((ids) => ({ id: ids })))
    } else {
      setSelection(
        media?.id === selection?.[0] ? [] : [{ id: media.id }]
      )
    }
  }

  const handleRemoveMedia = (mediaId) => {
    const currentSelection = cloneDeep(selection || [])
    const newSelection = isMultiple
      ? currentSelection.filter((id) => id !== mediaId).map((ids) => ({ id: ids }))
      : []

    setSelection(newSelection)
    setInitialValue(newSelection)
  }

  return (
    <div className={cn([styles.medias_input, noBorder ? styles.no_border : ''])}>
      {label ? (
        <p className={styles.label}>{label}</p>
      ) : null}

      {minRequired ? (
        <p className={styles.min_required}>Veuillez sélectionner {minRequired} médias minimum.</p>
      ) : null}

      <div className={cn([styles.medias_row, !disabled ? 'mb-3' : ''])}>
        {selection?.map((id, index) => (
          <div key={index} className={styles.item}>
            <img
              src={getImageSrc({
                fileKey: options.find((media) => media.id === id)?.fileKey,
                width: 200
              })}
              draggable={false}
            />

            {!disabled ? (
              <button
                type="button"
                className={styles.remove_btn}
                onClick={() => handleRemoveMedia(id)}
              >
                <Picto icon="cross" />
              </button>
            ) : null}
          </div>
        ))}
      </div>

      {!disabled ? (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setIsModalOpened(true)}
        >
          {getActionLabel()}
        </button>
      ) : null}

      {required ? (
        <input className={styles.required} type="text" required value={selection?.join('-') || ''} onChange={() => {}} />
      ) : null}

      <Modal visible={isModalOpened && !disabled} dialogClassName={styles.modal}>
        <div className="modal-header">
          <div className="text-xl modal-header-title">
            <b>
              {getActionLabel()}
            </b>
          </div>
        </div>

        <div ref={scrollContainerRef} className={styles.modal_body} onScroll={handleGridScroll}>
          <div className={styles.search}>
            <div className={styles.search_input}>
              <TextInput
                placeholder="Rechercher un média..."
                id="search_medias"
                value={filterByText}
                onChange={(e) => setFilterByText(e.target.value)}
              />
            </div>

            {isMultiple ? (
              <p>
                <strong>
                  {`${selection?.length || 0} ${selection?.length > 1 ? 'éléments sélectionnés' : 'élément sélectionné'}`}
                </strong>
              </p>
            ) : null}
          </div>

          {mediasItems?.length ? (
            <div className={styles.medias_grid}>
              {mediasItems.map((media, index) => (
                <div
                  role="presentation"
                  key={index} className={styles.media_item}
                  onClick={() => handleSelectMedia(media)}
                >
                  <div
                    className={cn([
                      styles.media_item_img,
                      selection?.some((id) => media.id === id) ? styles.selected : ''
                    ])}
                  >
                    <img draggable={false} src={getImageSrc({ fileKey: media.fileKey, width: 300 })} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>Aucun résultat</p>
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className={styles.reset_btn}
            onClick={handleResetSelection}
          >
            {`Réinitialiser la sélection ${isMultiple ? `(${selection?.length || 0})` : ''}`}
          </button>

          <button
            type="button"
            className="btn btn-default btn-md"
            onClick={handleCancel}
          >
            Annuler
          </button>
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={handleValidate}
          >
            Confirmer
          </button>
        </div>
      </Modal>
    </div>
  )
}

MediasInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selection: PropTypes.arrayOf(PropTypes.number),
  isMultiple: PropTypes.bool,
  minRequired: PropTypes.number,
  setSelection: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

MediasInput.defaultProps = {
  label: null,
  options: [],
  selection: [],
  isMultiple: false,
  minRequired: 0,
  setSelection: () => {},
  disabled: false,
  required: false,
}

export default MediasInput