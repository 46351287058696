import React, { useMemo, useState, useEffect } from 'react';

// Services
import useBookingsForCoachApi from '../../../../hooks/Api/useBookingsForCoachApi';

// Utils
import { weeksList } from '../../../../utils/weeks';
import { startOfWeek } from 'date-fns';
import { saveAs } from "file-saver";
import useAppContext from '../../../../hooks/useAppContext';

// Components
import ExtrasBySections from './ExtrasBySections/ExtrasBySections';
import WithMenu from '../../../../components/templates/WithMenu/WithMenu';
import WeekSelector from '../../../../components/atoms/WeekSelector/WeekSelector';
import Spinner from '../../../../components/atoms/Spinner/Spinner';
import { SelectInput } from '../../../../components/atoms/Inputs/Inputs';

import styles from './SectionsExtrasGroups.module.css';

const extraTypes = {
  oneToOne: 'oneToOne',
  WEOneToOne: 'WEOneToOne'
}

const SectionsExtrasGroups = () => {
  const date = new Date();
  const weeksListForGroups = useMemo(() => weeksList({
    start: new Date(date.getFullYear(), date.getMonth() - 1, date.getDay()+1),
    end: new Date(date.getFullYear() + 7, date.getMonth(), date.getDay()+1)
  }) , []);

  const { setCurrentCoachWeek, context: { currentCoachWeek }} = useAppContext()

  const mondayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const [weekSelected, setWeekSelection] = useState(currentCoachWeek || mondayOfCurrentWeek);

  const extraTypeOptions = [
    { label: 'One to one', value: 'oneToOne'},
    { label: 'One to One Week-end', value: 'WEOneToOne'},
  ];
  const [selectedExtraType, setSelectedExtraType] = useState(extraTypeOptions[0])
  const isWeekendExtraType = useMemo(() => selectedExtraType?.value === extraTypes.WEOneToOne, [selectedExtraType])

  const [getBookingsState,,, { getBookingsForCoach }] = useBookingsForCoachApi();
  const [putScheduleIndivCoachingState,,, { putScheduleIndividualCoaching }] = useBookingsForCoachApi();
  const [putScheduleIndividualCoachingWeekendState,,, { putScheduleIndividualCoachingWeekend }] = useBookingsForCoachApi();
  const [getExportState,,, { getCoachExtrasExportXls }] = useBookingsForCoachApi();

  const [bookings, setBookings] = useState([]);
  const [hasError, setHasError] = useState(false);

  const handlePopulateBookings = (week) => {
    getBookingsForCoach(week).then((res) => {
      if (res?.data?.bookings) {
        setBookings(res.data.bookings);
      }
    });
  }

  const handleChangeWeekSelector = (week) => {
    if (week !== weekSelected) {
      setWeekSelection(week);
      setCurrentCoachWeek(week);
      handlePopulateBookings(week);
    }
  }

  useEffect(() => {
    handlePopulateBookings(weekSelected);
  }, []);

  const handleAfterSave = ({ bookingId, coachId, court, result }) => {
    if (result?.status === 204) {
      setBookings((prev) => prev.map((booking) => {
        if (booking.id === bookingId) {
          if (isWeekendExtraType) {
            return {
              ...booking,
              individualCoachingCoachWe: {
                id: coachId,
              },
              individualCoachingCourtWe: court,
            }
          }
          return {
            ...booking,
            individualCoachingCoach: {
              id: coachId,
            },
            individualCoachingCourt: court,
          }
        }
        return booking;
      }))
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setHasError(true);
    }
  }

  const handleSaveBooking = ({ bookingId, coachId, court }) => {
    setHasError(false);

    if (isWeekendExtraType) {
      // One to one Weekend
      putScheduleIndividualCoachingWeekend({ bookingId, coachId, court }).then((res) => {
        handleAfterSave({ bookingId, coachId, court, result: res })
      });
    } else {
      // One to one
      putScheduleIndividualCoaching({ bookingId, coachId, court }).then((res) => {
        handleAfterSave({ bookingId, coachId, court, result: res })
      });
    }
  };

  const handleExportExtras = () => {
    getCoachExtrasExportXls(weekSelected).then((res) => {
      if (res?.data) {
        const file = res.data;
        saveAs(file, 'onetoone_extras.xlsx');
      }
    });
  }

  return (
    <WithMenu>
      <div className={styles.wrapper}>
        <h1>Options - Groupes par section</h1>
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <WeekSelector
          className={styles.week_selector}
          weeksList={weeksListForGroups}
          value={weekSelected}
          onChange={handleChangeWeekSelector}
        />

        <button
          className="btn btn-primary"
          disabled={getExportState === 'loading'}
          onClick={handleExportExtras}
        >
          Exporter toutes les options
          {getExportState === 'loading' ? (
            <span
              className="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </button>
      </div>

      <div className='mt-4'>
        {getBookingsState === 'loading' ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.extra_type}>
              <SelectInput 
                id="extraType"
                value={selectedExtraType?.value}
                onChange={(val) => setSelectedExtraType(extraTypeOptions.find((opt) => opt.value === val.target.value))} 
                name="extraType" 
                options={[
                  extraTypeOptions.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))
                ]}
              />
            </div>

            {hasError ? (
              <div className="alert alert-danger mt-4" role="alert">
                Une erreur s'est produite lors de la sauvegarde des données. Merci de réessayer ultérieurement.
              </div>
            ) : null}            

            <ExtrasBySections
              bookings={bookings || []} 
              handleSaveBooking={handleSaveBooking}
              groupIsSaving={putScheduleIndivCoachingState === 'loading' || putScheduleIndividualCoachingWeekendState === 'loading'}
              isWeekendExtraType={isWeekendExtraType}
            />
          </>
        )}
      </div>
    </WithMenu>
  )
}

SectionsExtrasGroups.propTypes = {};

export default SectionsExtrasGroups;
