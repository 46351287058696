// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useBookingsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getBookings = weekId =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/bookings${weekId ? `?weekId=${weekId}` : ""}`
    )

  const getBookingsExport = weekId => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "xlsx",
      },
    }

    return request(
      "GET",
      `${API_STAGES_URL}/v1/bookings${weekId ? `?weekId=${weekId}` : ""}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getBookingsGuest = weekId =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/bookings/guest${weekId ? `?weekId=${weekId}` : ""}`
    )

  const getBookingsGuestExport = weekId => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "xlsx",
      },
    }

    return request(
      "GET",
      `${API_STAGES_URL}/v1/bookings/guest${weekId ? `?weekId=${weekId}` : ""}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  return [
    requestState,
    payload,
    error,
    {
      getBookings,
      getBookingsExport,
      getBookingsGuest,
      getBookingsGuestExport,
    },
  ]
}

export default useBookingsApi
