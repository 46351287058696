import $ from "jquery"
import React from "react"
import Select from 'react-select';

import { isEmpty } from "lodash"
import cn from '../../../utils/cn'

import style from './StocksLegacy.module.css'

function Tab(props) {
  function handleClick() {
    props.changeSelectedYearId(props.year.id)
  }

  return (
    <a
      onClick={handleClick}
      className={`nav-link${props.active ? " active" : ""}`}
      href="#"
    >
      {props.year.number}
    </a>
  )
}

class Quota extends React.Component {
  state = {
    checked: false,
  }

  handleClick = () => {
    this.setState(state => {
      this.props.toggleQuota(this.props.quota.id, !state.checked)

      return {
        checked: !state.checked,
      }
    })
  }

  render = () => (
    <th className="align-middle">
      <div className="d-flex justify-content-between align-items-center">
        <div className="mr-1">{this.props.quota.name}</div>
          <div
            onClick={this.props.authorizedToEdit ? this.handleClick : null}
            className="custom-control custom-switch"
          >
            <input
              type="checkbox"
              checked={this.state.checked}
              className="custom-control-input"
              readOnly
              disabled={!this.props.authorizedToEdit}
            />
            <label className="custom-control-label" />
          </div>
      </div>
    </th>
  )
}

class Week extends React.Component {
  state = {
    checked: false,
  }

  handleClick = () => {
    this.setState(state => {
      this.props.toggleWeek(this.props.week.id, !state.checked)

      return {
        checked: !state.checked,
      }
    })
  }

  isToggleWeekDisabled = () => {
    const { quotas, filteredQuotaIds, canEditQuotaAndStock } = this.props;

    if (filteredQuotaIds()?.length) {
      const editPermissions = filteredQuotaIds().map((quotaId) => (quotas?.[quotaId] && canEditQuotaAndStock(quotas[quotaId])));
      return editPermissions.every((perm) => !perm);
    }

    return false;
  };

  render = () => (
    <td className={cn(["align-middle", "td-fixed-1", style.weekHeaderCol])}>
      <div className="d-flex justify-content-between align-items-center">
        <div className={cn(["mr-1", style.weekHeaderColElement])}>S{this.props.week.number}</div>
          <button
            type="button"
            className={cn([style.toggle_week, 'custom-control custom-switch'])}
            onClick={this.props.authorizedToEdit && !this.isToggleWeekDisabled() ? this.handleClick : null}
            disabled={!this.props.authorizedToEdit || this.isToggleWeekDisabled()}
          >
            <input
              type="checkbox"
              checked={this.state.checked}
              className="custom-control-input"
              readOnly
              disabled={!this.props.authorizedToEdit || this.isToggleWeekDisabled()}
            />
            <label className="custom-control-label" />
          </button>
      </div>
    </td>
  )
}

function Stock(props) {
  function handleClick() {
    props.toggleStock(props.quotaId, props.weekId, props.stock.id)
  }

  function handleChange(event) {
    props.changeStockQuantity(
      props.quotaId,
      props.weekId,
      Number(event.target.value)
    )
  }

  return (
    <td>
      <div className="d-flex justify-content-between align-items-center">
        <div onClick={props.authorizedToEdit ? handleClick : null} className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={props.stock.active}
            className="custom-control-input"
            readOnly
            disabled={!props.authorizedToEdit}
          />
          <label className="custom-control-label" />
        </div>

        <div className="text-nowrap mr-1">{props.stock.sold} / </div>

        <input
          type="number"
          value={props.stock.quantity}
          onChange={handleChange}
          className="form-control"
          style={{ width: "80px" }}
          min="0"
          disabled={!props.authorizedToEdit}
        />
      </div>
    </td>
  )
}

class StocksLegacy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedYearId: this.props.orderedYearsIds[0],
      stocks: this.props.stocks,
      quotaStocks: this.props.quotaStocks,
      events: [],
      syncing: false,
      filterQuotasIds: [],
    }
  }

  componentDidMount() {
    const $table = $(".table-responsive")

    // FIXME: optimize the table height
    // $table.height($(window).height() - ($("html").height() - $table.height()))
    $table.height('60vh')
    
    // to fix X colums in the Table, to avoid them to collapse on top one another we have to
    // set the left position by adding the width of the previous columns
    // TODO: re-compute left positioning at window resize
    const fixedColumsNumber = 3
    let leftOffset = 0
      Array.from(
        { length: fixedColumsNumber },
        (v, index) => {
          if (document.getElementsByClassName(`td-fixed-${index + 1}`) && document.getElementsByClassName(`td-fixed-${index + 1}`)[0]) {
            return document.getElementsByClassName(`td-fixed-${index + 1}`)[0].clientWidth
          }
          return 1
        }
      ).forEach((width, index) => {
        ;[...document.getElementsByClassName(`td-fixed-${index + 1}`)].forEach(
          td => {
            td.style.left = `${leftOffset}px`
          }
        )
        leftOffset += width
      })
  }

  sendEvents = () => {
    this.setState(state => {
      this.props
        .updateStock(JSON.stringify({ events: state.events }))
        .then(() => {
          this.setState({
            events: [],
            syncing: false,
          })
        })

      return { syncing: true }
    })
  }

  changeSelectedYearId = yearId => {
    this.setState({
      selectedYearId: yearId?.toString(),
    })
  }

  toggleQuota = (quotaId, active) => {
    this.setState(state => {
      const stocks = JSON.parse(JSON.stringify(state.stocks))
      const events = JSON.parse(JSON.stringify(state.events))
      const weeksIds = this.props.orderedWeeksIds[this.state.selectedYearId]

      weeksIds.forEach(weekId => {
        stocks[quotaId][weekId].active = active
      })

      events.push({
        type: "toggleQuota",
        data: JSON.stringify({
          quotaId,
          yearId: Number(this.state.selectedYearId),
          active,
        }),
      })

      return {
        stocks,
        events,
      }
    })
  }

  toggleWeek = (weekId, active) => {
    this.setState(state => {
      const stocks = JSON.parse(JSON.stringify(state.stocks))
      const events = JSON.parse(JSON.stringify(state.events))

      for (const quotaId in stocks) {
        if (
          stocks.hasOwnProperty(quotaId)
          && this.props.quotas[quotaId]
          && this.canEditQuotaAndStock(this.props.quotas[quotaId])
        ) {
          stocks[quotaId][weekId].active = active
        }
      }


      events.push({
        type: "toggleWeek",
        data: JSON.stringify({
          familyId: Number(this.props.familyId),
          weekId,
          active,
        }),
      })

      return {
        stocks,
        events,
      }
    })
  }

  toggleStock = (quotaId, weekId) => {
    this.setState(state => {
      const stocks = JSON.parse(JSON.stringify(state.stocks))
      const events = JSON.parse(JSON.stringify(state.events))
      const stock = stocks[quotaId][weekId]

      stock.active = !stock.active

      events.push({
        type: "toggleStock",
        data: JSON.stringify({
          stockId: stock.id,
          active: stock.active,
        }),
      })

      return {
        stocks,
        events,
      }
    })
  }

  changeStockQuantity = (quotaId, weekId, quantity) => {
    this.setState(state => {
      const stocks = JSON.parse(JSON.stringify(state.stocks))
      const events = JSON.parse(JSON.stringify(state.events))
      const stock = stocks[quotaId][weekId]

      stock.quantity = quantity

      events.push({
        type: "changeStock",
        data: JSON.stringify({
          stockId: stock.id,
          quantity,
        }),
      })

      return {
        stocks,
        events,
      }
    })
  }

  changeFilterQuotasIds = (selected) => {
    this.setState({
      filterQuotasIds: selected?.map((option) => parseInt(option.value)) || [],
    })
  }

  filteredQuotaIds = () => {
    const { orderedQuotasIds, quotasGraph } = this.props;
    const { filterQuotasIds } = this.state;

    return filterQuotasIds.length > 0
      ? orderedQuotasIds.filter((quotaId) =>
        filterQuotasIds.some((filterQuotaId) => quotasGraph?.[filterQuotaId]?.includes(quotaId))
      )
      : orderedQuotasIds;
  }

  canEditQuotaAndStock = (quota) => {
    return !isEmpty(this.props.permsByTypology) && quota.familyId === 3 && quota.typologyIds?.length
      ? quota.typologyIds.some((id) => !!this.props.permsByTypology[id])
      : this.props.authorizedToEdit;
  };

  render = () => (
    <React.StrictMode>
      <div className={style.select_filter}>
        <Select
          options={this.props.filterQuotaOptions}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          onChange={this.changeFilterQuotasIds}
          placeholder="Filtrer par produit..."
        />
      </div>

      <div className="row">
        <div className="col">
          <ul className={cn([style.nav_tabs, 'nav nav-tabs'])}>
            {this.props.orderedYearsIds.map(yearId => {
              return (
                <Tab
                  key={yearId}
                  year={this.props.years[yearId]}
                  active={this.state.selectedYearId === yearId}
                  changeSelectedYearId={this.changeSelectedYearId}
                />
              )
            })}
          </ul>
        </div>
        <div className="col text-right">
          {this.props.authorizedToExport && (
            <button
              disabled={this.props.exportLoading}
              onClick={this.props.exportMatrix}
              className="btn btn-primary"
            >
              Exporter
              {this.props.exportLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          )}
        </div>
      </div>
      <div className={cn([style.table, 'table-responsive'])}>
        <table className={cn(["table", "table-sm", "table-bordered", "table-hover", "table-striped", style.theadFixed])}>
          <thead>
            <tr>
              <th className="align-middle">Semaine</th>
              <th className="align-middle">Du</th>
              <th className="align-middle">Au</th>
              {this.filteredQuotaIds().map(quotaId => (
                <Quota
                  key={quotaId}
                  quota={this.props.quotas[quotaId]}
                  toggleQuota={this.toggleQuota}
                  authorizedToEdit={this.canEditQuotaAndStock(this.props.quotas[quotaId])}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.orderedWeeksIds[this.state.selectedYearId] ? this.props.orderedWeeksIds[this.state.selectedYearId].map(
              weekId => (
                <tr key={weekId}>
                  <Week
                    authorizedToEdit={this.props.authorizedToEdit}
                    week={this.props.weeks[weekId]}
                    toggleWeek={this.toggleWeek}
                    quotas={this.props.quotas}
                    filteredQuotaIds={this.filteredQuotaIds}
                    canEditQuotaAndStock={this.canEditQuotaAndStock}
                  />
                  <td className="align-middle td-fixed-2">
                    {this.props.weeks[weekId].start}
                  </td>
                  <td className="align-middle td-fixed-3">
                    {this.props.weeks[weekId].end}
                  </td>
                  {this.filteredQuotaIds()
                    .filter((quotaId) => this.state.stocks?.[quotaId]?.[weekId])
                    .map(quotaId => {
                      return (
                        <Stock
                          key={quotaId}
                          quotaId={quotaId}
                          weekId={weekId}
                          stock={this.state.stocks[quotaId][weekId]}
                          toggleStock={this.toggleStock}
                          changeStockQuantity={this.changeStockQuantity}
                          authorizedToEdit={this.canEditQuotaAndStock(this.props.quotas[quotaId])}
                        />
                      )
                  })}
                </tr>
              )
            ) : null}
          </tbody>
        </table>
      </div>
      {this.props.authorizedToEdit && (
        <>
          <button
            type="button"
            disabled={
              this.state.events.length === 0 ||
              this.state.syncing
            }
            onClick={this.sendEvents}
            className="btn btn-primary mt-3 mb-3"
          >
            {this.state.syncing ? "Synchonisation..." : "Valider"}
          </button>
          <a href="" className="btn btn-secondary mt-3 mb-3 ml-1">
            Annuler
          </a>
        </>
      )}
    </React.StrictMode>
  )
}

export default StocksLegacy
