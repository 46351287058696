import React, { useEffect, useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import PackageCreation from "./PackagesCreationLegacy"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import usePackagesApi from "../../../hooks/Api/usePackagesApi"
import useTypesApi from "../../../hooks/Api/useTypesApi"
import useDurationsApi from "../../../hooks/Api/useDurationsApi"
import useFormatsApi from "../../../hooks/Api/useFormatsApi"
import useSportsApi from "../../../hooks/Api/useSportsApi"
import useTagsApi from "../../../hooks/Api/useTagsApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import useCampsApi from "../../../hooks/Api/useCampsApi"
import useAccommodationsApi from "../../../hooks/Api/useAccommodationsApi"
import useExtrasApi from "../../../hooks/Api/useExtrasApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Utils & misc
import { API_STAGES_URL, PERMISSIONS } from "../../../constants/constants"
import {
  exportCollectionToArrayName,
  exportCollectionToArrayImages,
  exportCollectionToArrayProduct,
} from "../../../utils/collection"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_PACKAGES_UPDATE,
  PERMISSIONS.PERM_PACKAGES_CREATE,
  PERMISSIONS.PERM_PACKAGES_ARCHIVE,
]

// TODO make edit packages errors from api
const PackagesEdit = ({ match }) => {
  const history = useHistory()
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [, packageData, , { getPackageWithId }] = usePackagesApi()
  const [, typesData, , { getTypes }] = useTypesApi()
  const [, durationsData, , { getDurations }] = useDurationsApi()
  const [, formatsData, , { getFormats }] = useFormatsApi()
  const [, sportsData, , { getSports }] = useSportsApi()
  const [, tagsData, , { getTags }] = useTagsApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, campsData, , { getCamps }] = useCampsApi()
  const [, accommodationsData, , { getAccommodations }] = useAccommodationsApi()
  const [, extrasData, , { getExtras }] = useExtrasApi()

  const [,,, { archivePackage }] = usePackagesApi();

  const [submitError, setSubmitError] = useState(null)

  const [
    ,
    ,
    updateError,
    { updatePackage },
  ] = usePackagesApi()
  const [
    ,
    ,
    newPackageError,
    { createPackage },
  ] = usePackagesApi()

  const savedGetTypes = useCallback(getTypes, [])
  const savedGetDurations = useCallback(getDurations, [])
  const savedGetFormats = useCallback(getFormats, [])
  const savedGetSports = useCallback(getSports, [])
  const savedGetTags = useCallback(getTags, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetCamps = useCallback(getCamps, [])
  const savedGetAccommodations = useCallback(getAccommodations, [])
  const savedGetExtras = useCallback(getExtras, [])

  const savedGetPackagesWithId = useCallback(getPackageWithId, [])

  const isEditingPackages = !isEmpty(match.params)

  const localesCollectionToArray = () =>
    locales
      ? Object.keys(locales).map(i => ({
          ...locales[i],
          localeId: i,
        }))
      : []

  useEffect(() => {
    if (isEditingPackages) {
      savedGetPackagesWithId(match.params.id)
    }
  }, [isEditingPackages, savedGetPackagesWithId, match.params.id])

  useEffect(() => {
    savedGetTypes()
    savedGetDurations()
    savedGetFormats()
    savedGetSports()
    savedGetTags()
    savedGetMedias()
    savedGetCamps({ archived: false })
    savedGetAccommodations({ archived: false })
    savedGetExtras({ archived: false })
  }, [
    savedGetTypes,
    savedGetDurations,
    savedGetFormats,
    savedGetSports,
    savedGetTags,
    savedGetMedias,
    savedGetCamps,
    savedGetAccommodations,
    savedGetExtras,
  ])

  useEffect(() => {
    const errors = newPackageError || updateError

    if (errors) {
      if (errors.data.errors) {
        const errorsList = Object.keys(errors.data.errors).map(error => ({
          field: error,
          message: errors.data.errors[error],
        }))
        setSubmitError(errorsList)
      }
    }
  }, [newPackageError, updateError])

  const handleSubmit = (event, values) => {
    if (event) event.preventDefault()

    const sanitizedValues = { ...values }
    if (!sanitizedValues.videoSource) {
      sanitizedValues.videoTitle = null
    }

    const linkedCamp = campsData?.camps?.[sanitizedValues.campId];

    return isEditingPackages
      ? updatePackage(sanitizedValues).then(res => {
          if (res && res.data) {
            history.push({
              pathname: "/packages",
              state: { submitType: "edit", success: true },
            })
          }
        })
      : createPackage({
        ...sanitizedValues, 
        highlighted: false,
        typeId: linkedCamp?.typeId || null,
        durationId: linkedCamp?.durationId || null,
        formatId: linkedCamp?.formatId || null,
      }).then(res => {
          if (res && res.data) {
            history.push({
              pathname: "/packages",
              state: { submitType: "create", success: true },
            })
          }
        })
  }

  // const convertSlotToArray = obj => {
  //   const slotsKeys = Object.keys(obj)
  //   return slotsKeys.map(slotKey => {
  //     const bundleKeys = Object.keys(obj[slotKey].bundles)
  //     return {
  //       bundles: bundleKeys.map(bundleKey => ({
  //         ...obj[slotKey].bundles[bundleKey],
  //       })),
  //     }
  //   })
  // }

  const buildTranslateValues = l => {
    return l
      ? l.reduce((acc, locale) => ({ ...acc, [locale.localeId]: "" }), {})
      : {}
  }

  const props = {
    submitError,
    action: `${
      isEditingPackages
        ? `${API_STAGES_URL}/v1/packages/${match.params.id}`
        : `${API_STAGES_URL}/v1/packages`
    }`,
    locales: localesCollectionToArray(),
    types: exportCollectionToArrayName(typesData, "types"),
    durations: exportCollectionToArrayName(durationsData, "durations"),
    sports: exportCollectionToArrayName(sportsData, "sports"),
    tags: exportCollectionToArrayName(tagsData, "tags"),
    formats: exportCollectionToArrayName(formatsData, "formats"),
    images: exportCollectionToArrayImages(mediasData, "medias", "fileKey"),
    camps: exportCollectionToArrayProduct(campsData, "camps").map(camp => ({
      ...camp,
      name: camp.product.name[1],
    })),
    accommodations: exportCollectionToArrayProduct(
      accommodationsData,
      "accommodations"
    ).map(accomodation => ({
      ...accomodation,
      name: accomodation.product.name[1],
    })),
    extras: exportCollectionToArrayProduct(extrasData, "extras").map(extra => ({
      ...extra,
      name: extra.product.name[1],
    })),
    data: isEditingPackages
      ? {
          ...packageData,
          videoTitle:
            packageData?.videoTitle ||
            buildTranslateValues(localesCollectionToArray()),
          // slots: convertSlotToArray(packageData ? packageData.slots : {}),
        }
      : undefined,
    errors:
      newPackageError && updateError
        ? { ...newPackageError.errors, ...updateError.errors }
        : null,
    handleSubmit,
    archivePackage,
    history,
  }

  let isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_PACKAGES_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_PACKAGES_CREATE] && !isEditingPackages)
  if (isEditingPackages && isAllowedToEdit) {
    if (packageData && packageData.archived) isAllowedToEdit = false
    else isAllowedToEdit = true
  }

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <span className="d-inline-flex">
          <h1>
            {`Packages / ${isEditingPackages ? "Éditer" : "Nouveau"}`}
            {packageData && packageData.name
              ? ` / ${packageData.name[1]}`
              : null}
          </h1>
          {packageData && packageData.archived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Archivé
              </span>
            </h3>
          ) : null}
        </span>
      </div>

      {(isEditingPackages ? packageData : true) &&
      extrasData &&
      campsData &&
      accommodationsData &&
      typesData &&
      durationsData &&
      sportsData &&
      tagsData &&
      formatsData &&
      mediasData ? (
        <PackageCreation
          {...props}
          isAllowedToEdit={isAllowedToEdit}
          isAllowedToArchive={
            rolePermissions[PERMISSIONS.PERM_PACKAGES_ARCHIVE]
          }
          isEditing={isEditingPackages}
        />
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

PackagesEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default PackagesEdit
