// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useTaxRatesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getTaxRates = () => request("GET", `${API_STAGES_URL}/v1/taxrates`)

  return [
    requestState,
    payload,
    error,
    {
      getTaxRates,
    },
  ]
}

export default useTaxRatesApi
