// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useClientsApi = () => {
  const [requestState, payload, error, request, rawRequest] = useApi()

  const getClients = options => {
    const params = options ? objectToURLParam(options) : ""

    return request("GET", `${API_STAGES_URL}/v1/clients${params}`)
  }

  const getClientsRaw = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${API_STAGES_URL}/v1/clients${params}`)
  }

  const getClientWithId = clientId =>
    request("GET", `${API_STAGES_URL}/v1/clients/${clientId}`)

  const updateClient = client =>
    request("PUT", `${API_STAGES_URL}/v1/clients/${client.id}`, client)

  const updateClientRaw = client =>
    rawRequest("PUT", `${API_STAGES_URL}/v1/clients/${client.id}`, client)

  const createClient = client =>
    request("POST", `${API_STAGES_URL}/v1/clients`, client)

  const sendClientResetPassword = clientId =>
    request("POST", `${API_STAGES_URL}/v1/clients/${clientId}/resetpassword`)

  const archiveClient = clientId =>
    rawRequest("PUT", `${API_STAGES_URL}/v1/clients/${clientId}/archive`)

  const putClientPlayerTennisData = (clientId, playerId, tennisData) => request('PUT', `${API_STAGES_URL}/v1/clients/${clientId}/players/${playerId}/tennis-practice-data`, tennisData)

  return [
    requestState,
    payload,
    error,
    {
      getClients,
      getClientsRaw,
      getClientWithId,
      updateClient,
      updateClientRaw,
      createClient,
      sendClientResetPassword,
      archiveClient,
      putClientPlayerTennisData,
    },
  ]
}

export default useClientsApi
