// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useTagsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getTags = () => request("GET", `${API_STAGES_URL}/v1/tags`)

  const getTagWithId = tagId =>
    request("GET", `${API_STAGES_URL}/v1/tags/${tagId}`)

  const updateTag = tag =>
    request("PUT", `${API_STAGES_URL}/v1/tags/${tag.id}`, tag)

  const createTag = tag => request("POST", `${API_STAGES_URL}/v1/tags`, tag)

  return [
    requestState,
    payload,
    error,
    {
      getTags,
      getTagWithId,
      updateTag,
      createTag,
    },
  ]
}

export default useTagsApi
