// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const usePlayersApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getClientsPlayers = clientId =>
    request("GET", `${API_STAGES_URL}/v1/players?clientId=${clientId}`)

  const getPlayerById = playerId =>
    request("GET", `${API_STAGES_URL}/v1/players/${playerId}`)

  const updatePlayer = player =>
    request("PUT", `${API_STAGES_URL}/v1/players/${player.uid}`, player)

  const createPlayer = player =>
    request("POST", `${API_STAGES_URL}/v1/players`, player)

  const sendLinkEmail = (playerId, data) =>
    request("POST", `${API_STAGES_URL}/v1/players/${playerId}/link-email`, data)

  const deleteBorrower = (playerId, data) =>
    request(
      "DELETE",
      `${API_STAGES_URL}/v1/players/${playerId}/borrowers`,
      data
    )

  const activatePlayerImageRight = ({ clientId, playerId, isActive }) =>
    request(
      "PUT",
      `${API_STAGES_URL}/v1/clients/${clientId}/players/${playerId}:${isActive ? 'activate' : 'deactivate'}-right-to-the-image`
    )

  return [
    requestState,
    payload,
    error,
    {
      getClientsPlayers,
      getPlayerById,
      updatePlayer,
      createPlayer,
      sendLinkEmail,
      deleteBorrower,
      activatePlayerImageRight
    },
  ]
}

export default usePlayersApi
