import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { Formik, Form, Field } from "formik"
import { startOfYear, format, getYear } from "date-fns"
import { fr } from "date-fns/esm/locale"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import Editor from "../../../components/organisms/Editor/Editor"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useEventsApi from "../../../hooks/Api/useEventsApi"
import usePermissionsState from "../../../hooks/usePermissionsState"
import useTypesApi from "../../../hooks/Api/useTypesApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import useDurationsApi from "../../../hooks/Api/useDurationsApi"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"
import { weekIdToDate, dateToWeekId, getISOWeeks } from "../../../utils/weeks"
import { getInitialLocaleValues } from "../../../utils/locales"
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import { exportCollectionToArrayImages } from "../../../utils/collection"
import MediasInput from "../../../components/atoms/MediasInput/MediasInput"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_EVENTS_UPDATE,
  PERMISSIONS.PERM_EVENTS_CREATE,
]

const EventsEdit = ({ match }) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)

  const history = useHistory()
  const [, eventData,, { getEventById }] = useEventsApi()
  const [updateEventState,, updateError, { updateEvent }] = useEventsApi()
  const [newEventState,, newEventError, { createEvent }] = useEventsApi()
  const [, typesData, , { getTypes }] = useTypesApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, durationsData, , { getDurations }] = useDurationsApi()

  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  const savedGetEventWithId = useCallback(getEventById, [])
  const savedGetTypes = useCallback(getTypes, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetDurations = useCallback(getDurations, [])

  const backToList = () => {
    history.push({
      pathname: "/events",
      state: {},
    })
  }

  useEffect(() => {
    if (isEditing) {
      savedGetEventWithId(match.params.id).then((res) => {
        if (!res?.data) backToList();
      });
    }
    savedGetTypes()
    savedGetMedias()
    savedGetDurations()
    savedGetDurations()
  }, [
    isEditing,
    savedGetEventWithId,
    savedGetTypes,
    savedGetMedias,
    savedGetDurations,
    match.params.id,
  ])

  const handleSubmit = values => {
    if (isEditing) {
      updateEvent(values).then(() =>
        history.push({
          pathname: "/events",
          state: { submitType: "edit", success: true },
        })
      )
    } else {
      createEvent(values).then(() =>
        history.push({
          pathname: "/events",
          state: { submitType: "create", success: true },
        })
      )
    }
  }

  const weekOptions = useMemo(() => {
    const currentYear = getYear(new Date())
    const options = []
    const currentYearWeeks = getISOWeeks(Number(currentYear))
    const nextYearWeeks = getISOWeeks(Number(currentYear) + 1)
    const firstWeek = new Date(Number(currentYear), 1, 1, 0, 0)
    const firstWeekId = dateToWeekId(startOfYear(firstWeek), "booking")
    for (let i = 1; i < currentYearWeeks + nextYearWeeks; i += 1) {
      options.push({
        label: `Semaine du ${format(
          weekIdToDate(firstWeekId + i, "booking"),
          "dd MMMM yyyy",
          { locale: fr }
        )}`,
        value: firstWeekId + i,
      })
    }
    return options
  }, [])

  const createInitialValues = locales
    ? {
        typeId: 1,
        startWeekId: weekOptions[0].value,
        endWeekId: weekOptions[1].value,
        illustrationId: 1,
        bannerId: 1,
        active: true,
        title: getInitialLocaleValues(locales),
        imageTitle: getInitialLocaleValues(locales),
        bannerTitle: getInitialLocaleValues(locales),
        description: getInitialLocaleValues(locales),
        durationId: 1,
      }
    : {}

  const getWeekOption = weekId => {
    return {
      label: `Semaine du ${format(
        weekIdToDate(weekId, "booking"),
        "dd MMMM yyyy",
        { locale: fr }
      )}`,
      value: weekId,
    }
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_EVENTS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_EVENTS_CREATE] && !isEditing)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Events / ${isEditing ? "Éditer" : "Nouveau"}`}
          {eventData && eventData.name ? ` / ${eventData.name[1]}` : null}
        </h1>
      </div>

      {typesData && mediasData && durationsData ? (
        <Formik
          enableReinitialize
          initialValues={
            isEditing && eventData ? eventData : createInitialValues
          }
          onSubmit={handleSubmit}
          // validate={generalValidate}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form className="mw-100 w-75 m-auto">
              <div className={cn(["card mb-4"])}>
                <div className="card-body">
                  <div className="form-row">
                    {/* TYPE ID */}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="discount-typeId">Type id</label>
                      </b>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="typeId"
                        name="typeId"
                        required
                        onChange={e =>
                          setFieldValue("typeId", Number(e.target.value))
                        }
                        onBlur={handleBlur}
                        value={values.typeId}
                      >
                        {Object.keys(typesData.types).map(typeId => (
                          <option
                            key={`typeId_select_${typeId}`}
                            value={typeId}
                          >
                            {typesData.types[typeId].name["1"]}
                          </option>
                        ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <div className="align-items-center">
                          <b>
                            <label htmlFor="startWeekId" className="pr-2">
                              Semaine de début
                            </label>
                          </b>
                          <br />
                          <Select
                            isDisabled={!isAllowedToEdit}
                            value={getWeekOption(values.startWeekId)}
                            onChange={selected =>
                              setFieldValue("startWeekId", selected.value)
                            }
                            options={weekOptions}
                            placeholder="Semaine"
                            noOptionsMessage={() => (
                              <span>Pas de résultats</span>
                            )}
                            styles={{
                              container: provided => ({
                                ...provided,
                                flex: "1 1 0",
                              }),
                              option: provided => ({
                                ...provided,
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <div className="align-items-center">
                          <b>
                            <label htmlFor="startWeekId" className="pr-2">
                              Semaine de fin
                            </label>
                          </b>
                          <Select
                            isDisabled={!isAllowedToEdit}
                            value={getWeekOption(values.endWeekId)}
                            onChange={selected =>
                              setFieldValue("endWeekId", selected.value)
                            }
                            options={weekOptions}
                            placeholder="Semaine"
                            noOptionsMessage={() => (
                              <span>Pas de résultats</span>
                            )}
                            styles={{
                              container: provided => ({
                                ...provided,
                                flex: "1 1 0",
                              }),
                              option: provided => ({
                                ...provided,
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 pb-3">
                      <MediasInput
                        label="Image d'illustration Landing Page"
                        options={mediasOptions}
                        selection={values.illustrationId ? [values.illustrationId] : []}
                        setSelection={(value) => setFieldValue('illustrationId', value[0]?.id || null)}
                        required
                      />
                    </div>
                    {Object.keys(locales).map(localeId => (
                      <div
                        key={`bannerTitle_${localeId}`}
                        className="col-sm-4 col-md-4 pb-3"
                      >
                        <b>
                          <label htmlFor="extra-typeId">{`Titre Image d'illustration Page de résultats ${locales[
                            localeId
                          ].code.toUpperCase()}`}</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id={`bannerTitle.${localeId}`}
                          name={`bannerTitle.${localeId}`}
                          value={values.bannerTitle[localeId]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    ))}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="bannerId">
                        Image d'illustration Page de résultats
                        </label>
                      </b>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="bannerId"
                        name="bannerId"
                        required
                        onChange={e =>
                          setFieldValue(
                            "bannerId",
                            Number(e.target.value)
                          )
                        }
                        onBlur={handleBlur}
                        value={values.bannerId}
                      >
                        {Object.keys(mediasData.medias).map(bannerId => (
                          <option
                            key={`thumbnail_id_select_${bannerId}`}
                            value={bannerId}
                          >
                            {mediasData.medias[bannerId].fileKey}
                          </option>
                        ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>

                    {/* TITLE */}
                    {Object.keys(locales).map(localeId => (
                      <div
                        key={`title_${localeId}`}
                        className="col-sm-4 col-md-4 pb-3"
                      >
                        <b>
                          <label htmlFor="extra-typeId">{`Titre ${locales[
                            localeId
                          ].code.toUpperCase()}`}</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id={`title.${localeId}`}
                          name={`title.${localeId}`}
                          value={values.title[localeId]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    ))}

                    {/* IMAGE_TITLE */}
                    {Object.keys(locales).map(localeId => (
                      <div
                        key={`imageTitle_${localeId}`}
                        className="col-sm-4 col-md-4 pb-3"
                      >
                        <b>
                          <label htmlFor="extra-typeId">{`Titre image ${locales[
                            localeId
                          ].code.toUpperCase()}`}</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id={`imageTitle.${localeId}`}
                          name={`imageTitle.${localeId}`}
                          value={values.imageTitle[localeId]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    ))}

                    {/* DESCRIPTION */}
                    {Object.keys(locales).map(localeId => (
                      <div
                        key={`description_${localeId}`}
                        className="col-sm-4 col-md-4 pb-3"
                      >
                        <b>
                          <label htmlFor="extra-typeId">{`Description ${locales[
                            localeId
                          ].code.toUpperCase()}`}</label>
                        </b>
                        <Editor
                          disabled={!isAllowedToEdit}
                          initialContent={values.description[localeId]}
                          handleContentChange={htmlContent =>
                            setFieldValue(
                              `description.${localeId}`,
                              htmlContent
                            )
                          }
                          label={null}
                          error={null}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    ))}

                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="durations">Theme</label>
                      </b>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="durations"
                        name="durations"
                        required
                        onChange={e => {
                          setFieldValue("durationId", Number(e.target.value))
                        }}
                        onBlur={handleBlur}
                        value={values.durationId}
                      >
                        {Object.keys(durationsData.durations).map(duration => (
                          <option
                            key={`duration_select_${durationsData.durations[duration].id}`}
                            value={durationsData.durations[duration].id}
                          >
                            {durationsData.durations[duration].name["1"]}
                          </option>
                        ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>

                    <div className="col-sm-12 col-md-12 pb-3">
                      <div className="form-group">
                        <b>
                          <label htmlFor="active">Actif :&nbsp;</label>
                        </b>
                        <div role="group" aria-labelledby="my-radio-group">
                          <label className="mr-4" htmlFor="active-1">
                            <Field
                              disabled={!isAllowedToEdit}
                              id="active-1"
                              type="radio"
                              name="active"
                              className="mr-1"
                              onChange={() => setFieldValue("active", true)}
                              checked={values.active}
                            />
                            Oui
                          </label>
                          <label htmlFor="active-2">
                            <Field
                              disabled={!isAllowedToEdit}
                              id="active-2"
                              type="radio"
                              name="active"
                              className="mr-1"
                              onChange={() => setFieldValue("active", false)}
                              checked={!values.active}
                            />
                            Non
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {updateError?.data?.message || newEventError?.data?.message ? (
                <div className="alert alert-danger" role="alert">
                  {updateError?.data?.message || newEventError?.data?.message}
                </div>
              ) : null}

              <div className="float-right mb-4">
                {!isAllowedToEdit ? (
                  <button
                    onClick={backToList}
                    type="button"
                    className="btn btn-secondary mr-2"
                  >
                    Revenir à la liste
                  </button>
                ) : null}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    updateEventState === "loading" ||
                    newEventState === "loading" ||
                    !isAllowedToEdit
                  }
                >
                  {updateEventState === "loading" ||
                  newEventState === "loading" ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Valider
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

EventsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
}

export default EventsEdit
