export default [
  {
    Header: "Stage",
    accessor: "campName",
  },
  {
    Header: "Sexe",
    accessor: "sex",
  },
  {
    Header: "Nom du stagiaire",
    accessor: "lastname",
  },
  {
    Header: "Prénom du stagiaire",
    accessor: "firstname",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "Pays du stagiaire",
    accessor: "country",
  },
  {
    Header: "Option",
    accessor: "options",
  },
  {
    Header: "Hébergement",
    accessor: "accommodations",
  },
  {
    Header: "Code bénéficiaire",
    accessor: "publicId",
  },
  {
    Header: "Section du joueur",
    accessor: "section",
  },
  {
    Header: "Email client",
    accessor: "clientEmail",
  },
]
