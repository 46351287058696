import React, { useEffect, useState } from "react"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import FidAdminMenu from "../../../../components/molecules/Fid/FidAdminMenu/FidAdminMenu"

// Hooks

import style from "./FidAdmin.module.css"

const FidAdmin = () => {
  // Effect for fetching data
  useEffect(() => {}, [])

  const [menuView, setMenuView] = useState(null)

  const handleAdminMenuChange = view => {
    setMenuView(view)
  }

  const initialAdminMenu = initialView => {
    setMenuView(initialView)
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Fidélité / Administration</h1>
      </div>
      <FidAdminMenu
        initialAdminMenu={initialAdminMenu}
        handleAdminMenuChange={handleAdminMenuChange}
      />
      {menuView}
    </WithMenu>
  )
}
export default FidAdmin
