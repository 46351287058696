import React from "react"
import PropTypes from "prop-types"

// Components

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

import "./ConfigSnippets.module.css"

const permissions = [
  PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_DISPLAY,
  PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_UPDATE,
  PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_DISPLAY,
  PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_UPDATE,
]

const ConfigSnippets = ({
  values,
  handleChange,
  headJsSnippets,
  frontAndMiddleJsSnippets,
}) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const localesArray = Object.keys(locales).map(localeId => ({
    id: localeId,
    code: locales[localeId].code,
  }))

  return (
    <>
      {rolePermissions[PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_DISPLAY] ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <h3 className="card-title">Front and Middle JS Snippets</h3>
            <div className="form-row">
              {locales
                ? localesArray.map(locale => (
                    <div
                      className="col-sm-12 col-md-6 pb-2"
                      key={`locale-snippet-${locale.id}`}
                    >
                      <label
                        htmlFor={`config-frontAndMiddleJsSnippets-${locale.id}`}
                      >
                        {locale.code}
                      </label>
                      <textarea
                        disabled={
                          !rolePermissions[
                            PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_UPDATE
                          ]
                        }
                        required
                        className="form-control"
                        id={`frontAndMiddleJsSnippets.${locale.id}`}
                        name={`frontAndMiddleJsSnippets.${locale.id}`}
                        rows="3"
                        value={frontAndMiddleJsSnippets[locale.id]}
                        onChange={handleChange}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : null}

      {rolePermissions[PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_DISPLAY] ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <h3 className="card-title">Head JS Snippets</h3>
            <div className="form-row">
              {locales
                ? localesArray.map(locale => (
                    <div
                      className="col-sm-12 col-md-6 pb-2"
                      key={`locale-snippet-${locale.id}`}
                    >
                      <label htmlFor={`config-headJsSnippets-${locale.id}`}>
                        {locale.code}
                      </label>
                      <textarea
                        disabled={
                          !rolePermissions[
                            PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_UPDATE
                          ]
                        }
                        required
                        className="form-control"
                        id={`headJsSnippets.${locale.id}`}
                        name={`headJsSnippets.${locale.id}`}
                        rows="3"
                        value={headJsSnippets[locale.id]}
                        onChange={handleChange}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

ConfigSnippets.propTypes = {
  values: PropTypes.shape({ frontAndMiddleJsSnippets: PropTypes.shape() })
    .isRequired,
  handleChange: PropTypes.func.isRequired,
}
export default ConfigSnippets
