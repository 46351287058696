import React, { useEffect, useState, useCallback } from "react"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import GlobalMessage from "../../../components/atoms/GlobalMessage/GlobalMessage"
import Spinner from "../../../components/atoms/Spinner/Spinner"

// Hooks
import useRolesApi from "../../../hooks/Api/Authorization/useRolesApi"
import useCategories from "../../../hooks/Api/Authorization/useCategoriesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Validation
import roleSchema from "./validation"

// Utils
import cn from "../../../utils/cn"

// Styles
import styles from "./RolesEdit.module.css"

const permissions = [
  PERMISSIONS.PERM_ROLES_UPDATE,
  PERMISSIONS.PERM_ROLES_CREATE,
]

const RolesEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const history = useHistory()
  const [role, setRole] = useState(null)
  const isEditing = !isEmpty(match.params)
  const [categories, setCategories] = useState(null)
  const [roleTab, setRoleTab] = useState(1)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [{ getRole, createRole, editRole }] = useRolesApi()
  const [{ getCategories }] = useCategories()
  const savedGetRole = useCallback(getRole, [])
  const savedGetCategories = useCallback(getCategories, [])
  const isEditingRole = !isEmpty(match.params)

  const fetchRole = useCallback(async () => {
    try {
      if (match.params.id) {
        const roleResp = await savedGetRole(match.params.id)
        setRole(roleResp.data)
      }
      const categoriesResp = await savedGetCategories()

      setCategories(
        Object.keys(categoriesResp.data.categories).map(
          categoryId => categoriesResp.data.categories[categoryId]
        )
      )
    } catch (error) {
      //
    }
  }, [match.params.id, savedGetCategories, savedGetRole])

  useEffect(() => {
    fetchRole()
  }, [fetchRole])

  const handleMessageClose = e => {
    e.preventDefault()

    setGlobalMessage({ ...globalMessage, isActive: false })
  }

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues:
      isEditingRole && role
        ? role
        : {
            name: "",
            permissionsIds: [],
          },
    enableReinitialize: true,
    validationSchema: roleSchema,
    onSubmit: async vSubmit => {
      const sanitized = { ...vSubmit }
      delete sanitized.usersCount

      try {
        await roleSchema.validate(sanitized)
        if (!isEditingRole) {
          await createRole(sanitized)
          history.push({
            pathname: "/roles",
            state: { submitType: "create", success: true },
          })
        } else {
          await editRole(match.params.id, sanitized)
          history.push({
            pathname: "/roles",
            state: { submitType: "edit", success: true },
          })
        }
      } catch (error) {
        if (error.response) {
          setGlobalMessage({
            isActive: true,
            message: (
              <ul className="mb-0">
                {Object.keys(error.response.data.errors)
                  .map(e => ({
                    message: error.response.data.errors[e],
                    field: e,
                  }))
                  .map(e => (
                    <li
                      key={`formik_error_${e.field}`}
                    >{`${e.field} - ${e.message}`}</li>
                  ))}
              </ul>
            ),
            className: "alert alert-danger",
          })
        }
      }
    },
  })

  const handlePermissionChange = permId => {
    const permIndex = values.permissionsIds.findIndex(id => permId === id)
    const updatedPermissionsIds = values.permissionsIds.slice(0)
    if (permIndex !== -1) {
      updatedPermissionsIds.splice(permIndex, 1)
      setFieldValue("permissionsIds", updatedPermissionsIds)
    } else {
      updatedPermissionsIds.push(permId)
      setFieldValue("permissionsIds", updatedPermissionsIds)
    }
  }

  const backToList = () => {
    history.push({
      pathname: "/roles",
      state: {},
    })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_ROLES_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_ROLES_CREATE] && !isEditing)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Role / ${isEditingRole ? "Éditer" : "Nouveau"}`}
          {role ? ` / ${role.name}` : null}
        </h1>
      </div>

      {/* ROLE CHOICE */}
      <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-12">
          <div
            className="btn-group mb-4 float-right"
            role="group"
            aria-label="roleChoice"
          >
            <button
              type="button"
              onClick={() => setRoleTab(1)}
              className={`btn btn-outline-primary ${
                roleTab === 1 ? "active" : ""
              }`}
            >
              Nom
            </button>
            <button
              type="button"
              onClick={() => setRoleTab(2)}
              className={`btn btn-outline-primary ${
                roleTab === 2 ? "active" : ""
              }`}
            >
              Permissions
            </button>
          </div>
        </div>
      </div>

      {categories ? (
        <div className={cn(["mw-100", "w-100", "m-auto", styles.editSection])}>
          <form onSubmit={handleSubmit}>
            {roleTab === 1 ? (
              <div className={cn(["card mb-4"])}>
                <div className="card-body">
                  <h5 className="card-title">Nom du Rôle</h5>
                  <div className="form-row">
                    <div className="col-sm-6 col-md-6 pb-6">
                      <div className="form-group">
                        <label htmlFor="roleName">Nom</label>
                        <input
                          disabled={!isAllowedToEdit}
                          type="text"
                          className="form-control"
                          id="roleName"
                          aria-describedby="nameHelp"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                        />
                        {errors.name ? (
                          <small
                            id="nameHelp"
                            className="form-text text-danger"
                          >
                            {errors.name}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {categories.map(category => (
                  <div
                    key={`form_${category.id}`}
                    className={cn(["card mb-4"])}
                  >
                    <div className="card-body">
                      <h5 className="card-title mb-4">{category.name}</h5>
                      <div className="form-row">
                        <div className="col-sm-12 col-md-12 pb-12">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>Permissions</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(category.permissions)
                                .map(permId => category.permissions[permId])
                                .map(permission => (
                                  <tr key={`perm_${permission.id}`}>
                                    <td className={styles.nameColumn}>
                                      {permission.name}
                                    </td>
                                    <td className={styles.statusColumn}>
                                      <span>
                                        <input
                                          disabled={!isAllowedToEdit}
                                          type="checkbox"
                                          onChange={() =>
                                            handlePermissionChange(
                                              permission.id
                                            )
                                          }
                                          checked={
                                            values.permissionsIds.find(
                                              permId => permId === permission.id
                                            ) !== undefined
                                          }
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            <GlobalMessage
              isActive={globalMessage.isActive}
              content={globalMessage.message}
              className={globalMessage.className}
              onClose={handleMessageClose}
            />
            <div className="float-right mb-4">
              <button
                onClick={backToList}
                type="button"
                className="btn btn-secondary mr-2"
              >
                {isAllowedToEdit ? "Revenir à la liste" : "Annuler"}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isAllowedToEdit}
              >
                {isEditingRole ? "Éditer" : "Sauvegarder"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </WithMenu>
  )
}

RolesEdit.propTypes = {}

export default RolesEdit
