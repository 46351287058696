// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useClientShuttlesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getClientShuttlesFromClient = clientId =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/clientsshuttles${
        clientId ? `?clientId=${clientId}` : ""
      }`
    )

  const getClientShuttle = shuttleId =>
    request("GET", `${API_STAGES_URL}/v1/clientsshuttles/${shuttleId}`)

  const getClientShuttlesArray = shuttleArray => {
    const requestQueue = []
    for (let i = 0; i < shuttleArray.length; i += 1) {
      const url = `${API_STAGES_URL}/v1/clientsshuttles/${shuttleArray[i]}`
      requestQueue.push(request("GET", url))
    }
    return Promise.all(requestQueue)
  }

  const updateClientShuttle = (shuttleId, body) =>
    request("PUT", `${API_STAGES_URL}/v1/clientsshuttles/${shuttleId}`, body)

  return [
    requestState,
    payload,
    error,
    {
      getClientShuttlesFromClient,
      getClientShuttle,
      getClientShuttlesArray,
      updateClientShuttle,
    },
  ]
}

export default useClientShuttlesApi
