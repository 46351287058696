// Hooks
import useApi from "../useApi"

// Utils & misc
import { FID_HOSTNAME } from "../../../constants/constants"
import objectToURLParam from "../../../utils/objectToURLParam"

const useFidLevels = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${FID_HOSTNAME}/api/v1`

  const getFidLevels = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${BASE_URL}/levels${params}`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const createFidLevel = est =>
    rawRequest("POST", `${BASE_URL}/levels`, est, {
      authKey: AUTHORIZATION,
    })

  const updateFidLevel = (estId, est) =>
    rawRequest("PUT", `${BASE_URL}/levels/${estId}`, est, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getFidLevels,
      updateFidLevel,
      createFidLevel,
    },
  ]
}

export default useFidLevels
