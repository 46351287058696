/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback, useState } from "react"
import { getYear } from "date-fns"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

import "react-datepicker/dist/react-datepicker.css"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Spinner from "../../../components/atoms/Spinner/Spinner"
import Modal from "../../../components/molecules/Modal"
import GlobalMessage from "../../../components/atoms/GlobalMessage/GlobalMessage"

// Hooks
import useProductsApi from "../../../hooks/Api/useProductsApi"
import useYearsApi from "../../../hooks/Api/useYearsApi"
import useSeasonsApi from "../../../hooks/Api/useSeasonsApi"

// Utils & misc
import cn from "../../../utils/cn"
import { exportCollectionToArray } from "../../../utils/collection"

import style from "./PriceEdit.module.css"

countries.registerLocale(countriesFR)

const currentYear = getYear(new Date())

const families = {
  1: "Stage",
  2: "Extra",
  3: "Hébergement",
}

const PriceEdit = ({ match }) => {
  const history = useHistory()

  const [activeYear, setActiveYear] = useState(null)
  const [editedPrices, setEditedPrices] = useState([])
  const [confirmPriceChange, setConfirmPriceChange] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [formattedProduct, setFormattedProduct] = useState(null)

  const [, productData, , { getProductById }] = useProductsApi()
  const [, , , { updateProductPrice }] = useProductsApi()
  const [, yearsData, , { getYears }] = useYearsApi()
  const [, seasonsData, , { getSeasons }] = useSeasonsApi()

  const savedGetProductById = useCallback(getProductById, [])
  const savedGetYears = useCallback(getYears, [])
  const savedGetSeasons = useCallback(getSeasons, [])

  const handleActiveYearChange = (e, year) => {
    e.preventDefault()
    setActiveYear(year)
  }

  const handlePriceChange = (e, yearId, seasonId, value) => {
    e.preventDefault()

    const foundEditedIndex = editedPrices.findIndex(price => {
      if (price.seasonId === Number(seasonId) && price.yearId === yearId) {
        return true
      }
      return false
    })

    const newEdited = editedPrices.slice(0)
    if (foundEditedIndex !== -1) {
      if (formattedProduct.prices[yearId][seasonId] === Number(value)) {
        newEdited.splice(foundEditedIndex, 1)
      } else {
        newEdited[foundEditedIndex].value = value
      }
      setEditedPrices(newEdited)
    } else {
      newEdited.push({
        yearId,
        seasonId: Number(seasonId),
        value,
      })
      setEditedPrices(newEdited)
    }
  }

  const handlePricesValidate = e => {
    e.preventDefault()
    setConfirmPriceChange(true)
  }

  const handlePricesSubmit = () => {
    const updatePrice = ({ yearId, seasonId, value }) =>
      new Promise((resolve, reject) => {
        updateProductPrice(
          match.params.productId,
          yearId,
          seasonId,
          Number(value)
        )
          .then(r => {
            resolve()
          })
          .catch(e => {
            reject()
          })
      })
    const formatted = editedPrices.map(price => ({
      ...price,
      value: (Number(price.value) * 100).toString(),
    }))
    Promise.all(formatted.map(price => updatePrice(price))).then(r => {
      const pluralMessage = "Les prix ont été modifiés."
      const message = "Le prix a été modifié."
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong>{" "}
            {formatted.length === 1 ? message : pluralMessage}
          </span>
        ),
        className: "alert alert-success",
      })
      setEditedPrices([])
      setConfirmPriceChange(false)
      fetchData()
    })
  }

  const redirect = () => {
    switch (productData?.familyId) {
      case 1: {
        history.push("/camps/list")
        break
      }
      case 2: {
        history.push("/extras/list")
        break
      }
      case 3: {
        history.push("/accommodations/list")
        break
      }
      default:
        break
    }
  }

  const getEditedPrice = seasonId => {
    const foundPrice = editedPrices.find(
      price =>
        price.yearId === activeYear.id && price.seasonId === Number(seasonId)
    )
    if (foundPrice !== undefined) {
      return foundPrice.value
    }
    return formattedProduct.prices[activeYear.id][seasonId]
  }

  const areEditedPricesValid = () =>
    editedPrices.find(price => price.value === "") === undefined

  const displayPriceChange = price => {
    const season = seasonsData.seasons[price.seasonId].name
    const year = exportCollectionToArray(yearsData, "years").find(
      y => y.id === price.yearId
    )?.number

    // find initial value
    const initialPrice = formattedProduct.prices[activeYear.id][price.seasonId]

    return (
      <p className="m-0">
        {`Saison ${season}, année ${year}, de `}
        <span className="text-danger">{initialPrice}€</span> à{" "}
        <span className="text-success font-weight-bold">{price.value}€</span>
      </p>
    )
  }

  const fetchData = useCallback(() => {
    if (match.params.productId) {
      savedGetProductById(match.params.productId)
      savedGetYears()
      savedGetSeasons()
    }
  }, [
    savedGetProductById,
    savedGetYears,
    savedGetSeasons,
    match.params.productId,
  ])

  const convertPrices = (prices, currency) => {
    return Object.keys(prices).reduce((acc, yearId) => {
      return {
        ...acc,
        [yearId]: Object.keys(prices[yearId]).reduce((seasons, seasonId) => {
          return {
            ...seasons,
            [seasonId]:
              currency === "EUR"
                ? prices[yearId][seasonId] / 100
                : prices[yearId][seasonId] * 100,
          }
        }, {}),
      }
    }, {})
  }

  useEffect(() => {
    if (productData) {
      setFormattedProduct({
        ...productData,
        prices: convertPrices(productData.prices, "EUR"),
      })
    }
  }, [productData])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (yearsData) {
      setActiveYear(
        exportCollectionToArray(yearsData, "years").find(
          year => year.number === currentYear
        )
      )
    }
  }, [yearsData])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        {productData &&
        yearsData &&
        activeYear &&
        seasonsData &&
        formattedProduct ? (
          <div className="py-4">
            <form onSubmit={handlePricesValidate} className="needs-validation">
              <h1>{`Éditer Prix / ${families[productData?.familyId]} / ${
                productData?.name[1]
              }`}</h1>
              <GlobalMessage
                isActive={globalMessage.isActive}
                content={globalMessage.message}
                className={globalMessage.className}
                onClose={() =>
                  setGlobalMessage({ ...globalMessage, isActive: false })
                }
              />
              <ul className="nav nav-pills mt-4">
                {exportCollectionToArray(yearsData, "years")
                  .filter(
                    year =>
                      year.number === currentYear ||
                      year.number === currentYear + 1
                  )
                  .map(year => (
                    <li className="nav-item">
                      <a
                        tabIndex={0}
                        onKeyDown={null}
                        role="button"
                        onClick={e => handleActiveYearChange(e, year)}
                        className={`nav-link ${
                          activeYear.number === year.number ? "active" : ""
                        }`}
                        aria-current="page"
                        href=""
                      >
                        {year.number}
                      </a>
                    </li>
                  ))}
              </ul>

              <div className="row g-3 mt-3">
                {Object.keys(formattedProduct.prices[activeYear.id])
                  .sort((a, b) => {
                    return (
                      seasonsData.seasons[a].ordinal -
                      seasonsData.seasons[b].ordinal
                    )
                  })
                  .map(seasonId => {
                    return (
                      <div className="col-md-4 mt-2 mb-3">
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          {`Saison ${seasonsData.seasons[seasonId].name}`}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom01"
                          value={getEditedPrice(seasonId)}
                          onChange={e =>
                            handlePriceChange(
                              e,
                              activeYear.id,
                              seasonId,
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    )
                  })}
              </div>

              {editedPrices.length > 0 && !areEditedPricesValid() ? (
                <div className="row">
                  <div className="col">
                    <label htmlFor="price-submit" className="text-danger">
                      Des champs sont incomplets, veuillez vérifier la saisie
                      sur les deux années
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="row mt-2">
                <div className="col">
                  <button
                    id="price-submit"
                    type="button"
                    onClick={redirect}
                    className="btn btn-secondary mb-3 mr-3"
                  >
                    Retour
                  </button>
                  {editedPrices.length > 0 ? (
                    <>
                      <button
                        id="price-submit"
                        type="submit"
                        disabled={!areEditedPricesValid()}
                        className="btn btn-primary mb-3"
                      >
                        Valider
                      </button>
                    </>
                  ) : (
                    <button
                      id="price-submit"
                      type="submit"
                      disabled
                      className="btn btn-primary mb-3"
                    >
                      Valider
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <Modal
        isDisplayed={confirmPriceChange}
        onClose={() => setConfirmPriceChange(false)}
      >
        <h3>Récapitulatif</h3>
        <h5 className="text-secondary">
          Les changements de prix suivants vont être appliqués:
        </h5>
        <ul className={cn(["list-group mt-4", style.priceList])}>
          {editedPrices.map(price => (
            <li
              key={`price-item-change-${price.id}`}
              className="list-group-item"
            >
              {displayPriceChange(price)}
            </li>
          ))}
        </ul>
        <button
          id="price-submit"
          type="button"
          className="btn btn-primary mt-4"
          onClick={handlePricesSubmit}
        >
          Confirmer
        </button>
      </Modal>
    </WithMenu>
  )
}

PriceEdit.propTypes = {
  match: PropTypes.shape().isRequired,
}

export default PriceEdit
