import React, { useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from "libphonenumber-js"
import { upperCase } from "lodash"

// Components
import TextField from "../TextField"
import Select from "./Select"

// Hooks
import useCountriesApi from "../../../hooks/Api/useCountriesApi"

// Utils & Misc
import cn from "../../../utils/cn"

// Style
import flagsStyle from "../../../constants/flags.module.css"
import style from "./PhoneField.module.css"

export const PhoneField = ({
  defaultCountry,
  defaultPhone,
  className,
  onBlur,
  selectPlaceholder,
  disabled,
  value,
  onChange,
  onInit,
  ...propsLeft
}) => {
  const [, , , { getCountries }] = useCountriesApi()
  const [countries, setCountries] = useState(null)

  const initialValue = useRef(value)

  const savedGetCountries = useCallback(getCountries, [])
  const savedOnInit = useCallback(onInit, [])

  const [country, setCountry] = useState({
    codeISO: "",
    callingCode: "",
  })

  const [phoneValue, setPhoneValue] = useState("")

  useEffect(() => {
    const fetchCountries = async () => {
      const resp = await savedGetCountries()
      if (resp && resp.data) {
        const { countries: countriesData } = resp.data

        setCountries(
          Object.keys(countriesData)
            .filter(c => {
              const element = countriesData[c]
              return !!element.cc
            })
            .map(c => countriesData[c])
        )
      }
    }
    fetchCountries()

    //
    const formatted = parsePhoneNumberFromString(
      typeof initialValue.current !== "string" ? "" : initialValue.current
    )
    setCountry({
      codeISO: formatted ? formatted.country : "",
      callingCode: formatted ? formatted.countryCallingCode : "",
    })
    setPhoneValue(formatted ? formatted.nationalNumber : "")
    //

    if (!initialValue.current || initialValue.current === "")
      savedOnInit("EMPTY")
    else if (initialValue && formatted === undefined) savedOnInit("INVALID")
  }, [savedGetCountries, savedOnInit, initialValue])

  // Formatted option template for country select
  const getCountriesOptions = () =>
    countries
      .map(({ code }) => code)
      .map(countryIso => ({
        value: countryIso,
        label: (
          <>
            <span
              className={cn([flagsStyle["flag-icon"]])}
              style={{
                backgroundImage: `url(https://www.countryflags.io/${countryIso.toLowerCase()}/flat/64.png)`,
              }}
            />
            <span className={style.country}>{upperCase(countryIso)}</span>
          </>
        ),
      }))

  const handleTextFieldBlur = e => {
    onBlur(`+${country.callingCode}${phoneValue}`)
  }

  const handleCountryChange = (e, option) => {
    const codeISO = option.value
    const callingCode = `${getCountryCallingCode(option.value)}`

    setCountry({ codeISO, callingCode })

    onBlur(`+${callingCode}${phoneValue}`)
  }

  return (
    <>
      {countries && countries.length ? (
        <div className={cn([style.inputGroup, className])}>
          <Select
            className={style.selectCountry}
            isSearchable
            value={country.codeISO}
            placeholder={selectPlaceholder}
            options={getCountriesOptions()}
            onChange={handleCountryChange}
            menuPortalTarget={document.body}
            isDisabled={disabled}
          />
          <span className={style.phoneCode}>
            {country && country.callingCode ? country.callingCode : null}
          </span>
          <TextField
            className={style.textField}
            type="tel"
            onBlur={handleTextFieldBlur}
            disabled={disabled}
            value={phoneValue}
            onChange={e => setPhoneValue(e.target.value)}
            {...propsLeft}
          />
        </div>
      ) : (
        <span>Chargement</span>
      )}
    </>
  )
}

PhoneField.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onInit: PropTypes.func,
  defaultCountry: PropTypes.string,
  defaultPhone: PropTypes.string,
  className: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
}

PhoneField.defaultProps = {
  onInit: () => {},
  defaultCountry: "",
  defaultPhone: "",
  className: null,
  selectPlaceholder: null,
  disabled: false,
}

export default PhoneField
