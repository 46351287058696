import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useDiscountsApi from "../../../hooks/Api/useDiscountsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./DiscountsList.module.css"

const permissions = [
  PERMISSIONS.PERM_DISCOUNTS_DISPLAY,
  PERMISSIONS.PERM_DISCOUNTS_UPDATE,
  PERMISSIONS.PERM_DISCOUNTS_CREATE,
]

const DiscountsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [
    discountsRequestState,
    discountsData,
    discountsError,
    { getDiscounts },
  ] = useDiscountsApi({
    trigger: true,
  })
  const savedGetDiscounts = useCallback(getDiscounts, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetDiscounts()
  }, [savedGetDiscounts])

  const discountsCollectionToArray = () =>
    discountsData && discountsData.discounts
      ? Object.keys(discountsData.discounts).map(
          i => discountsData.discounts[i]
        )
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Codes Promo</h1>
        {rolePermissions[PERMISSIONS.PERM_DISCOUNTS_CREATE] ? (
          <Link className="btn btn-success" to="/discounts/edit">
            Nouveau Code Promo
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {discountsError ? (
        <div className="alert alert-discountsError" role="alert">
          {discountsError}
        </div>
      ) : null}
      {discountsRequestState === "loading" && !discountsData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_DISCOUNTS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_DISCOUNTS_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Code ",
                  accessor: "code",
                },
                {
                  Header: "",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      },
                    },
                  }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit discount"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/discounts/edit/${id}`}
                      >
                        Éditer
                      </Link>
                    </div>
                  ),
                },
              ]}
              data={discountsCollectionToArray().filter(
                discount => !discount.oneshot
              )}
              hasPagination
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default DiscountsList
