// Hooks
import useApi from "./useApi"

// Utils & misc
import { COACH_HOSTNAME } from "../../constants/constants"
import { format, isDate } from "date-fns"

const useBookingsForCoachApi = () => {
  const [requestState, payload, error, request] = useApi();
  
  const getBookingsForCoach = (weekDateInput) => {
    if(!weekDateInput || !isDate(weekDateInput)) return;
    const weekCode = format(weekDateInput, 'yyyy-MM-dd');
    return request(
      'GET',
      `${COACH_HOSTNAME}/v1/bookings?weekCode=${weekCode}`
    );
  }

  const postBookingAssignment = ({weekSection, bookingId}) => request(
    'POST',
    `${COACH_HOSTNAME}/v1/bookings/${bookingId}:validate-week-section`,
    {
      weekSectionCode: weekSection
    }
  );

  const postSessionToGroup = (weekSectionGroup, sessionId) => request(
    'POST',
    `${COACH_HOSTNAME}/v1/sessions/${sessionId}:set-week-section-rotation-group`,
    {
      weekSectionRotationGroupCode: weekSectionGroup === 'reserve' ? null : weekSectionGroup
    }
  );

  const putGroupNameAndCoach = (weekSectionGroupId, {coachId, court, level}) => request(
    'PUT',
    `${COACH_HOSTNAME}/v1/week-section-rotation-groups/${weekSectionGroupId}`,
    {
      coachId,
      court,
      level
    }
  );

  const getBookingsCoachExportXls = (week, section) => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }

    const endpoint = section ? `${COACH_HOSTNAME}/v1/week-sections/${week}:${section}` : `${COACH_HOSTNAME}/v1/week-sections?weekCode=${week}`;

    return request(
      'GET',
      endpoint,
      null,
      {
        axiosOptions: config,
      }
    )
  }
  
  const getCoachExtrasExportXls = (week) => {
    if (!week || !isDate(week)) return;
    const weekCode = format(week, 'yyyy-MM-dd');

    const config = {
      responseType: "blob",
      headers: {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }

    return request(
      'GET',
      `${COACH_HOSTNAME}/v1/week-sections/extras?weekCode=${weekCode}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const putForcedPlayerRanking = ({ playerId, forcedPlayerRanking }) => request(
    'PUT',
    `${COACH_HOSTNAME}/v1/bookings/${playerId}/forced-player-ranking`,
    {
      forcedPlayerRanking,
    }
  );

  const postValidateBookingWeekSections = (week) => request(
    'POST',
    `${COACH_HOSTNAME}/v1/weeks/${week}:validate-booking-week-sections`,
  );

  const putScheduleIndividualCoaching = ({ bookingId, coachId, court }) => request(
    'PUT',
    `${COACH_HOSTNAME}/v1/bookings/${bookingId}/schedule-individual-coaching`,
    {
      "individualCoachingCoachId": coachId,
      "individualCoachingCourt": court
    }
  );

  const putScheduleIndividualCoachingWeekend = ({ bookingId, coachId, court }) => request(
    'PUT',
    `${COACH_HOSTNAME}/v1/bookings/${bookingId}/schedule-individual-coaching-we`,
    {
      "individualCoachingCoachIdWe": coachId,
      "individualCoachingCourtWe": court
    }
  );
  
  return [
    requestState,
    payload,
    error,
    {
      getBookingsForCoach,
      getBookingsCoachExportXls,
      postBookingAssignment,
      postSessionToGroup,
      putGroupNameAndCoach,
      putForcedPlayerRanking,
      postValidateBookingWeekSections,
      putScheduleIndividualCoaching,
      putScheduleIndividualCoachingWeekend,
      getCoachExtrasExportXls
    },
  ]
}

export default useBookingsForCoachApi
