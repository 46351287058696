import React, { useState } from "react"
import { useFormik } from "formik"
import { isEmpty } from "lodash"

import style from "./FidPointsSearch.module.css"

// Components
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"
import { TextInput } from "../../../../atoms/Inputs/Inputs"

// Hooks
import useAppContext from "../../../../../hooks/useAppContext"
import useTimeoutQueue from "../../../../../hooks/useTimeoutQueue"
import useFidClientsApi from "../../../../../hooks/Api/Fid/useFidClientsApi"

// Utils
import cn from "../../../../../utils/cn"

const SAVE_DELAY = 1000 // in ms

const FidPointsSearch = () => {
  const {
    setFidActiveMember,
    context: { fidEmployee },
  } = useAppContext()
  const [createMode, setCreateMode] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [searchLoading, setSearchLoading] = useState(false)
  const [clientsFound, setClientsFound] = useState([])
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [{ searchClient, createClient }] = useFidClientsApi()
  const savedOnSave = async searchString => {
    try {
      const searchResp = await searchClient(searchString)
      if (searchResp && searchResp.data && !isEmpty(searchResp.data)) {
        const clientIds = Object.keys(searchResp.data.keycloakUserInfos)
        if (clientIds.length !== 0) setClientsFound([])
        const clients = clientIds.map(id => {
          return searchResp.data.keycloakUserInfos[id]
        })
        setClientsFound(clients)
      }
      setSearchLoading(false)
    } catch (error) {
      console.error(error)
      setSearchLoading(false)
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: <span>Une erreur est survenue au moment de la recherche</span>,
        className: "alert alert-danger",
      })
    }
  }

  const launchSaveTimeout = useTimeoutQueue(savedOnSave, SAVE_DELAY)

  // SEARCH VIEW
  const handleNewClientClick = () => {
    setCreateMode(true)
  }

  const handleClientSearchChange = e => {
    setSearchLoading(true)
    setClientsFound([])
    setSearchValue(e.target.value)
    launchSaveTimeout(e.target.value || "")
  }

  const handleResetSearchClick = () => {
    setSearchValue("")
    setClientsFound([])
  }

  const handleClientSelect = client => {
    setFidActiveMember(client)
  }
  //

  // NEW CLIENT VIEW
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      localeId: 1,
    },
    onSubmit: async submitValues => {
      try {
        const createdMember = await createClient(submitValues)
        setFidActiveMember(createdMember.data)
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> Le client a été créé.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 500: {
              setGlobalMessage({
                ...globalMessage,
                isActive: true,
                message: <span>Une erreur est survenue (500)</span>,
                className: "alert alert-danger",
              })
              break
            }
            case 409: {
              setGlobalMessage({
                ...globalMessage,
                isActive: true,
                message: (
                  <span>Un membre avec cette adresse email existe déjà</span>
                ),
                className: "alert alert-danger",
              })
              break
            }
            case 400: {
              const errorsList =
                error.response.data && error.response.data.errors
                  ? Object.keys(error.response.data.errors).map(errorName => ({
                      field: errorName,
                      message: error.response.data.errors[errorName],
                    }))
                  : null
              setGlobalMessage({
                ...globalMessage,
                isActive: true,
                message: (
                  <ul className="m-0">
                    {errorsList
                      ? errorsList.map(err => (
                          <li key={`error_add_client_${err.field}`}>
                            <b>{err.field}: </b>
                            {err.message || "Erreur au niveau de ce champ"}
                          </li>
                        ))
                      : "Erreur au niveau du formulaire"}
                  </ul>
                ),
                className: "alert alert-danger",
              })
              break
            }
            default:
              break
          }
        }
      }
    },
  })
  //

  return (
    <div>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />
      {fidEmployee ? (
        <>
          <h3 className={style.header}>
            Infos Membre{createMode ? " / Nouveau Membre" : " : "}
          </h3>
          {!createMode ? (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm float-right"
                    disabled={false}
                    onClick={handleNewClientClick}
                  >
                    Nouveau client
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-md-12">
                  <TextInput
                    id="searchClient"
                    name="searchClient"
                    placeholder="Nom, Prénom ou Adresse email du client"
                    onChange={handleClientSearchChange}
                    value={searchValue}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-12 col-md-12">
                  <button
                    type="button"
                    className={cn([
                      "btn btn-primary float-right",
                      searchLoading ? "invisible" : "",
                    ])}
                    disabled={false}
                    onClick={handleResetSearchClick}
                  >
                    Réinitialiser
                  </button>
                </div>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-4 col-md-4">
                  <TextInput
                    id="lastName"
                    name="lastName"
                    label="Nom"
                    placeholder="Nom"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                </div>
                <div className="col-sm-4 col-md-4">
                  <TextInput
                    id="firstName"
                    name="firstName"
                    label="Prénom"
                    placeholder="Prénom"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                </div>
                <div className="col-sm-4 col-md-4">
                  <TextInput
                    id="email"
                    name="email"
                    label="Adresse email"
                    placeholder="Adresse email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-12 col-md-12">
                  <div className="float-right">
                    <button
                      type="button"
                      className={cn(["btn btn-secondary btn-sm mr-2"])}
                      disabled={false}
                      onClick={resetForm}
                    >
                      Réinitialiser
                    </button>
                    <button
                      type="button"
                      className={cn(["btn btn-secondary btn-sm mr-2"])}
                      disabled={false}
                      onClick={() => setCreateMode(false)}
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className={cn(["btn btn-primary btn-sm"])}
                      disabled={false}
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          {/* CLIENTS SEARCH RESULT LIST */}
          {clientsFound.length ? (
            <div className="mb-4 mt-4">
              <h3 className={style.header}>Résultats</h3>
              <div className="row">
                <div className="col-sm-12 col-md-12 pb-12">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Adresse email</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Prénom</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {clientsFound
                        .filter((client, i) => i < 6)
                        .map(client => {
                          return (
                            <tr key={`client_found_${client.email}`}>
                              <td>{client.email}</td>
                              <td>{client.lastName}</td>
                              <td>{client.firstName}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm float-right"
                                  disabled={false}
                                  onClick={() => handleClientSelect(client)}
                                >
                                  Sélectionner
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <>{searchLoading ? <span>Recherche en cours...</span> : null}</>
          )}
        </>
      ) : (
        <h4>Sélectionnez un employé</h4>
      )}
    </div>
  )
}
export default FidPointsSearch
