// Hooks
import useApi from "../useApi"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../../constants/constants"

const useOptinsApi = () => {
  const [requestState, payload, error, , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getOptins = () => {
    return rawRequest("GET", `${BASE_URL}/optins`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const updateOptins = optins => {
    return rawRequest("PUT", `${BASE_URL}/optins`, optins, {
      authKey: AUTHORIZATION,
    })
  }

  return [
    requestState,
    payload,
    error,
    {
      getOptins,
      updateOptins,
    },
  ]
}

export default useOptinsApi
