// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useLocalesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getLocales = () => {
    return request("GET", `${API_STAGES_URL}/v1/locales`)
  }

  return [
    requestState,
    payload,
    error,
    {
      getLocales,
    },
  ]
}

export default useLocalesApi
