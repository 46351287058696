import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import { parsePhoneNumberFromString } from "libphonenumber-js"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"
import PhoneField from "../../../../components/atoms/PhoneField"
import Archive from "../../../../components/molecules/Archive/Archive"
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"
import { SelectInput, TextInput } from "../../../../components/atoms/Inputs/Inputs"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import useClientsApi from "../../../../hooks/Api/useClientsApi"
import useCountriesApi from "../../../../hooks/Api/useCountriesApi"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"
import { genderOptions } from "../../../../utils/coach/staticValues"

// Utils & misc
import cn from "../../../../utils/cn"

import style from "./ClientsEdit.module.css"

const permissions = [
  PERMISSIONS.PERM_CLIENTS_CREATE,
  PERMISSIONS.PERM_CLIENTS_UPDATE,
  PERMISSIONS.PERM_CLIENTS_ARCHIVE,
  PERMISSIONS.PERM_CLIENTS_RESET_PASSWORD,
]

const VIEWS = {
  FORM: "FORM",
  ARCHIVE: "ARCHIVE",
}

const ClientsEdit = ({ match }) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [currentView, setCurrentView] = useState(VIEWS.FORM)
  const [successMessage, setSuccessMessage] = useState(null)
  const [formFieldsError, setFormFieldsError] = useState({ email: null })
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [clientsEmails, setClientsEmails] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  // Services
  const [, clientData, clientError, { getClientWithId }] = useClientsApi()
  const [, clientsData, , { getClients }] = useClientsApi()
  const [countryState, countriesData, , { getCountries }] = useCountriesApi()
  const [updateClientState,, updateError, { updateClient }] = useClientsApi()
  const [,,, { archiveClient }] = useClientsApi()
  const [newClientState,, newClientError, { createClient }] = useClientsApi()
  const [,,, { sendClientResetPassword }] = useClientsApi()
  const [putClientPlayerState,, putClientPlayerError, { putClientPlayerTennisData }] = useClientsApi()

  const handleErrorMessage = (errors) => {
    const error = errors && Object.values(errors)?.length ? Object.values(errors)[0] : null;
    if (error) {
      window.scrollTo(0,0);
      setErrorMessage(error);
    }
  }

  useEffect(() => {
    if (newClientError) handleErrorMessage(newClientError?.data?.errors);
    if (putClientPlayerError) handleErrorMessage(putClientPlayerError?.data?.errors);
    if (clientError) handleErrorMessage(clientError?.data?.errors);
    if (updateError) handleErrorMessage(updateError?.data?.errors);

    if (!newClientError && !putClientPlayerError && !clientError && !updateError) {
      setErrorMessage(null);
    }
  }, [newClientError, putClientPlayerError, clientError, updateError ]);

  const history = useHistory()

  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetClients = useCallback(getClients, [])
  const savedGetCountries = useCallback(getCountries, [])

  const isEditingClient = !isEmpty(match.params)
  const newClient = {
    localeId: 1,
    defaultBillingAddress: {
      address: "",
      zipCode: "",
      city: "",
      country: "",
    },
    email: "",
    sexId: null,
    firstname: "",
    lastname: "",
    phone: "",
    nationalityId: "",
    nationalityCode: "",
    birthdate: "",
    preventSendWelcomeEmail: false,
    needPlayerInformations: false,
    tennis: {
      startYear: '',
      weeklyHours: '',
      yearlyMatches: '',
    }
  }

  useEffect(() => {
    if (isEditingClient) savedGetClientWithId(match.params.id)
    savedGetClients({ archived: false })
    savedGetCountries()
  }, [
    isEditingClient,
    savedGetClientWithId,
    match.params.id,
    savedGetCountries,
    savedGetClients,
  ])

  useEffect(() => {
    if (clientsData) {
      setClientsEmails(
        Object.keys(clientsData.clients).map(
          clientId => clientsData.clients[clientId].email
        )
      )
    }
    return () => {}
  }, [clientsData])

  const checkFormErrors = () => {
    let error = false

    for (const [, value] of Object.entries(formFieldsError)) {
      if (value) error = true
      break
    }

    return error
  }

  const checkFieldError = field => {
    for (const [f, value] of Object.entries(formFieldsError)) {
      if (f === field) return !!value
    }
    return false
  }

  const handleEmailChange = (e, handleChange) => {
    if (clientsEmails && clientData) {
      const emailFound = clientsEmails.find(email => email === e.target.value)
      if (emailFound) {
        if (!isEditingClient || !(clientData.email === e.target.value))
          setFormFieldsError({
            ...formFieldsError,
            email: "Un client avec le même email existe déja",
          })
      } else
        setFormFieldsError({
          ...formFieldsError,
          email: null,
        })
    }
    handleChange(e)
  }

  const handleResetPasswordClick = async () => {
    setSuccessMessage(null)
    try {
      await sendClientResetPassword(match.params.id)
      setSuccessMessage(
        "L'email de réinitialisation du mot de passe a bien été envoyé"
      )
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const handleSubmitClient = values => {
    const parsedNumber = parsePhoneNumberFromString(values.phone || "")
    if (!parsedNumber) {
      setErrorMessage("Le numéro de téléphone est invalide")
      window.scrollTo(0,0)
      return
    }

    const customValues = {
      ...values,
      localeId: parseInt(values.localeId, 10),
      phone: parsedNumber.number || "",
      // TODO: remove this when archive feature is implemented
      archived: false,
      sexId: isEditingClient ? 1 : parseInt(values.sexId)
    }

    if (!customValues.localeId) delete customValues.localeId;

    if (isEditingClient) {
      updateClient(customValues).then(() =>
        history.push({
          pathname: "/clients",
          state: { submitType: "edit", success: true },
        })
      )
    }
    if (!isEditingClient) {
      createClient(customValues).then((res) => {
        const clientCreated = res?.data;

        if (clientCreated) {
          if (values.needPlayerInformations) {
            putClientPlayerTennisData(clientCreated.id, clientCreated.properPlayerId, values.tennis).then(() => {
              history.push({
                pathname: "/clients",
                state: { submitType: "create", success: true },
              })
            })
          } else {
            history.push({
              pathname: "/clients",
              state: { submitType: "create", success: false },
            })
          }
        }
      })
    }
  }

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: isEditingClient && clientData ? { ...clientData } : newClient,
    onSubmit: handleSubmitClient,
  })

  const handleClientArchive = async () => {
    // const parsedNumber = parsePhoneNumberFromString(clientData?.phone || "") // unused
    // if (!parsedNumber) {
    //   setErrorMessage("Le numéro de téléphone est invalide")
    //   return
    // }

    const customValues = {
      ...(clientData || {}),
      archived: true,
    }

    delete customValues.localeId

    try {
      await archiveClient(match.params.id)
      history.push({
        pathname: "/clients",
        state: { submitType: "archive", success: true },
      })
    } catch (e) {
      if (e.response) {
        const { errors } = e?.response?.data
        setCurrentView(VIEWS.FORM)
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message:
            errors && errors.length ? (
              <>
                Une erreur est survenue pendant l'archivage:
                <ul className="mb-0">
                  {errors.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </>
            ) : (
              "Une erreur est survenue pendant l'archivage"
            ),
          className: "alert alert-danger",
        })
        window.scrollTo(0, 0)
      }
    }
  }

  const backToList = () => {
    history.push({
      pathname: "/clients",
      state: {},
    })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_CLIENTS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_CLIENTS_CREATE] && !isEditingClient)

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          {`Clients / ${isEditingClient ? "Éditer" : "Nouveau"}`}
          {clientData
            ? ` / ${clientData.firstname} ${clientData.lastname}`
            : null}
        </h1>
        {isEditingClient && clientData && (
          <ClientNavigation client={{ ...(clientData || {}), uid: match.params.id }} />
        )}
      </div>
      {currentView === VIEWS.FORM ? (
        <>
          <GlobalMessage
            isActive={globalMessage.isActive}
            content={globalMessage.message}
            className={globalMessage.className}
            onClose={() =>
              setGlobalMessage({ ...globalMessage, isActive: false })
            }
          />
          {clientsEmails && countriesData && countryState === "completed" ? (
            isEditingClient && !clientData ? (
              <div>
                <p>Une erreur s'est produite lors de la récupération des informations de ce client</p>
                <a href="/clients">
                  <button className="btn btn-primary" type="button">Retour à la liste</button>
                </a>
              </div>
            ) : (
              <>
                <form onSubmit={handleSubmit} className="pb-4">
                  {errorMessage ? (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  ) : null}
                  {successMessage ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : null}
                  <div className={cn(["card", style.formSpacing])}>
                    <div className="card-body">
                      <h5 className="card-title">Identité</h5>

                      <div className="form-row mt-2">
                        <div className={cn(['col-sm-12', isEditingClient ? 'col-md-6' : 'col-md-4'])}>
                          <b>
                            <label htmlFor="clients-firstname">Prénom*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="clients-firstname"
                            name="firstname"
                            htmlFor="fname"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                        <div className={cn(['col-sm-12', isEditingClient ? 'col-md-6' : 'col-md-4'])}>
                          <b>
                            <label htmlFor="clients-lastname">Nom*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="clients-lastname"
                            name="lastname"
                            htmlFor="lname"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>

                        {!isEditingClient ? (
                          <div className="col-sm-12 col-md-4">
                            <SelectInput
                              disabled={!isAllowedToEdit}
                              label="Genre*"
                              id="clients-sexId"
                              name="sexId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.sexId}
                              options={[
                                <option key="default" value="" disabled>
                                  Genre
                                </option>,
                                genderOptions.map((gender) => (
                                  <option key={`option_${gender.value}`} value={gender.value}>
                                    {gender.label}
                                  </option>
                                )),
                              ]}
                            />
                          </div>
                        ) : null}
                      </div>

                      <div className="form-row mt-3">
                        <div className="col-sm-12 col-md-6">
                          <b>
                            <label htmlFor="clients-email">E-mail*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="email"
                            className="form-control"
                            id="clients-email"
                            name="email"
                            value={values.email}
                            onChange={e => handleEmailChange(e, handleChange)}
                            onBlur={handleBlur}
                            required
                          />
                          {checkFieldError("email") ? (
                            <div className={style.fieldError}>
                              {formFieldsError.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <b>
                            <label htmlFor="clients-phone">Téléphone*</label>
                          </b>
                          <PhoneField
                            disabled={!isAllowedToEdit}
                            id="phone"
                            name="phone"
                            label=""
                            selectPlaceholder="..."
                            value={values.phone}
                            error={null}
                            onBlur={number => setFieldValue("phone", number)}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      </div>

                      <div className="form-row mt-3">
                        <div
                          className={`col-sm-12 ${
                            !isEditingClient ? "col-md-4" : "col-md-6"
                          }`}
                        >
                          <b>
                            <label htmlFor="clients-birthdate">
                              Date de naissance*
                            </label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="date"
                            className="form-control"
                            id="clients-birthdate"
                            name="birthdate"
                            value={values.birthdate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                        <div
                          className={`col-sm-12 ${
                            !isEditingClient ? "col-md-4" : "col-md-6"
                          }`}
                        >
                          <b>
                            <label htmlFor="clients-nationality">
                              Nationalité*
                            </label>
                          </b>
                          <select
                            disabled={!isAllowedToEdit}
                            className="custom-select"
                            id="clients-nationality"
                            name="nationalityCode"
                            required
                            onChange={e => {
                              setFieldValue("nationalityId", Number(e.target.value))
                              setFieldValue(
                                "nationalityCode",
                                countriesData.countries[e.target.value].code
                              )
                            }}
                            onBlur={handleBlur}
                            value={values.nationalityId}
                          >
                            <option disabled value="">
                              Choisir une nationalité...
                            </option>
                            {Object.keys(countriesData.countries).map(
                              countryISO => (
                                <option key={countryISO} value={countryISO}>
                                  {countriesData.countries[countryISO].name[1]}
                                </option>
                              )
                            )}
                          </select>
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                        
                        <div className={cn(['col-sm-12 col-md-4', isEditingClient ? 'mt-3' : ''])}>
                          <b>
                            <label htmlFor="clients-locale">
                              Langue de communication*
                            </label>
                          </b>

                          <select
                            disabled={!isAllowedToEdit}
                            className="custom-select"
                            id="clients-locale"
                            name="localeId"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.localeId || undefined}
                          >
                            <option disabled value="">
                              Choisir une locale...
                            </option>
                            {locales
                              ? Object.keys(locales).map(loc => (
                                  <option key={loc} value={loc}>
                                    {locales[loc].code}
                                  </option>
                                ))
                              : null}
                          </select>
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={cn(["card", style.formSpacing])}>
                    <div className="card-body">
                      <h5 className="card-title">Adresse de facturation</h5>

                      <div className="form-row mt-2">
                        <div className="col-sm-12">
                          <b>
                            <label htmlFor="clients-address">Adresse*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            className="form-control"
                            id="clients-address"
                            name="defaultBillingAddress.address"
                            value={values.defaultBillingAddress?.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      </div>

                      <div className="form-row mt-3">
                        <div className="col-sm-12 col-md-6">
                          <b>
                            <label htmlFor="clients-zipCode">Code postal*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            className="form-control"
                            id="clients-zipCode"
                            name="defaultBillingAddress.zipCode"
                            value={values.defaultBillingAddress?.zipCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <b>
                            <label htmlFor="clients-city">Ville*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            className="form-control"
                            id="clients-city"
                            name="defaultBillingAddress.city"
                            value={values.defaultBillingAddress?.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      </div>

                      <div className="form-row mt-3">
                        <div className="col-sm-12">
                          <b>
                            <label htmlFor="clients-country">Pays*</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            type="text"
                            className="form-control"
                            id="clients-country"
                            name="defaultBillingAddress.country"
                            value={values.defaultBillingAddress?.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      </div>

                      {!isEditingClient ? (
                        <div
                          className="form-row mt-2"
                          style={{ marginLeft: "1rem" }}
                        >
                          <div className="col-sm-12">
                            <input
                              disabled={!isAllowedToEdit}
                              type="checkbox"
                              className="form-check-input"
                              id="preventSendWelcomeEmail"
                              name="preventSendWelcomeEmail"
                              checked={!!values.preventSendWelcomeEmail}
                              onChange={e =>
                                setFieldValue(
                                  "preventSendWelcomeEmail",
                                  !values.preventSendWelcomeEmail
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preventSendWelcomeEmail"
                            >
                              Prevent send welcome email
                            </label>
                            <div className="valid-tooltip">Looks good!</div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {!isEditingClient ? (
                    <>
                      {/* REQUIRED TENNIS INFORMATIONS */}
                      <div className={cn(["card", style.formSpacing])}>
                        <div className="card-body">
                          <h5 className="card-title">Informations du joueur associé à ce client</h5>

                          <div className="form-row mt-3" style={{ marginLeft: "1rem" }}>
                            <div className="col-sm-12">
                              <input
                                disabled={!isAllowedToEdit}
                                type="checkbox"
                                className="form-check-input"
                                id="needPlayerInformations"
                                name="needPlayerInformations"
                                checked={!!values.needPlayerInformations}
                                onChange={e =>
                                  setFieldValue(
                                    "needPlayerInformations",
                                    !values.needPlayerInformations
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="needPlayerInformations"
                              >
                                Je souhaite attribuer un stage à ce client
                              </label>
                            </div>
                          </div>

                          <div className="form-row mt-2">
                            {values.needPlayerInformations ? (
                              <div className="col-sm-12">
                                {/* YEARS */}
                                <div className="mt-3">
                                  <TextInput
                                    disabled={!isAllowedToEdit}
                                    type="number"
                                    label={`En quelle année le joueur a-t-il commencé la pratique ? *`}
                                    id="tennis_startYear"
                                    name="startYear"
                                    value={values.tennis.startYear}
                                    onChange={e => setFieldValue('tennis.startYear', Number(e.target.value))}
                                    onBlur={handleBlur}
                                    required={values.needPlayerInformations}
                                  />
                                </div>

                                {/* HOURS PER WEEK */}
                                <div className="mt-3">
                                  <TextInput
                                    disabled={!isAllowedToEdit}
                                    type="number"
                                    label={`Combien d'heures le joueur joue-t-il par semaine ? *`}
                                    id="tennis_weeklyHours"
                                    name="weeklyHours"
                                    value={values.tennis.weeklyHours}
                                    onChange={e => setFieldValue('tennis.weeklyHours', Number(e.target.value))}
                                    onBlur={handleBlur}
                                    required={values.needPlayerInformations}
                                  />
                                </div>

                                {/* PARTICIPATIONS PER YEAR */}
                                <div className="mt-3">
                                  <TextInput
                                    disabled={!isAllowedToEdit}
                                    type="number"
                                    label={`À combien de matchs le joueur participe-t-il par an ? *`}
                                    id="tennis_yearlyMatches"
                                    name="yearlyMatches"
                                    value={values.tennis.yearlyMatches}
                                    onChange={e => setFieldValue('tennis.yearlyMatches', Number(e.target.value))}
                                    onBlur={handleBlur}
                                    required={values.needPlayerInformations}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {rolePermissions[PERMISSIONS.PERM_CLIENTS_ARCHIVE] &&
                  isEditingClient ? (
                    <div className="float-right mb-4">
                      {clientData?.balance !== 0 ? (
                        <span className="mr-3 text-secondary">
                          {clientData?.balance !== 0
                            ? "Le client n'a pas une balance de 0"
                            : "Le client dispose d'un stage dans le futur"}
                        </span>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => setCurrentView(VIEWS.ARCHIVE)}
                        className="btn btn-danger"
                        disabled={clientData?.balance !== 0}
                      >
                        ARCHIVER
                      </button>
                    </div>
                  ) : null}

                  {!isAllowedToEdit ? (
                    <button
                      onClick={backToList}
                      type="button"
                      className="btn btn-secondary mr-2"
                    >
                      Revenir à la liste
                    </button>
                  ) : null}
                  <button
                    type="submit"
                    className={cn(["btn btn-success"])}
                    disabled={
                      updateClientState === "loading" ||
                      newClientState === "loading" ||
                      putClientPlayerState === "loading" ||
                      !isAllowedToEdit ||
                      checkFormErrors()
                    }
                  >
                    {updateClientState === "loading" ||
                    newClientState === "loading" ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    VALIDER
                  </button>
                  {rolePermissions[PERMISSIONS.PERM_CLIENTS_RESET_PASSWORD] &&
                  isEditingClient ? (
                    <button
                      onClick={handleResetPasswordClick}
                      type="button"
                      className={cn([
                        "btn btn-secondary",
                        "ml-3",
                        style.editSection,
                      ])}
                    >
                      Réinitialisation MDP
                    </button>
                  ) : null}
                </form>
              </>
            )
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </>
      ) : null}
      {currentView === VIEWS.ARCHIVE && clientData ? (
        <div className={style.formWidth}>
          <Archive
            onArchivedClick={() => handleClientArchive()}
            onCancelClick={() => setCurrentView(VIEWS.FORM)}
          >
            <div className={style.archiveText}>
              {`Etes-vous sûr de vouloir archiver le client ${clientData.firstname} ${clientData.lastname} ?`}
            </div>
          </Archive>
        </div>
      ) : null}
    </WithMenu>
  )
}

ClientsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ClientsEdit
