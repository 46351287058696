import React from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"

// Utils & Misc
import cn from "../../../utils/cn"

export const Select = ({ label, id, className, error, ...propsLeft }) => (
  <>
    <label htmlFor={id} className={cn([className, error ? "error" : ""])}>
      {label ? (
        <span>
          {label}
          {error ? ` - ${error}` : null}
        </span>
      ) : null}
    </label>
    <ReactSelect {...propsLeft} />
  </>
)

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
}

Select.defaultProps = {
  label: null,
  id: null,
  className: null,
  error: null,
}

export default Select
