/* eslint-disable import/prefer-default-export */

export const ENV = process.env.REACT_APP_ENV
export const SPA_HOSTNAME = process.env.REACT_APP_SPA_HOSTNAME
export const SE_HOSTNAME = process.env.REACT_APP_SE_HOSTNAME
export const FID_HOSTNAME = process.env.REACT_APP_FID_HOSTNAME
export const AUTH_HOSTNAME = process.env.REACT_APP_AUTH_HOSTNAME
export const COACH_HOSTNAME = process.env.REACT_APP_API_COACH_HOSTNAME
export const AUTHORIZATION_HOSTNAME = process.env.REACT_APP_AUTHORIZATION_HOSTNAME
export const API_STAGES_URL = `${process.env.REACT_APP_API_STAGES_URL}`
export const API_SE_URL = process.env.REACT_APP_API_SE_URL
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
export const STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC

export const AUTH_REDIRECT = `https://${SPA_HOSTNAME}/login`

export const RANGE_TAB_YEARS = 2

export const ORDER_STATUS = {
  CART: "CART",
  VALIDATED: "VALIDATED",
}

export const PERMISSIONS = {
  // BOOKING
  PERM_ADMINS_DISPLAY: "pAdDi",
  PERM_ADMINS_CREATE: "pAdCr",
  PERM_ADMINS_UPDATE: "pAdUp",
  PERM_ADMINS_ARCHIVE: "pAdAr",
  PERM_ROLES_CREATE: "pRoCr",
  PERM_ROLES_UPDATE: "pRoUp",
  PERM_ROLES_ARCHIVE: "pRoAr",
  PERM_ROLES_DISPLAY: "pRoDi",
  PERM_SECTIONS_ASSIGNMENTS_CREATE: "pCoAd",
  PERM_SECTIONS_ASSIGNMENTS_UPDATE: "pCoAd",
  PERM_SECTIONS_ASSIGNMENTS_ARCHIVE: "pCoAd",
  PERM_SECTIONS_ASSIGNMENTS_DISPLAY: "pCoAd",
  PERM_SECTIONS_LISTING_CREATE: "pCoAd",
  PERM_SECTIONS_LISTING_UPDATE: "pCoAd",
  PERM_SECTIONS_LISTING_ARCHIVE: "pCoAd",
  PERM_SECTIONS_LISTING_DISPLAY: "pCoAd",
  PERM_SECTIONS_GROUPS_CREATE: "pCoAd",
  PERM_SECTIONS_GROUPS_UPDATE: "pCoAd",
  PERM_SECTIONS_GROUPS_ARCHIVE: "pCoAd",
  PERM_SECTIONS_GROUPS_DISPLAY: "pCoAd",
  PERM_REPORTS_HEAD_COACH: "pHeCo",
  PERM_REPORTS_FOLLOW_UP: "pReFo",
  PERM_DOWNLOAD_REPORTS: "pDlRe",
  PERM_PACKAGES_CREATE: "pPaCr",
  PERM_PACKAGES_UPDATE: "pPaUp",
  PERM_PACKAGES_ARCHIVE: "pPaAr",
  PERM_PACKAGES_DISPLAY: "pPaDi",
  PERM_TAGS_CREATE: "pTaCr",
  PERM_TAGS_UPDATE: "pTaUp",
  PERM_TAGS_DISPLAY: "pTaDi",
  PERM_MEDIAS_CREATE: "pMeCr",
  PERM_MEDIAS_UPDATE: "pMeUp",
  PERM_MEDIAS_DISPLAY: "pMeDi",
  PERM_SEASONS_UPDATE: "pSeUp",
  PERM_SEASONS_DISPLAY: "pSeDi",
  PERM_CAMPS_CREATE: "pCaCr",
  PERM_CAMPS_UPDATE: "pCaUp",
  PERM_CAMPS_ARCHIVE: "pCaAr",
  PERM_CAMPS_DISPLAY: "pCaDi",
  PERM_CAMP_PRICES_UPDATE: "pCaPrUp",
  PERM_EXTRAS_CREATE: "pExCr",
  PERM_EXTRAS_UPDATE: "pExUp",
  PERM_EXTRAS_ARCHIVE: "pExAr",
  PERM_EXTRAS_DISPLAY: "pExDi",
  PERM_EXTRA_PRICES_UPDATE: "pExPrUp",
  PERM_ACCOMMODATIONS_CREATE: "pAcCr",
  PERM_ACCOMMODATIONS_UPDATE: "pAcUp",
  PERM_ACCOMMODATIONS_ARCHIVE: "pAcAr",
  PERM_ACCOMMODATIONS_DISPLAY: "pAcDi",
  PERM_ACCOMMODATION_PRICES_UPDATE: "pAcPrUp",
  PERM_EXPORT_LOGS_DISPLAY: "pExLoDi",
  PERM_CONFIG_FRONT_SNIPPETS_UPDATE: "pCoFrSnUp",
  PERM_CONFIG_FRONT_SNIPPETS_DISPLAY: "pCoFrSnDi",
  PERM_CONFIG_HEAD_SNIPPET_UPDATE: "pCoHeSnUp",
  PERM_CONFIG_HEAD_SNIPPET_DISPLAY: "pCoHeSnDi",
  PERM_CONFIG_POPIN_UPDATE: "pCoPoUp",
  PERM_CONFIG_POPIN_DISPLAY: "pCoPoDi",
  PERM_CONFIG_PAYMENT_DELAY_MAX_UPDATE: "pCoPaDeMaUp",
  PERM_CONFIG_PAYMENT_DELAY_MAX_DISPLAY: "pCoPaDeMaDi",
  PERM_LOCALES_UPDATE: "pLoUp",
  PERM_LOCALES_DISPLAY: "pLoDi",
  PERM_ATTACHMENTS_CREATE: "pAtCr",
  PERM_ATTACHMENTS_UPDATE: "pAtUp",
  PERM_ATTACHMENTS_DISPLAY: "pAtDi",
  PERM_CLIENTS_CREATE: "pClCr",
  PERM_CLIENTS_UPDATE: "pClUp",
  PERM_CLIENTS_ARCHIVE: "pClAr",
  PERM_CLIENTS_RESET_PASSWORD: "pClRePa",
  PERM_CLIENTS_DISPLAY: "pClDi",
  PERM_PLAYERS_CREATE: "pPlCr",
  PERM_PLAYERS_UPDATE: "pPlUp",
  PERM_PLAYERS_DISPLAY: "pPlDi",
  PERM_PLAYERS_DISPLAY_BORROWERS: "pPlDiBo",
  PERM_PLAYERS_DELETE_BORROWER: "pPlDeBo",
  PERM_PLAYERS_SEND_LINK_EMAIL: "pPlSeLiEm",
  PERM_INVOICES_DOWNLOAD: "pInDo",
  PERM_ORDERS_COMMENT: "pOrCo",
  PERM_ORDERS_RESOLVE: "pOrRe",
  PERM_ORDERS_DISPLAY: "pOrDi",
  PERM_ORDERS_ARTICLES_CREATE: "pOrArCr",
  PERM_ORDERS_ARTICLES_UPDATE: "pOrArUp",
  PERM_ORDERS_ARTICLES_DISPLAY: "pOrArDi",
  PERM_ORDERS_BALANCE_UPDATE: "pOrBa",
  PERM_SHUTTLES_UPDATE: "pShUp",
  PERM_SHUTTLES_DISPLAY: "pShDi",
  PERM_DISCOUNTS_CREATE: "pDiCr",
  PERM_DISCOUNTS_UPDATE: "pDiUp",
  PERM_DISCOUNTS_ARCHIVE: "pDiAr",
  PERM_DISCOUNTS_DISPLAY: "pDiDi",
  PERM_BOOKINGS_EXPORT_WEEK_DISPLAY: "pBoExWeDi",
  PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD: "pBoExWeDo",
  PERM_STOCKS_CAMPS_UPDATE: "pStCaUp",
  PERM_STOCKS_CAMPS_EXPORT: "pStCaEx",
  PERM_STOCKS_CAMPS_DISPLAY: "pStCaDi",
  PERM_STOCKS_EXTRAS_UPDATE: "pStExUp",
  PERM_STOCKS_EXTRAS_EXPORT: "pStExEx",
  PERM_STOCKS_EXTRAS_DISPLAY: "pStExDi",
  PERM_STOCKS_ACCOMMODATIONS_OTHER_UPDATE: "pStAcOtUp",
  PERM_STOCKS_ACCOMMODATIONS_OTHER_EXPORT: "pStAcOtEx",
  PERM_STOCKS_ACCOMMODATIONS_OTHER_DISPLAY: "pStAcOtDi",
  PERM_STOCKS_ACCOMMODATIONS_BOARDING_UPDATE: "pStAcBoUp",
  PERM_STOCKS_ACCOMMODATIONS_BOARDING_EXPORT: "pStAcBoEx",
  PERM_STOCKS_ACCOMMODATIONS_BOARDING_DISPLAY: "pStAcBoDi",
  PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_UPDATE: "pStAcReUp",
  PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_EXPORT: "pStAcReEx",
  PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_DISPLAY: "pStAcReDi",
  PERM_STOCKS_ACCOMMODATIONS_HOTEL_UPDATE: "pStAcHoUp",
  PERM_STOCKS_ACCOMMODATIONS_HOTEL_EXPORT: "pStAcHoEx",
  PERM_STOCKS_ACCOMMODATIONS_HOTEL_DISPLAY: "pStAcHoDi",
  PERM_YIELDS_CAMPS_UPDATE: "pYiCaUp",
  PERM_YIELDS_CAMPS_EXPORT: "pYiCaEx",
  PERM_YIELDS_CAMPS_DISPLAY: "pYiCaDi",
  PERM_YIELDS_EXTRAS_UPDATE: "pYiExUp",
  PERM_YIELDS_EXTRAS_EXPORT: "pYiExEx",
  PERM_YIELDS_EXTRAS_DISPLAY: "pYiExDi",
  PERM_YIELDS_ACCOMMODATIONS_OTHER_UPDATE: "pYiAcOtUp",
  PERM_YIELDS_ACCOMMODATIONS_OTHER_EXPORT: "pYiAcOtEx",
  PERM_YIELDS_ACCOMMODATIONS_OTHER_DISPLAY: "pYiAcOtDi",
  PERM_YIELDS_ACCOMMODATIONS_BOARDING_UPDATE: "pYiAcBoUp",
  PERM_YIELDS_ACCOMMODATIONS_BOARDING_EXPORT: "pYiAcBoEx",
  PERM_YIELDS_ACCOMMODATIONS_BOARDING_DISPLAY: "pYiAcBoDi",
  PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_UPDATE: "pYiAcReUp",
  PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_EXPORT: "pYiAcReEx",
  PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_DISPLAY: "pYiAcReDi",
  PERM_YIELDS_ACCOMMODATIONS_HOTEL_UPDATE: "pYiAcHoUp",
  PERM_YIELDS_ACCOMMODATIONS_HOTEL_EXPORT: "pYiAcHoEx",
  PERM_YIELDS_ACCOMMODATIONS_HOTEL_DISPLAY: "pYiAcHoDi",
  PERM_YIELDS_PACKAGES_DISPLAY: "pYiPaDi",
  PERM_INVOICES_EXPORT_PDF: "pInExPd",
  PERM_CREDIT_NOTES_EXPORT_PDF: "pCrNoExPd",
  PERM_INVOICES_EXPORT_CSV: "pInExCs",
  PERM_CREDITS_NOTES_EXPORT_CSV: "pCrNoExCs",
  PERM_PAYMENTS_EXPORT: "pPaEx",
  PERM_BOOKINGS_EXPORT_FULL: "pBoExFu",
  PERM_BOOKINGS_EXPORT_ANONYMOUS: "pBoExAn",
  PERM_BOOKINGS_EXPORT_COMMERCIAL: "pBoExCo",
  PERM_BOOKINGS_EXPORT_GEOLOCALIZED: "pBoExGe",
  PERM_BOOKINGS_EXPORT_GEOLOCALIZED_ANONYMOUS: "pBoExGeAn",
  PERM_CARTS_EXPORT_WITH_CLIENTS_DATA: "pCaExWiClDa",
  PERM_CARTS_EXPORT: "pCaEx",
  PERM_ORDERS_EXPORT: "pOrEx",
  PERM_ORDERS_PAYMENTS_MEANS_EXPORT: "pOrPaMeEx",
  PERM_TRANSLATIONS_UPDATE: "pTrUp",
  PERM_TRANSLATIONS_DISPLAY: "pTrDi",
  PERM_STUDENTS_CREATE: "pStCr",
  PERM_STUDENTS_UPDATE: "pStUp",
  PERM_STUDENTS_DISPLAY: "pStDi",
  PERM_OPTINS_UPDATE: "pOtUp",
  PERM_SALES_SHEET_EXPORT: "pSaShEx",
  PERM_EVENTS_CREATE: "pEvCr",
  PERM_EVENTS_UPDATE: "pEvUp",
  PERM_THEMES_CREATE: "pThCr",
  PERM_THEMES_UPDATE: "pThUp",
  PERM_THEMES_DELETE: "pThDe",
  PERM_YIELD_INCOME_EXPORT: "pYiInEx",
  PERM_QUESTIONS_CREATE: "pQeCr",
  PERM_QUESTIONS_UPDATE: "pQeUp",
  PERM_QUESTIONS_DELETE: "pQeDe",
  PERM_TESTIMONIES_CREATE: "pTeCr",
  PERM_TESTIMONIES_UPDATE: "pTeUp",
  PERM_TESTIMONIES_DELETE: "pTeDe",

  // SE
  PERM_PRODUCTS_CREATE: "pPrCr",
  PERM_PRODUCTS_UPDATE: "pPrUp",
  PERM_PRODUCTS_ARCHIVE: "pPrAr",
  PERM_OPTIONS_CREATE: "pOpCr",
  PERM_OPTIONS_UPDATE: "pOpUp",
  PERM_OPTIONS_ARCHIVE: "pOpAr",
  PERM_PRODUCTS_OPTIONS_DISPLAY: "pPrOpDi",
  PERM_CAMPUS_LOCALES_UPDATE: "pCaLoUp",
  PERM_CAMPUS_LOCALES_DISPLAY: "pCaLoDi",
  PERM_FORMS_EXPORT: "pFoEx",
  PERM_CAMPUS_CONFIG_DISPLAY: "pCaCoDi",
  PERM_CAMPUS_CONFIG_UPDATE: "pCaCoUp",
  PERM_CAMPUS_STOCK_DISPLAY: "pCaStDi",
  PERM_CAMPUS_STOCK_UPDATE: "pCaStUp",
  PERM_CAMPUS_FORMS_DISPLAY: "pCaFoDi",
  PERM_CAMPUS_FORMS_UPDATE: "pCaFoUp",

  // COACH
  PERM_REPORTS_EVALUATIONS_DOWNLOAD: "pReEvDl",

  // FID
  PERM_EMPLOYEE_FULL_ACCESS: "pEmFuAc",
  PERM_ADMIN_FULL_ACCESS: "pAdFuAc",

  // ALL
  PERM_FULL_ACCESS: "perm_full_access",
}

// TODO: remove fields permission and role once transition is done
// field permissions: will check for at least one permission on the user object
// as opposed to the routes config, that will contain a list of perm that the user NEEDS to have
export const MENU_ENTRIES = [
  {
    i18nKey: "Administrateurs",
    path: "/admins",
    // permission: "MANAGE_PACKAGES",
    // role: ["SUPER_ADMIN", "ADMIN"],
    // TODO: no read-only, ask
    permissions: [
      PERMISSIONS.PERM_ADMINS_DISPLAY,
      PERMISSIONS.PERM_ROLES_ARCHIVE,
      PERMISSIONS.PERM_ADMINS_CREATE,
      PERMISSIONS.PERM_ADMINS_UPDATE,
    ],
    icon: "fas fa-user-tie",
  },
  {
    i18nKey: "Roles",
    path: "/roles",
    permissions: [
      PERMISSIONS.PERM_ROLES_DISPLAY,
      PERMISSIONS.PERM_ROLES_ARCHIVE,
      PERMISSIONS.PERM_ROLES_CREATE,
      PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE,
    ],
    icon: "fas fa-user-tag",
  },
  {
    i18nKey: "Sections",
    path: "/sections",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_CREATE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_UPDATE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_DISPLAY,
        ],
        path: "/sections/assignments",
        i18nKey: "assignmentSection",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_SECTIONS_LISTING_CREATE,
          PERMISSIONS.PERM_SECTIONS_LISTING_UPDATE,
          PERMISSIONS.PERM_SECTIONS_LISTING_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_LISTING_DISPLAY,
        ],
        path: "/sections/listing",
        i18nKey: "listingSections",
      },
      3: {
        permissions: [
          PERMISSIONS.PERM_SECTIONS_GROUPS_CREATE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_UPDATE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_DISPLAY,
        ],
        path: "/sections/groups",
        i18nKey: "sectionsGroups",
      },
      4: {
        permissions: [
          PERMISSIONS.PERM_SECTIONS_LISTING_CREATE,
          PERMISSIONS.PERM_SECTIONS_LISTING_UPDATE,
          PERMISSIONS.PERM_SECTIONS_LISTING_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_LISTING_DISPLAY,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_CREATE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_UPDATE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_ASSIGNMENTS_DISPLAY,
          PERMISSIONS.PERM_SECTIONS_GROUPS_CREATE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_UPDATE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_ARCHIVE,
          PERMISSIONS.PERM_SECTIONS_GROUPS_DISPLAY,
        ],
        path: "/sections/extras-groups",
        i18nKey: "extrasSectionsGroups",
      },
    },
    icon: "fas fa-users",
  },
  {
    i18nKey: "Rapports",
    path: "/reports",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_REPORTS_HEAD_COACH,
        ],
        path: "/reports/head-coach",
        i18nKey: "reportsHeadCoach",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_REPORTS_FOLLOW_UP,
        ],
        path: "/reports/coach",
        i18nKey: "reportsFollowUp",
      },
      3: {
        permissions: [
          PERMISSIONS.PERM_DOWNLOAD_REPORTS,
        ],
        path: "/reports/list",
        i18nKey: "reportsManagement",
      },
    },
    icon: "fas fa-folder-open",
  },
  {
    i18nKey: "Packages",
    path: "/packages",
    permissions: [
      PERMISSIONS.PERM_PACKAGES_DISPLAY,
      PERMISSIONS.PERM_PACKAGES_ARCHIVE,
      PERMISSIONS.PERM_PACKAGES_CREATE,
      PERMISSIONS.PERM_PACKAGES_UPDATE,
    ],
    icon: "fas fa-box",
  },
  {
    i18nKey: "Tags",
    path: "/tags",
    permissions: [
      PERMISSIONS.PERM_TAGS_DISPLAY,
      PERMISSIONS.PERM_TAGS_CREATE,
      PERMISSIONS.PERM_TAGS_UPDATE,
    ],
    icon: "fas fa-tag",
  },
  {
    i18nKey: "Events",
    path: "/events",
    permissions: [
      PERMISSIONS.PERM_EVENTS_CREATE,
      PERMISSIONS.PERM_EVENTS_UPDATE,
    ],
    icon: "fas fa-calendar",
  },
  {
    i18nKey: "Themes",
    path: "/themes",
    permissions: [
      PERMISSIONS.PERM_THEMES_CREATE,
      PERMISSIONS.PERM_THEMES_UPDATE,
      PERMISSIONS.PERM_THEMES_DELETE,
    ],
    icon: "fas fa-brush",
  },
  {
    i18nKey: "FAQ",
    path: "/questions",
    permissions: [
      PERMISSIONS.PERM_QUESTIONS_CREATE,
      PERMISSIONS.PERM_QUESTIONS_UPDATE,
      PERMISSIONS.PERM_QUESTIONS_DELETE,
    ],
    icon: "fas fa-question-circle",
  },
  {
    i18nKey: "Témoignages",
    path: "/testimonies",
    permissions: [
      PERMISSIONS.PERM_TESTIMONIES_CREATE,
      PERMISSIONS.PERM_TESTIMONIES_UPDATE,
      PERMISSIONS.PERM_TESTIMONIES_DELETE,
    ],
    icon: "fas fa-video",
  },
  {
    i18nKey: "Medias",
    path: "/medias",
    permissions: [
      PERMISSIONS.PERM_MEDIAS_DISPLAY,
      PERMISSIONS.PERM_MEDIAS_CREATE,
      PERMISSIONS.PERM_MEDIAS_UPDATE,
    ],
    icon: "far fa-image",
  },
  {
    i18nKey: "Saisons",
    path: "/seasons/matrix",
    permissions: [
      PERMISSIONS.PERM_SEASONS_DISPLAY,
      PERMISSIONS.PERM_SEASONS_UPDATE,
    ],
    icon: "far fa-calendar-alt",
  },
  {
    i18nKey: "Produits",
    path: "/products",
    // INFO: the dropdown does not have a permission and role field anymore
    dropdown: {
      1: {
        // role: ["SUPER_ADMIN", "ADMIN", "TRANSLATOR"],
        permissions: [
          PERMISSIONS.PERM_CAMPS_DISPLAY,
          PERMISSIONS.PERM_CAMPS_ARCHIVE,
          PERMISSIONS.PERM_CAMPS_CREATE,
          PERMISSIONS.PERM_CAMPS_UPDATE,
        ],
        path: "/camps/list",
        i18nKey: "camp",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_EXTRAS_DISPLAY,
          PERMISSIONS.PERM_EXTRAS_ARCHIVE,
          PERMISSIONS.PERM_EXTRAS_CREATE,
          PERMISSIONS.PERM_EXTRAS_UPDATE,
        ],
        path: "/extras/list",
        i18nKey: "extra",
      },
      3: {
        permissions: [
          PERMISSIONS.PERM_ACCOMMODATIONS_DISPLAY,
          PERMISSIONS.PERM_ACCOMMODATIONS_ARCHIVE,
          PERMISSIONS.PERM_ACCOMMODATIONS_CREATE,
          PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE,
        ],
        path: "/accommodations/list",
        i18nKey: "accommodation",
      },
    },
    // permission: "MANAGE_PACKAGES",
    // role: ["SUPER_ADMIN", "ADMIN", "TRANSLATOR"],
    icon: "fas fa-shopping-cart",
  },
  {
    i18nKey: "Logs d'exports",
    path: "/admin/exports",
    permissions: [PERMISSIONS.PERM_EXPORT_LOGS_DISPLAY],
    icon: "fas fa-file-export",
  },
  {
    i18nKey: "Config",
    path: "/config",
    permissions: [
      PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_UPDATE,
      PERMISSIONS.PERM_CONFIG_FRONT_SNIPPETS_DISPLAY,
      PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_UPDATE,
      PERMISSIONS.PERM_CONFIG_HEAD_SNIPPET_DISPLAY,
      PERMISSIONS.PERM_CONFIG_POPIN_UPDATE,
      PERMISSIONS.PERM_CONFIG_POPIN_DISPLAY,
      PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_UPDATE,
      PERMISSIONS.PERM_CONFIG_PAYMENT_DELAY_MAX_DISPLAY,
    ],
    icon: "fas fa-cog",
  },
  {
    i18nKey: "Langues",
    path: "/locales/list",
    permissions: [
      PERMISSIONS.PERM_LOCALES_DISPLAY,
      PERMISSIONS.PERM_LOCALES_UPDATE,
    ],
    icon: "fas fa-globe-asia",
  },
  {
    i18nKey: "Brochures",
    path: "/attachments/list",
    permissions: [
      PERMISSIONS.PERM_ATTACHMENTS_DISPLAY,
      PERMISSIONS.PERM_ATTACHMENTS_CREATE,
      PERMISSIONS.PERM_ATTACHMENTS_UPDATE,
    ],
    icon: "fas fa-book",
  },
  {
    i18nKey: "Clients",
    path: "/clients",
    permissions: [
      PERMISSIONS.PERM_CLIENTS_DISPLAY,
      PERMISSIONS.PERM_CLIENTS_CREATE,
      PERMISSIONS.PERM_CLIENTS_UPDATE,
      PERMISSIONS.PERM_CLIENTS_RESET_PASSWORD,
      PERMISSIONS.PERM_PLAYERS_DISPLAY_BORROWERS,
      PERMISSIONS.PERM_PLAYERS_DELETE_BORROWER,
      PERMISSIONS.PERM_PLAYERS_SEND_LINK_EMAIL,
    ],
    icon: "fas fa-users",
  },
  {
    i18nKey: "Commandes",
    path: "/orders/list",
    permissions: [
      PERMISSIONS.PERM_ORDERS_DISPLAY,
      PERMISSIONS.PERM_ORDERS_COMMENT,
      PERMISSIONS.PERM_ORDERS_RESOLVE,
      PERMISSIONS.PERM_ORDERS_EXPORT,
    ],
    icon: "fas fa-receipt",
  },
  {
    i18nKey: "Codes promo",
    path: "/discounts/list",
    permissions: [
      PERMISSIONS.PERM_DISCOUNTS_DISPLAY,
      PERMISSIONS.PERM_DISCOUNTS_ARCHIVE,
      PERMISSIONS.PERM_DISCOUNTS_CREATE,
      PERMISSIONS.PERM_DISCOUNTS_UPDATE,
    ],
    icon: "fas fa-percent",
  },
  {
    i18nKey: "Inscriptions",
    path: "/bookings",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DISPLAY,
          PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD,
        ],
        path: "/bookings",
        i18nKey: "bookings",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DISPLAY,
          PERMISSIONS.PERM_BOOKINGS_EXPORT_WEEK_DOWNLOAD,
        ],
        path: "/bookings/guest",
        i18nKey: "guest"
      }
    },
    icon: "fas fa-clipboard-list",
  },
  {
    i18nKey: "Stocks",
    path: "/stocks",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_STOCKS_CAMPS_DISPLAY,
          PERMISSIONS.PERM_STOCKS_CAMPS_EXPORT,
          PERMISSIONS.PERM_STOCKS_CAMPS_UPDATE,
        ],
        path: "/stocks/matrix/1",
        i18nKey: "camp",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_STOCKS_EXTRAS_DISPLAY,
          PERMISSIONS.PERM_STOCKS_EXTRAS_EXPORT,
          PERMISSIONS.PERM_STOCKS_EXTRAS_UPDATE,
        ],
        path: "/stocks/matrix/2",
        i18nKey: "extra",
      },
      3: {
        permissions: [
          PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_OTHER_DISPLAY,
          PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_BOARDING_DISPLAY,
          PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_DISPLAY,
          PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_HOTEL_DISPLAY,
        ],
        path: "/stocks/matrix/3",
        i18nKey: "accommodation",
      },
    },
    icon: "fas fa-warehouse",
  },
  {
    i18nKey: "Yields",
    path: "/yields",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_YIELDS_CAMPS_DISPLAY,
          PERMISSIONS.PERM_YIELDS_CAMPS_EXPORT,
          PERMISSIONS.PERM_YIELDS_CAMPS_UPDATE,
        ],
        path: "/yields/matrix/1",
        i18nKey: "camp",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_YIELDS_EXTRAS_DISPLAY,
          PERMISSIONS.PERM_YIELDS_EXTRAS_EXPORT,
          PERMISSIONS.PERM_YIELDS_EXTRAS_UPDATE,
        ],
        path: "/yields/matrix/2",
        i18nKey: "extra",
      },
      3: {
        permissions: [
          PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_DISPLAY,
          PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_DISPLAY,
          PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_DISPLAY,
          PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_DISPLAY,
        ],
        path: "/yields/matrix/3",
        i18nKey: "accommodation",
      },
      // packages: {
      //   permissions: [PERMISSIONS.PERM_YIELDS_PACKAGES_DISPLAY],
      //   path: "/yields/packages",
      //   i18nKey: "yieldpackages",
      // },
    },
    icon: "fas fa-chart-line",
  },
  {
    i18nKey: "Export",
    path: "/export",
    permissions: [
      PERMISSIONS.PERM_INVOICES_EXPORT_PDF,
      PERMISSIONS.PERM_CREDIT_NOTES_EXPORT_PDF,
      PERMISSIONS.PERM_INVOICES_EXPORT_CSV,
      PERMISSIONS.PERM_CREDITS_NOTES_EXPORT_CSV,
      PERMISSIONS.PERM_PAYMENTS_EXPORT,
      PERMISSIONS.PERM_BOOKINGS_EXPORT_FULL,
      PERMISSIONS.PERM_BOOKINGS_EXPORT_ANONYMOUS,
      PERMISSIONS.PERM_BOOKINGS_EXPORT_COMMERCIAL,
      PERMISSIONS.PERM_BOOKINGS_EXPORT_GEOLOCALIZED,
      PERMISSIONS.PERM_CARTS_EXPORT,
      PERMISSIONS.PERM_CARTS_EXPORT_WITH_CLIENTS_DATA,
      PERMISSIONS.PERM_ORDERS_EXPORT,
      PERMISSIONS.PERM_ORDERS_PAYMENTS_MEANS_EXPORT,
      PERMISSIONS.PERM_SALES_SHEET_EXPORT,
      PERMISSIONS.PERM_YIELD_INCOME_EXPORT,
    ],
    icon: "fas fa-download",
  },
  {
    i18nKey: "Traductions",
    path: "/intl/matrix",
    permissions: [
      PERMISSIONS.PERM_TRANSLATIONS_DISPLAY,
      PERMISSIONS.PERM_TRANSLATIONS_UPDATE,
    ],
    icon: "fas fa-language",
  },
  {
    i18nKey: "Sport Etudes",
    path: "/sport-etude",
    dropdown: {
      1: {
        permissions: [
          PERMISSIONS.PERM_PRODUCTS_CREATE,
          PERMISSIONS.PERM_OPTIONS_CREATE,
          PERMISSIONS.PERM_PRODUCTS_OPTIONS_DISPLAY,
          PERMISSIONS.PERM_OPTIONS_ARCHIVE,
          PERMISSIONS.PERM_OPTIONS_UPDATE,
          PERMISSIONS.PERM_PRODUCTS_ARCHIVE,
          PERMISSIONS.PERM_PRODUCTS_UPDATE,
          PERMISSIONS.PERM_CAMPUS_LOCALES_UPDATE,
          PERMISSIONS.PERM_CAMPUS_LOCALES_DISPLAY,
          PERMISSIONS.PERM_FORMS_EXPORT,
          PERMISSIONS.PERM_STUDENTS_CREATE,
          PERMISSIONS.PERM_STUDENTS_UPDATE,
          PERMISSIONS.PERM_STUDENTS_DISPLAY,
          PERMISSIONS.PERM_CAMPUS_CONFIG_DISPLAY,
          PERMISSIONS.PERM_CAMPUS_CONFIG_UPDATE,
          PERMISSIONS.PERM_CAMPUS_STOCK_DISPLAY,
          PERMISSIONS.PERM_CAMPUS_STOCK_UPDATE,
        ],
        path: "/sport-etude",
        i18nKey: "campusManagement",
      },
      2: {
        permissions: [
          PERMISSIONS.PERM_CAMPUS_FORMS_DISPLAY,
          PERMISSIONS.PERM_CAMPUS_FORMS_UPDATE,
        ],
        path: "/student-forms",
        i18nKey: "campusStudentForms",
      },
    },
    icon: "fas fa-graduation-cap",
  },
  {
    i18nKey: "Fidélité",
    path: "/loyalty",
    dropdown: {
      1: {
        permissions: [PERMISSIONS.PERM_EMPLOYEE_FULL_ACCESS],
        path: "/loyalty/points",
        i18nKey: "fidPoints",
      },
      2: {
        permissions: [PERMISSIONS.PERM_ADMIN_FULL_ACCESS],
        path: "/loyalty/admin",
        i18nKey: "fidAdmin",
      },
    },
    icon: "far fa-address-card",
  },
  {
    i18nKey: "Optins",
    path: "/optins",
    permissions: [PERMISSIONS.PERM_OPTINS_UPDATE],
    icon: "fas fa-mail-bulk",
  },
]

export const PRODUCTS = {
  CAMPS: "camps",
  ACCOMMODATIONS: "accommodations",
  EXTRAS: "extras",
}

export const OTHER_PAYMENT_MEANS = [
  {
    name: "PAYPAL",
    paymentMeanId: 2,
  },
  {
    name: "TRANSFER",
    paymentMeanId: 3,
  },
  {
    name: "CASH",
    paymentMeanId: 4,
  },
  {
    name: "CHECK",
    paymentMeanId: 5,
  },
  {
    name: "CB - TPE",
    paymentMeanId: 6,
  },
  {
    name: "STRIPE",
    paymentMeanId: 1,
  },
]

export const schoolGrades = [
  {
    value: '6eme',
    label: '6ème',
    schoolId: 1,
  },
  {
    value: '5eme',
    label: '5ème',
    schoolId: 1,
  },
  {
    value: '4eme',
    label: '4ème',
    schoolId: 1,
  },
  {
    value: '3eme',
    label: '3ème',
    schoolId: 1,
  },
  {
    value: '2nde',
    label: 'Seconde',
    schoolId: 1,
  },
  {
    value: '1ere',
    label: 'Première',
    schoolId: 1,
  },
  {
    value: 'term',
    label: 'Terminale',
    schoolId: 1,
  },
  {
    value: '5th',
    label: '5th',
    schoolId: 2,
  },
  {
    value: '6th',
    label: '6th',
    schoolId: 2,
  },
  {
    value: '7th',
    label: '7th',
    schoolId: 2,
  },
  {
    value: '8th',
    label: '8th',
    schoolId: 2,
  },
  {
    value: '9th',
    label: '9th',
    schoolId: 2,
  },
  {
    value: '10th',
    label: '10th',
    schoolId: 2,
  },
  {
    value: '11th',
    label: '11th',
    schoolId: 2,
  },
  {
    value: '12th',
    label: '12th',
    schoolId: 2,
  },
];

export const accTypologyToPermissions = {
  9: { // other
    // stocks
    update_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_OTHER_UPDATE,
    export_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_OTHER_EXPORT,
    display_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_OTHER_DISPLAY,
    // yields
    update_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_UPDATE,
    export_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_EXPORT,
    display_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_DISPLAY,
  },
  10: { // boarding
    // stocks
    update_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_BOARDING_UPDATE,
    export_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_BOARDING_EXPORT,
    display_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_BOARDING_DISPLAY,
    // yields
    update_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_UPDATE,
    export_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_EXPORT,
    display_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_DISPLAY,
  },
  11: { // residence
    // stocks
    update_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_UPDATE,
    export_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_EXPORT,
    display_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_RESIDENCE_DISPLAY,
    // yields
    update_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_UPDATE,
    export_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_EXPORT,
    display_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_DISPLAY,
  },
  12: { // hotel
    // stocks
    update_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_HOTEL_UPDATE,
    export_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_HOTEL_EXPORT,
    display_stocks: PERMISSIONS.PERM_STOCKS_ACCOMMODATIONS_HOTEL_DISPLAY,
    // yields
    update_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_UPDATE,
    export_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_EXPORT,
    display_yields: PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_DISPLAY,
  }
};
