// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL, API_SE_URL } from "../../constants/constants"

const useUtilsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getLocales = () =>
    request("GET", `${API_STAGES_URL}/v1/locales`, null, {
      silentLoad: false,
      axiosOptions: { headers: {} },
    })

  const getLocalesWithId = localeId =>
    request("GET", `${API_STAGES_URL}/v1/locales/${localeId}`, localeId, {
      silentLoad: false,
      axiosOptions: { headers: {} },
    })

  const postLocale = locale =>
    request("POST", `${API_STAGES_URL}/v1/locales`, locale, {
      silentLoad: false,
      axiosOptions: { headers: {} },
    })

  const putLocale = (localeId, locale) =>
    request("PUT", `${API_STAGES_URL}/v1/locales/${localeId}`, locale, {
      silentLoad: false,
      axiosOptions: { headers: {} },
    })

  const updateLocale = (locale, localeId) =>
    request("PUT", `${API_STAGES_URL}/v1/locales/${localeId}`, locale, {
      silentLoad: false,
      axiosOptions: { headers: {} },
    })

  const getLocalesSE = () =>
    request(
      "GET",
      `${API_SE_URL}/v1/locales`,
      null,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const getLocalesWithIdSE = localeId =>
    request(
      "GET",
      `${API_SE_URL}/v1/locales/${localeId}`,
      localeId,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const postLocaleSE = locale =>
    request(
      "POST",
      `${API_SE_URL}/v1/locales`,
      locale,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const putLocaleSE = (localeId, locale) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/locales/${localeId}`,
      locale,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  const updateLocaleSE = (locale, localeId) =>
    request(
      "PUT",
      `${API_SE_URL}/v1/locales/${localeId}`,
      locale,
      { silentLoad: false, axiosOptions: { headers: {} } },
      true
    )

  return [
    requestState,
    payload,
    error,
    {
      getLocales,
      getLocalesWithId,
      postLocale,
      putLocale,
      updateLocale,

      getLocalesSE,
      getLocalesWithIdSE,
      postLocaleSE,
      putLocaleSE,
      updateLocaleSE,
    },
  ]
}

export default useUtilsApi
