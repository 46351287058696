import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Droppable } from "react-beautiful-dnd"
import { sortBy } from "lodash"
import cn from "../../../../../utils/cn"

// Components
import {
  SelectInput,
  TextInput,
} from "../../../../../components/atoms/Inputs/Inputs"
import Player from "./Player"

// Style
import style from "./Group.module.css"

// Hooks
import useBookingsForCoachApi from "../../../../../hooks/Api/useBookingsForCoachApi"

const Group = ({ group, playersSelected, updatePlayerSelected, coaches, updateGroup, sections, handleResetSection, countries, draggedPlayer, allPlayersInSection, authorizedOrdinal, dropzoneClasses }) => {
  const [nameGroup, setNameGroup] = useState(group.court)
  const [levelGroup, setLevelGroup] = useState(group.level)
  const [coachIdGroup, setCoachIdGroup] = useState(group.coachId)

  const [playerContextMenuOpened, setPlayerContextMenuOpened] = useState(null)

  useEffect(() => {
    if(group.court !== nameGroup) setNameGroup(group.court)
    if(group.level !== levelGroup) setLevelGroup(group.level)
    if(group.coachId !== coachIdGroup) setCoachIdGroup(group.coachId)
  },[group])

  const [, , , { putGroupNameAndCoach }] = useBookingsForCoachApi()

  const saveGroup = () => {
    updateGroup({
      ...group, 
      coachId: coachIdGroup,
      court: nameGroup ?? '',
      level: levelGroup ?? '',
    })
    putGroupNameAndCoach(group.code, {
      coachId: coachIdGroup,
      court: nameGroup ?? '',
      level: levelGroup ?? '',
    })
  }

  const handleCoachModify = e => setCoachIdGroup(e.target.value || null)
  const handleChangeName = e => setNameGroup(e.target.value || null)
  const handleChangeLevel = e => setLevelGroup(e.target.value || null)

  const handleSelectPlayer = (e, playerClicked) => {
    e.stopPropagation()
    if (e.ctrlKey || e.metaKey) {
      const isPlayerAlreadySelected = playersSelected.some(
        playerSelected => playerSelected.id === playerClicked.id
      )
      if (isPlayerAlreadySelected) {
        updatePlayerSelected(
          playersSelected.filter(
            playerSelected => playerSelected.id !== playerClicked.id
          )
        )
      } else updatePlayerSelected(playersSelected.concat(playerClicked))
    }
  }

  const isDropDisabled = () => {
    const draggedPlayerDetails = allPlayersInSection?.find((player) => player.id === draggedPlayer);
    if (!draggedPlayerDetails) return false;

    // drop in group is disabled if dragged player has 2 sessions (morning and afternoon) and current dragged booking and group doesn't match (morning OR afternoon)
    const playerHasTwoSessions = draggedPlayerDetails.sessions?.length === 2;
    return (
      playerHasTwoSessions && draggedPlayerDetails.ordinal !== authorizedOrdinal
    );
  };

  return (
    <div className={`${style.group} `}>
      <SelectInput
        id="coach-selector"
        className="coach-selector sectionsGroupsCoachSelect"
        value={coachIdGroup}
        name="coachSelector"
        onChange={handleCoachModify}
        onBlur={saveGroup}
        options={[
          <option key="default" value="" disabled>
            Coach
          </option>,
          sortBy((coaches || []), 'firstName').map((coach, coachIndex) => (
            <option key={coachIndex} value={coach.id}>
              {coach.firstName} {coach.lastName}
            </option>
          )),
        ]}
      />
      <TextInput
        value={nameGroup}
        onChange={handleChangeName}
        onBlur={saveGroup}
        placeholder={`Groupe n°${group.ordinal}`}
        className="sectionsGroupsGroupInput"
      />
      <TextInput
        value={levelGroup}
        onChange={handleChangeLevel}
        onBlur={saveGroup}
        placeholder="Niveau"
        className="sectionsGroupsLevelInput"
      />
      <Droppable
        key={group.ordinal}
        droppableId={group.code}
        isDropDisabled={isDropDisabled()}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cn([
              'group-dropzone',
              dropzoneClasses.groupDropzone,
              isDropDisabled() ? dropzoneClasses.dropzoneDisabled : ''
            ])}
          >
            <div className={style["players-group"]}>
              <p className={style["players-group__title"]}>Groupe de Joueurs</p>
              {group.players &&
                group.players.map((player, playerIndex) => {
                  return (
                    <Player
                      key={player.id}
                      handleSelectPlayer={handleSelectPlayer}
                      player={{
                        ...player,
                        index: playerIndex,
                        isSelected: playersSelected.some(
                          p => p.id === player.id
                        ),
                      }}
                      playersSelected={playersSelected}
                      sections={sections}
                      playerContextMenuOpened={playerContextMenuOpened}
                      setPlayerContextMenuOpened={setPlayerContextMenuOpened}
                      handleResetSection={handleResetSection}
                      countries={countries}
                    />
                  )
                })}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

Group.propTypes = {
  group: PropTypes.object,
  coaches: PropTypes.array,
  playersSelected: PropTypes.array,
  updatePlayerSelected: PropTypes.func,
  updateGroup: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  handleResetSection: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Group
