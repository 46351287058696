import React, { useEffect, useCallback, useState, useMemo } from "react"
import { getYear } from "date-fns"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

import "react-datepicker/dist/react-datepicker.css"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Archive from "../../../../components/molecules/Archive/Archive"
import ProductArchiveWizard from "../../../../components/molecules/ProductArchiveWizard/ProductArchiveWizard"
import Spinner from "../../../../components/atoms/Spinner/Spinner"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useCampsApi from "../../../../hooks/Api/useCampsApi"
import useSeasonsApi from "../../../../hooks/Api/useSeasonsApi"
import useQuotasApi from "../../../../hooks/Api/useQuotasApi"
import useTypesApi from "../../../../hooks/Api/useTypesApi"
import useDurationsApi from "../../../../hooks/Api/useDurationsApi"
import useFormatsApi from "../../../../hooks/Api/useFormatsApi"
import useSportsApi from "../../../../hooks/Api/useSportsApi"
import useMediasApi from "../../../../hooks/Api/useMediasApi"
import useAttachmentsApi from "../../../../hooks/Api/useAttachmentsApi"
import useTaxRatesApi from "../../../../hooks/Api/useTaxRatesApi"
import usePackagesApi from "../../../../hooks/Api/usePackagesApi"
import useYearsApi from "../../../../hooks/Api/useYearsApi"

// Constants
import { PERMISSIONS, PRODUCTS } from "../../../../constants/constants"

// Utils & misc
import cn from "../../../../utils/cn"
import {
  isProductUsedInPackage,
  whichPackagesContainsProduct,
} from "../../../../utils/productsUtils"
import { exportCollectionToArray, exportCollectionToArrayImages } from "../../../../utils/collection"
import { getInitialLocaleValues } from "../../../../utils/locales"

import styles from "./CampsEdit.module.css"
import MediasInput from "../../../../components/atoms/MediasInput/MediasInput"

const VIEWS = {
  FORM: "FORM",
  ARCHIVE: "ARCHIVE",
}

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_CAMPS_UPDATE,
  PERMISSIONS.PERM_CAMPS_CREATE,
  PERMISSIONS.PERM_CAMPS_ARCHIVE,
]

const CampsEdit = ({ match }) => {
  const familyId = 1
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, seasonsData, , { getSeasons }] = useSeasonsApi()
  const [, quotasData, , { getQuotas }] = useQuotasApi()
  const [, typesData, , { getTypes }] = useTypesApi()
  const [, durationsData, , { getDurations }] = useDurationsApi()
  const [, formatsData, , { getFormats }] = useFormatsApi()
  const [, sportsData, , { getSports }] = useSportsApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, attachmentsData, , { getAttachments }] = useAttachmentsApi()
  const [, taxRatesData, , { getTaxRates }] = useTaxRatesApi()
  const [, packagesData, , { getPackages }] = usePackagesApi()
  const [, yearsData, , { getYears }] = useYearsApi()

  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  // Camps API Hooks
  const [, campData, campError, { getCampWithId }] = useCampsApi()
  const [updateCampState, , updateCampError, { updateCamp }] = useCampsApi()
  const [newCampState, , newCampError, { createCamp }] = useCampsApi()
  const history = useHistory()

  const isEditingCamp = !isEmpty(match.params)

  const [validationWarning, setValidationWarning] = useState(null)
  const [isUsedInPackage, setIsUsedInPackage] = useState(undefined)
  const [packagesContaining, setPackagesContaining] = useState([])
  const [currentView, setCurrentView] = useState(VIEWS.FORM)

  const savedGetCampWithId = useCallback(getCampWithId, [])
  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetQuotas = useCallback(getQuotas, [])
  const savedGetTypes = useCallback(getTypes, [])
  const savedGetDurations = useCallback(getDurations, [])
  const savedGetFormats = useCallback(getFormats, [])
  const savedGetSports = useCallback(getSports, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetAttachments = useCallback(getAttachments, [])
  const savedTaxRates = useCallback(getTaxRates, [])
  const savedGetPackages = useCallback(getPackages, [])
  const savedGetYears = useCallback(getYears, [])

  const getTaxRateByFamilyId = (first = false) => {
    if (taxRatesData && taxRatesData.taxRates) {
      const taxRatesArray = Object.keys(taxRatesData.taxRates).map(
        taxRateId => taxRatesData.taxRates[taxRateId]
      )

      if (first)
        return taxRatesArray.find(taxRate =>
          taxRate.taxRateFamiliesIds.find(id => id === familyId)
        )
      return taxRatesArray.filter(
        taxRate => !!taxRate.taxRateFamiliesIds.find(id => id === familyId)
      )
    }
    return null
  }

  const getCurrentYearId = () => {
    if (yearsData) {
      const year = getYear(new Date())
      const years = exportCollectionToArray(yearsData, "years")
      return years?.find(y => y.number === year)?.id || years[0].id
    }
    return null
  }
  const currentYearId = getCurrentYearId()

  const createPriceObject = () => {
    return {
      [currentYearId]: Object.keys(seasonsData.seasons).reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue] = 0
          return accumulator
        },
        {}
      ),
    }
  }

  const createInitialValues =
    locales && seasonsData && taxRatesData && yearsData
      ? {
          product: {
            code: "",
            account: "",
            name: getInitialLocaleValues(locales),
            prices: createPriceObject(),
            taxRateId: getTaxRateByFamilyId(true).id,
            quotasIds: [],
            defaultStock: 0,
            active: true,
          },
          typeId: 1,
          durationId: 1,
          formatId: 2,
          sportsIds: [],
          thumbnailId: 1,
          attachmentId: 1,
        }
      : {}

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      camps: isEditingCamp ? campData || {} : createInitialValues,
    },
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      const sanitizedValues = {
        ...valuesSubmit.camps,
        product: { ...valuesSubmit.camps.product, archived: false },
      }

      if (!sanitizedValues.sportsIds || !sanitizedValues.sportsIds.length) {
        setValidationWarning(
          "Sports Ids: Cette collection doit contenir 1 élément ou plus."
        )
        return
      }
      if (isEditingCamp)
        updateCamp(sanitizedValues).then(() =>
          history.push({
            pathname: "/camps/list",
            state: { submitType: "edit", success: true },
          })
        )
      else
        createCamp(sanitizedValues).then(() =>
          history.push({
            pathname: "/camps/list",
            state: { submitType: "create", success: true },
          })
        )
    },
  })

  const backToList = () => {
    history.push({
      pathname: "/camps/list",
      state: {},
    })
  }

  useEffect(() => {
    if (isEditingCamp) savedGetCampWithId(match.params.id)
    savedGetSeasons()
    savedGetQuotas({ archived: false })
    savedGetTypes()
    savedGetDurations()
    savedGetFormats()
    savedGetSports()
    savedGetMedias()
    savedGetAttachments()
    savedTaxRates()
    savedGetPackages()
    savedGetYears()
  }, [
    isEditingCamp,
    savedGetCampWithId,
    savedGetSeasons,
    savedGetQuotas,
    savedGetTypes,
    match.params.id,
    savedGetDurations,
    savedGetFormats,
    savedGetSports,
    savedGetMedias,
    savedGetAttachments,
    savedTaxRates,
    savedGetPackages,
    savedGetYears,
  ])

  const handleCheckboxClick = (checkBoxId, prevList, field) => {
    const newList = [...prevList]
    const found = newList.findIndex(quota => quota === checkBoxId)

    if (found === -1) newList.push(checkBoxId)
    else newList.splice(found, 1)
    setFieldValue(field, newList)
  }

  const handleCampArchive = async () => {
    const sanitizedValues = {
      ...values.camps,
      product: { ...values.camps.product, archived: true },
    }

    if (!sanitizedValues.sportsIds || !sanitizedValues.sportsIds.length) {
      setValidationWarning(
        "Sports Ids: Cette collection doit contenir 1 élément ou plus."
      )
      return
    }
    if (isEditingCamp)
      updateCamp(sanitizedValues).then(() =>
        history.push({
          pathname: "/camps/list",
          state: { submitType: "edit", success: true },
        })
      )
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_CAMPS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_CAMPS_CREATE] && !isEditingCamp)

  // When packageData changes, check if product is used in any of them, inactive or not
  useEffect(() => {
    if (packagesData && campData) {
      const isProductUsed = isProductUsedInPackage(
        campData,
        packagesData.packages
      )
      setIsUsedInPackage(isProductUsed)

      setPackagesContaining(
        whichPackagesContainsProduct(campData, packagesData.packages)
      )
    }
  }, [packagesData, campData])

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Stage / ${isEditingCamp ? "Éditer" : "Nouveau"}`}
          {campData ? ` / ${campData.product.name["1"]}` : null}
        </h1>
      </div>

      <div className={cn(["mw-100", "w-100", "m-auto", styles.editSection])}>
        {currentView === VIEWS.FORM ? (
          <>
            {values.camps.product &&
            seasonsData &&
            locales &&
            quotasData &&
            durationsData &&
            formatsData &&
            sportsData &&
            mediasData &&
            attachmentsData &&
            taxRatesData &&
            yearsData &&
            typesData ? (
              <form onSubmit={handleSubmit}>
                {campError || updateCampError || newCampError ? (
                  <div className="alert alert-error" role="alert">
                    {/* {campError || updateCampError || newCampError} */}
                  </div>
                ) : null}
                <div className={cn(["card mb-4"])}>
                  <div className="card-body">
                    <h5 className="card-title">Informations</h5>

                    <div className="form-row">
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="camp-typeId">Code</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          type="text"
                          className="form-control"
                          id="camps.product.code"
                          name="camps.product.code"
                          value={values.camps.product.code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="camp-typeId">Account</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id="camps.product.account"
                          name="camps.product.account"
                          value={values.camps.product.account}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* NAME (LOCALES) */}
                      {Object.keys(locales).map(localeId => (
                        <div
                          key={`name_input_${localeId}`}
                          className="col-sm-4 col-md-4 pb-3"
                        >
                          <b>
                            <label htmlFor="camp-typeId">{`Name ${locales[
                              localeId
                            ].code.toUpperCase()}`}</label>
                          </b>
                          <input
                            disabled={!isAllowedToEdit}
                            required
                            type="text"
                            className="form-control"
                            id={`camps.product.name.${localeId}`}
                            name={`camps.product.name.${localeId}`}
                            value={values.camps.product.name[localeId]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="valid-tooltip">Looks good!</div>
                        </div>
                      ))}

                      {/* PRIX (SEASONS) */}
                      {!isEditingCamp &&
                        Object.keys(seasonsData.seasons).map(seasonId => (
                          <div
                            key={`price_input_${seasonId}`}
                            className="col-sm-4 col-md-4 pb-3"
                          >
                            <b>
                              <label htmlFor="camp-typeId">{`Saison ${seasonsData.seasons[seasonId].name}`}</label>
                            </b>
                            <div className="input-group">
                              <input
                                disabled={!isAllowedToEdit}
                                required
                                type="number"
                                className="form-control"
                                id={`camps.product.prices.${currentYearId}.${seasonId}`}
                                name={`camps.product.prices.${currentYearId}.${seasonId}`}
                                value={
                                  values.camps.product.prices[currentYearId][
                                    seasonId
                                  ] / 100
                                }
                                onChange={(e) => setFieldValue(`camps.product.prices.${currentYearId}.${seasonId}`, Number(e.target.value) * 100)}
                                onBlur={handleBlur}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">€</span>
                              </div>
                            </div>
                            <div className="valid-tooltip">Looks good!</div>
                          </div>
                        ))}

                      {/* DEFAULT STOCK */}
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="camp-typeId">Default stock</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="number"
                          className="form-control"
                          id="camps.product.defaultStock"
                          name="camps.product.defaultStock"
                          value={values.camps.product.defaultStock}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* TAX RATE */}
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="camp-taxRate">Tax rate</label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="camps.product.taxRateId"
                          name="camps.product.taxRateId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "camps.product.taxRateId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.camps.product.taxRateId}
                        >
                          {getTaxRateByFamilyId().map(taxRate => (
                            <option
                              key={`duration_id_select_${taxRate.id}`}
                              value={taxRate.id}
                            >
                              {taxRate.value}
                            </option>
                          ))}
                        </select>

                        {/* <div className="input-group">
                      <input
                        disabled={!isAllowedToEdit}
                        required
                        type="number"
                        className="form-control"
                        id="camps.product.taxRate"
                        name="camps.product.taxRate"
                        value={values.camps.product.taxRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">%</span>
                      </div>
                    </div> */}

                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* QUOTAS ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="camp-typeId">Quotas ids</label>
                        </b>
                        {Object.keys(quotasData.quotas)
                          .filter(quotaId => quotasData.quotas[quotaId].macro)
                          .map(quotaId => (
                            <div
                              key={`quotas_input_${quotaId}`}
                              className="form-check"
                            >
                              <input
                                disabled={!isAllowedToEdit}
                                checked={values.camps.product.quotasIds.find(
                                  quota => quota === Number(quotaId)
                                )}
                                className="form-check-input"
                                type="checkbox"
                                onChange={() =>
                                  handleCheckboxClick(
                                    Number(quotaId),
                                    values.camps.product.quotasIds,
                                    "camps.product.quotasIds"
                                  )
                                }
                                id={`quotas_input_${quotaId}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`quotas_input_${quotaId}`}
                              >
                                {quotasData.quotas[quotaId].name}
                              </label>
                            </div>
                          ))}
                      </div>

                      {/* TYPE ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-typeId">Type id</label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="camps.typeId"
                          name="camps.typeId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "camps.typeId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.camps.typeId}
                        >
                          {Object.keys(typesData.types).map(typeId => (
                            <option
                              key={`typeId_select_${typeId}`}
                              value={typeId}
                            >
                              {typesData.types[typeId].name["1"]}
                            </option>
                          ))}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* DURATION ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-typeId">Duration id</label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="camps.durationId"
                          name="camps.durationId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "camps.durationId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.camps.durationId}
                        >
                          {Object.keys(durationsData.durations).map(
                            durationId => (
                              <option
                                key={`duration_id_select_${durationId}`}
                                value={durationId}
                              >
                                {durationsData.durations[durationId].name["1"]}
                              </option>
                            )
                          )}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* FORMAT ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-typeId">Format id</label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="camps.formatId"
                          name="camps.formatId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "camps.formatId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.camps.formatId}
                        >
                          {Object.keys(formatsData.formats).map(formatId => (
                            <option
                              key={`format_id_select_${formatId}`}
                              value={formatId}
                            >
                              {formatsData.formats[formatId].name["1"]}
                            </option>
                          ))}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>

                      {/* SPORTS ID */}
                      <div className="col-sm-6 col-md-6 pb-3">
                        <b>
                          <label htmlFor="camp-typeId">
                            Sports ids (au moins 1 élément coché)
                          </label>
                        </b>
                        {Object.keys(sportsData.sports).map(sportId => (
                          <div
                            key={`sport_input_${sportId}`}
                            className="form-check"
                          >
                            <input
                              disabled={!isAllowedToEdit}
                              checked={values.camps.sportsIds.find(
                                sport => sport === Number(sportId)
                              )}
                              className="form-check-input"
                              type="checkbox"
                              onChange={() =>
                                handleCheckboxClick(
                                  Number(sportId),
                                  values.camps.sportsIds,
                                  "camps.sportsIds"
                                )
                              }
                              id={`sport_input_${sportId}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`sport_input_${sportId}`}
                            >
                              {sportsData.sports[sportId].name["1"]}
                            </label>
                          </div>
                        ))}
                      </div>

                      {/* THUMBNAIL ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <MediasInput
                          label="Thumbnail"
                          options={mediasOptions}
                          selection={values.camps?.thumbnailId ? [values.camps.thumbnailId] : []}
                          setSelection={(value) => setFieldValue('camps.thumbnailId', value[0]?.id || null)}
                          disabled={!isAllowedToEdit}
                          required
                        />
                      </div>

                      {/* ATTACHMENT ID */}
                      <div className="col-sm-12 col-md-12 pb-3">
                        <b>
                          <label htmlFor="discount-attachmentId">
                            Attachment id
                          </label>
                        </b>
                        <select
                          disabled={!isAllowedToEdit}
                          className="custom-select"
                          id="camps.attachmentId"
                          name="camps.attachmentId"
                          required
                          onChange={e =>
                            setFieldValue(
                              "camps.attachmentId",
                              Number(e.target.value)
                            )
                          }
                          onBlur={handleBlur}
                          value={values.camps.attachmentId}
                        >
                          {Object.keys(attachmentsData.attachments).map(
                            attachmentId => (
                              <option
                                key={`attachment_id_select_${attachmentId}`}
                                value={attachmentId}
                              >
                                {attachmentsData.attachments[attachmentId].name}
                              </option>
                            )
                          )}
                        </select>
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    </div>
                  </div>
                </div>
                {validationWarning && (
                  <div className="alert alert-warning" role="alert">
                    {validationWarning}
                  </div>
                )}

                {rolePermissions[PERMISSIONS.PERM_CAMPS_ARCHIVE] &&
                isEditingCamp &&
                isAllowedToEdit ? (
                  <div className="float-right mb-4">
                    {/* {isUsedInPackage ? (
                      <span className="mr-3 text-secondary">
                        Ce produit est utilisé dans un package
                      </span>
                    ) : null} */}
                    <button
                      type="button"
                      onClick={() => setCurrentView(VIEWS.ARCHIVE)}
                      className="btn btn-danger"
                      // disabled={isUsedInPackage}
                    >
                      ARCHIVER
                    </button>
                  </div>
                ) : null}

                <div className="mb-4">
                  {isAllowedToEdit ? (
                    <button
                      type="submit"
                      className="btn btn-success mr-2"
                      disabled={
                        updateCampState === "loading" ||
                        newCampState === "loading"
                      }
                    >
                      {updateCampState === "loading" ||
                      newCampState === "loading" ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isEditingCamp ? "SAUVEGARDER" : "CRÉER"}
                    </button>
                  ) : (
                    <button
                      onClick={backToList}
                      type="button"
                      className="btn btn-primary"
                    >
                      Revenir à la liste
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </>
        ) : null}
        {currentView === VIEWS.ARCHIVE ? (
          <div className={styles.formWidth}>
            <Archive
              onArchivedClick={() => handleCampArchive()}
              onCancelClick={() => setCurrentView(VIEWS.FORM)}
              customView={isUsedInPackage}
              centered={!isUsedInPackage}
            >
              {isUsedInPackage ? (
                <div>
                  {packagesContaining ? (
                    <ProductArchiveWizard
                      productType={PRODUCTS.CAMPS}
                      product={campData}
                      packagesContaining={packagesContaining}
                      handleProductArchive={handleCampArchive}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              ) : (
                <div className={styles.archiveText}>
                  {`Etes-vous sûr de vouloir archiver le stage ${campData.product.name["1"]} ?`}
                </div>
              )}
            </Archive>
          </div>
        ) : null}
      </div>
    </WithMenu>
  )
}

CampsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
}

export default CampsEdit
