import React, { useEffect, useCallback, useState, useRef, useMemo } from "react"
import { useParams } from "react-router-dom"
import { saveAs } from "file-saver"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import YieldsLegacy from "./YieldsLegacy"

// Hooks
import useYearsApi from "../../../hooks/Api/useYearsApi"
import useSeasonsApi from "../../../hooks/Api/useSeasonsApi"
import useWeeksApi from "../../../hooks/Api/useWeeksApi"
import useQuotasApi from "../../../hooks/Api/useQuotasApi"
import useStocksApi from "../../../hooks/Api/useStocksApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS, accTypologyToPermissions } from "../../../constants/constants"

// Utils
import { exportCollectionToArray } from "../../../utils/collection"
import {
  buildWeeksIdsByYears,
  buildOrderedWeeks,
  buildOrderedQuotas,
  buildCollectionWeeks,
  buildActiveYears,
  buildActiveWeeks,
  buildStocks,
  buildQuotasGraph,
} from "../../../utils/yieldStockUtils"

import style from "./Yields.module.css"

const permissions = [
  // camps
  PERMISSIONS.PERM_YIELDS_CAMPS_DISPLAY,
  PERMISSIONS.PERM_YIELDS_CAMPS_EXPORT,
  PERMISSIONS.PERM_YIELDS_CAMPS_UPDATE,
  
  // extras
  PERMISSIONS.PERM_YIELDS_EXTRAS_DISPLAY,
  PERMISSIONS.PERM_YIELDS_EXTRAS_EXPORT,
  PERMISSIONS.PERM_YIELDS_EXTRAS_UPDATE,
  
  // accommodations
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_UPDATE,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_EXPORT,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_DISPLAY,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_UPDATE,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_EXPORT,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_DISPLAY,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_UPDATE,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_EXPORT,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_DISPLAY,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_UPDATE,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_EXPORT,
  PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_DISPLAY,
]

const Yields = ({ match }) => {
  const { id: productFamilyId } = useParams()
  const {
    context: { myInfo, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const firstUpdate = useRef(true)

  const [loading, setLoading] = useState(false)
  const [weeks, setWeeks] = useState({})
  const [years, setYears] = useState({})
  const [stocks, setStocks] = useState({})
  const [exportLoading, setExportLoading] = useState(false)
  const [orderedWeeks, setOrderedWeeks] = useState({})
  const [orderedQuotas, setOrderedQuotas] = useState([])
  const [quotasGraph, setQuotasGraph] = useState({})

  const [, seasonsData, , { getSeasons }] = useSeasonsApi()
  const [, yearsData, , { getYears }] = useYearsApi()
  const [, weeksData, , { getWeeks }] = useWeeksApi()
  const [, quotasData, , { getQuotas }] = useQuotasApi()
  const [, stocksData, , { getStocks }] = useStocksApi()
  const [, , , { getYieldsExport }] = useStocksApi()
  const [, , , { updateStocks }] = useStocksApi()

  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetYears = useCallback(getYears, [])
  const savedGetWeeks = useCallback(getWeeks, [])
  const savedGetQuotas = useCallback(getQuotas, [])
  const savedGetStocks = useCallback(getStocks, [])
  const savedGetYieldsExport = useCallback(getYieldsExport, [])

  useEffect(() => {
    setLoading(false)
    savedGetSeasons()
    savedGetYears()
    savedGetQuotas({ archived: false })
  }, [savedGetSeasons, savedGetYears, savedGetQuotas, productFamilyId])

  useEffect(() => {
    if (myInfo !== null && !loading) {
      setLoading(false)
      savedGetWeeks().then(res => {
        if (res && res.data) {
          // set weeks in state
          const weeksFormatted = exportCollectionToArray(res.data, "weeks")
          const weeksByYears = buildWeeksIdsByYears(weeksFormatted)
          savedGetStocks({
            minWeekId: weeksByYears[0].id,
            maxWeekId: weeksByYears[weeksByYears.length - 1].id,
            familyId: productFamilyId,
          }).then(() => setLoading(true))
        }
      })
    }
  }, [productFamilyId, savedGetWeeks, savedGetStocks, myInfo, loading])

  const permsByTypology = useCallback((permType) => {
    const permsByTypology = {};
    Object.keys(accTypologyToPermissions).forEach((typoId) => {
      permsByTypology[typoId] = rolePermissions?.[accTypologyToPermissions[typoId]?.[`${permType}_yields`]] || false;
    });
    return permsByTypology;
  }, [rolePermissions]);


  useEffect(() => {
    if (weeksData && seasonsData && yearsData && quotasData && stocksData) {
      const yearsFormatted = exportCollectionToArray(yearsData, "years")
      const weeksFormatted = exportCollectionToArray(weeksData, "weeks")
      const quotasFormatted = exportCollectionToArray(quotasData, "quotas")
      const stocksFormatted = exportCollectionToArray(stocksData, "stocks")
      const activeWeeks = buildCollectionWeeks(
        weeksFormatted,
        seasonsData.seasons
      )

      setYears(buildActiveYears(yearsFormatted))
      setWeeks(buildActiveWeeks(activeWeeks))
      setOrderedWeeks(buildOrderedWeeks(activeWeeks, yearsFormatted))
      setOrderedQuotas(buildOrderedQuotas(quotasFormatted, productFamilyId, permsByTypology('display')))
      setQuotasGraph(buildQuotasGraph(quotasFormatted, productFamilyId))
      setStocks(buildStocks(stocksFormatted))
    }
  }, [weeksData, yearsData, seasonsData, quotasData, stocksData, productFamilyId])

  const handleExportYields = () => {
    setExportLoading(true)
    savedGetYieldsExport(productFamilyId).then(res => {
      setExportLoading(false)
      if (res && res.data) saveAs(res.data, "yields.xlsx")
    })
  }

  const handleUpdateStocks = (stocksEvents) => {
    return updateStocks(stocksEvents)
      .then(res => {
        if (res && res.data) {
          return res.data
        }
      })
      .catch(e => {
        console.error(e)
        setExportLoading(false)
      });
  }

  const getYieldSectionName = () => {
    switch (productFamilyId) {
      case "1":
        return "Stages"
      case "2":
        return "Extras"
      case "3":
        return "Hébergements"
      default:
        return "Stages"
    }
  }

  const getYieldPermissions = () => {
    const campsPerms = {
      authorizedToEdit: !!rolePermissions[PERMISSIONS.PERM_YIELDS_CAMPS_UPDATE],
      authorizedToExport: !!rolePermissions[
        PERMISSIONS.PERM_YIELDS_CAMPS_EXPORT
      ],
    }
    const extrasPerms = {
      authorizedToEdit: !!rolePermissions[
        PERMISSIONS.PERM_YIELDS_EXTRAS_UPDATE
      ],
      authorizedToExport: !!rolePermissions[
        PERMISSIONS.PERM_YIELDS_EXTRAS_EXPORT
      ],
    }

    const updateAccPerms = [
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_UPDATE,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_UPDATE,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_UPDATE,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_UPDATE
    ];

    const exportAccPerms = [
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_OTHER_EXPORT,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_BOARDING_EXPORT,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_RESIDENCE_EXPORT,
      PERMISSIONS.PERM_YIELDS_ACCOMMODATIONS_HOTEL_EXPORT
    ];

    const accommodationsPerms = {
      authorizedToEdit: Object.keys(rolePermissions || {})
        .filter((perm) => updateAccPerms.includes(perm))
        .some((perm) => !!rolePermissions[perm])
      ,
      authorizedToExport: Object.keys(rolePermissions || {})
        .filter((perm) => exportAccPerms.includes(perm))
        .some((perm) => !!rolePermissions[perm])
      ,
    }

    switch (productFamilyId) {
      case "1":
        return campsPerms
      case "2":
        return extrasPerms
      case "3":
        return accommodationsPerms
      default:
        return campsPerms
    }
  }

  useEffect(() => {
    if (!firstUpdate.current) {
      setLoading(false)
    } else {
      firstUpdate.current = false
    }
  }, [productFamilyId])

  const filterQuotaOptions = useMemo(() => {
    const options = [];

    if (orderedQuotas?.length) {
      // MACRO QUOTAS
      if (orderedQuotas.some((quotaId) => quotasData?.quotas?.[quotaId]?.macro)) {
        options.push({
          label: 'Macro-Quotas',
          options: orderedQuotas
            .filter((quotaId) => quotasData?.quotas?.[quotaId]?.macro)
            .map((quotaId) => (
              { value: quotaId, label: quotasData.quotas[quotaId].name }
            )),
        });
      }
  
      // PRODUCTS
      options.push({
        label: 'Produits',
        options: orderedQuotas
          .filter((quotaId) => quotasData?.quotas?.[quotaId] && !quotasData.quotas[quotaId].macro)
          .map((quotaId) => (
            { value: quotaId, label: quotasData.quotas[quotaId].name }
          )),
      });
    }

    return options;
  }, [orderedQuotas, quotasData]);

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>{`Yields / ${getYieldSectionName()}`}</h1>
      </div>

      {years && orderedWeeks && orderedQuotas && loading && userInfo ? (
        <YieldsLegacy
          familyId={productFamilyId}
          years={years}
          weeks={weeks}
          quotas={quotasData.quotas}
          stocks={stocks}
          orderedYearsIds={Object.keys(years).map(year => year)}
          orderedWeeksIds={orderedWeeks}
          orderedQuotasIds={orderedQuotas}
          quotasGraph={quotasGraph}
          updateStock={handleUpdateStocks}
          exportMatrix={handleExportYields}
          exportLoading={exportLoading}
          authorizedToEdit={getYieldPermissions().authorizedToEdit}
          authorizedToExport={getYieldPermissions().authorizedToExport}
          productType={productFamilyId}
          filterQuotaOptions={filterQuotaOptions}
          permsByTypology={permsByTypology('update')}
        />
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

export default Yields
