import useApi from "./useApi"
import { API_STAGES_URL } from "../../constants/constants"

const useTestimoniesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getTestimonies = () => request("GET", `${API_STAGES_URL}/v1/testimonies?sorted=asc`)

  const getTestimonyById = testimonyId =>
    request("GET", `${API_STAGES_URL}/v1/testimonies/${testimonyId}`)

  const updateTestimony = ({ testimonyId, testimony }) =>
    request("PUT", `${API_STAGES_URL}/v1/testimonies/${testimonyId}`, testimony)

  const createTestimony = testimony =>
    request("POST", `${API_STAGES_URL}/v1/testimonies`, testimony)

  const deleteTestimony = id =>
    request("DELETE", `${API_STAGES_URL}/v1/testimonies/${id}`)

  const reorderTestimonies = (reorderedTestimonyId, newFollowingTestimonyId) =>
    request("POST", `${API_STAGES_URL}/v1/testimonies:reorder`, {
      reorderedTestimonyId,
      newFollowingTestimonyId,
    })

  return [
    requestState,
    payload,
    error,
    {
      getTestimonies,
      getTestimonyById,
      updateTestimony,
      createTestimony,
      deleteTestimony,
      reorderTestimonies,
    },
  ]
}

export default useTestimoniesApi
