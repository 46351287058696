import React, { useContext, useEffect, useState } from "react"
import i18n from "i18next"
import PropTypes from "prop-types"

// Components
import HeaderBanner from "../../components/atoms/HeaderBanner/HeaderBanner"

// Hooks
import useAppContext from "../../hooks/useAppContext"

// Utils & misc
import cn from "../../utils/cn"
import { AUTH_REDIRECT, SPA_HOSTNAME } from "../../constants/constants"
import { routeRedirect } from "../../utils/rolesHandler"
import { KeycloakAuthContext } from "../../components/templates/KeycloakAuthProvider"
import { isEmpty } from "lodash"

import style from "./views.module.css"

const Login = ({ history, location }) => {
  const { keycloak } = useContext(KeycloakAuthContext)

  const { context: { userInfo } } = useAppContext()

  const handleAuth = async () => {
    keycloak.login({
      kcLocale: i18n.language,
      redirectUri: AUTH_REDIRECT,
    })
  }

  useEffect(() => {
    if (userInfo) {
      const redirectPath = routeRedirect(userInfo, location.pathname)
      setTimeout(() => {
        history.push(redirectPath)
      }, 1000)
    }
  }, [history, location, userInfo])

  useEffect(() => {
    const redirectToPath = location.pathname;
    const isLoginPage = location.pathname === '/' || location.pathname === '/login';

    // auto login if needed
    if (!isLoginPage && !isEmpty(keycloak) && !keycloak.authenticated) {
      keycloak.login({
        kcLocale: i18n.language,
        redirectUri: `https://${SPA_HOSTNAME}${redirectToPath}`,
      })
    }
  }, [keycloak])

  return (
    <div style={{ height: "100vh" }}>
      <HeaderBanner sticky={false} dynamic={false} />
      <div className={cn([style.card, style.loginCard])}>
        <h2 className={style.headTitle}>Connexion</h2>
        <h1
          className={cn(["text-primary", "font-weight-bold"])}
          style={{ marginBottom: "2rem" }}
        >
          Mouratoglou Admin
        </h1>

        <button
          className="btn btn-primary"
          type="button"
          onClick={handleAuth}
          disabled={keycloak?.authenticated}
        >
          {keycloak?.authenticated ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
          Se connecter
        </button>
      </div>
    </div>
  )
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default Login
