import React, { useEffect, useCallback, useState } from "react"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Spinner from "../../../components/atoms/Spinner/Spinner"

// Hooks
import useOptinsApi from "../../../hooks/Api/Authorization/useOptinsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"
import { exportCollectionToArray } from "../../../utils/collection"

const permissions = [PERMISSIONS.PERM_OPTINS_UPDATE]

countries.registerLocale(countriesFR)

const defaultOptin = {
  sugarCrmSmsKey: "",
  sugarCrmEmailKey: "",
  intl: {
    fr: {
      locale: "fr",
      name: "",
      description: "",
    },
  },
}

const OptinsEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)
  const history = useHistory()

  const [optins, setOptins] = useState([])
  const [editedOptin, setEditedOptin] = useState(null)
  const [optin, setOptin] = useState(null)
  const [optinLoading, setOptinLoading] = useState(false)

  const [, , , { updateOptins }] = useOptinsApi()
  const [, , , { getOptins }] = useOptinsApi()
  const savedGetOptins = useCallback(getOptins, [])

  const isEditingOptin = !isEmpty(match.params)

  const getOptinFromSugarId = opt => {
    return opt.find(op => op.sugarCrmSmsKey === match.params.id)
  }

  const fetchOptins = useCallback(async () => {
    try {
      const resp = await savedGetOptins()
      const data = exportCollectionToArray(resp.data, "optinsSections")
      setOptins(data)
      if (isEditingOptin) {
        setOptin(getOptinFromSugarId(data))
        setEditedOptin(getOptinFromSugarId(data))
      } else setOptin(defaultOptin)
    } catch (error) {
      console.error(error)
    }
  }, [savedGetOptins])

  useEffect(() => {
    fetchOptins()
  }, [fetchOptins])

  const backToList = () => {
    history.push({
      pathname: "/optins",
      state: {},
    })
  }

  const handleOptinSubmit = async e => {
    e.preventDefault()
    let editedOptins = [...optins]

    setOptinLoading(true)

    try {
      if (isEditingOptin) {
        editedOptins = editedOptins.map(opt => {
          if (editedOptin.sugarCrmSmsKey === opt.sugarCrmSmsKey) {
            return optin
          }
          return opt
        })
      } else {
        editedOptins = [...editedOptins, optin]
      }

      const payload = {
        optinsSections: editedOptins,
      }

      await updateOptins(payload)
      setOptinLoading(false)
      history.push({
        pathname: "/optins",
        state: {
          message: `L'optin a bien été ${isEditing ? "édité" : "créé"}`,
          success: true,
        },
      })
    } catch (error) {
      //
      console.error(error)
      setOptinLoading(false)
    }
  }

  const isAllowedToEdit = rolePermissions[PERMISSIONS.PERM_OPTINS_UPDATE]

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <span className="d-inline-flex">
          <h1 className="mb-0">
            {`Optin / ${isEditingOptin ? "Éditer" : "Nouveau"}`}
          </h1>
        </span>
      </div>
      {optin && optins ? (
        <form onSubmit={handleOptinSubmit} className="mw-100 w-50 m-auto">
          <div className="form-row mb-2">
            <div className="col-sm-12 col-md-6 pb-2">
              <label htmlFor="admin-firstname">Clé Sugar CRM - SMS</label>
              <input
                disabled={!isAllowedToEdit}
                type="text"
                className="form-control"
                id="sugarCrmSmsKey"
                name="sugarCrmSmsKey"
                value={optin.sugarCrmSmsKey}
                onChange={e =>
                  setOptin({ ...optin, sugarCrmSmsKey: e.target.value })
                }
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 pb-2">
              <label htmlFor="admin-firstname">Clé Sugar CRM - Email</label>
              <input
                disabled={!isAllowedToEdit}
                type="text"
                className="form-control"
                id="sugarCrmEmailKey"
                name="sugarCrmEmailKey"
                value={optin.sugarCrmEmailKey}
                onChange={e =>
                  setOptin({ ...optin, sugarCrmEmailKey: e.target.value })
                }
                required
              />
            </div>
          </div>
          {["fr"].map(lang => (
            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">{lang}</h5>

                <div className="form-row mb-2">
                  <div className="col-sm-12 col-md-6 pb-2">
                    <label htmlFor="admin-firstname">Nom</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="text"
                      className="form-control"
                      id="admin-firstname"
                      name="firstname"
                      value={optin?.intl[lang].name}
                      onChange={e => {
                        const opt = { ...optin }
                        opt.intl[lang].name = e.target.value
                        setOptin(opt)
                      }}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                  <div className="col-sm-12 col-md-6 pb-2">
                    <label htmlFor="admin-lastname">Description</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="text"
                      className="form-control"
                      id="admin-lastname"
                      name="lastname"
                      value={optin?.intl[lang].description}
                      onChange={e => {
                        const opt = { ...optin }
                        opt.intl[lang].description = e.target.value
                        setOptin(opt)
                      }}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="mb-4">
            {!isAllowedToEdit ? (
              <button
                onClick={backToList}
                type="button"
                className="btn btn-secondary mr-2"
              >
                Revenir à la liste
              </button>
            ) : null}
            {!optins || !optins ? null : (
              <button
                type="submit"
                className="btn btn-success"
                disabled={optinLoading || !isAllowedToEdit}
              >
                {optinLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                VALIDER
              </button>
            )}
          </div>
        </form>
      ) : (
        <Spinner />
      )}
    </WithMenu>
  )
}

export default OptinsEdit
