// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useConfigsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getConfig = () => request("GET", `${API_STAGES_URL}/v1/config`)

  const updateConfig = config =>
    request("PUT", `${API_STAGES_URL}/v1/config`, config)

  const getPaymentDelayMaxConfig = () =>
    request("GET", `${API_STAGES_URL}/v1/config/payment-delay-max`)

  const updatePaymentDelayMaxConfig = config =>
    request("PUT", `${API_STAGES_URL}/v1/config/payment-delay-max`, config)

  return [
    requestState,
    payload,
    error,
    {
      getConfig,
      updateConfig,
      getPaymentDelayMaxConfig,
      updatePaymentDelayMaxConfig,
    },
  ]
}

export default useConfigsApi
