import React, { useEffect, useCallback, useMemo, useState } from "react"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useExportLogsApi from "../../../hooks/Api/useExportLogsApi"
import useExportTypesApi from "../../../hooks/Api/useExportTypesApi"
// import useAdminsApi from "../../../hooks/Api/useAdminsApi"
import useAdminsApi from "../../../hooks/Api/Authorization/useAdminsApi"

// Utils
import { exportCollectionToArray } from "../../../utils/collection"

import style from "./ExportLogsList.module.css"

const ExportLogsList = () => {
  const [
    requestStateEL,
    payloadEL,
    errorEL,
    { getExportLogs },
  ] = useExportLogsApi({
    trigger: true,
  })
  const [requestStateET, payloadET, , { getExportTypes }] = useExportTypesApi({
    trigger: true,
  })
  const [{ getAdmins }] = useAdminsApi()
  const [admins, setAdmins] = useState(null)

  const savedGetExportLogs = useCallback(getExportLogs, [])
  const savedGetExportTypes = useCallback(getExportTypes, [])
  const savedGetAdmins = useCallback(getAdmins, [])

  const fetchAdmins = useCallback(async () => {
    try {
      const adminsResp = await savedGetAdmins()
      setAdmins(exportCollectionToArray(adminsResp.data, "admins"))
    } catch (error) {
      //
    }
  }, [savedGetAdmins])

  useEffect(() => {
    savedGetExportTypes()
    fetchAdmins()
    savedGetExportLogs()
  }, [fetchAdmins, savedGetExportLogs, savedGetExportTypes])

  const exportTypes = useMemo(
    () => exportCollectionToArray(payloadET, "exportTypes"),
    [payloadET]
  )

  const getTableData = () => {
    let data = exportCollectionToArray(payloadEL, "exportLogs")
    data = data.map(log => {
      const exportType = exportTypes.find(eType => eType.id === log.typeId)

      return { ...log, exportType }
    })
    return data
  }

  const isDataReady =
    requestStateEL === "completed" &&
    payloadEL &&
    requestStateET === "completed" &&
    exportTypes &&
    admins !== null

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Export Logs</h1>
      </div>
      {errorEL ? (
        <div className="alert alert-errorEL" role="alert">
          {errorEL}
        </div>
      ) : null}
      {!isDataReady ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Table
          columns={[
            {
              Header: "Date",
              accessor: "date",
              Cell: ({ cell: { value } }) => <div>{value}</div>,
            },
            {
              Header: "Type",
              accessor: "exportType.name",
            },
            {
              Header: "Admin",
              accessor: "email",
            },
            {
              Header: "Filtres",
              accessor: "filters",
              Cell: ({ cell: { value } }) => <div>{value}</div>,
            },
          ]}
          data={getTableData()}
          hasPagination
          hasSort
          hasSearch
        />
      )}
    </WithMenu>
  )
}
export default ExportLogsList
