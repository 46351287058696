import React from "react"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"
import FidPointsAdmin from "../../../../components/molecules/Fid/FidPoints/FidPointsAdmin/FidPointsAdmin"
import FidPointsSearch from "../../../../components/molecules/Fid/FidPoints/FidPointsSearch/FidPointsSearch"
import FidPointsMember from "../../../../components/molecules/Fid/FidPoints/FidPointsMember/FidPointsMember"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"

import style from "./FidPoints.module.css"

const FidPoints = () => {
  const {
    setFidGlobalMessage,
    context: { fidActiveMember, fidGlobalMessage },
  } = useAppContext()

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Fidélité / Gestion des points</h1>
      </div>
      <FidPointsAdmin />
      {fidGlobalMessage ? (
        <GlobalMessage
          isActive={fidGlobalMessage.isActive}
          content={fidGlobalMessage.message}
          className={fidGlobalMessage.className}
          onClose={() =>
            setFidGlobalMessage({ ...fidGlobalMessage, isActive: false })
          }
        />
      ) : null}
      {fidActiveMember === null ? <FidPointsSearch /> : <FidPointsMember />}
    </WithMenu>
  )
}
export default FidPoints
