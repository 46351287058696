/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from "react"

// Utils
import Modal from "react-bootstrap4-modal"
import { Translation, useTranslation } from "react-i18next"
import { differenceInMonths } from "date-fns"
import { isNil } from "lodash"
import { genderOptions } from "../../../../utils/coach/staticValues"

// Components
import IDTooltip from "../../../../components/molecules/IDTooltip/IDTooltip"
import Tooltip from "../../../../components/molecules/Tooltip/Tooltip"
import { SelectInput, TextInput } from "../../../../components/atoms/Inputs/Inputs"
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

import style from "./Articles.module.css"

function Article(props) {
  const { t } = useTranslation()

  function handleButtonClick() {
    props.handleAction(props.article.id)
  }

  function handlePlayerChange(event) {
    props.changePlayer(props.article.id, event.target.value)
  }

  function handlePurchaseMotiveChange(event) {
    props.changePurchaseMotive(props.article.id, Number(event.target.value))
  }

  function handleCancellationMotiveChange(event) {
    props.changeCancellationMotive(props.article.id, Number(event.target.value))
  }

  function handleRefundAmountChange(event) {
    props.changeRefundAmount(props.article.id, event.target.value)
  }

  let colorClass = ""
  let insurance = props.article.insurance ? "Oui" : "Non"

  switch (props.article.status) {
    case "added":
      colorClass = "table-success"
      insurance = ""
      break
    case "cancelled":
      colorClass = "table-danger"
      break
    case "updated":
      colorClass = "table-warning"
      break
    default:
      break
  }

  const buttonColor =
    props.article.status === "former" ? "btn-danger" : "btn-warning"
  const buttonIcon = props.article.status === "former" ? "fa-minus" : "fa-undo"
  const textDecoration =
    props.article.status === "archived" ? "line-through" : "none"

  const displayDiscount = () => {
    const type = props.article.orderDiscountTypeId
    const value = props.article.orderDiscountValue
    switch (type) {
      case 1: {
        return `${Math.round((value + Number.EPSILON) * 100) / 100}€`
      }
      case 2: {
        return `${Math.round(value * 100)}%`
      }
      default:
        break
    }
  }

  const PriceTTCContent = ({ price, discount }) => (
    <div>
      <div>Prix facturé (remise incluse)</div>
      <div>{`${price}€ (${discount}% de remise)`} </div>
    </div>
  )

  return (
    <tr className={colorClass} style={{ textDecoration }}>
      <td className="align-middle">
        {props.weeks[props.article.weekId].start}
      </td>
      <td className="align-middle">
        <span className="d-inline-block">{props.article.invoiceNumber}</span>
      </td>
      <td className="align-middle">
        <IDTooltip uid={props.article.orderId} />
      </td>
      <td className="align-middle">
        {props.article.status !== "archived" &&
        props.article.status !== "frozen" ? (
          <select
            disabled={!props.canUserEdit}
            value={props.article.playerId}
            className="form-control form-control-sm"
            onChange={handlePlayerChange}
          >
            {props.orderedPlayersIds.map(playerId => (
              <option key={playerId} value={playerId}>
                {props.players[playerId].name}
              </option>
            ))}
          </select>
        ) : (
          <>{props.players[props.article.playerId].name}</>
        )}
      </td>
      <td className="align-middle">
        {props.familys[props.article.productFamilyId].name}
      </td>
      <td className="align-middle">
        {props.products[props.article.productId].name}
      </td>
      <td className="align-middle">{insurance}</td>
      <td className="align-middle">
        {props.article.status === "cancelled" && (
          <input
            type="number"
            min="0"
            step="0.01"
            value={props.article.refundAmount}
            onChange={handleRefundAmountChange}
            className="form-control form-control-sm"
          />
        )}
      </td>
      <td className="align-middle">
        {
          {
            cancelled: (
              <select
                disabled={!props.canUserEdit}
                value={props.article.cancellationMotiveId}
                className="form-control form-control-sm"
                onChange={handleCancellationMotiveChange}
              >
                <option key={-1} value={-1}>
                  Autre
                </option>
                {props.orderedMotivesIds.map(motiveId => {
                  return (
                    props.motives[motiveId].forCancellation && (
                      <option key={motiveId} value={motiveId}>
                        {props.motives[motiveId].code}
                      </option>
                    )
                  )
                })}
              </select>
            ),
            added: (
              <select
                disabled={!props.canUserEdit}
                value={props.article.purchaseMotiveId}
                className="form-control form-control-sm"
                onChange={handlePurchaseMotiveChange}
              >
                <option key={-1} value={-1}>
                  Autre
                </option>
                {props.orderedMotivesIds.map(motiveId => {
                  return (
                    props.motives[motiveId].forPurchase && (
                      <option key={motiveId} value={motiveId}>
                        {props.motives[motiveId].code}
                      </option>
                    )
                  )
                })}
              </select>
            ),
            archived:
              props.motives[props.article.cancellationMotiveId] &&
              props.motives[props.article.cancellationMotiveId].code,
            frozen:
              props.motives[props.article.purchaseMotiveId] &&
              props.motives[props.article.purchaseMotiveId].code,
            former:
              props.motives[props.article.purchaseMotiveId] &&
              props.motives[props.article.purchaseMotiveId].code,
          }[props.article.status]
        }
      </td>
      <td className="align-middle">
        {props.article.orderDiscountTypeId && props.article.orderDiscountValue
          ? displayDiscount()
          : ""}
      </td>
      <td className="align-middle text-right">
        <Tooltip
          copyContent={false}
          text={t("price", { price: props.article.priceInclTax })}
          content={
            <PriceTTCContent
              price={
                Math.round(props.article.discountedPriceInclTax * 100) / 100
              }
              discount={
                props.article.discountedPriceInclTax /
                  props.article.priceInclTax ===
                1
                  ? "0"
                  : Math.round(
                      ((props.article.priceInclTax -
                        props.article.discountedPriceInclTax) /
                        props.article.priceInclTax) *
                        100
                    )
              }
            />
          }
        />
      </td>
      {!props.canUserEdit && props.article.status === "former" ? null : (
        <td className="align-middle text-right">
          {props.article.status !== "archived" &&
            props.article.status !== "frozen" && (
              <button
                disabled={
                  !props.canUserEdit && props.article.status === "former"
                }
                type="button"
                onClick={handleButtonClick}
                className={`btn btn-sm ${buttonColor}`}
              >
                <i className={`fas fa-fw ${buttonIcon}`} />
              </button>
            )}
        </td>
      )}
    </tr>
  )
}

class Articles extends React.Component {
  constructor(props) {
    super(props)
    this.props.motives[-1] = { code: "" }

    const articles = JSON.parse(JSON.stringify(this.props.articles))
    for (const article of articles) {
      if (article.status === "VALIDATED") {
        const start = new Date(this.props.weeks[article.weekId].start)
        const frozen = start.getUTCFullYear() < new Date().getUTCFullYear()
        article.status = frozen ? "frozen" : "former"
      } else {
        article.status = "archived"
      }
      article.formerPlayerId = article.playerId
      this.calcRefundAmount(article)
    }

    this.sortArticles(articles)

    const weekId = this.props.orderedFutureWeeksIds[0]
    const familyId = this.props.orderedFamilysIds[0]
    const playerId = this.props.orderedPlayersIds[0]
    const productsIds = this.props.stocks[weekId][familyId]
    const productId = this.getDefaultProductFromList(productsIds)
    const priceInclTax = this.props.prices[weekId][productId]

    this.state = {
      showInsuranceConfirmModal: false,
      articles,
      weekId,
      playerId,
      familyId,
      productsIds,
      productId,
      priceInclTax,
      purchaseMotiveId: -1,
      insurance: false,
      offerInsurance: false,
      cancellationsInsurance: false,
      discountId: -1,
      discountCode: "",
      discountTypeId: this.props.orderedDiscountTypesIds[0],
      discountValue: "0.00",
      clientLocaleId: this.props.orderedLocalesIds[0],
      preventEmailSending: false,
      comment: "",
      billingAddress: {
        address: this.props.billingAddress.address,
        zipCode: this.props.billingAddress.zipCode,
        city: this.props.billingAddress.city,
        country: this.props.billingAddress.country,
        save: false,
      },
      showPlayerTennisDataModal: false,
      currentPlayerTennisData: null,
      currentPlayerGenderFilled: false,
      currentPlayerNeedsTennisDataVerification: false,
      playersTennisDataChecked: [],
    }

    this.nextAddedArticleId = 0
  }

  getDefaultProductFromList = productIds => {
    const product = productIds.find(
      productId => !this.props.products[productId].archived
    )
    if (product === undefined) return productIds[0]
    return product
  }

  calcRefundAmount = article => {
    const now = new Date()

    if (article.insurance) {
      article.refundAmount = article.priceInclTax
    } else {
      const start = new Date(this.props.weeks[article.weekId].start)
      const daysDiff = (start - now) / (1000 * 60 * 60 * 24)

      if (daysDiff < 15) {
        article.refundAmount = 0
      } else if (daysDiff < 30) {
        article.refundAmount = 0.5 * article.priceInclTax
      } else {
        article.refundAmount = 0.75 * article.priceInclTax
      }
    }
  }

  sortArticles = articles => {
    articles.sort((a, b) => {
      const properties = [
        { name: "weekId", orderedIds: this.props.orderedWeeksIds },
        { name: "playerId", orderedIds: this.props.orderedPlayersIds },
        { name: "productFamilyId", orderedIds: this.props.orderedFamilysIds },
        { name: "productId", orderedIds: this.props.orderedProductsIds },
      ]

      for (const property of properties) {
        if (a[property.name] !== b[property.name]) {
          const needle = [a[property.name], b[property.name]]
          const id = property.orderedIds.find(id => needle.includes(id))
          return a[property.name] === id ? -1 : 1
        }
      }

      return 0
    })
  }

  handleWeekIdChange = event => {
    const weekId = Number(event.target.value)

    this.setState(state => {
      const productsIds = this.props.stocks[weekId][state.familyId]
      const productId = this.getDefaultProductFromList(productsIds)
      const priceInclTax = this.props.prices[weekId][productId]

      return {
        weekId,
        productId,
        productsIds,
        priceInclTax,
      }
    })
  }

  handlePlayerIdChange = event => {
    this.setState({ playerId: event.target.value })
  }

  handleFamilyIdChange = event => {
    const familyId = Number(event.target.value)

    this.setState(state => {
      const productsIds = this.props.stocks[state.weekId][familyId]
      const productId = this.getDefaultProductFromList(productsIds)
      const priceInclTax = this.props.prices[state.weekId][productId]

      return {
        familyId,
        productId,
        productsIds,
        priceInclTax,
      }
    })
  }

  handleProductIdChange = event => {
    const productId = event.target.value

    this.setState(state => {
      const priceInclTax = this.props.prices[state.weekId][productId]

      return {
        productId,
        priceInclTax,
      }
    })
  }

  handlePurchaseMotiveIdChange = event => {
    this.setState({ purchaseMotiveId: Number(event.target.value) })
  }

  handleAddArticleToList = () => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))

      articles.push({
        id: this.nextAddedArticleId--,
        weekId: state.weekId,
        playerId: state.playerId,
        productFamilyId: state.familyId,
        productId: state.productId,
        priceInclTax: this.props.prices[state.weekId][state.productId],
        purchaseMotiveId: state.purchaseMotiveId,
        cancellationMotiveId: -1,
        status: "added",
      })

      this.sortArticles(articles)

      return { articles }
    })
  }

  handleAddClick = () => {
    if (this.state.familyId !== 1) {
      this.handleAddArticleToList();
      return;
    }

    const selectedPlayer = this.props.players[this.state.playerId];
    const playerAlreadyVerified = this.state.playersTennisDataChecked.some((id) => id === this.state.playerId);

    // Check if tennis data need to be verifed
    const now = new Date();
    const lastTennisDataUpdate = new Date(selectedPlayer?.lastTennisPracticeDataUpdate);
    const delay = differenceInMonths(now, lastTennisDataUpdate);
    const tennisDataNeedVerification = isNil(delay) || delay > 6;

    // Check that the gender is specified
    const genderIsFilled = !isNil(selectedPlayer.sexId);

    if ((tennisDataNeedVerification || !genderIsFilled) && !playerAlreadyVerified) {
      this.setState((state) => ({
        showPlayerTennisDataModal: true,
        currentPlayerTennisData: this.props.players[state.playerId],
        currentPlayerGenderFilled: genderIsFilled,
        currentPlayerNeedsTennisDataVerification: tennisDataNeedVerification,
      }))
    } else {
      this.handleAddArticleToList();
    }
  }

  handleAction = articleId => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))
      const index = articles.findIndex(article => article.id === articleId)
      const article = articles[index]

      switch (article.status) {
        case "former":
          article.status = "cancelled"
          break
        case "added":
          articles.splice(index, 1)
          break
        case "cancelled":
          article.status = "former"
          this.calcRefundAmount(article)
          break
        case "updated":
          article.playerId = article.formerPlayerId
          article.status = "former"
          break
        default:
          break
      }

      return { articles }
    })
  }

  changePlayer = (articleId, playerId) => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))
      const article = articles.find(article => article.id === articleId)

      if (article.status !== "cancelled") {
        article.playerId = playerId
      }

      if (article.status === "former") {
        article.status = "updated"
      }

      return { articles }
    })
  }

  changePurchaseMotive = (articleId, motiveId) => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))
      const article = articles.find(article => article.id === articleId)

      article.purchaseMotiveId = motiveId

      return { articles }
    })
  }

  changeCancellationMotive = (articleId, motiveId) => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))
      const article = articles.find(article => article.id === articleId)

      article.cancellationMotiveId = motiveId

      return { articles }
    })
  }

  changeRefundAmount = (articleId, refundAmount) => {
    this.setState(state => {
      const articles = JSON.parse(JSON.stringify(state.articles))
      const article = articles.find(article => article.id === articleId)

      if (refundAmount > article.priceInclTax) {
        return
      }

      article.refundAmount = refundAmount

      return { articles }
    })
  }

  toggleInsurance = () => {
    this.setState(state => ({
      insurance: false,
      offerInsurance: false,
      showInsuranceConfirmModal: !state.insurance && true,
    }))
  }

  toggleCancellationsInsurance = () => {
    this.setState(state => ({
      cancellationsInsurance: !state.cancellationsInsurance,
    }))
  }

  handleCloseInsuranceConfirmModal = () => {
    this.setState({ showInsuranceConfirmModal: false })
  }

  handleClosePlayerTennisDataModal = () => {
    this.setState({ showPlayerTennisDataModal: false })
  }

  handleConfirmInsurance = () => {
    this.setState(state => ({
      insurance: !state.insurance,
      showInsuranceConfirmModal: false,
    }))
  }

  handleConfirmPlayerTennisData = () => {
    this.props.putClientPlayerTennisData(
      this.props.clientId,
      this.state.playerId,
      {
        ...this.state.currentPlayerTennisData.tennisData,
        sexId: this.state.currentPlayerTennisData.sexId
      }
    ).then((res) => {
      if (res?.status === 204) {
        this.setState((state) => ({
          ...state,
          showPlayerTennisDataModal: false,
          playersTennisDataChecked: state.playersTennisDataChecked.concat([this.state.playerId])
        }))
        this.handleAddArticleToList();
      }
    });
  }

  handleTennisDataChange = (key, value) => {
    this.setState((state) => ({ 
      ...state, 
      currentPlayerTennisData: {
        ...state.currentPlayerTennisData,
        tennisData: {
          ...state.currentPlayerTennisData.tennisData,
          [key]: value,
        }
      }
    }));
  }

  handlePlayerGenderChange = (value) => {
    this.setState((state) => ({ 
      ...state, 
      currentPlayerTennisData: {
        ...state.currentPlayerTennisData,
        sexId: value ? +value : null
      }
    }));
  }

  handleDiscountChange = event => {
    const discountId = Number(event.target.value)

    if ([-1, 0].includes(discountId)) {
      this.setState({
        discountId,
        discountCode: "",
        discountTypeId: this.props.orderedDiscountTypesIds[0],
        discountValue: "0.00",
      })
    } else {
      this.setState({
        discountId,
        discountCode: this.props.discounts[discountId].code,
        discountTypeId: this.props.discounts[discountId].typeId,
        discountValue: this.props.discounts[discountId].value.toFixed(2),
      })
    }
  }

  handleDiscountCodeChange = event => {
    this.setState({ discountCode: event.target.value })
  }

  handleDiscountTypeIdChange = event => {
    this.setState({ discountTypeId: Number(event.target.value) })
  }

  handleDiscountValueChange = event => {
    this.setState({ discountValue: event.target.value })
  }

  handleClientLocaleChange = event => {
    this.setState({ clientLocaleId: Number(event.target.value) })
  }

  handleAddressChange = event => {
    const { name } = event.target
    const { value } = event.target

    this.setState(state => {
      const address = JSON.parse(JSON.stringify(state.billingAddress))
      address[name] = value
      return { billingAddress: address }
    })
  }

  toggleBillingAddressSave = () => {
    this.setState(state => {
      const address = JSON.parse(JSON.stringify(state.billingAddress))
      address.save = !address.save
      return { billingAddress: address }
    })
  }

  handleOrderCommentChange = event => {
    this.setState({ comment: event.target.value })
  }

  togglePreventEmailSending = () => {
    this.setState(state => {
      return { preventEmailSending: !state.preventEmailSending }
    })
  }

  handleSubmit = () => {
    this.setState(state => {
      const data = {
        additions: [],
        updates: [],
        cancellations: [],
        insurance: state.insurance,
        freeInsurance: state.offerInsurance,
        cancellationsInsurance: state.cancellationsInsurance,
        discountId: state.discountId,
        localeId: state.clientLocaleId,
        billingAddress: state.billingAddress,
        comment: state.comment,
        preventEmailSending: state.preventEmailSending,
      }

      if (state.discountId === 0) {
        data.oneshot = {
          code: state.discountCode,
          typeId: state.discountTypeId,
          value: Number(state.discountValue),
        }
      }

      for (const article of state.articles) {
        switch (article.status) {
          case "added":
            data.additions.push({
              weekId: article.weekId,
              playerId: article.playerId,
              productId: article.productId,
              purchaseMotiveId:
                article.purchaseMotiveId !== -1
                  ? article.purchaseMotiveId
                  : null,
            })
            break
          case "updated":
            data.updates.push({
              articleId: article.id,
              playerId: article.playerId,
            })
            break
          case "cancelled":
            data.cancellations.push({
              articleId: article.id,
              refundAmount: Number(article.refundAmount),
              cancellationMotiveId:
                article.cancellationMotiveId !== -1
                  ? article.cancellationMotiveId
                  : null,
            })
            break
          default:
            break
        }
      }

      // From now on, use the api call
      this.props.handleSubmitArticles(data)
      return {}
    })
  }

  isFormValid = () => {
    const { billingAddress } = this.state
    if (
      billingAddress.address &&
      billingAddress.city &&
      billingAddress.country &&
      billingAddress.zipCode
    )
      return true
    return false
  }

  getCancelledArticlesInsuranceTotal = () => {
    if (!this.state.cancellationsInsurance) return 0
    const cancelledArticles = this.state.articles.filter(
      a => a.status === "cancelled" && !a.freeInsurance
    )

    const totalPriceIncTax = cancelledArticles.length
      ? cancelledArticles.reduce((acc, curVal) => {
          const price = curVal.discountedPriceInclTax
          return acc + price
        }, 0)
      : 0

    const totalInsurance = Math.round(totalPriceIncTax * 0.07 * 100) / 100

    return totalInsurance ? -Math.abs(totalInsurance) : 0
  }

  render = () => {
    const totals = this.state.articles.reduce(
      (accumulator, article) => {
        switch (article.status) {
          case "added":
            accumulator.added += article.priceInclTax
            break
          case "cancelled":
            accumulator.cancelled -= article.refundAmount
            break
          default:
            break
        }

        return accumulator
      },
      { added: 0, cancelled: 0 }
    )

    let discountAmount = 0
    if (this.state.discountId !== -1) {
      const value = Number(this.state.discountValue)

      switch (this.state.discountTypeId) {
        case 1: // ABSOLUTE
          discountAmount = -value
          break
        case 2: // RELATIVE
          discountAmount = -value * totals.added
          break
        default:
          break
      }
    }

    let insuranceAmount = 0
    if (this.state.insurance) {
      insuranceAmount = 0.07 * (totals.added + discountAmount)
    }

    const insuranceTotal = this.state.offerInsurance ? 0 : insuranceAmount
    const cancelledArticlesInsuranceTotal = this.getCancelledArticlesInsuranceTotal()

    const operation =
      totals.added +
      totals.cancelled +
      discountAmount +
      insuranceTotal +
      cancelledArticlesInsuranceTotal

    const balance = this.props.balance - operation
    const buttonIcon = this.props.orderSyncing
      ? "fa-circle-notch fa-spin"
      : "fa-check"

    let isDiscountOverTotal
    if (this.state.discountTypeId === 1) {
      isDiscountOverTotal = !!(Number(this.state.discountValue) > totals.added)
    } else {
      isDiscountOverTotal = !!(Number(this.state.discountValue) > 1.0)
    }

    const isUserReadOnly =
      !this.props.rolePermissions[PERMISSIONS.PERM_ORDERS_ARTICLES_CREATE] &&
      !this.props.rolePermissions[PERMISSIONS.PERM_ORDERS_ARTICLES_UPDATE]

    const canUserCreate = this.props.rolePermissions[
      PERMISSIONS.PERM_ORDERS_ARTICLES_CREATE
    ]
    const canUserEdit = this.props.rolePermissions[
      PERMISSIONS.PERM_ORDERS_ARTICLES_UPDATE
    ]

    return (
      <React.StrictMode>
        <Translation>
          {t => (
            <div className={style.list}>
              <table className="table table-sm mb-4">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>N° Facture</th>
                    <th>ID Commande</th>
                    <th>Joueur</th>
                    <th>Type</th>
                    <th>Produit</th>
                    <th>Assurance</th>
                    <th>Remboursement</th>
                    <th>Motif</th>
                    <th>Remise</th>
                    <th>Prix TTC</th>
                    {canUserCreate || canUserEdit ? <th /> : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.articles.map(article => (
                    <Article
                      key={article.id}
                      article={article}
                      weeks={this.props.weeks}
                      players={this.props.players}
                      products={this.props.products}
                      familys={this.props.familys}
                      motives={this.props.motives}
                      orderedPlayersIds={this.props.orderedPlayersIds}
                      orderedMotivesIds={this.props.orderedMotivesIds}
                      handleAction={this.handleAction}
                      changePlayer={this.changePlayer}
                      changeRefundAmount={this.changeRefundAmount}
                      changePurchaseMotive={this.changePurchaseMotive}
                      changeCancellationMotive={this.changeCancellationMotive}
                      canUserEdit={canUserEdit}
                    />
                  ))}
                  {canUserCreate ? (
                    <tr className="table-secondary">
                      <td>
                        <select
                          disabled={!canUserCreate}
                          value={this.state.weekId}
                          onChange={this.handleWeekIdChange}
                          className="form-control form-control-sm"
                        >
                          {this.props.orderedFutureWeeksIds.map(weekId => (
                            <option key={weekId} value={weekId}>
                              {this.props.weeks[weekId].start}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td />
                      <td />
                      <td>
                        <select
                          disabled={!canUserCreate}
                          value={this.state.playerId}
                          onChange={this.handlePlayerIdChange}
                          className="form-control form-control-sm"
                        >
                          {this.props.orderedPlayersIds.map(playerId => (
                            <option key={playerId} value={playerId}>
                              {this.props.players[playerId].name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          disabled={!canUserCreate}
                          value={this.state.familyId}
                          onChange={this.handleFamilyIdChange}
                          className="form-control form-control-sm"
                        >
                          {this.props.orderedFamilysIds.map(familyId => (
                            <option key={familyId} value={familyId}>
                              {this.props.familys[familyId].name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          disabled={!canUserCreate}
                          value={this.state.productId}
                          onChange={this.handleProductIdChange}
                          className="form-control form-control-sm"
                        >
                          {this.state.productsIds
                            .filter(
                              productId =>
                                !this.props.products[productId].archived
                            )
                            .map(productId => (
                              <option key={productId} value={productId}>
                                {this.props.products[productId].name}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td />
                      <td />
                      <td>
                        <select
                          disabled={!canUserCreate}
                          value={this.state.purchaseMotiveId}
                          className="form-control form-control-sm"
                          onChange={this.handlePurchaseMotiveIdChange}
                        >
                          <option key={0} value={0}>
                            Autre
                          </option>
                          {this.props.orderedMotivesIds.map(motiveId => {
                            return (
                              this.props.motives[motiveId].forPurchase && (
                                <option key={motiveId} value={motiveId}>
                                  {this.props.motives[motiveId].code}
                                </option>
                              )
                            )
                          })}
                        </select>
                      </td>
                      <td />
                      <td className="align-middle text-right">
                        {t("price", { price: this.state.priceInclTax })}
                      </td>
                      <td className="align-middle text-right">
                        <button
                          disabled={!canUserCreate}
                          type="button"
                          onClick={this.handleAddClick}
                          className="btn btn-primary btn-sm btn-success"
                        >
                          <i className="fas fa-fw fa-plus" />
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <GlobalMessage
                isActive={this.props.globalMessage.isActive || false}
                content={this.props.globalMessage.message}
                className={this.props.globalMessage.className}
                onClose={this.props.handleMessageClose}
              />
              <div className="row mt-3">
                <div className="col-6">
                  <table className="table table-sm font-weight-bold">
                    <tbody>
                      <tr>
                        <td>Assurance</td>
                        <td>
                          <div
                            onClick={this.toggleInsurance}
                            className="custom-control custom-switch"
                          >
                            <input
                              disabled={isUserReadOnly}
                              type="checkbox"
                              checked={this.state.insurance}
                              className="custom-control-input"
                              readOnly
                            />
                            <label className="custom-control-label" />
                          </div>
                        </td>
                      </tr>

                      {this.state.insurance ? (
                        <tr>
                          <td>Offrir assurance</td>
                          <td>
                            <div
                              onClick={() =>
                                this.setState(state => ({
                                  offerInsurance: !state.offerInsurance,
                                }))
                              }
                              className="custom-control custom-switch"
                            >
                              <input
                                disabled={isUserReadOnly}
                                type="checkbox"
                                checked={this.state.offerInsurance}
                                className="custom-control-input"
                                readOnly
                              />
                              <label className="custom-control-label" />
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td className="align-middle">Promo</td>
                        <td>
                          <select
                            disabled={isUserReadOnly}
                            onChange={this.handleDiscountChange}
                            className="form-control form-control-sm"
                          >
                            <option value={-1}>-- AUCUNE --</option>
                            <option value={0}>-- CUSTOM --</option>
                            {this.props.orderedDiscountsIds.map(discountId => (
                              <option key={discountId} value={discountId}>
                                {this.props.discounts[discountId].code}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-4">Nom</td>
                        <td>
                          <input
                            type="text"
                            value={this.state.discountCode}
                            onChange={this.handleDiscountCodeChange}
                            disabled={isUserReadOnly || this.state.discountId !== 0}
                            className="form-control form-control-sm"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-4">Type</td>
                        <td>
                          <select
                            value={this.state.discountTypeId}
                            onChange={this.handleDiscountTypeIdChange}
                            disabled={isUserReadOnly ||this.state.discountId !== 0}
                            className="form-control form-control-sm"
                          >
                            {this.props.orderedDiscountTypesIds.map(
                              discountTypeId => (
                                <option
                                  key={discountTypeId}
                                  value={discountTypeId}
                                >
                                  {
                                    this.props.discountTypes[discountTypeId]
                                      .name
                                  }
                                </option>
                              )
                            )}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-4">Valeur</td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            step="0.01"
                            value={this.state.discountValue}
                            onChange={this.handleDiscountValueChange}
                            disabled={isUserReadOnly || this.state.discountId !== 0}
                            className="form-control form-control-sm"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Annulation assurance ?</td>
                        <td>
                          <div
                            onClick={this.toggleCancellationsInsurance}
                            className="custom-control custom-switch"
                          >
                            <input
                              disabled={isUserReadOnly}
                              type="checkbox"
                              checked={this.state.cancellationsInsurance}
                              className="custom-control-input"
                              readOnly
                            />
                            <label className="custom-control-label" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm font-weight-bold">
                    <tbody>
                      <tr>
                        <td>Total articles ajoutés</td>
                        <td className="text-right">
                          {t("price", { price: totals.added })}
                        </td>
                      </tr>
                      <tr>
                        <td>Total articles supprimés</td>
                        <td className="text-right">
                          {t("price", { price: totals.cancelled })}
                        </td>
                      </tr>
                      <tr>
                        <td>Promo</td>
                        <td className="text-right">
                          {t("price", { price: discountAmount })}
                        </td>
                      </tr>
                      <tr>
                        <td>{`Assurance ${
                          this.state.offerInsurance
                            ? `offerte (${t("price", {
                                price: insuranceAmount,
                              })})`
                            : ""
                        }`}</td>
                        <td className="text-right">
                          {t("price", {
                            price: insuranceTotal,
                          })}
                        </td>
                      </tr>

                      {this.state.cancellationsInsurance ? (
                        <tr>
                          <td>Annulation assurance</td>
                          <td className="text-right">
                            {t("price", {
                              price: cancelledArticlesInsuranceTotal,
                            })}
                          </td>
                        </tr>
                      ) : null}

                      <tr className="table-primary">
                        <td>Total opération</td>
                        <td className="text-right">
                          {t("price", { price: operation })}
                        </td>
                      </tr>
                      <tr>
                        <td>Balance actuelle</td>
                        <td className="text-right">
                          {t("price", { price: this.props.balance })}
                        </td>
                      </tr>
                      <tr className="table-primary">
                        <td>Balance après opération</td>
                        <td className="text-right">
                          {t("price", { price: balance })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table className="table table-sm font-weight-bold">
                <tbody>
                  <tr>
                    <td>Adresse *</td>
                    <td>
                      <input
                        disabled={isUserReadOnly}
                        type="text"
                        name="address"
                        value={this.state.billingAddress.address}
                        onChange={this.handleAddressChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Code postal *</td>
                    <td>
                      <input
                        disabled={isUserReadOnly}
                        type="text"
                        name="zipCode"
                        value={this.state.billingAddress.zipCode}
                        onChange={this.handleAddressChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Ville *</td>
                    <td>
                      <input
                        disabled={isUserReadOnly}
                        type="text"
                        name="city"
                        value={this.state.billingAddress.city}
                        onChange={this.handleAddressChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Pays *</td>
                    <td>
                      <input
                        disabled={isUserReadOnly}
                        type="text"
                        name="country"
                        value={this.state.billingAddress.country}
                        onChange={this.handleAddressChange}
                        className="form-control form-control-sm"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Enregistrer l'adresse de facturation</td>
                    <td>
                      <input
                        disabled={isUserReadOnly}
                        type="checkbox"
                        checked={this.state.billingAddress.save}
                        onChange={this.toggleBillingAddressSave}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Langue *</td>
                    <td>
                      <select
                        disabled={isUserReadOnly}
                        value={this.state.clientLocale}
                        onChange={this.handleClientLocaleChange}
                        className="form-control form-control-sm"
                      >
                        {this.props.orderedLocalesIds.map(localeId => (
                          <option key={localeId} value={localeId}>
                            {this.props.locales[localeId].code}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>Commentaire</td>
                    <td>
                      <textarea
                        disabled={isUserReadOnly}
                        value={this.state.comment}
                        onChange={this.handleOrderCommentChange}
                        className="form-control form-control-sm"
                        rows="3"
                      />
                    </td>
                  </tr>

                  {/* TODO: No email sending when preordering, to remove */}
                  {/* <tr>
                    <td>
                      Ne pas envoyer l&apos;email de confirmation de commande
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={this.state.preventEmailSending}
                        onChange={this.togglePreventEmailSending}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmit}
                disabled={
                  !this.isFormValid() || isDiscountOverTotal || isUserReadOnly
                }
              >
                <i className={`fas fa-fw ${buttonIcon}`} /> Valider
              </button>
              {isDiscountOverTotal ? (
                <div className={style.submitErrorLabel}>
                  Le code promo sélectionné est supérieur au montant total de la
                  commande !
                </div>
              ) : null}

              <Modal
                visible={this.state.showInsuranceConfirmModal}
                dialogClassName="modal-confirmation modal-confirm-insurance"
              >
                <div className="modal-header">
                  <div className="text-xl modal-header-title">
                    <b>Confirmer l'Assurance</b>
                  </div>
                  <div
                    className="close"
                    data-dismiss="modal"
                    onClick={this.handleCloseInsuranceConfirmModal}
                  >
                    <span className="icon-cross" aria-hidden="true" />
                    <span className="sr-only">Close</span>
                  </div>
                </div>

                <div className="modal-body confirm-finalize-body">
                  <p>Souhaitez-vous ajouter l'assurance annulation ?</p>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-default btn-md"
                    onClick={this.handleCloseInsuranceConfirmModal}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-primary btn-md"
                    onClick={this.handleConfirmInsurance}
                  >
                    Confirmer
                  </button>
                </div>
              </Modal>

              <Modal
                visible={this.state.showPlayerTennisDataModal}
                dialogClassName="modal-confirmation modal-confirm-tennis-data"
              >
                <div className="modal-header">
                  <div className="text-xl modal-header-title">
                    <b>
                      {`Vérifier les informations du joueur ${this.state.currentPlayerTennisData?.name}`}
                    </b>
                  </div>
                  <div
                    className="close"
                    data-dismiss="modal"
                    onClick={this.handleClosePlayerTennisDataModal}
                  >
                    <span className="icon-cross" aria-hidden="true" />
                    <span className="sr-only">Close</span>
                  </div>
                </div>

                <div className="modal-body confirm-finalize-body">
                  <div className="alert alert-warning" role="alert">
                    Merci de compléter les informations suivantes et/ou de vérifier leur exactitude
                  </div>

                  {!this.state.currentPlayerGenderFilled ? (
                    <div className="mb-2">
                      <SelectInput
                        id="playerGender"
                        value={this.state.currentPlayerTennisData?.sexId || null}
                        onChange={(e) => this.handlePlayerGenderChange(e.target.value)}
                        name="playerGender"
                        label="Veuillez indiquer le genre du joueur"
                        options={[
                          <option key="default" value="">
                            Genre
                          </option>,
                          genderOptions.map((gender) => (
                            <option key={gender.value} value={gender.value}>
                              {gender.label}
                            </option>
                          ))
                        ]}
                      />
                    </div>
                  ) : null}

                  {this.state.currentPlayerNeedsTennisDataVerification ? (
                    <div className="form-row mt-2">
                      <div className="col-sm-12">
                        {/* YEARS */}
                        <div className="mt-3">
                          <TextInput
                            type="number"
                            label="En quelle année le joueur a-t-il commencé la pratique ? *"
                            id="tennis_startYear"
                            name="startYear"
                            value={!isNil(this.state.currentPlayerTennisData?.tennisData?.startYear) ? this.state.currentPlayerTennisData.tennisData.startYear : ''}
                            onChange={(e) => {
                              this.handleTennisDataChange(
                                'startYear', 
                                e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : ''
                              )
                            }}
                            required
                          />
                        </div>

                        {/* HOURS PER WEEK */}
                        <div className="mt-3">
                          <TextInput
                            type="number"
                            label="Combien d'heures le joueur joue-t-il par semaine ? *"
                            id="tennis_weeklyHours"
                            name="weeklyHours"
                            value={!isNil(this.state.currentPlayerTennisData?.tennisData?.weeklyHours) ? this.state.currentPlayerTennisData.tennisData.weeklyHours : ''}
                            onChange={(e) => {
                              this.handleTennisDataChange(
                                'weeklyHours', 
                                e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : ''
                              )
                            }}
                            required
                          />
                        </div>

                        {/* PARTICIPATIONS PER YEAR */}
                        <div className="mt-3">
                          <TextInput
                            type="number"
                            label="À combien de matchs le joueur participe-t-il par an ? *"
                            id="tennis_yearlyMatches"
                            name="yearlyMatches"
                            value={!isNil(this.state.currentPlayerTennisData?.tennisData?.yearlyMatches) ? this.state.currentPlayerTennisData.tennisData.yearlyMatches : ''}
                            onChange={(e) => {
                              this.handleTennisDataChange(
                                'yearlyMatches', 
                                e.target.value !== '' ? (Number(e.target.value) >= 0 ? Number(e.target.value) : 0) : ''
                              )
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.props.putClientPlayerError?.data ? (
                    <div className="alert alert-danger mb-2 mt-4" role="alert">
                      {this.props.putClientPlayerError?.data?.description ? (
                        this.props.putClientPlayerError.data.description
                      ) : (
                        <>Une erreur s'est produite lors de la sauvergarde</>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-default btn-md"
                    onClick={this.handleClosePlayerTennisDataModal}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-primary btn-md"
                    onClick={this.handleConfirmPlayerTennisData}
                    disabled={
                      this.props.putClientPlayerState === 'loading'
                      || isNil(this.state.currentPlayerTennisData?.tennisData?.startYear)
                      || (this.state.currentPlayerTennisData?.tennisData?.startYear === '')
                      || isNil(this.state.currentPlayerTennisData?.tennisData?.weeklyHours)
                      || (this.state.currentPlayerTennisData?.tennisData?.weeklyHours === '')
                      || isNil(this.state.currentPlayerTennisData?.tennisData?.yearlyMatches)
                      || (this.state.currentPlayerTennisData?.tennisData?.yearlyMatches === '')
                      || isNil(this.state.currentPlayerTennisData.sexId)
                    }
                  >
                    {this.props.putClientPlayerState === 'loading' ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    Confirmer et valider
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </Translation>
      </React.StrictMode>
    )
  }
}

export default Articles
