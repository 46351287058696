import { differenceInYears, isValid, parse } from "date-fns";
import { isNumber } from "lodash";

// Checks if player has legal age (+18)
export const hasPlayerLegalAge = (birthdate) => {
  if (!birthdate) return false;

  const date = isValid(birthdate) ? birthdate : parse(birthdate, 'yyyy-MM-dd', new Date());
  if (!isValid(date)) return false;

  const today = new Date();
  today.setHours(0,0,0,0);
  const age = differenceInYears(today, date);

  return isNumber(age) && age >= 18;
};
