import React from "react"
import PropTypes from "prop-types"

// utils & misc
import cn from "../../../utils/cn"

const GlobalMessage = ({ isActive, content, className, onClose }) => (
  <>
    {isActive ? (
      <div className={cn([className, "alert-dismissible"])} role="alert">
        {content}
        {onClose ? (
          <button
            onClick={onClose}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        ) : null}
      </div>
    ) : null}
  </>
)

GlobalMessage.propTypes = {
  isActive: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}
GlobalMessage.defaultProps = {
  content: "",
  className: "alert alert-primary",
}

export default GlobalMessage
