import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { isEmpty, omit } from "lodash"

const AppContext = React.createContext([{}, () => {}])

const initStore = {
  auth: {},
  messages: [],
  searchedClients: [],
}

const nonSavedValues = {
  fidActiveMember: null,
  fidEmployee: null,
  fidGlobalMessage: {
    isActive: false,
    message: null,
    className: null,
  },
  userInfo: null,
  currentCoachWeek: null,
}

const AppProvider = ({ children }) => {
  const [state, setState] = useState(
    { ...JSON.parse(localStorage.getItem("mrtgStore")), ...nonSavedValues } ||
      initStore
  )

  useEffect(() => {
    try {
      const dataToStore = omit(!isEmpty(state) ? state : initStore, ['searchedClients'])
      localStorage.setItem("mrtgStore", JSON.stringify(dataToStore))      
    } catch (error) {
      console.error("[ERROR] - error occurred during localStorage setItem")
      console.error(error)
    }
  }, [state])

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppContext, AppProvider }
