/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"

// Components
import Spinner from "../../../../atoms/Spinner/Spinner"
import { TextInput, SelectInput } from "../../../../atoms/Inputs/Inputs"
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"

// Hooks
import useFidEstablishments from "../../../../../hooks/Api/Fid/useFidEstablishments"

// import useAdminsApi from "../../../../../hooks/Api/useAdminsApi"
import useAdminsApi from "../../../../../hooks/Api/Authorization/useAdminsApi"
import useCategoriesApi from "../../../../../hooks/Api/Authorization/useCategoriesApi"
import useRolesApi from "../../../../../hooks/Api/Authorization/useRolesApi"

// Constants
import { PERMISSIONS } from "../../../../../constants/constants"

// Utils
import cn from "../../../../../utils/cn"
import { exportCollectionToArray } from "../../../../../utils/collection"

// Data
import { initialEstablishment } from "../form-initial-values"

const EDITING = "editing"

// COMPONENT
const FidAdminEstablishments = () => {
  const [establishments, setEstablishments] = useState([])
  const [fidAdmins, setFidAdmins] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [establishmentsEdition, setEstablishmentsEdition] = useState([])
  const [
    { getFidEstablishments, createFidEstablishment, updateFidEstablishment },
  ] = useFidEstablishments()
  const [{ getAdmins }] = useAdminsApi()
  const [{ getCategories }] = useCategoriesApi()
  const [{ getRoles }] = useRolesApi()

  const getAdminsMem = useCallback(getAdmins, [])
  const getFidEstablishmentsMem = useCallback(getFidEstablishments, [])
  const getCategoriesMem = useCallback(getCategories, [])
  const getRolesMem = useCallback(getRoles, [])
  const handleCRUDRequestError = error => {
    if (error.response) {
      switch (error.response.status) {
        case 500: {
          setGlobalMessage({
            ...globalMessage,
            isActive: true,
            message: (
              <span>Une erreur est survenue au moment de la soumission</span>
            ),
            className: "alert alert-danger",
          })
          break
        }
        case 400:
          {
            const errorsList =
              error.response.data && error.response.data.errors
                ? Object.keys(error.response.data.errors).map(errorName => ({
                    field: errorName,
                    message: error.response.data.errors[errorName],
                  }))
                : null

            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <ul className="m-0">
                  {errorsList
                    ? errorsList.map(err => (
                        <li>
                          <b>{err.field}: </b>
                          {err.message || "Erreur au niveau de ce champ"}
                        </li>
                      ))
                    : "Erreur au niveau du formulaire"}
                </ul>
              ),
              className: "alert alert-danger",
            })
          }
          break
        default:
          break
      }
    }
  }
  const handleMessageClose = e => {
    e.preventDefault()

    setGlobalMessage({ ...globalMessage, isActive: false })
  }

  const getEligibleRoles = (roles, categories) => {
    const allowedPermission = categories
      .reduce((acc, value, index) => {
        return [
          ...acc,
          ...Object.keys(value.permissions).map(
            perm => value.permissions[perm]
          ),
        ]
      }, [])
      .find(p => p.code === PERMISSIONS.PERM_EMPLOYEE_FULL_ACCESS)

    return roles.filter(role => {
      if (role.permissionsIds.find(permId => permId === allowedPermission.id))
        return true
      return false
    })
  }

  const fetchInitialData = useCallback(async () => {
    try {
      const admins = await getAdminsMem({
        roleId: 7,
      })
      const estResp = await getFidEstablishmentsMem({
        withoutAdmin: 1,
      })

      const categoriesResp = await getCategoriesMem()
      const rolesResp = await getRolesMem()

      const estList = Object.keys(estResp.data.establishments).map(
        estId => estResp.data.establishments[estId]
      )

      const eligibleRoles = getEligibleRoles(
        exportCollectionToArray(rolesResp.data, "roles"),
        exportCollectionToArray(categoriesResp.data, "categories")
      )
      setFidAdmins(
        Object.keys(admins.data.admins)
          .map(adminId => admins.data.admins[adminId])
          .filter(adm => {
            if (adm.archived) return false
            const foundRole = eligibleRoles.find(role => role.id === adm.roleId)
            return foundRole !== undefined
          })
      )
      setEstablishments(estList)
      setEstablishmentsEdition(
        estList.map(est => ({
          ...est,
          isEditing: false,
          loading: false,
        }))
      )
      setDataReady(true)
    } catch (error) {
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>Une erreur est survenue au moment de charger les données</span>
        ),
        className: "alert alert-danger",
      })
    }
  }, [
    getAdminsMem,
    getFidEstablishmentsMem,
    getRolesMem,
    getCategoriesMem,
    globalMessage,
  ])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const setField = (type, fieldId, payload) => {
    if (type === EDITING) {
      setEstablishmentsEdition(
        establishmentsEdition.map(est => {
          if (est.id === fieldId) {
            return { ...est, ...payload }
          }
          return est
        })
      )
    } else {
      setEstablishments(
        establishments.map(est => {
          if (est.id === fieldId) {
            return { ...est, ...payload }
          }
          return est
        })
      )
    }
  }

  const toggleEditMode = (e, id, state) => {
    if (e) e.preventDefault()

    setEstablishmentsEdition(
      establishmentsEdition.map(est =>
        est.id === id ? { ...est, isEditing: state } : est
      )
    )
  }

  const submitUpdate = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const est = establishmentsEdition.find(estE => estE.id === fieldId)
    const { isEditing, loading, id: estId, ...payload } = est

    try {
      const newEstResp = await updateFidEstablishment(estId, payload)
      setEstablishments(
        establishments.map(e => (e.id === estId ? newEstResp.data : e))
      )
      setEstablishmentsEdition(
        establishmentsEdition.map(e =>
          e.id === estId
            ? {
                ...newEstResp.data,
                loading: false,
                isEditing: false,
              }
            : e
        )
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong> L'établissement a été modifié.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const handleEstablishmentFieldChange = (fieldId, fieldName, value) => {
    setField(EDITING, fieldId, {
      [fieldName]: value,
    })
  }

  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialEstablishment,
    onSubmit: async submitValues => {
      try {
        const newEstResp = await createFidEstablishment(submitValues)
        setEstablishments([...establishments, newEstResp.data])
        setEstablishmentsEdition([
          ...establishmentsEdition,
          {
            ...newEstResp.data,
            loading: false,
            isEditing: false,
          },
        ])
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> L'établissement a été ajouté.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  const columns = [
    {
      id: 1,
      header: "Établissement",
      inputType: "text",
      cell: (est, edition) => {
        if (!edition.isEditing) {
          return est.name
        }
        return (
          <TextInput
            id="name"
            name="name"
            placeholder="Nom"
            type="text"
            onChange={e =>
              handleEstablishmentFieldChange(est.id, "name", e.target.value)
            }
            value={edition.name}
          />
        )
      },
    },
    {
      id: 2,
      header: "Capping",
      cell: (est, edition) => {
        if (!edition.isEditing) {
          return est.cappingPoints
        }
        return (
          <TextInput
            id="cappingPoints"
            name="cappingPoints"
            placeholder="Capping"
            type="number"
            onChange={e =>
              handleEstablishmentFieldChange(
                est.id,
                "cappingPoints",
                Number(e.target.value)
              )
            }
            value={edition.cappingPoints}
          />
        )
      },
    },
    {
      id: 3,
      header: "Adresse admin de rattachement",
      cell: (est, edition) => {
        if (!edition.isEditing) {
          const foundAdmin = fidAdmins.find(adm => adm.id === est.keycloakId)
          return est.keycloakId && foundAdmin ? foundAdmin.email : ""
        }
        return (
          <SelectInput
            id="keycloakId"
            name="keycloakId"
            required={false}
            onChange={e =>
              handleEstablishmentFieldChange(
                est.id,
                "keycloakId",
                e.target.value
              )
            }
            value={edition.keycloakId}
            options={[
              <option key="default" value="" selected disabled>
                Adresse admin de rattachement
              </option>,
              fidAdmins.map(adm => (
                <option key={`option${adm.id}`} value={adm.id}>
                  {adm.email}
                </option>
              )),
            ]}
          />
        )
      },
    },
  ]

  return (
    <div>
      {/* CREATE ESTABLISHMENT */}
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={handleMessageClose}
      />
      <div className={cn(["card mb-4"])}>
        <div className="card-body">
          <h5 className="card-title">Création nouvel établissement</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Nom de l'établissement*"
                  id="estName"
                  placeholder="Nom"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Capping points*"
                  id="cappingPoints"
                  placeholder="Capping"
                  type="number"
                  name="cappingPoints"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cappingPoints}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <SelectInput
                  label="Adresse admin de rattachement"
                  id=""
                  required={false}
                  name="keycloakId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.keycloakId}
                  options={[
                    <option key="default" value="" selected disabled>
                      Adresse admin de rattachement
                    </option>,
                    fidAdmins.map(adm => (
                      <option key={`option${adm.id}`} value={adm.keycloakId}>
                        {adm.email}
                      </option>
                    )),
                  ]}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12 col-md-12 pb-12">
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={false}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ESTABLISHMENT LIST */}
      {dataReady ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 pb-12">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      {columns.map(col => (
                        <th scope="col" key={`table_header_${col.id}`}>
                          {col.header}
                        </th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {establishments.map(est => {
                      const edition = establishmentsEdition.length
                        ? establishmentsEdition.find(estE => estE.id === est.id)
                        : null

                      if (edition)
                        return (
                          <tr key={`row_${est.id}`}>
                            {columns.map(col => {
                              return (
                                <td
                                  key={`column_${col.id}`}
                                  className="align-middle"
                                >
                                  {col.cell(est, edition)}
                                </td>
                              )
                            })}
                            <td className="align-middle">
                              <div
                                className="btn-group btn-group-sm float-right"
                                role="group"
                                aria-label="Edit admin"
                              >
                                {!edition.isEditing ? (
                                  <a
                                    className="mr-2"
                                    onClick={e =>
                                      toggleEditMode(e, est.id, true)
                                    }
                                    href="/"
                                  >
                                    Modifier
                                  </a>
                                ) : (
                                  <>
                                    <a
                                      className="mr-4"
                                      onClick={e => submitUpdate(e, est.id)}
                                      href="/"
                                    >
                                      Valider
                                    </a>
                                    <a
                                      className="text-danger"
                                      onClick={e =>
                                        toggleEditMode(e, est.id, false)
                                      }
                                      href="/"
                                    >
                                      Annuler
                                    </a>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      return null
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

FidAdminEstablishments.propTypes = {}
FidAdminEstablishments.defaultProps = {}

export default FidAdminEstablishments
