import React from "react"
import PropTypes from "prop-types"

// Components

import style from "./RecapSection.module.css"

const RecapSection = ({ label, value, subLabel }) => (
  <div className={style.recap}>
    <div className={style.label}>{label}</div>
    <div className={style.value}>{value}</div>
    {subLabel ? <div className={style.subLabel}>{subLabel}</div> : null}
  </div>
)
RecapSection.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
RecapSection.defaultProps = {
  subLabel: null,
}

export default RecapSection
