export default [
  {
    Header: "Présent la semaine précédente",
    accessor: "presentWeekBefore",
  },
  {
    Header: "Stage(s) acheté(s)",
    accessor: "nbCmd",
  },
  {
    Header: "Balance du client",
    accessor: "clientBalance",
  },
  {
    Header: "Stage",
    accessor: "campName",
  },
  {
    Header: "Sexe",
    accessor: "sex",
  },
  {
    Header: "Nom du stagiaire",
    accessor: "lastname",
  },
  {
    Header: "Prénom du stagiaire",
    accessor: "firstname",
  },
  {
    Header: "Date de naissance du stagiaire",
    accessor: "birthdate",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "Téléphone du stagiaire",
    accessor: "phone",
  },
  {
    Header: "Pays du stagiaire",
    accessor: "country",
  },
  {
    Header: "Qualité du contact d'urgence",
    accessor: "contactOccupation",
  },
  {
    Header: "Nom du contact d'urgence",
    accessor: "contactLastname",
  },
  {
    Header: "Prénom du contact d'urgence",
    accessor: "contactFirstname",
  },
  {
    Header: "Téléphone du contact d'urgence",
    accessor: "contactPhone",
  },
  {
    Header: "Pickup d'arrivée",
    accessor: "hasPickUp",
  },
  {
    Header: "Date",
    accessor: "pickUpDate",
  },
  {
    Header: "Heure",
    accessor: "pickUpTime",
  },
  {
    Header: "Provenance",
    accessor: "pickUpProvenance",
  },
  {
    Header: "Compagnie",
    accessor: "pickUpCompany",
  },
  {
    Header: "Numéro de vol",
    accessor: "pickUpNumber",
  },
  {
    Header: "Terminal",
    accessor: "pickUpTerminal",
  },
  {
    Header: "Enfant non-accompagné",
    accessor: "pickUpIsAccompanied",
  },
  {
    Header: "Pickup départ",
    accessor: "hasDropOff",
  },
  {
    Header: "Départ : Date",
    accessor: "dropOffDate",
  },
  {
    Header: "Départ : Heure",
    accessor: "dropOffTime",
  },
  {
    Header: "Départ : Provenance",
    accessor: "dropOffProvenance",
  },
  {
    Header: "Départ : Compagnie",
    accessor: "dropOffCompany",
  },
  {
    Header: "Départ : Numéro de vol",
    accessor: "dropOffNumber",
  },
  {
    Header: "Départ : Terminal",
    accessor: "dropOffTerminal",
  },
  {
    Header: "Départ : Enfant non-accompagné",
    accessor: "dropOffIsAccompanied",
  },
  {
    Header: "Niveau de français",
    accessor: "frenchLevel",
  },
  {
    Header: "Niveau d'anglais",
    accessor: "englishLevel",
  },
  {
    Header: "Option",
    accessor: "options",
  },
  {
    Header: "Présent la semaine suivante",
    accessor: "presentWeekAfter",
  },
  {
    Header: "Commentaire Stagiaire",
    accessor: "playerComment",
  },
  {
    Header: "Commentaire commande",
    accessor: "orderComment",
  },
  {
    Header: "Yield offset",
    accessor: "yieldOffset",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Tennis : joueur classé",
    accessor: "tennisRanked",
  },
  {
    Header: "Tennis : pays classement",
    accessor: "tennisRankingCountry",
  },
  {
    Header: "Tennis : classement",
    accessor: "tennisRanking",
  },
  {
    Header: "Tennis : années de pratique",
    accessor: "tennisYears",
  },
  {
    Header: "Tennis : pratique hebdo (heures)",
    accessor: "tennisHours",
  },
  {
    Header: "Tennis : tournois par an",
    accessor: "tennisTournaments",
  },
  {
    Header: "Golf : joueur classé",
    accessor: "golfRanked",
  },
  {
    Header: "Golf : pays classement",
    accessor: "golfRankingCountry",
  },
  {
    Header: "Golf : classement",
    accessor: "golfRanking",
  },
  {
    Header: "Golf : années de pratique",
    accessor: "golfYears",
  },
  {
    Header: "Golf : pratique hebdo (heures)",
    accessor: "golfHours",
  },
  {
    Header: "Golf : tournois par an",
    accessor: "golfTournaments",
  },
  {
    Header: "Padel : joueur classé",
    accessor: "padelRanked",
  },
  {
    Header: "Padel : pays classement",
    accessor: "padelRankingCountry",
  },
  {
    Header: "Padel : classement",
    accessor: "padelRanking",
  },
  {
    Header: "Padel : années de pratique",
    accessor: "padelYears",
  },
  {
    Header: "Padel : pratique hebdo (heures)",
    accessor: "padelHours",
  },
  {
    Header: "Padel : tournois par an",
    accessor: "padelTournaments",
  },
  {
    Header: "Docteur : Nom",
    accessor: "doctorLastname",
  },
  {
    Header: "Docteur : Adresse",
    accessor: "doctorAddress",
  },
  {
    Header: "Docteur : Code postal",
    accessor: "doctorZipCode",
  },
  {
    Header: "Docteur : Ville",
    accessor: "doctorCity",
  },
  {
    Header: "Docteur : Pays",
    accessor: "doctorCountry",
  },
  {
    Header: "Sécurité sociale : Adresse",
    accessor: "socialSecurityAddress",
  },
  {
    Header: "Sécurité sociale : Code postal",
    accessor: "socialSecurityZipCode",
  },
  {
    Header: "Sécurité sociale : Ville",
    accessor: "socialSecurityCity",
  },
  {
    Header: "Sécurité sociale : Pays",
    accessor: "socialSecurityCountry",
  },
  {
    Header: "Observations médicales",
    accessor: "medicalObservations",
  },
  {
    Header: "Hébergement",
    accessor: "accommodationName",
  },
  {
    Header: "Code bénéficiaire",
    accessor: "publicId",
  },
  {
    Header: "Email client",
    accessor: "clientEmail",
  },
]
