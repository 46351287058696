import React, { useEffect, useState } from 'react'

// Services
import useReportFollowUpApi from '../../../../hooks/Api/useReportFollowUpApi';

// Components
import WithMenu from '../../../../components/templates/WithMenu/WithMenu';
import Select from '../../../../components/atoms/Select';
import { TextInput } from "../../../../components/atoms/Inputs/Inputs"
import Table from '../../../../components/organisms/Table';

import styles from './ReportsList.module.css';
import { format, parse } from 'date-fns';

const ReportsList = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const yearsOptions = Array.from({length: currentYear - startYear + 1}, (_, i) => i + startYear)?.map((y) => ({ value: y, label: y }));

  const [getReportsState,,, { getReports }] = useReportFollowUpApi();
  const [getReportPdfState,,, { getReportPdf }] = useReportFollowUpApi();

  const [filterYear, setFilterYear] = useState(yearsOptions[yearsOptions.length - 1]);
  const [reports, setReports] = useState([]);
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    getReports(filterYear?.value).then((res) => {
      setReports(res?.data?.reports || []);
    });
  }, [filterYear]);

  const getSanitized = (word) => (
    word?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") || ''
  );

  const handleDownloadReport = (id) => {
    getReportPdf(id).then((res) => {
      if (res?.data) {
        var file = new Blob([res.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    });
  };

  return (
    <WithMenu>
      <div className='mt-4 mb-4'>
        <h1 className='mb-3'>Gestion des rapports</h1>
        <div className='d-flex mb-3'>
          <div className={`${styles.select}`}>
            <Select
              options={yearsOptions}
              placeholder="Année"
              onChange={(val) => setFilterYear(val)}
              value={filterYear}
            />
          </div>
        </div>
        <div className='d-flex mb-5'>
          {reports?.length ? (
            <div className={`${styles.field}`}>
              <TextInput
                id="filterName"
                name="filterName"
                onChange={(input) => setFilterName(input?.target?.value || '')}
                value={filterName}
                placeholder="Rechercher un joueur ou un client..."
              />
            </div>
          ) : null}
        </div>

        {getReportsState === 'loading' ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          reports?.length ? (
            <Table
              columns={[
                {
                  Header: "Nom / Prénom du client",
                  accessor: "clientLastName",
                  Cell: ({
                    cell: {
                      row: {
                        original: { player },
                      },
                    },
                  }) => (
                    <strong>
                      {player?.clientFirstName || player?.clientLastName ? (
                        `${player?.clientFirstName} ${player?.clientLastName}`
                      ) : 'Non défini'}
                    </strong>
                  ),
                },
                {
                  Header: "Email client",
                  accessor: "emailAddress",
                  Cell: ({
                    cell: {
                      row: {
                        original: { player },
                      },
                    },
                  }) => player.emailAddress || 'Non défini',
                },
                {
                  Header: "Nom / Prénom du joueur",
                  accessor: "lastName",
                  Cell: ({
                    cell: {
                      row: {
                        original: { player },
                      },
                    },
                  }) => (
                    <strong>{`${player?.firstName} ${player?.lastName}`}</strong>
                  ),
                },
                {
                  Header: "Date de création",
                  accessor: "weekCode",
                  Cell: ({
                    cell: {
                      row: {
                        original: { weekCode },
                      },
                    },
                  }) => format(parse(weekCode, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
                },
                {
                  Header: "Date d'envoi",
                  accessor: "deliveryDate",
                  Cell: ({
                    cell: {
                      row: {
                        original: { deliveryDate },
                      },
                    },
                  }) => deliveryDate ? (
                    format(parse(deliveryDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
                  ) : 'Non envoyé',
                },
                {
                  Header: "Télécharger",
                  accessor: "id",
                  Cell: ({
                    cell: {
                      row: {
                        original: { id },
                      },
                    },
                  }) => (
                    <div>
                      <button
                        type="button"
                        className='btn btn-primary'
                        onClick={() => handleDownloadReport(id)}
                        disabled={getReportPdfState === 'loading'}
                      >
                        Télécharger
                      </button>
                    </div>
                  ),
                },
              ]}
              data={reports?.filter((rep) => (
                getSanitized(rep.player?.firstName).includes(getSanitized(filterName)) 
                || getSanitized(rep.player?.lastName).includes(getSanitized(filterName))
                || getSanitized(rep.player?.clientFirstName).includes(getSanitized(filterName))
                || getSanitized(rep.player?.clientLastName).includes(getSanitized(filterName))
                || getSanitized(rep.player?.emailAddress).includes(getSanitized(filterName))
              )) || []}
            />
          ) : <p className={styles.empty}>Aucun résultat à afficher.</p>
        )}
      </div>
    </WithMenu>
  )
}

ReportsList.propTypes = {}

export default ReportsList