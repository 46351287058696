import React from 'react'
import PropTypes from 'prop-types'

// Style
import style from "./WithMenu.module.css"
import cn from '../../../utils/cn'

const MenuItem = ({
  setVerticalMenuOpen,
  isDropdown,
  handleDropdownOpenChange = () => {},
  history,
  dropdownOpen,
  location,
  verticalMenuOpen,
  route,
}) => {
  const openDropdown = () => {
    setVerticalMenuOpen(true)
    handleDropdownOpenChange(route, "minified")
  }

  const navigate = () => {
    setVerticalMenuOpen(false)
    history.push(route.path)
  }

  return (
    <li
      onClick={isDropdown ? openDropdown : navigate}
      className={cn([
        style.upperCase,
        style.listItem,
        location.pathname === route.path || (isDropdown && dropdownOpen)
          ? style.active
          : "",
        !verticalMenuOpen ? "text-center" : "",
      ])}
      style={{ flex: "none" }}
      key={route.i18nKey}
    >
      {verticalMenuOpen ? (
        route.i18nKey
      ) : (
        <div>
          <i className={`${route.icon} fa-lg`} />
          <div className={style.minifiedMenuText}>
            {`${route.i18nKey.substr(0, 9)}${
              route.i18nKey.length > 9 ? "..." : ""
            }`}
          </div>
        </div>
      )}
    </li>
  )
}

MenuItem.propTypes = {
  setVerticalMenuOpen: PropTypes.func,
  isDropdown: PropTypes.bool,
  handleDropdownOpenChange: PropTypes.func,
  history: PropTypes.object,
  dropdownOpen: PropTypes.bool,
  location: PropTypes.object,
  verticalMenuOpen: PropTypes.bool,
  route: PropTypes.object,
}

export default MenuItem