import React, { useState, useCallback, useEffect, useMemo } from "react"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import WeekSelector from "../../../../components/atoms/WeekSelector/WeekSelector"
import FiltersSections from "./FiltersSection/FiltersSection"
import Button from "../../../../components/atoms/Button"
import PlayersTable from "./PlayersTable/PlayersTable"
import GlobalTooltip from "../../../../components/molecules/Tooltip/Tooltip"
import Modal from "react-bootstrap4-modal"

// Hooks
import useBookingsForCoachApi from "../../../../hooks/Api/useBookingsForCoachApi"
import useRotationsForCoachsApi from "../../../../hooks/Api/useRotationsForCoachsApi"
import useAssignmentsForCoachApi from "../../../../hooks/Api/useAssignmentsForCoachApi"

// Style
import style from "./SectionsAssignmentsList.module.css"

// utils & misc
import {
  flatSectionsList,
  mapFlatPlayer,
} from "../../../../utils/coach/mappers"
import { weeksList } from "../../../../utils/weeks"
import { format, startOfWeek } from "date-fns"
import useAppContext from "../../../../hooks/useAppContext"

const SectionsAssignmentsList = props => {
  const date = new Date();
  const weeksListForGroups = useMemo(() => weeksList({
    start: new Date(date.getFullYear(), date.getMonth() - 1, date.getDay()+1),
    end: new Date(date.getFullYear() + 7, date.getMonth(), date.getDay()+1)
  }) , []);

  const mondayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  const { setCurrentCoachWeek, context: { currentCoachWeek }} = useAppContext()

  const [isOpenedResetAllConfirm, setIsOpenedResetAllConfirm] = useState(false);
  const [isCleanFilters, setCleanFilter] = useState(true);
  const [filters, setFilters] = useState(true);

  const [weekSelected, setWeekSelection] = useState(currentCoachWeek || mondayOfCurrentWeek);
  const [tableHasChanged, setTableHasChanged] = useState(false);

  const [, bookingsPlayers,, { getBookingsForCoach }] = useBookingsForCoachApi();
  const [, rotations, , { getRotationsForCoachs }] = useRotationsForCoachsApi();
  const [postAssignmentsState,,, { postAsignmentSectionsAssignments }] = useAssignmentsForCoachApi();
  const [postValidateSectionsState, , , { postValidateBookingWeekSections }] = useBookingsForCoachApi();
  const [postResetAssignmentsState,,, { postResetAssignments }] = useAssignmentsForCoachApi();

  const savedGetBookings = useCallback(getBookingsForCoach, []);
  const savedGetRotations = useCallback(getRotationsForCoachs, []);

  const [bookings, setBookings] = useState([])

  const handleUpdateFilters = filtersToUpdate => {
    setFilters(filtersToUpdate)
    setCleanFilter(false)
    savedGetRotations(weekSelected)
    savedGetBookings(weekSelected)
  }
  const handleAssignmentSectionsToPlayers = async () => {
    postAsignmentSectionsAssignments(weekSelected).then(() => {
      savedGetRotations(weekSelected)
      savedGetBookings(weekSelected)
    });
  }

  useEffect(() => {
    if (weekSelected !== "") {
      savedGetRotations(weekSelected)
      savedGetBookings(weekSelected)
      setTableHasChanged(false)
      setCurrentCoachWeek(weekSelected)
    }
  }, [weekSelected])

  const mapBookings = (bookings) => (
    bookings
      ?.filter((booking) => booking.hasWeekSectionValidated === false)
      ?.map(booking => mapFlatPlayer(booking))
    || []
  );

  useEffect(() => {
    setBookings(mapBookings(bookingsPlayers?.bookings || []))
  }, [bookingsPlayers])

  const handleResetAll = () => {
    setIsOpenedResetAllConfirm(false);
    if (weekSelected) {
      postResetAssignments(format(weekSelected, 'yyyy-MM-dd')).then(() => {
        savedGetRotations(weekSelected)
        savedGetBookings(weekSelected)
        setTableHasChanged(false)
      });
    }
  };

  const handleValidateAllPlayers = () => {
    if (!weekSelected) return;
    postValidateBookingWeekSections(format(weekSelected, 'yyyy-MM-dd')).then(() => {
      savedGetRotations(weekSelected)
      savedGetBookings(weekSelected)
    });
  };

  return (
    <WithMenu>
      <div className={style["page-header"]}>
        <h1 className={style["page-header__title"]}>Affectation de Section</h1>
      </div>
      <div className={style["weekselector-container"]}>
        <WeekSelector
          className={style.weekselector}
          weeksList={weeksListForGroups}
          value={weekSelected}
          onChange={setWeekSelection}
        />
        <div className={style["buttons-control"]}>
          {rotations?.isSectionAssignmentDone && bookings?.length ? (
            <GlobalTooltip content="Script d'affectation des sections déjà lancé">
              <Button
                label="Affecter des sections aux joueurs"
                className={style["buttons-control__affectation"]}
                size="small"
                disabled={rotations?.isSectionAssignmentDone && bookings?.length}
              />
            </GlobalTooltip>
          ) : (
            <Button
              onClick={handleAssignmentSectionsToPlayers}
              label={postAssignmentsState === 'loading' ? 'Assignation en cours ...' : `Affecter des sections aux joueurs`}
              className={style["buttons-control__affectation"]}
              size="small"
              disabled={
                postAssignmentsState === 'loading' ||
                !rotations ||
                !bookings?.length ||
                rotations?.isSectionAssignmentDone
              }
            >
              {postAssignmentsState === 'loading' ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
            ) : null}
            </Button>
          )}

          <Button
            size="small"
            label="Valider les sections"
            className={style["buttons-control__affectation"]}
            onClick={handleValidateAllPlayers}
            disabled={
              postValidateSectionsState === 'loading'
              || postAssignmentsState === 'loading'
              || !rotations
              || !bookings?.length
              || !rotations?.isSectionAssignmentDone
              || bookings?.every((booking) => booking.hasWeekSectionValidated)
              || bookings?.some((booking) => !booking.weekSectionCode)
            }
          >
            {postValidateSectionsState === 'loading' ? (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>

          <Button
            onClick={() => setIsOpenedResetAllConfirm(true)}
            className={style["buttons-control__affectation"]}
            size="small"
            label="Réinitialiser"
            disabled={postResetAssignmentsState === 'loading'}
          >
            {postResetAssignmentsState === 'loading' ? (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>

          <Button
            onClick={() => setCleanFilter(true)}
            label="Vider tous les filtres"
            size="small"
          />
        </div>
      </div>
      <div className={style["filters-section"]}>
        <FiltersSections
          isCleanFilters={isCleanFilters}
          updateFilters={handleUpdateFilters}
          bookings={bookings}
        />
      </div>

      <PlayersTable
        filters={filters}
        bookings={bookings}
        setBookings={setBookings}
        sections={flatSectionsList(rotations?.weekSections || [])}
        tableHasChanged={tableHasChanged}
        setTableHasChanged={setTableHasChanged}
        rotations={rotations}
      />

      <Modal
        visible={isOpenedResetAllConfirm}
        dialogClassName="modal-confirmation modal-confirm-insurance"
      >
        <div className="modal-header">
          <div className="text-xl modal-header-title">
            <b>Réinitialiser</b>
          </div>
          <div
            className="close"
            data-dismiss="modal"
            onClick={() => setIsOpenedResetAllConfirm(false)}
          >
            <span className="icon-cross" aria-hidden="true" />
            <span className="sr-only">Close</span>
          </div>
        </div>

        <div className="modal-body confirm-finalize-body">
          <p>Êtes-vous sûr de vouloir réinitialiser les affectations pour la semaine sélectionnée ?</p>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-default btn-md"
            onClick={() => setIsOpenedResetAllConfirm(false)}
          >
            Annuler
          </button>
          <button
            className="btn btn-primary btn-md"
            onClick={() => handleResetAll()}
          >
            Confirmer
          </button>
        </div>
      </Modal>
    </WithMenu>
  )
}

SectionsAssignmentsList.propTypes = {}

export default SectionsAssignmentsList
