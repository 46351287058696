import React, { useState } from "react"

// Components
import StudentsList from "./StudentsList"
import StudentsEdit from "./StudentsEdit"

const Students = () => {
  const [component, setComponent] = useState("list")
  const [selectedPlayer, setSelectedPlayer] = useState(null)

  const handlePlayerSelect = player => {
    setSelectedPlayer(player)
    setComponent("edit")
  }

  const handleBackToList = () => setComponent("list")

  return component === "list" ? (
    <StudentsList onPlayerSelect={handlePlayerSelect} />
  ) : (
    <StudentsEdit
      selectedPlayer={selectedPlayer}
      onBackToList={handleBackToList}
    />
  )
}
Students.propTypes = {}

export default Students
