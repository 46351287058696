import React, { useEffect, useMemo, useState } from 'react';

// Utils
import { PERMISSIONS } from '../../../constants/constants';
import { DragDropContext } from 'react-beautiful-dnd';
import { Link, useLocation } from 'react-router-dom';
import cn from "../../../utils/cn";

// Hooks
import usePermissionsState from '../../../hooks/usePermissionsState';
import useAppContext from '../../../hooks/useAppContext';
import useTestimoniesApi from '../../../hooks/Api/useTestimoniesApi';

// Components
import WithMenu from '../../../components/templates/WithMenu/WithMenu';
import Table from '../../../components/organisms/Table';

import style from "./TestimoniesList.module.css";

const permissions = [
  PERMISSIONS.PERM_TESTIMONIES_CREATE,
  PERMISSIONS.PERM_TESTIMONIES_UPDATE,
  PERMISSIONS.PERM_TESTIMONIES_DELETE,
];

const TestimoniesList = () => {
  const { state } = useLocation();
  const { context: { userInfo } } = useAppContext();
  const rolePermissions = usePermissionsState(userInfo, permissions);

  const [getTestimoniesState, testimoniesPayload, error, { getTestimonies }] = useTestimoniesApi({ trigger: true });
  const [reorderTestimoniesState,,, { reorderTestimonies }] = useTestimoniesApi();
  const [deleteTestimonyState,,, { deleteTestimony }] = useTestimoniesApi();

  const testimonies = useMemo(() => testimoniesPayload?.testimonies || [], [testimoniesPayload]);

  const [confirmDeleteTestimonyId, setConfirmDeleteTestimonyId] = useState(null);

  useEffect(() => {
    getTestimonies();
  }, []);

  const handleDeleteTestimony = async (id) => {
    await deleteTestimony(id);
    getTestimonies();
  };

  const onDragEnd = async (result) => {
    if (!result?.destination || !result?.source || !testimonies?.length) return;

    const selectedIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let followingNewId = null;
    
    if (selectedIndex < destinationIndex) {
      // moving down
      followingNewId = testimonies[destinationIndex + 1]?.id || null;
    } else {
      // moving up
      followingNewId = testimonies[destinationIndex].id
    }

    const movingId = testimonies[selectedIndex]?.id;
    await reorderTestimonies(movingId, followingNewId);
    getTestimonies();
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Témoignages</h1>

        {rolePermissions[PERMISSIONS.PERM_TESTIMONIES_CREATE] ? (
          <Link className="btn btn-success" to="/testimonies/new">
            Nouveau témoignage
          </Link>
        ) : null}
      </div>

      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}

      {getTestimoniesState === "loading" || reorderTestimoniesState === 'loading' ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        testimonies?.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Titre",
                  accessor: "title",
                  Cell: ({
                    cell: {
                      row: {
                        original: { title },
                      },
                    },
                  }) => title?.[1],
                },
                {
                  Header: "",
                  accessor: "actions",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      }
                    }
                  }) => (
                    <div
                      role="group"
                      aria-label="theme-actions"
                      className="d-flex align-items-center justify-content-end"
                    >
                      {/* EDIT OR VIEW */}
                      {rolePermissions[PERMISSIONS.PERM_TESTIMONIES_UPDATE] || rolePermissions[PERMISSIONS.PERM_TESTIMONIES_CREATE] ? (
                        <Link
                          className="btn btn-sm btn-secondary mr-4"
                          to={`/testimonies/edit/${id}`}
                        >
                          {rolePermissions[PERMISSIONS.PERM_TESTIMONIES_UPDATE]
                            ? "Éditer"
                            : "Consulter"}
                        </Link>
                      ) : null}

                      {/* DELETE */}
                      {rolePermissions[PERMISSIONS.PERM_TESTIMONIES_DELETE] ? (
                        <>
                          {confirmDeleteTestimonyId === id ? (
                            <div className="btn-group">
                              <button
                                type="button"
                                onKeyDown={null}
                                tabIndex={0}
                                onClick={() => handleDeleteTestimony(id)}
                                className={cn([
                                  'btn btn-sm btn-danger',
                                  deleteTestimonyState === 'loading' ? 'disabled' : ''
                                ])}
                                disabled={deleteTestimonyState === 'loading'}
                              >
                                {deleteTestimonyState === 'loading' ? (
                                  <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                Confirmer
                              </button>
                              <button
                                type="button"
                                onKeyDown={null}
                                tabIndex={0}
                                onClick={() => setConfirmDeleteTestimonyId(null)}
                                className="btn btn-sm btn-secondary"
                                disabled={deleteTestimonyState === 'loading'}
                              >
                                Annuler
                              </button>
                            </div>
                          ) : (
                            <div
                              onKeyDown={null}
                              tabIndex={0}
                              role="button"
                              onClick={() => setConfirmDeleteTestimonyId(id)}
                              className="btn btn-sm btn-danger"
                            >
                              Supprimer
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  ),
                }
              ]}
              data={testimonies || []}
              isDraggable={rolePermissions[PERMISSIONS.PERM_TESTIMONIES_UPDATE]}
            />
          </DragDropContext>
        ) : (
          <p>Vous n'avez pas encore ajouté de témoignage.</p>
        )
      )}
    </WithMenu>
  )
}

TestimoniesList.propTypes = {}

export default TestimoniesList;
