// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL, API_SE_URL } from "../../constants/constants"

const useMediasApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getMedias = () => {
    return request("GET", `${API_STAGES_URL}/v1/medias`)
  }

  const getMediaWithId = mediaId =>
    request("GET", `${API_STAGES_URL}/v1/medias/${mediaId}`)

  const createImagesConfig = file => {
    const config = {
      headers: {
        "Content-Type": file.type,
      },
    }
    return request(
      "POST",
      `${API_SE_URL}/v1/images/${file.name}`,
      file,
      {
        axiosOptions: config,
      },
      true
    )
  }

  return [
    requestState,
    payload,
    error,
    {
      getMedias,
      getMediaWithId,
      createImagesConfig,
    },
  ]
}

export default useMediasApi
