import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import ClientNavigation from "../../../components/molecules/ClientNavigation/ClientNavigation"

// Hooks
import useClientsApi from "../../../hooks/Api/useClientsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./ClientsList.module.css"

const permissions = [
  PERMISSIONS.PERM_CLIENTS_CREATE,
  PERMISSIONS.PERM_CLIENTS_DISPLAY,
  PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE,
  PERMISSIONS.PERM_CLIENTS_UPDATE,
]

const ClientsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [requestState, payload, error, { getClients }] = useClientsApi({
    trigger: true,
  })
  const savedGetClients = useCallback(getClients, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetClients({ archived: false })
  }, [savedGetClients])

  const clientsCollectionToArray = () =>
    payload ? Object.keys(payload.clients).map(i => payload.clients[i]) : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Clients</h1>
        {rolePermissions[PERMISSIONS.PERM_CLIENTS_CREATE] ? (
          <Link className="btn btn-success" to="/clients/new">
            Nouveau client
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {requestState === "loading" && !payload ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_CLIENTS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_CLIENTS_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "Nom",
                  accessor: "lastname",
                },
                {
                  Header: "Prénom",
                  accessor: "firstname",
                },
                {
                  Header: "Joueurs",
                  accessor: "tag0",
                  Cell: ({
                    cell: {
                      row: {
                        original: { players },
                      },
                    },
                  }) => (
                    <div>
                      <ul className={style.playersList}>
                        {players
                          ? Object.keys(players).map(playerId => (
                              <li
                                className={style.playersListItem}
                                key={`player_${playerId}`}
                              >{`${players[playerId].firstname} ${players[playerId].lastname}`}</li>
                            ))
                          : ""}
                      </ul>
                    </div>
                  ),
                },
                {
                  Header: "Téléphone",
                  accessor: "phone",
                },
                {
                  Header: "E-Mail",
                  accessor: "email",
                },
                {
                  Header: "Nombre de Stage",
                  accessor: "nbBookings",
                },
                {
                  Header: " Stages a venir",
                  accessor: "nbFutureBookings",
                },
                {
                  Header: "Stages passés",
                  accessor: "campDone",
                },
                {
                  Header: "Total Dépensé",
                  accessor: "expenses",
                },
                {
                  Header: "Solde",
                  accessor: "balance",
                },
                {
                  Header: "Date d'inscription",
                  accessor: "creation",
                },
                {
                  Header: "",
                  accessor: "tag1",
                  Cell: ({
                    cell: {
                      row: {
                        original: { id, ...clientData },
                      },
                    },
                  }) => (
                    <ClientNavigation
                      size="small"
                      client={{ ...clientData, uid: id }}
                    />
                  ),
                },
              ]}
              data={clientsCollectionToArray()}
              hasPagination
              hasSort
              hasSearch
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default ClientsList
