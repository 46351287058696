/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from "react-table"
import { Droppable, Draggable } from "react-beautiful-dnd"

// Components
import Pagination from "../../molecules/Pagination"

// Utils & misc
import cn from "../../../utils/cn"

import style from "./Table.module.css"

// FIXME: You need to pass the prop hasPagination, unless you might get a "RangeError invalid array length"
const Table = ({
  data,
  columns,
  filterTypes,
  hasPagination,
  hasFilters,
  hasSort,
  hasSearch,
  isDraggable,
  hiddenColumns,
}) => {
  // Default Column filter or search filter
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ({ column: { filterValue, preFilteredRows, setFilter } }) => (
        <input
          type="text"
          value={filterValue || ""}
          onChange={e => {
            setFilter(e.target.value || undefined)
          }}
          placeholder="Rechercher ..."
        />
      ),
    }),
    []
  )

  const {
    // General Purpose for Table
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // Pagination Management
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    // Filters Management
    setGlobalFilter,

    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: hasPagination ? 100 : data.length,
        hiddenColumns: hiddenColumns || [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    if (data?.length) {
      setPageSize(hasPagination ? 100 : data.length)
    }
  }, [data]);

  return (
    <>
      {hasSearch ? (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="globalSearchPrepend">
              <svg
                className="bi bi-search"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.442 12.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M8.5 14a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM15 8.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            id="globalSearch"
            value={globalFilter || ""}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined)
            }}
            placeholder="Rechercher dans le tableau"
          />
        </div>
      ) : null}

      <table className="table table-hover" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    hasSort ? column.getSortByToggleProps() : undefined
                  )}
                >
                  <span
                    className={cn([
                      style.headCell,
                      column.isSorted ? style.headCellSorted : null,
                      // Sort indicator
                      column.isSorted && column.isSortedDesc
                        ? style.headCellSortedDesc
                        : null,
                      column.isSorted && !column.isSortedDesc
                        ? style.headCellSortedAsc
                        : null,
                    ])}
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isDraggable && !hasSort && !hasFilters && !hasSearch ? (
          <Droppable droppableId="table">
            {provided => (
              <tbody
                {...getTableBodyProps()}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {page.map((row, index) => {
                  prepareRow(row)
                  return (
                    <Draggable
                      draggableId={`${row.original.id}`}
                      key={index}
                      index={row.index}
                    >
                      {(providedDrag, snapshotDrag) => (
                        <tr
                          {...row.getRowProps()}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                          ref={providedDrag.innerRef}
                          // isDragging={snapshotDrag.isDragging}
                          style={{
                            opacity: snapshotDrag.isDragging ? "0.8" : "1",
                            display: snapshotDrag.isDragging ? "table" : "",
                            ...providedDrag.draggableProps.style,
                          }}
                        >
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>

      {hasPagination ? (
        <div className={style.paginationWrapper}>
          <span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50, 100].map(resLimit => (
                <option key={resLimit} value={resLimit}>
                  {resLimit} affichés
                </option>
              ))}
            </select>{" "}
            sur {data.length} résultats
          </span>
          <Pagination
            nextPage={nextPage}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            previousPage={previousPage}
          />
        </div>
      ) : null}
    </>
  )
}

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      accessor: PropTypes.string,
      width: PropTypes.number,
    })
  ).isRequired,
  filterTypes: PropTypes.object,
  hasPagination: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hasSort: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isDraggable: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
}

Table.defaultProps = {
  hasPagination: false,
  hasFilters: false,
  hasSort: false,
  hasSearch: false,
  filterTypes: null,
  isDraggable: false,
  hiddenColumns: [],
}

export default Table
