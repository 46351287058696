/**
 * Transform previous data structure to the one the api is waiting for
 * @param {*} data
 */
export default data => {
  const newData = {
    localeId: data.localeId,
    articles: data.additions,
    cancellations: data.cancellations,
    updates: data.updates,
    billingAddress: data.billingAddress,
    insurance: data.insurance,
    freeInsurance: data.freeInsurance,
    cancellationsInsurance: data.cancellationsInsurance,
    comment: data.comment,
    preventEmailSending: data.preventEmailSending,
  }
  if (data.discountId) newData.discountId = data.discountId
  if (data.oneshot) newData.oneShotDiscount = data.oneshot
  return newData
}

/*
1 - appeler endpoint d'ajout d'articles
2 - appeler endpoint de /preorder
3 - appeler endpoint de /preorder/validate
*/

/*
{
	"localeId": 1,
	"articles": [
		{
			"productId": 6,
			"weekId": 52,
			"playerId": 45
		},
		{
			"productId": 11,
			"weekId": 52,
			"playerId": 45
		}
	],
	"discount" : {
		"code": "STAFF80"
	},
	"billingAddress": {
			"address": "1 rue Monsieur le Prince",
			"zipCode": "75006",
			"city": "Paris",
			"country": "France",
			"save": true
	},
	"insurance": {
		"subscribe": true
	}
}

{
  "additions": [
    {
      "weekId": 65,
      "playerId": 2440,
      "productId": 31,
      "purchaseMotiveId": null
    }
  ],
  "updates": [],
  "cancellations": [],
  "insurance": false,
  "discountId": -1,
  "localeId": 1,
  "billingAddress": {
    "address": "test adress",
    "zipCode": "69006",
    "city": "Lyon",
    "country": "France",
    "save": false
  },
  "preventEmailSending": true
}
*/
