import useApi from "./useApi"
import { API_STAGES_URL } from "../../constants/constants"

const useQuestionsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getQuestions = () => request("GET", `${API_STAGES_URL}/v1/questions?sorted=asc`)

  const getQuestionById = questionId =>
    request("GET", `${API_STAGES_URL}/v1/questions/${questionId}`)

  const updateQuestion = ({ questionId, question }) =>
    request("PUT", `${API_STAGES_URL}/v1/questions/${questionId}`, question)

  const createQuestion = question =>
    request("POST", `${API_STAGES_URL}/v1/questions`, question)

  const deleteQuestion = id =>
    request("DELETE", `${API_STAGES_URL}/v1/questions/${id}`)

  const reorderQuestions = (reorderedQuestionId, newFollowingQuestionId) =>
    request("POST", `${API_STAGES_URL}/v1/questions:reorder`, {
      reorderedQuestionId,
      newFollowingQuestionId,
    })

  return [
    requestState,
    payload,
    error,
    {
      getQuestions,
      getQuestionById,
      updateQuestion,
      createQuestion,
      deleteQuestion,
      reorderQuestions,
    },
  ]
}

export default useQuestionsApi
