export const orderSections = [
  {sectionCode: 'wimbledon'},
  {sectionCode: 'us_open'},
  {sectionCode: 'australian_open'},
  {sectionCode: 'roland_garros'},
  {sectionCode: 'indian_wells'},
  {sectionCode: 'miami'},
  {sectionCode: 'monte_carlo'},
  {sectionCode: 'off_season'},
  {sectionCode: 'baby_tennis'},
  {sectionCode: 'weekend'},
  {sectionCode: 'night_session_18'},
  {sectionCode: 'night_session_20'},
]

export const orderRotationSections = (rotations) =>  rotations.weekSections.reduce((acc,curr) =>{
  return acc.map(accSection => {
    if(accSection.sectionCode === curr.sectionCode) return curr;
    return accSection;
  })
} , orderSections)