// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useArticlesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getArticlesFromClient = clientId =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/articles${clientId ? `?clientId=${clientId}` : ""}`
    )

  return [
    requestState,
    payload,
    error,
    {
      getArticlesFromClient,
    },
  ]
}

export default useArticlesApi
