import React, { useEffect, useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

// Components
import WithMenu from "../../../../../components/templates/WithMenu/WithMenu"
import CardForm from "./CardForm"

// Hooks
import useClientsApi from "../../../../../hooks/Api/useClientsApi"
import useOrdersApi from "../../../../../hooks/Api/useOrdersApi"

// Utils & misc
import style from "./ResolvePayment.module.css"
import { STRIPE_PUBLIC } from "../../../../../constants/constants"

const otherPaymentMeans = [
  {
    name: "PAYPAL",
    paymentMeanId: 2,
  },
  {
    name: "TRANSFER",
    paymentMeanId: 3,
  },
  {
    name: "CASH",
    paymentMeanId: 4,
  },
  {
    name: "CHECK",
    paymentMeanId: 5,
  },
  {
    name: "CB - TPE",
    paymentMeanId: 6,
  },
  {
    name: "STRIPE",
    paymentMeanId: 1,
  },
]

const ResolvePayment = ({ match }) => {
  const history = useHistory()
  const [, clientData, , { getClientWithId }] = useClientsApi()
  const [, ordersData, , { getOrderById }] = useOrdersApi()
  const [, , , { getStripePayment }] = useOrdersApi()
  const [, , , { payWithOtherMeans }] = useOrdersApi()
  const stripe = useRef(null)
  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [paymentToken, setPaymentToken] = useState(null)

  const [otherMeanId, setOtherMeanId] = useState("2")

  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetOrders = useCallback(getOrderById, [])

  const loadStripeObject = async () => {
    stripe.current = await loadStripe(STRIPE_PUBLIC)
    const secret = await getStripePayment(match.params.orderId)
    if (secret && secret.data) {
      setPaymentToken(secret.data)
      setStripeLoaded(true)
    }
  }
  const savedLoadStripe = useCallback(loadStripeObject, [])

  useEffect(() => {
    savedLoadStripe()
    return () => {}
  }, [savedLoadStripe])

  useEffect(() => {
    savedGetClientWithId(match.params.clientId)
    savedGetOrders(match.params.orderId)
  }, [
    savedGetClientWithId,
    match.params.clientId,
    match.params.orderId,
    savedGetOrders,
  ])

  const handleOtherPaymentSubmit = async () => {
    const id = Number(otherMeanId)
    if (!id) return

    try {
      await payWithOtherMeans(match.params.orderId, {
        paymentMeanId: id,
      })
      history.push({
        pathname: `/client/${clientData.id}/orders/list`,
        state: {
          submitType:
            "Commande régularisée avec succès (paiement par autre moyen)",
          success: true,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          {clientData ? (
            <>
              <h1>
                {`${clientData.firstname} ${clientData.lastname} / Commande / Régulariser`}
              </h1>
              <h3>
                <span className="mt-2 badge badge-warning">
                  Status: <b>à régulariser</b>
                </span>
              </h3>
              <button
                type="button"
                onClick={() =>
                  history.push(`/client/${match.params.clientId}/orders/list`)
                }
                className="btn btn-link p-0"
              >
                Retour aux commandes
              </button>
            </>
          ) : null}
        </h1>
        {/* <h1>
          {clientData
            ? `${clientData.firstname} ${clientData.lastname} / Commande / Régulariser`
            : null}
        </h1> */}
      </div>
      {ordersData ? (
        <div className="row">
          <div className="col-md-12">
            <ul className="list-group">
              <li className="list-group-item">
                Balance de la commande{" "}
                <span className="float-right">{ordersData.orderBalance}</span>
              </li>
              <li className="list-group-item">
                Crédits du client
                <span className="float-right">{ordersData.clientCredits}</span>
              </li>
              <li className="list-group-item">
                Crédits utilisé pour la régularisation
                <span className="float-right">
                  {ordersData.clientCreditsUsed}
                </span>
              </li>
              <li className="list-group-item">
                Montant à payer pour la régularisation
                <span className="float-right">{ordersData.toPayAmount}</span>
              </li>
              <li className="list-group-item">
                Montant remboursé pour la régularisation
                <span className="float-right">{ordersData.toRefundAmount}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}

      <div className="row mt-4">
        {stripeLoaded && paymentToken ? (
          <div className="col-md-4">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Paiement par Stripe</h5>
                <Elements stripe={stripe.current}>
                  <CardForm
                    paymentToken={paymentToken}
                    clientData={clientData}
                  />
                </Elements>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-md-4">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Autres moyens de paiement</h5>
              <select
                className="custom-select"
                id="paymentOtherMeans"
                name="paymentOtherMeans"
                onChange={e => setOtherMeanId(e.target.value)}
                value={otherMeanId}
              >
                {otherPaymentMeans.map(paymentMean => (
                  <option
                    key={paymentMean.name}
                    value={paymentMean.paymentMeanId}
                  >
                    {paymentMean.name}
                  </option>
                ))}
              </select>
              <button
                onClick={handleOtherPaymentSubmit}
                type="button"
                className="btn btn-primary mt-3"
              >
                Régulariser
              </button>
            </div>
          </div>
        </div>
      </div>
    </WithMenu>
  )
}

ResolvePayment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ResolvePayment
