import { useState, useEffect, useCallback } from "react"

// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

/*
This hook expose a single async request function to
go through the process of creating a new media
Once the task is completed, the response is set for the component
to subscribe to

epitech-marker.png: id => 2816
*/
const useFileUploadApi = () => {
  const [, fileUploadPayload, , fileUploadRequest] = useApi()
  const [, mediaPayload, , mediaRequest] = useApi()
  const [params, setParams] = useState("create")
  const [response, setResponse] = useState({
    data: null,
    status: "idle",
    error: null,
  })
  const savedMediaRequest = useCallback(mediaRequest, [])

  useEffect(() => {
    if (mediaPayload && mediaPayload.id && !response.data) {
      setResponse({
        ...response,
        status: "completed",
        data: mediaPayload,
        error: null,
      })
    }
    return () => {}
  }, [mediaPayload, response])

  useEffect(() => {
    if (fileUploadPayload) {
      if (fileUploadPayload.id) {
        if (params.method === "create") {
          savedMediaRequest("POST", `${API_STAGES_URL}/v1/medias`, {
            fileId: fileUploadPayload.id,
          })
        } else {
          savedMediaRequest(
            "PUT",
            `${API_STAGES_URL}/v1/medias/${params.mediaId}`,
            {
              fileId: fileUploadPayload.id,
            }
          )
        }
      }
    }
  }, [savedMediaRequest, params.method, params.mediaId, fileUploadPayload])

  const request = (
    fileName,
    fileData,
    acceptParams = { method: "create", mediaId: null }
  ) => {
    // Query /media-files -> on success, will run through the effect
    //   fileUploadRequest("POST", `${API_STAGES_URL}/v1/config`, {});
    setResponse({ ...response, status: "loading", data: null, error: null })
    setParams(acceptParams)

    return fileUploadRequest(
      "POST",
      `${API_STAGES_URL}/v1/medias-files/${fileName}`,
      fileData,
      {
        axiosOptions: {
          headers: {
            "Content-Type": fileData.type,
          },
        },
      }
    )
  }

  return [response, request]
}

export default useFileUploadApi
