import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useTagsApi from "../../../hooks/Api/useTagsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./TagsList.module.css"

const permissions = [
  PERMISSIONS.PERM_TAGS_DISPLAY,
  PERMISSIONS.PERM_TAGS_UPDATE,
  PERMISSIONS.PERM_TAGS_CREATE,
]

const TagsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [requestState, payload, error, { getTags }] = useTagsApi({
    trigger: true,
  })
  const savedGetTags = useCallback(getTags, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetTags()
  }, [savedGetTags])

  const tagsCollectionToArray = () =>
    payload && payload.tags
      ? Object.keys(payload.tags).map(i => payload.tags[i])
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Tags</h1>
        {rolePermissions[PERMISSIONS.PERM_TAGS_CREATE] ? (
          <Link className="btn btn-success" to="/tags/new">
            Nouveau tag
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {requestState === "loading" && !payload ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_TAGS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_TAGS_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Label",
                  accessor: "name",
                  Cell: ({ cell: { value } }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit tag"
                    >
                      {value[1]}
                    </div>
                  ),
                },
                {
                  Header: "",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      },
                    },
                  }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit tag"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/tags/edit/${id}`}
                      >
                        Éditer
                      </Link>
                    </div>
                  ),
                },
              ]}
              data={tagsCollectionToArray()}
              hasPagination
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default TagsList
