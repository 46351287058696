import React, { useState } from "react"
import PropTypes from "prop-types"
import { Editor, EditorState, RichUtils, getDefaultKeyBinding } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import { stateFromHTML } from "draft-js-import-html"
import "draft-js/dist/Draft.css"

// Components
import OptionsStyleControl from "./OptionsStyleControl/OptionsStyleControl"

// Utils
import cn from "../../../utils/cn"

import { EDITOR_TYPES, styleMap } from "./constants"
import "./Editor.css"

const MyEditor = ({
  placeholder,
  label,
  initialContent,
  handleContentChange,
  error,
  disabled,
}) => {
  const [editor, setEditor] = useState(
    EditorState.createWithContent(stateFromHTML(initialContent))
  )

  const handleEditorChange = editorUpdate => {
    setEditor(editorUpdate)

    const htmlContent = stateToHTML(editorUpdate.getCurrentContent())
    handleContentChange(htmlContent === "<p><br></p>" ? "" : htmlContent)
  }

  const handleToggleType = option => {
    handleEditorChange(
      EDITOR_TYPES[option.type.id].toggleType(editor, option.style)
    )
  }

  const handleKeyCommand = (cmd, editorUpdate) => {
    const newState = RichUtils.handleKeyCommand(editorUpdate, cmd)

    if (newState) {
      handleEditorChange(newState)
      return "handled"
    }
    return "not-handled"
  }

  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, editor, 4)
      if (newEditorState !== editor) {
        handleEditorChange(newEditorState)
      }
      return false
    }
    return getDefaultKeyBinding(e)
  }

  return (
    <div className="form-group">
      {label ? <div className="mb-2">{label}</div> : null}
      <div className={cn(["RichEditor-root", disabled ? "disabled" : ""])}>
        {!disabled ? (
          <OptionsStyleControl
            editorState={editor}
            onToggle={handleToggleType}
          />
        ) : null}
        <div className={cn(["RichEditor-editor", disabled ? "disabled" : ""])}>
          <Editor
            readOnly={disabled}
            editorState={editor}
            customStyleMap={styleMap}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={handleEditorChange}
            placeholder={placeholder}
          />
        </div>
      </div>
      <div className="invalid-feedback">{error}</div>
    </div>
  )
}

MyEditor.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleContentChange: PropTypes.func,
}
MyEditor.defaultProps = {
  label: null,
  placeholder: "...",
  handleContentChange: () => {},
  disabled: false,
}

export default MyEditor
