import React, { useState, useEffect } from 'react';

// Utils
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { parse, format } from 'date-fns';
import { fr } from "date-fns/esm/locale"

// Components
import WithMenu from '../../../../components/templates/WithMenu/WithMenu';
import { SelectInput } from '../../../../components/atoms/Inputs/Inputs';

// Services
import useReportFollowUpApi from '../../../../hooks/Api/useReportFollowUpApi';

import styles from './ReportEdit.module.css';

const translatedContent = {
  'FR': {
    "reportEdition": "Édition du rapport",
    "campDate": "Date du stage",
    "coach": "Le coach Mouratoglou",
    "camper": "Nom du stagiaire",
    "lang": "Langue du template",
    "fr": "Français",
    "en": "Anglais",
    "priority": "Priorité",
    "description": "Description",
    "evaluation": "L'appréciation finale",
    "save": "Enregistrer",
    "send": "Envoyer",
    "preview": "Télécharger un aperçu du rapport"
  },
  'EN': {
    "reportEdition": "Report edition",
    "campDate": "Camp date",
    "coach": "Mouratoglou coach",
    "camper": "Camper's name",
    "lang": "Template language",
    "fr": "French",
    "en": "English",
    "priority": "Priority",
    "description": "Description",
    "evaluation": "Final evaluation",
    "save": "Save",
    "send": "Send",
    "preview": "Download a preview of the report"
  }
};

const ReportEdit = ({ match }) => {
  const reportId = match?.params?.id;
  const history = useHistory();

  const [successSave, setSuccessSave] = useState(null);
  const [errorSave, setErrorSave] = useState(null);

  const [getReportState, reportDetails,, { getReport }] = useReportFollowUpApi();
  const [putSaveReportState,,, { putSaveReport }] = useReportFollowUpApi();
  const [postDeliverReportState,,, { postDeliverReport }] = useReportFollowUpApi();
  const [getReportPdfState,,, { getReportPdf }] = useReportFollowUpApi();

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    getReport(reportId).then((res) => {
      if (res?.data) {
        const report = res.data;

        setInitialValues({
          languageCode: report.languageCode || null,
          firstPriorityName: report.firstPriorityName || '',
          firstPriorityDescription: report.firstPriorityDescription || '',
          secondPriorityName: report.secondPriorityName || '',
          secondPriorityDescription: report.secondPriorityDescription || '',
          thirdPriorityName: report.thirdPriorityName || '',
          thirdPriorityDescription: report.thirdPriorityDescription || '',
          finalAppreciation: report.finalAppreciation || ''
        });
      }
    });
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSuccessSave(null);
      setErrorSave(null);
      
      putSaveReport(reportId, values).then((res) => {
        if (res?.data) {
          postDeliverReport(reportId).then((res) => {
            if (res?.status === 204) {
              if (reportDetails?.coach?.id) {
                history.push({
                  pathname: `/reports/coach/${reportDetails.coach.id}`,
                  state: { submitType: "edit", success: true },
                })
              } else {
                window.scrollTo(0, 0);
                setSuccessSave('Le rapport a bien été envoyé.');
              }
            }
          });
        } else {
          setErrorSave("Une erreur s'est produite. Merci de vérifier le formulaire et de réessayer.");
        }
      });
    }
  });

  const getInputField = (name, label) => (
    <tr className={`${styles.row} d-flex`}>
      <td className={styles.label_cell}>
        <label htmlFor={name}>{label}</label>
      </td>
      <td>
        <input
          id={name}
          name={name}
          value={formik.values[name] || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </td>
    </tr>
  );

  const getTextarea = (name, label) => (
    <tr className={`d-flex ${styles.row} ${styles.desc}`}>
      <td className={styles.label_cell}>
        <label htmlFor={name}>{label}</label>
      </td>
      <td>
        <textarea
          id={name}
          name={name}
          value={formik.values[name] || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          row="6"
          className={styles.textarea}
        />
      </td>
    </tr>
  );

  const handleSave = () => {
    setSuccessSave(null);
    setErrorSave(null);

    putSaveReport(reportId, formik.values).then((res) => {
      window.scrollTo(0, 0);
      if (res?.data) {
        setSuccessSave('Le rapport a bien été enregistré.');
      } else {
        setErrorSave("Une erreur s'est produite. Merci de vérifier le formulaire et de réessayer.");
      }
    });
  };

  const getBirthYear = (date) => {
    const parsed = date ? parse(date, 'yyyy-MM-dd', new Date()) : null;
    if (parsed) return format(parsed, 'yyyy');
    return null;
  };

  const translate = (key) => translatedContent[formik.values?.languageCode || 'FR'][key] || '';

  const handleDownloadPdf = () => {
    getReportPdf(reportId).then((res) => {
      var file = new Blob([res.data], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    });
  };

  return (
    <WithMenu>
      <div className='mt-4 mb-5'>
        <h1 className='mb-4'>
          {translate('reportEdition')}
        </h1>

        {getReportState === 'loading' ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          reportDetails ? (
            <>
              <div className={`${styles.details} mb-4`}>
                <p>
                  {translate('campDate')} :
                  <strong className='ml-1'>
                    {reportDetails.weekCode && parse(reportDetails.weekCode, 'yyyy-MM-dd', new Date()) ? (
                      format(
                        parse(reportDetails.weekCode, 'yyyy-MM-dd', new Date()),
                        "dd MMMM yyyy",
                        { locale: fr }
                      )
                    ) : null}
                  </strong>
                </p>

                {reportDetails.coach ? (
                  <p>
                    {translate('coach')}
                    <strong className='ml-1'>{`${reportDetails.coach.firstName} ${reportDetails.coach.lastName}`}</strong>
                  </p>
                ) : null}

                {reportDetails.player ? (
                  <p>
                    {translate('camper')} :
                    <strong className='ml-1'>
                      {`${reportDetails.player.firstName} ${reportDetails.player.lastName} (${getBirthYear(reportDetails.player.birthdate)})`}
                    </strong>
                  </p>
                ) : null}
              </div>

              <div className={styles.flash}>
                {errorSave || successSave ? (
                  <div className={`alert ${errorSave ? 'alert-danger ' : 'alert-success '}`} role="alert">
                  {errorSave || successSave}
                </div>
                ) : null}
              </div>

              <div className='mb-5 d-flex'>
                <div className={styles.select}>
                  <SelectInput
                    label={translate('lang')}
                    id="languageCode"
                    name="languageCode"
                    onChange={formik.handleChange}
                    value={formik.values.languageCode}
                    options={[
                      <option value="FR" key="FR">{translate('fr')}</option>,
                      <option value="EN" key="EN">{translate('en')}</option>
                    ]}
                  />
                </div>
              </div>

              <div className='mb-4'>
                <button 
                  type="button" 
                  className='btn btn-primary' 
                  onClick={handleDownloadPdf}
                  disabled={getReportPdfState === 'loading'}
                >
                  {translate('preview')}
                </button>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <table className={styles.table}>
                  <tbody>
                    {getInputField('firstPriorityName', `${translate('priority')} n°1`)}
                    {getTextarea('firstPriorityDescription', translate('description'))}
                    {getInputField('secondPriorityName', `${translate('priority')} n°2`)}
                    {getTextarea('secondPriorityDescription', translate('description'))}
                    {getInputField('thirdPriorityName', `${translate('priority')} n°3`)}
                    {getTextarea('thirdPriorityDescription', translate('description'))}

                    <tr>
                      <td colSpan={2} className={styles.full_textarea}>
                        <label htmlFor='finalAppreciation'>
                          {translate('evaluation')}
                        </label>
                        <textarea
                          id="finalAppreciation"
                          name="finalAppreciation"
                          value={formik.values.finalAppreciation || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          row="10"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className='mt-4'>
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    onClick={handleSave}
                    disabled={putSaveReportState === 'loading' || postDeliverReportState === 'loading'}
                  >
                    {putSaveReportState === 'loading' ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    
                    {translate('save')}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={putSaveReportState === 'loading' || postDeliverReportState === 'loading'}
                  >
                    {postDeliverReportState === 'loading' ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}

                    {translate('send')}
                  </button>
                </div>
              </form>
            </>
          ) : null
        )}
      </div>
    </WithMenu>
  )
}

ReportEdit.propTypes = {}

export default ReportEdit