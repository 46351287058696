/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useMemo } from "react"
import { getYear, startOfYear, format, addDays } from "date-fns"
import { fr } from "date-fns/esm/locale"

// Components
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useProductsApi from "../../../../hooks/Api/useProductsApi"

// Utils
import cn from "../../../../utils/cn"
import {
  weekIdToDate,
  dateToWeekId,
  getISOWeeks,
} from "../../../../utils/weeks"

import { PERMISSIONS } from "../../../../constants/constants"
import style from "./Stocks.module.css"

const permissions = [
  PERMISSIONS.PERM_CAMPUS_STOCK_DISPLAY,
  PERMISSIONS.PERM_CAMPUS_STOCK_UPDATE,
]

const Stocks = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, , , { getCampusProducts }] = useProductsApi()
  const [, , , { updateCampusProductStock }] = useProductsApi()
  const [products, setProducts] = useState([])
  const [productsEdited, setProductsEdited] = useState([])
  const [genderTab, setGenderTab] = useState("1")
  const [years] = useState([getYear(new Date()), getYear(new Date()) + 1])
  const [year, setYear] = useState(years[0])
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const savedGetCampusProducts = useCallback(getCampusProducts, [])

  const weekOptions = useMemo(() => {
    const weeks = []
    const currentYearWeeks = getISOWeeks(Number(year))
    const nextYearWeeks = getISOWeeks(Number(year) + 1)
    const firstWeek = new Date(Number(year), 1, 1, 0, 0)
    const firstWeekId = dateToWeekId(startOfYear(firstWeek), "campus")

    for (let i = 0; i < (currentYearWeeks + nextYearWeeks); i += 1) {
      const weekDate = weekIdToDate(firstWeekId + i, "campus")
      weeks.push({
        id: firstWeekId + i,
        from: format(weekDate, "dd/MM", { locale: fr }),
        to: format(addDays(weekDate, 6), "dd/MM", {
          locale: fr,
        }),
        year: getYear(weekDate),
      })
    }
    return weeks
  }, [year])

  const fetchProducts = useCallback(async () => {
    try {
      const { 0: first, [weekOptions.length - 1]: last } = weekOptions
      const resp = await savedGetCampusProducts({
        startWeekId: first.id,
        endWeekId: last.id,
        isStockLimited: true,
      })
      setProducts(resp?.data?.products || null)
      setProductsEdited(resp?.data?.products || null)
    } catch (error) {
      console.error(error)
    }
  }, [savedGetCampusProducts])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  const handleStockChange = async (
    productId,
    sexId,
    weekId,
    quantity,
    active
  ) => {
    setProductsEdited(
      productsEdited.map(product => {
        if (product.id === productId) {
          const updatedProduct = JSON.parse(JSON.stringify(product))
          updatedProduct.stocks[sexId][weekId].isActive = active
          updatedProduct.stocks[sexId][weekId].quantity = quantity
          return updatedProduct
        }
        return product
      })
    )
    await updateCampusProductStock({
      productId,
      sexId,
      weekId,
      quantity,
      active,
    })
  }

  const canEdit = rolePermissions[PERMISSIONS.PERM_CAMPUS_STOCK_UPDATE]

  return (
    <>
      <h1>Stocks</h1>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />

      {/* Gender select */}
      <div className="row mt-4">
        <div className="col">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                onClick={() => setGenderTab("1")}
                href="#filles"
                className={`nav-link ${genderTab === "1" ? "active" : ""}`}
              >
                Filles
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => setGenderTab("2")}
                href="#garcons"
                className={`nav-link ${genderTab === "2" ? "active" : ""}`}
              >
                Garçons
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Year select */}
      <div className="row mt-4">
        <div className="col">
          <ul className="nav nav-tabs">
            {years.map(y => (
              <a
                href="#"
                role="button"
                onKeyDown={null}
                tabIndex="0"
                onClick={() => setYear(y)}
                className={`nav-link${y === year ? " active" : ""}`}
              >
                {y}
              </a>
            ))}
          </ul>
        </div>
      </div>

      {/* Stocks table */}
      {products?.length && productsEdited?.length && weekOptions?.length ? (
        <div className="row">
          <div className="col">
            <div className="table-responsive" style={{ height: "60vh" }}>
              <table
                className={cn([
                  "table",
                  "table-sm",
                  "table-bordered",
                  canEdit ? "table-hover" : "",
                  "table-striped",
                  style.theadFixed,
                ])}
              >
                <thead>
                  <tr>
                    <th className="align-middle" scope="col">
                      Semaine
                    </th>
                    <th className="align-middle" scope="col">
                      Du
                    </th>
                    <th className="align-middle" scope="col">
                      Au
                    </th>
                    {products.map(product => (
                      <th className="align-middle" scope="col">
                        {product.name["1"]}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {weekOptions
                    .filter(week => week.year === year)
                    .map((week, index) => (
                      <tr key={week.id}>
                        {/* Week number */}
                        <td
                          className={cn([
                            "align-middle",
                            "td-fixed-1",
                            style.weekHeaderCol,
                          ])}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              className={cn([
                                "mr-1",
                                style.weekHeaderColElement,
                              ])}
                            >
                              S{index + 1}
                            </div>
                          </div>
                        </td>
                        {/* From */}
                        <td className="align-middle td-fixed-2">{week.from}</td>
                        {/* To */}
                        <td className="align-middle td-fixed-3">{week.to}</td>
                        {productsEdited.map(product => (
                          <td>
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                role="button"
                                tabIndex="0"
                                onKeyDown={null}
                                onClick={() =>
                                  handleStockChange(
                                    product.id,
                                    Number(genderTab),
                                    week.id,
                                    product.stocks[genderTab][week.id]
                                      ?.quantity,
                                    !product.stocks[genderTab][week.id]
                                      ?.isActive
                                  )
                                }
                                className="custom-control custom-switch"
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    product.stocks[genderTab][week.id]?.isActive
                                  }
                                  className="custom-control-input"
                                  readOnly
                                  disabled={!canEdit}
                                />
                                <label className="custom-control-label" />
                              </div>
                              <div className="text-nowrap mr-1">
                                {
                                  product.stocks[genderTab][week.id]
                                    ?.bookedQuantity
                                }{" "}
                                /{" "}
                              </div>
                              <input
                                type="number"
                                value={
                                  product.stocks[genderTab][week.id]?.quantity
                                }
                                onChange={e =>
                                  handleStockChange(
                                    product.id,
                                    Number(genderTab),
                                    week.id,
                                    Number(e.target.value),
                                    product.stocks[genderTab][week.id]?.isActive
                                  )
                                }
                                className="form-control"
                                style={{ width: "80px" }}
                                min="0"
                                disabled={!canEdit}
                              />
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-2">Chargement...</div>
      )}
    </>
  )
}

Stocks.propTypes = {}

export default Stocks
