// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const usePreOrderApi = () => {
  const [requestState, payload, error, request, rawRequest] = useApi()

  const postPreOrder = (clientId, data) =>
    request("POST", `${API_STAGES_URL}/v1/preorder/${clientId}`, data)

  const validatePreOrder = (clientId, options) => {
    return request(
      "PUT",
      `${API_STAGES_URL}/v1/preorder/${clientId}/validate`,
      options
    )
  }

  const payWithCredit = (clientId, body) =>
    rawRequest(
      "PUT",
      `${API_STAGES_URL}/v1/preorder/${clientId}/paywithcredit`,
      body
    )

  return [
    requestState,
    payload,
    error,
    {
      postPreOrder,
      validatePreOrder,
      payWithCredit,
    },
  ]
}

export default usePreOrderApi
