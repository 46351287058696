import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Components
import Table from "../../../../../components/organisms/Table"
import { SelectInput } from "../../../../../components/atoms/Inputs/Inputs"

// Hooks
import useBookingsForCoachApi from "../../../../../hooks/Api/useBookingsForCoachApi"
import useCountriesApi from "../../../../../hooks/Api/useCountriesApi"

// Style
import style from "./PlayersTable.module.css"

// utils & misc
import { isNil } from "lodash"
import { findGender, staticRanking } from "../../../../../utils/coach/staticValues"
import { findCountry } from "../../../../../utils/coach/findCountry"

const PlayersTable = ({ filters, bookings, setBookings, sections, tableHasChanged, setTableHasChanged, rotations }) => {
  const [playersFiltered, setPlayersFiltered] = useState([])

  const playerIndex = playerIndex => bookings.findIndex(p => p.id === playerIndex)

  const [postBookingAssignmentState, , , { postBookingAssignment }] = useBookingsForCoachApi();
  const [, , , { putForcedPlayerRanking }] = useBookingsForCoachApi();
  const [, countries, , { getCountries }] = useCountriesApi()

  useEffect(() => {
    if (!countries) getCountries();
  }, []);

  useEffect(() => {
    if (!tableHasChanged && (bookings?.[0]?.weekCode !== bookings?.[0]?.weekCode)) {
      setBookings(bookings);
    }
  }, [bookings]);

  useEffect(() => {
    setPlayersFiltered(bookings
      ?.filter(player => filters.gender ? player.sexCode === filters.gender : true)
      .filter(player => filters.birthYear ? player.birthdate.split("-")[0] === filters.birthYear : true)
      .filter(player => filters.color ? player.colorCode === filters.color : true)
      || []
    );
  }, [bookings, filters])

  const handleChangePlayerSection = (bookingId, weekSectionCode) => {
    setBookings((state) => state.map((booking) => {
      if (booking?.id === bookingId) {
        return {
          ...booking,
          selectedWeekSectionCode: weekSectionCode
        }
      } else {
        return booking;
      }
    }));

    postBookingAssignment({
      weekSection: weekSectionCode,
      bookingId: bookingId,
    }).then((res) => {
      if (res?.status === 204) {
        setBookings((state) => state.filter(p => p.id !== bookingId));
        setTableHasChanged(true);
        
      }
    });
  }

  const changeForcedRanking = (idPlayer, evt) => {
    const value = staticRanking.find((ranking) => (ranking.value === evt.target.value))?.label;
    if (value) {
      putForcedPlayerRanking({ playerId: idPlayer, forcedPlayerRanking: value }).then((res) => {
        if (res?.status === 204) {
          setBookings((state) => (
            state.map((player) => {
              if (player.id === idPlayer) return {...player, forcedPlayerRanking: value };
              return player;
            })
          ));
          setTableHasChanged(true);
        }
      });
    }
  };

  const columns = [
    {
      Header: "Code couleur",
      accessor: "colorCode",
      Cell: ({
        cell: {
          row: {
            values: { colorCode },
          },
        },
      }) => (
        colorCode === 'full_day_test' ? (
          <div><strong className={style.test_acad}>TEST ACAD</strong></div>
        ) : (
          <div title={colorCode.split('_').join(' ')} data-attr-code={colorCode} className={`${style.tag} ${style[colorCode]}`}></div>
        )
      ),
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Nom / Prénom",
      accessor: "name",
    },
    {
      Header: "Né(e) le",
      accessor: "birthdate",
    },
    {
      Header: "Genre",
      accessor: "sexCode",
      Cell: ({
        cell: {
          row: {
            values: { sexCode },
          },
        },
      }) => <div>{findGender(sexCode).title}</div>,
    },
    {
      Header: "Nationalité",
      accessor: "nationalityCountryCode",
      Cell: ({
        cell: {
          row: {
            values: { nationalityCountryCode },
          },
        },
      }) => {
        return (
          <div>
            {findCountry(countries?.countries, nationalityCountryCode)}
          </div>
        )}
    },
    {
      Header: 'Infos sportives',
      accessor: "firstYearOfPractice",
      Cell: ({
        row: {
          original: { firstYearOfPractice, hoursPlayedPerWeek, matchesPlayedPerYear } 
        }
      }) => (
        <div className={style.tennis_data}>
          {firstYearOfPractice || hoursPlayedPerWeek || matchesPlayedPerYear ? (
            <>
              {!isNil(firstYearOfPractice) ? <p>Début tennis : {firstYearOfPractice}</p> : null}
              {!isNil(hoursPlayedPerWeek) ? <p>Heures/semaine : {hoursPlayedPerWeek}</p> : null}
              {!isNil(matchesPlayedPerYear) ? <p>Matchs/an : {matchesPlayedPerYear}</p> : null}
            </>
          ) : (
            <p>Non renseignées</p>
          )}
        </div>
      )
    },
    {
      Header: "Ranking",
      accessor: "ranking",
      Cell: ({
        row: {
          original: { id, ranking, forcedPlayerRanking },
        },
      }) => (
        <>
          <SelectInput
            id="player-ranking-selector"
            onChange={evt => changeForcedRanking(id, evt)}
            value={
              staticRanking.find((r) => (r.label === forcedPlayerRanking))?.value
              || staticRanking.find((r) => (r.label === ranking))?.value
            }
            name="playerRanking"
            options={[
              <option key="label" value="" disabled>
                Ranking
              </option>,
              staticRanking.map(({ label, value }, key) => (
                <option key={key} value={value}>
                  {label}
                </option>
              )),
            ]}
          />
        </>
      ),
    },
    {
      Header: "Section",
      accessor: "weekSectionCode",
      Cell: ({
        cell: {
          row: {
            values: { id },
          },
        },
      }) => {
        const sectionCode = bookings[playerIndex(id)]?.weekSectionCode;

        return (
          <div className={style.section_name}>
            {rotations?.isSectionAssignmentDone && !sectionCode ? (
              <SelectInput
                id="bookingSection"
                onChange={(e) => handleChangePlayerSection(id, e.target.value)}
                value={bookings[playerIndex(id)]?.selectedWeekSectionCode}
                name="bookingSection"
                options={[
                  <option key="random" value="">
                    Section
                  </option>,
                  sections.map((sectionSelect, key) => (
                    <option key={key} value={sectionSelect.code}>
                      {sectionSelect.title}
                    </option>
                  )),
                ]}
                disabled={postBookingAssignmentState === 'loading'}
              />
            ) : (
              sections?.find((section) => section.code === sectionCode)?.title || 'Non définie'
            )}
          </div>
        );
      },
    },
  ]
  if (!bookings.length || !sections.length)
    return (
      <div className={style["player-table"]}>
        Aucun joueur n'existe pour cette semaine ou ils ont tous été validés.
      </div>
    )

  return (
    <div className={style["player-table"]}>
      {playersFiltered?.length ? (
        <Table columns={columns} data={playersFiltered} hasSort hiddenColumns={['id']} />
      ) : (
        <div>Aucun joueur dans la liste</div>
      )}
    </div>
  )
}

PlayersTable.propTypes = {
  bookings: PropTypes.array,
  tableHasChanged: PropTypes.bool,
  setTableHasChanged: PropTypes.func,
  rotations: PropTypes.shape({}),
}

PlayersTable.defaultProps = {
  bookings: []
}

export default PlayersTable
