import React from "react"
import PropTypes from "prop-types"

// Utils & misc
import cn from "../../../utils/cn"

import style from "./Archive.module.css"

const Archive = ({
  children,
  onArchivedClick,
  onCancelClick,
  customView,
  centered,
}) => {
  return (
    <div className={cn(["card", style.box])}>
      <div className={cn(["card-body", centered ? style.flexBody : ""])}>
        <div className={style.content}>{children}</div>
        {!customView ? (
          <div>
            <button
              onClick={onCancelClick}
              type="button"
              className="btn btn-secondary btn-lg mr-4"
            >
              ANNULER
            </button>
            <button
              onClick={onArchivedClick}
              type="button"
              className="btn btn-danger btn-lg"
            >
              ARCHIVER
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

Archive.propTypes = {
  customView: PropTypes.bool,
  centered: PropTypes.bool,
}
Archive.defaultProps = {
  customView: false,
  centered: true,
}

export default Archive
