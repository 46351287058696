// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useDurationsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getDurations = () => request("GET", `${API_STAGES_URL}/v1/durations`)

  const getDurationWithId = durationId =>
    request("GET", `${API_STAGES_URL}/v1/durations/${durationId}`)

  const updateDuration = duration =>
    request("PUT", `${API_STAGES_URL}/v1/durations/${duration.id}`, duration)

  const createDuration = duration =>
    request("POST", `${API_STAGES_URL}/v1/durations`, duration)

  return [
    requestState,
    payload,
    error,
    {
      getDurations,
      getDurationWithId,
      updateDuration,
      createDuration,
    },
  ]
}

export default useDurationsApi
