import React, { useEffect, useMemo, useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import PropTypes from "prop-types"

// Components
import Group from "./Group"
import Reserve from "./Reserve"
import Picto from "../../../../../components/atoms/Picto"

// hooks
import useBookingsForCoachApi from "../../../../../hooks/Api/useBookingsForCoachApi"
import useCoachesApi from "../../../../../hooks/Api/useCoachesApi"

//Style
import style from "./SlotsAndGroupsOfDay.module.css"

// utils & misc
import { getRotationAuthorizedOrdinal, getTitleRotation } from "../../../../../utils/coach/mappers"

const SlotsAndGroupsOfDay = ({ sections, updateSections, handleResetSection, countries }) => {
  const [playersSelected, setPlayersSelected] = useState([])
  const [draggedPlayer, setDraggedPlayer] = useState(null)

  const [, , , { postSessionToGroup }] = useBookingsForCoachApi()
  const [, coaches, , { getCoaches }] = useCoachesApi()

  // finders
  const sectionActive = sections.find(section => section.isActive)
  const IndexSectionActive = sections.findIndex(section => section.isActive)

  const allPlayersInSection = useMemo(() => (
    (sectionActive?.weekSectionRotations
      ?.reduce((all, rotation) => all.concat(rotation.weekSectionRotationGroups), [])
      ?.reduce((all, group) => all.concat(group.players), [])
      || []
    ).concat(
      sectionActive.reserve || []
    )
  ), [sectionActive])

  useEffect(() => {
    if (coaches === null) getCoaches()
  }, [coaches])

  const addEmptyGroup = newGroupToCreate => {
    const indexdRotation = sectionActive.weekSectionRotations.findIndex(
      rotation => rotation.rotationCode === newGroupToCreate.rotationCode
    )
    const idGroupToOpen = sectionActive.weekSectionRotations
      .find(rotation => rotation.rotationCode === newGroupToCreate.rotationCode)
      .weekSectionRotationGroups.findIndex(group => !group.isOpen)

    const sectionsUpdated = sections.slice()
    sectionsUpdated[IndexSectionActive].weekSectionRotations[
      indexdRotation
    ].weekSectionRotationGroups[idGroupToOpen].isOpen = true

    updateSections(sectionsUpdated)
  }

  const handleBlurAllInputs = () => {
    const groups = document.querySelectorAll('.sectionsGroupsGroupInput');
    if (Array.from(groups)?.length) {
      Array.from(groups).forEach((input) => {
        input.blur();
      });
    }

    const levels = document.querySelectorAll('.sectionsGroupsLevelInput');
    if (Array.from(levels)?.length) {
      Array.from(levels).forEach((input) => {
        input.blur();
      });
    }

    const coaches = document.querySelectorAll('.sectionsGroupsCoachSelect');
    if (Array.from(coaches)?.length) {
      Array.from(coaches).forEach((select) => {
        select.blur();
      });
    }
  };

  const handleActiveDropZone = (isDragging) => {
    // Add active class to drop zones
    let dropzones = document.querySelectorAll('.group-dropzone')
    dropzones = dropzones ? [...dropzones] : []
    dropzones.forEach((zone) => {
      if (isDragging) {
        zone.classList.add(style.active)
      } else {
        zone.classList.remove(style.active)
      }
    })
  }

  const onDragStart = evt => {
    handleBlurAllInputs();
    const idPlayerDraged = evt.draggableId.split(":")[0]
    if (!playersSelected.some(player => player.id === idPlayerDraged)) {
      setPlayersSelected([])
    }

    setDraggedPlayer(idPlayerDraged)
    handleActiveDropZone(true)
  }

  const onDragEnd = evt => {
    setDraggedPlayer(null)
    handleActiveDropZone(false)

    if (evt.destination === null) return
    if (
      evt.source.droppableId === evt.destination.droppableId &&
      playersSelected.length < 1
    )
      return

    const pathFromPlayer = evt.draggableId.split(":")
    const isPlayerFromReserve = evt.source.droppableId === "reserve"
    const isPlayerToReserve = evt.destination.droppableId === "reserve"

    const findPlayerTarget = () => {
      if (pathFromPlayer.length === 3) {
        return sectionActive.reserve[evt.source.index]
      }
      const playerFound = {
        ...sectionActive.weekSectionRotations
          .find(rotation => rotation.rotationCode === pathFromPlayer[3])
          .weekSectionRotationGroups.find(
            group => group.ordinal === Number(pathFromPlayer[4])
          )
          .players.find(player => player.id === pathFromPlayer[0]),
        weekSectionRotationGroupCode: isPlayerToReserve
          ? null
          : sectionActive.code,
      }
      return playerFound
    }

    const playerTarget = findPlayerTarget()

    if (playersSelected.length > 1) {
      playersSelected.map(player =>
        postSessionToGroup(evt.destination.droppableId, player.id)
      )
    } else {
      postSessionToGroup(evt.destination.droppableId, playerTarget.id)
    }

    const splicerGroupPlayers = (group, player) => {
      const result = group.slice()
      result.splice(evt.destination.index, 0, player)
      return result
    }

    const reserveUpdate = section => {
      if (playersSelected.length > 1) {
        let reserve = section.reserve.filter(
          player => !listOfIdsPlayers.includes(player.id)
        )
        if (!isPlayerToReserve) {
          playersSelected.forEach(player => {
            if (player.weekSectionRotationGroupCode === null)
              reserve = reserve.filter(
                playerReserve => playerReserve.id !== player.id
              )
          })
          return reserve
        } else {
          reserve.splice(evt.destination.index, 0, ...playersSelected)
        }
        return reserve
      }

      if (isPlayerFromReserve) {
        if (isPlayerToReserve) return section.reserve
        return section.reserve.filter(
          (p, playerIndex) => playerIndex !== evt.source.index
        )
      }
      if (isPlayerToReserve) {
        const gotoreserve = splicerGroupPlayers(section.reserve, playerTarget)
        return gotoreserve
      }
      return section.reserve
    }

    const multipleSplicer = group => {
      if (playersSelected.length > 1) {
        const multiplePlayers = playersSelected.map(player => ({
          ...player,
          weekSectionRotationGroupCode: group.code,
        }))
        const result = group.players
          .filter(player => !listOfIdsPlayers.includes(player.id))
          .slice()
        result.splice(evt.destination.index, 0, ...multiplePlayers)
        return result
      }
      return false
    }

    const listOfIdsPlayers = playersSelected.map(player => player.id)

    const sectionsToUpdate = () => {
      return sections.slice().map(section => {
        if (section.code !== sectionActive.code) return section
        return {
          ...section,
          reserve: reserveUpdate(section),
          weekSectionRotations: section.weekSectionRotations.map(rotation => {
            return {
              ...rotation,
              weekSectionRotationGroups: rotation.weekSectionRotationGroups.map(
                group => {
                  // if that group is NOT the destination
                  if (group.code !== evt.destination.droppableId) {
                    // if multiselected
                    if (
                      playersSelected.length > 1 &&
                      group.players.some(player =>
                        listOfIdsPlayers.includes(player.id)
                      )
                    ) {
                      return {
                        ...group,
                        players: group.players.filter(
                          player => !listOfIdsPlayers.includes(player.id)
                        ),
                      }
                    }
                    // if the dragged player is alone
                    return {
                      ...group,
                      players: group.players.filter(
                        player => player.id !== playerTarget.id
                      ),
                    }
                  }
                  // if that group is the destination
                  return {
                    ...group,
                    players:
                      multipleSplicer(group) ||
                      splicerGroupPlayers(group.players, {
                        ...playerTarget,
                        weekSectionRotationGroupCode: group.code,
                      }),
                  }
                }
              ),
            }
          }),
        }
      })
    }

    setPlayersSelected([])
    updateSections(sectionsToUpdate())
  }

  const updateGroup = ({sectionActive, rotation} ,groupToUpdate) => {
    const sectionsUpdated = sections.map(section => {
      if (section.code !== sectionActive.code) return section
      else{ 
        return { 
        ...sectionActive, 
        weekSectionRotations : sectionActive.weekSectionRotations.map(weekSectionRotation => {
          if (weekSectionRotation.code !== rotation.code) return weekSectionRotation;
          else{ 
            return {
              ...weekSectionRotation,
              weekSectionRotationGroups: weekSectionRotation.weekSectionRotationGroups.map( weekSectionRotationGroup => {
                if (weekSectionRotationGroup.code !== groupToUpdate.code) return weekSectionRotationGroup
                else {
                  return groupToUpdate
                }
              })
            }
          }
        }) 
      }}
    })
    updateSections(sectionsUpdated)
  }

  return (
    <div className={style["slots-groups"]}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        style={{ overflow: "auto" }}
      >
        <div className={style.slots}>
          {sectionActive.weekSectionRotations.map((rotation, rotationIndex) => (
            <div key={rotationIndex} className={style.slot}>
              <h3 className={style["title-rotation"]}>
                {getTitleRotation(rotation.rotationCode)}
              </h3>
              <div className={style.groups}>
                {rotation.weekSectionRotationGroups.map((group, groupIndex) => {
                  return (
                    coaches &&
                    group.isOpen && (
                      <Group
                        key={groupIndex}
                        coaches={coaches?.coaches}
                        group={{ ...group, index: groupIndex + 1 }}
                        playersSelected={playersSelected}
                        updatePlayerSelected={setPlayersSelected}
                        updateGroup={(g) => updateGroup( { sectionActive, rotation }, g)}
                        sections={sections}
                        handleResetSection={handleResetSection}
                        countries={countries}
                        draggedPlayer={draggedPlayer}
                        allPlayersInSection={allPlayersInSection}
                        authorizedOrdinal={getRotationAuthorizedOrdinal(rotation.rotationCode)}
                        dropzoneClasses={{
                          groupDropzone: style.group_dropzone,
                          dropzoneDisabled: style.dropzone_disabled,
                        }}
                      />
                    )
                  )
                })}
                {rotation.weekSectionRotationGroups.filter(
                  group => group.isOpen
                ).length < 30 && (
                  <button
                    className={style["button-add-group"]}
                    onClick={() => addEmptyGroup(rotation)}
                    aria-label="button to add group"
                  >
                    <Picto
                      icon="plus"
                      className={style["button-add-group__icon"]}
                    />
                    <p className={style["button-add-group__label"]}>
                      Ajouter un groupe
                    </p>
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {sectionActive?.reserve && (
          <Reserve
            players={sectionActive.reserve}
            playersSelected={playersSelected}
            updatePlayerSelected={setPlayersSelected}
            sections={sections}
            handleResetSection={handleResetSection}
            countries={countries}
          />
        )}
      </DragDropContext>
    </div>
  )
}

SlotsAndGroupsOfDay.propTypes = {
  sections: PropTypes.array,
  updateSections: PropTypes.func,
  handleResetSection: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
}

export default SlotsAndGroupsOfDay
