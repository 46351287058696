import React, { useEffect, useState, useCallback } from "react"

// Components
import FidPointsHistory from "./FidPointsHistory/FidPointsHistory"
import FidPointsManager from "./FidPointsManager/FidPointsManager"

// Hooks
import useAppContext from "../../../../../hooks/useAppContext"

// Utils
import cn from "../../../../../utils/cn"

// Style
import style from "./FidPointsMember.module.css"

const menuItems = [
  {
    name: "Gestion des points",
    key: "MANAGE_POINTS",
    component: <FidPointsManager />,
  },
  {
    name: "Historique des derniers crédits",
    key: "CREDIT_HISTORY",
    component: <FidPointsHistory />,
  },
]

const MenuItem = ({ menuItem, active, handleMenuClick, index }) => (
  <li className={cn(["nav-item", style.menuItem])}>
    <a
      onClick={() => handleMenuClick(menuItem.key)}
      className={cn([
        "nav-link",
        active ? style.active : "",
        style.link,
        style.navText,
      ])}
      href="#"
    >
      {menuItem.name}
    </a>
  </li>
)

const FidPointsMember = ({ isAdmin }) => {
  const {
    setFidActiveMember,
    context: { fidActiveMember },
  } = useAppContext()
  const [activeKey, setActiveKey] = useState("MANAGE_POINTS")

  const initialLoad = useCallback(async () => {
    try {
      //
    } catch (error) {
      //
    }
  }, [])

  useEffect(() => {
    initialLoad()
  }, [initialLoad])

  const handleIndexChange = index => {
    setActiveKey(index)
  }

  return (
    <div>
      <h3 className={style.header}>Infos Membre :</h3>
      {fidActiveMember ? (
        <button
          type="button"
          className="btn btn-primary btn-sm mb-4"
          onClick={() => setFidActiveMember(null)}
        >
          <i className="fas fa-undo mr-2" />
          Retour à la recherche
        </button>
      ) : null}
      <ul className={cn(["nav", style.menu])}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            handleMenuClick={handleIndexChange}
            index={index}
            active={menuItem.key === activeKey}
            menuItem={menuItem}
            key={`${menuItem.key}`}
          />
        ))}
      </ul>
      {menuItems.find(item => item.key === activeKey).component}
    </div>
  )
}
export default FidPointsMember
