import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { isEmpty, isNil, pick } from 'lodash';
import { PERMISSIONS } from '../../../constants/constants';
import { useHistory } from 'react-router-dom';
import { getInitialLocaleValues } from '../../../utils/locales';
import cn from '../../../utils/cn';
import { useFormik } from 'formik';

// Hooks
import useAppContext from '../../../hooks/useAppContext';
import usePermissionsState from '../../../hooks/usePermissionsState';
import useQuestionsApi from '../../../hooks/Api/useQuestionsApi';

// Components
import WithMenu from '../../../components/templates/WithMenu/WithMenu';
import Editor from "../../../components/organisms/Editor/Editor";

const permissions = [
  PERMISSIONS.PERM_QUESTIONS_UPDATE,
  PERMISSIONS.PERM_QUESTIONS_CREATE,
];

const QuestionEdit = ({ match }) => {
  const history = useHistory();

  const { context: { locales, userInfo } } = useAppContext();
  const rolePermissions = usePermissionsState(userInfo, permissions);

  const [getQuestionState, questionData,, { getQuestionById }] = useQuestionsApi();
  const [updateQuestionState,, updateQuestionError, { updateQuestion }] = useQuestionsApi();
  const [createQuestionState,, createQuestionError, { createQuestion }] = useQuestionsApi();

  const isEditing = !isEmpty(match?.params);

  const backToList = () => {
    history.push({
      pathname: "/questions",
      state: {},
    })
  }

  const isAllowedToEdit = rolePermissions[PERMISSIONS.PERM_QUESTIONS_UPDATE];
  const isAllowedToCreate = rolePermissions[PERMISSIONS.PERM_QUESTIONS_CREATE];
  const isAllowedToEditOrCreate = (isEditing && isAllowedToEdit) || (!isEditing && isAllowedToCreate);

  useEffect(() => {
    if (isEditing) {
      getQuestionById(match.params.id).then((res) => {
        if (!res?.data) backToList();
      });
    }
  }, []);

  const createInitialValues = {
    title: locales ? getInitialLocaleValues(locales) : {},
    description: locales ? getInitialLocaleValues(locales) : {},
  };

  const handleSubmit = (e, values) => {
    e.preventDefault();
    const question = pick(values, ['title', 'description']);

    if (isEditing) {
      // edit question
      updateQuestion({ questionId: questionData.id, question }).then((res) => {
        if (res?.status === 200) {
          history.push({
            pathname: "/questions",
            state: { submitType: "edit", success: true },
          });
        }
      });
    } else {
      // create new question
      createQuestion(question).then((res) => {
        if (res?.status === 201) {
          history.push({
            pathname: "/questions",
            state: { submitType: "create", success: true },
          });
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: isEditing ? questionData || {} : createInitialValues,
    enableReinitialize: true,
  });

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Questions / ${isEditing ? `Éditer / Question ${questionData?.id || ''}` : "Ajouter"}`}
        </h1>
      </div>

      {(isEditing && !questionData) || getQuestionState === 'loading' || isNil(locales) ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <form
          className="mw-100 w-75 m-auto" 
          onSubmit={(e) => handleSubmit(e, formik.values)}
        >
          <div className={cn(["card mb-4"])}>
            <div className="card-body">
              <div className="form-row">
                {Object.keys(locales).map(localeId => (
                  <div
                    key={`title_${localeId}`}
                    className="col-sm-6 col-md-6 pb-3"
                  >
                    <b>
                      <label htmlFor={`title.${localeId}`}>
                        {`Titre ${locales[localeId].code.toUpperCase()}`}
                      </label>
                    </b>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id={`title.${localeId}`}
                      name={`title.${localeId}`}
                      value={formik.values?.title?.[localeId] || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!isAllowedToEditOrCreate}
                      placeholder={`Titre ${locales[localeId].code.toUpperCase()}`}
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={cn(["card mb-4"])}>
            <div className="card-body">
              <div className="form-row">
                {Object.keys(locales).map(localeId => (
                  <div
                    key={`description_${localeId}`}
                    className="col-sm-6 col-md-6 pb-3"
                  >
                    <b>
                      <label>
                        {`Description ${locales[localeId].code.toUpperCase()}`}
                      </label>
                    </b>

                    <Editor
                      initialContent={formik.values?.description?.[localeId]}
                      handleContentChange={
                        (htmlContent) => formik.setFieldValue(`description.${localeId}`, htmlContent)
                      }
                      label={null}
                      error={null}
                      disabled={!isAllowedToEditOrCreate}
                      placeholder={`Description ${locales[localeId].code.toUpperCase()}`}
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {updateQuestionError?.data?.message || createQuestionError?.data?.message ? (
            <div className="alert alert-danger" role="alert">
              {updateQuestionError?.data?.message || createQuestionError?.data?.message}
            </div>
          ) : null}

          <div className="d-flex align-items-center justify-content-end mb-4">
            <button
              onClick={backToList}
              type="button"
              className="btn btn-secondary mr-2"
            >
              Revenir à la liste
            </button>

            {isAllowedToEditOrCreate ? (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  updateQuestionState === "loading" ||
                  createQuestionState === "loading"
                }
              >
                {updateQuestionState === "loading" || createQuestionState === "loading" ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isEditing ? 'Enregistrer' : 'Ajouter'}
              </button>
            ) : null}
          </div>
        </form>
      )}
    </WithMenu>
  )
}

QuestionEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }).isRequired,
};

export default QuestionEdit