export const exportCollectionToArray = (initialPayload, collection) => {
  if (initialPayload && initialPayload[collection])
    return Object.keys(initialPayload[collection]).map(
      i => initialPayload[collection][i]
    )
  return []
}

// TODO problem trad on some field of payload
/**
 *
 * @param {object} initialPayload
 * @param {string} collection
 */
export const exportCollectionToArrayName = (initialPayload, collection) => {
  if (initialPayload && initialPayload[collection])
    return Object.keys(initialPayload[collection]).map(i => ({
      ...initialPayload[collection][i],
      name: initialPayload[collection][i].name[1],
    }))
  return []
}
// TODO problem trad on some field of payload
/**
 *
 * @param {object} initialPayload
 * @param {string} collection
 */
export const exportCollectionToArrayProduct = (initialPayload, collection) => {
  if (initialPayload && initialPayload[collection])
    return Object.keys(initialPayload[collection]).map(i => ({
      ...initialPayload[collection][i],
      name: initialPayload[collection][i].product.name[1],
    }))
  return []
}

// TODO problem trad on some field of payload
/**
 *
 * @param {object} initialPayload
 * @param {string} collection
 */
export const exportCollectionToArrayImages = (
  initialPayload,
  collection,
  nameField
) => {
  if (initialPayload && initialPayload[collection])
    return Object.keys(initialPayload[collection]).map(i => ({
      ...initialPayload[collection][i],
      name: initialPayload[collection][i][nameField],
    }))
  return []
}
