import React, { useState } from "react"
import Tooltip from "react-tooltip-lite"
import PropTypes from "prop-types"

// Utils
import copyTextToClipboard from "../../../utils/copyTextToClipboard"

// Style
import style from "./IDTooltip.module.css"

export const useDropdown = () => {
  const [isToggled, setIsToggled] = useState(false)
  const toggle = () => setIsToggled(!isToggled)
  return [isToggled, toggle]
}

const IDTooltip = ({ uid }) => {
  const [idCopied, setIdCopied] = useState(false)

  const handleIdClick = id => {
    copyTextToClipboard(id)
    if (id) {
      setIdCopied(true)
      setTimeout(() => {
        setIdCopied(false)
      }, 3000)
    }
  }

  return (
    <span
      onKeyDown={null}
      role="button"
      tabIndex={[0]}
      onClick={() => handleIdClick(uid || "")}
      className={style.idCol}
    >
      <Tooltip
        direction="up-start with arrow"
        tipContentClassName={style.idTooltipContent}
        content={idCopied ? "ID copié" : uid || ""}
      >
        {uid && typeof uid === "string" ? `${uid.substring(0, 8)}...` : ""}
      </Tooltip>
    </span>
  )
}

IDTooltip.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default IDTooltip
