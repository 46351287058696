import React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"

// Utils & Misc
import cn from "../../../utils/cn"
import { ReactComponent as Loader } from "../../../assets/loader/oval.svg"

// Style
import style from "./Button.module.css"

export const Button = ({
  label,
  size,
  type,
  onClick,
  shape,
  icon,
  className,
  hasLoader,
  color,
  children,
  ...propsLeft
}) => (
  <button
    type={type}
    onClick={onClick}
    className={cn([
      style[shape],
      style.button,
      hasLoader && style.hasLoader,
      className,
      style[color],
      style[size]
    ])}
    {...propsLeft}
  >
    {children}
    {icon ? <span className={style.icon}>icon</span> : null}
    {hasLoader ? <Loader className={style.loader} /> : null}
    {label}
  </button>
)

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf([
    "fill",
    "border",
    "fill-disabled",
    "border-disabled",
  ]),
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]),
  type: PropTypes.string,
  icon: PropTypes.oneOf(["test"]),
  className: PropTypes.string,
  hasLoader: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "green"]),
}

Button.defaultProps = {
  onClick: noop,
  shape: "fill",
  type: "button",
  size: "large",
  icon: null,
  className: null,
  hasLoader: false,
  color: "primary",
}

export default Button
