import React, { useEffect, useCallback, useState } from "react"
import { useParams } from "react-router-dom"

import useYearsApi from "../../../../hooks/Api/useYearsApi"
import useSeasonsApi from "../../../../hooks/Api/useSeasonsApi"
import useWeeksApi from "../../../../hooks/Api/useWeeksApi"
import useOffersApi from "../../../../hooks/Api/useOffersApi"
import usePackagesApi from "../../../../hooks/Api/usePackagesApi"

import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import PackagesLegacy from "./PackagesLegacy"

import { exportCollectionToArray } from "../../../../utils/collection"
import {
  buildWeeksIdsByYears,
  buildOrderedWeeks,
  buildCollectionWeeks,
  buildActiveYears,
  buildActiveWeeks,
  buildOffers,
} from "../../../../utils/yieldStockUtils"

import style from "./Packages.module.css"

const Packages = () => {
  const { id: familyId } = useParams()
  const [loading, setLoading] = useState(false)
  const [weeks, setWeeks] = useState({})
  const [years, setYears] = useState({})
  const [offers, setOffers] = useState({})
  const [orderedWeeks, setOrderedWeeks] = useState({})

  const [, seasonsData, , { getSeasons }] = useSeasonsApi()
  const [, yearsData, , { getYears }] = useYearsApi()
  const [, weeksData, , { getWeeks }] = useWeeksApi()
  const [, offersData, , { getOffers }] = useOffersApi()
  const [, packagesData, , { getPackages }] = usePackagesApi()

  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetYears = useCallback(getYears, [])
  const savedGetWeeks = useCallback(getWeeks, [])
  const savedGetOffers = useCallback(getOffers, [])
  const savedGetPackages = useCallback(getPackages, [])

  useEffect(() => {
    savedGetWeeks().then(res => {
      if (res && res.data) {
        const weeksFormatted = exportCollectionToArray(res.data, "weeks")
        const weeksByYears = buildWeeksIdsByYears(weeksFormatted)
        savedGetOffers({
          minWeekId: weeksByYears[0].id,
          maxWeekId: weeksByYears[weeksByYears.length - 1].id,
          familyId,
        })
      }
    })
    savedGetSeasons()
    savedGetYears()
    savedGetPackages()
  }, [
    savedGetSeasons,
    savedGetWeeks,
    savedGetYears,
    savedGetOffers,
    savedGetPackages,
    familyId,
  ])

  useEffect(() => {
    if (weeksData && seasonsData && yearsData && packagesData && offersData) {
      const yearsFormatted = exportCollectionToArray(yearsData, "years")
      const weeksFormatted = exportCollectionToArray(weeksData, "weeks")

      const activeWeeks = buildCollectionWeeks(
        weeksFormatted,
        seasonsData.seasons
      )

      setYears(buildActiveYears(yearsFormatted))
      setWeeks(buildActiveWeeks(activeWeeks))
      setOrderedWeeks(buildOrderedWeeks(activeWeeks, yearsFormatted))
      setOffers(buildOffers(offersData))
      setLoading(true)
    }
  }, [weeksData, yearsData, seasonsData, offersData, packagesData])

  // TODO call update offers api
  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Yields / Packages</h1>
      </div>

      {loading ? (
        <PackagesLegacy
          years={years}
          weeks={weeks}
          packages={packagesData.packages}
          prices={offers}
          orderedPackagesIds={Object.keys(packagesData.packages)
            .filter(packageId => !packagesData.packages[packageId].archived)
            .map(packageId => packageId)}
          orderedYearsIds={Object.keys(years).map(year => year)}
          orderedWeeksIds={orderedWeeks}
        />
      ) : (
        // <></>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

export default Packages
