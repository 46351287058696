import React, { useState } from "react"

// Components
import Header from "./Header"
import Students from "./Students/Students"
import Products from "./Products/Products"
import Locales from "./Locales/Locales"
import Exports from "./Exports/Exports"
import Config from "./Config/Config"
import Stocks from "./Stocks/Stocks"
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useAppContext from "../../../hooks/useAppContext"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

const permissions = [ // eslint-disable-line no-unused-vars
  // PRODUITS
  PERMISSIONS.PERM_PRODUCTS_CREATE,
  PERMISSIONS.PERM_OPTIONS_CREATE,
  PERMISSIONS.PERM_PRODUCTS_OPTIONS_DISPLAY,
  PERMISSIONS.PERM_OPTIONS_ARCHIVE,
  PERMISSIONS.PERM_OPTIONS_UPDATE,
  PERMISSIONS.PERM_PRODUCTS_ARCHIVE,
  PERMISSIONS.PERM_PRODUCTS_UPDATE,

  // LOCALES
  PERMISSIONS.PERM_CAMPUS_LOCALES_UPDATE,
  PERMISSIONS.PERM_CAMPUS_LOCALES_DISPLAY,

  // EXPORT DOSSIER
  PERMISSIONS.PERM_FORMS_EXPORT,

  // STUDENTS
  PERMISSIONS.PERM_STUDENTS_CREATE,
  PERMISSIONS.PERM_STUDENTS_UPDATE,
  PERMISSIONS.PERM_STUDENTS_DISPLAY,

  // CONFIG
  PERMISSIONS.PERM_CAMPUS_CONFIG_DISPLAY,
  PERMISSIONS.PERM_CAMPUS_CONFIG_UPDATE,

  // STOCKS
  PERMISSIONS.PERM_CAMPUS_STOCK_DISPLAY,
  PERMISSIONS.PERM_CAMPUS_STOCK_UPDATE,
]

// TODO: should have proper routing when the app will be stand-alone and better data flow
// handling, with custom hooks a more DRY code on API calls

const StudentsIndex = ({ SeApiUri = "" }) => {
  const {
    context: { userInfo },
  } = useAppContext()

  const routes = {
    students: {
      comp: <Students />,
      name: "Étudiants",
      permissions: [
        PERMISSIONS.PERM_STUDENTS_CREATE,
        PERMISSIONS.PERM_STUDENTS_UPDATE,
        PERMISSIONS.PERM_STUDENTS_DISPLAY,
      ],
    },
    products: {
      comp: <Products />,
      name: "Produits",
      permissions: [
        PERMISSIONS.PERM_PRODUCTS_CREATE,
        PERMISSIONS.PERM_PRODUCTS_OPTIONS_DISPLAY,
        PERMISSIONS.PERM_PRODUCTS_UPDATE,
      ],
    },
    locales: {
      comp: <Locales />,
      name: "Locales",
      permissions: [
        PERMISSIONS.PERM_CAMPUS_LOCALES_DISPLAY,
        PERMISSIONS.PERM_CAMPUS_LOCALES_UPDATE,
      ],
    },
    config: {
      comp: <Config />,
      name: "Config",
      permissions: [
        PERMISSIONS.PERM_CAMPUS_CONFIG_DISPLAY,
        PERMISSIONS.PERM_CAMPUS_CONFIG_UPDATE,
      ],
    },
    exports: {
      comp: <Exports />,
      name: "Exports",
      permissions: [PERMISSIONS.PERM_FORMS_EXPORT],
    },
    stocks: {
      comp: <Stocks />,
      name: "Stocks",
      permissions: [
        PERMISSIONS.PERM_CAMPUS_STOCK_DISPLAY,
        PERMISSIONS.PERM_CAMPUS_STOCK_UPDATE,
      ],
    },
  }
  const [selectedRoute, setSelectedRoute] = useState("students")

  const handleNavClick = link => setSelectedRoute(link)

  return (
    <>
      <WithMenu>
        <Header userInfo={userInfo} onClick={handleNavClick} routes={routes} />
        {routes[selectedRoute].comp}
      </WithMenu>
    </>
  )
}

export default StudentsIndex
