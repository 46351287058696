import React, { useEffect, useCallback, useState } from "react"
import {
  isAfter,
  parse,
  isBefore,
  addYears,
  format,
  addDays,
  startOfYear,
  endOfYear,
  getYear,
} from "date-fns"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useSeasonsApi from "../../../hooks/Api/useSeasonsApi"
import useWeeksApi from "../../../hooks/Api/useWeeksApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./SeasonsList.module.css"

const permissions = [PERMISSIONS.PERM_SEASONS_UPDATE]

const SeasonsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [
    seasonsRequestState,
    seasonsData,
    seasonsError,
    { getSeasons },
  ] = useSeasonsApi()
  const [weeksRequestState, weeksData, , { getWeeks }] = useWeeksApi()
  const [, , , { updateWeeks }] = useWeeksApi()
  const [yearTabs, setYearTabs] = useState([])
  const [selectedYear, setSelectedYear] = useState(null)

  const savedGetSeasons = useCallback(getSeasons, [])
  const savedGetWeeks = useCallback(getWeeks, [])

  useEffect(() => {
    savedGetSeasons()
    savedGetWeeks()
  }, [savedGetSeasons, savedGetWeeks])

  const exportCollectionToArray = (initialPayload, collection) => {
    if (initialPayload && initialPayload[collection])
      return Object.keys(initialPayload[collection]).map(
        i => initialPayload[collection][i]
      )
    return []
  }

  const getTableData = () => {
    let data = exportCollectionToArray(weeksData, "weeks")

    data = data
      .filter(week => {
        const currentDate = new Date()
        const currentYear = getYear(currentDate)
        const startYear = startOfYear(currentDate)

        const date = parse(week.start, "yyyy-MM-dd", new Date())
        const dateYear = getYear(date)

        if (
          currentYear === 2020 &&
          dateYear === 2019 &&
          week.start === "2019-12-30"
        ) {
          return true
        }

        return (
          isAfter(date, startYear) &&
          isBefore(date, endOfYear(addYears(startYear, 2))) &&
          dateYear >= currentYear &&
          dateYear <= currentYear + 2
        )
      })
      .map(log => {
        return { ...log }
      })
    return data
  }

  useEffect(() => {
    if (weeksData) {
      const weeks = getTableData()

      const yearArray = []

      weeks.map(week => {
        let year = parse(week.start, "yyyy-MM-dd", new Date()).getFullYear()

        if (week.start === "2019-12-30") {
          year = 2020
        }

        if (!yearArray.find(y => y.year === year))
          yearArray.push({
            year,
            weeks: [week],
          })
        else {
          yearArray[yearArray.findIndex(y => y.year === year)].weeks.push(week)
        }
        return 1
      })
      setYearTabs(yearArray)
      if (yearArray.length) setSelectedYear(yearArray[0].year)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeksData])

  const editSeasonId = (weekId, seasonId) => {
    const weekData = yearTabs
      .find(y => y.year === selectedYear)
      .weeks.find(w => w.id === Number(weekId))
    updateWeeks(weekId, {
      seasonId: Number(seasonId),
      freeInsurance: weekData.freeInsurance,
    })

    const newYearTabs = yearTabs.map(year => {
      const newYear = { ...year }
      newYear.weeks = year.weeks.map(week => {
        const newWeek = { ...week }
        if (week.id === weekId) newWeek.seasonId = Number(seasonId)

        return newWeek
      })
      return newYear
    })

    setYearTabs(newYearTabs)
  }

  const handleWeekInsuranceToggleClick = (weekId, freeInsurance) => {
    const weekData = yearTabs
      .find(y => y.year === selectedYear)
      .weeks.find(w => w.id === Number(weekId))

    updateWeeks(weekId, {
      seasonId: weekData.seasonId,
      freeInsurance,
    })

    const newYearTabs = yearTabs.map(year => {
      const newYear = { ...year }
      newYear.weeks = year.weeks.map(week => {
        const newWeek = { ...week }
        if (week.id === weekId) newWeek.freeInsurance = freeInsurance

        return newWeek
      })
      return newYear
    })

    setYearTabs(newYearTabs)
  }

  const isDataReady =
    seasonsRequestState === "completed" &&
    seasonsData &&
    weeksRequestState === "completed" &&
    weeksData

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Saisons</h1>
      </div>
      {seasonsError ? (
        <div className="alert alert-seasonsError" role="alert">
          {seasonsError}
        </div>
      ) : null}

      {isDataReady && selectedYear ? (
        <ul className="nav nav-pills">
          {yearTabs.map(y => (
            <li key={`tab_${y.year}`} className="nav-item">
              <option
                onKeyDown={() => setSelectedYear(y.year)}
                onClick={() => setSelectedYear(y.year)}
                className={`nav-link ${style.yearTabPill} ${
                  selectedYear === y.year ? "active" : ""
                }`}
              >
                {y.year}
              </option>
            </li>
          ))}
        </ul>
      ) : null}

      {!isDataReady || !selectedYear ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Table
          columns={[
            {
              Header: "Semaine",
              accessor: "number",
              Cell: ({ cell: { value } }) => <div>{`S${value}`}</div>,
            },
            {
              Header: "Du",
              accessor: "start",
              Cell: ({ cell: { value } }) => (
                <div>
                  {format(parse(value, "yyyy-MM-dd", new Date()), "dd/MM")}
                </div>
              ),
            },
            {
              Header: "Au",
              accessor: "start-end",
              Cell: ({
                cell: {
                  row: {
                    original: { start },
                  },
                },
              }) => (
                <div>
                  {format(
                    addDays(parse(start, "yyyy-MM-dd", new Date()), 6),
                    "dd/MM"
                  )}
                </div>
              ),
            },
            {
              Header: "Type de saison",
              accessor: "seasonId",
              Cell: ({
                cell: {
                  value,
                  row: {
                    original: { id },
                  },
                },
              }) => (
                <select
                  className="custom-select"
                  id="discounts.typeId"
                  name="discounts.typeId"
                  required
                  disabled={!rolePermissions[PERMISSIONS.PERM_SEASONS_UPDATE]}
                  onChange={e => editSeasonId(id, e.target.value)}
                  value={value}
                >
                  {Object.keys(seasonsData.seasons)
                    .sort((a, b) => {
                      return (
                        seasonsData.seasons[a].ordinal -
                        seasonsData.seasons[b].ordinal
                      )
                    })
                    .map(seasonId => (
                      <option
                        key={`option_select_${seasonId}`}
                        value={seasonsData.seasons[seasonId].id}
                      >
                        {seasonsData.seasons[seasonId].name}
                      </option>
                    ))}
                </select>
              ),
            },
            {
              Header: "Assurance Gratuite",
              accessor: "freeInsurance",
              Cell: ({
                cell: {
                  value,
                  row: {
                    original: { freeInsurance, id },
                  },
                },
              }) => (
                <div
                  onKeyDown={null}
                  tabIndex={[0]}
                  role="button"
                  onClick={() =>
                    rolePermissions[PERMISSIONS.PERM_SEASONS_UPDATE]
                      ? handleWeekInsuranceToggleClick(id, !freeInsurance)
                      : () => {}
                  }
                  className="custom-control custom-switch"
                >
                  <input
                    disabled={!rolePermissions[PERMISSIONS.PERM_SEASONS_UPDATE]}
                    type="checkbox"
                    checked={freeInsurance}
                    className="custom-control-input"
                    readOnly
                  />
                  <label className="custom-control-label" />
                </div>
              ),
            },
          ]}
          data={yearTabs.find(y => y.year === selectedYear).weeks}
          hasPagination
          hasSort
        />
      )}
    </WithMenu>
  )
}
export default SeasonsList
