import React, { useState } from "react"
import PropTypes from "prop-types"
import { Droppable } from "react-beautiful-dnd"

// Components
import Player from "./Player"

//Style
import style from "./Reserve.module.css"


const Reserve = ({ players, playersSelected, updatePlayerSelected, sections, handleResetSection, countries }) => {
  const [playerContextMenuOpened, setPlayerContextMenuOpened] = useState(null);

  const handleSelectPlayer = (e, playerClicked) => {
    e.stopPropagation()
    if (e.ctrlKey || e.metaKey) {
      const isPlayerAlreadySelected = playersSelected.some(
        playerSelected => playerSelected.id === playerClicked.id
      )
      if (isPlayerAlreadySelected) {
        updatePlayerSelected(
          playersSelected.filter(
            playerSelected => playerSelected.id !== playerClicked.id
          )
        )
      } else updatePlayerSelected(playersSelected.concat(playerClicked))
    }
  }

  if (!players) return null;

  return (
    <Droppable droppableId="reserve">
      {(provided, snapshot) => {
        return (
          <div
            className={`${style.reserve} ${
              snapshot.isDraggingOver ? style.isDragIn : ""
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {provided.placeholder}
            <h3 className={style.title}>Réserve</h3>
            {players.map((player, playerIndex) => {
              return (
                <Player
                  key={player.id}
                  handleSelectPlayer={handleSelectPlayer}
                  player={{
                    ...player,
                    index: playerIndex,
                    isSelected: playersSelected.some(p => p.id === player.id),
                  }}
                  playersSelected={playersSelected}
                  sections={sections}
                  playerContextMenuOpened={playerContextMenuOpened}
                  setPlayerContextMenuOpened={setPlayerContextMenuOpened}
                  handleResetSection={handleResetSection}
                  countries={countries}
                />
              )
            })}
            {provided.placeholder}
          </div>
        )
      }}
    </Droppable>
  )
}

Reserve.propTypes = {
  players: PropTypes.array,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  handleResetSection: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Reserve
