import { eachYearOfInterval  } from 'date-fns'


export const staticGroupsColor = [
  // FULL DAY
  {
    code: 'full_day',
    backgroundColor: '#4a86e8',
    color: '#000000',
  },
  {
    code: 'full_day_children',
    backgroundColor: '#4a86e8',
    color: '#000000',
  },
  {
    code: 'full_day_language',
    backgroundColor: '#93c47d',
    color: '#000000',
  },
  {
    code: 'full_day_test',
    backgroundColor: '#4a86e8',
    color: '#ffff00',
  },
  {
    code: 'full_day_tournament',
    backgroundColor: '#ffc000',
    color: '#000000',
  },
  // HALF DAY
  {
    code: 'half_day',
    backgroundColor: '#ff0000',
    color: '#ffffff',
  },
  {
    code: 'half_day_afternoon',
    backgroundColor: '#e06666',
    color: '#000000',
  },
  {
    code: 'half_day_children',
    backgroundColor: '#ff0000',
    color: '#ffffff',
  },
  {
    code: 'half_day_language',
    backgroundColor: '#ff0000',
    color: '#00ff00',
  },
  {
    code: 'half_day_padel',
    backgroundColor: '#ff0000',
    color: '#00ff00',
  },
  {
    code: 'half_day_weekend',
    backgroundColor: '#6febed',
    color: '#ff0000',
  },
  // WEEKEND
  {
    code: 'weekend',
    backgroundColor: '#00ffff',
    color: '#000000',
  },
  {
    code: 'weekend_intensif',
    backgroundColor: '#6febed',
    color: '#000000',
  },
  // OTHERS
  {
    code: 'tennis_and_golf',
    backgroundColor: '#ffff00',
    color: '#000000',
  },
  {
    code: 'baby_tennis',
    backgroundColor: '#d9d2e9',
    color: '#000000',
  },
  // {
  //   code: 'multisport',
  //   backgroundColor: '#fce5cd',
  //   color: '#6aa84f',
  // },
  // {
  //   code: 'padel',
  //   backgroundColor: '#e69138',
  //   color: '#000000',
  // },
  {
    code: 'padel_and_tennis',
    backgroundColor: '#b45f06',
    color: '#000000',
  },
  // NIGHT SESSIONS
  {
    code: 'night_session_adults_18',
    backgroundColor: '#000',
    color: '#fff',
  },
  {
    code: 'night_session_adults_20',
    backgroundColor: '#000',
    color: '#fff',
  },
  {
    code: 'night_session_children_18',
    backgroundColor: '#000',
    color: '#fff',
  },
  {
    code: 'night_session_children_20',
    backgroundColor: '#000',
    color: '#fff',
  },
];


export const staticGenders = [
  {
    code: 'male',
    title: 'Homme'
  },
  {
    code: 'female',
    title: 'Femme'
  }
];

export const genderOptions = [
  {
    value: 1,
    label: 'Féminin'
  },
  {
    value: 2,
    label: 'Masculin'
  }
]

export const findGender = (gender) => {
  if(!gender) return  {code: 'no-sex', title: 'Genre indéfini'}
  return staticGenders.find(gdr => gdr.code === gender);
}

const date = new Date();

export const staticBirthdates = eachYearOfInterval({
  start: new Date(date.getFullYear() -90, date.getMonth(), date.getDay()+1),
  end: new Date(),
}, {weekStartsOn: 1}).map(date => date.getFullYear()).reverse()

export const staticRanking = [
  { label: 'VIOLET', value: 'violet' },
  { label: 'ROUGE1', value: 'rouge1' },
  { label: 'ROUGE2', value: 'rouge2' },
  { label: 'ORANGE1', value: 'orange1' },
  { label: 'ORANGE2', value: 'orange2' },
  { label: 'VERT1', value: 'vert1' },
  { label: 'VERT2', value: 'vert2' },
  { label: 'ROUGE', value: 'rouge' },
  { label: 'ORANGE', value: 'orange' },
  { label: 'VERT3', value: 'vert3' },
  { label: '4ème S', value: '4emeS' },
  { label: '3ème S', value: '3emeS' },
  { label: 'VERT', value: 'vert' },
  { label: 'DEB, DEB+', value: 'deb' },
  { label: 'VERT1, DEB', value: 'vert1deb' },
  { label: 'VERT2/ VERT3', value: 'vert2vert3' },
  { label: '40-30/5', value: '40-305' },
  { label: '30/4-30/3', value: '304-303' },
  { label: '30/3-30/2', value: '303-302' },
  { label: '30/2-30/1', value: '302-301' },
  { label: '30/1-30', value: '301-30' },
  { label: '30-15/5', value: '30-155' },
  { label: '15/5-15/4', value: '155-154' },
  { label: '15/4-15/3', value: '154-153' },
  { label: '15/3-15/2', value: '153-152' },
  { label: '15/2-15/1', value: '152-151' },
  { label: '15/1-15', value: '151-15' },
  { label: '15-5/6', value: '15-56' },
  { label: '5/6-4/6-3/6', value: '56-46-36' },
  { label: '4/6-3/6', value: '46-36' },
  { label: '2/6-1/6', value: '26-16' },
  { label: '0 et plus', value: '0+' },
];
