// Hooks
import useApi from "../useApi"

// Utils & misc
import { FID_HOSTNAME } from "../../../constants/constants"
import objectToURLParam from "../../../utils/objectToURLParam"

const useFidPrivileges = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${FID_HOSTNAME}/api/v1`

  const getFidPrivileges = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${BASE_URL}/privileges${params}`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const createFidPrivilege = est =>
    rawRequest("POST", `${BASE_URL}/privileges`, est, {
      authKey: AUTHORIZATION,
    })

  const updateFidPrivilege = (estId, est) =>
    rawRequest("PUT", `${BASE_URL}/privileges/${estId}`, est, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getFidPrivileges,
      updateFidPrivilege,
      createFidPrivilege,
    },
  ]
}

export default useFidPrivileges
