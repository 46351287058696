import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from "react-bootstrap4-modal"
import { isNil } from 'lodash'
import useImage from '../../../../hooks/useImage'
import cn from '../../../../utils/cn'

import styles from './MediasEditModal.module.css'
import useFileUploadApi from '../../../../hooks/Api/useFileUploadApi'

const MediasEditModal = ({ listStyle, selectedMedia, setSelectedMedia, setUploadSuccess, handleRefreshList }) => {
  const { getImageSrc } = useImage()

  const [newFile, setNewFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [preview, setPreview] = useState(null)

  const [fileUploadError, setFileUploadError] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)

  const [fileUploadResponse, fileUploadRequest] = useFileUploadApi()

  useEffect(() => {
    const objectUrl = newFile ? URL.createObjectURL(newFile) : null
    setPreview(objectUrl)
 
    return () => URL.revokeObjectURL(objectUrl)
  }, [newFile])

  const handleCloseModal = () => {
    setNewFile(null)
    setFileName(null)
    setPreview(null)
    setSelectedMedia(null)
    setFileUploadError(false)
    setFileUploading(false)
  
    const mediaEditNewFile = document.querySelector('#mediaEditNewFile')
    if (mediaEditNewFile) mediaEditNewFile.value = ''
  }

  const handleSaveNewFile = () => {
    if (!newFile) return;

    setFileUploading(true)
    setFileUploadError(false)
    setUploadSuccess(false)

    const fileNameSanitized = newFile.name.replace(/[^a-z0-9.]/gi, "_").toLowerCase()
    
    fileUploadRequest(
      fileNameSanitized,
      newFile,
      {
        method: "edit",
        mediaId: selectedMedia.id,
      }
    )
      .then((res) => {
        if (!res) {
          setFileUploadError(true)
          setFileUploading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setFileUploadError(true)
        setFileUploading(false)
      })
  }
  
  useEffect(() => {
    if (fileUploadResponse.status === 'completed') {
      setFileUploading(false)
      setUploadSuccess(true)
      handleCloseModal()
      handleRefreshList()
    }
  }, [fileUploadResponse?.status])

  const handleFileChange = (e) => {
    const file = e.target?.files?.[0]

    setNewFile(file || '')
    setFileName(file?.name || null)
  }

  return (
    <Modal
      visible={!isNil(selectedMedia)}
      dialogClassName={styles.modal} 
      onClickBackdrop={handleCloseModal}
    >
      <div className="modal-header">
        <div className="text-xl modal-header-title">
          <b>
            {!isNil(selectedMedia) ? (
              selectedMedia.fileKey.replace('medias/', '')
            ) : (
              'Éditer le média'
            )}
          </b>
        </div>
      </div>

      <div className="modal-body">
        {fileUploadError ? (
          <div className="alert alert-danger">Une erreur s'est produite. Merci de vérifier votre sélection et de réessayer.</div>
        ) : null}

        <div className={styles.modal_wrapper}>
          <div className={styles.image_container}>
            <div className={cn([listStyle.media_item_img, listStyle.media_details_img])}>
              {!isNil(selectedMedia) ? (
                <img
                  alt={selectedMedia.fileKey}
                  src={getImageSrc({ fileKey: selectedMedia.fileKey, width: 400 })}
                  className={!isNil(preview) ? styles.faded : ''}
                />
              ) : null}
            </div>
          </div>

          <div className={styles.input_container}>
            <label className='mb-3'>
              <strong>Remplacer le fichier</strong>
            </label>

            <label htmlFor="mediaEditNewFile" className={styles.input_file}>
              <input
                id="mediaEditNewFile"
                type="file"
                accept="image/*"
                className="form-control-file"
                onChange={handleFileChange}
                disabled={fileUploading}
              />

              <button type="button" className={cn([styles.input_file_btn, 'btn btn-primary'])}>
                Sélectionner un fichier
              </button>
            </label>

            {fileName ? (
              <p className={styles.file_name}>
                {fileName}
              </p>
            ) : null}

            {preview ? (
              <div className={styles.preview}>
                <img src={preview} />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default btn-md"
          onClick={handleCloseModal}
        >
          Annuler
        </button>
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={handleSaveNewFile}
          disabled={isNil(newFile) || isNil(selectedMedia)}
        >
          {fileUploading ? (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            />
          ) : null}
          Remplacer
        </button>
      </div>
    </Modal>
  )
}

MediasEditModal.propTypes = {
  selectedMedia: PropTypes.shape({
    fileKey: PropTypes.string,
  }),
  listStyle: PropTypes.shape({}),
  setSelectedMedia: PropTypes.func,
  setUploadSuccess: PropTypes.func,
  handleRefreshList: PropTypes.func
}

MediasEditModal.defaultProps = {
  selectedMedia: null,
  listStyle: {},
  setSelectedMedia: () => {},
  setUploadSuccess: () => {},
  handleRefreshList: () => {}
}

export default MediasEditModal