import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { Formik, Form } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useThemesApi from "../../../hooks/Api/useThemesApi"
import usePermissionsState from "../../../hooks/usePermissionsState"
import useTypesApi from "../../../hooks/Api/useTypesApi"
import useMediasApi from "../../../hooks/Api/useMediasApi"
import usePackagesApi from "../../../hooks/Api/usePackagesApi"
import useDurationsApi from "../../../hooks/Api/useDurationsApi"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"
import { getInitialLocaleValues } from "../../../utils/locales"
import Toggle from "../../../components/atoms/Toggle/Toggle"
import { exportCollectionToArrayImages } from "../../../utils/collection"

import styles from "./ThemesEdit.module.css"
import MediasInput from "../../../components/atoms/MediasInput/MediasInput"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_THEMES_UPDATE,
  PERMISSIONS.PERM_THEMES_CREATE,
]

const ThemesEdit = ({ match }) => {
  const {
    context: { locales, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)

  const history = useHistory()
  const [, themeData,, { getThemeById }] = useThemesApi()
  const [updateThemeState,, updateError, { updateTheme }] = useThemesApi()
  const [newThemeState,, newThemeError, { createTheme }] = useThemesApi()
  const [, typesData, , { getTypes }] = useTypesApi()
  const [, mediasData, , { getMedias }] = useMediasApi()
  const [, packagesData, , { getPackages }] = usePackagesApi()
  const [, durationsData, , { getDurations }] = useDurationsApi()

  const mediasOptions = useMemo(() => (exportCollectionToArrayImages(mediasData, "medias", "fileKey") || []), [mediasData])

  const savedGetThemeWithId = useCallback(getThemeById, [])
  const savedGetTypes = useCallback(getTypes, [])
  const savedGetMedias = useCallback(getMedias, [])
  const savedGetPackages = useCallback(getPackages, [])
  const savedGetDurations = useCallback(getDurations, [])

  const backToList = () => {
    history.push({
      pathname: "/themes",
      state: {},
    })
  }

  useEffect(() => {
    if (isEditing) {
      savedGetThemeWithId(match.params.id).then((res) => {
        if (!res?.data) backToList();
      });
    }
    savedGetTypes()
    savedGetMedias()
    savedGetPackages()
    savedGetDurations()
  }, [
    isEditing,
    savedGetThemeWithId,
    savedGetTypes,
    savedGetMedias,
    savedGetPackages,
    savedGetDurations,
    match.params.id,
  ])

  const handleSubmit = values => {
    if (isEditing) {
      updateTheme(values).then((res) => {
        if (res?.status === 200) {
          history.push({
            pathname: "/themes",
            state: { submitType: "edit", success: true },
          })
        }
      })
    } else {
      createTheme(values).then((res) => {
        if (res?.status === 201) {
          history.push({
            pathname: "/themes",
            state: { submitType: "create", success: true },
          })
        }
      })
    }
  }

  const createInitialValues = locales
    ? {
        durationId: 1,
        typeId: 1,
        thumbnailId: 1,
        active: true,
        packageIds: [],
        name: getInitialLocaleValues(locales),
        isActivated: true,
      }
    : {}

  const getPackageNameFromId = id => {
    const found = packagesData.packages.find(p => p.id === id)
    if (found === undefined) return "Package introuvable"
    return found.name[1]
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_THEMES_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_THEMES_CREATE] && !isEditing)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Thèmes / ${isEditing ? "Éditer" : "Nouveau"}`}
          {themeData && themeData.name ? ` / ${themeData.name[1]}` : null}
        </h1>
      </div>

      {typesData && mediasData && packagesData && durationsData ? (
        <Formik
          enableReinitialize
          initialValues={
            isEditing && themeData ? themeData : createInitialValues
          }
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form className="mw-100 w-75 m-auto">
              <div className={cn(["card mb-4"])}>
                <div className="card-body">
                  <div className="form-row">
                    {/* type Id */}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="discount-typeId">Type id</label>
                      </b>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="typeId"
                        name="typeId"
                        required
                        onChange={e => {
                          setFieldValue("typeId", Number(e.target.value))
                          setFieldValue("packageIds", [])
                        }}
                        onBlur={handleBlur}
                        value={values.typeId}
                      >
                        {Object.keys(typesData.types).map(typeId => (
                          <option
                            key={`typeId_select_${typeId}`}
                            value={typeId}
                          >
                            {typesData.types[typeId].name["1"]}
                          </option>
                        ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>

                    {/* THUMBNAIL ID */}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <MediasInput
                        label="Image de miniature"
                        options={mediasOptions}
                        selection={values.thumbnailId ? [values.thumbnailId] : []}
                        setSelection={(value) => setFieldValue('thumbnailId', value[0]?.id || null)}
                        disabled={!isAllowedToEdit}
                        required
                      />
                    </div>

                    {/* PACKAGE IDS */}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="thumbnailId">{`Liste de packages (${
                          typesData.types[values.typeId].name["1"]
                        })`}</label>
                      </b>
                      <Select
                        isDisabled={!isAllowedToEdit}
                        value={values.packageIds.map(p => ({
                          label: getPackageNameFromId(p),
                          value: p,
                        }))}
                        isMulti
                        onChange={options => {
                          setFieldValue(
                            "packageIds",
                            !options ? [] : options.map(option => option.value)
                          )
                        }}
                        options={packagesData.packages
                          .filter(p => p.typeId === values.typeId)
                          .filter(p => !p.archived)
                          .map(p => ({
                            label: p.name[1],
                            value: p.id,
                          }))}
                        placeholder="Sélectionner"
                        noOptionsMessage={() => <span>Pas de résultats</span>}
                        styles={{
                          container: provided => ({
                            ...provided,
                            flex: "1 1 0",
                          }),
                        }}
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>

                    {/* NAME */}
                    {Object.keys(locales).map(localeId => (
                      <div
                        key={`name_${localeId}`}
                        className="col-sm-4 col-md-4 pb-3"
                      >
                        <b>
                          <label htmlFor="extra-typeId">{`Nom ${locales[
                            localeId
                          ].code.toUpperCase()}`}</label>
                        </b>
                        <input
                          disabled={!isAllowedToEdit}
                          required
                          type="text"
                          className="form-control"
                          id={`name.${localeId}`}
                          name={`name.${localeId}`}
                          value={values.name[localeId]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="valid-tooltip">Looks good!</div>
                      </div>
                    ))}
                    {/* duration ID */}
                    <div className="col-sm-12 col-md-12 pb-3">
                      <b>
                        <label htmlFor="durations">Theme</label>
                      </b>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="durations"
                        name="durations"
                        required
                        onChange={e => {
                          setFieldValue("durationId", Number(e.target.value))
                        }}
                        onBlur={handleBlur}
                        value={values.durationId}
                      >
                        {Object.keys(durationsData.durations).map(duration => (
                          <option
                            key={`duration_select_${durationsData.durations[duration].id}`}
                            value={durationsData.durations[duration].id}
                          >
                            {durationsData.durations[duration].name["1"]}
                          </option>
                        ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    {/* isActive */}
                    <div className={`col-sm-12 col-md-12 pb-3 ${styles.isactiveToggle}`}>
                      <Toggle 
                        id="isThemeActive"
                        name="isThemeActive"
                        label="Thème actif"
                        onChange={() => {
                          setFieldValue("isActivated", !values.isActivated)
                        }}
                        onBlur={handleBlur}
                        toggled={values.isActivated}
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  </div>
                </div>
              </div>

              {updateError?.data?.message || newThemeError?.data?.message ? (
                <div className="alert alert-danger" role="alert">
                  {updateError?.data?.message || newThemeError?.data?.message}
                </div>
              ) : null}

              <div className="float-right mb-4">
                {!isAllowedToEdit ? (
                  <button
                    onClick={backToList}
                    type="button"
                    className="btn btn-secondary mr-2"
                  >
                    Revenir à la liste
                  </button>
                ) : null}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    updateThemeState === "loading" ||
                    newThemeState === "loading" ||
                    !isAllowedToEdit
                  }
                >
                  {updateThemeState === "loading" || newThemeState === "loading" ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Valider
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

ThemesEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
}

export default ThemesEdit
