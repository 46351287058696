import React, { useEffect, useState, useCallback } from "react"

// Components
import { SelectInput } from "../../../../atoms/Inputs/Inputs"
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"

// Hooks
import useAppContext from "../../../../../hooks/useAppContext"
import useFidEstablishments from "../../../../../hooks/Api/Fid/useFidEstablishments"
import useFidEmployees from "../../../../../hooks/Api/Fid/useFidEmployees"

import style from "./FidPointsAdmin.module.css"
import cn from "../../../../../utils/cn"

const FidPoints = ({ isAdmin }) => {
  const {
    setFidEmployee,
    context: { fidEmployee },
  } = useAppContext()
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [myEstablishment, setMyEstablishment] = useState(null)
  const [employees, setEmployees] = useState([])
  const [{ getMyFidEstablishment }] = useFidEstablishments()
  const [{ getFidEmployees }] = useFidEmployees()
  const getMyFidEstablishmentMem = useCallback(getMyFidEstablishment, [])
  const getFidEmployeesMem = useCallback(getFidEmployees, [])

  // const handleCRUDRequestError = useCallback(
  //   error => {
  //     if (error.response) {
  //       switch (error.response.status) {
  //         case 500: {
  //           setGlobalMessage({
  //             ...globalMessage,
  //             isActive: true,
  //             message: <span>Une erreur est survenue (500)</span>,
  //             className: "alert alert-danger",
  //           })
  //           break
  //         }
  //         case 400:
  //           setGlobalMessage({
  //             ...globalMessage,
  //             isActive: true,
  //             message: <span>Une erreur est survenue (400)</span>,
  //             className: "alert alert-danger",
  //           })
  //           break
  //         default:
  //           break
  //       }
  //     }
  //   },
  //   [globalMessage]
  // )

  const initialLoad = useCallback(async () => {
    try {
      const myEst = await getMyFidEstablishmentMem()
      setMyEstablishment(myEst.data)
    } catch (error) {
      //   handlCRUDRequestError(error)
    }
  }, [getMyFidEstablishmentMem])

  const fetchEmployees = useCallback(async () => {
    try {
      const employeesResp = await getFidEmployeesMem({
        establishmentId: myEstablishment.id,
        active: 1,
      })
      if (employeesResp && employeesResp.data) {
        setEmployees(
          Object.keys(employeesResp.data.employees).map(
            emp => employeesResp.data.employees[emp]
          )
        )
      }
    } catch (error) {
      //   handlCRUDRequestError(error)
    }
  }, [getFidEmployeesMem, myEstablishment])

  useEffect(() => {
    initialLoad()
  }, [initialLoad])

  useEffect(() => {
    if (myEstablishment) {
      fetchEmployees()
    }
  }, [fetchEmployees, myEstablishment])

  const handleEmployeeChange = e => {
    e.preventDefault()
    setFidEmployee(employees.find(emp => emp.id === Number(e.target.value)))
  }

  return (
    <div className={style.section}>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />
      <h3 className={style.header}>
        Infos {isAdmin ? "Super Admin" : "Employé"} :
      </h3>
      <div className="row">
        <div className="col-sm-6 col-md-6">
          <span className={style.textHeader}>
            Etablissement:{" "}
            <b>{myEstablishment ? myEstablishment.name : "Chargement..."}</b>
          </span>
          {myEstablishment && myEstablishment.cappingPoints ? (
            <div>
              Pour cet établissement le maximum de points pouvant être crédités
              en 1 commande est {myEstablishment.cappingPoints} euros.
            </div>
          ) : null}
        </div>
        <div className="col-sm-6 col-md-6">
          <div className="row">
            <label
              htmlFor="employeeSelect"
              className={cn(["col-sm-4 col-form-label", style.textHeader])}
            >
              Employé(e):
            </label>
            <div className="col-sm-8">
              <SelectInput
                required={false}
                className={cn(["form-control", style.textHeader])}
                onChange={handleEmployeeChange}
                name="employeeSelect"
                id="employeeSelect"
                value={fidEmployee ? fidEmployee.id : null}
                options={[
                  <option key="default" value="" selected disabled>
                    Sélection Employé(e)
                  </option>,
                  employees.map(emp => (
                    <option
                      key={`employee_select_${emp.id}`}
                      value={Number(emp.id)}
                    >
                      {`${emp.firstName} ${emp.lastName}`}
                    </option>
                  )),
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FidPoints
