import React, { useEffect, useMemo, useState } from 'react';

// Utils
import { PERMISSIONS } from '../../../constants/constants';
import { DragDropContext } from 'react-beautiful-dnd';
import { Link, useLocation } from 'react-router-dom';
import cn from "../../../utils/cn";

// Hooks
import usePermissionsState from '../../../hooks/usePermissionsState';
import useAppContext from '../../../hooks/useAppContext';
import useQuestionsApi from '../../../hooks/Api/useQuestionsApi';

// Components
import WithMenu from '../../../components/templates/WithMenu/WithMenu';
import Table from '../../../components/organisms/Table';

import style from "./QuestionsList.module.css";

const permissions = [
  PERMISSIONS.PERM_QUESTIONS_CREATE,
  PERMISSIONS.PERM_QUESTIONS_UPDATE,
  PERMISSIONS.PERM_QUESTIONS_DELETE,
];

const QuestionsList = () => {
  const { state } = useLocation();
  const { context: { userInfo } } = useAppContext();
  const rolePermissions = usePermissionsState(userInfo, permissions);

  const [getQuestionsState, questionsPayload, error, { getQuestions }] = useQuestionsApi({ trigger: true });
  const [reorderQuestionsState,,, { reorderQuestions }] = useQuestionsApi();
  const [deleteQuestionState,,, { deleteQuestion }] = useQuestionsApi();

  const questions = useMemo(() => questionsPayload?.questions || [], [questionsPayload]);

  const [confirmDeleteQuestionId, setConfirmDeleteQuestionId] = useState(null);

  useEffect(() => {
    getQuestions();
  }, []);

  const handleDeleteQuestion = async (id) => {
    await deleteQuestion(id);
    getQuestions();
  };

  const onDragEnd = async (result) => {
    if (!result?.destination || !result?.source || !questions?.length) return;

    const selectedIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let followingNewId = null;
    
    if (selectedIndex < destinationIndex) {
      // moving down
      followingNewId = questions[destinationIndex + 1]?.id || null;
    } else {
      // moving up
      followingNewId = questions[destinationIndex].id
    }

    const movingId = questions[selectedIndex]?.id;
    await reorderQuestions(movingId, followingNewId);
    getQuestions();
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>FAQ - Questions</h1>

        {rolePermissions[PERMISSIONS.PERM_QUESTIONS_CREATE] ? (
          <Link className="btn btn-success" to="/questions/new">
            Nouvelle question
          </Link>
        ) : null}
      </div>

      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}

      {getQuestionsState === "loading" || reorderQuestionsState === 'loading' ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        questions?.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Question",
                  accessor: "title",
                  Cell: ({
                    cell: {
                      row: {
                        original: { title },
                      },
                    },
                  }) => title?.[1],
                },
                {
                  Header: "",
                  accessor: "actions",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      }
                    }
                  }) => (
                    <div
                      role="group"
                      aria-label="theme-actions"
                      className="d-flex align-items-center justify-content-end"
                    >
                      {/* EDIT OR VIEW */}
                      {rolePermissions[PERMISSIONS.PERM_QUESTIONS_UPDATE] || rolePermissions[PERMISSIONS.PERM_QUESTIONS_CREATE] ? (
                        <Link
                          className="btn btn-sm btn-secondary mr-4"
                          to={`/questions/edit/${id}`}
                        >
                          {rolePermissions[PERMISSIONS.PERM_QUESTIONS_UPDATE]
                            ? "Éditer"
                            : "Consulter"}
                        </Link>
                      ) : null}

                      {/* DELETE */}
                      {rolePermissions[PERMISSIONS.PERM_QUESTIONS_DELETE] ? (
                        <>
                          {confirmDeleteQuestionId === id ? (
                            <div className="btn-group">
                              <button
                                type="button"
                                onKeyDown={null}
                                tabIndex={0}
                                onClick={() => handleDeleteQuestion(id)}
                                className={cn([
                                  'btn btn-sm btn-danger',
                                  deleteQuestionState === 'loading' ? 'disabled' : ''
                                ])}
                                disabled={deleteQuestionState === 'loading'}
                              >
                                {deleteQuestionState === 'loading' ? (
                                  <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                Confirmer
                              </button>
                              <button
                                type="button"
                                onKeyDown={null}
                                tabIndex={0}
                                onClick={() => setConfirmDeleteQuestionId(null)}
                                className="btn btn-sm btn-secondary"
                                disabled={deleteQuestionState === 'loading'}
                              >
                                Annuler
                              </button>
                            </div>
                          ) : (
                            <div
                              onKeyDown={null}
                              tabIndex={0}
                              role="button"
                              onClick={() => setConfirmDeleteQuestionId(id)}
                              className="btn btn-sm btn-danger"
                            >
                              Supprimer
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  ),
                }
              ]}
              data={questions || []}
              isDraggable={rolePermissions[PERMISSIONS.PERM_QUESTIONS_UPDATE]}
            />
          </DragDropContext>
        ) : (
          <p>Vous n'avez pas encore ajouté de question.</p>
        )
      )}
    </WithMenu>
  )
}

QuestionsList.propTypes = {}

export default QuestionsList