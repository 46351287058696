import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useUtilsApi from "../../../hooks/Api/useUtilsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./LocalesList.module.css"

const permissions = [
  PERMISSIONS.PERM_LOCALES_DISPLAY,
  PERMISSIONS.PERM_LOCALES_UPDATE,
]

const LocalesList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [requestState, payload, error, { getLocales }] = useUtilsApi()
  const savedGetLocales = useCallback(getLocales, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetLocales()
  }, [savedGetLocales])

  const localesCollectionToArray = () =>
    payload && payload.locales
      ? Object.keys(payload.locales).map(i => ({
          ...payload.locales[i],
          localeId: i,
        }))
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Locales</h1>
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {error ? (
        <div className="alert alert-error" role="alert">
          {error.message}
        </div>
      ) : null}
      {requestState === "loading" && !payload ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_LOCALES_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_LOCALES_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "Code",
                  accessor: "code",
                },
                {
                  Header: "Edit",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        original: { localeId },
                      },
                    },
                  }) => {
                    return (
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Edit tag"
                      >
                        <Link
                          className="btn btn-secondary"
                          to={`/locales/edit/${localeId}`}
                        >
                          Éditer
                        </Link>
                      </div>
                    )
                  },
                },
              ]}
              data={localesCollectionToArray()}
              hasPagination
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default LocalesList
