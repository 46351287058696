// Hooks
import useApi from "../useApi"

// Utils & misc
import { FID_HOSTNAME } from "../../../constants/constants"
import objectToURLParam from "../../../utils/objectToURLParam"

const useFidClients = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${FID_HOSTNAME}/api/v1`

  const searchClient = search => {
    return rawRequest(
      "POST",
      `${BASE_URL}/clients/search`,
      {
        search,
      },
      {
        authKey: AUTHORIZATION,
      }
    )
  }

  const infoOfClient = (keycloakId, employeeId) => {
    return rawRequest(
      "GET",
      `${BASE_URL}/clients/${keycloakId}/${employeeId}`,
      null,
      {
        authKey: AUTHORIZATION,
      }
    )
  }

  const createClient = client =>
    rawRequest("POST", `${BASE_URL}/clients`, client, {
      authKey: AUTHORIZATION,
    })

  const addClientPoints = body => {
    return rawRequest("POST", `${BASE_URL}/points-additions`, body, {
      authKey: AUTHORIZATION,
    })
  }

  const consumeClientPrivileges = body =>
    rawRequest("POST", `${BASE_URL}/clients/privileges`, body, {
      authKey: AUTHORIZATION,
    })

  const logClientsHistory = body => {
    return rawRequest("POST", `${BASE_URL}/logs/clients/history`, body, {
      authKey: AUTHORIZATION,
      axiosOptions: {
        responseType: "blob",
      },
    })
  }

  const getClientHistory = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${BASE_URL}/clients/history${params}`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const cancelPrivilegeUse = (privilegeId, body) => {
    return rawRequest(
      "POST",
      `${BASE_URL}/clients/privileges/${privilegeId}/cancel`,
      body,
      {
        authKey: AUTHORIZATION,
      }
    )
  }

  const editClientPoints = (body, clientId) =>
    rawRequest("PUT", `${BASE_URL}/clients/${clientId}/points`, body, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      searchClient,
      infoOfClient,
      createClient,
      addClientPoints,
      consumeClientPrivileges,
      logClientsHistory,
      getClientHistory,
      cancelPrivilegeUse,
      editClientPoints,
    },
  ]
}

export default useFidClients
