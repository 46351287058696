// Hooks
import useApi from "./useApi"

// Utils & misc
import { COACH_HOSTNAME } from "../../constants/constants"

const useAssignmentsForCoachApi = () => {
  const [requestState, payload, error, request] = useApi()
  
  const postAsignmentSectionsAssignments= async (weekInput) => {
    const weekInDate = typeof weekInput === 'object' ? weekInput : new Date(weekInput);
    const options = {year: "numeric", month: "numeric", day: "numeric"};
    const week = new Intl.DateTimeFormat('fr-FR', options).format(weekInDate);
    await request("POST", `${COACH_HOSTNAME}/v1/weeks/${week.split('/').reverse().join('-')}:run-section-assignment`)
    return requestState
  }
  const postAsignmentGroupsAssignments= async (weekInput) =>{
    const weekInDate = typeof weekInput === 'object' ? weekInput : new Date(weekInput)
    const options = {year: "numeric", month: "numeric", day: "numeric"};
    const week = new Intl.DateTimeFormat('fr-FR', options).format(weekInDate);
    await request("POST", `${COACH_HOSTNAME}/v1/weeks/${week.split('/').reverse().join('-')}:run-rotation-and-group-assignment`)
    return requestState
    }

  const postResetAssignments = (weekCode) => request(
    'POST',
    `${COACH_HOSTNAME}/v1/weeks/${weekCode}:reset-assignments`,
  );
  
  return [
    requestState,
    payload,
    error,
    {
      postAsignmentSectionsAssignments,
      postAsignmentGroupsAssignments,
      postResetAssignments
    },
  ]
}

export default useAssignmentsForCoachApi
