// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useFamiliesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getFamilies = () => request("GET", `${API_STAGES_URL}/v1/families`)

  return [
    requestState,
    payload,
    error,
    {
      getFamilies,
    },
  ]
}

export default useFamiliesApi
