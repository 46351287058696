export const getFormStateName = stateId => {
  switch (stateId) {
    case 1:
      return "Edition admin"
    case 2:
      return "Edition client"
    case 3:
      return "Soumis"
    case 4:
      return "Validé"
    case 5:
      return "Signé"
    default:
      return ""
  }
}

export const formStateOptions = [
  { value: 1, label: 'Edition admin' },
  { value: 2, label: 'Edition client' },
  { value: 3, label: 'Soumis' },
  { value: 4, label: 'Validé' },
  { value: 5, label: 'Signé' },
];