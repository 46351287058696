import React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"

import style from "./Toggle.module.css"
import cn from "../../../utils/cn"

const Toggle = ({
  name,
  onChange,
  onBlur,
  id,
  className,
  toggled,
  disabled,
  label,
}) => (
  <>
    <label
      htmlFor={id}
      className={cn([style.switch, className, disabled && style.disabled])}
    >
      <input
        disabled={disabled}
        id={id}
        name={name}
        onChange={(e) => onChange(e)}
        onBlur={onBlur}
        checked={toggled}
        type="checkbox"
      />
      <span className={style.sliderRound} />
    </label>
    {label ? <span className={style.label}>{label}</span> : null}
  </>
)

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

Toggle.defaultProps = {
  onBlur: noop,
  className: null,
  disabled: false,
  label: "",
}

export default Toggle
