import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"

// Hooks
import useAppContext from "../../../hooks/useAppContext"

// Constants
import { formatGroupLabel, initialSearchResults } from "./searchInputConstants"

const SearchInput = ({ onElementClick }) => {
  const [searchResults, setSearchResults] = useState(initialSearchResults)

  const {
    context: { searchedClients },
  } = useAppContext()

  const handleSearchChange = value => {
    const valueToSearch = value.toLowerCase()

    if (searchedClients && value && value.length > 0) {
      const foundClients = searchedClients
        .filter(client => !!(client.fullname && client.email && client.id))
        .filter(client => {
          return (
            client.fullname.includes(valueToSearch) ||
            client.email.includes(valueToSearch) ||
            client.id.includes(valueToSearch) ||
            (client.phone && client.phone !== null && client.phone !== undefined
              ? client.phone.includes(valueToSearch)
              : false)
          )
        })
        .map(client => ({
          ...client,
          entity: "client",
          label: `${client.firstname} ${client.lastname}`,
          value: client.id,
        }))
      const foundPlayers = searchedClients.reduce((acc, val) => {
        const current = acc.slice(0)

        let playersFound = []
        if (val.players) {
          playersFound = Object.keys(val.players)
            .map((p, i) => {
              const player = val.players[p]
              return {
                entity: "player",
                name: `${player.firstname} ${player.lastname}`,
                id: player.id,
                client: val,
                value: player.id,
                label: `${player.firstname} ${player.lastname}`,
              }
            })
            .filter(player => {
              return (
                player.name.toLowerCase().includes(valueToSearch) ||
                player.id.includes(valueToSearch)
              )
            })
        }

        if (playersFound && playersFound.length > 0)
          return [...current, ...playersFound]
        return current
      }, [])

      if (foundClients.length !== 0 || foundPlayers.length !== 0) {
        setSearchResults([
          {
            label: "Clients",
            options: foundClients.slice(0, 20),
          },
          {
            label: "Joueurs",
            options: foundPlayers.slice(0, 20),
          },
        ])
      } else {
        setSearchResults(initialSearchResults)
      }
    }
  }

  const handleSelectChange = value => {
    setSearchResults(initialSearchResults)
    onElementClick(value)
  }

  return (
    <Select
      onInputChange={handleSearchChange}
      onChange={handleSelectChange}
      options={searchResults}
      placeholder="Clients / Joueurs"
      noOptionsMessage={value => <span>Pas de résultats</span>}
      filterOption={false}
      styles={{
        control: provided => ({
          ...provided,
          minWidth: 240,
          borderRadius: "1.3rem",
        }),
        menu: provided => ({ ...provided, color: "#000" }),
        option: provided => ({ ...provided, fontSize: ".9rem" }),
        groupHeading: provided => ({
          ...provided,
          fontSize: "105%",
          color: "#222d32",
          fontWeight: "750",
          textTransform: "none",
        }),
      }}
      formatGroupLabel={formatGroupLabel}
    />
  )
}

SearchInput.propTypes = {
  onElementClick: PropTypes.func,
}
SearchInput.defaultProps = {
  onElementClick: () => {},
}

export default SearchInput
