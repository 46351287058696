// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const usePaymentsApi = () => {
  const [, , , , rawRequest] = useApi()

  const createPayment = payment =>
    rawRequest("POST", `${API_STAGES_URL}/v1/payments`, payment)

  return [
    {
      createPayment,
    },
  ]
}

export default usePaymentsApi
