import React, { useContext } from "react"
import PropTypes from "prop-types"

import ClientSearch from "../../organisms/ClientSearch/ClientSearch"

import { ReactComponent as MRTGLogo } from "../../../assets/icons/mouratoglou_logo_white.svg"
import { KeycloakAuthContext } from "../../templates/KeycloakAuthProvider"

import style from "./HeaderBanner.module.css"
import cn from "../../../utils/cn"

const HeaderBanner = ({ sticky, dynamic }) => {
  const { keycloak } = useContext(KeycloakAuthContext)

  return (
    <div className={cn([style.header, sticky ? style.fixed : style.relative])}>
      <span className={style.headerLeft} />
      <span className={style.headerCenter}>
        <MRTGLogo />
      </span>
      <span className={style.headerRight}>
        {keycloak.authenticated && dynamic ? <ClientSearch /> : null}
      </span>
    </div>
  )
}

HeaderBanner.propTypes = {
  sticky: PropTypes.bool,
  dynamic: PropTypes.bool,
}
HeaderBanner.defaultProps = {
  sticky: true,
  dynamic: true,
}

export default HeaderBanner
