import {
  format,
  addYears,
  addDays,
  isBefore,
  isAfter,
  startOfYear,
  endOfYear,
  getYear,
} from "date-fns"

import { RANGE_TAB_YEARS } from "../constants/constants"
import { isEmpty, orderBy } from "lodash"

/**
 * return weeks between two years
 * @param {*} weeksFormatted
 */
export const buildWeeksIdsByYears = weeksFormatted => {
  const currentYear = new Date()
  return weeksFormatted.reduce((acc, week) => {
    const date = new Date(week.start)
    if (
      isAfter(date, startOfYear(currentYear)) &&
      isBefore(date, endOfYear(addYears(currentYear, RANGE_TAB_YEARS)))
    ) {
      acc.push({
        ...week,
      })
    }
    return acc
  }, [])
}

/**
 * Build array of id array by year of weeks
 * @param {*} weeksOrdered
 * @param {*} yearsArray
 */
export const buildOrderedWeeks = (weeksOrdered, yearsArray) =>
  weeksOrdered.reduce((acc, week) => {
    const activeYear = getYear(week.startDate)
    const { id: activeYearId } = yearsArray.find(
      year => year.number === activeYear
    )
    if (!acc[activeYearId]) acc[activeYearId] = []
    acc[activeYearId].push(week.id)
    return acc
  }, {})

/**
 *  Build array of id array by family id
 * @param {*} quotasArray
 * @param {*} familyId
 */
export const buildOrderedQuotas = (quotasArray, familyId, permsByTypology) => {
  // apply product order (camps family (1) only)
  const orderedQuotas = Number(familyId) === 1 ? (
    orderBy(quotasArray, [
      (quota) => (quota.macro ? 0 : 1), // keep macro first
      (quota) => (quota.macro || !quota.productOrder ? quota.id : -quota.productOrder)
    ])
  ) : quotasArray;

  // filter by permissions
  const filteredQuotas = orderedQuotas.reduce((acc, quota) => {
    const hasTypologyPermission = quota.familyId === 3 && !isEmpty(permsByTypology) && quota.typologyIds?.length ? (
      quota.typologyIds.some((id) => !!permsByTypology[id])
    ) : true;
  
    if (
      quota.familyId === Number(familyId)
      && hasTypologyPermission
    ) {
      acc.push(quota.id)
    }
    return acc
  }, []);

  return filteredQuotas;
}

/**
 * find the weeks between two dates
 * @param {*} dates
 * @param {Object} seasons
 */
export const buildCollectionWeeks = (dates, seasons) => {
  return dates.reduce((acc, week) => {
    const currentDate = new Date()
    const date = new Date(week.start)
    if (
      isAfter(date, startOfYear(currentDate)) &&
      isBefore(date, endOfYear(addYears(currentDate, RANGE_TAB_YEARS)))
    )
      acc.push({
        ...week,
        startDate: date,
        start: format(new Date(week.start), "dd/MM"),
        end: format(addDays(new Date(week.start), 6), "dd/MM"),
        season: seasons[week.seasonId].name,
      })

    return acc
  }, [])
}

/**
 * find years between two dates
 * @param {*} dates
 */
export const buildActiveYears = dates => {
  return dates.reduce((acc, year) => {
    const currentDate = new Date()
    const date = new Date(year.number, 0, 1, 1)
    if (
      isAfter(date, startOfYear(currentDate)) &&
      isBefore(date, endOfYear(addYears(currentDate, RANGE_TAB_YEARS)))
    ) {
      if (!acc[year.id]) acc[year.id] = {}
      return {
        ...acc,
        [year.id]: { ...year },
      }
    }

    return acc
  }, {})
}

/**
 *
 * @param {*} weeksArray
 */
export const buildActiveWeeks = weeksArray => {
  const build = weeksArray.reduce((acc, week) => {
    if (acc[week.id]) acc[week.id] = []
    return {
      ...acc,
      [week.id]: { ...week },
    }
  }, {})
  return build
}

export const buildStocks = stocksArray => {
  const build = stocksArray.reduce((acc, stock) => {
    if (!acc[stock.quotaId]) acc[stock.quotaId] = {}

    return {
      ...acc,
      [stock.quotaId]: {
        ...acc[stock.quotaId],
        [stock.weekId]: {
          ...acc[stock.quotaId][stock.weekId],
          ...stock,
          yield: stock.price + (stock.offset || 0),
        },
      },
    }
  }, {})
  return build
}

export const buildQuotasGraph = (quotasArray, familyId) => {
  const build = quotasArray.reduce((acc, quota) => {
    if (quota.familyId === Number(familyId)) {
      if (!acc[quota.id]) acc[quota.id] = []
      if (quota.graph.length) {
        acc[quota.id].push(...quota.graph)
      }
    }
    return acc
  }, {})
  return build
}

export const buildOffers = offersArray => {
  const build = offersArray.reduce((acc, offer) => {
    if (!acc[offer.packageId]) acc[offer.packageId] = {}

    return {
      ...acc,
      [offer.packageId]: {
        ...acc[offer.packageId],
        [offer.weekId]: parseFloat(offer.price),
      },
    }
  }, {})
  return build
}
