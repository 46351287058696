import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// utils
import cn from "../../../utils/cn"
import { isNil } from "lodash"

// Style
import styles from "./Inputs.module.css"

export const TextInput = ({
  label,
  name,
  id,
  onChange,
  onBlur,
  value,
  required = false,
  type = "text",
  placeholder = "",
  disabled = false,
  key,
  className,
}) => (
  <>
    {label ? (
      <b>
        <label htmlFor={label}>{label}</label>
      </b>
    ) : null}
    <input
      type={type}
      required={required}
      id={id}
      name={name}
      className={cn(['form-control', className || ''])}
      onChange={onChange}
      onBlur={onBlur}
      value={!isNil(value) ? value : ''}
      placeholder={placeholder}
      disabled={disabled}
      key={key}
    />
    <div className="valid-tooltip">Looks good!</div>
  </>
)

export const SelectInput = ({
  label,
  name,
  id,
  onChange,
  onBlur,
  value,
  required = true,
  options,
  className = "",
  disabled = false,
}) => (
  <>
    {label ? (
      <b>
        <label htmlFor={label}>{label}</label>
      </b>
    ) : null}
    <select
      className={cn(["custom-select", className])}
      id={id}
      name={name}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      value={value || ""}
      disabled={disabled}
    >
      {options}
    </select>
    <div className="valid-tooltip">Looks good!</div>
  </>
)

export const DateInput = ({ label, onChange, value }) => (
  <>
    <b>
      <label htmlFor={label}>{label}</label>
    </b>
    <div className="input-group">
      <DatePicker
        className="form-control"
        dateFormat="dd/MM/yyyy"
        selected={value}
        onChange={onChange}
      />
      <div className="input-group-append">
        <span role="img" aria-label="calendar" className="input-group-text">
          📅
        </span>
      </div>
    </div>
    <div className="valid-tooltip">Looks good!</div>
  </>
)

export const Switch = ({ onClick, onChange, label, value, id, disabled }) => {
  return (
    <div
      role="button"
      onKeyDown={null}
      tabIndex={[0]}
      onClick={() => onClick(!value)}
      className={cn(["custom-control custom-switch", styles.switchBtn])}
    >
      <input
        type="checkbox"
        checked={value}
        className="custom-control-input"
        readOnly
        disabled={disabled}
      />
      <label className="custom-control-label">{label}</label>
    </div>
  )
}

Switch.propTypes = {
  disabled: PropTypes.bool,
}
Switch.defaultProps = {
  disabled: false,
}
