import React, { useState, useEffect, useCallback } from "react"

// Components
import FidAdminEstablishments from "./FidAdminEstablishments/FidAdminEstablishments"
import FidAdminEmployees from "./FidAdminEmployees/FidAdminEmployees"
import FidAdminLevels from "./FidAdminLevels/FidAdminLevels"
import FidAdminPrivileges from "./FidAdminPrivileges/FidAdminPrivileges"
import FidAdminLogs from "./FidAdminLogs/FidAdminLogs"

// Utils
import cn from "../../../../utils/cn"

import style from "./FidAdminMenu.module.css"

const menuItems = [
  {
    name: "Gestion Établissements",
    key: "ESTABLISHMENTS",
    component: <FidAdminEstablishments />,
  },
  {
    name: "Gestion Employés",
    key: "EMPLOYEES",
    component: <FidAdminEmployees />,
  },
  {
    name: "Gestion Paliers",
    key: "THRESHOLDS",
    component: <FidAdminLevels />,
  },
  {
    name: "Gestion Privilèges",
    key: "PRIVILEGES",
    component: <FidAdminPrivileges />,
  },
  {
    name: "Logs",
    key: "LOGS",
    component: <FidAdminLogs />,
  },
]

const MenuItem = ({ menuItem, active, handleMenuClick, index }) => (
  <li className={cn(["nav-item", style.menuItem])}>
    <a
      onClick={() => handleMenuClick(index)}
      className={cn(["nav-link", active ? style.active : "", style.link])}
      href="#"
    >
      {menuItem.name}
    </a>
  </li>
)

const FidAdminMenu = ({ initialAdminMenu, handleAdminMenuChange }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const savedInitialAdminMenu = useCallback(initialAdminMenu, [])

  useEffect(() => {
    savedInitialAdminMenu(menuItems[activeIndex].component)
  }, [activeIndex, savedInitialAdminMenu])

  const handleIndexChange = index => {
    setActiveIndex(index)
    handleAdminMenuChange(menuItems[index].component)
  }

  return (
    <div>
      <ul className={cn(["nav", style.menu])}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            handleMenuClick={handleIndexChange}
            index={index}
            active={index === activeIndex}
            menuItem={menuItem}
            key={`${menuItem.key}`}
          />
        ))}
      </ul>
    </div>
  )
}

FidAdminMenu.propTypes = {}

FidAdminMenu.defaultProps = {}

export default FidAdminMenu
