/* eslint-disable react/jsx-filename-extension */
import React from "react"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: "85%",
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

export const initialSearchResults = [
  {
    label: "Clients",
    options: [],
  },
  {
    label: "Joueurs",
    options: [],
  },
]

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)
