import React, { useState } from "react"

// Components
import ProductsEdit from "./ProductsEdit"
import ProductsList from "./ProductsList"
import OptionEdit from "./OptionEdit"

const Products = ({ api }) => {
  const [component, setComponent] = useState("list")
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleProductSelect = product => {
    setSelectedProduct(product)
    setComponent("edit")
  }

  const handleOptionSelect = option => {
    setSelectedOption(option)
    setComponent("option")
  }

  const handleBackToList = () => setComponent("list")

  switch (component) {
    case "list":
      return (
        <ProductsList
          api={api}
          onSelectProduct={handleProductSelect}
          onSelectOption={handleOptionSelect}
        />
      )
    case "edit":
      return (
        <ProductsEdit
          api={api}
          selectedProduct={selectedProduct}
          onBackToList={handleBackToList}
        />
      )
    case "option":
      return (
        <OptionEdit
          api={api}
          selectedOption={selectedOption}
          onBackToList={handleBackToList}
        />
      )
    default:
      return <ProductsList api={api} onSelectProduct={handleProductSelect} />
  }
}

Products.propTypes = {}

export default Products
