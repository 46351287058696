import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"
import { format, parseISO } from "date-fns"

// Components
import RecapSection from "./RecapSection"
import GlobalMessage from "../../../../../atoms/GlobalMessage/GlobalMessage"
import Spinner from "../../../../../atoms/Spinner/Spinner"

// Hooks
import useAppContext from "../../../../../../hooks/useAppContext"
import useFidClientsApi from "../../../../../../hooks/Api/Fid/useFidClientsApi"
import useFidTargets from "../../../../../../hooks/Api/Fid/useFidTargets"
import useFidEstablishments from "../../../../../../hooks/Api/Fid/useFidEstablishments"

// Utils
import cn from "../../../../../../utils/cn"
import style from "./FidPointsManager.module.css"

const FidPointsManager = () => {
  const {
    setBatchState,
    context: { fidActiveMember, fidEmployee },
  } = useAppContext()
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [clientInfo, setClientInfo] = useState(null)
  const [targets, setTargets] = useState(null)
  const [establishments, setEstablishments] = useState(null)

  const [
    { addClientPoints, consumeClientPrivileges, infoOfClient },
  ] = useFidClientsApi()
  const [{ getFidTargets }] = useFidTargets()
  const [{ getFidEstablishments }] = useFidEstablishments()

  const infosOfClientMem = useCallback(infoOfClient, [])
  const getFidTargetsMem = useCallback(getFidTargets, [])
  const getFidEstablishmentsMem = useCallback(getFidEstablishments, [])

  const handleCRUDRequestError = useCallback(
    error => {
      if (error.response) {
        switch (error.response.status) {
          case 400: {
            const errorsList =
              error.response.data && error.response.data.errors
                ? Object.keys(error.response.data.errors).map(errorName => ({
                    field: errorName,
                    message: error.response.data.errors[errorName],
                  }))
                : null

            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <ul className="m-0">
                  {errorsList
                    ? errorsList.map(err => (
                        <li>
                          <b>{err.field}: </b>
                          {err.message || "Erreur au niveau de ce champ"}
                        </li>
                      ))
                    : "Erreur au niveau du formulaire"}
                </ul>
              ),
              className: "alert alert-danger",
            })
            break
          }
          case 500: {
            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: <span>Une erreur est survenue au chargement</span>,
              className: "alert alert-danger",
            })
            break
          }
          default:
            break
        }
      }
    },
    [globalMessage]
  )
  const fetchUserInfo = useCallback(async () => {
    const getListFromResponse = r => Object.keys(r).map(id => r[id])
    try {
      const clientInfoResp = await infosOfClientMem(
        fidActiveMember.keycloakId,
        fidEmployee.id
      )
      const targetsResp = await getFidTargetsMem()
      const estResp = await getFidEstablishmentsMem({
        withoutAdmin: 1,
      })

      setClientInfo(clientInfoResp.data)
      setTargets(getListFromResponse(targetsResp.data.targets))
      setEstablishments(getListFromResponse(estResp.data.establishments))
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }, [
    fidActiveMember.keycloakId,
    fidEmployee.id,
    getFidEstablishmentsMem,
    getFidTargetsMem,
    handleCRUDRequestError,
    infosOfClientMem,
  ])

  useEffect(() => {
    fetchUserInfo()
  }, [fetchUserInfo])

  // ADD POINTS FORM
  const {
    values: addPointValues,
    handleSubmit: handleAddPointSubmit,
    setFieldValue: setAddPointFieldValue,
  } = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: async submitValues => {
      try {
        const body = {
          ...submitValues,
          clientId: fidActiveMember.keycloakId,
        }
        await addClientPoints(body, fidActiveMember.keycloakId)
        setBatchState({
          fidGlobalMessage: {
            ...globalMessage,
            isActive: true,
            message: (
              <span>
                <strong>Succès</strong> Points ajoutés.
              </span>
            ),
            className: "alert alert-success",
          },
          fidActiveMember: null,
        })
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  // USE PRIVILEGES FORM
  const {
    values: privValues,
    handleSubmit: handlePrivSubmit,
    setFieldValue: setPrivValues,
  } = useFormik({
    initialValues: {
      privilegesId: [],
    },
    onSubmit: async (sValues, { resetForm }) => {
      try {
        //
        const body = {
          employeeId: fidEmployee.id,
          clientId: clientInfo.id,
          ...sValues,
        }
        await consumeClientPrivileges(body)
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> Privilèges consommés.
            </span>
          ),
          className: "alert alert-success",
        })
        const clientInfoResp = await infosOfClientMem(
          fidActiveMember.keycloakId,
          fidEmployee.id
        )
        setClientInfo(clientInfoResp.data)
        resetForm()
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  const makePrivilegeCategories = () => {
    return clientInfo.privileges.reduce((acc, val, index) => {
      const foundCategoryIndex = acc.findIndex(
        category => category.id === val.establishmentId
      )
      if (foundCategoryIndex !== -1) {
        acc[foundCategoryIndex] = {
          ...acc[foundCategoryIndex],
          privileges: [...acc[foundCategoryIndex].privileges, val],
        }
        return acc
      }
      return [
        ...acc,
        {
          id: val.establishmentId,
          name: establishments.find(est => est.id === val.establishmentId).name,
          privileges: [val],
        },
      ]
    }, [])
  }

  const displayPrivilegeInfo = privilege => {
    const targ = privilege.targets.reduce((acc, val, index) => {
      return `${acc} ${targets.find(targ => targ.id === val).name}${
        index >= privilege.targets.length - 1 ? "" : ","
      }`
    }, "")

    if (privilege.recurrence !== null) {
      return `Récurrent | ${targ}`
    }
    return `One Shot | ${targ}`
  }

  const handleAddPrivilegeToConsume = privilegeId => {
    const privilegeToConsume = privValues.privilegesId.slice(0)
    const isPrivFound = privilegeToConsume.findIndex(
      privId => privilegeId === privId
    )
    if (isPrivFound !== -1) {
      privilegeToConsume.splice(isPrivFound, 1)
      setPrivValues("privilegesId", privilegeToConsume)
    } else {
      setPrivValues("privilegesId", [...privilegeToConsume, privilegeId])
    }
  }

  return (
    <>
      {clientInfo && targets && establishments ? (
        <div>
          {/* MEMBER RECAP */}
          <div>
            <div className="row">
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection
                  label="Adresse email :"
                  value={fidActiveMember.email}
                />
              </div>
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection label="Nom :" value={fidActiveMember.lastName} />
              </div>
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection
                  label="Prénom :"
                  value={fidActiveMember.firstName}
                />
              </div>
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection label="Status :" value={clientInfo.level} />
              </div>
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection
                  label="Solde de points :"
                  subLabel={`Jusqu'au ${format(
                    parseISO(clientInfo.resetPointsDate),
                    "dd/MM/yyyy"
                  )}`}
                  value={clientInfo.currentPoints}
                />
              </div>
              <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
                <RecapSection
                  label="Points manquants pour niveau suivant :"
                  value={clientInfo.pointsRequiredBeforeNextLevel}
                />
              </div>
            </div>
          </div>

          <GlobalMessage
            isActive={globalMessage.isActive}
            content={globalMessage.message}
            className={globalMessage.className}
            onClose={() =>
              setGlobalMessage({ ...globalMessage, isActive: false })
            }
          />

          {/* ADD POINTS */}
          <div className={cn(["row mb-4", style.addPointsSection])}>
            <div className="col-sm-12 col-md-12">
              <h3 className="mb-4">Ajout de points :</h3>
            </div>
            <div className="col-sm-12 col-md-12 text-center">
              <form
                onSubmit={handleAddPointSubmit}
                className={style.addPointsForm}
              >
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <input
                      type="text"
                      pattern="[0-9]*"
                      inputMode="decimal"
                      className="form-control mb-2"
                      id="addPoints"
                      placeholder="Points à ajouter"
                      onChange={e => {
                        e.preventDefault()
                        const regex = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
                        if (e.target.value === "")
                          setAddPointFieldValue("amount", "")
                        if (regex.test(e.target.value))
                          setAddPointFieldValue(
                            "amount",
                            Number(e.target.value)
                          )
                      }}
                      value={addPointValues.amount}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      disabled={
                        addPointValues.amount < 0 || !addPointValues.amount
                      }
                      type="submit"
                      className="btn btn-secondary btn-sm mb-2"
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* PRIVILEGES SECTION */}
          <div className={cn(["row pt-4", style.privilegesSection])}>
            <div className="col-sm-12 col-md-4">
              <h3 className="mb-4 text-center">Privilèges permanents :</h3>
              <h4
                className={cn([
                  "font-weight-bold mb-4 text-center",
                  style.level,
                ])}
              >
                {clientInfo.level}
              </h4>
              {clientInfo.privileges
                .filter(p => p.isPermanent)
                .map(privilege => (
                  <div
                    key={`permanentPrivilege_${privilege.id}`}
                    className={cn(["", style.privilegePermanent])}
                  >
                    <h5 className={style.privilegePermanentName}>
                      {privilege.name}
                    </h5>
                    <span className={style.privilegeSubHeader}>
                      {privilege.targets.reduce((acc, val, index) => {
                        return `${acc} ${
                          targets.find(targ => targ.id === val).name
                        }${index >= privilege.targets.length - 1 ? "" : ","}`
                      }, "")}
                    </span>
                  </div>
                ))}
            </div>
            <div
              className={cn(["col-sm-12 col-md-8", style.consumablePrivileges])}
            >
              <form onSubmit={handlePrivSubmit}>
                <h3 className="mb-4 text-center">Privilèges à consommer :</h3>
                {makePrivilegeCategories().map(est => (
                  <div
                    className={style.consumablePrivilegeSection}
                    key={`category_est_${est.id}`}
                  >
                    <div
                      className={cn([
                        "row text-center",
                        style.privilegeCategoryTitle,
                      ])}
                    >
                      <div className="col-sm-12 col-md-12">
                        <h4 className={style.privilegeConsumableName}>
                          {est.name}
                        </h4>
                      </div>
                    </div>
                    <div className="row ml-4">
                      {est.privileges
                        .filter(priv => !priv.isPermanent)
                        .map(privilege => (
                          <div
                            className={cn([
                              "col-sm-6 col-md-4",
                              style.privilegeConsumableName,
                              privilege.resetDate ? style.disabled : "",
                            ])}
                          >
                            <div
                              className={cn([
                                "form-group form-check",
                                style.privilegeSelect,
                              ])}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`privilege_${privilege.id}`}
                                onChange={() =>
                                  handleAddPrivilegeToConsume(privilege.id)
                                }
                                checked={
                                  privValues.privilegesId.find(
                                    privId => privId === privilege.id
                                  ) !== undefined
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`privilege_${privilege.id}`}
                              >
                                <span className={style.privilegeName}>
                                  {privilege.name}
                                </span>
                              </label>
                            </div>
                            <div
                              htmlFor={`privilege_${privilege.id}`}
                              className={cn([style.privilegeSubLabel, "mt-2"])}
                            >
                              {displayPrivilegeInfo(privilege)}
                            </div>
                            {privilege.resetDate ? (
                              <div
                                htmlFor={`privilege_${privilege.id}`}
                                className={style.privilegeSubLabel}
                              >
                                {`Récupération le ${format(
                                  parseISO(privilege.resetDate),
                                  "dd/MM/yyyy"
                                )}`}
                              </div>
                            ) : null}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center mt-4">
                    <button
                      disabled={privValues.privilegesId.length <= 0}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Utiliser
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default FidPointsManager
