import React from "react"

// Component
import ProductsTable from "./ProductsTable"

const ProductsList = ({ onSelectProduct }) => (
  <>
    <h1>Produits</h1>
    <ProductsTable onSelectProduct={onSelectProduct} />
  </>
)
ProductsList.propTypes = {}

export default ProductsList
