import { subDays, addMonths, isBefore, isAfter } from "date-fns"
import { isNil } from "lodash"

/**
 * Build array of id starting from the beginning, to 5 months after current date
 * @param {*} weeksOrdered
 * @param {*} dataType
 */
export const buildOrderedWeeks = (weeksOrdered, dataType = "ids") => {
  const orderedWeeks = weeksOrdered.reduce((acc, week) => {
    const now = new Date()
    const date = new Date(week.start)
    if (isBefore(date, addMonths(now, 17))) {
      switch (dataType) {
        case "ids":
          acc.push(week.id)
          break
        case "weeks":
          acc.push({ start: week.start })
          break
        default:
          break
      }
    }
    return acc
  }, [])

  return orderedWeeks
}

/**
 * Build array of id starting from current date, to 5 months
 * @param {*} weeksOrdered
 */
export const buildOrderedFutureWeeks = weeksOrdered => {
  const orderedWeeks = weeksOrdered.reduce((acc, week) => {
    const now = new Date()
    const date = new Date(week.start)
    if (isBefore(date, addMonths(now, 17)) && isAfter(date, subDays(now, 6)))
      acc.push(week.id)

    return acc
  }, [])

  return orderedWeeks
}

/**
 * Build array of players, with the name, gender and tennis data
 * @param {*} players
 */
export const buildPlayers = players => {
  const res = players.reduce((acc, player) => {
    acc[player.uid] = { 
      name: `${player.lastname} ${player.firstname}`,
      lastTennisPracticeDataUpdate: player.lastTennisPracticeDataUpdate,
      tennisData: {
        startYear: !isNil(player.playerSports?.['1']?.years) ? player.playerSports['1'].years : '',
        weeklyHours: !isNil(player.playerSports?.['1']?.hoursPerWeek) ? player.playerSports['1'].hoursPerWeek : '',
        yearlyMatches: !isNil(player.playerSports?.['1']?.participationsPerYear) ? player.playerSports['1'].participationsPerYear : ''
      },
      sexId: player.sexId
    }
    return acc
  }, {})

  return res
}

/**
 * Build array of families, with only the name
 * @param {*} families
 */
export const buildFamilies = families => {
  const res = families.reduce((acc, family) => {
    acc[family.id] = { name: family.name.toUpperCase() }
    return acc
  }, {})

  return res
}

/**
 * Build array of families, with only the name
 * @param {*} families
 */
export const buildDiscounts = discounts => {
  const res = discounts.reduce((acc, discount) => {
    const { code, typeId, absoluteValue, relativeValue } = discount
    acc[discount.id] = {
      code,
      typeId,
      value: typeId === 1 ? absoluteValue : relativeValue,
    }
    return acc
  }, {})

  return res
}

/**
 * Build array of products, with only the name and archived status
 * @param {*} productsData
 */
export const buildProducts = productsData => {
  const products = Object.keys(productsData.products).map(product => ({
    ...productsData.products[product],
    id: product,
  }))
  const res = products.reduce((acc, product) => {
    acc[product.id] = { name: product.name["1"], archived: product.archived }
    return acc
  }, {})

  return res
}

/**
 * Build array of products, with only the name
 * @param {*} productsStocks
 */
export const buildStocksPrices = productsStocks => {
  const stocks = {}

  for (const id in productsStocks) {
    if (Object.prototype.hasOwnProperty.call(productsStocks, id)) {
      const {
        productId,
        weekId,
        stock,
        familyId,
        price,
        active,
      } = productsStocks[id]

      if (!stocks[productId]) stocks[productId] = {}
      stocks[productId][weekId] = {
        inStock: stock > 0 && active,
        familyId,
        price,
      }
    }
  }

  const stocksBuilt = {}
  const pricesBuilt = {}

  for (const stockId in stocks) {
    if (Object.prototype.hasOwnProperty.call(stocks, stockId)) {
      for (const weekId in stocks[stockId]) {
        if (Object.prototype.hasOwnProperty.call(stocks[stockId], weekId)) {
          const week = stocks[stockId][weekId]
          const { familyId, inStock, price } = week

          if (!stocksBuilt[weekId]) stocksBuilt[weekId] = {}
          if (!stocksBuilt[weekId][familyId]) stocksBuilt[weekId][familyId] = []
          if (inStock) stocksBuilt[weekId][familyId].push(stockId)

          if (!pricesBuilt[weekId]) pricesBuilt[weekId] = {}
          pricesBuilt[weekId][stockId] = price
        }
      }
    }
  }

  return { stocksBuilt, pricesBuilt }
}
