import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Spinner from "../../../components/atoms/Spinner/Spinner"
import { Switch } from "../../../components/atoms/Inputs/Inputs"
import Archive from "../../../components/molecules/Archive/Archive"

// Hooks
import useAdminsApi from "../../../hooks/Api/Authorization/useAdminsApi"
import useRolesApi from "../../../hooks/Api/Authorization/useRolesApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"
import { exportCollectionToArray } from "../../../utils/collection"

// Styles
import style from "./AdminsEdit.module.css"

const permissions = [
  PERMISSIONS.PERM_ADMINS_UPDATE,
  PERMISSIONS.PERM_ADMINS_CREATE,
  PERMISSIONS.PERM_ADMINS_ARCHIVE,
]

const VIEWS = {
  FORM: "FORM",
  ARCHIVE: "ARCHIVE",
}

countries.registerLocale(countriesFR)

const AdminsEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const isEditing = !isEmpty(match.params)
  const history = useHistory()
  const [formFieldsError, setFormFieldsError] = useState({
    email: null,
  })
  const [currentView, setCurrentView] = useState(VIEWS.FORM)
  const [admins, setAdmins] = useState(null)
  const [admin, setAdmin] = useState(null)
  const [roles, setRoles] = useState(null)
  const [error, setError] = useState(null)
  const [adminLoading, setAdminLoading] = useState(false)

  const [
    { getAdmins, getAdminWithId, updateAdmin, createAdmin },
  ] = useAdminsApi()
  const [{ getRoles }] = useRolesApi()
  const savedGetAdmins = useCallback(getAdmins, [])
  const savedGetAdminWithId = useCallback(getAdminWithId, [])
  const savedGetRoles = useCallback(getRoles, [])

  const isEditingAdmin = !isEmpty(match.params)

  const fetchAdmins = useCallback(async () => {
    try {
      const adminsResp = await savedGetAdmins()

      setAdmins(exportCollectionToArray(adminsResp.data, "admins"))
    } catch (e) {
      if (e.response) {
        console.error(e.response)
      }
    }
  }, [match.params.id, savedGetAdmins])

  const fetchAdmin = useCallback(async () => {
    try {
      const adminResp = await savedGetAdminWithId(match.params.id)

      setAdmin(adminResp.data)
    } catch (e) {
      if (e.response) {
        console.error(e.response)
      }
    }
  }, [match.params.id, savedGetAdminWithId])

  const fetchRoles = useCallback(async () => {
    try {
      const rolesResp = await savedGetRoles()
      setRoles(exportCollectionToArray(rolesResp.data, "roles"))
    } catch (e) {
      if (e.response) {
        console.error(e.response)
      }
    }
  }, [savedGetRoles])

  useEffect(() => {
    if (isEditingAdmin) fetchAdmin()
    fetchAdmins()
    fetchRoles()
  }, [fetchAdmins, fetchAdmin, fetchRoles, isEditingAdmin])

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues:
      isEditingAdmin && admin
        ? admin
        : {
            email: "",
            firstname: "",
            lastname: "",
            roleId: "",
            active: true,
            archived: false,
          },
    onSubmit: async valuesSubmit => {
      setAdminLoading(true)
      try {
        if (!isEditingAdmin) {
          await createAdmin(valuesSubmit)
          setAdminLoading(false)
          history.push({
            pathname: "/admins",
            state: { submitType: "create", success: true },
          })
        } else {
          await updateAdmin(match.params.id, valuesSubmit)
          setAdminLoading(false)
          history.push({
            pathname: "/admins",
            state: { submitType: "edit", success: true },
          })
        }
      } catch (e) {
        if (e.response) setError(e.response.data.errors.join())
        setAdminLoading(false)
      }
    },
  })

  const checkFormErrors = () => {
    let e = false

    for (const [, value] of Object.entries(formFieldsError)) {
      if (value) e = true
      break
    }

    return e
  }

  const checkFieldError = field => {
    for (const [f, value] of Object.entries(formFieldsError)) {
      if (f === field) return !!value
    }
    return false
  }

  const handleEmailChange = (e, hChange) => {
    if (admins) {
      const emailFound = admins.find(adm => adm.email === e.target.value)
      if (emailFound) {
        if (!isEditingAdmin || !(admin.email === e.target.value))
          setFormFieldsError({
            ...formFieldsError,
            email: "Un admin avec le même email existe déja",
          })
      } else
        setFormFieldsError({
          ...formFieldsError,
          email: null,
        })
    }
    hChange(e)
  }

  const handleAdminArchive = async () => {
    setAdminLoading(true)
    const valuesToSubmit = { ...values, archived: true }
    try {
      if (!isEditingAdmin) {
        await createAdmin(valuesToSubmit)
        setAdminLoading(false)
        history.push({
          pathname: "/admins",
          state: { submitType: "create", success: true },
        })
      } else {
        await updateAdmin(match.params.id, valuesToSubmit)
        setAdminLoading(false)
        history.push({
          pathname: "/admins",
          state: { submitType: "edit", success: true },
        })
      }
    } catch (e) {
      if (e.response) setError(e.response.data.errors.join())
      setAdminLoading(false)
    }
  }

  const backToList = () => {
    history.push({
      pathname: "/admins",
      state: {},
    })
  }

  let isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_ADMINS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_ADMINS_CREATE] && !isEditing)
  if (isEditing && isAllowedToEdit) {
    if (admin && admin.archived) isAllowedToEdit = false
    else isAllowedToEdit = true
  }

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <span className="d-inline-flex">
          <h1 className="mb-0">
            {`Admins / ${isEditingAdmin ? "Éditer" : "Nouveau"}`}
            {admin ? ` / ${admin.firstname} ${admin.lastname}` : null}
          </h1>
          {admin && admin.archived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Archivé
              </span>
            </h3>
          ) : null}
        </span>
      </div>
      {currentView === VIEWS.FORM ? (
        <>
          {(isEditingAdmin && admin) || !isEditingAdmin ? (
            <form onSubmit={handleSubmit} className="mw-100 w-50 m-auto">
              {error ? (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              ) : null}
              <div className={cn(["card mb-4"])}>
                <div className="card-body">
                  <h5 className="card-title">Identité</h5>

                  <div className="form-row mb-2">
                    <div className="col-sm-12 col-md-6 pb-2">
                      <label htmlFor="admin-firstname">Prénom</label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="text"
                        className="form-control"
                        id="admin-firstname"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    <div className="col-sm-12 col-md-6 pb-2">
                      <label htmlFor="admin-lastname">Nom</label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="text"
                        className="form-control"
                        id="admin-lastname"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  </div>

                  <div className="form-row mb-2">
                    <div className="col-sm-12 col-md-6 pb-2">
                      <label htmlFor="admin-email">E-mail</label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="email"
                        className="form-control"
                        id="admin-email"
                        name="email"
                        value={values.email}
                        onChange={e => handleEmailChange(e, handleChange)}
                        onBlur={handleBlur}
                        required
                      />
                      {checkFieldError("email") ? (
                        <div className={style.fieldError}>
                          {formFieldsError.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-sm-12 col-md-6 pb-2">
                      <label htmlFor="clients-nationality">Rôle</label>
                      <select
                        disabled={!isAllowedToEdit}
                        className="custom-select"
                        id="admin-role"
                        name="roleId"
                        required
                        onChange={e => setFieldValue("roleId", e.target.value)}
                        onBlur={handleBlur}
                        value={values.roleId}
                      >
                        <option disabled value="">
                          Choisir un rôle
                        </option>
                        {roles &&
                          roles.map(role => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                      </select>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-sm-12 col-md-6 pb-2">
                      <label htmlFor="admin-email">Status</label>
                      <Switch
                        label="Actif"
                        value={values.active}
                        onClick={active => setFieldValue("active", active)}
                        disabled={!isAllowedToEdit}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {rolePermissions[PERMISSIONS.PERM_ADMINS_ARCHIVE] &&
              isAllowedToEdit &&
              isEditing ? (
                <div className="float-right mb-4">
                  <button
                    type="button"
                    onClick={() => setCurrentView(VIEWS.ARCHIVE)}
                    className="btn btn-danger"
                  >
                    ARCHIVER
                  </button>
                </div>
              ) : null}

              <div className="mb-4">
                {!isAllowedToEdit ? (
                  <button
                    onClick={backToList}
                    type="button"
                    className="btn btn-secondary mr-2"
                  >
                    Revenir à la liste
                  </button>
                ) : null}
                {admin && admin.archived ? null : (
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={
                      adminLoading || checkFormErrors() || !isAllowedToEdit
                    }
                  >
                    {adminLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    VALIDER
                  </button>
                )}
              </div>
            </form>
          ) : (
            <Spinner />
          )}
        </>
      ) : null}
      {currentView === VIEWS.ARCHIVE ? (
        <div className={style.formWidth}>
          <Archive
            onArchivedClick={() => handleAdminArchive()}
            onCancelClick={() => setCurrentView(VIEWS.FORM)}
          >
            <div className={style.archiveText}>
              {`Etes-vous sûr de vouloir archiver l'admin ${admin.firstname} ${admin.lastname} ?`}
            </div>
          </Archive>
        </div>
      ) : null}
    </WithMenu>
  )
}

AdminsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default AdminsEdit
