// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useQuotasApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getQuotas = options => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/quotas${params}`)
  }

  const getQuotaWithId = quotaId =>
    request("GET", `${API_STAGES_URL}/v1/quotas/${quotaId}`)

  const updateQuota = quota =>
    request("PUT", `${API_STAGES_URL}/v1/quotas/${quota.id}`, quota)

  const createQuota = quota =>
    request("POST", `${API_STAGES_URL}/v1/quotas`, quota)

  return [
    requestState,
    payload,
    error,
    {
      getQuotas,
      getQuotaWithId,
      updateQuota,
      createQuota,
    },
  ]
}

export default useQuotasApi
