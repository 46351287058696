import React, {useCallback, useEffect, useState} from 'react'

//components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"
import { SelectInput } from '../../../../components/atoms/Inputs/Inputs'
import Spinner from '../../../../components/atoms/Spinner/Spinner'
import Button from '../../../../components/atoms/Button'
import GlobalMessage from '../../../../components/atoms/GlobalMessage/GlobalMessage'

// hooks
import useClientsApi from '../../../../hooks/Api/useClientsApi'
import usePaymentsApi from '../../../../hooks/Api/usePaymentsApi'
import useOrdersApi from '../../../../hooks/Api/useOrdersApi'
import useRefundApi from '../../../../hooks/Api/useRefundApi'


// styles
import style from "./ClientBalance.module.css"

export const ClientBalance = ({ match }) => {
  
  const [orderSelected, setOrderSelected] = useState(null)
  const [actionSelected, setActionSelected] = useState(null)
  const [amount, setAmount] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })


  const [, clientData, , { getClientWithId }] = useClientsApi()
  const [, ordersData, , { getOrders }] = useOrdersApi()
  const [{ createPayment }] = usePaymentsApi()
  const [{ createRefund }] = useRefundApi()

  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetOrders = useCallback(getOrders, [])

  useEffect(() => {
    savedGetClientWithId(match.params.clientId)
    savedGetOrders({ clientId: match.params.clientId })

  }, [savedGetClientWithId])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const amountToApi = Number(String(amount.toFixed(2)).split('.').join(''))
    setButtonLoading(true)
    try{
      if(!!orderSelected && actionSelected === 'debit' && !!amount) {
        await createPayment({
          orderId: orderSelected,
          amount: amountToApi,
          paymentMeanId: 3,
        })
      }
      if(!!orderSelected && actionSelected === 'credit' && !!amount) {
        await createRefund({
          orderId: orderSelected,
          amount: amountToApi,
        })
      }
      setButtonLoading(false)
      
      setOrderSelected(null)
      setActionSelected(null)
      setAmount(null)

      setGlobalMessage({
        isActive: true,
        message: <span>Action réalisé avec succès</span>,
        className: "alert alert-success",
      })
    } catch(err){
      setGlobalMessage({
        isActive: true,
        message: <span>Une erreur est survenue, veuillez réessayez ou contacter un administrateur</span>,
        className: "success alert-danger",
      })
      setButtonLoading(false)
    }
  }

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          {`Clients 
          ${
            clientData
              ? ` / ${clientData.firstname} ${clientData.lastname}`
              : null
          } / Articles`}
        </h1>
      </div>
      <div className={style.topMenu}>
        <ClientNavigation
          client={{ ...clientData, uid: match.params.clientId }}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <GlobalMessage
          isActive={globalMessage.isActive}
          content={globalMessage.message}
          className={globalMessage.className}
          onClose={() =>
            setGlobalMessage({
              ...globalMessage,
              isActive: false,
            })
          }
        />
        <div className="row">
        {ordersData 
            ? <div className='col-md-6'>
                <SelectInput 
                  label="Liste des commandes"
                  id="orders"
                  name="orders"
                  options={[
                    <option key="default" value="" disabled>
                      Choisissez une commande
                    </option>,
                    ordersData.orders.map(order => (
                      <option key={order.id} value={order.id}>
                        {order.id}
                      </option>
                    ))
                  ]}
                  value={orderSelected}
                  onChange={(e) => setOrderSelected(e.target.value)}
                />
              </div> 
              : <Spinner />
          }
          <div className='col-md-6'>
            <SelectInput 
                label="Action à effectuer"
                id="action"
                name="action"
                options={[
                  <option value="" disabled>
                    Choisissez l'action à effectuer
                  </option>,
                  <option value="credit" >
                    Crédit
                  </option>,
                  <option value="debit" >
                    Débit
                  </option>
                ]}
                value={actionSelected}
                onChange={(e) => setActionSelected(e.target.value)}
              />
          </div>
        </div>
        <div className="row">
          <div className='col-md-6'>
            <label htmlFor='amount'>Montant</label>
            <input
              id="amount"
              type="number"
              value={amount || ""}
              min={0}
              max={9_999_999}
              step=".01"
              onChange={e => {
                setAmount(Number(e.target.value))
              }}
              className="form-control"
              required
            />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-6'>
            <Button type="submit" label="Valider" disabled={buttonLoading}/>
          </div>
        </div>
      </form>
    </WithMenu>
  )
}
