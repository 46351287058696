// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useRefundApi = () => {
  const [, , , , rawRequest] = useApi()

  const createRefund = payment =>
    rawRequest("POST", `${API_STAGES_URL}/v1/refunds`, payment)

  return [
    {
      createRefund,
    },
  ]
}

export default useRefundApi
