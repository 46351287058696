import React, { useMemo } from "react"
import PropTypes from "prop-types"

// style
import style from "./Player.module.css"

// utils & misc
import { findGender } from "../../../../../utils/coach/staticValues"

const TooltipPlayer = ({ player, countries }) => {
  const rankingCountry = useMemo(
    () => countries.find((country) => country.code === player.rankCountryCode)?.name?.[1],
    [countries]
  );

  return (
    <div className={style.tooltipPlayer}>
      <p>{player.name}</p>
      <p>Sexe : {findGender(player.sexCode).title}</p>
      <p>Date de naissance : {player.birthdate}</p>
      {player.forcedPlayerRanking || player.ranking ? <p>Ranking : {player.forcedPlayerRanking || player.ranking}</p> : null}
      {player.mtaRank ? <p>Ranking MTA : {player.mtaRank}</p> : null}

      {player.firstYearOfPractice ? <p>Début : {player.firstYearOfPractice}</p> : null}
      {player.hoursPlayedPerWeek ? <p>Heure(s) / sem. : {player.hoursPlayedPerWeek}</p> : null}
      {player.matchesPlayedPerYear ? <p>Match(s) / an : {player.matchesPlayedPerYear}</p> : null}

      {player.frenchRank ? <p>Classement : {player.frenchRank}</p> : null}
      {rankingCountry ? <p>Pays de classement : {rankingCountry}</p> : null}

      {player.playerHasBookingPreviousWeek ? <p>Camp LW</p> : null}
      {player.playerHasBookingNextWeek ? <p>Camp NW</p> : null}
    </div>
  )
}

TooltipPlayer.propTypes = {
  player: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
}

export default TooltipPlayer
