import useApi from "./useApi"
import { API_STAGES_URL } from "../../constants/constants"

const useEventsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getEvents = () => request("GET", `${API_STAGES_URL}/v1/events`)

  const getEventById = eventId =>
    request("GET", `${API_STAGES_URL}/v1/events/${eventId}`)

  const updateEvent = event =>
    request("PUT", `${API_STAGES_URL}/v1/events/${event.id}`, event)

  const createEvent = event =>
    request("POST", `${API_STAGES_URL}/v1/events`, event)

  const reorderEvent = (eventId, newPrecedingEventId) =>
    request("POST", `${API_STAGES_URL}/v1/events:reorder`, {
      reorderedEventId: eventId,
      newPrecedingEventId,
    })

  return [
    requestState,
    payload,
    error,
    {
      getEvents,
      getEventById,
      updateEvent,
      createEvent,
      reorderEvent,
    },
  ]
}

export default useEventsApi
