import React from "react"

// Components
import EditorControlButton from "../EditorControlButton/EditorControlButton"

import { EDITOR_OPTIONS } from "../constants"

import "../Editor.css"

const OptionsStyleControl = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <div className="RichEditor-controls">
      {EDITOR_OPTIONS.map(option => (
        <EditorControlButton
          option={option}
          key={option.label}
          active={option.style === blockType || currentStyle.has(option.style)}
          onToggle={onToggle}
        />
      ))}
    </div>
  )
}

export default OptionsStyleControl
