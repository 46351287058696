/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from "react"
import { isNil } from "lodash"

import { exportCollectionToArray } from "../../../../utils/collection"

// Hook
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useProductsApi from "../../../../hooks/Api/useProductsApi"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

// Constants
import { PERMISSIONS, schoolGrades } from "../../../../constants/constants"

const permissions = [
  PERMISSIONS.PERM_PRODUCTS_CREATE,
  PERMISSIONS.PERM_PRODUCTS_UPDATE,
]

const ProductsTable = ({ onSelectProduct }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, , , { getProductsSe }] = useProductsApi()
  const [, , , { postProductSchool }] = useProductsApi()
  const [, , , { postProductGradeStatus }] = useProductsApi()
  const [, , , { reorderProduct }] = useProductsApi()
  const [, , , { getFormTypes }] = useSportStudiesApi()

  const [loadProducts, setLoadProducts] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [formTypes, setFormTypes] = useState([])
  const [dragPosition, setDragPosition] = useState(null)

  const [productFilter, setProductFilter] = useState(null)
  const [formTypeFilter, setFormTypeFilter] = useState(null)
  const [selectedGrade, setSelectedGrade] = useState({
    1: schoolGrades.find((grade) => grade.schoolId === 1)?.value,
    2: schoolGrades.find((grade) => grade.schoolId === 2)?.value,
  })

  const savedGetProductsSe = useCallback(getProductsSe, [])
  const savedGetFormTypes = useCallback(getFormTypes, [])

  useEffect(() => {
    savedGetFormTypes().then(r => {
      if (r && r.data) {
        setFormTypes(exportCollectionToArray(r.data, "formsTypes"))
      }
    })
    savedGetProductsSe()
      .then(response => {
        if (response && response.data) {
          const reordered = exportCollectionToArray(
            response.data,
            "products"
          ).sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
          setProductsList(reordered)
        }
      })
      .catch(errors => {
        setLoadProducts(false)
      })
  }, [savedGetProductsSe, savedGetFormTypes])

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragPosition({
      ...dragPosition,
      last: parseInt(e.currentTarget.dataset.position, 10),
    })
    e.currentTarget.style.borderTop = "solid 2px black"
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.style.borderTop = "none"
  }

  const handleDragStart = e => {
    const { position } = e.currentTarget.dataset
    setDragPosition({ ...dragPosition, start: parseInt(position, 10) })
    e.currentTarget.style.opacity = "0.5"
  }

  const handleDrop = async e => {
    e.preventDefault()
    e.currentTarget.style.borderTop = "none"
    ;[...e.currentTarget.children].forEach(child => {
      child.style.border = "none"
      child.style.opacity = "1"
    })

    const { last, start } = dragPosition

    await reorderProduct(Number(start), Number(last))

    savedGetProductsSe()
      .then(response => {
        if (response && response.data) {
          const reordered = exportCollectionToArray(
            response.data,
            "products"
          ).sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
          setProductsList(reordered)
        }
      })
      .catch(errors => {
        setLoadProducts(false)
      })
  }

  const handleDragStopProp = e => {
    // Otherwise the drop event isn't fired
    e.preventDefault()
    e.stopPropagation()
  }

  const handleProductFilterChange = (e) => setProductFilter(parseInt(e.target.value, 10));

  const handleFormTypeChange = (e) => setFormTypeFilter(parseInt(e.target.value, 10));

  const handleGradeChange = (e, schoolId) => {
    const { value } = e.target;
    setSelectedGrade((state) => ({...state, [schoolId]: value }));
  };

  const handleProductActive = async (e, updatedProduct, schoolId) => {
    const isChecked = e.target.checked;

    const res = await postProductSchool(updatedProduct.id, Number(schoolId), isChecked);
    if (res?.status === 204) {
      setProductsList(
        productsList.map(product => {
          if (product.id === updatedProduct.id) {
            const newProduct = { ...product };
            newProduct.prices[schoolId].active = isChecked;
            return newProduct;
          }
          return product;
        })
      )
    }
  }

  const handleProductActiveByGrade = async (e, updatedProduct, schoolId) => {
    const isChecked = e.target.checked;

    const res = await postProductGradeStatus(updatedProduct.id, selectedGrade[schoolId], isChecked);
    if (res?.status === 204) {
      setProductsList(
        productsList.map(product => {
          if (product.id === updatedProduct.id) {
            const newProduct = { ...product };
            newProduct.gradePrices[selectedGrade[schoolId]].active = isChecked;
            return newProduct;
          }
          return product;
        })
      )
    }
  };

  const canEdit = rolePermissions[PERMISSIONS.PERM_PRODUCTS_UPDATE]

  const HAS_SCHOOL_ID = (!isNil(formTypeFilter) && (formTypeFilter === 1 || formTypeFilter === 4 || formTypeFilter === 6));
  const IS_PACKAGE_TYPE = (!isNil(productFilter) && productFilter === 2);

  const formTypesNames = {
    2: 'Centre Entrainement',
    3: 'Centre Compétition',
    5: 'Pro team',
    7: 'Centre Entrainement 2',
  };

  const getGradeSelector = (schoolId) => (
    <select
      className="custom-select"
      id="gradeSelect"
      name="gradeSelect"
      value={selectedGrade[schoolId]}
      onChange={(e) => handleGradeChange(e, schoolId)}
      style={{ maxWidth: 120 }}
    >
      {schoolGrades
        .filter((grade) => grade.schoolId === schoolId)
        .map(({ value, label}, key) => (
          <option key={key} value={value}>{label}</option>
        ))
      }
    </select>
  );

  const getPriceInput = (product, schoolId) => (
    <>
      {product.prices[schoolId].value === 0
        ? "offert"
        : Number(product.prices[schoolId].value).toFixed(2) / 100
      }
      <input
        className="ml-2"
        disabled={!canEdit}
        type="checkbox"
        checked={product.prices[schoolId].active}
        onChange={e => handleProductActive(e, product, schoolId)}
      />
    </>
  );

  const getPriceByGradeInput = (product, schoolId) => (
    <>
      {product.gradePrices[selectedGrade[schoolId]].value === 0
        ? "offert"
        : Number(product.gradePrices[selectedGrade[schoolId]].value).toFixed(2) / 100
      }
      <input
        className="ml-2"
        disabled={!canEdit}
        type="checkbox"
        checked={product.gradePrices[selectedGrade[schoolId]].active}
        onChange={e => handleProductActiveByGrade(e, product, schoolId)}
      />
    </>
  );

  return (
    <>
      {formTypes ? (
        <>
          <div className="float-right">
            {rolePermissions[PERMISSIONS.PERM_PRODUCTS_CREATE] ? (
              <button
                type="button"
                onClick={() => onSelectProduct(null)}
                className="btn btn-success"
              >
                Nouveau produit
              </button>
            ) : null}
          </div>
          <h4 className="mt-4">Dossier:</h4>
          <select
            className="custom-select w-25"
            id="formTypeSelect"
            name="formTypeSelect"
            value={formTypeFilter || ''}
            onChange={handleFormTypeChange}
          >
            <option value="" disabled>
              Choisir un type de dossier
            </option>
            {formTypes.map((formType, key) => (
              <option value={formType.id} key={key}>{formType.code}</option>
            ))}
          </select>
          <h4 className="mt-4">Produit:</h4>
          <select
            className="custom-select w-25"
            id="formTypeSelect"
            name="formTypeSelect"
            value={productFilter || ''}
            onChange={handleProductFilterChange}
          >
            <option value="" disabled>
              Choisir un type de produit
            </option>
            {/* <option value={1}>Programme</option> */}
            <option value={2}>Package</option>
            <option value={3}>Hébergement</option>
            <option value={4}>Interne - Obligatoire</option>
            <option value={5}>Interne - Optionnel</option>
            <option value={6}>Option</option>
          </select>
        </>
      ) : null}

      {productFilter && formTypeFilter ? (
        <table className="table mt-4">
          <thead>
            <tr>
              <th className="align-middle">#</th>
              <th className="align-middle">Nom</th>
              <th className="align-middle">Tarification</th>
              {HAS_SCHOOL_ID ? (
                IS_PACKAGE_TYPE ? (
                  <>
                    <th className="align-middle">
                      <div className="d-flex align-items-center text-nowrap">
                        <div className="mr-4">École Fr</div>
                        {getGradeSelector(1)}
                      </div>
                    </th>
                    <th className="align-middle">
                      <div className="d-flex align-items-center text-nowrap">
                        <div className="mr-4">École Int</div>
                        {getGradeSelector(2)}
                      </div>
                    </th>
                  </>
                ) : (
                  <>
                    <th className="align-middle">École Fr</th>
                    <th className="align-middle">École Int</th>
                  </>
                )
              ) : (
                <th className="align-middle">
                  {formTypesNames[formTypeFilter] || 'Prix'}
                </th>
              )}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody onDrop={handleDrop}>
            {loadProducts || !productFilter || !formTypeFilter ? (
              <tr>
                <td
                  style={{
                    display: "block",
                    boxSizing: "border-box",
                    clear: "both",
                  }}
                >
                  Chargement ...
                </td>
              </tr>
            ) : (
              productsList
                .filter(
                  product =>
                    product.typeId === productFilter &&
                    product.formTypesIds.find(id => id === formTypeFilter)
                )
                .map(product => (
                  <tr
                    key={product.id}
                    draggable="true"
                    data-position={product.id}
                    onDragStart={handleDragStart}
                    onDragEnter={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragStopProp}
                  >
                    <td>{product.id}</td>
                    <td style={{ maxWidth: 200 }}>{product.name["1"]}</td>
                    <td>{product.isPricedWeekly ? "Semaine" : "Année"}</td>

                    {HAS_SCHOOL_ID ? (
                      IS_PACKAGE_TYPE ? (
                        <>
                          <td>
                            {getPriceByGradeInput(product, '1')}
                          </td>
                          <td>
                            {getPriceByGradeInput(product, '2')}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {getPriceInput(product, '1')}
                          </td>
                          <td>
                            {getPriceInput(product, '2')}
                          </td>
                        </>
                      )
                    ) : (
                      <td>
                        {getPriceInput(product, '1')}
                      </td>
                    )}
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => onSelectProduct(product)}
                      >
                        Éditer
                      </button>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      ) : null}
    </>
  )
}

ProductsTable.propTypes = {}

export default ProductsTable
