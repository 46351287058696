/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { isEqual } from "lodash"

// Hooks
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

// Components
import GlobalMessage from "../../../../components/atoms/GlobalMessage/GlobalMessage"

// Utils
import { exportCollectionToArray } from "../../../../utils/collection"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

const permissions = [PERMISSIONS.PERM_CAMPUS_LOCALES_UPDATE]

const LocalesEdit = ({ onBackToList, selectedLocale }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, formsTypesData, , { getFormTypes }] = useSportStudiesApi()
  const [, , , { putFormType }] = useSportStudiesApi()
  const savedGetFormTypes = useCallback(getFormTypes, [])

  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [data, setData] = useState({})
  const [formSave, setFormSave] = useState(false)
  const [formError, setFormError] = useState(null)
  const [formTypeSelected, setFormsTypeSelected] = useState(null)
  const [formTypesOptions, setFormsTypesOptions] = useState([])
  const [formsTypesList, setFormsTypesList] = useState([])

  const getCurrentFormTypeData = () => {
    const foundType = formsTypesList.find(
      formType => formType.id === formTypeSelected.value
    )
    if (foundType === undefined) return {}
    return foundType.intl[selectedLocale.id]
  }

  useEffect(() => {
    savedGetFormTypes()
  }, [savedGetFormTypes])

  useEffect(() => {
    if (formsTypesData) {
      const list = exportCollectionToArray(formsTypesData, "formsTypes")
      setFormsTypesOptions(
        list.map(formType => ({
          label: formType.code,
          value: formType.id,
        }))
      )
      setFormsTypeSelected({
        label: list[0].code,
        value: list[0].id,
      })
      setData(list[0].intl[selectedLocale.id])
      setFormsTypesList(list)
    }
  }, [formsTypesData, selectedLocale])

  const handleFormTypeChange = option => {
    setFormsTypeSelected(option)
    setData(
      formsTypesList.find(formType => formType.id === option.value).intl[
        selectedLocale.id
      ]
    )
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setFormSave(true)
    setFormError(null)

    try {
      if (selectedLocale) {
        const payload = formsTypesList.find(
          formType => formType.id === formTypeSelected.value
        )
        payload.intl[selectedLocale.id] = data
        await putFormType(payload.id, payload)
        setFormsTypesList(
          formsTypesList.map(formType => {
            if (formType.id === payload.id) return payload
            return formType
          })
        )
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès,</strong>{" "}
              {` locale modifiée pour le dossier de type ${formTypeSelected.label}`}
              .
            </span>
          ),
          className: "alert alert-success",
        })
        setFormSave(false)
      }
    } catch (errors) {
      setFormSave(false)
    }
  }

  const handleChange = e => {
    if (e.target.name === "docuSignTemplateId")
      setData({ ...data, [e.target.name]: e.target.value })
    else setData({ ...data, [e.target.name]: parseFloat(e.target.value) })
  }

  const handleChangeStr = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const canEdit = rolePermissions[PERMISSIONS.PERM_CAMPUS_LOCALES_UPDATE]

  return (
    <div className="container-fluid mt-3">
      <button
        className="btn btn-primary btn-sm mb-2"
        type="button"
        onClick={onBackToList}
      >
        Retour à la liste
      </button>
      <form onSubmit={handleSubmit}>
        <h1>
          {selectedLocale
            ? `Édition locale / ${selectedLocale.code}`
            : "Nouveau locale"}
        </h1>
        <GlobalMessage
          isActive={globalMessage.isActive}
          content={globalMessage.message}
          className={globalMessage.className}
          onClose={() =>
            setGlobalMessage({ ...globalMessage, isActive: false })
          }
        />
        {formError ? (
          <div className="alert alert-danger">{formError}</div>
        ) : null}

        {formTypesOptions.length > 0 &&
        formTypeSelected !== null &&
        formsTypesList.length > 0 ? (
          <>
            <div className="row">
              <div className="col-12 mt-4">
                <label htmlFor="react-select-form-type">
                  <b>Type de dossier</b>
                </label>
                <Select
                  value={formTypeSelected}
                  onChange={option => handleFormTypeChange(option)}
                  options={formTypesOptions}
                  placeholder="Sélectionner un type de dossier"
                  isDisabled={!isEqual(data, getCurrentFormTypeData())}
                  noOptionsMessage={() => <span>Pas de résultats</span>}
                  styles={{
                    control: provided => ({
                      ...provided,
                      maxWidth: 500,
                    }),
                    menu: provided => ({
                      ...provided,
                      color: "#000",
                      maxWidth: 500,
                    }),
                    option: provided => ({ ...provided, fontSize: ".9rem" }),
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-4">
                <GlobalMessage
                  isActive={!isEqual(data, getCurrentFormTypeData())}
                  content="Veuillez sauvegarder les changements avant de changer de type de dossier"
                  className="alert alert-warning"
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="form-group">
          <div className="form-row mb-4 mt-4">
            <div className="col">
              <label htmlFor="emarsysFormReregistrationCampaignId">
                ID campagne Emarsys de l'email de réinscription
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormReregistrationCampaignId"
                type="number"
                name="emarsysFormReregistrationCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormReregistrationCampaignId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <label htmlFor="emarsysFormSubmissionCampaignId">
                ID campagne Emarsys de soumission de dossier &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormSubmissionCampaignId"
                type="number"
                name="emarsysFormSubmissionCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormSubmissionCampaignId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <label htmlFor="emarsysFormMessageCampaignId">
                ID campagne Emarsys de notification &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormMessageCampaignId"
                type="number"
                name="emarsysFormMessageCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormMessageCampaignId}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              <label htmlFor="emarsysFormCreationCampaignId">
                ID campagne Emarsys d&apos;ouverture de dossier &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormCreationCampaignId"
                type="number"
                name="emarsysFormCreationCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormCreationCampaignId}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col">
              <label htmlFor="emarsysFormSignatureCampaignId">
                ID campagne Emarsys de signature &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormSignatureCampaignId"
                type="number"
                name="emarsysFormSignatureCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormSignatureCampaignId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <label htmlFor="docuSignTemplateId">
                ID template DocuSign de l&apos;enveloppe &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="docuSignTemplateId"
                type="string"
                name="docuSignTemplateId"
                placeholder="ID du template"
                value={data.docuSignTemplateId}
                onChange={handleChangeStr}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              <label htmlFor="emarsysFormPaymentSuccessCampaignId">
                ID campagne Emarsys de confirmation de paiement &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormPaymentSuccessCampaignId"
                type="number"
                name="emarsysFormPaymentSuccessCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormPaymentSuccessCampaignId}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <label htmlFor="emarsysFormPaymentFailureCampaignId">
                ID campagne Emarsys d'échec de paiement &nbsp;
              </label>
              <input
                disabled={!canEdit}
                className="form-control"
                id="emarsysFormPaymentFailureCampaignId"
                type="number"
                name="emarsysFormPaymentFailureCampaignId"
                placeholder="ID de la campagne"
                value={data.emarsysFormPaymentFailureCampaignId}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-success mb-4"
          disabled={
            formSave || !canEdit || isEqual(data, getCurrentFormTypeData())
          }
        >
          Sauvegarder
        </button>
      </form>
    </div>
  )
}

LocalesEdit.propTypes = {
  onBackToList: PropTypes.func.isRequired,
  selectedLocale: PropTypes.shape().isRequired,
}

export default LocalesEdit
