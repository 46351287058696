import React from 'react';
import BookingsList from '../BookingsList';

const ExportGuest = () => {
  return (
    <BookingsList isExportGuest />
  )
}

ExportGuest.propTypes = {};

export default ExportGuest;
