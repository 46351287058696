import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import WeekSelector from "../../../../components/atoms/WeekSelector/WeekSelector"
import Tabs from "../../../../components/molecules/Tabs/Tabs"
import SectionShowed from "./SectionShowed/SectionShowed"
import Spinner from "../../../../components/atoms/Spinner/Spinner"
import Button from "../../../../components/atoms/Button"
import GlobalTooltip from "../../../../components/molecules/Tooltip/Tooltip"

//Hooks
import useBookingsForCoachApi from "../../../../hooks/Api/useBookingsForCoachApi"
import useRotationsForCoachsApi from "../../../../hooks/Api/useRotationsForCoachsApi"
import useAssignmentsForCoachApi from "../../../../hooks/Api/useAssignmentsForCoachApi"

// Style
import style from "./SectionsListingList.module.css"

// utils & misc
import {
  flatSectionsList,
  mapPlayersToSectionsListing,
  mapSectionsListing,
} from "../../../../utils/coach/mappers"
import { formatDateForCoachUrl, weeksList } from "../../../../utils/weeks"
import { orderRotationSections } from "../../../../utils/coach/orderSections"
import { startOfWeek } from "date-fns"
import useAppContext from "../../../../hooks/useAppContext"

const SectionsListingList = props => {
  const date = new Date();
  const weeksListForGroups = useMemo(() => weeksList({
    start: new Date(date.getFullYear(), date.getMonth() - 1, date.getDay()+1),
    end: new Date(date.getFullYear() + 7, date.getMonth(), date.getDay()+1)
  }) , []);

  const { setCurrentCoachWeek, context: { currentCoachWeek }} = useAppContext();
  const mondayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  const params = useParams()
  const history = useHistory() 
  const paramWeekSelected = params?.weekSelected && new Date(params.weekSelected.split('_').reverse().join('/'))
  
  // sections
  const [sections, setSections] = useState(null)

  const [weekSelected, setWeekSelection] = useState(paramWeekSelected || currentCoachWeek || mondayOfCurrentWeek)

  const [pageLoaded, setPageLoaded] = useState(false)
  const [isAssignmentLoading, setAssignmentLoading] = useState(false)
  
  //hooks
  const [, rotations, , { getRotationsForCoachs }] = useRotationsForCoachsApi()
  const [
    ,
    bookingsPlayers,
    ,
    { getBookingsForCoach },
  ] = useBookingsForCoachApi()
  const [, , , { postAsignmentGroupsAssignments }] = useAssignmentsForCoachApi()
  
  const savedGetRotations = useCallback(getRotationsForCoachs, [])
  const savedGetBookings = useCallback(getBookingsForCoach, [])
  
  const isTabActive = (section, indexToMatch) =>
    props.match.params.section
      ? props.match.params.section === section.sectionCode
      : indexToMatch === 0
  const tabActive = sections && sections.find(section => section.isActive)

  useEffect(() => {
    if (paramWeekSelected) {
      setCurrentCoachWeek(paramWeekSelected)
    }
  }, [])
      
  useEffect(() => {
    if (weekSelected !== "" && !sections && !pageLoaded) {
      savedGetRotations(weekSelected)
      savedGetBookings(weekSelected)
      setPageLoaded(true)
    }
  }, [savedGetRotations, savedGetBookings, weekSelected, sections, pageLoaded])

  const handleUpdateSections = sectionsUpdated => setSections(sectionsUpdated)
  const handleUpdateActiveTab = activeTab => {
    const sectionsUpdated = sections.map(section => ({
      ...section,
      isActive: section.sectionCode === activeTab.sectionCode,
    }))
    setSections(sectionsUpdated)
  }

  const initSectionsMapperAndSetter = () => {
    const orderRotations = orderRotationSections(rotations)
    const weekSelectedFormated = formatDateForCoachUrl(weekSelected)
    const sectionsFlatted = flatSectionsList(orderRotations)
    const mappedSection = mapSectionsListing(sectionsFlatted, isTabActive, weekSelectedFormated)
    const mappedPlayersToSection = mapPlayersToSectionsListing(mappedSection, bookingsPlayers.bookings)
    setSections(mappedPlayersToSection)
  }

  useEffect(() => {
    if (rotations && bookingsPlayers) {
      initSectionsMapperAndSetter()
    }
  }, [rotations, bookingsPlayers])

  const handleChangeWeekSelector = (week) => {
    const weekSelectedFormated = formatDateForCoachUrl(week)
    savedGetRotations(week)
    savedGetBookings(week)
    setWeekSelection(week)
    setCurrentCoachWeek(week)
    history.push("/sections/listing/"+ tabActive.sectionCode + '/' +  weekSelectedFormated)
  }

  const handleAssignmentSectionsToPlayers = async () => {
    setAssignmentLoading(true)
    await postAsignmentGroupsAssignments(weekSelected)
    setAssignmentLoading(false)
    savedGetRotations(weekSelected)
    savedGetBookings(weekSelected)
  }

  return (
    <WithMenu>
      <div className="row">
        <div className={[style["page-header"], "col-sm-9"]}>
          <h1 className={style["page-header__title"]}>Listing par Section</h1>
        </div>
      </div>
      <div className={style["top-control"]}>
        <WeekSelector
          className={style.weekselector}
          weeksList={weeksListForGroups}
          value={weekSelected}
          onChange={handleChangeWeekSelector}
        />
        {(rotations && !rotations?.isSectionAssignmentDone) ||
        rotations?.isRotationAndGroupAssignmentDone ? (
          <GlobalTooltip
            content={
              rotations.isRotationAndGroupAssignmentDone
                ? "Script d'affectation des groupes déjà lancé"
                : "Veuillez Lancer d'abord l'affectation à des sections"
            }
          >
            <Button
              label="Affecter les joueurs à des groupes"
              className={style["buttons-control__affectation"]}
              size="small"
              disabled={
                !rotations?.isSectionAssignmentDone ||
                rotations?.isRotationAndGroupAssignmentDone
              }
            />
          </GlobalTooltip>
        ) : (
          <Button
            onClick={handleAssignmentSectionsToPlayers }
            label={isAssignmentLoading ? 'Assignation en cours ...' : 'Affecter les joueurs à des groupes'}
            className={style["buttons-control__affectation"]}
            size="small"
            disabled={
              isAssignmentLoading ||
              !rotations ||
              !bookingsPlayers ||
              !rotations?.isSectionAssignmentDone
            }
          />
        )}
      </div>

      {sections ? (
        <Tabs
          tabs={sections}
          updateActiveTab={handleUpdateActiveTab}
          className={style.tabs}
        />
      ) : 
        pageLoaded ? <div>Aucune section n'existe pour cette semaine</div> : <Spinner />
      }
      {sections ? (
        <SectionShowed
          sections={sections}
          updateSections={handleUpdateSections}
        />
      ) : 
        pageLoaded ? <div>Aucun joueur n'existe dans cette liste pour cette semaine</div> : <Spinner />
      }
    </WithMenu>
  )
}

export default SectionsListingList
