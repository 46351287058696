// Hooks
import useApi from "../useApi"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../../constants/constants"

const useRolesApi = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getRoles = () =>
    rawRequest("GET", `${BASE_URL}/roles`, null, { authKey: AUTHORIZATION })

  const getRole = roleId =>
    rawRequest("GET", `${BASE_URL}/roles/${roleId}`, null, {
      authKey: AUTHORIZATION,
    })

  const createRole = role =>
    rawRequest("POST", `${BASE_URL}/roles`, role, {
      authKey: AUTHORIZATION,
    })

  const editRole = (roleId, role) =>
    rawRequest("PUT", `${BASE_URL}/roles/${roleId}`, role, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getRoles,
      getRole,
      createRole,
      editRole,
    },
  ]
}

export default useRolesApi
