import React from "react"

import "../Editor.css"

const StyleButton = ({ option, onToggle, active }) => {
  const handleToggle = e => {
    e.preventDefault()
    onToggle(option)
  }

  let className = "RichEditor-styleButton"
  if (active) {
    className += " RichEditor-activeButton"
  }

  return (
    <span
      tabIndex={[0]}
      role="button"
      className={className}
      onMouseDown={handleToggle}
    >
      {option.label}
    </span>
  )
}

export default StyleButton
