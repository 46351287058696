import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { sortBy } from "lodash"

import useUtilsApi from "../../../../hooks/Api/useUtilsApi"
// TODO: Refacto with ProductsTable to have something more DRY

const LocalesList = ({ api, onSelectLocale }) => {
  const [, , , { getLocalesSE }] = useUtilsApi()
  const [loadLocales, setLoadLocales] = useState(false)
  const [localesList, setLocalesList] = useState([])

  const savedGetLocalesSE = useCallback(getLocalesSE, [])

  useEffect(() => {
    // Get the locales
    savedGetLocalesSE()
      .then(response => {
        if (response && response.data)
          setLocalesList(sortBy(response.data.locales, ["id"]))
        setLoadLocales(false)
      })
      .catch(errors => {
        // if (errors.response.status === 401) {
        //   api.refreshToken().then(response => {
        //     if (response.data)
        //       localStorage.setItem("auth", JSON.stringify(response.data))
        //     setLoadLocales(true)
        //     return axios
        //       .request({
        //         ...getLocalesConfig,
        //         headers: {
        //           ...getLocalesConfig.headers,
        //           "X-Authorization": `Bearer ${response.data.accessToken}`,
        //         },
        //       })
        //       .then(lastResponse => {
        //         if (lastResponse && lastResponse.data) {
        //           setLocalesList(sortBy(lastResponse.data.locales, ["id"]))
        //           setLoadLocales(false)
        //         }
        //       })
        //   })
        // }
        setLoadLocales(false)
      })
  }, [savedGetLocalesSE])

  return (
    <>
      <h1>Options</h1>
      <table className="table">
        <thead>
          <tr>
            <th className="align-middle">#</th>
            <th className="align-middle">Code</th>
            <th className="align-middle">
              {/* <button
                type="button"
                onClick={() => onSelectLocale(null)}
                className="btn btn-success btn-sm"
              >
                Ajouter
              </button> */}
            </th>
          </tr>
        </thead>
        <tbody>
          {loadLocales ? (
            <tr>
              <td
                style={{
                  display: "block",
                  boxSizing: "border-box",
                  clear: "both",
                }}
              >
                Chargement ...
              </td>
            </tr>
          ) : (
            localesList.map(locale => (
              <tr key={locale.id}>
                <td>{locale.id}</td>
                <td>{locale.code}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => onSelectLocale(locale)}
                  >
                    Éditer
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  )
}

LocalesList.propTypes = {
  api: PropTypes.shape({}).isRequired,
  onSelectLocale: PropTypes.func.isRequired,
}

export default LocalesList
