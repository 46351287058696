import React from "react"

function Media(props) {
  function getValue() {
    return props.getValue(props.path)
  }

  function setValue(event) {
    props.setValue(props.path, Number(event.target.value))
  }

  function getError() {
    return props.getError(props.path)
  }

  return (
    <>
      <select
        disabled={!props.canUserEdit}
        value={getValue()}
        onChange={setValue}
        className={`form-control${getError() ? " is-invalid" : ""}`}
      >
        {Object.keys(props.medias).map(mediaId => (
          <option value={mediaId}>{props.medias[mediaId].name}</option>
        ))}
      </select>
      <div className="invalid-feedback">{getError()}</div>
    </>
  )
}

function Input(props) {
  function getValue() {
    return props.getValue(props.path)
  }

  function setValue(event) {
    props.setValue(props.path, event.target.value)
  }

  function getError() {
    return props.getError(props.path)
  }

  const Input = props.type

  return (
    <>
      <Input
        disabled={!props.canUserEdit}
        value={getValue()}
        onChange={setValue}
        className={`form-control${getError() ? " is-invalid" : ""}`}
      />
      <div className="invalid-feedback">{getError()}</div>
    </>
  )
}

class Entity extends React.Component {
  constructor(props) {
    super(props)

    const data = props.data[props.entity.code]
    const hash = JSON.stringify(data)

    this.state = {
      data,
      hash,
      state: "synced",
      errors: [],
      entryValid: true,
    }
  }

  handleClick = () => {
    this.props.changeSelectedEntityCode(this.props.entity.code)
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let entryValid = true
    for (const i in this.state.data) {
      const item = this.state.data[i]
      for (const j in item) {
        const element = item[j]
        if (typeof element === "object") {
          for (const k in element) {
            let missingLocale = false
            for (const [, locale] of Object.entries(this.props.locales)) {
              const { id } = locale
              missingLocale =  Object.keys(element).find(el => el === id) === undefined
            }
            const lang = element[k]
            if (missingLocale || !lang || (typeof lang === "string" && !lang.length)) {
              entryValid = false
              break
            }
          }
        }
        if (!entryValid) break
      }
      if (!entryValid) break
    }
    if (!entryValid) {
      this.setState({ entryValid: false })
      return
    }

    this.setState({ entryValid: true })

    this.setState(state => {
      if (state.state !== "unsynced") {
        return
      }

    this.props
      .updateIntl(
        JSON.stringify({
          code: this.props.entity.code,
          data: this.state.data,
        })
      )
      .then(() => {
        this.setState({
          errors: [],
          state: "synced",
        })
      })
      .catch(data => {
        const json = data.responseJSON
        const errors = {}

        for (const key in json) {
          if (json.hasOwnProperty(key)) {
            const error = json[key]
            const path = key.replace(/\[/g, ".").replace(/]/g, "")
            errors[path] = error
          }
        }

        this.setState({
          errors,
          state: "unsynced",
        })
      })

      return { state: "syncing" }
    })
  }

  getValue = path => {
    const segments = path.split(".")

    let target = this.state.data

    for (let i = 0; i < segments.length; ++i) {
      target = target[segments[i]]
    }

    return target
  }

  setValue = (path, value) => {
    this.setState(state => {
      const data = JSON.parse(JSON.stringify(state.data))
      const segments = path.split(".")

      let i
      let target = data

      for (i = 0; i < segments.length - 1; ++i) {
        target = target[segments[i]]
      }

      target[segments[i]] = value

      const hash = JSON.stringify(data)

      return {
        data,
        state: hash === state.hash ? "synced" : "unsynced",
      }
    })
  }

  getError = path => {
    if (path in this.state.errors && this.state.errors.hasOwnProperty(path)) {
      return this.state.errors[path]
    }
  }

  render = () => {
    let button

    switch (this.state.state) {
      case "synced":
        button = (
          <button
            disabled={!this.props.canUserEdit}
            type="button"
            onClick={this.handleSubmit}
            className="btn btn-success"
          >
            Saved <i className="fas fa-fw fa-check" />
          </button>
        )
        break
      case "unsynced":
        button = (
          <button
            type="button"
            onClick={this.handleSubmit}
            className="btn btn-primary"
          >
            Save <i className="fas fa-fw fa-save" />
          </button>
        )
        break
      case "syncing":
        button = (
          <button
            type="button"
            onClick={this.handleSubmit}
            className="btn btn-secondary"
          >
            Saving <i className="fas fa-fw fa-circle-notch fa-spin" />
          </button>
        )
        break
      default:
        break
    }

    return (
      <>
        <tr>
          <th colSpan={this.props.locales.length} onClick={this.handleClick}>
            {this.props.entity.label}
            <i
              className={`fas dropdown-toggle ${
                this.props.selected ? "down" : "right"
              } ml-2`}
            />
          </th>
          <th className="text-right">
            { !this.state.entryValid &&
              <span className="alert alert-danger mr-4" role="alert">
                Toutes les entrées doivent être remplies
              </span>
            }
            <span>{button}</span>
          </th>
        </tr>
        {Object.keys(this.props.data[this.props.entity.code]).map(
          (entityId, ordinal) => (
            <React.Fragment key={entityId}>
              {this.props.selected && (
                <>
                  <tr>
                    <th>{ordinal + 1}</th>
                    {this.props.locales.map(locale => (
                      <th key={locale.id}>{locale.code.toUpperCase()}</th>
                    ))}
                  </tr>
                  {Object.keys(
                    this.props.data[this.props.entity.code][entityId]
                  ).map(field => (
                    <tr key={field}>
                      <td>{field}</td>
                      {this.props.locales.map(locale => (
                        <td key={locale.id}>
                          {this.props.types[this.props.entity.code][field] ===
                          "media" ? (
                            <Media
                              canUserEdit={this.props.canUserEdit}
                              path={`${entityId}.${field}.${locale.id}`}
                              getValue={this.getValue}
                              setValue={this.setValue}
                              getError={this.getError}
                              medias={this.props.medias}
                            />
                          ) : (
                            <Input
                              canUserEdit={this.props.canUserEdit}
                              type={
                                this.props.types[this.props.entity.code][field]
                              }
                              path={`${entityId}.${field}.${locale.id}`}
                              getValue={this.getValue}
                              setValue={this.setValue}
                              getError={this.getError}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </React.Fragment>
          )
        )}
      </>
    )
  }
}

class Matrix extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedEntityCode: null,
    }
  }

  changeSelectedEntityCode = entityCode => {
    this.setState(state => {
      if (state.selectedEntityCode === entityCode) {
        entityCode = null
      }

      return { selectedEntityCode: entityCode }
    })
  }

  render = () => (
    <React.StrictMode>
      <table className="table">
        <tbody>
          {this.props.entities.map(entity => (
            <Entity
              canUserEdit={this.props.canUserEdit}
              key={entity.code}
              locales={this.props.locales}
              medias={this.props.medias}
              types={this.props.types}
              data={this.props.data}
              updateIntl={this.props.updateIntl}
              entity={entity}
              selected={this.state.selectedEntityCode === entity.code}
              changeSelectedEntityCode={this.changeSelectedEntityCode}
            />
          ))}
        </tbody>
      </table>
    </React.StrictMode>
  )
}

export default Matrix

// $(() => {
//   const $matrix = $('.matrix');
//   const props = $matrix.data('props');
//   ReactDOM.render(
//     <Matrix
//       locales={props.locales}
//       medias={props.medias}
//       entities={props.entities}
//       types={props.types}
//       data={props.data}
//       updateIntl={props.updateIntll}
//     />,
//     $matrix[0],
//   );
// });
