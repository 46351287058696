// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useCampsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getCamps = options => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/camps${params}`)
  }

  const getCampWithId = campId =>
    request("GET", `${API_STAGES_URL}/v1/camps/${campId}`)

  const updateCamp = camp =>
    request("PUT", `${API_STAGES_URL}/v1/camps/${camp.id}`, camp)

  const createCamp = camp => request("POST", `${API_STAGES_URL}/v1/camps`, camp)

  return [
    requestState,
    payload,
    error,
    {
      getCamps,
      getCampWithId,
      updateCamp,
      createCamp,
    },
  ]
}

export default useCampsApi
