import { useState, useEffect, useCallback } from "react"

import { doesUserHaveRoles } from "../utils/rolesHandler"

/*
The arg passed to the launchTimeout function
is transfered to the callback call
*/
const usePermissionsState = (userInfo, permissions) => {
  const [permissionState, setPermissionState] = useState({})

  const savedDoesUserHaveRoles = useCallback(doesUserHaveRoles, [])

  useEffect(() => {
    if (userInfo) {
      const permState = {}
      permissions.forEach(perm => {
        permState[perm] = savedDoesUserHaveRoles(userInfo, perm)
      })
      setPermissionState(permState)
    }
  }, [permissions, savedDoesUserHaveRoles, userInfo])

  return permissionState
}

export default usePermissionsState
