import React, { useState, useEffect } from "react"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

const OptionEdit = ({ onBackToList, selectedOption, api }) => {
  const [, , , { postOptionsConfig }] = useSportStudiesApi()
  const [, , , { putOptionsConfig }] = useSportStudiesApi()

  const [data, setData] = useState({
    schoolsIds: [],
    name: {
      1: "",
      2: "",
    },
  })
  const [formSave, setFormSave] = useState(false)
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    if (selectedOption) setData(selectedOption)
  }, [selectedOption])

  const handleSubmit = e => {
    e.preventDefault()

    setFormSave(true)
    setFormError(null)

    selectedOption
      ? putOptionsConfig(selectedOption ? selectedOption.id : "", data)
      : postOptionsConfig(data)
          .then(() => {
            onBackToList()
          })
          .catch(errors => {
            setFormSave(false)
            setFormError(errors.response.data.errors[0])
          })
  }

  const handleChange = e => {
    if (e.target.name.includes("name")) {
      const splitedName = e.target.name.split("-")
      setData({
        ...data,
        name: { ...data.name, [splitedName[1]]: e.target.value },
      })
    } else if (e.target.name.includes("schoolsIds")) {
      const splitedName = e.target.name.split("-")
      if (e.target.checked) {
        setData({
          ...data,
          schoolsIds: data.schoolsIds.concat([parseInt(splitedName[1], 10)]),
        })
      } else {
        setData({
          ...data,
          schoolsIds: data.schoolsIds.filter(id => id !== splitedName[1]),
        })
      }
    } else setData({ ...data, [e.target.name]: e.target.value })
  }

  return (
    <div className="container-fluid mt-3">
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={onBackToList}
      >
        Retour à la liste
      </button>
      <form onSubmit={handleSubmit}>
        <h1>{selectedOption ? "Édition option" : "Nouvelle option"}</h1>
        {formError ? (
          <div className="alert alert-danger">{formError}</div>
        ) : null}

        <fieldset id="general" className="form-group">
          <div className="form-group">
            <label htmlFor="name-1">
              Nom &nbsp;
              <input
                id="name-1"
                type="text"
                name="name-1"
                placeholder="Nom en francais"
                value={data.name["1"]}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="name-2">
              <input
                id="name-2"
                type="text"
                name="name-2"
                placeholder="Nom en Anglais"
                onChange={handleChange}
                value={data.name["2"]}
                required
              />
            </label>
          </div>
        </fieldset>

        <div className="form-group">
          <label htmlFor="schoolsIds-1">
            <input
              id="schoolsIds-1"
              type="checkbox"
              name="schoolsIds-1"
              onChange={handleChange}
              checked={data.schoolsIds.includes(1)}
            />
            &nbsp;École Francaise
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="schoolsIds-2">
            <input
              id="schoolsIds-2"
              type="checkbox"
              name="schoolsIds-2"
              onChange={handleChange}
              checked={data.schoolsIds.includes(2)}
            />
            &nbsp;École Internationale
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-success btn-sm"
          disabled={formSave}
        >
          Sauvegarder
        </button>
      </form>
    </div>
  )
}

OptionEdit.propTypes = {}

export default OptionEdit
