// Views
import Login from "./Login"

import RolesList from "./Roles/RolesList"
import RolesEdit from "./Roles/RolesEdit"

import ClientsList from "./Clients/ClientsList"
import ClientsEdit from "./Clients/ClientsEdit/ClientsEdit"
import ClientsPlayers from "./Clients/ClientsPlayers"
import PlayersEdit from "./Clients/Players/PlayersEdit"

import Orders from "./Orders/Orders"

import OrdersList from "./Clients/Orders/OrdersList"
import ResolvePayment from "./Clients/Orders/ResolvePayment/ResolvePayment"
import ChoosePayment from "./Clients/Orders/ChoosePayment/ChoosePayment"

import ShuttlesList from "./Clients/Shuttles/ShuttlesList"
import ShuttlesEdit from "./Clients/Shuttles/ShuttlesEdit"
import Articles from "./Clients/Articles/Articles"

import AdminsList from "./Admins/AdminsList"
import AdminsEdit from "./Admins/AdminsEdit"

import TagsList from "./Tags/TagsList"
import TagsEdit from "./Tags/TagsEdit"

import EventsList from "./Events/EventsList"
import EventsEdit from "./Events/EventsEdit"

import ThemesList from "./Themes/ThemesList"
import ThemesEdit from "./Themes/ThemesEdit"

import QuestionsList from "./Questions/QuestionsList"
import QuestionEdit from "./Questions/QuestionEdit"

import TestimoniesList from "./Testimonies/TestimoniesList"
import TestimonyEdit from "./Testimonies/TestimonyEdit"

import MediasList from "./Medias/MediasList"

import SectionsAssignmentsList from "./Sections/Assignements/SectionsAssignmentsList"
import SectionsListingList from "./Sections/Listing/SectionsListingList"
import SectionsGroupsList from "./Sections/Groups/SectionsGroupsList"
import SectionsExtrasGroups from "./Sections/ExtrasGroups/SectionsExtrasGroups"

import HeadCoach from "./Reports/HeadCoach/HeadCoach"
import CoachReports from "./Reports/CoachReports/CoachReports"
import ReportEdit from "./Reports/ReportEdit/ReportEdit"
import ReportsList from "./Reports/ReportsList/ReportsList"

import ConfigEdit from "./Config/ConfigEdit"
import PackagesList from "./Packages/PackagesList"
import PackagesEdit from "./Packages/PackagesEdit"

import SeasonsList from "./Seasons/SeasonsList"

import CampsList from "./Products/Camps/CampsList"
import CampsEdit from "./Products/Camps/CampsEdit"
import ExtrasList from "./Products/Extras/ExtrasList"
import ExtrasEdit from "./Products/Extras/ExtrasEdit"
import AccommodationsList from "./Products/Accomodations/AccommodationsList"
import AccommodationsEdit from "./Products/Accomodations/AccommodationsEdit"

import PriceEdit from "./Products/PriceEdit"

import ExportLogsList from "./ExportLogs/ExportLogsList"

import LocalesList from "./Locales/LocalesList"
import LocalesEdit from "./Locales/LocalesEdit"

import DiscountsList from "./Discounts/DiscountsList"
import DiscountEdit from "./Discounts/DiscountsEdit"

import AttachmentsList from "./Attachments/AttachmentsList"
import AttachmentsEdit from "./Attachments/AttachmentsEdit"

import Stocks from "./Stocks/Stocks"
import Yields from "./Yields/Yields"
import Packages from "./Yields/Packages/Packages"

import BookingsList from "./Bookings/BookingsList"
import ExportGuest from "./Bookings/ExportGuest/ExportGuest"

import Intl from "./Intl/Intl"

import Export from "./Export/Export"

import StudentsIndex from "./SportStudies/se-index"

import StudentForms from "./StudentForms/StudentForms"

import FidAdmin from "./Fid/FidAdmin/FidAdmin"
import FidPoints from "./Fid/FidPoints/FidPoints"

import Optins from "./Optins/Optins"
import OptinsEdit from "./Optins/OptinsEdit"
import { ClientBalance } from "./Clients/Balance/ClientBalance"

export const routes = [
  {
    path: "/",
    component: Login,
    exact: true,
  },
]

export const protectedRoutes = [
  {
    path: "/roles",
    component: RolesList,
    exact: true,
  },
  {
    path: "/roles/edit/:id",
    component: RolesEdit,
    exact: true,
  },
  {
    path: "/roles/new",
    component: RolesEdit,
    exact: true,
  },
  {
    path: "/clients",
    component: ClientsList,
    exact: true,
  },
  {
    path: "/clients/new",
    component: ClientsEdit,
    exact: true,
  },
  {
    path: "/clients/edit/:id",
    component: ClientsEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN", "SALESPERSON", "SPORT_ETUDE"],
  },
  {
    path: "/client/:clientId/player/edit",
    component: PlayersEdit,
    exact: true,
  },
  {
    path: "/client/:clientId/player/edit/:playerId",
    component: PlayersEdit,
    exact: true,
  },
  {
    path: "/client/:id/players/list",
    component: ClientsPlayers,
    exact: true,
  },
  {
    path: "/client/:clientId/orders/list",
    component: OrdersList,
  },
  {
    path: "/client/:clientId/order/:orderId/resolve",
    component: ResolvePayment,
  },
  {
    path: "/client/:clientId/order/:orderId/payment-choice",
    component: ChoosePayment,
    exact: true,
  },
  {
    path: "/client/:clientId/shuttles/list",
    component: ShuttlesList,
    exact: true,
  },
  {
    path: "/client/:clientId/shuttles/edit/:shuttleId/:shuttleType",
    component: ShuttlesEdit,
    exact: true,
  },
  {
    path: "/client/:clientId/articles/list",
    component: Articles,
    exact: true,
  },
  {
    path: "/client/:clientId/balance",
    component: ClientBalance,
    exact: true,
  },
  {
    path: "/orders/list",
    component: Orders,
    exact: true,
  },
  {
    path: "/admins",
    component: AdminsList,
    exact: true,
  },
  {
    path: "/admins/new",
    component: AdminsEdit,
    exact: true,
  },
  {
    path: "/admins/edit/:id",
    component: AdminsEdit,
    exact: true,
  },
  {
    path: "/tags",
    component: TagsList,
    exact: true,
  },
  {
    path: "/tags/new",
    component: TagsEdit,
    exact: true,
  },
  {
    path: "/tags/edit/:id",
    component: TagsEdit,
    exact: true,
  },
  {
    path: "/events",
    component: EventsList,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/events/new",
    component: EventsEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/events/edit/:id",
    component: EventsEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/themes",
    component: ThemesList,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/themes/new",
    component: ThemesEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/themes/edit/:id",
    component: ThemesEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/questions",
    component: QuestionsList,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/questions/new",
    component: QuestionEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/questions/edit/:id",
    component: QuestionEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/testimonies",
    component: TestimoniesList,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/testimonies/new",
    component: TestimonyEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/testimonies/edit/:id",
    component: TestimonyEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/medias",
    component: MediasList,
    exact: true,
  },

  {
    path: "/sections/assignments",
    component: SectionsAssignmentsList,
    exact: true,
  },
  {
    path: "/sections/listing",
    component: SectionsListingList,
    exact: true,
  },
  {
    path: "/sections/listing/:section/:weekSelected",
    component: SectionsListingList,
    exact: true,
  },
  {
    path: "/sections/groups",
    component: SectionsGroupsList,
    exact: true,
  },
  {
    path: "/sections/groups/:section/:weekSelected",
    component: SectionsGroupsList,
    exact: true,
  },
  {
    path: "/sections/extras-groups",
    component: SectionsExtrasGroups,
    exact: true,
  },
  {
    path: "/reports/head-coach",
    component: HeadCoach,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/reports/coach/:id",
    component: CoachReports,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/reports/coach",
    component: CoachReports,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/reports/list",
    component: ReportsList,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/reports/edit/:id",
    component: ReportEdit,
    exact: true,
    role: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/packages",
    component: PackagesList,
    exact: true,
  },
  {
    path: "/packages/new",
    component: PackagesEdit,
    exact: true,
  },
  {
    path: "/packages/edit/:id",
    component: PackagesEdit,
    exact: true,
  },
  {
    path: "/config",
    component: ConfigEdit,
    exact: true,
  },
  {
    path: "/seasons/matrix",
    component: SeasonsList,
    exact: true,
  },
  {
    path: "/prices/edit/:productId",
    component: PriceEdit,
    exact: true,
  },
  {
    path: "/camps/list",
    component: CampsList,
    exact: true,
  },
  {
    path: "/camps/edit",
    component: CampsEdit,
    exact: true,
  },
  {
    path: "/camps/edit/:id",
    component: CampsEdit,
    exact: true,
  },
  {
    path: "/extras/list",
    component: ExtrasList,
    exact: true,
  },
  {
    path: "/extra/edit",
    component: ExtrasEdit,
    exact: true,
  },
  {
    path: "/extra/edit/:id",
    component: ExtrasEdit,
  },
  {
    path: "/accommodations/list",
    component: AccommodationsList,
    exact: true,
  },
  {
    path: "/accommodations/edit",
    component: AccommodationsEdit,
    exact: true,
  },
  {
    path: "/accommodations/edit/:id",
    component: AccommodationsEdit,
    exact: true,
  },
  {
    path: "/admin/exports",
    component: ExportLogsList,
    exact: true,
  },
  {
    path: "/locales/list",
    component: LocalesList,
    exact: true,
  },
  {
    path: "/locales/edit/:id",
    component: LocalesEdit,
    exact: true,
  },
  {
    path: "/discounts/list",
    component: DiscountsList,
    exact: true,
  },
  {
    path: "/discounts/edit",
    component: DiscountEdit,
    exact: true,
  },
  {
    path: "/discounts/edit/:id",
    component: DiscountEdit,
  },
  {
    path: "/attachments/list",
    component: AttachmentsList,
    exact: true,
  },
  {
    path: "/attachments/edit",
    component: AttachmentsEdit,
    exact: true,
  },
  {
    path: "/attachments/edit/:id",
    component: AttachmentsEdit,
    exact: true,
  },
  {
    path: "/stocks/matrix/:id",
    component: Stocks,
  },
  {
    path: "/yields/matrix/:id",
    component: Yields,
  },
  {
    path: "/yields/packages",
    component: Packages,
  },
  {
    path: "/bookings",
    component: BookingsList,
    exact: true,
  },
  {
    path: "/bookings/guest",
    component: ExportGuest,
    exact: true,
  },
  {
    path: "/intl/matrix",
    component: Intl,
    exact: true,
  },
  {
    path: "/export",
    component: Export,
    exact: true,
  },
  {
    path: "/sport-etude",
    component: StudentsIndex,
    exact: true,
  },
  {
    path: "/student-forms",
    component: StudentForms,
    exact: true,
  },
  {
    path: "/loyalty/points",
    component: FidPoints,
    exact: true,
  },
  {
    path: "/loyalty/admin",
    component: FidAdmin,
    exact: true,
  },
  {
    path: "/optins",
    component: Optins,
    exact: true,
  },
  {
    path: "/optins/new",
    component: OptinsEdit,
    exact: true,
  },
  {
    path: "/optins/edit/:id",
    component: OptinsEdit,
    exact: true,
  },
]
