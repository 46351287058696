// Hooks
import useApi from "./useApi"

// Utils & misc
import { COACH_HOSTNAME } from "../../constants/constants"

const useReportFollowUpApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getReportsListByCoach = (coachId) => request("GET", `${COACH_HOSTNAME}/v1/reports?coachId=${coachId}`)

  const getReports = (year) => request("GET", `${COACH_HOSTNAME}/v1/reports?year=${year}`);

  const getReport = (reportId) => request(
    "GET", 
    `${COACH_HOSTNAME}/v1/reports/${reportId}`,
    null,
    {
      axiosOptions: {
        responseType: "application/json",
        headers: { Accept: "application/json" },
      },
    }
  );

  const getReportPdf = (reportId) => request(
    "GET", 
    `${COACH_HOSTNAME}/v1/reports/${reportId}`,
    null,
    {
      axiosOptions: {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      },
    }
  );

  const putSaveReport = (reportId, report) => request(
    "PUT",
    `${COACH_HOSTNAME}/v1/reports/${reportId}`, report
  );

  const postDeliverReport = (reportId) => request(
    "POST",
    `${COACH_HOSTNAME}/v1/reports/${reportId}:deliver`
  );

  const postUpdateCoach = (reportId, coachId) => request(
    "POST",
    `${COACH_HOSTNAME}/v1/reports/${reportId}:update-coach`,
    {
      "coachId": coachId,
    }
  );

  return [
    requestState,
    payload,
    error,
    {
      getReportsListByCoach,
      getReport,
      getReportPdf,
      putSaveReport,
      postDeliverReport,
      postUpdateCoach,
      getReports,
    },
  ]
}

export default useReportFollowUpApi
