export const getTitleSection = section => {
  switch (section) {
    case "baby_tennis":
      return "Baby Tennis"
    case "weekend":
      return "Week-end"
    case "miami":
      return "Miami"
    case "indian_wells":
      return "Indian Wells"
    case "wimbledon":
      return "Wimbledon"
    case "us_open":
      return "US Open"
    case "australian_open":
      return "Australian Open"
    case "off_season":
      return "Off Season"
    case "roland_garros":
      return "Roland Garros"
    case "monte_carlo":
      return "Monte Carlo"
    case "night_session_18":
      return "Night Session - 18h30"
    case "night_session_20":
      return "Night Session - 20h00"
    case "undefined_section":
      return "Section non définie"
    default:
      return "Aucune section trouvé"
  }
}
export const getTitleRotation = section => {
  switch (section) {
    case "afternoon_first":
      return "Après-midi 1"
    case "afternoon_second":
      return "Après-midi 2"
    case "morning_first":
      return "Matin 1"
    case "morning_second":
      return "Matin 2"
    case "evening_first":
      return "Soir 1"
    case "evening_second":
      return "Soir 2"
    default:
      return "Aucune rotation trouvé"
  }
}
export const getRotationAuthorizedOrdinal = (rotationCode) => {
  switch (rotationCode) {
    case 'morning_first':
      return 1;
    case 'morning_second':
      return 1;
    case 'afternoon_first':
      return 2;
    case 'afternoon_second':
      return 2;
  }
}

export const flatSectionsList = (sections) => sections
  .filter((section) => section.sectionCode !== 'multisport' && section.sectionCode !== 'padel')
  .map((section) => ({
    code: section.code,
    sectionCode: section.sectionCode,
    title: getTitleSection(section.sectionCode),
  }))

export const mapFlatPlayer = (booking, optionnalKey = {}) =>
  booking && {
    ...booking,
    ...booking.player,
    name: `${booking.player.firstName} ${booking.player.lastName}`,
    ...optionnalKey,
  }

const sectionsMappedToGetPlayers = sections =>
  sections.map(section => ({
    ...section,
    reserve: [],
    weekSectionRotations: section.weekSectionRotations.map(
      weekSectionRotation => {
        return {
          ...weekSectionRotation,
          weekSectionRotationGroups: weekSectionRotation.weekSectionRotationGroups
            .sort((a, b) => a.ordinal - b.ordinal)
            .map(weekSectionRotationGroup => ({
              ...weekSectionRotationGroup,
              isOpen: false,
              players: [],
            })),
        }
      }
    ),
  }))

export const mapPlayersInSectionsAndGroups = (sections, players) => {
  if (!sections || !players) return []
  const mappedPlayers = players
    .reduce((acc, curr) => {
      if (curr.hasWeekSectionValidated)
        curr.sessions.forEach(session => acc.push({ ...curr, ...session, bookingId: curr.id }))
      return acc
    }, [])
    .reduce((sectionsReduced, player) => {
      const playerCodeSplited = (
        player?.weekSectionRotationGroupCode ||
        player?.weekSectionCode ||
        ""
      ).split(":")
      const playerFlated = mapFlatPlayer({
        ...player,
        weekSectionCode: player.weekSectionCode,
      })
      
      const indexSection = sectionsReduced.findIndex(section =>
        playerFlated?.weekSectionCode?.includes(section.code)
      )

      if ((playerCodeSplited.length === 2 || playerCodeSplited.length === 3) && indexSection >= 0 && sectionsReduced?.[indexSection]?.reserve) {
        sectionsReduced[indexSection].reserve.push(playerFlated)
      }
      if (playerCodeSplited.length === 4) {
        const indexRotation = sectionsReduced[
          indexSection
        ].weekSectionRotations.findIndex(rotation => {
          return rotation.code.includes(playerCodeSplited[2])
        })

        const indexGroup = sectionsReduced[indexSection].weekSectionRotations[
          indexRotation
        ].weekSectionRotationGroups.findIndex(group => {
          return group.code.split(":")[3] === playerCodeSplited[3]
        })

        sectionsReduced[indexSection].weekSectionRotations[
          indexRotation
        ].weekSectionRotationGroups[indexGroup].players.push(playerFlated)

        sectionsReduced[indexSection].weekSectionRotations[
          indexRotation
        ].weekSectionRotationGroups[indexGroup].isOpen = true
      }
      return sectionsReduced
    }, sectionsMappedToGetPlayers(sections))

  return mappedPlayers
}

// MAPPERS FOR SECTIONS LISTING

export const mapSectionsListing = (sections, isTabActive, weekSelected) =>
  sections.map((section, index) => ({
    ...section,
    isActive: isTabActive(section, index),
    players: [],
    url: "/sections/listing/" + section.sectionCode + '/' + weekSelected,
  }))
export const mapPlayersToSectionsListing = (sectionsMapped, players) =>
  players
  .filter(player => player.hasWeekSectionValidated)
  .reduce((sectionsReduced, booking, id) => {

    const indexSection = sectionsReduced.findIndex(
      section => section.code === booking.weekSectionCode
    )

    if (indexSection === -1) return sectionsReduced
    const isNotPlayerinSection = !sectionsReduced[indexSection]["players"].some(
      p => p.id === booking.id
    )
    if (isNotPlayerinSection)
      sectionsReduced[indexSection]["players"].push(
        mapFlatPlayer(booking, { isModified: false })
      )
    return sectionsReduced
  }, sectionsMapped)
