import React, { useState, useCallback, useEffect } from "react"
import { format, isAfter, parseISO } from "date-fns"

// Components
import RecapSection from "../FidPointsManager/RecapSection"
import GlobalMessage from "../../../../../atoms/GlobalMessage/GlobalMessage"
import Spinner from "../../../../../atoms/Spinner/Spinner"

// Hooks
import useAppContext from "../../../../../../hooks/useAppContext"
import usePermissionState from "../../../../../../hooks/usePermissionsState"
import useFidClientsApi from "../../../../../../hooks/Api/Fid/useFidClientsApi"

import useFidEmployees from "../../../../../../hooks/Api/Fid/useFidEmployees"
import useFidEstablishments from "../../../../../../hooks/Api/Fid/useFidEstablishments"
import useFidPrivileges from "../../../../../../hooks/Api/Fid/useFidPrivileges"

// Constants
import { PERMISSIONS } from "../../../../../../constants/constants"

// Utils
import cn from "../../../../../../utils/cn"
import style from "./FidPointsHistory.module.css"

const permissions = [PERMISSIONS.PERM_ADMIN_FULL_ACCESS]

const PrivilegeUsed = ({ privilege, historyEntry, handleCancelClick }) => {
  const [editMode, setEditMode] = useState(false)

  const handleClick = e => {
    e.preventDefault()
    setEditMode(false)
    handleCancelClick(privilege, historyEntry)
  }

  const handleResetEdit = e => {
    e.preventDefault()
    setEditMode(!editMode)
  }

  return (
    <div>
      <span>{`Utilisation privilège: ${privilege.name}`}</span>{" "}
      {historyEntry.isAlreadyCancelled === null ? (
        <span className="ml-4 float-right">
          {editMode ? (
            <b className={cn([style.confirmLabel])}>Êtes vous sûr ?</b>
          ) : null}
          <a
            className="text-danger"
            onClick={!editMode ? handleResetEdit : handleClick}
            href="/"
          >
            {editMode ? "Oui" : "Annuler"}
          </a>
          {editMode ? (
            <a
              className="btn btn-link pt-0 pb-0"
              onClick={handleResetEdit}
              href="/"
            >
              Non
            </a>
          ) : null}
        </span>
      ) : (
        <span className={cn(["float-right"])}>Restitué</span>
      )}
    </div>
  )
}

const PointsAdded = ({
  historyEntry,
  latestAdded,
  handleEditLastPoints,
  isFidAdmin,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [editedPointsValue, setEditedPointsValue] = useState(
    historyEntry.amount
  )

  const handleClick = e => {
    e.preventDefault()
    setEditMode(false)
    handleEditLastPoints(historyEntry, editedPointsValue)
  }

  const handleResetEdit = e => {
    e.preventDefault()
    setEditMode(!editMode)
  }

  return (
    <div>
      {!editMode || (historyEntry.datetime !== latestAdded && !isFidAdmin) ? (
        <span>{`${historyEntry.amount >= 0 ? "Ajout" : "Retrait"} de Point: ${
          historyEntry.amount
        } pts`}</span>
      ) : (
        <div className="form-check form-check-inline">
          <input
            className="form-control"
            type="number"
            name={`targetTypePointEdit${historyEntry.id}`}
            id={`targetTypePointEdit${historyEntry.id}`}
            onChange={e => setEditedPointsValue(Number(e.target.value))}
            value={editedPointsValue}
          />
        </div>
      )}
      {historyEntry.datetime === latestAdded || isFidAdmin ? (
        <span className="ml-4 float-right">
          <a
            className="text-primary"
            onClick={!editMode ? handleResetEdit : handleClick}
            href="/"
          >
            {editMode ? "Valider" : "Modifier"}
          </a>
          {editMode ? (
            <a className="text-primary ml-4" onClick={handleResetEdit} href="/">
              Annuler
            </a>
          ) : null}
        </span>
      ) : null}
    </div>
  )
}

const HISTORY_STATUS = {
  pointsAdded: (historyEntry, latestUsed, handleEditLastPoints, isFidAdmin) => (
    <PointsAdded
      historyEntry={historyEntry}
      latestAdded={latestUsed}
      handleEditLastPoints={handleEditLastPoints}
      isFidAdmin={isFidAdmin}
    />
  ),
  privilegeUsed: (priv, historyEntry, handleCancelClick) => (
    <PrivilegeUsed
      privilege={priv}
      historyEntry={historyEntry}
      handleCancelClick={handleCancelClick}
    />
  ),
  privilegeRefunded: priv => `Restitution privilège: ${priv}`,
  pointsReset: () => "Date de validité des points expirée : remise à zéro",
  pointsRestored: () => "P",
}

const FidPointsHistory = () => {
  // Context
  const {
    context: { fidActiveMember, fidEmployee, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionState(userInfo, permissions)

  // Data
  const [clientInfo, setClientInfo] = useState(null)
  const [clientHistory, setClientHistory] = useState(null)
  const [establishments, setEstablishments] = useState([])
  const [employees, setEmployees] = useState([])
  const [privileges, setPrivileges] = useState([])
  const [onlyDisplayPrivileges, setOnlyDisplayPrivileges] = useState(false)

  // API
  const [
    { infoOfClient, getClientHistory, cancelPrivilegeUse, editClientPoints },
  ] = useFidClientsApi()
  const [{ getFidEstablishments }] = useFidEstablishments()
  const [{ getFidEmployees }] = useFidEmployees()
  const [{ getFidPrivileges }] = useFidPrivileges()
  const infosOfClientMem = useCallback(infoOfClient, [])
  const getClientHistoryMem = useCallback(getClientHistory, [])
  const getFidEstablishmentsMem = useCallback(getFidEstablishments, [])
  const getFidEmployeesMem = useCallback(getFidEmployees, [])
  const getFidPrivilegesMem = useCallback(getFidPrivileges, [])

  // Misc
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const handleCRUDRequestError = useCallback(
    error => {
      if (error.response) {
        switch (error.response.status) {
          case 500: {
            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <span>Une erreur est survenue au moment de la soumission</span>
              ),
              className: "alert alert-danger",
            })
            break
          }
          case 400:
            {
              const errorsList =
                error.response.data && error.response.data.errors
                  ? Object.keys(error.response.data.errors).map(errorName => ({
                      field: errorName,
                      message: error.response.data.errors[errorName],
                    }))
                  : null

              setGlobalMessage({
                ...globalMessage,
                isActive: true,
                message: (
                  <ul className="m-0">
                    {errorsList
                      ? errorsList.map(err => (
                          <li>
                            <b>{err.field}: </b>
                            {err.message || "Erreur au niveau de ce champ"}
                          </li>
                        ))
                      : "Erreur au niveau du formulaire"}
                  </ul>
                ),
                className: "alert alert-danger",
              })
            }
            break
          default:
            break
        }
      }
    },
    [globalMessage]
  )

  const fetchInitialData = useCallback(async () => {
    const getListFromResponse = r => Object.keys(r).map(id => r[id])
    try {
      setClientInfo(null)
      setClientHistory(null)
      setEstablishments([])
      setEmployees([])
      setPrivileges([])
      const clientInfoResp = await infosOfClientMem(
        fidActiveMember.keycloakId,
        fidEmployee.id
      )
      const clientHistoryResp = await getClientHistoryMem({
        clientId: clientInfoResp.data.id,
      })
      const empResp = await getFidEmployeesMem()
      const estResp = await getFidEstablishmentsMem()
      const privResp = await getFidPrivilegesMem()

      setClientInfo(clientInfoResp.data)
      setClientHistory(getListFromResponse(clientHistoryResp.data.historyItems))
      setEstablishments(getListFromResponse(estResp.data.establishments))
      setEmployees(getListFromResponse(empResp.data.employees))
      setPrivileges(getListFromResponse(privResp.data.privileges))
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }, [
    fidActiveMember.keycloakId,
    fidEmployee.id,
    getClientHistoryMem,
    getFidEmployeesMem,
    getFidEstablishmentsMem,
    getFidPrivilegesMem,
    handleCRUDRequestError,
    infosOfClientMem,
  ])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const handleCancelPrivilege = async (privilege, historyEntry) => {
    try {
      await cancelPrivilegeUse(historyEntry.id, {
        employeeId: fidEmployee.id,
        clientId: clientInfo.id,
      })
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <b>Succès</b> Privilège restauré
          </span>
        ),
        className: "alert alert-success",
      })
      fetchInitialData()
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const handleEditLastPoints = async (historyEntry, amount) => {
    try {
      await editClientPoints(
        {
          id: historyEntry.id,
          amount,
          employeeId: fidEmployee.id,
        },
        fidActiveMember.keycloakId
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <b>Succès</b> Points mis à jour
          </span>
        ),
        className: "alert alert-success",
      })
      fetchInitialData()
    } catch (error) {
      //
    }
  }

  const displayStatus = historyEntry => {
    switch (historyEntry.type) {
      case "privilege_use": {
        const foundPriv = privileges.find(
          priv => priv.id === historyEntry.privilegeId
        )
        return HISTORY_STATUS.privilegeUsed(
          foundPriv,
          historyEntry,
          handleCancelPrivilege
        )
      }
      case "privilege_use_cancelled": {
        const foundPriv = privileges.find(
          priv => priv.id === historyEntry.privilegeId
        )
        return HISTORY_STATUS.privilegeRefunded(foundPriv.name)
      }
      case "point_add": {
        const latestUsed = clientHistory
          .filter(hist => hist.type === "point_add")
          .reduce((acc, val) => {
            if (!acc) return val.datetime
            return isAfter(new Date(acc), new Date(val.datetime))
              ? acc
              : val.datetime
          }, null)

        return HISTORY_STATUS.pointsAdded(
          historyEntry,
          latestUsed,
          handleEditLastPoints,
          rolePermissions[PERMISSIONS.PERM_ADMIN_FULL_ACCESS]
        )
      }
      case "cycle_reset_all": {
        return HISTORY_STATUS.pointsReset()
      }
      case "cycle_restore_all": {
        return HISTORY_STATUS.pointsRestored()
      }
      default:
        break
    }
  }

  return (
    <div>
      {/* MEMBER RECAP */}
      {clientInfo ? (
        <div>
          <div className="row">
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection
                label="Adresse email :"
                value={fidActiveMember.email}
              />
            </div>
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection label="Nom :" value={fidActiveMember.lastName} />
            </div>
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection
                label="Prénom :"
                value={fidActiveMember.firstName}
              />
            </div>
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection label="Status :" value={clientInfo.level} />
            </div>
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection
                label="Solde de points :"
                subLabel={`Jusqu'au ${format(
                  parseISO(clientInfo.resetPointsDate),
                  "dd/MM/yyyy"
                )}`}
                value={clientInfo.currentPoints}
              />
            </div>
            <div className={cn(["col-sm-6 col-md-4", style.infoSection])}>
              <RecapSection
                label="Points manquants pour niveau suivant :"
                value={clientInfo.pointsRequiredBeforeNextLevel}
              />
            </div>
          </div>
        </div>
      ) : null}

      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />

      {clientHistory &&
      establishments.length &&
      employees.length &&
      privileges.length ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-8 col-md-6 pb-6">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onChange={() =>
                      setOnlyDisplayPrivileges(!onlyDisplayPrivileges)
                    }
                    checked={onlyDisplayPrivileges}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    N'afficher que les privilèges
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 pb-12">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Jour</th>
                      <th>Heure</th>
                      <th>Employé</th>
                      <th>Établissement</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientHistory
                      .filter(historyEntry => {
                        if (!onlyDisplayPrivileges) return true
                        return (
                          historyEntry.type === "privilege_use" ||
                          historyEntry.type === "privilege_use_cancelled"
                        )
                      })
                      .map(historyEntry => (
                        <tr key={`history_entry_${historyEntry.datetime}`}>
                          <td>{`Le ${format(
                            parseISO(historyEntry.datetime),
                            "dd/MM/yyyy"
                          )}`}</td>
                          <td>
                            {format(
                              parseISO(historyEntry.datetime),
                              "HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {(() => {
                              const foundEmployee = employees.find(
                                emp => emp.id === historyEntry.employeeId
                              )
                              if (foundEmployee)
                                return `${foundEmployee.firstName} ${foundEmployee.lastName}`
                              return "Inconnu"
                            })()}
                          </td>
                          <td>
                            {(() => {
                              const foundEst = establishments.find(
                                est => est.id === historyEntry.establishmentId
                              )
                              if (foundEst) return `${foundEst.name}`
                              return "Inconnu"
                            })()}
                          </td>
                          <td>{displayStatus(historyEntry)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default FidPointsHistory
