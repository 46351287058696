// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useStocksApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getStocks = ({ minWeekId, maxWeekId, familyId }) =>
    request(
      "GET",
      `${API_STAGES_URL}/v1/stocks${
        minWeekId || maxWeekId || familyId
          ? `?minWeekId=${minWeekId}&maxWeekId=${maxWeekId}&familyId=${familyId}`
          : ""
      }`
    )

  const getStockWithId = stockId =>
    request("GET", `${API_STAGES_URL}/v1/stocks/${stockId}`)

  const updateStocks = stocks =>
    request("POST", `${API_STAGES_URL}/v1/stock/update`, stocks)

  const updateStock = stock =>
    request("PUT", `${API_STAGES_URL}/v1/stocks/${stock.id}`, stock)

  const createStock = stock =>
    request("POST", `${API_STAGES_URL}/v1/stocks`, stock)

  const getStocksExport = familyId => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "xlsx",
      },
    }

    return request(
      "GET",
      `${API_STAGES_URL}/v1/stocks/matrix/export/${familyId}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  const getYieldsExport = familyId => {
    const config = {
      responseType: "blob",
      headers: {
        Accept: "xlsx",
      },
    }

    return request(
      "GET",
      `${API_STAGES_URL}/v1/yields/matrix/export/${familyId}`,
      null,
      {
        axiosOptions: config,
      }
    )
  }

  return [
    requestState,
    payload,
    error,
    {
      getStocks,
      getStockWithId,
      updateStock,
      updateStocks,
      createStock,
      getStocksExport,
      getYieldsExport,
    },
  ]
}

export default useStocksApi
