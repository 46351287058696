import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

// Utils
import { getYear } from 'date-fns';
import Select from "react-select"

import styles from './StudentFormsFilters.module.css';
import { TextInput } from '../../../../components/atoms/Inputs/Inputs';

const currentYear = getYear(new Date())

const StudentFormsFilters = ({ filters, setFilters, yearSelected, setYearSelected }) => {
  const yearOptions = useMemo(() => (
    [currentYear - 1, currentYear, currentYear + 1].map(year => ({ label: `${year}/${year + 1}`, value: year }))
  ), []);

  const [searchName, setSearchName] = useState('');
  const [searchAmount, setSearchAmount] = useState('');

  return (
    <div className={styles.filter_wrapper}>
      <div className={styles.yearSelect}>
        <label>Année scolaire:</label>
        <Select
          value={yearSelected}
          onChange={(option) => setYearSelected(option)}
          options={yearOptions}
          placeholder="Semaine"
          noOptionsMessage={() => <span>Pas de résultats</span>}
        />
      </div>

      <div className={styles.filters}>
        <div className={styles.field}>
          <label>Statut du dossier</label>
          <Select
            value={filters.formState?.value}
            onChange={(option) => setFilters((prev) => ({ ...prev, formState: { ...prev.formState, value: option } }))}
            options={filters.formState?.options || []}
            placeholder="Sélectionnez..."
            noOptionsMessage={() => <span>Pas de résultats</span>}
            isMulti
            isClearable
          />
        </div>

        <div className={styles.field}>
          <label>Type de dossier</label>
          <Select
            value={filters.formType?.value}
            onChange={(option) => setFilters((prev) => ({ ...prev, formType: { ...prev.formType, value: option } }))}
            options={filters.formType?.options || []}
            placeholder="Sélectionnez..."
            noOptionsMessage={() => <span>Pas de résultats</span>}
            isMulti
            isClearable
          />
        </div>

        <div className={styles.field}>
          <label>Nom ou prénom de l'étudiant</label>
          <TextInput
            name="studentName"
            placeholder="Recherchez..."
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onBlur={(e) => {
              const inputValue = e.target.value;
              setFilters((prev) => ({ ...prev, studentName: { ...prev.studentName, value: inputValue } }));
            }}
          />
        </div>
        
        <div className={styles.field}>
          <label>Date de rentrée</label>
          <Select
            value={filters.startWeek?.value}
            onChange={(option) => setFilters((prev) => ({ ...prev, startWeek: { ...prev.startWeek, value: option } }))}
            options={filters.startWeek?.options || []}
            placeholder="Sélectionnez..."
            noOptionsMessage={() => <span>Pas de résultats</span>}
            isMulti
            isClearable
          />
        </div>

        <div className={styles.field}>
          <label>Statut de l'acompte</label>
          <Select
            value={filters.depositStatus?.value}
            onChange={(option) => setFilters((prev) => ({ ...prev, depositStatus: { ...prev.depositStatus, value: option } }))}
            options={filters.depositStatus?.options || []}
            placeholder="Sélectionnez..."
            noOptionsMessage={() => <span>Pas de résultats</span>}
            isClearable
          />
        </div>

        <div className={styles.field}>
          <label>Montant total</label>
          <TextInput
            name="amount"
            placeholder="Recherchez..."
            value={searchAmount}
            onChange={(e) => setSearchAmount(e.target.value)}
            onBlur={(e) => {
              const inputValue = e.target.value;
              setFilters((prev) => ({ ...prev, amount: { ...prev.amount, value: inputValue } }));
            }}
          />
        </div>
      </div>
    </div>
  )
}

StudentFormsFilters.propTypes = {
  filters: PropTypes.shape({}),
  setFilters: PropTypes.func,
  yearSelected: PropTypes.shape({}),
  setYearSelected: PropTypes.func,
}

StudentFormsFilters.defaultProps = {
  filters: {},
  setFilters: () => {},
  yearSelected: null,
  setYearSelected: () => {},
}

export default StudentFormsFilters