// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"
import objectToURLParam from "../../utils/objectToURLParam"

const useAccommodationsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getAccommodations = options => {
    const params = options ? objectToURLParam(options) : ""
    return request("GET", `${API_STAGES_URL}/v1/accommodations${params}`)
  }

  const getAccommodationWithId = accommodationsId =>
    request("GET", `${API_STAGES_URL}/v1/accommodations/${accommodationsId}`)

  const updateAccommodation = accommodations =>
    request(
      "PUT",
      `${API_STAGES_URL}/v1/accommodations/${accommodations.id}`,
      accommodations
    )

  const createAccommodation = accommodations =>
    request("POST", `${API_STAGES_URL}/v1/accommodations`, accommodations)

  const getAccommodationConstraints = () =>
    request("GET", `${API_STAGES_URL}/v1/accommodation-constraints`)

  return [
    requestState,
    payload,
    error,
    {
      getAccommodations,
      getAccommodationWithId,
      updateAccommodation,
      createAccommodation,
      getAccommodationConstraints,
    },
  ]
}

export default useAccommodationsApi
