import React, { useState } from "react"
import { saveAs } from "file-saver"

// Components
import Spinner from "../../../../components/atoms/Spinner/Spinner"

// Hooks
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

const Exports = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => i + startYear)?.map((y) => y.toString());

  const [, , , { getDocument }] = useSportStudiesApi()
  const [exportsLoading, setExportsLoading] = useState(years.map(() => false))

  const handleExport = async year => {
    const yearIndex = years.findIndex(y => y === year)

    setExportsLoading(exportsLoading.map((y, i) => i === yearIndex))

    try {
      const file = await getDocument({ year, stateId: "5" }, "application/zip")
      saveAs(file.data, `campus_export.zip`)
      setExportsLoading(
        exportsLoading.map((y, i) => (i === yearIndex ? false : y))
      )
    } catch (error) {
      console.error(error)
      setExportsLoading(
        exportsLoading.map((y, i) => (i === yearIndex ? false : y))
      )
    }
  }

  return (
    <>
      <h1>Exports</h1>
      <div className="row">
        <div className="col">
          <table>
            <thead>
              <tr>
                <th />
                {years.map(year => (
                  <th key={year}>
                    {year} / {+year + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pr-4">Tous les dossiers ouverts</td>
                {years.map((year, index) => {
                  return (
                    <td key={year}>
                      <button
                        disabled={exportsLoading[index]}
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleExport(year)}
                      >
                        Télécharger
                        {exportsLoading[index] && <Spinner />}
                      </button>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

Exports.propTypes = {}

export default Exports
