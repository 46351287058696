import React from "react"
import PropTypes from "prop-types"

const range = (from, to, step = 1) => {
  let i = from
  const innerRange = []

  while (i <= to) {
    innerRange.push(i)
    i += step
  }

  return innerRange
}

const LEFT_PAGE = "LEFT"
const RIGHT_PAGE = "RIGHT"

const Pagination = ({
  previousPage,
  nextPage,
  gotoPage,
  pageIndex,
  pageOptions,
}) => {
  const totalPages = pageOptions.length
  const currentPage = pageIndex + 1
  const pageNeighbours = 2

  const pages = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let basePages = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          basePages = [LEFT_PAGE, ...extraPages, ...basePages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          basePages = [...basePages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          basePages = [LEFT_PAGE, ...basePages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...basePages, totalPages]
    }

    return range(1, totalPages)
  }

  return (
    <nav aria-label="Countries Pagination">
      <ul className="pagination">
        {pages().map(page => {
          if (page === LEFT_PAGE) {
            return (
              <li key={page} className="page-item">
                <button
                  type="button"
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={previousPage}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
            )
          }

          if (page === RIGHT_PAGE) {
            return (
              <li key={page} className="page-item">
                <button
                  type="button"
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={nextPage}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            )
          }

          return (
            <li
              key={page}
              className={`page-item${currentPage === page ? " active" : ""}`}
            >
              <button
                type="button"
                className="page-link"
                href="#"
                onClick={() => gotoPage(page - 1)}
              >
                {page}
              </button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageOptions: PropTypes.array.isRequired,
}

export default Pagination
