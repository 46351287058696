import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { useFormik } from "formik"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import ClientNavigation from "../../../components/molecules/ClientNavigation/ClientNavigation"
import Modal from "../../../components/molecules/Modal"
import GlobalMessage from "../../../components/atoms/GlobalMessage/GlobalMessage"

// Hooks
import useClientsApi from "../../../hooks/Api/useClientsApi"
import usePlayersApi from "../../../hooks/Api/usePlayersApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./ClientsPlayers.module.css"

const permissions = [
  PERMISSIONS.PERM_PLAYERS_DISPLAY,
  PERMISSIONS.PERM_PLAYERS_CREATE,
  PERMISSIONS.PERM_PLAYERS_CREATE,
  PERMISSIONS.PERM_PLAYERS_DISPLAY_BORROWERS,
  PERMISSIONS.PERM_PLAYERS_DELETE_BORROWER,
  PERMISSIONS.PERM_PLAYERS_SEND_LINK_EMAIL,
]

const BorrowerCard = ({ onDelete, borrower, playerId, rolePermissions }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  return (
    <div className="col-md-6 col-sm-12">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {`${borrower.firstname} ${borrower.lastname}`}
          </h5>
          <p className="card-text">{borrower.email}</p>
          {rolePermissions[PERMISSIONS.PERM_PLAYERS_DELETE_BORROWER] ? (
            <button
              onKeyDown={null}
              tabIndex={[0]}
              type="button"
              className="btn btn-danger btn-sm mt-"
              onClick={
                deleteConfirm
                  ? () => onDelete(playerId, borrower)
                  : () => setDeleteConfirm(true)
              }
            >
              {deleteConfirm ? "Confirmer" : "Supprimer"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const ClientsPlayers = ({ match }) => {
  const {
    context: { userInfo, locales },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const { state } = useLocation()

  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })
  const [borrowerMessage, setBorrowerMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [, clientData, clientError, { getClientWithId }] = useClientsApi()
  const [, , , { getClientWithId: getClientWithIdLink }] = useClientsApi()

  const [
    getPlayersState,
    playersData,
    playersError,
    { getClientsPlayers },
  ] = usePlayersApi()
  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetClientsPlayers = useCallback(getClientsPlayers, [])

  const [, , , { sendLinkEmail }] = usePlayersApi()
  const [, , , { deleteBorrower }] = usePlayersApi()

  const [sendLinkEmailModalOpen, setSendLinkEmailModalOpen] = useState(false)
  const [checkBorrowersModalOpen, setCheckBorrowersModalOpen] = useState(false)
  const [linkEmailData, setLinkEmailData] = useState(null)
  const [borrowerData, setBorrowerData] = useState({
    borrowers: [],
    player: null,
  })

  const {
    values: linkEmailValues,
    handleChange: handleLinkEmailChange,
    setFieldValue: setLinkEmailFieldValue,
    handleSubmit: handleLinkEmailSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      localeId: 1,
    },
    onSubmit: async submitValues => {
      try {
        const { playerId } = linkEmailData
        await sendLinkEmail(playerId, submitValues)
        setSendLinkEmailModalOpen(false)
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> L'email de connexion à été envoyé.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        setSendLinkEmailModalOpen(false)
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: <span>Une erreur est survenue</span>,
          className: "alert alert-danger",
        })
      }
    },
  })

  const handleSendEmailOpen = (playerId, firstName, lastName, email) => {
    setLinkEmailData({
      playerId,
      firstName,
      lastName,
      email,
    })
    setLinkEmailFieldValue("email", email)
    setSendLinkEmailModalOpen(true)
  }

  const handleCheckBorrowersOpen = async (player, borrowerIds) => {
    setCheckBorrowersModalOpen(true)
    try {
      const clients = await Promise.all(
        borrowerIds.map(id => getClientWithIdLink(id))
      )
      setBorrowerData({ borrowers: clients.map(resp => resp.data), player })
    } catch (error) {
      console.error(error)
    }
  }

  const handleBorrowerDeletion = async (playerId, borrower) => {
    try {
      await deleteBorrower(playerId, { borrowerId: borrower.id })
      setBorrowerData({
        ...borrowerData,
        borrowers: borrowerData.borrowers.filter(b => b.id !== borrower.id),
      })
      savedGetClientWithId(match.params.id)
      savedGetClientsPlayers(match.params.id)
      setBorrowerMessage({
        ...borrowerMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong> Liaison supprimée.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    savedGetClientWithId(match.params.id)
    savedGetClientsPlayers(match.params.id)
  }, [savedGetClientWithId, savedGetClientsPlayers, match.params.id])

  const playersCollectionToArray = () =>
    playersData
      ? Object.keys(playersData.players).map(i => playersData.players[i])
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          Clients
          {clientData
            ? ` / ${clientData.firstname} ${clientData.lastname} `
            : null}
          / Joueurs
        </h1>
        {rolePermissions[PERMISSIONS.PERM_PLAYERS_CREATE] ? (
          <Link
            className="btn btn-success"
            to={`/client/${match.params.id}/player/edit`}
          >
            Nouveau Joueur
          </Link>
        ) : null}
      </div>
      <ClientNavigation client={{ ...clientData, uid: match.params.id }} />
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={() => setGlobalMessage({ ...globalMessage, isActive: false })}
      />
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {clientError || playersError ? (
        <div className="alert alert-error" role="alert">
          {clientError || playersError}
        </div>
      ) : null}
      {getPlayersState === "loading" && !playersData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_PLAYERS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_PLAYERS_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                },
                {
                  Header: "Prénom",
                  accessor: "firstname",
                },
                {
                  Header: "Nom",
                  accessor: "lastname",
                },
                {
                  Header: "Commentaires",
                  accessor: "comment",
                },
                {
                  Header: "",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        original: {
                          uid,
                          firstname,
                          lastname,
                          email,
                          borrowerClientsIds,
                        },
                      },
                    },
                  }) => (
                    <div>
                      <Link
                        className="btn btn-secondary btn-sm"
                        to={`/client/${match.params.id}/player/edit/${uid}`}
                      >
                        Éditer
                      </Link>
                      {rolePermissions[
                        PERMISSIONS.PERM_PLAYERS_SEND_LINK_EMAIL
                      ] ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm ml-4"
                            onClick={() =>
                              handleSendEmailOpen(
                                uid,
                                firstname,
                                lastname,
                                email
                              )
                            }
                          >
                            Envoyer un email de connexion
                          </button>
                          {borrowerClientsIds &&
                          borrowerClientsIds.length > 0 ? (
                            <button
                              className="btn btn-secondary btn-sm ml-2"
                              type="button"
                              onKeyDown={null}
                              onClick={() =>
                                handleCheckBorrowersOpen(
                                  { uid, firstname, lastname },
                                  borrowerClientsIds
                                )
                              }
                            >
                              Consulter les liaisons
                            </button>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  ),
                },
              ]}
              data={playersCollectionToArray()}
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
      <Modal
        isDisplayed={checkBorrowersModalOpen}
        onClose={() => setCheckBorrowersModalOpen(false)}
      >
        <GlobalMessage
          isActive={borrowerMessage.isActive}
          content={borrowerMessage.message}
          className={borrowerMessage.className}
          onClose={() =>
            setBorrowerMessage({ ...borrowerMessage, isActive: false })
          }
        />
        {borrowerData?.borrowers && borrowerData?.player ? (
          <>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <h4>{`Liaisons de ${borrowerData?.player?.firstname} ${borrowerData?.player?.lastname}`}</h4>
              </div>
            </div>
            <div className="row mt-4">
              {borrowerData.borrowers.map(borrower => (
                <BorrowerCard
                  playerId={borrowerData?.player?.uid}
                  borrower={borrower}
                  onDelete={handleBorrowerDeletion}
                  rolePermissions={rolePermissions}
                />
              ))}
            </div>
          </>
        ) : null}
      </Modal>
      <Modal
        isDisplayed={sendLinkEmailModalOpen}
        onClose={() => setSendLinkEmailModalOpen(false)}
      >
        {linkEmailData && locales ? (
          <form onSubmit={handleLinkEmailSubmit}>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <h4>{`${linkEmailData.firstName} ${linkEmailData.lastName}`}</h4>
              </div>
            </div>
            <div className="row justify-content-md-center mt-4">
              <div className="col-md-10">
                <div className="form-group">
                  <label htmlFor="linkEmail">
                    Veuillez confirmer l'email de l'utilisateur
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="linkEmail"
                    name="email"
                    aria-describedby="linkEmail"
                    placeholder="Email"
                    onChange={handleLinkEmailChange}
                    value={linkEmailValues.email}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-md-center mt-2">
              <div className="col-md-10">
                <div className="form-group">
                  <label htmlFor="linkEmail">Langue email</label>
                  <select
                    value={linkEmailValues.localeId}
                    onChange={e =>
                      setLinkEmailFieldValue("localeId", Number(e.target.value))
                    }
                    className="form-control"
                  >
                    {Object.keys(locales)
                      .map(localeId => locales[localeId])
                      .map(locale => (
                        <option key={locale.id} value={locale.id}>
                          {locale.code}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 text-center">
                <button className="btn btn-primary" type="submit">
                  Envoyer l'email
                </button>
              </div>
            </div>
          </form>
        ) : null}
      </Modal>
    </WithMenu>
  )
}

ClientsPlayers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ClientsPlayers
