import React, { useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Table from "../../../../components/organisms/Table"
import ClientNavigation from "../../../../components/molecules/ClientNavigation/ClientNavigation"

// Hooks
import useClientShuttlesApi from "../../../../hooks/Api/useClientShuttlesApi"
import useWeeksApi from "../../../../hooks/Api/useWeeksApi"
import useArticlesApi from "../../../../hooks/Api/useArticlesApi"
import useProductsApi from "../../../../hooks/Api/useProductsApi"
import useClientsApi from "../../../../hooks/Api/useClientsApi"

// Utils
import cn from "../../../../utils/cn"

import style from "./ShuttlesList.module.css"

const ShuttlesList = ({ match }) => {
  const { state } = useLocation()
  const [
    getShuttlesState,
    shuttlesData,
    shuttlesError,
    { getClientShuttlesFromClient },
  ] = useClientShuttlesApi()
  const [, , , { getClientShuttlesArray }] = useClientShuttlesApi()

  const [, clientData, , { getClientWithId }] = useClientsApi()
  const [, weeksData, , { getWeeks }] = useWeeksApi()
  const [, articlesData, , { getArticlesFromClient }] = useArticlesApi()
  const [, productsData, , { getProducts }] = useProductsApi()

  const [clientShuttlesTable, setClientShuttlesTable] = useState([])

  const savedGetShuttles = useCallback(getClientShuttlesFromClient, [])
  const savedGetWeeks = useCallback(getWeeks, [])
  const savedGetArticles = useCallback(getArticlesFromClient, [])
  const savedGetProducts = useCallback(getProducts, [])
  const savedGetClientWithId = useCallback(getClientWithId, [])
  const savedGetClientShuttlesArray = useCallback(getClientShuttlesArray, [])

  useEffect(() => {
    savedGetShuttles(match.params.clientId)
    savedGetArticles(match.params.clientId)
    savedGetClientWithId(match.params.clientId)
    savedGetProducts()
    savedGetWeeks()
  }, [
    savedGetShuttles,
    match.params.clientId,
    savedGetArticles,
    savedGetClientWithId,
    savedGetProducts,
    savedGetWeeks,
  ])

  useEffect(() => {
    if (
      shuttlesData &&
      shuttlesData.clientsShuttles &&
      articlesData &&
      productsData &&
      articlesData.articles
    ) {
      if (productsData && productsData.products) {
        savedGetClientShuttlesArray(
          Object.keys(shuttlesData.clientsShuttles)
        ).then(val => {
          const shuttlesTable = val.map((shuttle, i) => {
            const shuttleId = Object.keys(shuttlesData.clientsShuttles)[i]
            const s = shuttlesData.clientsShuttles[shuttleId]
            const article = articlesData.articles[s.articleId]
            const product = productsData.products[article.productId]
            return {
              ...shuttle.data,
              shuttleId,
              article,
              product,
            }
          })
          setClientShuttlesTable(shuttlesTable)
        })
      }
    }
    return () => {}
  }, [shuttlesData, articlesData, productsData, savedGetClientShuttlesArray])

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>
          {clientData
            ? `${clientData.firstname} ${clientData.lastname} / Navettes`
            : ""}
        </h1>
      </div>
      <ClientNavigation
        client={{ ...clientData, uid: match.params.clientId }}
      />
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {shuttlesError ? (
        <div className="alert alert-error" role="alert">
          {shuttlesError}
        </div>
      ) : null}
      {getShuttlesState === "loading" &&
      !shuttlesData &&
      !weeksData &&
      !articlesData &&
      !productsData &&
      clientShuttlesTable.length ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Table
          columns={[
            {
              Header: "Extra",
              accessor: "tag0",
              Cell: ({
                cell: {
                  row: {
                    original: { product },
                  },
                },
              }) => <div>{product.name["1"]}</div>,
            },
            {
              Header: "Semaine du",
              accessor: "tag1",
              Cell: ({
                cell: {
                  row: {
                    original: { article },
                  },
                },
              }) => <div>{weeksData.weeks[article.weekId].start}</div>,
            },
            {
              Header: "Pick Up Infos",
              accessor: "tag2",
              Cell: ({
                cell: {
                  row: {
                    original: { typeId, shuttleId },
                  },
                },
              }) =>
                typeId === 1 ? (
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Link
                      className={cn(["btn btn-secondary"])}
                      to={`/client/${
                        match.params.clientId
                      }/shuttles/edit/${shuttleId.substr(1)}/${typeId}`}
                    >
                      Éditer
                    </Link>
                  </div>
                ) : null,
            },
            {
              Header: "Drop Off Infos",
              accessor: "tag3",
              Cell: ({
                cell: {
                  row: {
                    original: { typeId, shuttleId },
                  },
                },
              }) =>
                typeId === 2 ? (
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Link
                      className={cn(["btn btn-secondary"])}
                      to={`/client/${
                        match.params.clientId
                      }/shuttles/edit/${shuttleId.substr(1)}/${typeId}`}
                    >
                      Éditer
                    </Link>
                  </div>
                ) : null,
            },
          ]}
          data={clientShuttlesTable}
          hasPagination
          hasSort
        />
      )}
    </WithMenu>
  )
}

ShuttlesList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default ShuttlesList
