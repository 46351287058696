import React, { useState } from "react"
import { saveAs } from "file-saver"
import PropTypes from "prop-types"

// Hooks
import useInvoiceApi from "../../../hooks/Api/useInvoiceApi"

// Utils
import cn from "../../../utils/cn"

import style from "./InvoiceExportLanguage.module.css"

const InvoiceExportLanguage = ({ locales, invoiceId, invoiceName }) => {
  const [languageSelected, setLanguageSelected] = useState("")

  const [, , , { getInvoiceLanguage }] = useInvoiceApi()

  const handleLanguageChange = e => {
    setLanguageSelected(e.target.value)
  }

  const handleExportClick = async () => {
    try {
      const file = await getInvoiceLanguage(invoiceId, languageSelected)

      saveAs(file.data, `${invoiceName}_${locales[languageSelected].code}.pdf`)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div
      className={cn(["input-group input-group-sm", style.invoiceExportSelect])}
    >
      <select
        className="custom-select"
        id="invoiceLanguageSelect"
        aria-label="Example select with button addon"
        onChange={handleLanguageChange}
        value={languageSelected}
      >
        <option disabled value="">
          Langue
        </option>
        {Object.keys(locales).map(lang => (
          <option key={lang} value={lang}>
            {locales[lang].code}
          </option>
        ))}
      </select>
      <div className="input-group-append">
        <button
          disabled={!languageSelected}
          onClick={handleExportClick}
          className={cn([
            "btn",
            languageSelected ? "btn-primary" : "btn-outline-primary",
          ])}
          type="button"
        >
          Exporter
        </button>
      </div>
    </div>
  )
}

InvoiceExportLanguage.propTypes = {
  invoiceName: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  locales: PropTypes.shape().isRequired,
}

export default InvoiceExportLanguage
