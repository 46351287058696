/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { useFormik } from "formik"
import { format } from "date-fns"
import { saveAs } from "file-saver"

// Hooks
import useFidClientsApi from "../../../../../hooks/Api/Fid/useFidClientsApi"

// Utils
import cn from "../../../../../utils/cn"

const FidAdminLogs = () => {
  const [{ logClientsHistory }] = useFidClientsApi()
  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: { startDatetime: "", endDatetime: "" },
    onSubmit: async subValues => {
      try {
        const body = {
          startDatetime: format(
            new Date(subValues.startDatetime),
            "yyyy-MM-dd HH:mm:ss"
          ),
          endDatetime: format(
            new Date(subValues.endDatetime),
            "yyyy-MM-dd HH:mm:ss"
          ),
        }
        const file = await logClientsHistory(body)
        saveAs(file.data, `fid_export.csv`)
      } catch (error) {
        //
      }
    },
  })

  return (
    <div className={cn(["card mb-4"])}>
      <div className="card-body">
        <h5 className="card-title mb-4">Derniers ajouts de points</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group mr-4">
              <input
                type="datetime-local"
                className="form-control"
                id="startDatetime"
                name="startDatetime"
                value={values.startDatetime}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="datetime-local"
                className="form-control"
                id="endDatetime"
                name="endDatetime"
                value={values.endDatetime}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <button
              type="submit"
              className="btn btn-primary float-right"
              disabled={
                !(values.startDatetime !== "" && values.endDatetime !== "")
              }
            >
              Exporter
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

FidAdminLogs.propTypes = {}
FidAdminLogs.defaultProps = {}

export default FidAdminLogs
