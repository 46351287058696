import React, { useEffect, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"

// Hooks
import useAttachmentsApi from "../../../hooks/Api/useAttachmentsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

import style from "./AttachmentsList.module.css"

const permissions = [
  PERMISSIONS.PERM_ATTACHMENTS_DISPLAY,
  PERMISSIONS.PERM_ATTACHMENTS_CREATE,
  PERMISSIONS.PERM_ATTACHMENTS_UPDATE,
]

const AttachmentsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [
    attachmentsRequestState,
    attachmentsData,
    attachmentsError,
    { getAttachments },
  ] = useAttachmentsApi({
    trigger: true,
  })
  const savedGetAttachments = useCallback(getAttachments, [])
  const { state } = useLocation()

  useEffect(() => {
    savedGetAttachments()
  }, [savedGetAttachments])

  const attachmentsCollectionToArray = () =>
    attachmentsData && attachmentsData.attachments
      ? Object.keys(attachmentsData.attachments).map(
          i => attachmentsData.attachments[i]
        )
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <h1>Attachments</h1>
        {rolePermissions[PERMISSIONS.PERM_ATTACHMENTS_CREATE] ? (
          <Link className="btn btn-success" to="/attachments/edit">
            Nouvelle Brochure
          </Link>
        ) : null}
      </div>

      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}

      {attachmentsError ? (
        <div className="alert alert-attachmentsError" role="alert">
          {attachmentsError}
        </div>
      ) : null}
      {attachmentsRequestState === "loading" && !attachmentsData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_ATTACHMENTS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_ATTACHMENTS_UPDATE] ? (
            <Table
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Nom",
                  accessor: "name",
                },
                {
                  Header: "",
                  accessor: "tag",
                  Cell: ({
                    cell: {
                      row: {
                        values: { id },
                      },
                    },
                  }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Edit brochure"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/attachments/edit/${id}`}
                      >
                        Éditer
                      </Link>
                    </div>
                  ),
                },
              ]}
              data={attachmentsCollectionToArray()}
              hasPagination
              hasSort
            />
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default AttachmentsList
