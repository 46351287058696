// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useFormatsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getFormats = () => request("GET", `${API_STAGES_URL}/v1/formats`)

  const createFormat = format =>
    request("POST", `${API_STAGES_URL}/v1/formats`, format)

  return [
    requestState,
    payload,
    error,
    {
      getFormats,
      createFormat,
    },
  ]
}

export default useFormatsApi
