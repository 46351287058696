import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { SelectInput } from '../../../../../components/atoms/Inputs/Inputs'

// Style
import style from './FiltersSection.module.css'

// utils & misc
import { staticGenders, staticBirthdates, staticGroupsColor } from '../../../../../utils/coach/staticValues'
import { format, parse } from 'date-fns'


const iniStateFilters = {
  birthYear: null,
  gender: null,
  color: null,
};

const FiltersSection = ({ updateFilters, isCleanFilters, bookings }) => {

  const [filters, setFilters] = useState(iniStateFilters)

  const [playersCountByYear, setPlayersCountByYear] = useState({});

  const handleFilter = (filter, evt) =>{
    setFilters({...filters, [filter]: evt.target.value !== '' ? evt.target.value : null})
    updateFilters({...filters, [filter]: evt.target.value !== '' ? evt.target.value : null})
  };

  useEffect(() => {
    if(isCleanFilters) {
      setFilters(iniStateFilters)
      updateFilters(iniStateFilters)
    }
  }, [isCleanFilters])

  useEffect(() => {
    if (bookings?.length) {
      const yearsCount = {};
      bookings.forEach((booking) => {
        if (booking.player?.birthdate) {
          const parsedDate = parse(booking.player.birthdate, 'yyyy-MM-dd', new Date());
          const year = parsedDate ? format(parsedDate, 'yyyy') : null;
          if (year) yearsCount[year] = yearsCount[year] ? yearsCount[year] + 1 : 1;
        }
      });

      setPlayersCountByYear(yearsCount);
    }
  }, [bookings]);

  return (
    <div className={style['filters-section']} >  
      <SelectInput
        id="birthYear-selector-filter"
        className="birthYear-selector" 
        value={filters.birthYear} 
        onChange={(evt) => handleFilter('birthYear', evt)}
        name="birthYearSelector"
        options={[
          <option key="default" value="">
            Année de naissance
          </option>,
          staticBirthdates.map(year => (
            <option key={year} value={year}>
              {playersCountByYear && playersCountByYear[year] ? `${year} (${playersCountByYear[year]})` : year}
            </option>
          ))
        ]}
        /> 
      <SelectInput
        id="gender-selector-filter"
        className="gender-selector" 
        value={filters.gender} 
        onChange={(evt) => handleFilter('gender', evt)} 
        name="genderSelector"
        options={[
          <option key="default" value="">
            Sexe
          </option>,
          staticGenders.map(gender => (
            <option key={gender.code} value={gender.code}>
              {gender.title}
            </option>
          ))
        ]}
        />
      <SelectInput
        id="color-selector-filter"
        className="gender-selector" 
        value={filters.color} 
        onChange={(evt) => handleFilter('color', evt)} 
        name="colorSelector"
        options={[
          <option key="default" value="">
            Code couleurs
          </option>,
          staticGroupsColor.map(color => (
            <option key={color.code} value={color.code} style={{backgroundColor: color.backgroundColor, color: color.color}}>
              {color.code}
            </option>
          ))
        ]}
        />
    </div>
  )
}

FiltersSection.propTypes = {
  isCleanFilters: PropTypes.bool,
  updateFilters: PropTypes.func,
  bookings: PropTypes.arrayOf(PropTypes.any),
}

export default FiltersSection