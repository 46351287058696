// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const usePackagesApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getPackages = () => request("GET", `${API_STAGES_URL}/v1/packages`)

  const getPackageWithId = packageId =>
    request("GET", `${API_STAGES_URL}/v1/packages/${packageId}`)

  const reorderPackage = (packageId, newPrecedingPackageId) =>
    request("POST", `${API_STAGES_URL}/v1/packages:reorder`, {
      reorderedPackageId: packageId,
      newPrecedingPackageId,
    })

  const updatePackage = data =>
    request("PUT", `${API_STAGES_URL}/v1/packages/${data.id}`, data)

  const updatePackages = data =>
    request("PUT", `${API_STAGES_URL}/v1/packages`, { packages: data })

  const createPackage = data =>
    request("POST", `${API_STAGES_URL}/v1/packages`, data)

  const archivePackage = (id) => request('POST', `${API_STAGES_URL}/v1/packages/${id}:archive`);

  return [
    requestState,
    payload,
    error,
    {
      getPackages,
      getPackageWithId,
      reorderPackage,
      updatePackage,
      updatePackages,
      createPackage,
      archivePackage,
    },
  ]
}

export default usePackagesApi
