// Hooks
import useApi from "../useApi"

// Utils & misc
import { FID_HOSTNAME } from "../../../constants/constants"
import objectToURLParam from "../../../utils/objectToURLParam"

const useFidEstablishments = () => {
  const [, , , , rawRequest] = useApi()

  const AUTHORIZATION = "X-Authorization"
  const BASE_URL = `https://${FID_HOSTNAME}/api/v1`

  const getFidEstablishments = options => {
    const params = options ? objectToURLParam(options) : ""
    return rawRequest("GET", `${BASE_URL}/establishments${params}`, null, {
      authKey: AUTHORIZATION,
    })
  }

  const getMyFidEstablishment = () =>
    rawRequest("GET", `${BASE_URL}/establishments/mine`, null, {
      authKey: AUTHORIZATION,
    })

  const createFidEstablishment = est =>
    rawRequest("POST", `${BASE_URL}/establishments`, est, {
      authKey: AUTHORIZATION,
    })

  const updateFidEstablishment = (estId, est) =>
    rawRequest("PUT", `${BASE_URL}/establishments/${estId}`, est, {
      authKey: AUTHORIZATION,
    })

  return [
    {
      getFidEstablishments,
      getMyFidEstablishment,
      updateFidEstablishment,
      createFidEstablishment,
    },
  ]
}

export default useFidEstablishments
