import { isEmpty } from "lodash"
import { MENU_ENTRIES } from "../constants/constants"

const getUserPermissions = userInfo =>
  Object.keys(userInfo.resource_access).reduce((acc, val) => {
    const roles = acc.slice(0)
    const permList = userInfo.resource_access[val].roles

    return [...roles, ...permList]
  }, [])

const isMenuEntryAllowed = (userInfo, entry) => {
  const permissions = getUserPermissions(userInfo)

  if (entry.permissions.length === 0) return true
  if (
    permissions.find(perm => {
      const found = entry.permissions.find(entryPerm => entryPerm === perm)
      return found !== undefined
    }) !== undefined
  )
    return true
  return false
}

export const filterMenuEntries = userInfo => {
  let updatedEntries = [...MENU_ENTRIES]

  updatedEntries = updatedEntries
    .filter(entry => {
      if (entry.dropdown) return true
      return isMenuEntryAllowed(userInfo, entry)
    })
    .map(entry => {
      if (!entry.dropdown) return entry
      const dropEntry = { ...entry }
      Object.keys(dropEntry.dropdown).forEach(dropId => {
        const dropPerm = dropEntry.dropdown[dropId]
        if (!isMenuEntryAllowed(userInfo, dropPerm)) {
          delete dropEntry.dropdown[dropId]
        }
      })
      return dropEntry
    })
    .filter(entry => {
      if (entry.dropdown && isEmpty(entry.dropdown)) return false
      return true
    })

  return updatedEntries
}

export const routeRedirect = (userInfo, previousPath) => {
  if (previousPath === "/login" || previousPath === "/") {
    const menuFiltered = filterMenuEntries(userInfo)

    if (!menuFiltered || isEmpty(menuFiltered)) {
      throw new Error("User has no permissions.")
    }

    const firstEntry = menuFiltered[0]
    if (firstEntry.dropdown) {
      return firstEntry.dropdown[Object.keys(firstEntry.dropdown)[0]].path
    }
    return firstEntry.path
  }
  return previousPath
}

export const doesUserHaveRoles = (userInfo, permission) => {
  const permissions = getUserPermissions(userInfo)

  return permissions.find(perm => permission === perm) !== undefined
}
