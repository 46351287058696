import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import addDays from "date-fns/addDays"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

import "react-datepicker/dist/react-datepicker.css"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useDiscountsApi from "../../../hooks/Api/useDiscountsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

// Utils & misc
import cn from "../../../utils/cn"

import styles from "./DiscountsEdit.module.css"

countries.registerLocale(countriesFR)

const permissions = [
  PERMISSIONS.PERM_DISCOUNTS_UPDATE,
  PERMISSIONS.PERM_DISCOUNTS_CREATE,
]

const DiscountsEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  // Discounts API Hooks
  const [
    ,
    discountData,
    discountError,
    { getDiscountWithId },
  ] = useDiscountsApi()
  const [
    updateDiscountState,
    ,
    updateDiscountError,
    { updateDiscount },
  ] = useDiscountsApi()
  const [
    newDiscountState,
    ,
    newDiscountError,
    { createDiscount },
  ] = useDiscountsApi()
  const history = useHistory()

  const isEditingDiscount = !isEmpty(match.params)

  const savedGetDiscountWithId = useCallback(getDiscountWithId, [])

  const createInitialValues = {
    typeId: 1,
    absoluteValue: null,
    relativeValue: null,
    code: "",
    start: format(new Date(), "yyyy-MM-dd"),
    end: format(addDays(new Date(), 60), "yyyy-MM-dd"),
    quantity: null,
    oneshot: false,
    active: true,
  }

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      discounts: isEditingDiscount ? discountData || {} : createInitialValues,
    },
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      const sanitizedValues = { ...valuesSubmit.discounts }
      if (!sanitizedValues.absoluteValue) sanitizedValues.absoluteValue = null
      if (!sanitizedValues.relativeValue) sanitizedValues.relativeValue = null
      delete sanitizedValues.uses

      if (isEditingDiscount)
        updateDiscount(sanitizedValues, match.params.id).then(() =>
          history.push({
            pathname: "/discounts/list",
            state: { submitType: "edit", success: true },
          })
        )
      else
        createDiscount(sanitizedValues).then(() =>
          history.push({
            pathname: "/discounts/list",
            state: { submitType: "create", success: true },
          })
        )
    },
  })

  useEffect(() => {
    if (isEditingDiscount) savedGetDiscountWithId(match.params.id)
  }, [isEditingDiscount, savedGetDiscountWithId, match.params.id])

  const backToList = () => {
    history.push({
      pathname: "/discounts/list",
      state: {},
    })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_DISCOUNTS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_DISCOUNTS_CREATE] && !isEditingDiscount)

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Code Promo / ${isEditingDiscount ? "Éditer" : "Nouveau"}`}
          {discountData && discountData.name ? ` / ${discountData.name}` : null}
        </h1>
      </div>

      <div className={cn(["mw-100", "w-75", "m-auto", styles.editSection])}>
        {!isEditingDiscount || discountData ? (
          <form onSubmit={handleSubmit}>
            {discountError || updateDiscountError || newDiscountError ? (
              <div className="alert alert-error" role="alert">
                {discountError || updateDiscountError || newDiscountError}
              </div>
            ) : null}

            <div className={cn(["card mb-4"])}>
              <div className="card-body">
                <h5 className="card-title">Informations</h5>

                <div className="form-row">
                  <div className="col-sm-12 col-md-12 pb-2">
                    <label htmlFor="discount-typeId">Type id</label>
                    <select
                      disabled={values.discounts.uses > 0 || !isAllowedToEdit}
                      className="custom-select"
                      id="discounts.typeId"
                      name="discounts.typeId"
                      required
                      onChange={e =>
                        setFieldValue(
                          "discounts.typeId",
                          Number(e.target.value)
                        )
                      }
                      onBlur={handleBlur}
                      value={values.discounts.typeId}
                    >
                      <option value={1}>ABSOLUTE</option>
                      <option value={2}>RELATIVE</option>
                    </select>
                    <div className="valid-tooltip">Looks good!</div>
                  </div>

                  {values.discounts.typeId === 1 && (
                    <div className="col-sm-6 col-md-6 pb-2">
                      <label htmlFor="discount-code">Absolute value</label>
                      <div className="input-group">
                        <input
                          disabled={
                            values.discounts.uses > 0 || !isAllowedToEdit
                          }
                          type="number"
                          className="form-control"
                          id="discounts.absoluteValue"
                          name="discounts.absoluteValue"
                          value={values.discounts.absoluteValue || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={values.discounts.typeId === 1}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  )}
                  {values.discounts.typeId === 2 && (
                    <div className="col-sm-6 col-md-6 pb-2">
                      <label htmlFor="discount-code">Relative value</label>
                      <div className="input-group">
                        <input
                          disabled={
                            values.discounts.uses > 0 || !isAllowedToEdit
                          }
                          type="number"
                          className="form-control"
                          id="discounts.relativeValue"
                          name="discounts.relativeValue"
                          value={
                            Math.round(values.discounts.relativeValue * 100) ||
                            ""
                          }
                          onChange={e =>
                            setFieldValue(
                              "discounts.relativeValue",
                              e.target.value / 100
                            )
                          }
                          onBlur={handleBlur}
                          required={values.discounts.typeId === 2}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div>
                      </div>
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  )}
                  <div className="col-sm-12 col-md-12 pb-2">
                    <label htmlFor="discount-code">Code</label>
                    <input
                      disabled={values.discounts.uses > 0 || !isAllowedToEdit}
                      type="text"
                      className="form-control"
                      id="discounts.code"
                      name="discounts.code"
                      value={values.discounts.code || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>

                  <div className="col-sm-6 col-md-6 pb-2">
                    <label htmlFor="clients-birthdate">Start</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="date"
                      className="form-control"
                      id="clients-discounts.start"
                      name="discounts.start"
                      value={values.discounts.start}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                  <div className="col-sm-6 col-md-6 pb-2">
                    <label htmlFor="clients-birthdate">End</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="date"
                      className="form-control"
                      id="clients-discounts.end"
                      name="discounts.end"
                      value={values.discounts.end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                  <div className="col-sm-12 col-md-12 pb-2">
                    <label htmlFor="discount-code">Quantité</label>
                    <input
                      disabled={!isAllowedToEdit}
                      type="number"
                      className="form-control"
                      id="discounts.quantity"
                      name="discounts.quantity"
                      value={values.discounts.quantity || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <div className="valid-tooltip">Looks good!</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4">
              {!isAllowedToEdit ? (
                <button
                  onClick={backToList}
                  type="button"
                  className="btn btn-secondary mr-2"
                >
                  Revenir à la liste
                </button>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  updateDiscountState === "loading" ||
                  newDiscountState === "loading" ||
                  !isAllowedToEdit
                }
              >
                {updateDiscountState === "loading" ||
                newDiscountState === "loading" ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isEditingDiscount ? "Sauvegarder" : "Créer"}
              </button>
            </div>
          </form>
        ) : (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </WithMenu>
  )
}

DiscountsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default DiscountsEdit
