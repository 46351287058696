import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next)

i18n.init({
  debug: false,
  lng: "fr",
  interpolation: {
    escapeValue: false,
    format: (value, format /* , lng */) => {
      if (format === "price") {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        return new Intl.NumberFormat("fr", options).format(value)
      }

      return value
    },
  },
  resources: {
    fr: {
      translation: {
        dropdown: {
          assignmentSection: 'Affectation section',
          listingSections: 'Listing par section',
          sectionsGroups: 'Groupes par section',
          extrasSectionsGroups: 'Options - Groupes par section',
          camp: "Stages",
          extra: "Extras",
          accommodation: "Hébergements",
          yieldpackages: "Packages",
          fidPoints: "Gestion des points",
          fidAdmin: "Administration",
          campusManagement: "Gestion",
          campusStudentForms: "Suivi des dossiers",
          reportsHeadCoach: "Head Coach",
          reportsFollowUp: "Suivi des rapports",
          reportsManagement: "Gestion des rapports",
          bookings: "Inscriptions",
          guest: "Export guest"
        },
        home: {
          book: "Rechercher",
        },
        offers: {
          offersCount: "{{ count }} offre trouvée",
          offersCount_plural: "{{ count }} offres trouvées",
          noOffer:
            "Aucun résultat. Veuillez redéfinir les dates et/ou les critères de votre recherche.",
          choosePackage: "Choisir ce stage",
          selected: "Séléctionné",
          priceFrom: "À partir de",
          details: "En détails",
          accommodation: "Hébergement",
          informationAboutPackage: "Information à propos de",
          watchPlanning: "Voir le planning type",
          book: "Réserver",
          weekNumber: "Semaine",
          campNumber: "Stage",
          noAccommodation: "Pas d'hébergement",
          search: "Recherche",
          calendar: "Calendrier",
          sports: "Sport",
          formats: "Format",
          submit: "Rechercher",
          more: "Autres",
          lastPlaces: "Dernières places disponibles",
          outSold: "Complet",
          new: "Nouveauté",
          lastMinute: "Dernière minute",
          legal:
            "L'hébergement sur le campus n’est pas disponible pour les enfants de moins de 10 ans.",
          legal2:
            "Le stage avec tournois est seulement disponible sur 2 semaines minimum.",
          accommodationWarning:
            "Attention, avec cet hébergement, le check-in se fera un jour plus tôt",
          modalWarn: "Attention",
          dropConfigurationPackage:
            "Souhaitez vous abandonner la configuration de votre {{ packageName }}?",
          abandonFinalize: "Abandonner et finaliser",
          continueConf: "Continuer la configuration",
          confirm: "Confirmer",
          cancel: "Annuler",
          youreAboutToSwitchOffer:
            "Vous allez remplacer l'offre de votre panier",
          youreAboutToSwitchExplanations:
            "En confirmant vous allez remplacer l'offre sélectionnée actuellement dans votre panier par cette nouvelle offre.",
          selectStage: "Sélectionnez votre stage",
          stageSelected: "Stage sélectionné",
          next: "suivant",
        },
        extras: {
          extras: "Choisissez vos extras",
          options: "Choisissez vos options",
          extrasNext: "Satisfait(e) de vos extras ?",
          optionsNext: "Satisfait(e) de vos options ?",
          next: "Suivant",
          addExtra: "Choisir",
          addOption: "Choisir",
          book: "Ajouter",
          weekNumber: "Semaine",
          campNumber: "Stage",
        },
        accommodations: {
          seeGallery: "Voir la galerie",
          selected: "Sélectionné",
          optional: "optionnel",
          title: "Choisissez votre hébergement",
          titleNext: "Satisfait(e) de votre hébergement ?",
          skip: "passer",
          next: "suivant",
          forYour: "Pour votre:",
          book: "Réserver",
          player: "Joueur",
          more: "voir plus",
          less: "voir moins",
          aboutToSwitch: "Vous allez remplacer l'hébergement de votre panier",
          aboutToSwitchText:
            "En confirmant vous allez remplacer l'hébergement actuellement dans votre panier par le dernier choisi.",
        },
        options: {
          confirm: "Confirmer",
          more: "En savoir plus",
          choose: "Choisir",
          continue: "Ajouter un stage",
          finish: "Finaliser ma commande",
        },
        players: {
          player: "Choisir le joueur",
          members: "Joueurs",
          pleaseSelect:
            "Veuillez sélectionner ou créer le joueur qui participera au stage.",
          form:
            "Veuillez remplir les informations relatives au joueur qui participera au stage.",
          personalInfo: "Informations personnelles",
          sportInfo: "Informations",
          new: "Nouveau joueur",
          firstname: "Prénom",
          lastname: "Nom",
          sexId: "Genre",
          nationalityId: "Nationalité",
          female: "Féminin",
          male: "Masculin",
          birthdate: "Date de naissance",
          email: "Email",
          phone: "Téléphone",
          day: "Jour",
          month: "Mois",
          year: "Année",
          isRanked: "Êtes-vous classé ?",
          otherRanking: "Autre classement",
          country: "Pays",
          years: "En quelle année avez-vous commencé la pratique ?",
          hoursPerWeek: "Combien d'heures jouez-vous par semaine ?",
          participationsPerYear: "À combien de matchs participez-vous par an ?",
          ranking: "Classement",
          next: "Suivant",
          back: "Retour",
          validate: "Valider",
          emergencyContactLastname: "Nom",
          emergencyContactFirstname: "Prénom",
          emergencyContactOccupation: "Qualité",
          emergencyContactPhone: "Téléphone",
          doctorLastname: "Nom",
          doctorAddress: "Adresse",
          doctorZipCode: "Code postal",
          doctorCity: "Ville",
          doctorCountryId: "Pays",
          socialSecurityAddress: "Adresse",
          socialSecurityZipCode: "Code postal",
          socialSecurityCity: "Ville",
          socialSecurityCountryId: "Pays",
          medicalObservations:
            "Observations complémentaires (Allergies, traitement en cours, ...)",
          medicalTermsAccepted:
            'J\'accepte les <a href="/medical-terms" target="_blank">conditions médicales</a>',
          responsible: "Contact d'urgence",
          doctor: "Médecin traitant",
          socialSecurity: "Sécurité sociale",
          levelIn: "Niveau en",
          languageLevel: "Niveau de langue",
          choosePicture: "Choisir une photo",
          acceptedFiles: "Fichiers acceptés",
        },
        breadcrumb: {
          offers: "Choix",
          accommodations: "Hébergements",
          extras: "Extras",
          options: "Options",
          players: "Coordonnées",
          recap: "Récapitulatif",
          payment: "Paiement",
        },
        basket: {
          selection: "Votre sélection",
          total: "Total",
          taxes: "Taxes incluses",
          delete: "Supprimer",
          finalize: "Finaliser la commande",
        },
        calendar: {
          monday: "lu",
          tuesday: "ma",
          wednesday: "me",
          thursday: "je",
          friday: "ve",
          saturday: "sa",
          sunday: "di",
        },
        price: "{{price, price}}",
        payment: {
          pay: "Payer",
          dontCloseTab:
            "Le paiement est en cours, êtes vous sûr de vouloir quitter ?",
          generalError:
            "Il y a eu une erreur lors de votre paiement. Veuillez réessayer ou contactez notre service client.",
        },
        middle: {
          players: {
            number: "Joueur",
            completeProfile: "Compléter le profil",
            addPlayer: "Ajouter un joueur",
            personalInfo: "Infos personnelles",
            sportsInfo: "Infos sportives",
            medicalInfo: "Infos médicales",
            plannedCamps: "Stage(s) prévu(s)",
            finishedCamps: "Stage(s) terminé(s)",
            modalPersonalInfo: "Infos personnelles",
            modalSportsInfo: "Infos sportives",
            modalMedicalInfo: "Infos médicales",
            modalSave: "Enregistrer",
          },
          orders: {
            date: "Date",
            cancellation: "Assurance annulation",
            amount: "Montant",
            balance: "Balance",
            invoice: "Facture",
            creditNotes: "Avoirs",
            yes: "Oui",
            no: "Non",
            back: "Précédent",
            taxesIncluded: "Taxes incluses",
            totalRegul: "Montant à régulariser",
            regularize: "Régulariser",
            useFromBalance: "utilisé de la balance",
          },
        },
      },
    },
    en: {
      translation: {
        home: {
          book: "Search",
        },
        offers: {
          offersCount: "{{ count }} offer found",
          offersCount_plural: "{{ count }} offers found",
          noOffer:
            "No results. Please select new dates and/or criteria for your search.",
          choosePackage: "Choose package",
          selected: "Selected",
          priceFrom: "Starting",
          details: "Details",
          accommodation: "Accommodation",
          informationAboutPackage: "Information about",
          watchPlanning: "See the example schedule",
          book: "Book",
          weekNumber: "Week",
          campNumber: "Camp",
          noAccommodation: "No accommodation",
          search: "Search",
          calendar: "Calendar",
          sports: "Sport",
          formats: "Format",
          submit: "Search",
          more: "Other",
          lastPlaces: "Final spots available",
          outSold: "Sold Out",
          new: "New",
          lastMinute: "Last minute",
          legal:
            "Accommodation on campus is not available for children under 10 years old.",
          legal2:
            "The tournament camp is only available for a minimum of two weeks.",
          accommodationWarning:
            "Warning, with this accommodation, check-in will be a day before",
          modalWarn: "Warning",
          dropConfigurationPackage:
            "Do you wish to abandon the configutation of your {{ packageName }}?",
          abandonFinalize: "Abandon and finalize",
          continueConf: "Continue to configure",
          confirm: "Confirm",
          cancel: "Cancel",
          youreAboutToSwitchOffer:
            "You are about to switch offers in your cart",
          youreAboutToSwitchExplanations:
            "If you confirm the action the offer currently in your cart will be replaced by the one you selected.",
          selectStage: "Select your camp",
          stageSelected: "Camp selected",
          next: "next",
        },
        accommodations: {
          seeGallery: "See photo gallery",
          selected: "Selected",
          optional: "optional",
          next: "next",
          title: "Select your accommodation",
          titleNext: "Pleased with your accommodation ?",
          skip: "skip",
          forYour: "About your :",
          book: "Book",
          player: "Player",
          more: "see more",
          less: "see less",
          aboutToSwitch: "You are about to switch accommodation in your cart",
          aboutToSwitchText:
            "If you confirm the action the accommodation currently in your cart will be replaced by the one you selected.",
        },
        extras: {
          extras: "Select your extras",
          options: "Select your options",
          extrasNext: "Pleased with your extras ?",
          optionsNext: "Pleased with your options ?",
          next: "Next",
          addExtra: "Select",
          addOption: "Select",
          book: "Book",
          weekNumber: "Week",
          campNumber: "Camp",
        },
        options: {
          confirm: "Confirm",
          more: "Learn more",
          choose: "Select",
          continue: "Add a camp",
          finish: "Finalize my order",
        },
        players: {
          player: "Choose player",
          members: "Members",
          pleaseSelect:
            "Please select or create the player who will be participating in the camp.",
          form:
            "Here you will find information about the player participating in the camp.",
          personalInfo: "Personal Information",
          sportInfo: "Informations",
          new: "New player",
          firstname: "First name",
          lastname: "Surname",
          sexId: "Gender",
          nationalityId: "Nationality",
          female: "Female",
          male: "Male",
          birthdate: "Date of birth",
          email: "Email",
          phone: "Telephone",
          day: "Day",
          month: "Month",
          year: "Year",
          isRanked: "Are you a professional player?",
          otherRanking: "Other ranking",
          country: "Country",
          years: "When did you start practising?",
          hoursPerWeek: "How many hours a week do you practice?",
          participationsPerYear: "How many matches do you play per year?",
          ranking: "Ranking",
          next: "Next",
          back: "Back",
          validate: "Confirm",
          emergencyContactLastname: "Surname",
          emergencyContactFirstname: "Name",
          emergencyContactOccupation: "Role",
          emergencyContactPhone: "Phone",
          doctorLastname: "Surname",
          doctorAddress: "Address",
          doctorZipCode: "Postcode",
          doctorCity: "City",
          doctorCountryId: "Country",
          socialSecurityAddress: "Address",
          socialSecurityZipCode: "Postcode",
          socialSecurityCity: "City",
          socialSecurityCountryId: "Country",
          medicalObservations:
            "Additional comments (allergies, current medical treatments, etc.)",
          medicalTermsAccepted:
            'I accept the  <a href="/medical-terms" target="_blank">medical terms and conditions</a>',
          responsible: "Person responsible",
          doctor: "Doctor",
          socialSecurity: "French social security",
          levelIn: "Level in",
          languageLevel: "Language fluency",
          choosePicture: "Choose a picture",
          acceptedFiles: "Accepted files",
        },
        breadcrumb: {
          offers: "Selection",
          accommodations: "Accommodations",
          extras: "Extras",
          options: "Options",
          players: "Contact info",
          recap: "Summary",
          payment: "Payment",
        },
        basket: {
          selection: "Your selection",
          total: "Total",
          taxes: "Taxes included",
          delete: "Delete",
          finalize: "Finalize your order",
        },
        calendar: {
          monday: "Mon",
          tuesday: "Tue",
          wednesday: "Wed",
          thursday: "Thu",
          friday: "Fri",
          saturday: "Sat",
          sunday: "Sun",
        },
        price: "{{price, price}}",
        payment: {
          pay: "Pay",
          dontCloseTab: "Payment in process, are you sure you want to leave ?",
          generalError:
            "Something went wrong with the payment, try again or contact our customer service.",
        },
        middle: {
          players: {
            number: "Player",
            completeProfile: "Complete the profile",
            addPlayer: "Add a player",
            personalInfo: "Personal info",
            sportsInfo: "Sport info",
            medicalInfo: "Medical info",
            plannedCamps: "Planned camp(s)",
            finishedCamps: "Completed camp(s)",
            modalPersonalInfo: "Personal info",
            modalSportsInfo: "Sport info",
            modalMedicalInfo: "Medical info",
            modalSave: "Save",
          },
          orders: {
            date: "Date",
            cancellation: "Cancellation insurance",
            amount: "Amount",
            balance: "Balance",
            invoice: "Invoice",
            creditNotes: "Credit note",
            yes: "Yes",
            no: "No",
            back: "Previous",
            taxesIncluded: "Taxes included",
            totalRegul: "Total to be paid",
            regularize: "Settle my order",
            useFromBalance: "amount used from your account balance: ",
          },
        },
      },
    },
    it: {
      translation: {
        home: {
          book: "Prenota",
        },
        offers: {
          offersCount: "{{ count }} offerta trovata",
          offersCount_plural: "{{ count }} offerte trovate",
          noOffer:
            "Nessun risultato. Ridefinisci le date e/o i criteri della tua ricerca.",
          choosePackage: "Seleziona questo programma",
          selected: "Selezionato",
          priceFrom: "A partire da",
          details: "Dettagli",
          accommodation: "Alloggio",
          informationAboutPackage: "Informazioni su",
          watchPlanning: "Consulta il programma standard",
          book: "Prenota",
          weekNumber: "Settimana",
          campNumber: "Programma",
          noAccommodation: "Nessun alloggio",
          search: "Ricerca",
          calendar: "Calendario",
          sports: "Sport",
          formats: "Formato",
          submit: "Ricerca",
          more: "Altri",
          lastPlaces: "Ultimi posti",
          outSold: "Completo",
          new: "Novità",
          lastMinute: "Ultimo minuto",
          legal:
            "L'alloggio sul campus non è disponibile per i bambini di età inferiore ai 10 anni.",
          legal2:
            "Il programma con tornei è disponibile solo per una durata minima di 2 settimane.",
          accommodationWarning:
            "Attenzione, se si seleziona questo allogio, il check-in verrà effettuato il giorno prima",
          selectStage: "Seleziona il tuo programma",
          stageSelected: "Programma selezionato",
          next: "Successivo",
          confirm: "Confermare",
          cancel: "Annullare",
          youreAboutToSwitchOffer: "SI SOSTITUISCE L'OFFERTA DEL TUO CARRELLO",
          youreAboutToSwitchExplanations:
            "Conferma di sostituire l'offerta attualmente selezionata nel tuo carrello con questa nuova offerta.",
        },
        extras: {
          extras: "Scegli il tuo extra",
          options: "Scegli il tuo opzione",
          extrasNext: "Soddisfatto del tuo extra ?",
          optionsNext: "Soddisfatto del tuo opzione ?",
          next: "Successivo",
          addExtra: "Aggiungi questo extra",
          addOption: "Aggiungi questa opzione",
          book: "Prenota",
          weekNumber: "Settimana",
          campNumber: "Programma",
        },
        accommodations: {
          seeGallery: "vedi la galleria",
          optional: "opzionale",
          selected: "selezionato",
          title: "Scegli il tuo allogio",
          titleNext: "Soddisfatto del tuo allogio ?",
          skip: "Saltare",
          next: "Successivo",
          forYour: "Per il tuo :",
          book: "Prenota",
          player: "Giocatore",
          more: "Maggiori informazioni",
          less: "Menori informazioni",
          aboutToSwitch: "Sostituirai il allogio del tuo carrello",
          aboutToSwitchText:
            "Confermando, sostituirai il allogio attualmente nel tuo carrello con l'ultimo scelto",
        },
        options: {
          confirm: "Conferma",
          more: "Maggiori informazioni",
          choose: "Seleziona",
          continue: "Aggiungi un programma",
          finish: "Finalizza il mio ordine",
        },
        players: {
          player: "Scegli il giocatore",
          members: "Membri",
          pleaseSelect:
            "Seleziona o crea il giocatore che parteciperà al programma.",
          form:
            "Qui troverà le informazioni relative al giocatore che prenderà parte al programma.",
          personalInfo: "Informazioni personali",
          sportInfo: "Informazioni",
          new: "Nuovo giocatore",
          firstname: "Nome",
          lastname: "Cognome",
          sexId: "Sesso",
          nationalityId: "Cittadinanza",
          female: "Femminile",
          male: "Maschile",
          birthdate: "Data di nascita",
          email: "E-mail",
          phone: "Telefono",
          day: "Giorno",
          month: "Mese",
          year: "Anno",
          isRanked: "Sei un giocatore professionista?",
          country: "Paese",
          years: "Quando hai iniziato a praticare?",
          hoursPerWeek: "Quante ore alla settimana ti alleni?",
          participationsPerYear: "Quante partite giochi ogni anno?",
          ranking: "Classifica",
          next: "Successivo",
          back: "Indietro",
          validate: "Conferma",
          emergencyContactLastname: "Cognome",
          emergencyContactFirstname: "Nome",
          emergencyContactOccupation: "In qualità de",
          emergencyContactPhone: "Telefono",
          doctorLastname: "Cognome",
          doctorAddress: "Indirizzo",
          doctorZipCode: "CAP",
          doctorCity: "Città",
          doctorCountryId: "Paese",
          socialSecurityAddress: "Indirizzo",
          socialSecurityZipCode: "CAP",
          socialSecurityCity: "Città",
          socialSecurityCountryId: "Paese",
          medicalObservations:
            "Informazioni complementari (allergie, trattamenti in corso...)",
          medicalTermsAccepted:
            'Accetto le <a href="/medical-terms" target="_blank">condizioni mediche</a>',
          responsible: "Recapiti di contatto per emergenze",
          doctor: "Medico curante",
          socialSecurity: "Sicurezza sociale francese",
          levelIn: "Livello di",
          languageLevel: "Livello linguistico",
          choosePicture: "Scegli una foto",
          acceptedFiles: "file accettati",
        },
        breadcrumb: {
          offers: "Selezione",
          extras: "Extra",
          options: "Opzioni",
          players: "Coordinate",
          recap: "Riepilogo",
          payment: "Pagamento",
          accommodations: "Alloggi",
        },
        basket: {
          selection: "La tua selezione",
          total: "Totale",
          taxes: "IVA incl.",
          delete: "Elimina",
          finalize: "Completa l’ordine",
        },
        calendar: {
          monday: "lun",
          tuesday: "mar",
          wednesday: "mer",
          thursday: "gio",
          friday: "ven",
          saturday: "sab",
          sunday: "dom",
        },
        price: "{{price, price}}",
        payment: {
          pay: "Pagare",
          dontCloseTab:
            "Il pagamento è in corso, sei sicuro di voler andare via?",
          generalError:
            "Qualcosa è andato storto con il pagamento, riprova o contatta il nostro servizio clienti.",
        },
        middle: {
          players: {
            number: "Giocatore",
            completeProfile: "Completa il profilo",
            addPlayer: "Aggiungi un giocatore",
            personalInfo: "Informazioni personali",
            sportsInfo: "Informazioni sportive",
            medicalInfo: "Informazioni mediche",
            plannedCamps: "Programma/i previsto/i",
            finishedCamps: "Programma/i terminato/i",
            modalPersonalInfo: "Informazioni personali",
            modalSportsInfo: "Informazioni sportive",
            modalMedicalInfo: "Informazioni mediche",
            modalSave: "Salva",
          },
          orders: {
            date: "Data",
            cancellation: "Assicurazione annullamento",
            amount: "Importo",
            balance: "Saldo",
            invoice: "Fattura",
            creditNotes: "Crediti",
            yes: "Sì",
            no: "No",
            back: "Indietro",
            taxesIncluded: "IVA incl.",
            totalRegul: "Totale da pagare",
            regularize: "Paga il mio ordine",
            useFromBalance: "importo utilizzato dal saldo",
          },
        },
      },
    },
  },
})

export default i18n
