export const initialEstablishment = {
  name: "",
  cappingPoints: 100,
  keycloakId: "",
}

export const initialEmployee = {
  firstName: "",
  lastName: "",
  establishmentId: null,
  active: true,
}

export const initialLevel = {
  name: "",
  threshold: null,
}

export const initialPrivilege = {
  name: "",
  expirationDate: null,
  recurrence: null,
  isPermanent: false,
  levelId: null,
  establishmentId: null,
  targets: [],
}
