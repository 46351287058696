const useImage = () => {
  const cloudImageUrl = process.env.REACT_APP_CLOUDIMAGE_URL;

  const getImageSrc = ({ fileKey, width }) => {
    const basePath = cloudImageUrl && fileKey ? `${cloudImageUrl}${fileKey}` : null;

    if (!basePath || !width) return '';

    const mediaUrl = new URL(basePath);
    mediaUrl.searchParams.append('width', width);
    mediaUrl.searchParams.append('sharp', 1);

    return mediaUrl?.href || '';
  };

  return {
    getImageSrc,
  };
};

export default useImage;
