import React, { useEffect, useState, useCallback, useContext } from "react"
import { useHistory } from "react-router-dom"

// Components
import Spinner from "../../atoms/Spinner/Spinner"
import SearchInput from "../../molecules/SearchInput/SearchInput"

// Hooks
import useAppContext from "../../../hooks/useAppContext"
import useClientsApi from "../../../hooks/Api/useClientsApi"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Utils
import { PERMISSIONS } from "../../../constants/constants"
import { KeycloakAuthContext } from "../../templates/KeycloakAuthProvider"

const permissions = [PERMISSIONS.PERM_CLIENTS_DISPLAY]

const ClientSearch = () => {
  const {
    setSearchedClients,
    context: { searchedClients, userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const history = useHistory()
  const { keycloak } = useContext(KeycloakAuthContext)
  const [allowedToSearch, setAllowedToSearch] = useState(false)
  const [, , , { getClientsRaw }] = useClientsApi()
  const getClients = useCallback(getClientsRaw, [])
  const savedSearchedClients = useCallback(setSearchedClients, [])

  const fetchClients = useCallback(async () => {
    try {
      const clientsResponse = await getClients({ archived: false })
      const { clients } = clientsResponse.data

      savedSearchedClients(
        Object.keys(clients).map(clientKey => ({
          ...clients[clientKey],
          fullname: `${clients[clientKey].firstname} ${clients[clientKey].lastname}`.toLowerCase(),
        }))
      )
    } catch (error) {
      console.error("error in ClientSearch: ", error)
    }
  }, [getClients, savedSearchedClients])

  useEffect(() => {
    if (userInfo) {
      if (rolePermissions[PERMISSIONS.PERM_CLIENTS_DISPLAY])
        setAllowedToSearch(true)
    }
  }, [rolePermissions, userInfo])

  useEffect(() => {
    if (allowedToSearch && keycloak.authenticated) {
      fetchClients()
    }
  }, [allowedToSearch, keycloak, fetchClients])

  const handleItemSelected = item => {
    switch (item.entity) {
      case "client": {
        history.push(`/client/${item.id}/articles/list`)
        break
      }
      case "player": {
        history.push(`/client/${item.client.id}/articles/list`)
        break
      }
      default: {
        break
      }
    }
  }

  return (
    <div>
      {allowedToSearch ? (
        <span>
          {!searchedClients || !searchedClients.length ? (
            <Spinner />
          ) : (
            <SearchInput onElementClick={handleItemSelected} />
          )}
        </span>
      ) : null}
    </div>
  )
}

ClientSearch.propTypes = {}

ClientSearch.defaultProps = {}

export default ClientSearch
