// Hooks
import useApi from "./useApi"

// Utils & misc
import { API_STAGES_URL } from "../../constants/constants"

const useAttachmentsApi = () => {
  const [requestState, payload, error, request] = useApi()

  const getAttachments = () =>
    request("GET", `${API_STAGES_URL}/v1/attachments`)

  const getAttachmentWithId = attachmentId =>
    request("GET", `${API_STAGES_URL}/v1/attachments/${attachmentId}`)

  const updateAttachment = (attachment, attachmentId) =>
    request(
      "PUT",
      `${API_STAGES_URL}/v1/attachments/${attachmentId}`,
      attachment
    )

  const createAttachment = attachment =>
    request("POST", `${API_STAGES_URL}/v1/attachments`, attachment)

  return [
    requestState,
    payload,
    error,
    {
      getAttachments,
      getAttachmentWithId,
      updateAttachment,
      createAttachment,
    },
  ]
}

export default useAttachmentsApi
