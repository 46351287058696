import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { orderSections } from '../../../../../utils/coach/orderSections';
import { getTitleSection } from '../../../../../utils/coach/mappers';
import { isEmpty, isNil, sortBy } from 'lodash';
import { staticGroupsColor } from '../../../../../utils/coach/staticValues';
import cn from '../../../../../utils/cn';

// Services
import useCoachesApi from '../../../../../hooks/Api/useCoachesApi';

// Components
import { SelectInput, TextInput } from '../../../../../components/atoms/Inputs/Inputs';

import styles from './ExtrasBySections.module.css';

const ExtrasBySections = ({ bookings, handleSaveBooking, groupIsSaving, isWeekendExtraType }) => {
  const [, coaches, , { getCoaches }] = useCoachesApi();

  const [courtValues, setCourtValues] = useState({});

  useEffect(() => {
    getCoaches();
  }, []);

  const getGroupFromBooking = (booking) => ({
    bookingId: booking.id,
    player: {
      firstName: booking.player?.firstName || '',
      lastName: booking.player?.lastName || '',
      birthdate: booking.player?.birthdate || '',
    },
    colorCode: booking.colorCode || null,
    coachId: (
      // One to one OR One to one Weekend
      isWeekendExtraType
        ? booking.individualCoachingCoachWe?.id
        : booking.individualCoachingCoach?.id
    ) || null,
    court: (
      // One to one OR One to one Weekend
      isWeekendExtraType
        ? booking.individualCoachingCourtWe
        : booking.individualCoachingCourt
    ) || '',
    level: booking.sessions?.[0]?.weekSectionRotationGroup?.level || '',
  });

  const groupsBySection = useMemo(() => (
    orderSections
      .map(({ sectionCode }) => ({
        sectionCode,
        groups: bookings
          // One to one OR One to one Weekend
          .filter((booking) => isWeekendExtraType ? booking.hasIndividualCoachingExtraWe : booking.hasIndividualCoachingExtra)
          .filter((booking) => booking.weekSectionCode?.includes(sectionCode))
          .map((booking) => (getGroupFromBooking(booking)))
      }))
      .concat([{
        sectionCode: 'undefined_section',
        groups: bookings
          // One to one OR One to one Weekend
          .filter((booking) => isWeekendExtraType ? booking.hasIndividualCoachingExtraWe : booking.hasIndividualCoachingExtra)
          .filter((booking) => isEmpty(booking.weekSectionCode))
          .map((booking) => (getGroupFromBooking(booking)))
      }])
      .filter((section) => section.groups?.length > 0)
  ), [bookings, isWeekendExtraType]);

  const coachesOptions = useMemo(() => ([
      <option key="default" value="" disabled>
        Coach
      </option>,
      sortBy((coaches?.coaches || []), 'firstName').map((coach, coachIndex) => (
        <option key={coachIndex} value={coach.id}>
          {coach.firstName} {coach.lastName}
        </option>
      )),
    ]), [coaches]);

  const handleCourtChange = (e, bookingId) => {
    const value = e.target?.value || '';
    // One to one OR One to one Weekend
    const key = isWeekendExtraType ? `${bookingId}-weekend` : bookingId
    setCourtValues((prev) => ({ ...prev, [key]: value }));
  }

  const handleCourtBlur = (e, group) => {
    const value = e.target.value;

    if (value !== group.court) {
      handleSaveBooking({
        bookingId: group.bookingId,
        coachId: group.coachId,
        court: e.target.value
      });
    }
  };

  const getCourtInstantValue = (group) => {
    const valueKey = isWeekendExtraType ? `${group.bookingId}-weekend` : group.bookingId;

    if (!isNil(courtValues[valueKey])) {
      return courtValues[valueKey]
    }
    return group.court || ''
  }

  return (
    <div className='mt-3'>
      {groupsBySection?.length ? (
        groupsBySection
          .map(({ sectionCode, groups }, index) => (
            <div key={index} className={styles.section}>
              <h2 className={styles.section_title}>{getTitleSection(sectionCode)}</h2>
  
              <div className={styles.groups}>
                {groups.map((group, i) => (
                  <div key={i} className={styles.group}>
                    <div className={styles.group_inner}>
                      <SelectInput
                        id="coach-selector"
                        value={group.coachId || null}
                        name="coachSelector"
                        onChange={(e) => handleSaveBooking({ bookingId: group.bookingId, coachId: e.target.value, court: group.court })}
                        options={coachesOptions}
                        className={cn([styles.input, 'mb-1'])}
                        disabled={groupIsSaving}
                      />

                      <TextInput
                        value={getCourtInstantValue(group)}
                        onChange={(e) => handleCourtChange(e, group.bookingId)}
                        onBlur={(e) => handleCourtBlur(e, group)}
                        placeholder="Nom du court"
                        className={cn([styles.input, 'mb-1'])}
                        disabled={groupIsSaving}
                      />

                      {group.level ? (
                        <div className={styles.level}>
                          <strong>Niveau : </strong>
                          {group.level}
                        </div>
                      ) : null}
                    </div>

                    <p
                      className={styles.player}
                      style={{
                        backgroundColor: staticGroupsColor?.find((g) => group.colorCode === g?.code)?.backgroundColor || '',
                        color: staticGroupsColor?.find((g) => group.colorCode === g?.code)?.color || '',
                      }}
                    >
                      {`${group.player?.firstName} ${group.player?.lastName}`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))
      ) : (
        <p className='mt-4'>Aucun groupe à afficher pour la semaine sélectionnée.</p>
      )}
    </div>
  )
}

ExtrasBySections.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExtrasBySections;
