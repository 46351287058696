import React, { useEffect, useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { saveAs } from "file-saver"
import Select from "react-select"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"
import Table from "../../../components/organisms/Table"
import Spinner from "../../../components/atoms/Spinner/Spinner"
import Tooltip from "../../../components/molecules/Tooltip/Tooltip"
import InvoiceExportLanguage from "../../../components/molecules/InvoiceExportLanguage/InvoiceExportLanguage"

// Hooks
import useOrdersApi from "../../../hooks/Api/useOrdersApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"
import useInvoiceApi from "../../../hooks/Api/useInvoiceApi"

// Constants
import { PERMISSIONS, OTHER_PAYMENT_MEANS } from "../../../constants/constants"

// Utils
import cn from "../../../utils/cn"
import { exportCollectionToArray } from "../../../utils/collection"

import style from "./Orders.module.css"

const permissions = [
  PERMISSIONS.PERM_ORDERS_COMMENT,
  PERMISSIONS.PERM_ORDERS_RESOLVE,
  PERMISSIONS.PERM_ORDERS_EXPORT,
  PERMISSIONS.PERM_ORDERS_DISPLAY,
]

const BackRowComment = ({ order, readonly = false }) => {
  const [, , , { editOrder }] = useOrdersApi()
  const [editMode, setEditMode] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [commentValue, setCommentValue] = useState(order.comment)

  const handleOrderCommentSubmit = async () => {
    if (commentValue) {
      try {
        setSubmitLoading(true)
        await editOrder(order.id, { comment: commentValue })
        setEditMode(false)
        setSubmitLoading(false)
      } catch (error) {
        console.error("error in handleOrderCommentSubmit: ", error)
        setSubmitLoading(false)
      }
    }
  }

  const editName = commentValue ? "Modifier" : "Ajouter"

  return (
    <>
      {readonly ? (
        <div>{commentValue}</div>
      ) : (
        <div>
          {editMode ? (
            <div className={cn(["input-group", style.inputComment])}>
              <input
                value={commentValue}
                onChange={e => setCommentValue(e.target.value)}
                className="form-control"
              />
              <div className="input-group-append">
                <button
                  disabled={submitLoading}
                  onClick={handleOrderCommentSubmit}
                  className="btn btn-primary"
                  type="button"
                >
                  Valider
                  {submitLoading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div>{commentValue || ""}</div>
          )}
          <span
            onClick={() => setEditMode(!editMode)}
            onKeyDown={null}
            role="button"
            tabIndex={[]}
            className={cn(["btn btn-link p-0", style.editCommentButton])}
          >
            {editMode ? "Annuler" : editName}
          </span>
        </div>
      )}
    </>
  )
}

const Orders = () => {
  const {
    context: { userInfo, locales },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)
  const [, , , { getOrders }] = useOrdersApi()
  const [, , , { getInvoiceById }] = useInvoiceApi()
  const [orders, setOrders] = useState(null)
  //   const [error, setError] = useState(null)
  const savedGetOrders = useCallback(getOrders, [])
  const savedGetInvoiceById = useCallback(getInvoiceById, [])
  const { state } = useLocation()

  // Filters
  const [clientFilterOptions, setClientFilterOptions] = useState([])
  const [invoiceFilterOptions, setInvoiceFilterOptions] = useState([])
  const [clientFilter, setClientFilter] = useState(null)
  const [invoiceFilter, setInvoiceFilter] = useState(null)
  const [amountFilter, setAmountFilter] = useState(null)

  useEffect(() => {
    if (orders) {
      setClientFilterOptions(
        orders.reduce((prev, current) => {
          const client = {
            value: current.clientId,
            label: `${current.clientFirstname} ${current.clientLastname}`,
          }

          const doesClientExist =
            prev.find(c => c.value === client.value) !== undefined

          if (!doesClientExist) {
            return [...prev, client]
          }
          return prev
        }, [])
      )
      setInvoiceFilterOptions(
        orders.reduce((prev, current) => {
          const invoice = {
            value: current.invoice.id,
            label: `${current.invoice.number}.pdf`,
            clientId: current.clientId,
          }

          const doesInvoiceExist =
            prev.find(i => i.value === invoice.value) !== undefined

          if (!doesInvoiceExist) {
            return [...prev, invoice]
          }
          return prev
        }, [])
      )
    }
  }, [orders])

  const fetchOrders = useCallback(async () => {
    try {
      const ordersResp = await savedGetOrders({
        balance: "negative",
        hidden: "false",
      })
      setOrders(exportCollectionToArray(ordersResp.data, "orders"))
    } catch (e) {
      if (e.status) {
        // setError(e.status.message)
      }
    }
  }, [savedGetOrders])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  const handleInvoiceDowload = async invoice => {
    const invoiceFile = await savedGetInvoiceById(invoice.id)
    saveAs(invoiceFile.data, `${invoice.number}.pdf`)
  }

  const getFilteredList = (list) => {
    return list.filter((item) => {
      // client
      if (clientFilter && clientFilter.value !== item.clientId) {
        return false
      }

      // invoice
      if (invoiceFilter && invoiceFilter.value !== item.invoice.id) {
        return false
      }

      // amount
      if (
        amountFilter
        && item.amountInclTax
        && parseInt(item.amountInclTax, 10)
        && ((parseInt(item.amountInclTax, 10) / 100) !== amountFilter)
      ) {
        return false
      }

      return true
    })
  };

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <span className="d-inline-flex">
          <h1 className="mb-0">Clients / Commandes à régulariser</h1>
        </span>
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          {state.submitType || ""}
        </div>
      )}
      {orders && orders.length ? (
        <>
          {rolePermissions[PERMISSIONS.PERM_ORDERS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_ORDERS_COMMENT] ||
          rolePermissions[PERMISSIONS.PERM_ORDERS_EXPORT] ||
          rolePermissions[PERMISSIONS.PERM_ORDERS_RESOLVE] ? (
            <>
              <div className="row">
                <div className="col-md-3 mb-4">
                  {clientFilterOptions ? (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={clientFilter}
                      isClearable
                      placeholder="Filtrer par client"
                      isSearchable
                      name="clientFilter"
                      onChange={value => setClientFilter(value)}
                      options={clientFilterOptions}
                    />
                  ) : null}
                </div>
                <div className="col-md-3 mb-4">
                  {invoiceFilterOptions ? (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={invoiceFilter}
                      isClearable
                      placeholder="Filtrer par facture"
                      isSearchable
                      name="invoiceFilter"
                      onChange={value => setInvoiceFilter(value)}
                      options={invoiceFilterOptions.filter(invoice => {
                        if (
                          clientFilter &&
                          invoice.clientId !== clientFilter.value
                        ) {
                          return false
                        }
                        return true
                      })}
                    />
                  ) : null}
                </div>
                <div className="col-md-3 mb-4">
                  <span className={style.filterAmount}>
                    <div className="mr-2">Montant à payer égal à </div>
                    <input
                      type="number"
                      value={amountFilter || ""}
                      onChange={e => {
                        setAmountFilter(Number(e.target.value))
                      }}
                      className={cn(["form-control", style.filterAmountInput])}
                    />
                    <span
                      className="fas fa-times ml-2"
                      onClick={() => setAmountFilter(null)}
                    />
                  </span>
                </div>
              </div>
              <Table
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    Cell: ({
                      cell: {
                        row: {
                          original: { id },
                        },
                      },
                    }) => (
                      <div>
                        <Tooltip
                          copyContent={false}
                          text={`${id.substring(0, 8)}...`}
                          content={<div>{id}</div>}
                        />
                      </div>
                    ),
                  },
                  {
                    Header: "Client",
                    accessor: "tag0",
                    Cell: ({
                      cell: {
                        row: {
                          original: { clientFirstname, clientLastname },
                        },
                      },
                    }) => <div>{`${clientFirstname} ${clientLastname}`}</div>,
                  },
                  {
                    Header: "Date",
                    accessor: "creation",
                    Cell: ({
                      cell: {
                        row: {
                          original: { creation },
                        },
                      },
                    }) => <div className={style.dateEntry}>{creation}</div>,
                  },
                  {
                    Header: "Assu Annulation",
                    accessor: "insurance",
                    Cell: ({
                      cell: {
                        row: {
                          values: { insurance },
                        },
                      },
                    }) => <div>{insurance ? "Oui" : "Non"}</div>,
                  },
                  {
                    Header: "Montant",
                    accessor: 'amountInclTax',
                    Cell: ({
                      cell: {
                        row: {
                          original: { amountInclTax },
                        }
                      }
                    }) => (parseInt(amountInclTax, 10) / 100).toFixed(2),
                  },
                  {
                    Header: "Balance",
                    accessor: "balance",
                  },
                  {
                    Header: "Facture",
                    accessor: "invoice",
                    Cell: ({
                      cell: {
                        row: {
                          original: { invoice, amountExclTax, amountInclTax },
                        },
                      },
                    }) => (
                      <>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => handleInvoiceDowload(invoice)}
                        >
                          <Tooltip
                            copyContent={false}
                            text={`${invoice.number}.pdf`}
                            content={
                              <div>
                                <div>{`Total HT(€): ${(
                                  parseInt(amountExclTax, 10) / 100
                                ).toFixed(2)}`}</div>
                                <div>{`Total TTC(€): ${(
                                  parseInt(amountInclTax, 10) / 100
                                ).toFixed(2)}`}</div>
                              </div>
                            }
                          />
                        </button>
                      </>
                    ),
                  },
                  {
                    Header: "Avoirs",
                    accessor: "creditNotes",
                    Cell: ({
                      cell: {
                        row: {
                          values: { creditNotes },
                        },
                      },
                    }) => (
                      <div>
                        {creditNotes
                          ? creditNotes.map((credit, index) => (
                              <button
                                key={index}
                                type="submit"
                                className={cn([
                                  "btn btn-link",
                                  style.creditSpacing,
                                ])}
                                onClick={() => handleInvoiceDowload(credit)}
                              >
                                {credit.number}.pdf
                              </button>
                            ))
                          : null}
                      </div>
                    ),
                  },
                  {
                    Header: "Langue",
                    accessor: "tag1",
                    Cell: ({
                      cell: {
                        row: {
                          original: { ...order },
                        },
                      },
                    }) =>
                      locales ? (
                        <InvoiceExportLanguage
                          invoiceId={order.invoice.id.toString()}
                          invoiceName={order.invoice.number}
                          locales={locales}
                        />
                      ) : null,
                  },
                  {
                    Header: "Commentaire",
                    accessor: "comment",
                    width: 400,
                    Cell: ({
                      cell: {
                        row: {
                          original: { ...order },
                        },
                      },
                    }) => (
                      <BackRowComment
                        readonly={
                          !rolePermissions[PERMISSIONS.PERM_ORDERS_COMMENT]
                        }
                        order={order}
                      />
                    ),
                  },
                  {
                    Header: "Mode de paiement",
                    accessor: "paymentMeanId",
                    Cell: ({
                      cell: {
                        row: {
                          original: { paymentMeanId },
                        },
                      },
                    }) => (
                      <div>
                        {OTHER_PAYMENT_MEANS.find(
                          paymentMean =>
                            paymentMean.paymentMeanId === paymentMeanId
                        )?.name || ""}
                      </div>
                    ),
                  },
                  {
                    Header: "Date limite",
                    accessor: "paymentDueDate",
                    Cell: ({
                      cell: {
                        row: {
                          original: { paymentDueDate },
                        },
                      },
                    }) => (
                      <div className={style.dateEntry}>
                        {paymentDueDate || "Aucune"}
                      </div>
                    ),
                  },
                  {
                    Header: "Status",
                    accessor: "tag3",
                    Cell: ({
                      cell: {
                        row: {
                          original: { id, clientId },
                        },
                      },
                    }) => (
                      <>
                        {rolePermissions[PERMISSIONS.PERM_ORDERS_RESOLVE] ? (
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Resolve"
                          >
                            <Link
                              className="btn btn-secondary"
                              to={`/client/${clientId}/order/${id}/resolve`}
                            >
                              Régulariser
                            </Link>
                          </div>
                        ) : (
                          <div>à régulariser</div>
                        )}
                      </>
                    ),
                  },
                ]}
                data={getFilteredList(orders)}
                hasPagination
                hasSort
              />
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </WithMenu>
  )
}

export default Orders
