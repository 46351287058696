import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"
import countries from "i18n-iso-countries"
import countriesFR from "i18n-iso-countries/langs/fr.json"

// Components
import WithMenu from "../../../components/templates/WithMenu/WithMenu"

// Hooks
import useUtilsApi from "../../../hooks/Api/useUtilsApi"
import useAppContext from "../../../hooks/useAppContext"
import usePermissionsState from "../../../hooks/usePermissionsState"

// Utils & misc
import cn from "../../../utils/cn"

// Constants
import { PERMISSIONS } from "../../../constants/constants"

countries.registerLocale(countriesFR)

const permissions = [PERMISSIONS.PERM_LOCALES_UPDATE]

const LocalesEdit = ({ match }) => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [
    localeRequest,
    localeData,
    localeError,
    { getLocalesWithId },
  ] = useUtilsApi()
  const [
    updateLocaleRequest,
    updateLocaleData,
    updateError,
    { updateLocale },
  ] = useUtilsApi()
  const history = useHistory()

  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      locales: {
        emarsysClientRegistrationCampaignId: 0,
        emarsysClientKeycloakRegistrationCampaignId: 0,
        emarsysOrderValidationCampaignId: 0,
        emarsysResetPasswordCampaignId: 0,
        emarsysPlayerLinkCampaignId: 0,
        emarsysEmailPaymentCampaignId: 0,
        emarsysEmailHotelArticleCancelledCampaignId: 0,
        emarsysEmailHotelArticlePlayerUpdatedCampaignId: 0,
        emarsysEmailHotelNewOrderCampaignId: 0,
        emarsysEmailHotelOrderCommentUpdatedCampaignId: 0,
        ...localeData,
      },
    },
    enableReinitialize: true,
    onSubmit: valuesSubmit => {
      const sanitizedValues = { ...valuesSubmit.locales }
      delete sanitizedValues.enabled
      sanitizedValues.emarsysClientCreationCampaignId = 0
      updateLocale(sanitizedValues, match.params.id).then(() =>
        history.push({
          pathname: "/locales/list",
          state: { submitType: "edit", success: true },
        })
      )
    },
  })

  const savedGetLocalesWithId = useCallback(getLocalesWithId, [])

  const isEditingLocale = !isEmpty(match.params)

  useEffect(() => {
    if (isEditingLocale) savedGetLocalesWithId(match.params.id)
  }, [savedGetLocalesWithId, match.params.id, isEditingLocale])

  const backToList = () => {
    history.push({
      pathname: "/locales/list",
      state: {},
    })
  }

  const isAllowedToEdit = rolePermissions[PERMISSIONS.PERM_LOCALES_UPDATE]

  return (
    <WithMenu>
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <h1>
          {`Locales / ${isEditingLocale ? "Éditer" : "Nouveau"}`}
          {localeData && localeData.name ? ` / ${localeData.name[1]}` : null}
        </h1>
      </div>
      {localeData && localeRequest === "completed" ? (
        <form onSubmit={handleSubmit} className="mw-100 w-50 m-auto">
          {!isEmpty(updateLocaleData) && updateLocaleRequest === "completed" ? (
            <div className="alert alert-success" role="alert">
              Sauvegardé avec succès
            </div>
          ) : null}
          {localeError || updateError ? (
            <div className="alert alert-error" role="alert">
              {localeError || updateError}
            </div>
          ) : null}
          <div className={cn(["card mb-4"])}>
            <div className="card-body">
              <h5 className="card-title">Emarsys</h5>

              <div className="form-row">
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysClientRegistrationCampaignId"
                >
                  <label htmlFor="locales.emarsysClientRegistrationCampaignId">
                    ID campagne Emarsys - Inscription par booking
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysClientRegistrationCampaignId"
                    name="locales.emarsysClientRegistrationCampaignId"
                    value={values.locales.emarsysClientRegistrationCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysPlayerLinkCampaignId"
                >
                  <label htmlFor="locales.emarsysPlayerLinkCampaignId">
                    ID campagne Emarsys - Lien client / bénéficiaire
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysPlayerLinkCampaignId"
                    name="locales.emarsysPlayerLinkCampaignId"
                    value={values.locales.emarsysPlayerLinkCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysOrderValidationCampaignId"
                >
                  <label htmlFor="locales.emarsysOrderValidationCampaignId">
                    ID campagne Emarsys - Validation de commande
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysOrderValidationCampaignId"
                    name="locales.emarsysOrderValidationCampaignId"
                    value={values.locales.emarsysOrderValidationCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysResetPasswordCampaignId"
                >
                  <label htmlFor="locales.emarsysResetPasswordCampaignId">
                    ID campagne Emarsys - Inscription par le BO
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysResetPasswordCampaignId"
                    name="locales.emarsysResetPasswordCampaignId"
                    value={values.locales.emarsysResetPasswordCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysClientKeycloakRegistrationCampaignId"
                >
                  <label htmlFor="locales.emarsysClientKeycloakRegistrationCampaignId">
                    ID campagne Emarsys - Inscription par Keycloak
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysClientKeycloakRegistrationCampaignId"
                    name="locales.emarsysClientKeycloakRegistrationCampaignId"
                    value={values.locales.emarsysClientKeycloakRegistrationCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>
                <div
                  className="col-sm-6 col-md-6 pb-3"
                  key="emarsysEmailPaymentCampaignId"
                >
                  <label htmlFor="locales.emarsysEmailPaymentCampaignId">
                    ID campagne Emarsys - Paiement par email
                  </label>
                  <input
                    disabled={!isAllowedToEdit}
                    type="number"
                    className="form-control"
                    id="locales.emarsysEmailPaymentCampaignId"
                    name="locales.emarsysEmailPaymentCampaignId"
                    value={values.locales.emarsysEmailPaymentCampaignId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>

                {localeData?.code === 'fr' ? (
                  <>
                    <div
                      className="col-sm-6 col-md-6 pb-3"
                      key="emarsysEmailHotelNewOrderCampaignId"
                    >
                      <label htmlFor="locales.emarsysEmailHotelNewOrderCampaignId">
                        ID campagne Emarsys - Nouvelle commande (Email hotel)
                      </label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="number"
                        className="form-control"
                        id="locales.emarsysEmailHotelNewOrderCampaignId"
                        name="locales.emarsysEmailHotelNewOrderCampaignId"
                        value={values.locales.emarsysEmailHotelNewOrderCampaignId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 pb-3"
                      key="emarsysEmailHotelArticlePlayerUpdatedCampaignId"
                    >
                      <label htmlFor="locales.emarsysEmailHotelArticlePlayerUpdatedCampaignId">
                        ID campagne Emarsys - Modification article (Email hotel)
                      </label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="number"
                        className="form-control"
                        id="locales.emarsysEmailHotelArticlePlayerUpdatedCampaignId"
                        name="locales.emarsysEmailHotelArticlePlayerUpdatedCampaignId"
                        value={values.locales.emarsysEmailHotelArticlePlayerUpdatedCampaignId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 pb-3"
                      key="emarsysEmailHotelArticleCancelledCampaignId"
                    >
                      <label htmlFor="locales.emarsysEmailHotelArticleCancelledCampaignId">
                        ID campagne Emarsys - Annulation article (Email hotel)
                      </label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="number"
                        className="form-control"
                        id="locales.emarsysEmailHotelArticleCancelledCampaignId"
                        name="locales.emarsysEmailHotelArticleCancelledCampaignId"
                        value={values.locales.emarsysEmailHotelArticleCancelledCampaignId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                    <div
                      className="col-sm-6 col-md-6 pb-3"
                      key="emarsysEmailHotelOrderCommentUpdatedCampaignId"
                    >
                      <label htmlFor="locales.emarsysEmailHotelOrderCommentUpdatedCampaignId">
                        ID campagne Emarsys - Modification commentaire (Email hotel)
                      </label>
                      <input
                        disabled={!isAllowedToEdit}
                        type="number"
                        className="form-control"
                        id="locales.emarsysEmailHotelOrderCommentUpdatedCampaignId"
                        name="locales.emarsysEmailHotelOrderCommentUpdatedCampaignId"
                        value={values.locales.emarsysEmailHotelOrderCommentUpdatedCampaignId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <div className="valid-tooltip">Looks good!</div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mb-4">
            {!isAllowedToEdit ? (
              <button
                onClick={backToList}
                type="button"
                className="btn btn-secondary mr-2"
              >
                Revenir à la liste
              </button>
            ) : null}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={updateLocaleRequest === "loading" || !isAllowedToEdit}
            >
              {updateLocaleRequest === "loading" ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Sauvegarder
            </button>
          </div>
        </form>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </WithMenu>
  )
}

LocalesEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default LocalesEdit
