import React from "react"
import PropTypes from "prop-types"

// Utils
import { doesUserHaveRoles } from "../../../utils/rolesHandler"

const Header = ({ onClick, routes, userInfo }) => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <ul className="navbar-nav mr-auto">
      {Object.keys(routes)
        .filter(route => {
          const r = routes[route]
          let found = false
          if (!userInfo) return false
          if (r.permissions.length === 0) found = true
          r.permissions.forEach(perm => {
            if (doesUserHaveRoles(userInfo, perm)) {
              found = true
            }
          })
          return found
        })
        .map(route => (
          <li key={routes[route].name} className="nav-item">
            <a className="nav-link" onClick={() => onClick(route)}>
              {routes[route].name}
            </a>
          </li>
        ))}
    </ul>
  </nav>
)

Header.propTypes = {
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.object.isRequired,
}

export default Header
