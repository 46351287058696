/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"

// Components
import Spinner from "../../../../atoms/Spinner/Spinner"
import { TextInput, SelectInput } from "../../../../atoms/Inputs/Inputs"
import GlobalMessage from "../../../../atoms/GlobalMessage/GlobalMessage"

// Hooks
import useFidEstablishments from "../../../../../hooks/Api/Fid/useFidEstablishments"
import useFidEmployees from "../../../../../hooks/Api/Fid/useFidEmployees"

// Utils
import cn from "../../../../../utils/cn"

// Data
import { initialEmployee } from "../form-initial-values"

import style from "./FidAdminEmployees.module.css"

const EDITING = "editing"

// COMPONENT
const FidAdminEmployees = () => {
  const [establishments, setEstablishments] = useState([])
  const [employees, setEmployees] = useState([])
  const [employeesEdition, setEmployeesEdition] = useState([])

  const [dataReady, setDataReady] = useState(false)
  const [filterEstablishment, setFilterEstablishment] = useState(null)
  const [globalMessage, setGlobalMessage] = useState({
    isActive: false,
    message: null,
    className: null,
  })

  const [
    { getFidEmployees, createFidEmployee, updateFidEmployee },
  ] = useFidEmployees()

  // Unnecessary
  const [{ getFidEstablishments }] = useFidEstablishments()

  const getFidEmployeesMem = useCallback(getFidEmployees, [])
  const getFidEstablishmentsMem = useCallback(getFidEstablishments, [])

  const handleCRUDRequestError = error => {
    if (error.response) {
      switch (error.response.status) {
        case 500: {
          setGlobalMessage({
            ...globalMessage,
            isActive: true,
            message: (
              <span>Une erreur est survenue au moment de la soumission</span>
            ),
            className: "alert alert-danger",
          })
          break
        }
        case 400:
          {
            const errorsList =
              error.response.data && error.response.data.errors
                ? Object.keys(error.response.data.errors).map(errorName => ({
                    field: errorName,
                    message: error.response.data.errors[errorName],
                  }))
                : null

            setGlobalMessage({
              ...globalMessage,
              isActive: true,
              message: (
                <ul className="m-0">
                  {errorsList
                    ? errorsList.map(err => (
                        <li>
                          <b>{err.field}: </b>
                          {err.message || "Erreur au niveau de ce champ"}
                        </li>
                      ))
                    : "Erreur au niveau du formulaire"}
                </ul>
              ),
              className: "alert alert-danger",
            })
          }
          break
        default:
          break
      }
    }
  }
  const handleMessageClose = e => {
    e.preventDefault()

    setGlobalMessage({ ...globalMessage, isActive: false })
  }

  const fetchInitialData = useCallback(async () => {
    try {
      const empResp = await getFidEmployeesMem({
        withoutAdmin: 1,
        active: 1,
      })
      const estResp = await getFidEstablishmentsMem({
        withoutAdmin: 1,
      })

      const empList = Object.keys(empResp.data.employees).map(
        empId => empResp.data.employees[empId]
      )
      const estList = Object.keys(estResp.data.establishments).map(
        estId => estResp.data.establishments[estId]
      )

      setEmployees(empList)
      setEmployeesEdition(
        empList.map(emp => ({
          ...emp,
          isEditing: false,
          loading: false,
        }))
      )
      setEstablishments(estList)
      setDataReady(true)
    } catch (error) {
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>Une erreur est survenue au moment de charger les données</span>
        ),
        className: "alert alert-danger",
      })
    }
  }, [getFidEmployeesMem, getFidEstablishmentsMem, globalMessage])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const setField = (type, fieldId, payload) => {
    if (type === EDITING) {
      setEmployeesEdition(
        employeesEdition.map(est => {
          if (est.id === fieldId) {
            return { ...est, ...payload }
          }
          return est
        })
      )
    } else {
      setEmployees(
        employees.map(est => {
          if (est.id === fieldId) {
            return { ...est, ...payload }
          }
          return est
        })
      )
    }
  }

  const toggleEditMode = (e, id, state) => {
    if (e) e.preventDefault()

    setEmployeesEdition(
      employeesEdition.map(est =>
        est.id === id ? { ...est, isEditing: state } : est
      )
    )
  }

  const submitUpdate = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })
    const emp = employeesEdition.find(empId => empId.id === fieldId)
    const { isEditing, loading, id: empId, ...payload } = emp

    try {
      const newEstResp = await updateFidEmployee(empId, payload)
      setEmployees(
        employees.map(employee =>
          employee.id === empId ? newEstResp.data : employee
        )
      )
      setEmployeesEdition(
        employeesEdition.map(employee =>
          employee.id === empId
            ? {
                ...newEstResp.data,
                loading: false,
                isEditing: false,
              }
            : employee
        )
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès</strong> L'employé à été modifié.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const handleEmployeeFieldChange = (fieldId, fieldName, value) => {
    setField(EDITING, fieldId, {
      [fieldName]: value,
    })
  }

  const deleteEmployees = async (e, fieldId) => {
    e.preventDefault()
    setField(EDITING, fieldId, {
      loading: true,
    })

    const emp = employeesEdition.find(empE => empE.id === fieldId)

    const { isEditing, loading, confirmDelete, id: empId, ...payload } = emp

    try {
      payload.active = false
      const delLevResp = await updateFidEmployee(empId, payload)
      setEmployees(employees.map(e => (e.id === empId ? delLevResp.data : e)))
      setEmployeesEdition(
        employeesEdition.filter(employee => employee.id !== empId)
      )
      setGlobalMessage({
        ...globalMessage,
        isActive: true,
        message: (
          <span>
            <strong>Succès: </strong> L'employé a été supprimé.
          </span>
        ),
        className: "alert alert-success",
      })
    } catch (error) {
      handleCRUDRequestError(error)
    }
  }

  const setConfirmDelete = (e, empId, value) => {
    e.preventDefault()
    setField(EDITING, empId, {
      confirmDelete: value,
    })
  }

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: initialEmployee,
    onSubmit: async submitValues => {
      try {
        const newEmpResp = await createFidEmployee(submitValues)
        setEmployees([...employees, newEmpResp.data])
        setEmployeesEdition([
          ...employeesEdition,
          {
            ...newEmpResp.data,
            loading: false,
            isEditing: false,
          },
        ])
        setGlobalMessage({
          ...globalMessage,
          isActive: true,
          message: (
            <span>
              <strong>Succès</strong> L'employé a été ajouté.
            </span>
          ),
          className: "alert alert-success",
        })
      } catch (error) {
        handleCRUDRequestError(error)
      }
    },
  })

  const columns = [
    {
      id: 1,
      header: "Établissement de rattachement",
      cell: (emp, edition) => {
        if (!edition.isEditing) {
          return emp.establishmentId
            ? establishments.find(est => est.id === emp.establishmentId).name
            : ""
        }
        return (
          <SelectInput
            id="establishmentId"
            name="establishmentId"
            required={false}
            onChange={e =>
              handleEmployeeFieldChange(
                emp.id,
                "establishmentId",
                Number(e.target.value)
              )
            }
            value={edition.establishmentId}
            options={[
              <option key="default" value="" selected disabled>
                Adresse admin de rattachement
              </option>,
              establishments.map(est => (
                <option key={`option${est.id}`} value={Number(est.id)}>
                  {est.name}
                </option>
              )),
            ]}
          />
        )
      },
    },
    {
      id: 2,
      header: "Nom",
      cell: (emp, edition) => {
        if (!edition.isEditing) {
          return emp.lastName
        }
        return (
          <TextInput
            id="lastName"
            name="lastName"
            placeholder="Nom"
            onChange={e =>
              handleEmployeeFieldChange(emp.id, "lastName", e.target.value)
            }
            value={edition.lastName}
          />
        )
      },
    },
    {
      id: 3,
      header: "Prénom",
      cell: (emp, edition) => {
        if (!edition.isEditing) {
          return emp.firstName
        }
        return (
          <TextInput
            id="firstName"
            name="firstName"
            placeholder="Prénom"
            onChange={e =>
              handleEmployeeFieldChange(emp.id, "firstName", e.target.value)
            }
            value={edition.firstName}
          />
        )
      },
    },
  ]

  return (
    <div>
      <GlobalMessage
        isActive={globalMessage.isActive}
        content={globalMessage.message}
        className={globalMessage.className}
        onClose={handleMessageClose}
      />
      {/* CREATE EMPLOYEE */}
      <div className={cn(["card mb-4"])}>
        <div className="card-body">
          <h5 className="card-title">Création nouvel employé</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Prénom*"
                  id="firstName"
                  placeholder="Prénom"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <TextInput
                  label="Nom*"
                  id="lastName"
                  placeholder="Nom"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
              </div>
              <div className="col-sm-4 col-md-4 pb-3">
                <SelectInput
                  label="Établissement de rattachement"
                  id=""
                  required={false}
                  name="establishmentId"
                  onChange={e =>
                    setFieldValue("establishmentId", Number(e.target.value))
                  }
                  onBlur={handleBlur}
                  value={values.establishmentId}
                  options={[
                    <option key="default" value="" selected disabled>
                      Établissement de rattachement
                    </option>,
                    establishments.map(est => (
                      <option key={`option${est.id}`} value={est.id}>
                        {est.name}
                      </option>
                    )),
                  ]}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12 col-md-12 pb-12">
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={false}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ESTABLISHMENT LIST */}
      {dataReady ? (
        <div className={cn(["card mb-4"])}>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-8 col-md-6 pb-6">
                <SelectInput
                  label="Filtrer par établissement"
                  id=""
                  required={false}
                  onChange={e =>
                    setFilterEstablishment(
                      e.target.value === "null" ? null : Number(e.target.value)
                    )
                  }
                  value={filterEstablishment}
                  options={[
                    <option key="defaultFilterEst" value="null">
                      Établissement de rattachement
                    </option>,
                    establishments.map(est => (
                      <option key={`option${est.id}`} value={Number(est.id)}>
                        {est.name}
                      </option>
                    )),
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 pb-12">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      {columns.map(col => (
                        <th scope="col" key={`table_header_${col.id}`}>
                          {col.header}
                        </th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {employees
                      .filter(emp => {
                        if (filterEstablishment !== null) {
                          return emp.establishmentId === filterEstablishment
                        }
                        return true
                      })
                      .map(emp => {
                        const edition = employeesEdition.length
                          ? employeesEdition.find(empE => empE.id === emp.id)
                          : null

                        if (edition)
                          return (
                            <tr key={`row_${emp.id}`}>
                              {columns.map(col => {
                                return (
                                  <td
                                    key={`column_${col.id}`}
                                    className="align-middle"
                                  >
                                    {col.cell(emp, edition)}
                                  </td>
                                )
                              })}
                              <td className="align-middle">
                                <div
                                  className="btn-group btn-group-sm float-right"
                                  role="group"
                                  aria-label="Edit admin"
                                >
                                  {!edition.isEditing ? (
                                    <span>
                                      {!edition.confirmDelete ? (
                                        <a
                                          onClick={e =>
                                            toggleEditMode(e, emp.id, true)
                                          }
                                          href="/"
                                        >
                                          Modifier
                                        </a>
                                      ) : null}
                                      <span
                                        className={cn([
                                          "ml-4",
                                          !edition.confirmDelete
                                            ? style.delete
                                            : "",
                                        ])}
                                      >
                                        {edition.confirmDelete ? (
                                          <b className={style.confirmLabel}>
                                            Êtes vous sûr ?
                                          </b>
                                        ) : null}
                                        <a
                                          className="text-danger"
                                          onClick={
                                            edition.confirmDelete
                                              ? e => deleteEmployees(e, emp.id)
                                              : e =>
                                                  setConfirmDelete(
                                                    e,
                                                    emp.id,
                                                    true
                                                  )
                                          }
                                          href="/"
                                        >
                                          {edition.confirmDelete
                                            ? "Oui"
                                            : "Supprimer"}
                                        </a>
                                        {edition.confirmDelete ? (
                                          <a
                                            className="ml-4 text-primary"
                                            onClick={e =>
                                              setConfirmDelete(e, emp.id, false)
                                            }
                                            href="/"
                                          >
                                            Annuler
                                          </a>
                                        ) : null}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <a
                                        className="mr-4"
                                        onClick={e => submitUpdate(e, emp.id)}
                                        href="/"
                                      >
                                        Valider
                                      </a>
                                      <a
                                        className={cn([
                                          "text-danger mr-2",
                                          style.delete,
                                        ])}
                                        onClick={e =>
                                          toggleEditMode(e, emp.id, false)
                                        }
                                        href="/"
                                      >
                                        Annuler
                                      </a>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

FidAdminEmployees.propTypes = {}
FidAdminEmployees.defaultProps = {}

export default FidAdminEmployees
